import React, { Fragment } from "react";
import MainMenu from "./menu";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { NavLink } from "react-router-dom";
import GlobalUserInfo from "./components/user-info";
import { RubikBody } from "./components/rubik-body";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { UpgradeShopPackage } from "components/UpgradeShopPackage/UpgradeShopPackage";

export default function Layout({ children }) {
  const layout = useLayoutContext();
  const { isShopExpired } = useApp();

  return (
    <Fragment>
      <MainMenu />
      <div className={`l-main ${isShopExpired ? "shop-plan--expired" : ""}`}>
        <div className="l-header">
          <div className="l-header-tile">
            {layout.back_link ? (
              <NavLink
                className="l-button l-button-default l-button-sm squared light nowrap l-mt-8 l-mr-16"
                to={layout.back_link}
              >
                <Icon name="close" />
              </NavLink>
            ) : null}
            {layout.breadcrumb && layout.breadcrumb.length ? (
              <div className="l-header--breadcrumb">
                {layout.breadcrumb.map((item, idx) => {
                  return item.link ? (
                    <NavLink
                      key={item.title + idx}
                      to={item.link}
                      className="l-header--breadcrumb-item"
                    >
                      {item.title}
                    </NavLink>
                  ) : (
                    <div
                      className="l-header--breadcrumb-title"
                      key={item.title + idx}
                    >
                      <span className="l-header--breadcrumb-item">
                        {item.title}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div id="l-header-wrapper" className="l-header--comp"></div>
          <div className="l-header-info pl-3">
            <GlobalUserInfo />
            <RubikBody />
          </div>
        </div>
        <div className={`l-body ${isShopExpired ? "shop-expired" : ""}`}>
          {isShopExpired && <UpgradeShopPackage isExpired={true} />}
          <div className={`full-size ${isShopExpired ? "d-none" : ""}`}>
            {children}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
