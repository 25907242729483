import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useEffect, useRef, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Logo from "component-v2/logos/logos";
import { QRCodeSVG } from "qrcode.react";
import { VN247 } from "@haravan/napas";
import { callGetApiOrderDetails } from "api/order-repository";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import Icon from "component-v2/icons/icons";
import PrintButtonQr from "screens-v2/orders/components/print-button-qr";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useIntl } from "react-intl";
import { toNumber } from "lodash";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import Tooltip from "rc-tooltip";
import SpinnerBladeLoader from "component-v2/Loading/SpinnerBladeLoader";
import { HOTKEYS } from "models/constants";
import moment from "moment";
import { getCheckoutSession } from "api/checkout-repository";
import { repaymentCheckout } from "api/checkout-repository";
import {
  AutoQRPaymentBanks,
  PaymentQrMethods,
} from "models/payment-methods-model";

const QRStatus = {
  loading: "loading",
  success: "success",
  error: "error",
  expired: "expired",
};
const CheckoutPaymentStatus = {
  authorized: "Authorized",
  paid: "Paid",
  partially_refunded: "PartiallyRefunded",
  partially_paid: "PartiallyPaid",
  pending: "Pending",
  refunded: "Refunded",
  unpaid: "Unpaid",
  voided: "Voided",
};
const qrHeader = {
  21: "Ví MoMo và Chuyển khoản",
};
export const ModalQRPayment = ({
  show,
  setShow,
  paymentDetails,
  setPaymentDetails,
  changeMethodAction,
  cancelOrderAction,
  onTransactionCompleted,
  signalRData,
}) => {
  const { callAPI, shop_setting, current_location, auth } = useApp();
  const qrcodeSVG = useRef(null);
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const [orderData, setOrderData] = useState();
  const [transferAccountData, setTransferAccountData] = useState(null);
  const [authCode, setAuthCode] = useState();
  const [countDown, setCountDown] = useState(600); // 10 mins
  const [qrStatus, setQrStatus] = useState(
    paymentDetails?.qrStatus || "loading",
  );
  const [loading, setLoading] = useState(false);
  const countDownRef = useRef(countDown);
  const showRef = useRef(show);
  const checkStatusRef = useRef(null);
  const paymentDetailsRef = useRef(paymentDetails);
  const authCodeInputRef = useRef(null);
  countDownRef.current = countDown;
  showRef.current = show;
  paymentDetailsRef.current = paymentDetails;
  const qrCodeSize = 200;
  const autoConfirmPayment =
    !!AutoQRPaymentBanks[transferAccountData?.bankCode];

  useEffect(() => {
    let timer;
    if (
      qrStatus === QRStatus.success &&
      paymentDetails?.paymentMethodId === PaymentQrMethods.momo_qr
    ) {
      let startTime = new Date();
      let endTime = moment(startTime).add(601, "s");
      timer = setInterval(() => {
        setCountDown((time) => {
          const remainingTime = endTime.diff(moment(new Date()), "seconds");
          if (time <= 0 || remainingTime < 0) {
            setQrStatus(QRStatus.expired);
            clearInterval(timer);
            return 0;
          } else return remainingTime;
        });
      }, 1000);
    }
    if (!show && !!timer) {
      clearInterval(timer);
    }
    return () => {
      setAuthCode("");
      clearInterval(timer);
    };
  }, [show, qrStatus, paymentDetails]);
  const getOrder = async () => {
    const res = await callGetApiOrderDetails(
      callAPI,
      paymentDetailsRef.current?.orderId,
    );
    if (res && res?.data) {
      const { transactions, noteAttributes } = res?.data;
      let newOrderData = { ...paymentDetails?.orderData, ...res?.data };
      newOrderData.noteAttributes = noteAttributes || [];
      newOrderData.transactions = transactions || [];
      setOrderData({ ...newOrderData, transferAccount: transferAccountData });
      onTransactionCompleted?.({
        ...newOrderData,
        paymentStatus: "paid",
        isQRPayment: true,
      });
    } else {
      const errorMsg =
        res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: `notifications.${errorMsg}`,
          defaultMessage: errorMsg,
        }),
      );
    }
  };
  useEffect(() => {
    if (
      signalRData &&
      signalRData?.order &&
      signalRData?.order?.id === paymentDetailsRef?.current?.orderData?.orderId
    ) {
      const { paymentStatus } = signalRData?.order;
      if (paymentStatus === CheckoutPaymentStatus.paid) {
        getOrder();
      } else if (
        paymentStatus === CheckoutPaymentStatus.unpaid ||
        paymentStatus === CheckoutPaymentStatus.voided
      ) {
        onTransactionCompleted?.({
          ...paymentDetails?.orderData,
          paymentStatus: "failed",
          isQRPayment: true,
        });
      }
    }
    return () => {};
  }, [signalRData]);

  const checkTransactionStatus = async (interval = false) => {
    if (countDownRef.current <= 0 || showRef.current === false) {
      if (!!checkStatusRef.current) {
        clearTimeout(checkStatusRef.current);
        checkStatusRef.current = null;
      }
      return;
    }
    return await getCheckoutSession(
      callAPI,
      paymentDetailsRef.current?.sessionId,
    )
      .then(async (res) => {
        if (
          res &&
          res?.order &&
          res?.order?.id === paymentDetailsRef?.current?.orderData?.orderId
        ) {
          const { paymentStatus } = res?.order;
          if (paymentStatus === CheckoutPaymentStatus.paid) {
            getOrder();
          } else if (
            paymentStatus === CheckoutPaymentStatus.unpaid ||
            paymentStatus === CheckoutPaymentStatus.voided
          ) {
            onTransactionCompleted?.({
              ...paymentDetails?.orderData,
              paymentStatus: "failed",
              isQRPayment: true,
            });
          } else if (interval) {
            if (!!checkStatusRef.current) {
              clearTimeout(checkStatusRef.current);
              checkStatusRef.current = null;
            }
            checkStatusRef.current = setTimeout(() => {
              checkTransactionStatus(true);
            }, 5000);
          }
        } else {
          const errorMsg =
            res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
          showGlobalToast(
            "error",
            intl.formatMessage({
              id: `notifications.${errorMsg}`,
              defaultMessage: errorMsg,
            }),
          );
        }
      })
      .catch((err) => {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${"Đã có lỗi xảy ra, xin vui lòng thử lại."}`,
            defaultMessage: "Đã có lỗi xảy ra, xin vui lòng thử lại.",
          }),
        );
      });
  };
  useEffect(() => {
    if (
      show &&
      paymentDetails &&
      paymentDetails?.url &&
      paymentDetails?.orderData
    ) {
      let transferData = null;
      if (
        paymentDetails?.url &&
        paymentDetails?.paymentMethodId !== PaymentQrMethods.momo_qr
      ) {
        const parsed = JSON.parse(paymentDetails.url);
        transferData = {
          bankName: parsed?.BankName,
          bankCode: parsed?.BankCode,
          bankAccount: parsed?.BankAccount,
          bankAccountName: parsed?.BankAccountName,
          amount: parsed?.Amount,
          note: parsed?.Note,
          createdDate: parsed?.CreatedDate,
          autoConfirmPayment: !!AutoQRPaymentBanks[parsed?.BankCode],
        };
        setTransferAccountData(transferData);
      }
      setOrderData({
        ...paymentDetails?.orderData,
        amountPaid: paymentDetails?.amount,
        transferAccount: transferData,
      });
      setQrStatus(paymentDetails?.qrStatus);
      setCountDown(600); // 10 mins
      if (
        paymentDetails.qrStatus === QRStatus.success &&
        paymentDetails?.paymentMethodId === PaymentQrMethods.momo_qr
      ) {
        checkStatusRef.current = setTimeout(() => {
          checkTransactionStatus(true);
        }, 5000);
      }
    }
  }, [show, paymentDetails]);
  const redirectChangePaymentMethod = () => {
    if (paymentDetails?.orderId) {
      window.open(
        `${getConstantLinkDetail(
          CONSTANT_LINKS.route_order_detail,
          paymentDetails?.orderId,
        )}?confirmPayment=true`,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };
  const redirectCancelOrder = () => {
    if (paymentDetails?.orderId) {
      window.open(
        `${getConstantLinkDetail(
          CONSTANT_LINKS.route_order_detail,
          paymentDetails?.orderId,
        )}?cancelOrder=true`,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };
  const renewPaymentLink = async () => {
    setQrStatus(QRStatus.loading);
    await repaymentCheckout(
      callAPI,
      paymentDetails?.sessionId,
      paymentDetails?.paymentInstructionId,
    ).then((res) => {
      const qrData =
        res?.nextActions?.find((action) => action.type === "Qr")?.data ||
        res?.order?.paymentQrData;
      if (!!qrData) {
        setPaymentDetails((prev) => ({
          ...prev,
          url: qrData,
          qrStatus: "success",
        }));
      } else {
        setPaymentDetails((prev) => ({ ...prev, qrStatus: "error" }));
        const errorMsg =
          res?.errors?.[0] || "Có lỗi xảy ra khi lấy thông tin QR Code";
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${errorMsg}`,
            defaultMessage: errorMsg,
          }),
        );
      }
    });
  };
  const body = () => {
    return (
      <div className="modal-create-customer">
        <div
          className={`modal-qr-wrapper d-flex flex-column align-items-center`}
        >
          <div
            className={`modal-qr-content d-flex flex-column align-items-center ${
              paymentDetails?.paymentMethodId === PaymentQrMethods.momo_qr
                ? "momo-bg"
                : ""
            }`}
          >
            {paymentDetails?.paymentMethodId === PaymentQrMethods.momo_qr ? (
              <>
                <span className="logo-span">
                  <Logo
                    name={`${"mobile_wallet"}`}
                    size={"5"}
                    className="d-flex w-100"
                  />
                </span>
                <p
                  className="m-0 text-white font-weight-bold"
                  style={{ letterSpacing: 1 }}
                >
                  THANH TOÁN QUA MỌI ỨNG DỤNG
                </p>
              </>
            ) : (
              <>
                {transferAccountData?.bankName && (
                  <div className="position-relative mb-2">
                    <span className="position-absolute logo-absolute-left-center">
                      <Logo
                        name={`${
                          paymentDetails?.paymentMethodType || "other_qr"
                        }`}
                        size={"md"}
                        className="d-flex w-100"
                      />
                    </span>
                    <span className="font-weight-bold">
                      {transferAccountData?.bankName}
                    </span>
                  </div>
                )}
                <span className="font-weight-bold">
                  {transferAccountData?.bankAccount}
                </span>
                <span>{transferAccountData?.bankAccountName}</span>
              </>
            )}
            <div
              id="qrcodeSVG"
              className="position-relative m-auto l-p-8 bg-white"
              ref={qrcodeSVG}
            >
              {qrStatus !== QRStatus.success && (
                <div
                  className="position-absolute"
                  style={{
                    width: `${qrCodeSize}px`,
                    height: `${qrCodeSize}px`,
                    background: "rgba(255, 255, 255, 0.70)",
                  }}
                ></div>
              )}
              {qrStatus === QRStatus.loading &&
                (paymentDetails?.paymentMethodId !== PaymentQrMethods.momo_qr
                  ? !!transferAccountData
                  : true) && (
                  <div
                    className="position-absolute bg-white rounded-circle"
                    style={{
                      padding: "26px",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <SpinnerBladeLoader />
                  </div>
                )}
              {paymentDetails?.paymentMethodId === PaymentQrMethods.momo_qr ? (
                <QRCodeSVG
                  value={paymentDetailsRef?.current?.url}
                  includeMargin={false}
                  width={qrCodeSize}
                  height={qrCodeSize}
                />
              ) : (
                <QRCodeSVG
                  value={VN247(
                    transferAccountData?.bankCode || "",
                    transferAccountData?.bankAccount || "",
                    transferAccountData?.amount || 0,
                    transferAccountData?.note || "",
                  )}
                  width={qrCodeSize}
                  height={qrCodeSize}
                />
              )}
            </div>
            {paymentDetails?.paymentMethodId === PaymentQrMethods.momo_qr && (
              <div className="logo-wrapper d-flex flex-column align-items-center">
                <span className="bank-logo-span">
                  <Logo
                    name={`${"momo_bank"}`}
                    size={"4.2"}
                    className="d-flex"
                  />
                </span>
                <span className="bank-logo-text text-white font-weight-bold">
                  VÍ ĐIỆN TỬ VÀ CÁC APP NGÂN HÀNG KHÁC
                </span>
              </div>
            )}
          </div>
          {qrStatus === QRStatus.success ? (
            <>
              {paymentDetails.paymentMethodId === PaymentQrMethods.momo_qr && (
                <span className="l-mt-4 d-flex content-secondary w-100 justify-content-center">
                  <p className="mr-2 l-mb-8">Mã QR sẽ hết hạn sau</p>
                  <p className="content-accent l-mb-8">
                    {`${Math.floor(countDown / 60)}`.padStart(2, 0)}:
                    {`${countDown % 60}`.padStart(2, 0)}
                  </p>
                </span>
              )}
              <div className="d-flex l-mb-8">
                <p className="m-0 mr-2 content-secondary">
                  Đơn hàng {paymentDetails?.orderNumber}
                </p>
                {paymentDetails.paymentMethodId ===
                  PaymentQrMethods.momo_qr && (
                  <Tooltip
                    placement="top"
                    showArrow={true}
                    overlay={
                      <>
                        Nếu khách đã thanh toán, bấm để kiểm tra trạng thái
                        nhanh
                      </>
                    }
                  >
                    <div
                      className="position-relative"
                      onClick={async () => {
                        if (qrStatus === QRStatus.loading || loading) return;
                        setLoading(true);
                        await checkTransactionStatus().finally(() =>
                          setLoading(false),
                        );
                      }}
                    >
                      <Icon
                        name={"refresh_dual"}
                        size={"2.3"}
                        className={`refresh-dual ${
                          loading ? "active" : ""
                        } cursor-pointer`}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
              <p
                className="l-mb-8 content-primary font-weight-bold"
                style={{ fontSize: "18px" }}
              >
                {!!paymentDetails?.amount &&
                  toFormattedCurrency(
                    Math.abs(toNumber(paymentDetails?.amount)),
                  )}
              </p>
              {paymentDetails?.paymentMethodId !== PaymentQrMethods.momo_qr ? (
                !autoConfirmPayment ? (
                  <div className="cart-search-container">
                    <span className={`cart-search-icon`}>
                      <Icon name={"edit"} color="var(--contentSecondary)" />
                    </span>
                    <input
                      id="search_cart_product"
                      type="text"
                      autoComplete="off"
                      ref={authCodeInputRef}
                      className="cart-search-box h-100"
                      style={{ width: "145px" }}
                      placeholder={"Nhập mã chuẩn chi"}
                      value={authCode}
                      onChange={(e) => setAuthCode(e.target.value)}
                    />
                  </div>
                ) : (
                  <span className="font-weight-bold content-accent d-flex">
                    {"Xác nhận thanh toán tự động..."}
                    <div
                      className="position-relative ml-1"
                      onClick={async () => {
                        if (qrStatus === QRStatus.loading || loading) return;
                        setLoading(true);
                        await checkTransactionStatus().finally(() =>
                          setLoading(false),
                        );
                      }}
                    >
                      <Icon
                        name={"refresh_dual"}
                        size={"2.3"}
                        className={`refresh-dual ${
                          loading ? "active" : ""
                        } cursor-pointer`}
                      />
                    </div>
                  </span>
                )
              ) : null}
            </>
          ) : qrStatus === QRStatus.error ? (
            <>
              <p className="m-0 mt-2 mr-2 content-secondary">
                Tạo mã QR thất bại. Vui lòng thử lại
              </p>
              <Button
                title={"Thử lại"}
                id="order.create.success_popup.new_button"
                onClick={() => renewPaymentLink()}
                light
                size="md"
                className="w-100 font-weight-semibold font-size-16 w-max mt-2 l-mb-4"
                icon={"refresh"}
                status="primary"
                disabled={qrStatus === QRStatus.loading}
              ></Button>
            </>
          ) : qrStatus === QRStatus.expired ? (
            <>
              <p className="m-0 mt-2 mr-2 content-secondary">
                Mã QR đã hết hạn. Vui lòng làm mới
              </p>
              <Button
                title={"Làm mới"}
                id="order.create.success_popup.new_button"
                onClick={() => renewPaymentLink()}
                light
                size="md"
                className="w-100 font-weight-semibold font-size-16 w-max mt-2 l-mb-4"
                icon={"cached"}
                status="primary"
                disabled={qrStatus === QRStatus.loading}
              ></Button>
            </>
          ) : (
            <>
              <p className="m-0 mt-2 mr-2 content-secondary">
                Đang tạo mã QR...
              </p>
              <div className="d-flex l-mb-8">
                <p className="m-0 mr-2 content-secondary">
                  Đơn hàng {paymentDetails?.orderNumber}
                </p>
              </div>
              {!!paymentDetails?.amount && (
                <p
                  className="l-mb-16 content-primary font-weight-bold"
                  style={{ fontSize: "18px" }}
                >
                  {toFormattedCurrency(
                    Math.abs(toNumber(paymentDetails?.amount)),
                  )}
                </p>
              )}
            </>
          )}
        </div>
        <div className="l-mt-16 d-flex flex-column">
          <div className="d-flex">
            <PrintButtonQr
              disabled={qrStatus !== QRStatus.success}
              orderData={orderData}
              hasTitle={true}
              qrcodeSVG={qrcodeSVG}
              isShowTitleButton={true}
              transferAccountData={transferAccountData}
            />
            {!autoConfirmPayment &&
              !(
                paymentDetails?.paymentMethodId === PaymentQrMethods.momo_qr
              ) && (
                <Button
                  title={"Xác nhận thanh toán"}
                  id="order.create.success_popup.new_button"
                  onClick={async () => {
                    const confirmPaymentRes = await callAPI(
                      "post",
                      `/call/com_api/pos/orders/${paymentDetails?.orderId}/transactions`,
                      [
                        {
                          gateway: paymentDetails.paymentMethodName,
                          amount: +Number(paymentDetails.amount).toFixed(4),
                          externalTransactionId: authCode?.trim(),
                          locationId: current_location.id,
                          userId: auth.user.id,
                          instructionId: paymentDetails.paymentInstructionId,
                        },
                      ],
                    );
                    if (
                      !!confirmPaymentRes.errors?.length ||
                      !confirmPaymentRes.data
                    ) {
                      const errorMsg =
                        confirmPaymentRes.errors[0] ||
                        "Thanh toán không thành công";
                      showGlobalToast(
                        "error",
                        intl.formatMessage({
                          id: `notifications.${errorMsg}`,
                          defaultMessage: errorMsg,
                        }),
                      );
                      throw new Error(errorMsg);
                    }
                    getOrder();
                  }}
                  size="lg"
                  className="l-ml-16 w-100 font-weight-semibold font-size-16"
                  disabled={qrStatus !== QRStatus.success}
                  status="primary"
                ></Button>
              )}
          </div>
          <div className="l-mt-16 d-flex">
            {shop_setting?.allowCancelOrder ? (
              <Button
                title={"Hủy đơn hàng"}
                id="order.create.success_popup.new_button"
                onClick={
                  cancelOrderAction
                    ? () => cancelOrderAction(paymentDetails)
                    : () => redirectCancelOrder()
                }
                light
                size="lg"
                className="l-mr-8 w-100 font-weight-semibold font-size-16"
                disabled={qrStatus === QRStatus.loading}
              ></Button>
            ) : (
              <Button
                title={"Tạo đơn mới"}
                id="order.create.success_popup.new_button"
                onClick={() => setShow(false, paymentDetails, false, false)}
                light
                size="lg"
                className="l-mr-8 w-100 font-weight-semibold font-size-16"
                disabled={qrStatus === QRStatus.loading}
                badge={HOTKEYS.ESC}
              ></Button>
            )}
            <Button
              title={"Đổi phương thức khác"}
              id="order.create.success_popup.new_button"
              onClick={
                changeMethodAction
                  ? () => changeMethodAction(paymentDetails)
                  : () => redirectChangePaymentMethod()
              }
              light
              size="lg"
              className="l-ml-8 w-100 font-weight-semibold font-size-16"
              disabled={qrStatus === QRStatus.loading}
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      headerTitle={
        qrHeader[paymentDetails?.paymentMethodId] || "Chuyển khoản ngân hàng"
      }
      isTitleCenter
      body={body()}
      size={"sm"}
      show={show}
      setShow={(value) => setShow(value, paymentDetails)}
      className={"modal-qr-dialog"}
      onCancel={() => setShow(false, paymentDetails, false, false)}
      footer={<></>}
    />
  );
};
