import CurrencyUtils from "./CurrencyUtils";
import DateUtils from "./DateUtils";
import ProductUtils from "./ProductUtils";
import SiteUtils from "./SiteUtils";
import EventBus from "./Event";

const dataLayerPush = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export {
  dataLayerPush,
  CurrencyUtils,
  DateUtils,
  ProductUtils,
  SiteUtils,
  EventBus,
};
