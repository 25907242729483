import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";

export default function OrderDetailSummaryTransactions({ transaction, type }) {
  const intl = useIntl();
  if (type === "payment") {
    if (
      transaction.transactionName === "Xác nhận thanh toán" ||
      transaction?.type === "paid"
    ) {
      return (
        <>
          <div className="d-flex flex-row justify-content-between l-pt-4 l-pb-4 font-size-12 text-secondary ml-4">
            <div>{transaction.paymentMethodName || transaction.gateway}</div>
            <div>
              <CurrencyShow value={transaction.amountTransaction} />
            </div>
          </div>
          {transaction.externalTransactionId ? (
            <div className="d-flex flex-row justify-content-between mx-2 mb-3 font-size-12 text-secondary ml-4">
              <div className="sub-info text-secondary">
                {intl.formatMessage({
                  id: "carts.orders.Mã chuẩn chi",
                  defaultMessage: "Mã chuẩn chi",
                })}
                : {transaction.externalTransactionId}
              </div>
            </div>
          ) : null}
        </>
      );
    }
  } else if (type === "refund") {
    if (
      transaction.transactionName === "Hoàn trả" ||
      transaction?.type === "refund"
    ) {
      return (
        <div className="d-flex flex-row justify-content-between l-pt-4 l-pb-4 font-size-12 text-secondary ml-4">
          <div>{transaction.paymentMethodName || transaction.gateway}</div>
          <div>
            <CurrencyShow value={transaction.amountTransaction} />
          </div>
        </div>
      );
    }
  } else if (type === "paid") {
    if (transaction.transactionName === "Chờ xử lý") {
      return (
        <div className="d-flex flex-row justify-content-between l-pt-4 l-pb-4 font-size-12 text-secondary ml-4">
          <div>{transaction.paymentMethodName || transaction.gateway}</div>
          <div>
            <CurrencyShow value={Math.abs(transaction.amountTransaction)} />
          </div>
        </div>
      );
    }
  }

  return null;
}
