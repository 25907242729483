import { Fragment, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Assets } from "assets";
import { Loading } from "@haravan/react-components";
import { findIndex, union } from "lodash";
import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import { renderTooltipItem } from "../utils/highchart-utils";

const defaultTable = [
  {
    Gateway: "Tiền mặt",
    PaidAmount: 0,
    ReturnsAmount: 0,
    color: "#3C83F6",
  },
  {
    Gateway: "Visa/Master",
    PaidAmount: 0,
    ReturnsAmount: 0,
    color: "#F97415",
  },
  {
    Gateway: "Chuyển khoản",
    PaidAmount: 0,
    ReturnsAmount: 0,
    color: "#14B8A5",
  },
  {
    Gateway: "COD",
    PaidAmount: 0,
    ReturnsAmount: 0,
    color: "#6467F2",
  },
];
export default function PaymentMethodReportChart({ table, series, isLoading }) {
  const intl = useIntl();
  const [_table, setTable] = useState([]);

  useEffect(() => {
    if (table && table.length) {
      filterTable(table, series);
    } else {
      setTable(defaultTable);
    }
  }, [table, series]);

  const filterTable = (table_input, series) => {
    let newTable = table_input;
    if (
      findIndex(table_input, (item) => {
        return item.Gateway === "Tiền mặt";
      }) === -1
    ) {
      newTable.push({
        Gateway: "Tiền mặt",
        PaidAmount: 0,
        ReturnsAmount: 0,
        color: "#3C83F6",
      });
    }
    if (
      findIndex(table_input, (item) => {
        return item.Gateway === "Visa/Master";
      }) === -1
    ) {
      newTable.push({
        Gateway: "Visa/Master",
        PaidAmount: 0,
        ReturnsAmount: 0,
        color: "#F97415",
      });
    }
    if (
      findIndex(table_input, (item) => {
        return item.Gateway === "Chuyển khoản";
      }) === -1
    ) {
      newTable.push({
        Gateway: "Chuyển khoản",
        PaidAmount: 0,
        ReturnsAmount: 0,
        color: "#14B8A5",
      });
    }
    if (
      findIndex(table_input, (item) => {
        return item.Gateway === "COD";
      }) === -1
    ) {
      newTable.push({
        Gateway: "COD",
        PaidAmount: 0,
        ReturnsAmount: 0,
        color: "#6467F2",
      });
    }
    let sortTable = [];
    for (let i = 0; i < newTable.length; i++) {
      const element = newTable[i];
      if (element.Gateway === "Tiền mặt") {
        sortTable[0] = element;
      } else if (element.Gateway === "Visa/Master") {
        sortTable[1] = element;
      } else if (element.Gateway === "Chuyển khoản") {
        sortTable[2] = element;
      } else if (element.Gateway === "COD") {
        sortTable[3] = element;
      }
    }
    for (let i = 0; i < series.length; i++) {
      for (let j = 0; j < newTable.length; j++) {
        if (newTable[j].Gateway === series[i].name) {
          newTable[j].color = series[i].color;
        }
      }
    }
    sortTable = union(sortTable, newTable);
    setTable(sortTable);
  };
  const renderTable = (table_input) => {
    let result = null;
    if (table_input) {
      result = table_input.map((item, index) => {
        return (
          <tr
            key={index}
            // style={{ height: `${table_input ? table_input.length > 4 ? `calc((100vh - 310px)/${table_input.length})` : '85px' : '85px'}`, minHeight: '50px' }}
          >
            <th scope="row">
              <span>{item.Gateway ? item.Gateway : "--"}</span>
            </th>
            <td className="text-right">
              <CurrencyShow value={item.PaidAmount} />
            </td>
            <td className="text-right">
              <CurrencyShow value={item.ReturnsAmount} />
            </td>
            <td className="text-right">
              <CurrencyShow value={item.PaidAmount + item.ReturnsAmount} />
            </td>
          </tr>
        );
      });
    }
    return result;
  };

  if (isLoading) {
    return (
      <Fragment>
        <div className="col-6 pr-0 h-100" style={{ minWidth: "30%" }}>
          <LayoutCard className="card-chart-payment-container">
            <div className="report__chart__payment-methodV2 d-flex justify-content-center align-items-center">
              <Loading />
            </div>
          </LayoutCard>
        </div>
        <div className="col-6 h-100">
          <LayoutCard className="card-table-container">
            <div
              className="report__detail__payment-method scrollable-y d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <Loading />
            </div>
          </LayoutCard>
        </div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <div className="col-6 pr-0">
        <LayoutCard className="card-chart-payment-container overflow-hidden h-100">
          <div
            className="report__chart__payment-methodV2"
            style={{ display: `${series && series.length ? "" : "flex"}` }}
          >
            {series && series.length ? (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  top: 0,
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    useHTML: true,
                    enabled: true,
                    align: "center",
                    layout: "horizontal",
                    symbolPadding: 0,
                    symbolHeight: 0,
                    symbolRadius: 0,
                    symbolWidth: 0,
                    verticalAlign: "bottom",
                    labelFormatter: function () {
                      return (
                        `<div class="report-chart-legend-custom ${
                          this.visible
                            ? ""
                            : "report-chart-legend-custom--invisible"
                        }">` +
                        '<span class="legend-custom-symbol" style="background-color:' +
                        this.color +
                        '"></span>' +
                        '<span class="legend-custom-label">' +
                        this.name +
                        "</span>" +
                        "</div>"
                      );
                    },
                  },
                  chart: {
                    type: "pie",
                  },
                  title: {
                    text: "",
                  },
                  tooltip: {
                    useHTML: true,
                    borderRadius: 4,
                    borderWidth: 0,
                    backgroundColor: "#FFFFFF",
                    style: {
                      boxShadow:
                        "0px 0px 3px rgba(0, 0, 0, 0.12), 0px 4px 20px rgba(0, 0, 0, 0.16)",
                    },
                    formatter: function () {
                      return renderTooltipItem(this.key, this.color, this.y);
                    },
                    shared: true,
                  },
                  plotOptions: {
                    pie: {
                      allowPointSelect: false,
                      cursor: "pointer",
                      dataLabels: {
                        enabled: false,
                      },
                      showInLegend: true,
                      size: "60%",
                    },
                  },
                  series: [
                    {
                      name: "Tổng hoá đơn",
                      colorByPoint: true,
                      dataLabels: {
                        enabled: true,
                        formatter: function () {
                          let x = parseFloat((this.y / this.total) * 100);
                          const n = parseFloat(x);
                          x = Math.round(n * 100) / 100;
                          return x + "%";
                        },
                      },
                      allowPointSelect: false,
                      data: series,
                      innerSize: "70%",
                    },
                  ],
                }}
              />
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#ffffff",
                  flexDirection: "column",
                  overflow: "auto",
                }}
              >
                <img
                  style={{ width: "auto", height: "100%" }}
                  src={Assets.icon.no_data_payment}
                  alt="no-data"
                />
              </div>
            )}
          </div>
        </LayoutCard>
      </div>
      <div className="col-6">
        <LayoutCard className="pd-16 card-table-container h-100">
          <div
            className="report__detail__payment-method "
            style={{ overflow: "auto", height: "100%" }}
          >
            <table className="table table-hover table-bordered mb-0 payment-method-table">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({
                      id: "payment-vouchers.Phương thức thanh toán",
                      defaultMessage: "Phương thức thanh toán",
                    })}
                  </th>
                  <th className="text-right">
                    {intl.formatMessage({
                      id: "shifts.Đã thu",
                      defaultMessage: "Đã thu",
                    })}
                  </th>
                  <th className="text-right">
                    {intl.formatMessage({
                      id: "shifts.Hoàn trả",
                      defaultMessage: "Hoàn trả",
                    })}
                  </th>
                  <th className="text-right">
                    {intl.formatMessage({
                      id: "shifts.Thực nhận",
                      defaultMessage: "Thực nhận",
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>{renderTable(_table)}</tbody>
            </table>
          </div>
        </LayoutCard>
      </div>
    </Fragment>
  );
}
