import { Loading } from "@haravan/react-components";
import {
  createCustomerAddress,
  updateCustomerAddress,
  updateCustomerAddressDefault,
  updateCustomerInfo,
} from "api/customer-repository";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { isNil } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { concatString } from "screens-v2/carts-v2/utils";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { isValidPhoneNumber } from "utils/Commons";
import { Addresses } from "../customers/tabModalCustomerInfo/addresses";
import { CurrentOrders } from "../customers/tabModalCustomerInfo/currentOrders";
import { EditAddress } from "../customers/tabModalCustomerInfo/editAddress";
import { Info } from "../customers/tabModalCustomerInfo/info";
import { Loyalty } from "../customers/tabModalCustomerInfo/loyalty";

const CustomerInfoTab = {
  info: "info",
  currentOrder: "currentOrder",
  address: "address",
  loyalty: "loyalty",
  editAddress: "editAddress",
};

export const ModalCustomerInfo = ({
  show,
  setShow,
  customerInfo,
  showEditAddress,
  reloadCustomer,
}) => {
  const intl = useIntl();
  const { callAPI, shop_loyalty } = useApp();
  const TabDiscountOrder = () => {
    return [
      {
        id: CustomerInfoTab.info,
        name: "Thông tin",
        isHidden: false,
      },
      {
        id: CustomerInfoTab.currentOrder,
        name: "Đơn hàng gần đây",
        isHidden: false,
      },
      {
        id: CustomerInfoTab.address,
        name: "Địa chỉ giao hàng",
        isHidden: false,
      },
      {
        id: CustomerInfoTab.loyalty,
        name: "Loyalty",
        isHidden: !shop_loyalty?.enabled,
      },
    ];
  };

  const { showGlobalToast } = useLayoutContext();
  const [tabActive, setTabActive] = useState(CustomerInfoTab.info);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [_customerInfo, setCustomerInfo] = useState(
    formatDataCustomer(customerInfo),
  );
  const [editCustomerAddress, setEditCustomerAddress] = useState(null);
  const [editCustomerAddressDefault, setEditCustomerAddressDefault] =
    useState(null);
  const [loadingBtnSave, setLoadingBtnSave] = useState(false);

  function formatDataCustomer(customerInfo) {
    if (!customerInfo) return null;
    return {
      ...customerInfo,
      birthday:
        customerInfo.birthday === ""
          ? customerInfo.birthday
          : moment(customerInfo.birthday).format("DD/MM/YYYY"),
    };
  }

  useEffect(() => {
    if (showEditAddress) setTabActive(CustomerInfoTab.address);
    else setTabActive(CustomerInfoTab.info);
  }, [showEditAddress]);

  useEffect(() => {
    setCustomerInfo(formatDataCustomer(customerInfo));
    if (customerInfo?.customerAddress?.length) {
      const customerAddressDefault = customerInfo?.customerAddress.find(
        (item) => item.isDefault,
      );
      setEditCustomerAddress(customerAddressDefault);
      setEditCustomerAddressDefault(customerAddressDefault);
    }
  }, [customerInfo]);

  async function handleSaveAddress() {
    if (showModalAddress === "edit") {
      handleUpdateCustomerAddress();
    } else {
      handleCreateCustomerAddress();
    }
  }

  const handleUpdateCustomerAddress = async () => {
    try {
      setLoadingBtnSave(true);
      const response = await updateCustomerAddress(
        callAPI,
        customerInfo.id,
        editCustomerAddress.id,
        editCustomerAddress,
      );
      setLoadingBtnSave(false);
      if (!response.errors?.length) {
        if (editCustomerAddress?.isDefault) {
          await updateCustomerAddressDefault(
            callAPI,
            _customerInfo.id,
            editCustomerAddress.id,
          );
        }
        reloadCustomer();
        setShowModalAddress(false);
        showGlobalToast("default", "Cập nhật địa chỉ thành công.");
      } else {
        showGlobalToast("error", response.errors?.[0]);
      }
    } catch (error) {
      // showGlobalToast("error", error?.message);
      setLoadingBtnSave(false);
    }
  };

  const handleCreateCustomerAddress = async () => {
    try {
      setLoadingBtnSave(true);
      if (!isNil(editCustomerAddress.phone)) {
        if (isNaN(Number(editCustomerAddress.phone))) {
          let message = "Số điện thoại không hợp lệ";
          showGlobalToast(
            "default",
            intl.formatMessage({
              id: `notifications.${message}`,
              defaultMessage: message,
            }),
          );
          setLoadingBtnSave(false);
          return;
        }
        if (!isValidPhoneNumber(editCustomerAddress.phone)) {
          let message = "Độ dài số điện thoại phải từ 8 đến 15 ký tự";
          showGlobalToast(
            "default",
            intl.formatMessage({
              id: `notifications.${message}`,
              defaultMessage: message,
            }),
          );
          setLoadingBtnSave(false);
          return;
        }
      }
      const response = await createCustomerAddress(
        callAPI,
        customerInfo.id,
        editCustomerAddress,
      );
      setLoadingBtnSave(false);
      if (!response.errors?.length) {
        reloadCustomer();
        showGlobalToast("default", "Thêm địa chỉ mới thành công.");
        setShowModalAddress(false);
      } else {
        showGlobalToast("error", response.errors?.[0]);
      }
    } catch (error) {
      showGlobalToast("error", error?.message);
      setLoadingBtnSave(false);
    }
  };

  function handleCancelModalAddress() {
    setShowModalAddress(false);
  }

  async function handleSaveCustomer() {
    try {
      let response = null;
      switch (tabActive) {
        case CustomerInfoTab.info:
          const model = {
            gender: _customerInfo.gender,
            lastName: _customerInfo.lastName,
            firstName: _customerInfo.firstName,
            phone: _customerInfo.phone,
            email: _customerInfo.email,
            birthday: moment(_customerInfo.birthday).isValid()
              ? moment(_customerInfo.birthday, "DD/MM/YYYY").format(
                  "YYYY/MM/DD",
                )
              : "",
          };
          setLoadingBtnSave(true);
          response = await updateCustomerInfo(callAPI, _customerInfo.id, model);
          setLoadingBtnSave(false);
          if (!response.errors?.length) {
            setShow(false);
            reloadCustomer();
            showGlobalToast(
              "default",
              "Cập nhật thông tin khách hàng thành công.",
            );
          } else {
            showGlobalToast("error", response.errors?.[0]);
          }
          break;
        case CustomerInfoTab.address:
          setLoadingBtnSave(true);
          response = await updateCustomerAddressDefault(
            callAPI,
            _customerInfo.id,
            editCustomerAddressDefault.id,
          );
          setLoadingBtnSave(false);

          if (!response.errors?.length) {
            setShow(false);
            reloadCustomer();
            showGlobalToast("default", "Cập nhật địa chỉ mặc định thành công.");
          } else {
            showGlobalToast("error", response?.errors?.[0]);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      setLoadingBtnSave(false);
    }
  }

  function handleCancelModal() {
    setShow(false);
    const customerAddressDefault = customerInfo?.customerAddress.find(
      (item) => item.isDefault,
    );
    setEditCustomerAddress(customerAddressDefault);
    setEditCustomerAddressDefault(customerAddressDefault);
    setCustomerInfo(formatDataCustomer(customerInfo));
  }

  const body = () => {
    let content = null;

    switch (tabActive) {
      case CustomerInfoTab.info:
        content = _customerInfo ? (
          <Info
            customerInfo={_customerInfo}
            handleUpdateCustomer={(key, value) => {
              setCustomerInfo((prev) => ({
                ...prev,
                [key]: value,
              }));
            }}
          />
        ) : null;
        break;
      case CustomerInfoTab.currentOrder:
        content = <CurrentOrders customerInfo={_customerInfo} />;
        break;
      case CustomerInfoTab.address:
        content = (
          <Addresses
            customerInfo={_customerInfo}
            editCustomerAddressDefault={editCustomerAddressDefault}
            setShowModalAddress={setShowModalAddress}
            setEditCustomerAddressDefault={setEditCustomerAddressDefault}
            setEditCustomerAddress={setEditCustomerAddress}
          />
        );
        break;
      case CustomerInfoTab.loyalty:
        content = <Loyalty />;
        break;
      default:
        content = null;
        break;
    }

    if (showModalAddress) {
      return (
        <EditAddress
          customerAddress={editCustomerAddress}
          setEditCustomerAddress={setEditCustomerAddress}
          isEdit={showModalAddress === "edit"}
        />
      );
    }

    return (
      <div>
        <div className="d-flex mb-3 l-tab-group l-mt-16">
          {TabDiscountOrder().map((tab) => {
            if (tab.isHidden) return null;
            return (
              <div
                key={tab.id}
                className={`p-3 font-weight-semibold rounded cursor-pointer ${
                  tab.id === tabActive
                    ? "background-accent50 content-accent"
                    : "content-secondary"
                }`}
                onClick={() => {
                  setTabActive(tab.id);
                }}
              >
                {tab.name}
              </div>
            );
          })}
        </div>
        <div className="mt-4">{content}</div>
      </div>
    );
  };

  const footer = () => {
    return tabActive === CustomerInfoTab.info ||
      tabActive === CustomerInfoTab.address ||
      showModalAddress ? (
      <div className="d-flex justify-content-center">
        <Button
          onClick={() => {
            showModalAddress ? handleCancelModalAddress() : handleCancelModal();
          }}
          className=""
          title={"Hủy"}
        />
        <Button
          status="primary"
          className=" ml-3 position-relative"
          onClick={() => {
            showModalAddress ? handleSaveAddress() : handleSaveCustomer();
          }}
        >
          {loadingBtnSave ? (
            <Loading
              size="pico"
              className="loading-btn loading-btn-custom position-absolute"
            />
          ) : (
            ""
          )}
          <span style={{ color: `${loadingBtnSave ? "transparent" : ""}` }}>
            Lưu
          </span>
        </Button>
      </div>
    ) : (
      <div className="d-flex justify-content-center">
        <Button
          onClick={() => {
            setShow(false);
          }}
          className=""
          title={"Đóng"}
          light
        />
      </div>
    );
  };

  const customerFullName = useMemo(() => {
    if (!customerInfo?.lastName && !customerInfo?.firstName) {
      return "";
    }
    return concatString({
      value: [customerInfo?.lastName, customerInfo?.firstName],
    });
  }, [customerInfo?.lastName, customerInfo?.firstName]);

  return (
    <GlobalModal
      fixHeight
      headerTitle={
        showModalAddress
          ? showModalAddress === "edit"
            ? "Sửa địa chỉ"
            : "Thêm địa chỉ mới"
          : customerFullName
            ? `${customerFullName}`
            : "Thông tin khách hàng"
      }
      isTitleCenter
      body={body()}
      footer={footer()}
      show={show}
      setShow={(value) => {
        setShow(value);
        if (!value) handleCancelModal();
      }}
      headerClose={!showModalAddress}
      headerBack={showModalAddress}
      onClickBack={() => {
        setShowModalAddress(false);
      }}
    />
  );
};
