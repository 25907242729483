import { useEffect, useState } from "react";
import * as HrvComponents from "@haravan/react-components";
import { getTittleReportFollowOrderDateDeal } from "./utils/utils";
import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import NumberShow from "component-v2/number-show";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import { useApp } from "contexts/app";

export default function ReportPaymentMethodInfo({
  globalLoading,
  dateRange,
  channel,
  staff,
  summaryReport,
  summaryRefund,
}) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState({
    totalOrder: 0,
    paidAmount: 0,
    returnsAmount: 0,
    grossSaleAmount: 0,
    quantity: 0,
  });
  const appContext = useApp();
  useEffect(() => {
    if (globalLoading) {
      setIsLoading(true);
    } else {
      onRefreshSummaryReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLoading]);

  const onRefreshSummaryReport = async () => {
    setIsLoading(true);
    setSummary({
      totalOrder: 0,
      paidAmount: 0,
      returnsAmount: 0,
      grossSaleAmount: 0,
      quantity: 0,
    });
    const reportOrderDate = await getTittleReportFollowOrderDateDeal(
      appContext,
      dateRange.startDate,
      dateRange.endDate,
      "",
      channel,
      staff,
    );
    const orderCountSpecial = reportOrderDate?.find(
      (x) => x.name === "OrderCountSpecial",
    );
    let _returnsAmount = summaryReport?.find((x) => x.name === "ReturnsAmount");
    const _paidAmount = summaryReport?.find((x) => x.name === "PaidAmount");
    const _grossSaleAmount = summaryReport?.find(
      (x) => x.name === "GrossSaleAmount",
    );
    const _netSaleAmount = summaryReport?.find(
      (x) => x.name === "NetSaleAmount",
    );
    const _quantity = summaryReport?.find((x) => x.name === "Quantity");
    let totalReturnAmount = 0;
    if (channel) {
      if (channel === "channelPOS") {
        totalReturnAmount =
          Math.abs(_returnsAmount ? _returnsAmount.value : 0) +
          (summaryRefund ? summaryRefund.refundAmountTotal : 0);
      } else if (channel === "channelOnline") {
        totalReturnAmount =
          Math.abs(_returnsAmount ? _returnsAmount.value : 0) -
          (summaryRefund ? summaryRefund.refundAmountTotal : 0);
      }
    }
    let totalOrder = orderCountSpecial ? orderCountSpecial.value : 0;
    let grossSaleAmount = _grossSaleAmount ? _grossSaleAmount.value : 0;
    let returnsAmount = channel
      ? totalReturnAmount
      : _returnsAmount
        ? _returnsAmount.value
        : 0;
    let paidAmount = _paidAmount ? _paidAmount.value : 0;
    let netSaleAmount = _netSaleAmount ? _netSaleAmount.value : 0;
    let quantity = _quantity?.value || 0;
    setSummary({
      totalOrder,
      paidAmount,
      returnsAmount,
      netSaleAmount,
      quantity,
    });
    setIsLoading(false);
  };

  return (
    <LayoutCard className="report__infoV2">
      <div className="row justify-content-between">
        <div className="col pr-0">
          <HrvComponents.Tooltip
            placement="bottomRight"
            title={intl.formatMessage({
              id: "reports.Doanh thu thuần = Số lượng sản phẩm (x) Giá bán đã trừ khuyến mãi sản phẩm",
              defaultMessage:
                "Doanh thu thuần = Số lượng sản phẩm (x) Giá bán đã trừ khuyến mãi sản phẩm",
            })}
            overlayClassName="tooltip-custom"
          >
            <div className="report__info__total report__info__total-revenueV2">
              <p className="report__info__total__title">
                {intl.formatMessage({
                  id: "reports.Doanh thu thuần",
                  defaultMessage: "Doanh thu thuần",
                })}
              </p>
              {isLoading ? (
                <HrvComponents.Loading size="pico" />
              ) : (
                <p className="text-left summary-currency font-size-16">
                  <CurrencyShow value={summary.netSaleAmount} />
                </p>
              )}
            </div>
          </HrvComponents.Tooltip>
        </div>
        <div className="col pr-0">
          <HrvComponents.Tooltip
            placement="bottomRight"
            title={intl.formatMessage({
              id: "reports.Tổng tiền thu được do bán sản phẩm, xác định bằng Tổng hóa đơn (-) Chưa thu",
              defaultMessage:
                "Tổng tiền thu được do bán sản phẩm, xác định bằng Tổng hóa đơn (-) Chưa thu",
            })}
            overlayClassName="tooltip-custom"
          >
            <div className="report__info__total report__info__total-billV2">
              <p className="report__info__total__title">
                {intl.formatMessage({
                  id: "shifts.Đã thu",
                  defaultMessage: "Đã thu",
                })}
              </p>
              {isLoading ? (
                <HrvComponents.Loading size="pico" />
              ) : (
                <p className="text-left summary-currency font-size-16">
                  <CurrencyShow value={summary.paidAmount} />
                </p>
              )}
            </div>
          </HrvComponents.Tooltip>
        </div>
        <div className="col pr-0">
          <HrvComponents.Tooltip
            placement="bottomRight"
            title={intl.formatMessage({
              id: "reports.Tổng tiền cuối cùng nhận được, xác định bằng Đã thu (-) Hoàn trả",
              defaultMessage:
                "Tổng tiền cuối cùng nhận được, xác định bằng Đã thu (-) Hoàn trả",
            })}
            overlayClassName="tooltip-custom"
          >
            <div className="report__info__total report__info__total-refundV2">
              <p className="report__info__total__title">
                {intl.formatMessage({
                  id: "shifts.Thực nhận",
                  defaultMessage: "Thực nhận",
                })}
              </p>
              {isLoading ? (
                <HrvComponents.Loading size="pico" />
              ) : (
                <p className="text-left summary-currency font-size-16">
                  <CurrencyShow
                    value={summary.paidAmount - Math.abs(summary.returnsAmount)}
                  />
                </p>
              )}
            </div>
          </HrvComponents.Tooltip>
        </div>
        <div className="col pr-0">
          <HrvComponents.Tooltip
            placement="bottomRight"
            title={intl.formatMessage({
              id: "reports.Tổng số lượng đơn hàng",
              defaultMessage: "Tổng số lượng đơn hàng",
            })}
            overlayClassName="tooltip-custom"
          >
            <div className="report__info__total report__info__total-orderV2">
              <p className="report__info__total__title">
                {intl.formatMessage({
                  id: "reports.Đơn hàng",
                  defaultMessage: "Đơn hàng",
                })}
              </p>
              {isLoading ? (
                <HrvComponents.Loading size="pico" />
              ) : (
                <p className="text-left summary-currency font-size-16">
                  <NumberShow value={summary.totalOrder} />
                </p>
              )}
            </div>
          </HrvComponents.Tooltip>
        </div>
        <div className="col">
          <HrvComponents.Tooltip
            placement="bottomRight"
            title={intl.formatMessage({
              id: "reports.Tổng số lượng sản phẩm đã bán được",
              defaultMessage: "Tổng số lượng sản phẩm đã bán được",
            })}
            overlayClassName="tooltip-custom"
          >
            <div className="report__info__total report__info__total-quantityV2">
              <p className="report__info__total__title">
                {intl.formatMessage({
                  id: "reports.Sản phẩm",
                  defaultMessage: "Sản phẩm",
                })}
              </p>
              {isLoading ? (
                <HrvComponents.Loading size="pico" />
              ) : (
                <p className="text-left summary-currency font-size-16">
                  <NumberShow value={summary.quantity} />
                </p>
              )}
            </div>
          </HrvComponents.Tooltip>
        </div>
      </div>
    </LayoutCard>
  );
}
