import { Children } from "react";
import SelectionCustomer from "screens-v2/cash-flow/components/selections/selection-customer2";
import SelectionSuppliers from "screens-v2/cash-flow/components/selections/selection-suppliers2";

export const EnumAccountTypeMapping = {
  Cash: 1,
  Bank: 2,
};

export const EnumIdObjectiveMapping = {
  Cash: 1,
  Bank: 2,
  Customer: 3,
  Supplier: 4,
  Carrier: 5,
  Other: 6,
};

export const InventoryModeEnum = {
  standard: 1,
  advance: 2,
};

export const conditionReasonToRenderSelect = {
  InternalTransferMoney: "Luân chuyển tiền nội bộ",
  WithdrawMoney: "Rút/Chuyển khoản",
};

export const accountTypes = [
  {
    id: EnumAccountTypeMapping.Cash,
    isSystem: true,
    name: "Tiền mặt",
  },
  {
    id: EnumAccountTypeMapping.Bank,
    isSystem: true,
    name: "Ngân hàng",
  },
];

export const objectiveList = [
  {
    id: EnumIdObjectiveMapping.Customer,
    name: "Khách hàng",
    isSearch: true,
    displayName: null,
  },
  {
    id: EnumIdObjectiveMapping.Supplier,
    name: "Nhà cung cấp",
    isSearch: false,
    displayName: null,
  },
  {
    id: EnumIdObjectiveMapping.Cash,
    name: "Quỹ tiền mặt",
    isSearch: false,
    displayName: "Chi nhánh",
  },
  {
    id: EnumIdObjectiveMapping.Other,
    name: "Khác",
    isSearch: false,
    displayName: "Đối tượng",
  },
  {
    id: EnumIdObjectiveMapping.Bank,
    name: "Tài khoản ngân hàng",
    isSearch: false,
    displayName: null,
  },
];

export const bankAccountList = [
  { name: "(ACB) Á Châu", value: "ACB" },
  { name: "(TPBANK) Tiên Phong", value: "TPBANK" },
  { name: "(DONGABANK) Đông Á", value: "DONGABANK" },
  { name: "(SEABANK) Đông Nam Á", value: "SEABANK" },
  { name: "(ABBANK) An Bình", value: "ABBANK" },
  { name: "(BACA) Bắc Á", value: "BACA" },
  { name: "(VIETCAPITALBANK) Bản Việt", value: "VIETCAPITALBANK" },
  { name: "(MSB) Hàng hải Việt Nam", value: "MSB" },
  { name: "(TECHCOMBANK) Kỹ Thương Việt Nam", value: "TECHCOMBANK" },
  { name: "(KIENLONGBANK) Kiên Long", value: "KIENLONGBANK" },
  { name: "(NAMABANK) Nam Á", value: "NAMABANK" },
  { name: "(NCB) Quốc Dân", value: "NCB" },
  { name: "(VPBANK) Việt Nam Thịnh Vượng", value: "VPBANK" },
  { name: "(HDBANK) Phát triển TP.HCM", value: "HDBANK" },
  { name: "(OCB) Phương Đông", value: "OCB" },
  { name: "(MB) Quân đội", value: "MB" },
  { name: "(PVCOMBANK) Đại chúng", value: "PVCOMBANK" },
  { name: "(VIB) Quốc tế Việt Nam", value: "VIB" },
  { name: "(SCB) Sài Gòn", value: "SCB" },
  { name: "(SAIGONBANK) Sài Gòn Công Thương", value: "SAIGONBANK" },
  { name: "(SHB) Sài Gòn - Hà Nội", value: "SHB" },
  { name: "(SACOMBANK) Sài Gòn Thương Tín", value: "SACOMBANK" },
  { name: "(VIETABANK) Việt Á", value: "VIETABANK" },
  { name: "(BAOVIETBANK) Bảo Việt", value: "BAOVIETBANK" },
  { name: "(VIETBANK) Việt Nam Thương Tín", value: "VIETBANK" },
  { name: "(PGBANK) Xăng Dầu Petrolimex", value: "PGBANK" },
  { name: "(EXIMBANK) Xuất Nhập khẩu Việt Nam", value: "EXIMBANK" },
  { name: "(LIENVIETPOSTBANK) Bưu điện Liên Việt", value: "LIENVIETPOSTBANK" },
  { name: "(VIETCOMBANK) Ngoại thương Việt Nam", value: "VIETCOMBANK" },
  { name: "(VIETINBANK) Công Thương Việt Nam", value: "VIETINBANK" },
  { name: "(BIDV) Đầu tư và Phát triển Việt Nam", value: "BIDV" },
  { name: "(VDB) Phát triển Việt Nam", value: "VDB" },
  { name: "(CB) Xây dựng Việt Nam", value: "CB" },
  { name: "(OCEANBANK) Đại Dương", value: "OCEANBANK" },
  { name: "(GPBANK) Dầu Khí Toàn Cầu", value: "GPBANK" },
  {
    name: "(AGRIBANK) Nông nghiệp và Phát triển Nông thôn Việt Nam",
    value: "AGRIBANK",
  },
  { name: "(ANZ) ANZ Việt Nam", value: "ANZ" },
  { name: "(DEUTSCHEBANK) Deutsche Bank Việt Nam", value: "DEUTSCHEBANK" },
  { name: "(CITIBANK) Citibank Việt Nam", value: "CITIBANK" },
  { name: "(HSBC) Hongkong and Shanghai Bank", value: "HSBC" },
  {
    name: "(STANDARDCHARTERED) Standard Chartered Việt Nam",
    value: "STANDARDCHARTERED",
  },
  { name: "(SHINHAN) Shinhan Bank Việt Nam", value: "SHINHAN" },
  { name: "(HLBANK) Hong Leong Việt Nam", value: "HLBANK" },
  { name: "(BIDC) Đầu tư và Phát triển Campuchia", value: "BIDC" },
  { name: "(MIZUHO) Mizuho Corporate Bank", value: "MIZUHO" },
  { name: "(SMBC) Sumitomo Mitsui Bank", value: "SMBC" },
  { name: "(PBVN) Public Việt Nam", value: "PBVN" },
  { name: "(ICBC) Công Thương Trung Quốc (ICBC)", value: "ICBC" },
  { name: "Khác", value: "OTHER" },
];

export const PaymentMethods = [
  {
    id: 1,
    name: "Thẻ",
  },
  {
    id: 2,
    name: "Chuyển khoản",
  },
];

export const PaymentVoucherFieldType = {
  selection: "selection",
  text: "text",
  number: "number",
};

export const PaymentVoucherFieldModels = ({
  formData: data,
  bankList,
  reasons,
  objectives,
  locations,
  objectOthers,
}) => {
  return {
    "Chi vào quỹ": {
      name: "accountTypeId",
      placeHolder: "",
      type: PaymentVoucherFieldType.selection,
      options: accountTypes,
      isHidden: false,
      hasCreateNew: false,
      showSearch: true,
      disabled: false,
    },
    "Tài khoản chi": {
      name: "accountId",
      placeHolder: "Chọn tài khoản chi",
      type: PaymentVoucherFieldType.selection,
      options: bankList?.filter((bank) => bank.id !== data["objectId"]),
      isHidden: data["accountTypeId"] !== EnumAccountTypeMapping.Bank,
      hasCreateNew: true,
      showSearch: true,
      disabled: false,
      hasPullCol: true,
      hasEdit: true,
    },
    "Số tiền chi": {
      name: "amount",
      placeHolder: "Số tiền chi",
      type: PaymentVoucherFieldType.number,
      options: [],
      isHidden: false,
      hasCreateNew: true,
      showSearch: false,
      disabled: false,
    },
    "Lý do chi": {
      name: "subTypeId",
      placeHolder: "Chọn lý do chi",
      type: PaymentVoucherFieldType.selection,
      options:
        data["accountTypeId"] === EnumAccountTypeMapping.Bank
          ? reasons.filter((reason) => reason?.id !== 1)
          : reasons,
      isHidden: false,
      hasCreateNew: true,
      showSearch: true,
      disabled: false,
      hasEdit: true,
    },
    "Đối tượng nhận": {
      name: "objectTypeId",
      placeHolder: "Chọn đối tượng nhận",
      type: PaymentVoucherFieldType.selection,
      options: objectives,
      isHidden: false,
      hasCreateNew: false,
      showSearch: false,
      disabled: !data["subTypeId"],
    },
    "Tài khoản nhận": {
      name: "objectId",
      placeHolder: "Chọn tài khoản nhận",
      type: PaymentVoucherFieldType.selection,
      options: bankList?.filter((bank) => bank.id !== data["accountId"]),
      isHidden: data["objectTypeId"] !== EnumIdObjectiveMapping.Bank,
      hasCreateNew: data["objectTypeId"] === EnumIdObjectiveMapping.Bank,
      showSearch: true,
      disabled: false,
      hasEdit: true,
    },
    "Chi nhánh nhận": {
      name: "objectId",
      placeHolder: "Chọn chi nhánh nhận",
      type: PaymentVoucherFieldType.selection,
      options: locations,
      isHidden: data["objectTypeId"] !== EnumIdObjectiveMapping.Cash,
      hasCreateNew: false,
      showSearch: true,
      disabled: false,
    },
    "Tên đối tượng nhận": {
      name: "objectId",
      placeHolder: "Chọn tên đối tượng nhận",
      type: PaymentVoucherFieldType.selection,
      options:
        data["objectTypeId"] === EnumIdObjectiveMapping.Other
          ? objectOthers
          : [],
      isHidden:
        data["objectTypeId"] === EnumIdObjectiveMapping.Cash ||
        data["objectTypeId"] === EnumIdObjectiveMapping.Bank,
      // hasCreateNew: data["objectTypeId"] === EnumIdObjectiveMapping.Other,
      hasCreateNew: true,
      showSearch: true,
      disabled: !data["objectTypeId"],
      hasEdit: data["objectTypeId"] === EnumIdObjectiveMapping.Other,
      hasCustomRender: (props) => {
        if (data["objectTypeId"] === EnumIdObjectiveMapping.Supplier) {
          return <SelectionSuppliers {...props} />;
        } else if (data["objectTypeId"] === EnumIdObjectiveMapping.Customer) {
          return <SelectionCustomer {...props}> {Children} </SelectionCustomer>;
        }
        return null;
      },
    },
    "Phương thức thanh toán": {
      name: "paymentMethod",
      placeHolder: "Chọn phương thức thanh toán",
      type: PaymentVoucherFieldType.selection,
      options: PaymentMethods,
      isHidden: !(
        data["objectTypeId"] &&
        data["accountTypeId"] === EnumAccountTypeMapping.Bank &&
        data["objectTypeId"] !== EnumIdObjectiveMapping.Cash
      ),
      hasCreateNew: false,
      showSearch: false,
      disabled: data["objectTypeId"] === EnumIdObjectiveMapping.Bank,
    },
  };
};

export const CashReceiptFieldModels = ({
  formData: data,
  bankList,
  reasons,
  objectives,
  locations,
  objectOthers,
}) => {
  return {
    "Thu vào quỹ": {
      name: "accountTypeId",
      placeHolder: "",
      type: PaymentVoucherFieldType.selection,
      options: accountTypes,
      isHidden: false,
      hasCreateNew: false,
      showSearch: true,
      disabled: false,
    },
    "Tài khoản thu": {
      name: "accountId",
      placeHolder: "Chọn tài khoản thu",
      type: PaymentVoucherFieldType.selection,
      options: bankList?.filter((bank) => bank.id !== data["objectId"]),
      isHidden: data["accountTypeId"] !== EnumAccountTypeMapping.Bank,
      hasCreateNew: true,
      showSearch: true,
      disabled: false,
      hasPullCol: true,
      hasEdit: true,
    },
    "Số tiền thu": {
      name: "amount",
      placeHolder: "Số tiền thu",
      type: PaymentVoucherFieldType.number,
      options: [],
      isHidden: false,
      hasCreateNew: true,
      showSearch: false,
      disabled: false,
    },
    "Lý do thu": {
      name: "subTypeId",
      placeHolder: "Chọn lý do thu",
      type: PaymentVoucherFieldType.selection,
      options:
        data["accountTypeId"] === EnumAccountTypeMapping.Bank
          ? reasons.filter((reason) => reason?.id !== 1)
          : reasons,
      isHidden: false,
      hasCreateNew: true,
      showSearch: true,
      disabled: false,
      hasEdit: true,
    },
    "Đối tượng nộp": {
      name: "objectTypeId",
      placeHolder: "Chọn đối tượng nộp",
      type: PaymentVoucherFieldType.selection,
      options: objectives,
      isHidden: false,
      hasCreateNew: false,
      showSearch: false,
      disabled: !data["subTypeId"],
    },
    "Tài khoản nộp": {
      name: "objectId",
      placeHolder: "Chọn tài khoản nộp",
      type: PaymentVoucherFieldType.selection,
      options: bankList?.filter((bank) => bank.id !== data["accountId"]),
      isHidden: data["objectTypeId"] !== EnumIdObjectiveMapping.Bank,
      hasCreateNew: data["objectTypeId"] === EnumIdObjectiveMapping.Bank,
      showSearch: true,
      disabled: false,
      hasEdit: true,
    },
    "Chi nhánh nộp": {
      name: "objectId",
      placeHolder: "Chọn chi nhánh nộp",
      type: PaymentVoucherFieldType.selection,
      options: locations,
      isHidden: data["objectTypeId"] !== EnumIdObjectiveMapping.Cash,
      hasCreateNew: false,
      showSearch: true,
      disabled: false,
    },
    "Tên đối tượng nộp": {
      name: "objectId",
      placeHolder: "Chọn tên đối tượng nộp",
      type: PaymentVoucherFieldType.selection,
      options:
        data["objectTypeId"] === EnumIdObjectiveMapping.Other
          ? objectOthers
          : [],
      isHidden:
        data["objectTypeId"] === EnumIdObjectiveMapping.Cash ||
        data["objectTypeId"] === EnumIdObjectiveMapping.Bank,
      // hasCreateNew: data["objectTypeId"] === EnumIdObjectiveMapping.Other,
      hasCreateNew: true,
      showSearch: true,
      disabled: !data["objectTypeId"],
      hasEdit: data["objectTypeId"] === EnumIdObjectiveMapping.Other,
      hasCustomRender: (props) => {
        if (data["objectTypeId"] === EnumIdObjectiveMapping.Supplier) {
          return <SelectionSuppliers {...props} />;
        } else if (data["objectTypeId"] === EnumIdObjectiveMapping.Customer) {
          return <SelectionCustomer {...props} />;
        }
        return null;
      },
    },
    "Phương thức thanh toán": {
      name: "paymentMethod",
      placeHolder: "Chọn phương thức thanh toán",
      type: PaymentVoucherFieldType.selection,
      options: PaymentMethods,
      isHidden: !(
        data["objectTypeId"] &&
        data["accountTypeId"] === EnumAccountTypeMapping.Bank &&
        data["objectTypeId"] !== EnumIdObjectiveMapping.Cash
      ),
      hasCreateNew: false,
      showSearch: false,
      disabled: data["objectTypeId"] === EnumIdObjectiveMapping.Bank,
    },
  };
};
