import Button from "component-v2/buttons";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import { toNumber } from "lodash";
import { useMemo } from "react";
import { useRefundContext } from "screens-v2/refunds-v2";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { PaymentMethods } from "../Payments/paymentMethods";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_SCOPES } from "models/constants";

export const ModalPaymentMethods = ({
  show,
  setShow,
  totalMoneyPaidByCustomer,
  refundOrder,
  setShowModalPaymentPartial,
  isCod,
}) => {
  const {
    current_shopping_cart,
    isSubmitRefundOrderWaiting,
    current_refund,
    finalPrice,
  } = useRefundContext();

  const payment_methods_active = useMemo(() => {
    return current_shopping_cart?.payment_methods?.filter(
      (method) => method.enable,
    );
  }, [current_shopping_cart?.payment_methods]);

  useGlobalHotkeys({
    keys: [HOTKEYS.F9],
    callback: (e) => {
      refundOrder();
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });

  const body = () => {
    return (
      <div>
        <p className="font-weight-semibold font-size-16">
          {!current_shopping_cart?.isShipping
            ? "Bán tại quầy"
            : "Bán giao hàng"}
        </p>
        <p className="mt-4 font-weight-medium">Chọn phương thức thanh toán</p>
        {show && (
          <PaymentMethods totalMoneyPaidByCustomer={totalMoneyPaidByCustomer} />
        )}
      </div>
    );
  };

  const footer = () => {
    if (
      !current_shopping_cart?.lineItems?.length ||
      current_shopping_cart?.summaries?.total_payment <=
        current_refund?.priceUserRefund
    ) {
      return (
        <Button
          status="warning"
          className="w-100 position-relative"
          onClick={() => {
            refundOrder();
          }}
          disabled={!payment_methods_active?.length}
          badge={HOTKEYS.F9}
          title={` Hoàn trả · ${toFormattedCurrency(Math.abs(finalPrice()))}`}
        ></Button>
      );
    }

    const totalFinalPrice = toNumber(
      Math.abs(
        current_refund?.priceUserRefund +
          current_refund?.refundRedeemAmount -
          current_shopping_cart?.summaries.total_payment,
      ).toFixed(4),
    );

    return (
      <div className="w-100">
        <div className="d-flex pb-4 justify-content-between font-size-16 font-weight-semibold">
          <p>
            {totalMoneyPaidByCustomer >= totalFinalPrice
              ? "Tiền thừa trả khách"
              : "Tính vào công nợ"}{" "}
          </p>
          <p>
            {toFormattedCurrency(
              Math.abs(toNumber(totalMoneyPaidByCustomer) - totalFinalPrice),
            )}
          </p>
        </div>
        <div className="d-flex">
          <Button
            status="primary"
            className="btn-outline-primary w-100 ml-sm-2 font-weight-semibold position-relative"
            onClick={() => {
              refundOrder();
            }}
            disabled={!payment_methods_active?.length}
            badge={HOTKEYS.F9}
            title={
              isCod || !totalMoneyPaidByCustomer
                ? "Đặt hàng"
                : `Thanh toán ·${" "}
            ${toFormattedCurrency(
              totalMoneyPaidByCustomer > totalFinalPrice
                ? Math.abs(totalFinalPrice)
                : totalMoneyPaidByCustomer,
            )}`
            }
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      fixHeight
      headerTitle={"Xác nhận thanh toán"}
      isTitleCenter
      show={show}
      setShow={setShow}
      body={body()}
      footer={footer()}
      size="lg"
      loadingOverlay={isSubmitRefundOrderWaiting}
      onCancel={() => setShow(false)}
    />
  );
};
