import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { map } from "lodash";
import HighchartsReact from "highcharts-react-official";
import { Loading } from "@haravan/react-components";
import { CurrencyUtils } from "utils";
import { FormattedMessage } from "react-intl";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";

export default function TopProductReportChart({ dataSelling, isLoading }) {
  const [chartOptions, setChartOption] = useState(chartOptionsDefault2);

  useEffect(() => {
    onRefreshDataReport(dataSelling);
  }, [dataSelling, isLoading]);

  const onRefreshDataReport = (dataSelling) => {
    try {
      setChartOption({ ...chartOptionsDefault2, series: [] });

      if (
        !dataSelling.highcharts?.series[0].data ||
        !dataSelling.highcharts?.series[0].data.length
      ) {
        setChartOption(chartOptionsDefault);
      } else {
        const category = map(dataSelling.highcharts.series[0].data, "name");
        const chartOptions = { ...chartOptionsDefault2 };
        chartOptions.xAxis.categories = category;
        chartOptions.xAxis.labels.formatter = function () {
          const product = this.value
            ? isNaN(this.value)
              ? this.value?.split("-")
              : this.value
            : null;
          const productName =
            product && product[0] ? product[0].trim("") : "Sản phẩm tùy chọn";
          const variantName = product && product[1] ? product[1].trim("") : "";
          return (
            '<div style="font-size:12px; color: #171717; text-align: center">' +
            productName +
            "</div>" +
            '<div style="font-size:12px; color: #525252; text-align: center">' +
            variantName +
            "</div>"
          );
        };
        setChartOption({
          ...chartOptions,
          tooltip: {
            useHTML: true,
            formatter: function () {
              const name = this.point.name || "";
              const product = name ? name.split("-") : null;
              const productName =
                product && product[0]
                  ? product[0].trim("")
                  : "Sản phẩm tùy chọn";
              const variantName =
                product && product[1] ? product[1].trim("") : "";
              return (
                '<div style="font-size:12px; color: #171717">' +
                productName +
                "</div>" +
                '<p style="font-size:12px; color: #525252; margin-bottom: 6px">' +
                variantName +
                "</p>" +
                '<span style="font-size:12px">' +
                this.y +
                "</span>"
              );
            },
          },
          series: dataSelling.highcharts.series,
        });
      }
    } catch (e) {}
  };
  if (isLoading) {
    return (
      <div className="col-12">
        <LayoutCard
          style={{ height: "450px" }}
          className="product__chart d-flex justify-content-center align-items-center"
        >
          <Loading />
        </LayoutCard>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <LayoutCard className="product__chartV2">
          <div>
            <p className="font-weight-semibold font-size-16 mb-4">
              <FormattedMessage id="reports.Biểu đồ" defaultMessage="Biểu đồ" />
            </p>
            <p className="font-weight-medium text-default p-0">
              <FormattedMessage
                id="reports.Số lượng"
                defaultMessage="Số lượng"
              />
            </p>
          </div>
          <div className="padding-top-16">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        </LayoutCard>
      </div>
    );
  }
}

const chartOptionsDefault = {
  credits: { enabled: false },
  legend: {
    enabled: false,
  },
  chart: {
    ignoreHiddenSeries: false,
    height: 280,
  },
  plotOptions: {
    column: {
      stacking: "normal",
      maxPointWidth: 100,
    },
    series: {
      pointPadding: 0.2,
      borderWidth: 0,
      borderRadius: 4,
    },
  },
  xAxis: {
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    title: { text: "" },
  },
  title: {
    text: null,
  },
  tooltip: {},
  series: [
    {
      data: [0, 1, 2, 3, 4],
      visible: false,
    },
  ],
};

const chartOptionsDefault2 = {
  credits: { enabled: false },
  chart: {
    type: "column",
  },
  xAxis: {
    title: {
      text: null,
    },
    labels: {
      useHTML: true,
      style: { color: "#16325C", fontWeight: "500" },
    },
  },
  colors: ["#F97415"],
  yAxis: {
    min: 0,
    allowDecimals: false,
    title: { text: "" },
    labels: {
      style: { color: "#16325C", fontWeight: "500" },
      formatter: function () {
        return CurrencyUtils.formatMoney(this.value);
      },
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      maxPointWidth: 100,
    },
    series: {
      pointPadding: 0.2,
      borderWidth: 0,
      borderRadius: 4,
    },
  },
  title: {
    text: null,
  },
  tooltip: {},
  series: [],
};
