import React from "react";
import EmptyState from "component-v2/empty-state";

function RefundCartEmpty({intl}) {
  return <EmptyState
    iconName='shopping_cart'
    title=''
    description='Chưa có sản phẩm mua mới'
  />;
}

export {RefundCartEmpty};