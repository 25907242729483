import { HaraLoyalty } from "@haravan/haraloyalty";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  toFormattedCurrency,
  toFormattedNumber,
} from "infrastructures/format-helpers";
import { toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCartContext } from "screens-v2/carts-v2";
import {
  DiscountAllocationType,
  checkValidRedeem,
} from "screens-v2/carts-v2/cart-helpers.service";
import { concatString } from "screens-v2/carts-v2/utils";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { ModalCustomerInfo } from "../components/modalCustomerInfo";
import Tooltip from "rc-tooltip";
import { SYSTEM_GUEST_EMAIL } from "infrastructures/utils";

export const CustomerInfo = ({
  customer,
  removeCustomer,
  showEditAddress,
  setShowEditAddress,
  reloadCustomer,
}) => {
  const { locale, auth } = useApp();
  const appContext = useApp();
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const {
    updateCurrentSession,
    updateCurrentCart,
    current_shopping_cart,
    setLoyaltyLoading,
  } = useCartContext();
  const [editCustomerInfo, setEditCustomerInfo] = useState(false);
  const [loyaltyProfileLoading, setLoyaltyProfileLoading] = useState(true);
  const [customerInfo, setCustomerInfo] = useState(customer);
  const { orgid } = auth.user;

  useEffect(() => {
    if (appContext.shop_loyalty?.enabled) {
      setLoyaltyLoading(true);
    }
  }, []);

  useEffect(() => {
    setCustomerInfo(customer);
  }, [customer]);

  useEffect(() => {
    if (showEditAddress) {
      setEditCustomerInfo(true);
    }
  }, [showEditAddress]);

  const handleLoyaltyProfileCallback = async (loyaltyProfilePayload) => {
    try {
      setLoyaltyProfileLoading(false);
      if (loyaltyProfilePayload && loyaltyProfilePayload.status === 1) {
        const otp_verified =
          loyaltyProfilePayload.data.discover.type === "membership";
        const redeemConfig = {
          ...loyaltyProfilePayload.data.discover,
          otp_verified: otp_verified,
        };
        const loyalty = {
          ...current_shopping_cart.loyalty,
          profile: loyaltyProfilePayload.data,
          redeemConfig,
        };
        updateCurrentCart({
          ...current_shopping_cart,
          loyalty,
        });
        if (loyaltyProfilePayload.data.discover.type !== "membership") {
          checkValidRedeem(appContext, {
            currentCart: {
              ...current_shopping_cart,
              loyalty,
            },
            showGlobalToast,
          });
        }
      }
      setLoyaltyLoading(false);
    } catch (error) {
      setLoyaltyProfileLoading(false);
      setLoyaltyLoading(false);
    }
  };

  const handleUpdateRewardRedemAmount = async (amount) => {
    if (current_shopping_cart) {
      const { lineItems, summaries, loyalty } = current_shopping_cart;
      if (lineItems?.length > 0) {
        const maxAmountLoyalty = summaries.subtotal_price_after_promotion;
        if (amount <= maxAmountLoyalty) {
          if (loyalty?.redeemConfig?.type) {
            updateCurrentSession({
              orderDiscountSelected:
                current_shopping_cart?.orderDiscountSelected,
              loyalty: {
                ...loyalty,
                redeemConfig: {
                  ...loyalty.redeemConfig,
                  otp_verified: true,
                },
                rewardRedeemAmount: toNumber(amount),
              },
            });
          } else {
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: "notifications.Lỗi! Không tìm thấy chương trình khách hàng thân thiết.",
                defaultMessage:
                  "Lỗi! Không tìm thấy chương trình khách hàng thân thiết.",
              }),
            );
          }
        } else {
          showGlobalToast(
            "error",
            intl.formatMessage({
              id: "notifications.Thông báo! Số tiền sử dụng không được lớn hơn tổng giá trị đơn hàng.",
              defaultMessage:
                "Thông báo! Số tiền sử dụng không được lớn hơn tổng giá trị đơn hàng.",
            }),
          );
        }
      } else {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: "notifications.Thông báo! Đơn hàng chưa có sản phẩm nào.",
            defaultMessage: "Thông báo! Đơn hàng chưa có sản phẩm nào.",
          }),
        );
      }
    }
  };

  const handleOnUsingCoupon = async (data) => {
    const isOrderHasProductNotAllowPromotion =
      current_shopping_cart?.lineItems?.some(
        (orderLine) => orderLine.notAllowPromotion,
      );

    const orderLineTotal = current_shopping_cart?.lineItems?.length ?? 0;
    if (
      current_shopping_cart?.lineItems?.length === 1 &&
      isOrderHasProductNotAllowPromotion
    ) {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: "carts.orders.Đơn hàng có chứa sản phẩm không được áp dụng khuyến mãi.",
          defaultMessage:
            "Đơn hàng có chứa sản phẩm không được áp dụng khuyến mãi.",
        }),
      );
    } else if (orderLineTotal === 0) {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: "carts.orders.Thông báo! Đơn hàng chưa có sản phẩm nào.",
          defaultMessage: "Thông báo! Đơn hàng chưa có sản phẩm nào.",
        }),
      );
    } else {
      if (data && data.coupon_code) {
        const orderDiscounts =
          current_shopping_cart?.discountAllocations?.filter(
            (d) =>
              d.type !== DiscountAllocationType.MANUAL_ORDER &&
              d.type !== DiscountAllocationType.MEMBERSHIP &&
              d.type !== DiscountAllocationType.REDEEM_POINT,
          );
        const newCoupons = [
          ...orderDiscounts.map((c) => c.name),
          data.coupon_code.toUpperCase(),
        ];
        const orderDiscountSelected = {
          discountTypeId: 5,
          discountTypeName: "coupon",
          discountAmount: 0,
          discountName: "Mã khuyến mãi",
          discountPercent: 0,
          coupons: newCoupons,
          hasPromotion: current_shopping_cart?.lineItems?.some(
            (orderLine) => orderLine.hasPromotion,
          ),
          isUseCouponCode: true,
          isCouponCode: true,
        };

        updateCurrentSession({
          orderDiscountSelected,
          loyalty: {
            ...current_shopping_cart?.loyalty,
            discountCouponLoyalty: data.coupon_code,
          },
        });
      }
    }
  };

  const loyaltyCardRender = () => {
    if (loyaltyProfileLoading) {
      return (
        <Badge status="info" light className="border-0">
          Đang kiểm tra điểm thưởng...
        </Badge>
      );
    } else {
      if (!current_shopping_cart?.loyalty?.redeemConfig) {
        return null;
      } else {
        if (current_shopping_cart?.loyalty?.redeemConfig.is_error) {
          let errorMsg = "";
          if (
            current_shopping_cart?.loyalty?.redeemConfig.message ===
            "error_loyalty_is_not_registered"
          ) {
            errorMsg = "Loyalty chưa được kích hoạt";
          } else if (
            current_shopping_cart?.loyalty?.redeemConfig.message ===
            "error_customer_not_found"
          ) {
            errorMsg = "Không tìm thấy thông tin khách hàng";
          } else if (
            current_shopping_cart?.loyalty?.redeemConfig.message ===
            "error_customer_not_is_member"
          ) {
            errorMsg = "Chưa là thành viên Loyalty";
          } else {
            errorMsg = "Lỗi kết nối Loyalty";
          }
          return (
            <Badge status="default" light className="mr-3">
              <FormattedMessage
                id={`carts.orders.${errorMsg}`}
                defaultMessage={errorMsg}
              />
            </Badge>
          );
        } else {
          return (
            <Badge status="info" light className="border-0">
              <span>
                {concatString({
                  value: [
                    current_shopping_cart?.loyalty?.profile
                      ?.current_membership_level,
                    `${toFormattedNumber(
                      current_shopping_cart?.loyalty?.profile?.available_points,
                    )} điểm`,
                  ],
                  separator: " • ",
                })}
              </span>
            </Badge>
          );
        }
      }
    }
  };

  const renderCustomerDebt = () => {
    if (customerInfo.email === SYSTEM_GUEST_EMAIL) return null;

    if (customer && customer?.debt === 0) {
      return (
        <Badge status="default" light className="mr-3 border-0">
          <FormattedMessage id="Không có nợ" defaultMessage="Không có nợ" />
        </Badge>
      );
    }

    return (
      <Badge
        status={customer.debt < 0 ? "red" : "green"}
        light
        className="mr-3 border-0"
      >
        <FormattedMessage
          id={"Khách nợ: {x}"}
          defaultMessage={"Khách nợ: {x}"}
          values={{
            x: (
              <span
                className={customer.debt < 0 ? "text-danger" : "text-success"}
              >
                {toFormattedCurrency(-customer.debt)}
              </span>
            ),
          }}
        />
      </Badge>
    );
  };

  return (
    <div>
      <div className="l-section--with-title align-items-center l-pb-8">
        <div>
          {appContext.shop_loyalty?.enabled && customer.id ? (
            <HaraLoyalty
              className="flex-auto"
              config={{
                path: `/call/loy_api/v1`,
                product: "hararetail",
                language: locale.language,
              }}
              customerId={customer.id}
              callback={(data) => {
                handleLoyaltyProfileCallback(data);
              }}
              autoFetch={true}
              onChangeUsingPoint={(data) => {
                handleUpdateRewardRedemAmount(data.amount);
              }}
              organizationId={orgid}
              organizationIdHardcode={orgid}
              onChangeUsingCoupon={(data) => handleOnUsingCoupon(data)}
              key={customer.id}
            />
          ) : (
            <Icon name="avatar" size="lg" className="mr-2" />
          )}
        </div>
        <div
          className="flex-stretch overflow-auto cursor-pointer ml-2"
          onClick={() => setEditCustomerInfo(true)}
        >
          <Tooltip
            placement="bottomLeft"
            trigger="hover"
            overlay={
              <div className="font-weight-regular font-size-12 lh-18">
                <p className="content-on-color-primary l-mb-4">
                  {concatString({
                    value: [customer.lastName, customer.firstName],
                  }) || "--"}
                </p>
                <p className="content-tertiary mb-0 ">
                  {concatString({
                    value: [customer.phone, customer.email],
                    separator: " • ",
                  })}
                </p>
              </div>
            }
          >
            <div>
              <div
                className="content-accent text-truncate"
                title={
                  concatString({
                    value: [customer.lastName, customer.firstName],
                  }) || "--"
                }
              >
                {concatString({
                  value: [customer.lastName, customer.firstName],
                }) || "--"}
              </div>
              <div className="content-secondary font-size-12 text-truncate">
                {concatString({
                  value: [customer.phone, customer.email],
                  separator: " • ",
                })}
              </div>
            </div>
          </Tooltip>
          <div className="d-flex flex-row flex-wrap">
            <div className="mt-1">{renderCustomerDebt()}</div>
            <div className="mt-1">{loyaltyCardRender()}</div>
          </div>
        </div>
        <div
          className="flex-auto cursor-pointer btn-close l-ml-8"
          onClick={removeCustomer}
          style={{ height: "max-content", padding: "0.2rem" }}
        >
          <Icon name="close" />
        </div>
      </div>
      <ModalCustomerInfo
        customerInfo={customerInfo}
        show={editCustomerInfo}
        setShow={(value) => {
          setEditCustomerInfo(value);
          if (!value) setShowEditAddress(value);
        }}
        showEditAddress={showEditAddress}
        reloadCustomer={reloadCustomer}
      />
    </div>
  );
};
