import React, { useState } from "react";
import "./index.css";
import { useApp } from "contexts/app";
import { AppBridgeDetail } from "./app-bridge-detail";
import Button from "component-v2/buttons";

export const AppBridge = () => {
  const { shop_info, apps, callAPI } = useApp();
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="app-bridge">
      {!!apps?.length ? (
        apps?.length <= 3 ? (
          apps.map((app) => {
            return (
              <AppBridgeDetail
                key={`${app.client_id}-${app.tab_id}`}
                appDetail={app}
                shop_info={shop_info}
                callAPI={callAPI}
              />
            );
          })
        ) : (
          <>
            {apps.slice(0, 3)?.map((app) => {
              return (
                <AppBridgeDetail
                  key={`${app.client_id}-${app.tab_id}`}
                  appDetail={app}
                  shop_info={shop_info}
                  callAPI={callAPI}
                />
              );
            })}
            <div className="show-more-dots">
              <Button
                light
                icon="three_dots"
                size="sm"
                onClick={() => setShowMore((prev) => !prev)}
              />
              <div className={`show-more-dots--body${showMore ? " show" : ""}`}>
                {apps.slice(3)?.map((app) => {
                  return (
                    <div className="show-more-dots--item">
                      <AppBridgeDetail
                        key={`${app.client_id}-${app.tab_id}`}
                        appDetail={app}
                        shop_info={shop_info}
                        callAPI={callAPI}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )
      ) : null}
    </div>
  );
};
