import { useApp } from "contexts/app";
import React, { useEffect, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";
import { Input } from "@haravan/react-components";
import { updateOrderNotes } from "api/order-repository";
import { useLayoutContext } from "contexts/Layout/layout-context";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
export const OrderDetailAttributes = ({ order, onSucceed }) => {
  const [show, setShow] = useState(false);
  const [attributes, setAttributes] = useState(null);
  const { callAPI } = useApp();
  const { showGlobalToast } = useLayoutContext();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAttributes([...order?.noteAttributes]);
  }, [order]);
  const validateNoteAttributes = () => {
    var validateElements = document.getElementById("attr-form");
    var inputs = Array.prototype.filter.call(
      validateElements,
      function (element) {
        return element.nodeName === "INPUT";
      },
    );
    let firstErr = null;
    for (var i = 0; i < inputs.length; i++) {
      var input = inputs[i];
      if (
        input?.classList?.contains("attr-form-value") &&
        input.value?.length > 500
      ) {
        if (!input?.classList?.contains("err")) {
          input.classList.add("err");
        }
        if (!firstErr) {
          firstErr = input;
          showGlobalToast(
            "error",
            "Độ dài giá trị thuộc tính không quá 500 ký tự",
          );
        }
      }
    }
    if (firstErr) {
      firstErr.focus();
      return false;
    } else {
      return true;
    }
  };
  async function handleSaveOrderAttributes() {
    if (validateNoteAttributes()) {
      setLoading(true);
      updateOrderNotes(callAPI, order?.orderId, {
        listAttributes: attributes,
        note: order?.orderNotes || "",
      })
        .then((res) => {
          if (res && !(res?.errorCodes || res?.errors || res?.status !== 200)) {
            showGlobalToast("default", "Đã cập nhật thuộc tính");
            onSucceed?.();
          } else {
            if (!res?.status) {
              showGlobalToast(
                "error",
                res?.errors || "Đã có lỗi xảy ra, xin vui lòng thử lại.",
              );
            }
            setAttributes([...order?.noteAttributes]);
          }
          setShow(false);
        })
        .catch(() => {
          showGlobalToast("error", "Đã có lỗi xảy ra, xin vui lòng thử lại.");
          setAttributes([...order?.noteAttributes]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  const body = () => {
    return (
      <form id="attr-form">
        <div className="row">
          <div className="col-12">
            {attributes?.map((attribute, index) => {
              if (attribute.name?.toLowerCase().startsWith("x-haravan-"))
                return null;
              return (
                <div
                  key={`${attribute?.id}`}
                  className="d-flex flex-column l-pt-4 l-mb-16 align-items-center"
                >
                  <label className="margin-0 mb-1 w-100 omni-flex-1 text-left">
                    {attribute?.name}
                  </label>
                  <Input
                    className="attr-form-value flex-4 w-100"
                    placeholder="Nhập giá trị"
                    value={attribute?.value}
                    onChange={(value) => {
                      attributes[index] = {
                        ...attributes[index],
                        value: value,
                      };
                    }}
                    onFocus={(_, e) => e.currentTarget.select()}
                    onBlur={(_, e) => {
                      if (e.currentTarget?.value?.length <= 500) {
                        e.currentTarget?.classList?.remove("err");
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </form>
    );
  };
  const handleCancel = () => {
    setAttributes(
      order?.noteAttributes?.length > 0
        ? [...order?.noteAttributes]
        : [{ name: "", value: "" }],
    );
    setShow(false);
  };
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        handleCancel();
      }
      if (e.key === HOTKEY_EVENTS.F8) {
        handleSaveOrderAttributes();
      }
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  const footer = () => {
    return (
      <>
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <Button
            className="mr-2 light"
            onClick={handleCancel}
            title="Hủy"
            badge={HOTKEYS.ESC}
          />
          <Button
            status="primary"
            disabled={loading}
            onClick={() => handleSaveOrderAttributes()}
            title="Xác nhận"
            badge={HOTKEYS.F8}
          />
        </div>
      </>
    );
  };
  if (order?.noteAttributes?.length > 0 && attributes) {
    const listAttributes = order?.noteAttributes?.filter((attribute) => {
      if (attribute.name?.toLowerCase().startsWith("x-haravan-")) return null;
      return attribute;
    });
    return (
      <div className="flex-fill order-detail-right-section pt-0">
        <div className="l-section--with-title">
          <GlobalModal
            headerTitle={<span>Thuộc tính</span>}
            footer={footer()}
            onCancel={handleCancel}
            body={body()}
            show={show}
            setShow={setShow}
            fixHeight={true}
          >
            <label
              className="flex-auto content-accent cursor-pointer text-truncate align-self-start"
              onClick={() => setShow(true)}
            >
              {`Thuộc tính (${listAttributes?.length})`}
            </label>
          </GlobalModal>
          <div className="l-section--body">
            {listAttributes?.length > 0 ? (
              listAttributes?.length > 3 ? (
                <>
                  {listAttributes?.slice(0, 3).map((attribute) => (
                    <p
                      key={attribute?.id}
                      style={{ maxWidth: "250px" }}
                      className="content-primary text-truncate"
                    >
                      {`${attribute?.name}: ${attribute?.value}`}{" "}
                    </p>
                  ))}
                  <p>...</p>
                </>
              ) : (
                <>
                  {listAttributes?.map((attribute) => (
                    <p
                      key={attribute?.id}
                      style={{ maxWidth: "250px" }}
                      className="content-primary text-truncate"
                    >
                      {`${attribute?.name}: ${attribute?.value}`}{" "}
                    </p>
                  ))}
                </>
              )
            ) : (
              <span className="content-tertiary">Chưa có thuộc tính</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};
