import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { QuickProducts, CartSearch } from "./Products";
import { RightCart } from "./RightCart";
import { CartList } from "./CartList/CartList/index";
import { MAX_TAB_COUNT, ShoppingCartModel } from "./utils";
import "./index.css";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { cloneDeep, isNumber, toNumber } from "lodash";
import CartFunctions from "./CartList/cart-functions";
import CartTabs from "./cart-tabs";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import { getLotsData } from "../../api/order-repository";
import { getDecimalValue } from "../../utils/Commons";
import {
  paymentMethodsDefault,
  pricingDefault,
  productQuantityMax,
  processingDiscover,
  fillLots,
  SysAttributeKeys,
  ManualDiscountType,
  updateLots,
} from "./cart-helpers.service";
import { PINNED_PRICING_CACHE_KEY, useApp } from "contexts/app";
import { useShift } from "contexts/shift/shift-context";
import _ from "lodash";
import {
  createCheckoutSession,
  deleteCheckoutSession,
  getCheckoutSession,
  getIncompleteCheckoutSessions,
  updateCheckoutSession,
} from "api/checkout-repository";
import { ENDPOINT_GATEWAY, useGetDataSWR } from "api/api-connect-base";
import { useIntl } from "react-intl";
import { ProductCartHelperService } from "screens-v2/carts-v2/Products/product-cart-helper.service";
import { getProductDetail } from "api/product-repository";

const memo = (callback) => {
  const cache = new Map();
  const memoFunc = async (...args) => {
    const selector = JSON.stringify(args);
    if (cache.has(selector)) return cache.get(selector);
    const value = await callback(...args);
    cache.set(selector, value);
    return value;
  };
  memoFunc.clearCache = () => cache.clear();
  return memoFunc;
};

export const memoizedGetProductDetail = memo(getProductDetail);
export default function Cart() {
  const appContext = useApp();
  const intl = useIntl();
  const storeId = parseInt(appContext?.auth?.user?.orgid);
  const layout = useLayoutContext();
  const { checkShiftReady } = useShift();
  const [shopping_carts, setShoppingCarts] = useState([]);
  const [current_shopping_cart, setCurrentShoppingCart] = useState(null);
  const [pricingList, setPricingList] = useState(appContext.defaultPricingList);
  const [isSubmitOrderWaiting, setIsSubmitOrderWaiting] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [new_current_shopping_cart, setNewCurrentShoppingCart] = useState(null);
  const [loyaltyLoading, setLoyaltyLoading] = useState(false);
  const [checkoutSessions, setCheckoutSessions] = useState([]);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalSuccessQR, setShowModalSuccessQR] = useState(false);
  const [showQRPaymentModal, setShowQRPaymentModal] = useState(false);
  const [pinnedPricingList, setPinnedPricingList] = useState(null);
  const productCartHelper = new ProductCartHelperService(
    appContext.shop_setting,
  );
  const methodDefaults = paymentMethodsDefault(appContext.shop_setting);
  const { data: cartSWRData } = useGetDataSWR(
    ENDPOINT_GATEWAY.commerce,
    current_shopping_cart?.id
      ? `/checkouts_v2/session/${current_shopping_cart?.id}`
      : null,
    null,
    { keepPreviousData: true, revalidateOnMount: false },
  );
  const [processedSWR, setProcessedSWR] = useState();
  const [signalRData] = useState();

  useEffect(() => {
    layout.setBreadcrumb([{ title: "Bán hàng", link: null }]);
    layout.setPageTitle("Bán hàng");
    checkShiftReady(true);
    getPinnedPricingList();
    return () => {
      layout.setBreadcrumb(null);
      layout.setPageTitle("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPinnedPricingList = () => {
    let pinnedPricingLists = JSON.parse(
      localStorage.getItem(PINNED_PRICING_CACHE_KEY) || "[]",
    );
    if (!pinnedPricingLists?.length) pinnedPricingLists = [];
    const pinned = pinnedPricingLists?.find(
      (pl) =>
        pl.userId === appContext?.auth?.user?.id &&
        pl.locationId === appContext?.current_location?.id,
    );
    setPinnedPricingList(pinned);
  };

  useEffect(() => {
    if (appContext.staff_users && cartSWRData) {
      if (!cartSWRData.id) {
        resetCurrentCart(current_shopping_cart);
      }
      if (!!cartSWRData?.completedAt) {
        if (!showModalSuccess && !showModalSuccessQR && !showQRPaymentModal) {
          setShowModalSuccess({
            type: "create_order_success",
            data: { dataResult: cartSWRData.order },
          });
        }
        if (
          showQRPaymentModal &&
          cartSWRData?.order?.paymentStatus === "Paid"
        ) {
          setShowQRPaymentModal(false);
          setShowModalSuccess({
            type: "create_order_success",
            data: { dataResult: cartSWRData.order },
          });
        }
        return;
      } else {
        const cart = {
          ...current_shopping_cart,
        };
        processingDiscover({
          currentCart: cart,
          newCart: cartSWRData,
          showGlobalToast: layout.showGlobalToast,
          appContext,
          methodDefaults,
          intl,
        }).then((res) => {
          setProcessedSWR(res);
        });
      }
    }
  }, [cartSWRData]);
  useEffect(() => {
    if (appContext?.auth?.user?.id && appContext.staff_users) {
      getCheckoutSessions(appContext?.auth?.user?.id);
    }
  }, [appContext?.auth?.user?.id, appContext.staff_users]);

  useEffect(() => {
    if (processedSWR && current_shopping_cart) {
      if (current_shopping_cart.id === processedSWR.id) {
        updateCurrentCart({ ...processedSWR });
      }
      setProcessedSWR(null);
    }
  }, [processedSWR, current_shopping_cart]);

  useEffect(() => {
    if (!pricingList?.length) {
      setPricingList(appContext.defaultPricingList);
    }
  }, [appContext.defaultPricingList]);

  async function getCheckoutSessions(userId) {
    try {
      setLoadingCart(true);
      const sessions = await getIncompleteCheckoutSessions(appContext.callAPI, {
        createdUserId: userId,
        locationId: appContext.current_location.id,
      });
      if (!!sessions?.length) {
        const filteredSessions = [];
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i];
          const sessionData = await getCheckoutSession(
            appContext.callAPI,
            session.id,
          );
          if (
            sessionData?.id &&
            !sessionData?.attributes?.find(
              (att) => att?.key === SysAttributeKeys.SYS_REF_ORDER,
            )
          ) {
            filteredSessions.push(sessionData);
          }
        }
        if (!filteredSessions?.length) {
          initNewCart();
          return;
        }
        const cart = {
          ...new ShoppingCartModel(),
          title: "Đơn 1",
          isReady: true,
        };
        const res = await processingDiscover({
          currentCart: cart,
          newCart: filteredSessions[0],
          showGlobalToast: layout.showGlobalToast,
          appContext,
          methodDefaults,
          intl,
        });
        const carts = filteredSessions?.map((session, index) => ({
          ...(session.id === res?.id
            ? {
                ...res,
              }
            : {
                ...new ShoppingCartModel(),
                ...session,
                title: "Đơn " + (index + 1),
              }),
          id: session.id,
        }));
        setShoppingCarts(carts);
        setCheckoutSessions(filteredSessions);
        setCurrentShoppingCart({ ...res });
      } else {
        initNewCart();
      }
    } catch (error) {
      initNewCart();
    } finally {
      setLoadingCart(false);
    }
  }

  useEffect(() => {
    if (new_current_shopping_cart) {
      checkCartDiscover(new_current_shopping_cart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_current_shopping_cart]);

  async function resetCurrentCart(currentCart) {
    let new_cart = new ShoppingCartModel();
    const pinnedPricingListExists =
      pinnedPricingList &&
      pricingList?.some((list) => list.id === pinnedPricingList?.id);
    updateCurrentCart({
      ...currentCart,
      id: null,
      lineItems: [],
      items: [],
      orderDiscountSelected: null,
      pricingApplied: pinnedPricingListExists
        ? pinnedPricingList
        : pricingDefault,
      summaries: new_cart.summaries,
      loyalty: {
        ...currentCart.loyalty,
        redeemUsedAmount: 0,
        rewardRedeemAmount: 0,
      },
      loyalty_program: {
        ...currentCart.loyalty_program,
        used_amount: 0,
      },
      customer: null,
    });
    if (currentCart?.id) {
      deleteCheckoutSession(appContext.callAPI, currentCart?.id).catch(
        (err) => {},
      );
    }
  }

  function removeAllSessionProduct() {
    updateCurrentSession({ lineItems: [] });
  }
  function initNewCart() {
    let new_cart = new ShoppingCartModel();
    const prefix = "Đơn ";
    for (let i = 1; i <= MAX_TAB_COUNT; i++) {
      let _title = prefix + i;
      const _idx = shopping_carts.findIndex((c) => c.title === _title);
      new_cart.title = _title;
      if (_idx === -1) {
        break;
      }
    }
    let pinnedPricingLists = JSON.parse(
      localStorage.getItem(PINNED_PRICING_CACHE_KEY) || "[]",
    );
    const data = appContext.defaultPricingList;
    setPricingList(appContext.defaultPricingList);
    if (!pinnedPricingLists?.length) pinnedPricingLists = [];
    const pinned = pinnedPricingLists?.find(
      (pl) =>
        pl.userId === appContext?.auth?.user?.id &&
        pl.locationId === appContext?.current_location?.id,
    );
    const isPinnedActive = pinned && data?.some((pl) => pl.id === pinned.id);

    let current_cart = {
      ...new_cart,
      payment_methods: cloneDeep(methodDefaults),
      pricingApplied: isPinnedActive ? pinned : pricingDefault,
      salesMan: {
        id: appContext?.auth?.user?.id,
        name: appContext?.auth?.user?.name,
      },
    };
    setCurrentShoppingCart(current_cart);
    setShoppingCarts((shopping_carts) => [...shopping_carts, current_cart]);
  }

  async function removeCart(key, sessionId) {
    if (shopping_carts.length <= 1) return;
    let carts = [...shopping_carts];
    let index = carts.findIndex((cart) => cart.key === key);
    if (!index === -1) return;

    if (carts[index].key === current_shopping_cart.key) {
      if (carts.length <= index + 1) switchCart(carts[index - 1].key);
      else switchCart(carts[index + 1].key);
    }
    if (sessionId) {
      await deleteCheckoutSession(appContext.callAPI, sessionId).catch(
        (err) => {},
      );
    }
    carts.splice(index, 1);
    setShoppingCarts(carts);
  }
  async function switchCart(cart_key) {
    const nextIndex = shopping_carts.findIndex((c) => c.key === cart_key);
    if (nextIndex === -1) return;
    const carts = [...shopping_carts];
    let next_cart = carts[nextIndex];
    if (!!next_cart.id && !next_cart.isReady) {
      setLoadingCart(true);
      const sessionData = await getCheckoutSession(
        appContext.callAPI,
        next_cart.id,
      );
      if (!!sessionData?.completedAt) {
        resetCurrentCart(current_shopping_cart, sessionData?.id);
        setLoadingCart(false);
        return;
      } else {
        const cart = {
          ...next_cart,
          isReady: true,
        };
        const res = await processingDiscover({
          currentCart: cart,
          newCart: sessionData,
          showGlobalToast: layout.showGlobalToast,
          appContext,
          methodDefaults,
          intl,
        });
        carts[nextIndex] = { ...res };
        setCurrentShoppingCart(res);
        setShoppingCarts(carts);
        setLoadingCart(false);
      }
    } else {
      setCurrentShoppingCart(next_cart);
    }
    memoizedGetProductDetail.clearCache();
  }
  function updateCurrentCart({ ...args }) {
    if (
      args?.key &&
      args?.key !== current_shopping_cart?.key &&
      args?.title !== current_shopping_cart?.title
    )
      return;
    const newCurrentCart = {
      ...current_shopping_cart,
      ...args,
      fields: args ? Object.keys(args) : [],
    };
    setCurrentShoppingCart(newCurrentCart);
    let carts = [...shopping_carts];
    let index = carts.findIndex((cart) => {
      return cart.key === current_shopping_cart.key;
    });
    if (index === -1) return;
    carts[index] = cloneDeep(newCurrentCart);
    setShoppingCarts(carts);
  }
  async function addBulkItemCurrentCart(lineItems = []) {
    if (!lineItems?.length) return;
    let _current_cart = { ...current_shopping_cart };
    for (let i = 0; i < lineItems.length; i++) {
      const item = lineItems[i];
      if (item.units && item.units.length) {
        item.selectedUnit = item.units.find((u) => u.base);
        item.variantUnitId = item.selectedUnit?.id;
      }
      if (!productCartHelper.validateRuleCart(item)) {
        layout.showGlobalToast(
          "error",
          intl.formatMessage({
            id: "notifications.Sản phẩm đã hết hàng!",
            defaultMessage: "Sản phẩm đã hết hàng!",
          }),
        );
        continue;
      }
      item.key = [item.productId, item.id, item.variantUnitId]
        .filter(Boolean)
        .join("-");
      if (!_current_cart) _current_cart = {};
      if (_current_cart && !_current_cart.lineItems)
        _current_cart.lineItems = [];
      let index = _current_cart.lineItems?.findIndex((line) => {
        return line.variantId === item.id && line.productId === item.productId;
      });
      if (index > -1) {
        _current_cart.lineItems[index].quantity += item.quantityImport;
      } else {
        item.quantity = item.quantityImport;
        _current_cart.lineItems.unshift({
          productId: item.productId,
          variantId: item.id,
          variantTitle: item.variantTitle,
          variantUnitId: item.variantUnitId,
          name: item.productTitle,
          quantity: item.quantity,
          price: item.price,
          imageUrl: item.imageUrl,
          autoFillLot: true,
        });
      }
      _current_cart.itemsOriginal = _current_cart.lineItems || [];
    }
    setNewCurrentShoppingCart({ ..._current_cart, fields: ["lineItems"] });
  }
  async function addBulkItemPromotions(lineItems = []) {
    if (!lineItems?.length) return;
    let _current_cart = { ...current_shopping_cart };
    for (let i = 0; i < lineItems.length; i++) {
      const item = lineItems[i];
      item.key = [item.productId, item.id, item.variantUnitId]
        .filter(Boolean)
        .join("-");
      if (!_current_cart) _current_cart = {};
      if (_current_cart && !_current_cart.lineItems)
        _current_cart.lineItems = [];
      let index = _current_cart.lineItems.findIndex((line) => {
        return line.variantId === item.id && line.productId === item.productId;
      });
      if (index > -1) {
        _current_cart.lineItems = _current_cart.lineItems?.map((l) => {
          if (l.variantId === item.id && l.productId === item.productId) {
            l.quantity = 0;
          }
          return l;
        });
        _current_cart.lineItems[index].quantity = item.quantity;
      } else if (item.quantity > 0) {
        _current_cart.lineItems.unshift({
          productId: item.productId,
          variantId: item.id,
          variantTitle: item.variantTitle,
          variantUnitId: item.variantUnitId,
          name: item.productTitle,
          quantity: item.quantity,
          price: item.price,
          imageUrl: item.imageUrl,
          autoFillLot: true,
        });
      }
      _current_cart.itemsOriginal = _current_cart.lineItems || [];
    }
    setNewCurrentShoppingCart({ ..._current_cart, fields: ["lineItems"] });
  }
  async function addItemCurrentCart(item, quantity = 1) {
    let fields = ["lineItems"];
    item.key = [item.productId, item.id, item.variantUnitId]
      .filter(Boolean)
      .join("-");
    let _current_cart = { ...current_shopping_cart };
    if (!_current_cart) _current_cart = {};
    if (_current_cart && !_current_cart.lineItems) _current_cart.lineItems = [];
    let isScanBarcode =
      appContext.shop_setting.isBarcodePatternCustom && item.lotNo?.length > 0;
    let lotsData = [];
    let index = _current_cart.lineItems.findIndex((line) => {
      return line.variantId === item.id && line.productId === item.productId;
    });

    // Check lô có tồn tại hay không
    if (item.lotSupport) {
      let isHasLot =
        index > -1 && _current_cart.lineItems[index].lots?.length > 0;
      if (index > -1) lotsData = _current_cart.lineItems[index].lotsData;

      if (
        (isScanBarcode && index === -1) ||
        (index > -1 && isHasLot && lotsData?.length === 0)
      ) {
        lotsData = await getLotsData(appContext.callAPI, item, appContext);
      }
      // Nếu có lotNo mà không có tồn tại trong lotsData thì không thêm vào giỏ hàng
      if (isScanBarcode) {
        let existLot = lotsData?.find((l) => l.lotNo === item.lotNo);
        if (!existLot) {
          return;
        }
      }
    }
    if (!lotsData) lotsData = [];

    if (index > -1) {
      _current_cart.lineItems[index].quantity += quantity;
      if (item.lotSupport) {
        _current_cart.lineItems[index].lotsData = lotsData;
        _current_cart.lineItems[index].lots = updateLots(
          _current_cart.lineItems[index].lots,
          _current_cart.lineItems[index].lotsData,
          getDecimalValue(
            appContext.shop_info,
            _current_cart.lineItems[index].quantity,
          ),
          appContext.shop_info,
          item.lotNo,
        );
        _current_cart.lineItems[index].fields = ["quantity"];
      }
    } else {
      item.quantity = quantity;
      if (item.lotSupport) {
        item.lotsData = lotsData;
        item.lots = fillLots(lotsData, 1, appContext.shop_info, item.lotNo);
        let lotSelected = item.lots?.find((x) => (x.lotNo = item.lotNo));
        if (lotSelected) {
          item.attributes = [
            {
              key: SysAttributeKeys.SYS_LINE_LOTS,
              value: JSON.stringify([{ id: lotSelected.id, quantity: 1 }]),
            },
          ];
        }
      }
      if (_current_cart.lineItems?.length >= 200) {
        layout.showGlobalToast(
          "error",
          intl.formatMessage(
            {
              id: `notifications.Số lượng sản phẩm quá lớn: {a}/200 sản phẩm`,
              defaultMessage: "Số lượng sản phẩm quá lớn: {a}/200 sản phẩm",
            },
            { a: _current_cart.lineItems?.length + 1 },
          ),
        );
        return;
      } else {
        _current_cart.lineItems.unshift({
          productId: item.productId,
          variantId: item.id,
          variantTitle: item.variantTitle,
          variantUnitId: item.variantUnitId,
          name: item.productTitle,
          quantity: item.quantity,
          price: item.price,
          imageUrl: item.imageUrl,
          autoFillLot: true,
          attributes: item.attributes,
        });
      }
    }
    _current_cart.itemsOriginal = _current_cart.lineItems || [];
    setNewCurrentShoppingCart({ ..._current_cart, fields: fields });
  }

  function removeItemCurrentCart(id, index) {
    let _current_cart = { ...current_shopping_cart };
    if (
      _current_cart?.lineItems &&
      _current_cart.lineItems[index] &&
      _current_cart.lineItems[index].id === id
    )
      _current_cart.lineItems[index].quantity = 0;
    if (
      _current_cart?.itemsOriginal &&
      _current_cart.itemsOriginal[index] &&
      _current_cart.itemsOriginal[index].id === id
    )
      _current_cart.itemsOriginal.splice(index, 1);
    setNewCurrentShoppingCart({ ..._current_cart, fields: ["lineItems"] });
  }

  async function updateCurrentSession({ ...args }) {
    setNewCurrentShoppingCart({
      ...current_shopping_cart,
      ...args,
      fields: args ? Object.keys(args) : [],
    });
  }

  function updateItemCurrentCart(
    orderLine,
    orderLineIndex,
    quantity,
    updateFields = [],
    autoFillLot = true,
  ) {
    let _quantity = toNumber(quantity);
    if (_quantity === 0) {
      _quantity = 1;
    } else if (_quantity > productQuantityMax) {
      _quantity = productQuantityMax;
      autoFillLot = true;
    }
    let currentOrderLines = _.cloneDeep(current_shopping_cart?.lineItems);
    let currentOrderLinesOriginal = _.cloneDeep(
      current_shopping_cart?.itemsOriginal,
    );

    if (!currentOrderLines) currentOrderLines = [];
    if (!currentOrderLinesOriginal) currentOrderLinesOriginal = [];

    currentOrderLines[orderLineIndex] = {
      ...orderLine,
      quantity: _quantity,
      discountAmount: orderLine.discountAmountPerLine * _quantity,
      isCalculatePromotion: true,
      lots:
        orderLine.lotSupport && autoFillLot && orderLine.lots
          ? updateLots(
              orderLine.lots,
              orderLine.lotsData,
              _quantity,
              appContext.shop_info,
            )
          : orderLine.lots,
      autoFillLot: autoFillLot,
      fields: updateFields,
    };

    currentOrderLinesOriginal[orderLineIndex] = {
      ...currentOrderLinesOriginal[orderLineIndex],
      quantity: _quantity,
      discountAmount: orderLine.discountAmountPerLine * _quantity,
      isCalculatePromotion: true,
      lots:
        orderLine.lotSupport && autoFillLot && orderLine.lots
          ? updateLots(
              orderLine.lots,
              orderLine.lotsData,
              _quantity,
              appContext.shop_info,
            )
          : orderLine.lots,
      autoFillLot: autoFillLot,
    };
    const _current_shopping_cart = _.cloneDeep(current_shopping_cart);
    setNewCurrentShoppingCart({
      ..._current_shopping_cart,
      lineItems: currentOrderLines,
      itemsOriginal: currentOrderLinesOriginal,
      fields: ["lineItems"],
    });
    resetOrderLineErr();
  }

  const resetOrderLineErr = () => {
    updateCurrentCart({ orderLineErr: null });
  };

  const resetPayment = () => {
    updateCurrentCart({ payment_methods: methodDefaults });
  };

  const checkCartDiscover = async (currentCart) => {
    try {
      const oldCard = shopping_carts?.find(
        (cart) => cart.key === currentCart.key,
      );
      let newCurrentCart = cloneDeep(currentCart);
      let { fields: updateFields } = newCurrentCart;
      const location = appContext.current_location;
      let result = newCurrentCart;
      const oldLines = cloneDeep(oldCard.lineItems || []);
      let lineUpdate = [];
      const lineRemoved = newCurrentCart.lineItems?.filter(
        (line) => line.quantity === 0,
      );
      if (lineRemoved?.length) {
        lineRemoved.map((line) =>
          lineUpdate.push({ id: line.id, quantity: 0 }),
        );
      }
      const lineItems = newCurrentCart.lineItems?.filter(
        (item) => item.quantity > 0,
      );
      const currentOrderLines = lineItems?.map((line, index) => {
        let item = oldLines?.find((oldLine) => oldLine.id === line.id);
        line.attributes = line.attributes || [];
        let newItem = {
          ...(line.id && { id: line.id }),
          ...(!line.variantId &&
            !line.productTitle && {
              name: line.productName,
            }),
          ...(line.productId && {
            productId: line.productId,
          }),
          ...(line.variantId && {
            variantId: line.variantId,
          }),
          ...(line.variantId &&
            line.variantUnitId && {
              variantUnitId: line.variantUnitId,
            }),
          quantity: line.quantity,
          ...((isNumber(line.discountAmountPerLine) || !line.variantId) && {
            price: line.variantId
              ? Number(
                  (
                    line.originalPrice -
                    (isNumber(line.discountAmountPerLine)
                      ? line.discountAmountPerLine
                      : 0)
                  ).toFixed(4),
                )
              : line.price,
          }),
          ...(line.isShipping !== null && {
            requiresShipping: line.requiresShipping || line.isShipping,
          }),
          ...(line.discountValue && {
            discountType:
              line.discountType === "percent"
                ? ManualDiscountType.Percent
                : ManualDiscountType.Amount,
            discountValue: line.discountValue,
          }),
          ...(line.attributes?.length && {
            attributes: line.attributes,
          }),
          seq: index,
        };
        let updatedProperties = {};
        if (item && item.id) {
          if ((item.name || null) !== (newItem.name || null)) {
            updatedProperties.name = line.productName;
          }
          if (item.productId != newItem.productId) {
            updatedProperties.productId = newItem.productId;
          }
          if (item.variantId != newItem.variantId) {
            updatedProperties.variantId = newItem.variantId;
          }
          if (
            line?.fields?.includes("price") ||
            item.variantUnitId != newItem.variantUnitId
          ) {
            updatedProperties.variantUnitId = newItem.variantUnitId;
          }
          if (
            line?.fields?.includes("variantUnitId") ||
            line?.fields?.includes("quantity") ||
            item.quantity != newItem.quantity ||
            lineRemoved.length > 0
          ) {
            updatedProperties.quantity = newItem.quantity;
            updatedProperties.variantUnitId = newItem.variantUnitId;
          }
          if (line?.fields?.includes("quantity") && line.lotSupport) {
            let lotIndex = line.attributes?.findIndex(
              (att) => att.key === SysAttributeKeys.SYS_LINE_LOTS,
            );
            if (lotIndex > -1) {
              line.attributes[lotIndex] = {
                key: SysAttributeKeys.SYS_LINE_LOTS,
                value: JSON.stringify(
                  !!line?.lots?.length
                    ? line?.lots?.map((l) => ({
                        id: l.id,
                        quantity: l.quantity,
                        lotNo: l.lotNo,
                      }))
                    : [],
                ),
              };
              updatedProperties.attributes = line.attributes;
            } else {
              line.attributes.push({
                key: SysAttributeKeys.SYS_LINE_LOTS,
                value: JSON.stringify(
                  !!line?.lots?.length
                    ? line?.lots?.map((l) => ({
                        id: l.id,
                        quantity: l.quantity,
                        lotNo: l.lotNo,
                      }))
                    : [],
                ),
              });
              updatedProperties.attributes = line.attributes;
            }
          }
          if (line?.fields?.includes("attributes")) {
            updatedProperties.attributes = line.attributes;
          }
          if (
            line?.fields?.includes("price") ||
            (item.discountValue || "") != (line.discountValue || "")
          ) {
            updatedProperties = {
              ...updatedProperties,
              priceReason: line.discountDescription || "",
              discountType: newItem.discountType,
              discount: line.discountValue,
            };
          }
          if (item.seq != newItem.seq) {
            updatedProperties.seq = index;
            updatedProperties.quantity = newItem.quantity;
            updatedProperties.variantUnitId = newItem.variantUnitId;
          }
          if (item.requiresShipping !== newItem.requiresShipping) {
            updatedProperties.requiresShipping = newItem.requiresShipping;
          }
        } else {
          lineUpdate.push({
            id: line.id,
            ...newItem,
          });
        }
        if (Object.keys(updatedProperties).length > 0) {
          lineUpdate.push({
            id: line.id,
            ...updatedProperties,
          });
        }
        return newItem;
      });
      const totalQuantity = currentOrderLines?.reduce(
        (sum, cur) => sum + cur.quantity,
        0,
      );
      let newLineItems;
      if (
        updateFields?.includes("lineItems") ||
        updateFields?.includes("payment_methods")
      ) {
        newLineItems = {
          lineItems: lineUpdate || [],
          ...(totalQuantity === 0 && {
            shippingMethodId: 0,
            shipping: 0,
            shippingMethodName: "",
            discount: 0,
          }),
          ...(!oldLines?.length &&
            newCurrentCart.customerId && {
              customerId: newCurrentCart.customerId,
            }),
        };
      }
      let newDiscount;
      if (updateFields.includes("orderDiscountSelected")) {
        if (updateFields.includes("loyalty")) {
          const { loyalty } = newCurrentCart;
          newDiscount = {
            redeemPoint: loyalty?.rewardRedeemAmount,
          };
        }
        if (
          newCurrentCart.orderDiscountSelected?.discountTypeName === "amount" ||
          newCurrentCart.orderDiscountSelected?.discountTypeName === "percent"
        ) {
          let discountValue =
            newCurrentCart.orderDiscountSelected?.discountTypeName === "percent"
              ? newCurrentCart.orderDiscountSelected?.discountPercent || 0
              : newCurrentCart.orderDiscountSelected?.discountAmount || 0;
          if (discountValue == undefined) {
            discountValue = 0;
          } else {
            discountValue = Number(discountValue);
          }
          newDiscount = {
            ...newDiscount,
            discount: discountValue,
            discountReason: "ORDER DISCOUNT",
            discountType:
              newCurrentCart.orderDiscountSelected?.discountTypeName ===
              "percent"
                ? ManualDiscountType.Percent
                : ManualDiscountType.Amount,
            coupons: [],
          };
        } else if (
          newCurrentCart.orderDiscountSelected?.discountTypeName === "coupon" ||
          !newCurrentCart.orderDiscountSelected?.discountTypeName
        ) {
          newDiscount = {
            ...newDiscount,
            discount: 0,
            coupons: newCurrentCart.orderDiscountSelected?.coupons || [],
          };
        }
      }

      const paymentMethodActive =
        newCurrentCart?.payment_methods?.find(
          (method) => method.enable && !method.isHidden,
        )?.name || "Tiền mặt";
      const sessionId = newCurrentCart?.id;

      if (!sessionId) {
        setLoadingCart(true);
        const newCoupons =
          newCurrentCart.orderDiscountSelected?.discountTypeName === "coupon"
            ? newCurrentCart.orderDiscountSelected?.coupons
            : [];
        const eInvoiceInfo = newCurrentCart.taxData?.eInvoiceInfo?.name
          ? {
              ...newCurrentCart.taxData?.eInvoiceInfo,
              submit: true,
            }
          : null;
        await createCheckoutSession(appContext.callAPI, {
          storeId: storeId,
          channel: "pos",
          ...((newCurrentCart.customer?.key || newCurrentCart.customer?.id) && {
            customerId:
              newCurrentCart.customer?.key || newCurrentCart.customer?.id,
            fullName:
              newCurrentCart.customer?.lastName ||
              newCurrentCart.customer?.firstName
                ? `${newCurrentCart.customer?.lastName || ""} ${
                    newCurrentCart.customer?.firstName || ""
                  }`.trim()
                : "",
            ...(newCurrentCart.customer?.phone && {
              phone: newCurrentCart.customer?.phone,
            }),
            ...(newCurrentCart.customer?.email && {
              email: newCurrentCart.customer?.email,
            }),
          }),
          ...(newCoupons?.length && {
            coupons: newCoupons,
          }),
          ...newDiscount,
          ...(newCurrentCart.noteAttributes &&
            newCurrentCart.noteAttributes?.length && {
              attributes: newCurrentCart.noteAttributes,
            }),
          ...(eInvoiceInfo && {
            eInvoiceInfo: eInvoiceInfo,
          }),
          lineItems: currentOrderLines || [],
          ...(newCurrentCart?.pricingApplied?.id && {
            pricingListId: newCurrentCart?.pricingApplied?.id,
          }),
          ...(newCurrentCart.summaries?.shipping_fee &&
            newCurrentCart.summaries?.shipping_fee > 0 && {
              shipping: newCurrentCart.summaries?.shipping_fee,
            }),
          ...(newCurrentCart.note &&
            newCurrentCart.note?.length && {
              note: newCurrentCart.note,
            }),
          locationId: location?.id,
          createdUserId: appContext?.auth?.user?.id,
          allowPurchaseWhenSoldOut:
            appContext.shop_setting.allowOrderProductOutOfStock,
          saleUserId:
            newCurrentCart.salesMan?.id || newCurrentCart.customer?.id || null,
          paymentMethodName: paymentMethodActive,
        })
          .then((res) => {
            if (res) {
              let sessions = [...checkoutSessions, res];
              setCheckoutSessions(sessions);
              result = { ...res };
            }
          })
          .catch((err) => {});
      } else if (sessionId) {
        const newAttributes = updateFields?.includes("noteAttributes") && {
          attributes: newCurrentCart.noteAttributes || null,
        };
        const newPricingList = updateFields?.includes("pricingApplied") && {
          pricingListId: newCurrentCart?.pricingApplied?.id || null,
        };
        const newShippingFee = updateFields?.includes("summaries") && {
          shipping: newCurrentCart.summaries?.shipping_fee,
          ...(newCurrentCart.summaries?.shipping_fee > 0 ||
          newCurrentCart?.shippingMethodId
            ? {
                shippingMethodId: newCurrentCart?.shippingMethodId,
                ...(!newCurrentCart?.shippingMethodId && {
                  shippingMethodName:
                    newCurrentCart.shippingMethodName ||
                    (newCurrentCart.isShipping
                      ? "Giao hàng sau"
                      : "Tại cửa hàng"),
                }),
              }
            : { shippingMethodId: 0, shippingMethodName: "" }),
        };
        const newOrderNote = updateFields?.includes("note") && {
          note: newCurrentCart.note,
        };
        const newTaxData = updateFields?.includes("taxData") && {
          eInvoiceInfo: {
            ...newCurrentCart.taxData?.eInvoiceInfo,
          },
        };
        const newCustomer = updateFields?.includes("customer")
          ? newCurrentCart?.customer?.key || newCurrentCart?.customer?.id
            ? {
                customerId:
                  newCurrentCart.customer?.key || newCurrentCart.customer?.id,
                fullName:
                  newCurrentCart.customer?.lastName ||
                  newCurrentCart.customer?.firstName
                    ? `${newCurrentCart.customer?.lastName || ""} ${
                        newCurrentCart.customer?.firstName || ""
                      }`.trim()
                    : "",
                phone: newCurrentCart.customer?.phone,
                email: newCurrentCart.customer?.email,
              }
            : {
                fullName: "",
                phone: "",
                email: "",
                customerId: null,
                shippingAddress: null,
                shippingCountryId: null,
                shippingProvinceId: null,
                shippingDistrictId: null,
                shippingWardId: null,
              }
          : null;

        const newSaleMan = updateFields?.includes("salesMan") && {
          saleUserId: newCurrentCart.salesMan?.id || null,
        };

        const ignoreLoyalty = updateFields?.includes("ignoreLoyalty") && {
          disallowLoyaltyProgram: newCurrentCart?.ignoreLoyalty || false,
        };

        const needUpdating =
          !!newCustomer ||
          !!newAttributes ||
          !!newPricingList ||
          !!newShippingFee ||
          !!newOrderNote ||
          !!newDiscount ||
          !!newTaxData ||
          !!newLineItems ||
          !!newSaleMan ||
          !!ignoreLoyalty;

        if (needUpdating) {
          setLoadingCart(true);
          await updateCheckoutSession(appContext.callAPI, sessionId, {
            ...newCustomer,
            ...newDiscount,
            ...newAttributes,
            ...newPricingList,
            ...newShippingFee,
            ...newOrderNote,
            ...newTaxData,
            ...newLineItems,
            ...newSaleMan,
            ...ignoreLoyalty,
          }).then(async (res) => {
            if (res && !res?.error && res?.status === 200) {
              result = { ...res };
            } else {
              result = await getCheckoutSession(
                appContext.callAPI,
                newCurrentCart?.id,
              );
            }
          });
        }
      }

      // Refresh cart after update
      await processingDiscover({
        currentCart: newCurrentCart,
        newCart: result,
        showGlobalToast: layout.showGlobalToast,
        appContext,
        methodDefaults,
        intl,
      })
        .then((res) => {
          updateCurrentCart({ ...res, isReady: true });
          if (
            !res?.requiresShipping &&
            !!newCurrentCart.summaries?.shipping_fee
          ) {
            layout.showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.Không áp dụng phí vận chuyển vì có sản phẩm không hỗ trợ vận chuyển`,
                defaultMessage:
                  "Không áp dụng phí vận chuyển vì có sản phẩm không hỗ trợ vận chuyển",
              }),
            );
          }
          setLoadingCart(false);
        })
        .catch((e) => {
          layout.showGlobalToast(
            "error",
            intl.formatMessage({
              id: `notifications.${"Đã có lỗi xảy ra, xin vui lòng thử lại."}`,
              defaultMessage: "Đã có lỗi xảy ra, xin vui lòng thử lại.",
            }),
          );
          return;
        });
    } catch (e) {
      updateCurrentCart({
        ...currentCart,
        lineItems: currentCart?.lineItems,
        items: currentCart?.lineItems,
      });
      setLoadingCart(false);
      setNewCurrentShoppingCart(null);
      layout.showGlobalToast(
        "error",
        intl.formatMessage({
          id: `notifications.${"Đã có lỗi xảy ra, xin vui lòng thử lại."}`,
          defaultMessage: "Đã có lỗi xảy ra, xin vui lòng thử lại.",
        }),
      );
      return;
    }
    setNewCurrentShoppingCart(null);
  };

  const refreshCart = async (session) => {
    let cart = { ...session };
    if (!cart) {
      cart = await getCheckoutSession(
        appContext.callAPI,
        current_shopping_cart.id,
      );
    }
    processingDiscover({
      currentCart: cart,
      newCart: cart,
      showGlobalToast: layout.showGlobalToast,
      appContext,
      methodDefaults,
      intl,
    }).then((res) => {
      setProcessedSWR(res);
    });
  };

  function priceSubTotal({ totalPrice = 0, redeemUsedAmount = 0 }) {
    let total = totalPrice;
    return +Number(total).toFixed(4);
  }

  const onUpdateUnit = (currentLineItem, unit) => {
    const currentOrderLines = [...current_shopping_cart?.lineItems];
    let indexChange =
      currentOrderLines &&
      currentOrderLines.findIndex((item) => item.id === currentLineItem.id);
    let lineUpdate = { ...currentOrderLines[indexChange] };
    currentOrderLines[indexChange] = {
      ...lineUpdate,
      selectedUnit: unit,
      variantUnitId: unit.id,
      price: unit.price,
      totalPrice: unit.price,
      originalPrice: unit.price,
      isCalculatePromotion: true,
      discountAmount: 0,
      discountAmountPerLine: 0,
      discountPromotion: 0,
      discountType: null,
      discountTypeId: null,
      discountValue: 0,
      sku: unit.sku,
      barcode: unit.barcode,
      fields: ["variantUnitId"],
    };
    setNewCurrentShoppingCart({
      ...current_shopping_cart,
      lineItems: currentOrderLines,
      fields: ["lineItems"],
    });
  };

  const onUpdateLineAdviser = (currentLineItem, objAdviser) => {
    const currentOrderLines = [...current_shopping_cart?.lineItems];
    let indexChange =
      currentOrderLines &&
      currentOrderLines.findIndex((item) => item.id === currentLineItem.id);
    let currentAttributes = currentOrderLines[indexChange].attributes || [];
    let index = currentAttributes?.findIndex(
      (att) => att.key === SysAttributeKeys.SYS_LINE_ADVISER,
    );
    let hasChange = false;
    if (index > -1) {
      if (!objAdviser || !objAdviser.id) {
        currentAttributes.splice(index, 1);
      } else {
        currentAttributes[index].value = JSON.stringify({
          id: objAdviser.id,
          name: objAdviser.name,
        });
      }
      hasChange = true;
    } else {
      if (objAdviser && objAdviser.id) {
        currentAttributes.push({
          key: SysAttributeKeys.SYS_LINE_ADVISER,
          value: JSON.stringify({ id: objAdviser.id, name: objAdviser.name }),
        });
        hasChange = true;
      }
    }
    if (hasChange) {
      let lineUpdate = { ...currentOrderLines[indexChange] };
      currentOrderLines[indexChange] = {
        ...lineUpdate,
        fields: ["quantity", "attributes"],
      };
      setNewCurrentShoppingCart({
        ...current_shopping_cart,
        lineItems: currentOrderLines,
        fields: ["lineItems"],
      });
    }
  };

  return (
    <CartContext.Provider
      value={{
        shopping_carts,
        current_shopping_cart,
        initNewCart,
        removeCart,
        switchCart,
        updateCurrentCart,
        addItemCurrentCart,
        addBulkItemCurrentCart,
        addBulkItemPromotions,
        removeItemCurrentCart,
        removeAllSessionProduct,
        updateItemCurrentCart,
        loadingCart,
        updateCurrentSession,
        onUpdateUnit,
        priceSubTotal,
        setLoadingCart,
        isSubmitOrderWaiting,
        setIsSubmitOrderWaiting,
        resetPayment,
        resetOrderLineErr,
        methodDefaults,
        loyaltyLoading,
        setLoyaltyLoading,
        showModalSuccess,
        setShowModalSuccess,
        showModalSuccessQR,
        setShowModalSuccessQR,
        showQRPaymentModal,
        setShowQRPaymentModal,
        refreshCart,
        signalRData,
        onUpdateLineAdviser,
        pinnedPricingList,
        setPinnedPricingList,
        pricingList,
        setPricingList,
      }}
    >
      <CartTabs />
      <LayoutBody size="ne" flexRow className="l-cart-container">
        <div className="left-main">
          <div className="l-segment">
            <CartSearch />
            <CartFunctions />
          </div>
          <CartList />
          <QuickProducts />
        </div>
        <div className="right-main">
          <RightCart />
        </div>
        {(loadingCart || isSubmitOrderWaiting) && (
          <div className="app-loading">
            <LoadingCircle overlay className="h-100" showLoading />
          </div>
        )}
      </LayoutBody>
    </CartContext.Provider>
  );
}
const CartContext = createContext({
  shopping_carts: [],
  show_payment: false, // true: hiển thị bước thanh toán | false: ẩn bước thanh toán
  setShowPayment: (is_show) => {},
  current_shopping_cart: {},
  initNewCart: () => {},
  removeCart: (cart_key, sessionId) => {},
  switchCart: (cart_key) => {},
  updateCurrentCart: ({ ...args }) => {},
  addItemCurrentCart: (item, quantity) => {},
  addBulkItemCurrentCart: (lineItems) => {},
  addBulkItemPromotions: (lineItems) => {},
  removeItemCurrentCart: (key, index) => {},
  removeAllSessionProduct: () => {},
  salesMan: null,
  setSalesMan: (salesMan) => {},
  updateItemCurrentCart: () => {},
  loadingCart: false,
  priceSubTotal: () => {},
  updateCurrentSession: ({ ...args }) => {},
  onUpdateUnit: () => {},
  setLoadingCart: (value) => {},
  setIsSubmitOrderWaiting: (value) => {},
  isSubmitOrderWaiting: false,
  resetPayment: () => {},
  resetOrderLineErr: () => {},
  methodDefaults: null,
  loyaltyLoading: false,
  setLoyaltyLoading: () => {},
  showModalSuccess: false,
  setShowModalSuccess: () => {},
  showModalSuccessQR: false,
  setShowModalSuccessQR: () => {},
  showQRPaymentModal: false,
  setShowQRPaymentModal: () => {},
  refreshCart: () => {},
  signalRData: null,
  onUpdateLineAdviser: (currentLineItem, adviser) => {},
  pinnedPricingList: null,
  setPinnedPricingList: () => {},
  pricingList: null,
  setPricingList: () => {},
});

export const useCartContext = () => {
  const context = useContext(CartContext);
  return context;
};
