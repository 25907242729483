import EmptyState from "component-v2/empty-state";
import moment from "moment";
import { Fragment } from "react";
import DateGroup from "screens-v2/orders/components/date-group";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import { useShiftContext } from "..";
import ShiftListItem from "./shift-list-item";

export default function ShiftList() {
  const { shifts, setCurrentShift, callNextPage, loading, limitReached } =
    useShiftContext();
  if (!shifts?.length)
    return (
      <EmptyState iconName="access_time" description="Không có dữ liệu ca" />
    );
  return (
    <div
      className="shift-list"
      onScroll={(e) => {
        const bottom =
          e.target.scrollHeight - e.target.scrollTop <
          e.target.clientHeight + 3;
        if (bottom) {
          callNextPage();
        }
      }}
    >
      {shifts.map((shift, idx) => {
        const prev_date =
          idx === 0
            ? ""
            : new Date(moment(shifts[idx - 1].openAt)).toDateString();
        const curr_date = new Date(moment(shift.openAt)).toDateString();
        return (
          <Fragment key={"shft-lst-" + shift.id}>
            {curr_date !== prev_date ? (
              <div className="shift-list--date-group">
                <DateGroup date={curr_date} />
              </div>
            ) : null}
            <ShiftListItem
              item={shift}
              onClick={(e, item) => setCurrentShift(item)}
            />
          </Fragment>
        );
      })}
      {loading ? (
        <div className="shift-list--loading">
          <LoadingCircle />
        </div>
      ) : null}
      {limitReached ? (
        <div className="shift-list--limit-reach">
          <span>Đã hết danh sách ca</span>
        </div>
      ) : null}
    </div>
  );
}
