import { useApp } from "contexts/app";
import { useCartContext } from "..";
import { SelectionCustom } from "./components/SelectionCustom";

export const SaleUsers = () => {
  const { shop_setting } = useApp();
  const { staff_users } = useApp();
  const { updateCurrentSession, current_shopping_cart } = useCartContext();

  return (
    <SelectionCustom
      value={current_shopping_cart?.salesMan?.id}
      options={staff_users}
      idField="id"
      valueField="name"
      onChange={(value) => {
        if (value !== current_shopping_cart?.salesMan?.id) {
          updateCurrentSession({
            salesMan: staff_users.find((item) => item.id === value),
          });
        }
      }}
      prefix={
        <label className="align-self-center label-in-selection">
          Nhân viên bán:{" "}
        </label>
      }
      placeholder={"Chọn nhân viên bán hàng"}
      disabled={!shop_setting.allowChangeSeller}
      // style={{ maxWidth: "280px" }}
    >
      {staff_users?.map((u) => {
        return (
          <SelectionCustom.Option key={u.id} value={u.id}>
            <div className="d-flex align-items-center">{u.name}</div>
          </SelectionCustom.Option>
        );
      })}
    </SelectionCustom>
  );
};
