import { Input, InputCheckbox, InputHasIcon } from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import React, { useEffect, useState } from "react";
import { concatString } from "screens-v2/carts-v2/utils";
import AddressesGlobal from "../../components/addressesGlobal";

const customerAddressDefault = {
  shippingAddress: "",
  billingAddress: "",
  isDefault: false,
  lastName: "",
  firstName: "",
  phone: "",
  address1: "",
  address2: "",
  company: "",
  countryId: 241,
  countryCode: "vn",
  countryName: "Vietnam",
  city: "",
  zipCode: "",
  provinceId: "",
  provinceName: "",
  provinceCode: "",
  districtId: "",
  districtName: "",
  districtCode: "",
  wardId: "",
  wardName: "",
  wardCode: "",
  fullName: "",
  fullAddress: "",
};

export const EditAddress = ({
  customerAddress,
  setEditCustomerAddress,
  isEdit,
}) => {
  const [address, setAddress] = useState(customerAddressDefault);

  useEffect(() => {
    customerAddress && setAddress(customerAddress);
  }, [customerAddress]);

  const userInfo = concatString({
    value: [customerAddress?.fullName, customerAddress?.phone],
    separator: " · ",
  });

  function handleChangeAddress(key, value) {
    setEditCustomerAddress((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  if (!address) return null;

  return (
    <>
      {isEdit ? (
        <>
          <div>
            <p>{address.fullAddress ?? "--"}</p>
            <p className="content-secondary mt-2">{userInfo}</p>
          </div>
          <hr className="my-4" />
        </>
      ) : null}
      <div className="row">
        <div className="col-12 col-lg-6 mt-3">
          <Input
            placeholder="Họ"
            value={address.lastName || ""}
            onChange={(value) => {
              handleChangeAddress("lastName", value);
            }}
          />
        </div>
        <div className="col-12 col-lg-6 mt-3">
          <Input
            placeholder="Tên"
            value={address.firstName || ""}
            onChange={(value) => {
              handleChangeAddress("firstName", value);
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <InputHasIcon
            prefix={<Icon name="phone_enabled" />}
            placeholder="Số điện thoại"
            value={address.phone || ""}
            onChange={(value) => {
              handleChangeAddress("phone", value);
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <Input
            placeholder="Địa chỉ"
            value={address.address1 || ""}
            onChange={(value) => {
              handleChangeAddress("address1", value);
            }}
          />
        </div>
        <div className="col-12">
          <div className="row">
            <AddressesGlobal
              countryId={address?.countryId || 241}
              provinceId={address?.provinceId}
              districtId={address?.districtId}
              wardId={address?.wardId}
              handleOnChange={(data) => {
                setEditCustomerAddress((prev) => ({
                  ...prev,
                  ...data,
                }));
              }}
            />
          </div>
        </div>
      </div>
      <InputCheckbox
        className="mt-3"
        checked={address.isDefault}
        onChange={(check) => handleChangeAddress("isDefault", check)}
      >
        Chọn làm địa chỉ mặc định
      </InputCheckbox>
    </>
  );
};
