import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import { CartItem } from "../CartItem";
import { cloneDeep, debounce, isNil } from "lodash";
import { useIntl } from "react-intl";
import { useCartContext } from "../../index";
import { CartEmpty } from "../CartEmpty";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { productQuantityMax } from "screens-v2/carts-v2/cart-helpers.service";
import { getDecimalValue } from "utils/Commons";
import { useApp } from "contexts/app";

function CartList() {
  const { shop_info } = useApp();
  const intl = useIntl();
  const [itemSelected, setItemSelected] = useState(null);
  const [orderLines, setOrderLines] = useState([]);
  const { updateItemCurrentCart, current_shopping_cart, loadingCart } =
    useCartContext();
  useEffect(() => {
    setOrderLines(current_shopping_cart?.lineItems || []);
  }, [current_shopping_cart?.lineItems]);

  useGlobalHotkeys({
    keys: [HOTKEYS.HOME, HOTKEYS.DOWN, HOTKEYS.UP],
    scopes: HOTKEY_SCOPES.CART,
    enabled: !loadingCart,
    callback: (e) => {
      const inputEl = document.querySelectorAll(".quantity_cart_item");
      const hotkeyAction = e.key;
      if (hotkeyAction === HOTKEY_EVENTS.HOME) {
        if (inputEl?.length) {
          inputEl[0].focus();
          setItemSelected(0);
        }
      }
      if (hotkeyAction === HOTKEY_EVENTS.DOWN && !isNil(itemSelected)) {
        if (itemSelected === inputEl?.length - 1) {
          inputEl[0].focus();
          setItemSelected(0);
        } else {
          inputEl[itemSelected + 1].focus();
          setItemSelected(itemSelected + 1);
        }
      }
      if (hotkeyAction === HOTKEY_EVENTS.UP && !isNil(itemSelected)) {
        if (!itemSelected) {
          inputEl[inputEl?.length - 1].focus();
          setItemSelected(inputEl?.length - 1);
        } else {
          inputEl[itemSelected - 1].focus();
          setItemSelected(itemSelected - 1);
        }
      }
    },
  });

  useGlobalHotkeys({
    keys: [HOTKEYS.RIGHT, HOTKEYS.LEFT],
    scopes: HOTKEY_SCOPES.CART,
    enabled: !isNil(itemSelected),
    callback: (e) => {
      const hotkeyAction = e.key;
      const newOrderLines = cloneDeep(orderLines);

      if (hotkeyAction === HOTKEY_EVENTS.RIGHT && !isNil(itemSelected)) {
        if (newOrderLines[itemSelected].quantity <= productQuantityMax) {
          newOrderLines[itemSelected].quantity = getDecimalValue(
            shop_info,
            newOrderLines[itemSelected].quantity + 1,
          );
          setOrderLines(newOrderLines);
          debounceOnChange(
            newOrderLines[itemSelected],
            itemSelected,
            newOrderLines[itemSelected].quantity,
          );
        }
      }
      if (hotkeyAction === HOTKEY_EVENTS.LEFT && !isNil(itemSelected)) {
        if (newOrderLines[itemSelected].quantity > 1) {
          newOrderLines[itemSelected].quantity = getDecimalValue(
            shop_info,
            newOrderLines[itemSelected].quantity - 1,
          );
          setOrderLines(newOrderLines);
          debounceOnChange(
            newOrderLines[itemSelected],
            itemSelected,
            newOrderLines[itemSelected].quantity,
          );
        }
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((item, index, value) => {
      updateItemCurrentCart(item, index, value, ["quantity"]);
    }, 500),
    [current_shopping_cart?.lineItems],
  );

  return (
    <div className="l-segment carts-cart scrollable">
      {orderLines.length === 0 ? (
        <CartEmpty intl={intl} />
      ) : (
        <table className="align-top table-cart-list">
          <tbody>
            {orderLines.map((item, index) => {
              return (
                <CartItem
                  intl={intl}
                  key={`${item.key}-${index}`}
                  index={index}
                  item={item}
                  setItemSelected={setItemSelected}
                />
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export { CartList };
