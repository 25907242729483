import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { getZoomTarget } from "utils/Commons";

export function ScreenCapture({ children, onEndCapture }) {
  const [isPrepareCapture, setIsPrepareCapturing] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [endPosition, setEndPosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
  const zoom = getZoomTarget(windowSize.width);

  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      setWindowSize({ width: windowWidth, height: windowHeight });
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const onStartCapture = () => {
    setIsPrepareCapturing(true);
  };

  const handleTakeScreenShot = () => {
    let cropWidth = cropSize.width;
    let cropHeigth = cropSize.height;
    if (cropWidth === 0 || cropHeigth === 0) {
      cropWidth = 100;
      cropHeigth = 100;
    }
    cropWidth /= zoom;
    cropHeigth /= zoom;
    const content = document.querySelector("body");
    html2canvas(content, {
      scale: 1,
      useCORS: true,
      logging: false,
      width: windowSize.width / zoom,
      height: windowSize.height / zoom,
      windowWidth: windowSize.width / zoom,
      windowHeight: windowSize.height / zoom,
      scrollX: window.scrollX / zoom,
      scrollY: window.scrollY / zoom,
    }).then((canvas) => {
      let croppedCanvas = document.createElement("canvas");
      let croppedCanvasContext = croppedCanvas.getContext("2d");

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeigth;

      croppedCanvasContext.drawImage(
        canvas,
        Math.min(startPosition.x, endPosition.x) / zoom,
        Math.min(startPosition.y, endPosition.y) / zoom,
        cropWidth,
        cropHeigth,
        0,
        0,
        cropWidth,
        cropHeigth,
      );
      onEndCapture(croppedCanvas.toDataURL());
      setIsPrepareCapturing(false);
      setStartPosition({ x: 0, y: 0 });
      setEndPosition({ x: 0, y: 0 });
      setCropSize({ width: 0, height: 0 });
    });
  };

  const handleMouseDown = (e) => {
    setIsCapturing(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isCapturing) {
      const endX = e.clientX;
      const endY = e.clientY;
      setEndPosition({ x: endX, y: endY });
      setCropSize({
        width: Math.abs(endX - startPosition.x),
        height: Math.abs(endY - startPosition.y),
      });
    }
  };
  const handleMouseUp = (e) => {
    if (isCapturing) {
      const endX = e.pageX;
      const endY = e.pageY;
      setEndPosition({ x: endX, y: endY });
      setCropSize({
        width: Math.abs(endX - startPosition.x),
        height: Math.abs(endY - startPosition.y),
      });
      setIsCapturing(false);
    }
    handleTakeScreenShot();
  };
  if (isPrepareCapture)
    return (
      <div
        className="capture-overlay"
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <div
          className="capture-target"
          style={{
            left: Math.min(startPosition.x, endPosition.x) / zoom,
            top: Math.min(startPosition.y, endPosition.y) / zoom,
            width: `${cropSize.width / zoom}px`,
            height: `${cropSize.height / zoom}px`,
          }}
        ></div>
      </div>
    );
  return children({ onStartCapture });
}
