export const getVariantInventory = async (appContext, variantId, locationId) => {
  let temp_params = `?page=1&limit=50&variant_ids=${variantId}&inventory_min=0`;

  if(locationId) {
    temp_params += `&location_ids=${locationId}`
  }

  return await appContext.callAPI('get', `/call/com_api/inventory/locationbalance` + temp_params);
};

