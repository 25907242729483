export const GetCashFlowBankAccount = async (appContext) => {
  return await appContext.callAPI('get', `/call/com_api/cash_flows/bank/accounts`);
}

export const GetCashFlowReason = async (appContext, type) => {
  return await appContext.callAPI('get', `/call/com_api/cash_flows/${type}/reasons`);
}

export const CreateCashFlowBankAccount = async (appContext, model) => {
  return await appContext.callAPI('post',
    '/call/com_api/cash_flows/bank/accounts',
    model
  );
}

export const UpdateBankAccount = async (appContext, model, accountId) => {
  return await appContext.callAPI('put',
    `/call/com_api/cash_flows/bank/accounts/${accountId}`,
    model
  );
}

export const CreateCashFlowReason = async (appContext, model, type = "cash_payment") => {
  return await appContext.callAPI('post',
    `/call/com_api/cash_flows/${type}/reasons`,
    model
  );
}

export const UpdateCashFlowReason = async (appContext, model, type = "cash_payment") => {
  return await appContext.callAPI('put',
    `/call/com_api/cash_flows/${type}/reasons/${model.id}`,
    model
  );
}

export const GetAssignedLocations = async (appContext, type) => {
  return await appContext.callAPI('get', `/call/com_api/locations/assigned?type=${type}`)
}

export const GetCustomers = async (appContext, searchText, page = 1, limit = 10) => {
  try {
    const response = await appContext.callAPI('get', `/call/com_api/orders/customers/simple?query=${searchText}&page=${page}&isAdjustment=false&acceptUnTrackInventory=true&limit=${limit}`, true)
    return response
  } catch (error) {
    return []
  }
}
export const GetSuppliers = async (appContext, searchText, page = 1, limit = 10) => {
  try {
    const response = await appContext.callAPI('get', `/call/com_api/suppliers?query=${searchText}&page=${page}&isAdjustment=false&acceptUnTrackInventory=true&limit=${limit}`, true);
    return response
  } catch (error) {
    return []
  }
}

export const GetObject = async (appContext) => {
  return await appContext.callAPI('get', '/call/com_api/cash_flows/objects')
}

export const CreateCashFlowObject = async (appContext, model) => {
  return await appContext.callAPI('post',
    '/call/com_api/cash_flows/objects',
    model
  );
}

export const UpdateCashFlowObject = async (appContext, value, id) => {
  return await appContext.callAPI('put',
    `/call/com_api/cash_flows/objects/${id}`,
    value
  );
}

export const CreateCashFlow = async (appContext, model) => {
  return await appContext.callAPI('post', '/call/com_api/cash_flows', model)
}

export const GetCashFlowObjectById = async (appContext, id) => {
  return await appContext.callAPI('get', `/call/com_api/cash_flows/objects/${id}`)
}

export const GetCashFlowById = async (appContext, id) => {
  return await appContext.callAPI('get', `/call/com_api/cash_flows/${id}`)
}

export const CreateCustomer = async (appContext, model) => {
  return await appContext.callAPI('post', "/call/com_api/customers", model)
}

export const CreateImages = async (appContext, images, cashflow_id) => {
  const res = await appContext.callAPI('upload_file_multi',
    `/call/com_api/files/cash_flows/${cashflow_id}/images`,
    images,
  );
  return res;
}