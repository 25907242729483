import { InputRadio } from "@haravan/react-components";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { Fragment } from "react";
import { concatString } from "screens-v2/carts-v2/utils";
import Badge from "screens-v2/layouts/layouts/omni-badge";
export const Addresses = ({
  setShowModalAddress,
  setEditCustomerAddressDefault,
  customerInfo,
  editCustomerAddressDefault,
  setEditCustomerAddress,
}) => {
  return (
    <>
      {customerInfo?.customerAddress?.length ? (
        customerInfo?.customerAddress?.map((address, i) => {
          const fullAddress = concatString({
            value: [
              address.address1,
              address.wardName,
              address.districtName,
              address.provinceName,
              address.countryName,
            ],
            separator: ", ",
          });

          const fullName = concatString({
            value: [address.lastName, address.firstName],
          });
          const userInfo = concatString({
            value: [fullName, address.phone],
            separator: " · ",
          });
          const newAddress = {
            ...address,
            fullName,
            userInfo,
            fullAddress,
          };

          return (
            <Fragment key={address.id}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <InputRadio
                    className={`${
                      address.id === editCustomerAddressDefault?.id
                        ? "border-0 location-item--radio-button-checked"
                        : "location-item--radio-button-uncheck"
                    } input-radio-center`}
                    name="address_default"
                    checked={address.id === editCustomerAddressDefault?.id}
                    onChange={(check) => {
                      setEditCustomerAddressDefault(newAddress);
                    }}
                  >
                    <p className="mb-0">{fullAddress || "--"}</p>
                    <p className="content-secondary mb-0 mt-2">{userInfo}</p>
                    {address.isDefault ? (
                      <Badge status="info" className={"mt-2"} light rounded>
                        Địa chỉ mặc định
                      </Badge>
                    ) : null}
                  </InputRadio>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setShowModalAddress("edit");
                    setEditCustomerAddress(newAddress);
                  }}
                >
                  <Icon name="edit_pencil" />
                </div>
              </div>
              <hr className="my-3" />
            </Fragment>
          );
        })
      ) : (
        <p className="text-center content-secondary">
          Không có địa chỉ giao hàng
        </p>
      )}
      <Button
        className="font-weight-semibold"
        onClick={() => {
          setShowModalAddress("create");
          setEditCustomerAddress(null);
        }}
        status="primary"
        title={
          <>
            <Icon name="add" color="var(--contentAccent)" />
            Địa chỉ mới
          </>
        }
      />
    </>
  );
};
