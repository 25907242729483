import { Loading, Tooltip } from "@haravan/react-components";
import { useShift } from "contexts/shift/shift-context";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import Button from "component-v2/buttons";
import PartialPaymentInput from "./partial-payment-input";
import Logo from "component-v2/logos/logos";
import {
  BANK_QR_MIN_AMOUNT,
  HOTKEYS,
  HOTKEY_EVENTS,
  HOTKEY_SCOPES,
  MOMO_MIN_AMOUNT,
} from "models/constants";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { useApp } from "contexts/app";
import * as HrvComponents from "@haravan/react-components";
import { paymentMethodsDefault } from "../../../screens-v2/carts-v2/cart-helpers.service";
import _ from "lodash";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { repaymentCheckout } from "api/checkout-repository";
import { PaymentQrMethods } from "models/payment-methods-model";
export default function OrderPaymentConfirmModal({
  order,
  onSucceeded,
  show,
  setShow,
  hasQRPayment,
  setHasQRPayment,
  showQRPaymentModal,
  setShowQRPaymentModal,
  setQrPaymentDetails,
  session,
}) {
  const intl = useIntl();
  const appContext = useApp();
  const { showGlobalToast } = useLayoutContext();
  const { checkShiftReady } = useShift();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentPartial, setIsPaymentPartial] = useState(false);
  const [gatewaysSelected, setGatewaysSelected] = useState({
    cash: {
      name: "Tiền mặt",
      value: order.amountToPaid,
      isOnly: false,
      isCustom: false,
    },
  });
  const [sum, setSum] = useState({});
  // const paymentMethods = listPaymentMethod(appContext.shop_setting)
  const paymentMethods = paymentMethodsDefault(appContext.shop_setting);

  useEffect(() => {
    if (show) {
      setIsPaymentPartial(false);
      if (order) {
        setSum({
          amountToPaid: order.amountToPaid,
          totalPaid: order.totalPaid,
          amountPaid: order.amountPaid,
        });
        setGatewaysSelected({
          cash: {
            name: "Tiền mặt",
            value: order.amountToPaid,
            isOnly: false,
            isCustom: false,
          },
        });
      } else setSum({});
    }
  }, [show, order]);
  useEffect(() => {
    if (gatewaysSelected) {
      let hasQR = false;
      for (const [, value] of Object.entries(gatewaysSelected)) {
        if (value.isQR) {
          hasQR = true;
          break;
        }
      }
      setHasQRPayment(hasQR);
    }
  }, [gatewaysSelected]);

  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F9],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        setShow(false);
      }
      if (e.key === HOTKEY_EVENTS.F9 && !showQRPaymentModal) {
        if (hasQRPayment) {
          handleQRPayment();
        } else {
          handleSubmitPayment();
        }
      }
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });

  let paymentTooltipPrefix = "Thanh toán";
  const totalInputValue = Object.keys(gatewaysSelected).reduce((total, gw) => {
    return total + gatewaysSelected[gw].value;
  }, 0);

  const handleUpdateInput = (type, value) => {
    const othersGatewayValue = gatewaysSelected[type]
      ? totalInputValue - gatewaysSelected[type].value
      : totalInputValue;
    if (othersGatewayValue + value > sum.amountToPaid) {
      // vượt quá số tiền phải
      // optimize: giá trị cũ bằng giá trị mới thì ko render lại
      value = sum.amountToPaid - othersGatewayValue;
    }
    setGatewaysSelected((prev) => ({
      ...prev,
      [type]: { ...gatewaysSelected[type], value },
    }));
  };

  const handleSubmitPayment = async (_isPaymentPartial) => {
    const currentLocation = appContext.current_location;
    const currentOrderId = order.orderId;
    let transactions = [];
    transactions = Object.keys(gatewaysSelected)
      .map((gateway) => {
        return {
          gateway: gatewaysSelected[gateway].name,
          amount: gatewaysSelected[gateway].value,
          ...(gateway !== "cash"
            ? {
                externalTransactionId:
                  gatewaysSelected[gateway].transaction_id || "0",
              }
            : {}),
          userId: appContext.auth.user.id,
          locationId: currentLocation.id,
        };
      })
      .filter((item) => item.amount);
    if (totalInputValue < sum.amountToPaid && !_isPaymentPartial) {
      setIsPaymentPartial(true);
      setShowModalConfirm(true);
    } else {
      setIsLoading(true);
      const result = await appContext.callAPI(
        "post",
        `/call/com_api/pos/orders/${currentOrderId}/transactions`,
        transactions,
      );
      setIsLoading(false);
      if (!result.errors && result.data === true) {
        setShowModalConfirm(false);
        setShow(false);
        onSucceeded?.();
      }
    }
  };
  const handleQRPayment = async () => {
    let qrTransaction;
    if (gatewaysSelected) {
      for (const [key, value] of Object.entries(gatewaysSelected)) {
        if (value.isQR) {
          qrTransaction = { ...value, type: key };
          break;
        }
      }
    }
    if (qrTransaction && !!order?.checkoutToken) {
      await repaymentCheckout(
        appContext.callAPI,
        order?.checkoutToken,
        qrTransaction?.paymentInstructionId,
      )
        .then((res) => {
          const qrData =
            res?.nextActions?.find((action) => action.type === "Qr")?.data ||
            res?.order?.paymentQrData;
          if (!!qrData) {
            setQrPaymentDetails({
              url: qrData,
              paymentInstructionId: qrTransaction?.paymentInstructionId,
              paymentMethodId: qrTransaction?.paymentInstructionTypeId,
              paymentMethodName: qrTransaction.name,
              paymentMethodType: qrTransaction.type,
              orderNumber: order?.orderNumber,
              orderId: order?.orderId,
              amount: sum.amountToPaid,
              orderData: { ...order },
              qrStatus: "success",
              sessionId: order?.checkoutToken,
            });
          } else {
            setQrPaymentDetails({
              url:
                qrTransaction?.paymentInstructionTypeId ===
                PaymentQrMethods.momo_qr
                  ? "error"
                  : `{"Amount": 0,"Note":"error","CreatedDate":"","BankCode":"","BankName":"","BankAccountName":"","BankAccount":""}`,
              paymentInstructionId: qrTransaction?.paymentInstructionId,
              paymentMethodId: qrTransaction?.paymentInstructionTypeId,
              paymentMethodName: qrTransaction.name,
              paymentMethodType: qrTransaction.type,
              orderNumber: order.orderNumber,
              orderId: order.orderId,
              amount: sum.amountToPaid,
              orderData: order,
              qrStatus: "error",
              sessionId: order?.checkoutToken,
            });
            const errorMsg =
              res?.errors?.[0] || "Có lỗi xảy ra khi lấy thông tin QR Code";
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.${errorMsg}`,
                defaultMessage: errorMsg,
              }),
            );
          }
        })
        .catch((err) => {
          setQrPaymentDetails({
            url:
              qrTransaction?.paymentInstructionTypeId ===
              PaymentQrMethods.momo_qr
                ? "error"
                : `{"Amount": 0,"Note":"error","CreatedDate":"","BankCode":"","BankName":"","BankAccountName":"","BankAccount":""}`,
            paymentInstructionId: qrTransaction?.paymentInstructionId,
            paymentMethodId: qrTransaction?.paymentInstructionTypeId,
            paymentMethodName: qrTransaction.name,
            paymentMethodType: qrTransaction.type,
            orderNumber: order.orderNumber,
            orderId: order.orderId,
            amount: sum.amountToPaid,
            orderData: order,
            qrStatus: "error",
          });
        })
        .finally(() => {
          setShowQRPaymentModal(true);
        });
    }
  };
  const handleToggleGateWay = (gateway, name, isOnly) => {
    let gateways = { ...gatewaysSelected };
    let othersGatewayValue = sum.amountToPaid - totalInputValue;
    if (Object.keys(gateways).includes(gateway)) {
      delete gateways[gateway];
    } else {
      if (isOnly) {
        let gatewayOnly = Object.keys(gateways).find((item) => {
          return gateways[item].isOnly;
        });
        if (gatewayOnly) {
          othersGatewayValue = othersGatewayValue + gateways[gatewayOnly].value;
          delete gateways[gatewayOnly];
        }
      }
      if (gateway?.isQR) {
        othersGatewayValue = sum.amountToPaid;
        gateways = {};
      } else {
        let gatewayQR = Object.keys(gateways).find((item) => {
          return gateways[item]?.isQR;
        });
        if (gatewayQR) {
          delete gateways[gatewayQR];
        }
      }
      gateways = {
        ...gateways,
        [gateway.type]: {
          value: othersGatewayValue,
          name,
          isOnly,
          isCustom: gateway.isCustom,
          isQR: gateway?.isQR,
          ...(gateway?.paymentInstructionId && {
            paymentInstructionId: gateway?.paymentInstructionId,
          }),
          ...(gateway?.paymentInstructionTypeId && {
            paymentInstructionTypeId: gateway?.paymentInstructionTypeId,
          }),
        },
      };
    }
    setGatewaysSelected(gateways);
  };

  const handleOnChangeTransactionId = (gateway, value) => {
    const gateways = { ...gatewaysSelected };
    gateways[gateway].transaction_id = value;
    setGatewaysSelected(gateways);
  };

  // const showListPaymentMethod = Object.keys(paymentMethods).filter(method => !paymentMethods[method].isHidden && method !== "cod" && !Object.keys(gatewaysSelected).includes(method))
  const showListPaymentMethod = paymentMethods;

  function renderPaymentPartialBodyContent() {
    const body = (
      <>
        <div className="partial-payment-content">
          <FormattedMessage
            id="carts.orders.Ghi nhận đã thanh toán {a} cho đơn hàng {b}.{c} Nhấn <d>XÁC NHẬN</d> để tiếp tục."
            defaultMessage="Ghi nhận đã thanh toán {a} cho đơn hàng {b}.{c} Nhấn <d>XÁC NHẬN</d> để tiếp tục."
            values={{
              a: (
                <span>
                  <CurrencyShow value={totalInputValue} />
                </span>
              ),
              b: (
                <span>
                  <CurrencyShow value={sum.amountToPaid} />
                </span>
              ),
              d: (content) => <span className="text-blue">{content}</span>,
              c: <br />,
            }}
          />
        </div>
      </>
    );

    const footer = (
      <div className="margin-top-16 text-right partial-payment-footer">
        <Button light className="" onClick={() => setShowModalConfirm(false)}>
          {intl
            .formatMessage({
              id: "carts.orders.Hủy bỏ",
              defaultMessage: "Hủy bỏ",
            })
            .toUpperCase()}
        </Button>
        <Button
          status="primary"
          disabled={isLoading}
          onClick={() => handleSubmitPayment(true)}
          className="ml-4 mt-0 position-relative"
        >
          {isLoading ? (
            <LoadingCircle size="md" overlay style={{ margin: "-16px" }} />
          ) : null}
          <span style={{ visibility: isLoading ? "hidden" : "" }}>
            {intl
              .formatMessage({
                id: "carts.orders.Xác nhận",
                defaultMessage: "Xác nhận",
              })
              .toUpperCase()}
          </span>
        </Button>
      </div>
    );
    return (
      <GlobalModal
        headerTitle={intl.formatMessage({
          id: "carts.orders.Thanh toán chưa hoàn tất",
          defaultMessage: "Thanh toán chưa hoàn tất",
        })}
        body={body}
        footer={footer}
        show={showModalConfirm}
        setShow={setShowModalConfirm}
      />
    );
  }

  function renderMainBodyContent() {
    const gatewayDefault = Object.keys(gatewaysSelected).reduce((obj, key) => {
      if (!gatewaysSelected[key].isCustom) {
        obj = { ...obj, [key]: gatewaysSelected[key] };
      }
      return obj;
    }, {});
    const gatewayCustom = Object.keys(gatewaysSelected).reduce((obj, key) => {
      if (gatewaysSelected[key].isCustom) {
        obj = { ...obj, [key]: gatewaysSelected[key] };
      }
      return obj;
    }, {});
    return (
      <div className="checkout-partial-content">
        <div className="partial-payment-input">
          {showListPaymentMethod?.length ? (
            <div className="add-payment-method pb-2">
              <p className="pb-2 pt-2 font-size-12">
                {intl.formatMessage({
                  id: "settings.generals.Chọn phương thức thanh toán",
                  defaultMessage: "Chọn phương thức thanh toán",
                })}
              </p>
              <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                {showListPaymentMethod
                  ?.filter((method) => {
                    if (
                      order?.paymentStatusId === 4 ||
                      !appContext.shop_setting.isNewPos ||
                      !session?.id
                    ) {
                      // ẩn QR khi thanh toán một phần
                      return !method?.isQR;
                    }
                    return true;
                  })
                  .map((method, index) => {
                    if (method.isHidden || method.type === "cod") return null;
                    const isDisable =
                      (sum.amountToPaid < MOMO_MIN_AMOUNT &&
                        method.paymentInstructionTypeId ===
                          PaymentQrMethods.momo_qr) ||
                      (sum.amountToPaid < BANK_QR_MIN_AMOUNT &&
                        method.isQR &&
                        method.paymentInstructionTypeId !==
                          PaymentQrMethods.momo_qr);
                    return (
                      <Tooltip
                        id={method.type}
                        key={method.type}
                        title={intl.formatMessage({
                          id: `carts.orders.${method.tooltip}`,
                          defaultMessage: `${paymentTooltipPrefix} ${method.tooltip}`,
                        })}
                      >
                        <div
                          id={method.type}
                          key={`${method.name}-${method.type}}-${index}`}
                          className={`background-tertiary rounded-circle p-1 cursor-pointer ${
                            gatewaysSelected.hasOwnProperty(method.type)
                              ? "border border-primary"
                              : ""
                          } ${isDisable ? "payment-method-disabled" : ""}`}
                          style={{ width: "40px", height: "40px" }}
                          onClick={() => {
                            handleToggleGateWay(
                              method,
                              method.name,
                              method.hasOnly,
                            );
                          }}
                        >
                          {method.isCustom ? (
                            <HrvComponents.Avatar
                              arrColor="gray"
                              className="text-primary"
                              shape="circle"
                              content={method.name}
                            ></HrvComponents.Avatar>
                          ) : (
                            <Logo
                              // className={'align-self-center'}
                              name={method.type}
                              size="md"
                            />
                          )}
                        </div>
                      </Tooltip>
                    );
                  })}
              </div>
            </div>
          ) : null}
          <div
            className="scroll-bar-config payment-method"
            style={{
              height: `${
                showListPaymentMethod?.length ? "calc(100% - 94px)" : "100%"
              }`,
            }}
            id="cart-payment-input"
          >
            {show ? (
              <>
                <hr />
                {Object.keys(gatewayDefault).length > 0 &&
                  Object.keys(gatewayDefault).map((gw, index) => {
                    return (
                      <>
                        <PartialPaymentInput
                          gatewaysSelected={gatewayDefault}
                          name={gatewayDefault[gw].name}
                          type={gw}
                          key={`${gw}-${index}`}
                          hasExternalId={
                            gw !== "cash" && !gatewayDefault[gw]?.isQR
                          }
                          value={gatewayDefault[gw].value || "0"}
                          transaction_id={gatewayDefault[gw].transaction_id}
                          onChangeValue={handleUpdateInput}
                          onChangeTransactionId={handleOnChangeTransactionId}
                          handleClickClose={handleToggleGateWay}
                          disableEditAmount={gatewayDefault[gw]?.isQR}
                        />
                      </>
                    );
                  })}
                {Object.keys(gatewayCustom).length > 0 &&
                  Object.keys(gatewayCustom).map((gw, index) => {
                    return (
                      <>
                        <PartialPaymentInput
                          gatewaysSelected={gatewayCustom}
                          name={gatewayCustom[gw].name}
                          type={gw}
                          key={`${gw}-${index}`}
                          hasExternalId={gw !== "cash"}
                          value={gatewayCustom[gw].value || "0"}
                          transaction_id={gatewayCustom[gw].transaction_id}
                          onChangeValue={handleUpdateInput}
                          onChangeTransactionId={handleOnChangeTransactionId}
                          handleClickClose={handleToggleGateWay}
                        />
                      </>
                    );
                  })}
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  function renderBodyFooter() {
    return (
      <div className="w-100">
        <p className="d-flex justify-content-between align-items-center mt-3">
          <b>
            {intl.formatMessage({
              id: "orders.searches.Tiền khách phải thanh toán",
              defaultMessage: "Tiền khách phải thanh toán",
            })}
          </b>
          <b>
            <CurrencyShow value={sum.totalPaid} />
          </b>
        </p>
        <p className="d-flex justify-content-between align-items-center padding-top-8">
          <span>
            {intl.formatMessage({
              id: "orders.searches.Tiền khách đã thanh toán",
              defaultMessage: "Tiền khách đã thanh toán",
            })}
          </span>
          <span>
            <CurrencyShow value={sum.amountPaid} />
          </span>
        </p>
        {isLoading ? (
          <Button
            className={"w-100 font-size-20 position-relative"}
            status="primary"
          >
            <Loading className="print-button--loading-btn" size="pico" />
          </Button>
        ) : (
          <Button
            size="lg"
            badge={HOTKEYS.F9}
            status="primary"
            className="w-100 position-relative"
            onClick={
              hasQRPayment
                ? () => handleQRPayment()
                : () => handleSubmitPayment()
            }
            disabled={isLoading || totalInputValue === 0}
            title={
              <span>
                {hasQRPayment ? (
                  `Quét mã QR`
                ) : (
                  <>
                    {intl.formatMessage({
                      id: "carts.orders.Thanh toán",
                      defaultMessage: "Thanh toán",
                    })}{" "}
                    {totalInputValue > 0 ? (
                      <CurrencyShow value={totalInputValue} />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </span>
            }
            icon={hasQRPayment && `qrCode`}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <GlobalModal
        size="lg"
        fixHeight
        headerTitle={
          <span>
            {intl.formatMessage({
              id: "orders.searches.Xác nhận thanh toán",
              defaultMessage: "Xác nhận thanh toán",
            })}
          </span>
        }
        body={renderMainBodyContent()}
        show={show}
        footer={renderBodyFooter()}
        setShow={setShow}
      >
        <Button
          status="primary"
          light
          className="mr-3"
          title="Xác nhận thanh toán"
          onClick={() => checkShiftReady(true, () => setShow(true))}
        />
      </GlobalModal>
      {renderPaymentPartialBodyContent()}
    </>
  );
}
