import { useIntl } from "react-intl";
import {
  LayoutCard,
  LayoutCardHeader,
  LayoutCardSection,
} from "screens-v2/layouts/layouts/omni-layout-card";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";

function UserSetting() {
  const intl = useIntl();
  const { shop_info } = useApp();
  const omnipower_account = `${shop_info.buyer_domain}admin/settings/accounts/`;
  return (
    <LayoutCard className="bg-transparent">
      <LayoutCardSection className="pt-0">
        <LayoutCardHeader title="Quản lý nhân viên" />
        <p className="text-secondary mt-2">
          Cấu hình tài khoản và chi nhánh cho nhân viên bán hàng
        </p>

        <div className="border-top mt-4">
          <p className="mt-4 text-secondary font-weight-medium">
            Thêm nhân viên
          </p>
          <LayoutCard className="l-mt-8">
            <LayoutCardSection className="flex-row">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  {intl.formatMessage({
                    id: "settings.staffs.Thêm mới và cấp quyền quản lý cho nhân viên tại trang quản trị MyHaravan",
                    defaultMessage:
                      "Thêm mới và cấp quyền quản lý cho nhân viên tại trang quản trị MyHaravan",
                  })}
                </p>
                <p className="text-secondary">
                  {intl.formatMessage({
                    id: "settings.staffs.Trang quản trị MyHaravan sẽ là nơi quản lý tập trung cho toàn bộ hệ thống vận hành của cửa hàng",
                    defaultMessage:
                      "Trang quản trị MyHaravan sẽ là nơi quản lý tập trung cho toàn bộ hệ thống vận hành của cửa hàng",
                  })}
                </p>
              </div>
              <Button
                status="primary"
                icon="open_new"
                title="Thêm nhân viên"
                onClick={() => window.open(omnipower_account, "_blank")}
              />
            </LayoutCardSection>
          </LayoutCard>
        </div>
      </LayoutCardSection>
    </LayoutCard>
  );
}

export default UserSetting;
