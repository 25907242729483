import React, { Fragment, useState } from "react";
import * as HrvComponents from "@haravan/react-components";
import ImportOrderAlert from "./import-order-alert";
import { useIntl } from "react-intl";
import "./style.css";
import Button from "component-v2/buttons";

export default function ImportCartItems() {
  const [show, setShow] = useState(false);
  const intl = useIntl();

  return (
    <Fragment>
      <HrvComponents.Tooltip
        placement="bottomRight"
        title={intl.formatMessage({
          id: "carts.orders.Nhập đơn hàng",
          defaultMessage: "Nhập đơn hàng",
        })}
        overlayClassName="tooltip-custom"
      >
        <Button
          size="sm"
          light
          icon="icon_import"
          title="Nhập sản phẩm"
          className="l-ml-8 btn-import-order"
          onClick={() => setShow(true)}
        />
      </HrvComponents.Tooltip>
      <ImportOrderAlert intl={intl} show={show} setShow={setShow} />
    </Fragment>
  );
}
