const statuses = [
  "gray",
  "blue",
  "green",
  "red",
  "orange",
  "yellow",
  "teal",
  "cyan",
  "purple",
  "pink",
  "default",
  "warning",
  "error",
  "success",
  "info",
];
const sizes = ["S", "M", "L"];
/**
 * @param status : 'gray', 'blue', 'green', 'red', 'orange', 'yellow', 'teal', 'cyan', 'purple', 'pink', 'default', 'warning', 'error', 'success', 'info'
 * phát triển thêm: size, overlay + positions
 */
export default function Badge({
  style = null,
  children,
  className,
  status,
  size = "M",
  light = false,
  overlay = false,
  rounded = false,
}) {
  let _class_name = "";
  if (rounded) _class_name += " badge-rounded";
  status = status?.toLowerCase();
  status = statuses.find((stt) => stt === status) ? status : "default";
  if (light) _class_name += ` badge-light-${status}`;
  else _class_name += ` badge-${status}`;

  size = size ? size.toUpperCase() : "";
  if (sizes.find((s) => s === size)) _class_name += ` badge-${size}`;

  if (overlay) _class_name += " badge-overlay";

  if (className) _class_name += ` ${className}`;
  return (
    <span style={style} className={`omni-badge${_class_name}`}>
      {children}
    </span>
  );
}
