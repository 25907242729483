import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";

export default function DailyReportSummaries({
  loading,
  summaries,
  selectedItem,
  updateSelectedItem,
}) {
  const intl = useIntl();
  if (!summaries) return null;

  return (
    <div className="position-relative">
      {loading ? <LoadingCircle overlay /> : null}
      <table className="mt-4 daily-report-sumaries-table">
        <thead>
          <tr>
            <th className="text-left">
              {intl.formatMessage({
                id: "reports.daily-report.Phương thức",
                defaultMessage: "Phương thức",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "reports.daily-report.Thực nhận",
                defaultMessage: "Thực nhận",
              })}
            </th>
            <th className="content-tertiary">
              {intl.formatMessage({
                id: "reports.daily-report.Tổng thu",
                defaultMessage: "Tổng thu",
              })}
            </th>
            <th className="content-tertiary">
              {intl.formatMessage({
                id: "reports.daily-report.Tổng chi",
                defaultMessage: "Tổng chi",
              })}
            </th>
          </tr>
        </thead>
        <tbody className="position-relative">
          <Data
            intl={intl}
            data={summaries}
            selected={selectedItem}
            updateSelected={updateSelectedItem}
          />
        </tbody>
      </table>
    </div>
  );
}

function Data({ intl, data, selected, updateSelected }) {
  if (!data || !data.length) return null;
  return data.map((summary) => {
    let active = false;
    if (summary.accountId === selected?.accountId) active = true;
    return (
      <tr
        key={"rprts-smmrs-" + summary.accountId}
        className={active ? "active" : ""}
        onClick={() => {
          if (active) return;
          updateSelected(summary);
        }}
      >
        <td style={{ maxWidth: "300px" }}>
          <span className="text-break">
            {summary.accountId === 0
              ? intl.formatMessage({
                  id: "reports.daily-report.Tất cả",
                  defaultMessage: "Tất cả",
                })
              : summary.accountName}
          </span>
        </td>
        <td>
          <CurrencyShow value={summary.totalRemain} colored />
        </td>
        <td>
          <CurrencyShow value={summary.totalReceived} />
        </td>
        <td>
          <CurrencyShow value={summary.totalExpenses} />
        </td>
      </tr>
    );
  });
}
