export const updateCustomerAddressDefault = async (
  callAPI,
  customerId,
  addressId
) => {
  return await callAPI(
    "put",
    `/call/com_api/customers/${customerId}/addresses/${addressId}/default`
  );
};

export const updateCustomerInfo = async (callAPI, customerId, body) => {
  return await callAPI(
    "put",
    `/call/com_api/customers/${customerId}/info`,
    body
  );
};

export const getCurrentOrderByCustomerId = async (callAPI, customerId) => {
  return await callAPI(
    "get",
    `/call/com_api/orders/?page=1&limit=10&customer_ids=${customerId}`
  );
};

export const updateCustomerAddress = async (
  callAPI,
  customerId,
  addressId,
  body
) => {
  return await callAPI(
    "put",
    `/call/com_api/customers/${customerId}/addresses/${addressId}`,
    body
  );
};

export const createCustomerAddress = async (callAPI, customerId, body) => {
  return await callAPI(
    "post",
    `/call/com_api/customers/${customerId}/addresses`,
    body
  );
};

export const createCustomer = async (callAPI, body) => {
  return await callAPI("post", "/call/com_api/pos/customers", body);
};

export const getCustomers = async (callAPI, searchText = "", page = 1) => {
  return await callAPI(
    "get",
    `/call/com_api/orders/customers/simple?query=${searchText}&page=${page}&isAdjustment=false&acceptUnTrackInventory=true&limit=10`
  );
};

export const getCustomerDetails = async (callAPI, customerId) => {
  return await callAPI(
    "get",
    `/call/com_api/orders/customers/${customerId}/simple`
  );
};

export const postCustomerLoyaltyPoint = async (
  callAPI,
  loyaltyId,
  totalMoney
) => {
  return await callAPI(
    "post",
    `/call/loy_api/v1/customerprofiles/${loyaltyId}/printinfo`,
    { amount_spent: totalMoney }
  );
};

export const getLoyaltyProfile = async (
  appContext,
  customer_id,
  has_promotion
) => {
  try {
    const resultData = await appContext.callAPI(
      "get",
      `/call/loy_api/v1/redeems/discover/?customer_id=${customer_id}&has_promotion=${has_promotion}`
    );
    if (resultData.status === 1) {
      return resultData.data;
    }
  } catch (error) {}
};
