import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";

export default function DailyReportSummaryCards({ summary }) {
  const intl = useIntl();

  return (
    <div className="row m-0">
      <div className="col-4 m-0 p-0">
        <div className="report-summary-card">
          <div className=" content-tertiary">
            {intl.formatMessage({
              id: "reports.daily-report.Thực nhận",
              defaultMessage: "Thực nhận",
            })}
          </div>
          <div
            className="report-summary-card--total"
            style={{ color: "#16A249" }}
          >
            {summary ? (
              <CurrencyShow value={summary.totalRemain} colored />
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-4 m-0">
        <div className="report-summary-card">
          <div className=" content-tertiary">
            {intl.formatMessage({
              id: "reports.daily-report.Tổng thu",
              defaultMessage: "Tổng thu",
            })}
          </div>
          <div className="report-summary-card--total">
            {summary ? <CurrencyShow value={summary.totalReceived} /> : null}
          </div>
        </div>
      </div>
      <div className="col-4 m-0 p-0">
        <div className="report-summary-card">
          <div className=" content-tertiary">
            {intl.formatMessage({
              id: "reports.daily-report.Tổng chi",
              defaultMessage: "Tổng chi",
            })}
          </div>
          <div className="report-summary-card--total">
            {summary ? <CurrencyShow value={summary.totalExpenses} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
