import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { filter } from "lodash";

import { VariantSearchBox } from "./VariantSearchBox.component";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Icon from "component-v2/icons/icons";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import NumberShow from "component-v2/number-show";
import "./variant.style.css";

export const PopupVariant = (props) => {
  const intl = useIntl();
  const [variants, setVariants] = useState(null);

  useEffect(() => {
    if (props.product.variants) {
      setVariants(props.product.variants);
    }
  }, [props.product]);

  const onSearchVariant = (e) => {
    if (!e.target.value) {
      setVariants(props.product.variants);
      return;
    }
    let _keyword = e.target.value ? e.target.value.toLowerCase().trim() : "";
    let resFiltered = filter(props.product.variants, (item) => {
      return item.variantTitle.toLowerCase().trim().indexOf(_keyword) !== -1;
    });
    setVariants(resFiltered);
  };

  const onItemClick = (value) => {
    if (props.onClick) {
      props.onClick(props.product, value);
    }
    props.setShow(false);
  };

  const renderChild = (item, index) => {
    const { hideOutStock, allowOrderOutStock } = props;
    if (
      item.isTrackingInventory &&
      item.quantity <= 0 &&
      hideOutStock &&
      !allowOrderOutStock
    ) {
      return null;
    }

    if (item.isTrackingInventory && item.quantity <= 0 && !allowOrderOutStock) {
      return (
        <li
          className="list-group-item pointer"
          key={index}
          onClick={() => {
            onItemClick(item);
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="text-black">{item.variantTitle}</p>
            <p className="product-item-quantity badge-sold-out">Hết hàng</p>
          </div>
        </li>
      );
    }

    if (item.isTrackingInventory) {
      return (
        <li
          className="list-group-item pointer"
          key={index}
          onClick={() => {
            onItemClick(item);
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="text-black">{item.variantTitle}</p>
            {item.quantity > 0 ? (
              <Badge status="gray" light rounded className="mr-3 prod-tag">
                <span className="content-secondary">
                  <NumberShow value={item.quantity} />
                </span>
              </Badge>
            ) : (
              <Badge status="error" light rounded className="mr-3 prod-tag">
                <span className="text-danger ">
                  <NumberShow value={item.quantity} />
                </span>
              </Badge>
            )}
          </div>
        </li>
      );
    } else {
      return (
        <li
          className="list-group-item pointer"
          key={index}
          onClick={() => {
            onItemClick(item);
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="text-black">{item.variantTitle}</p>
            <Badge status="info" light rounded className="mr-3 prod-tag">
              <span className="text-primary ">
                <Icon name="infinity" color="var(--contentAccent)" />
              </span>
            </Badge>
          </div>
        </li>
      );
    }
  };

  const body = () => {
    return (
      <div id="popup-variant">
        <VariantSearchBox
          icon={<Icon name={"search"} />}
          onChange={onSearchVariant}
          placeholder="Tìm biến thể sản phẩm"
        />
        <div className="prod-pb-16 " />
        <ul
          className="list-group scrollable-y list-group-flush"
          style={{ height: "80%", overflowY: "auto" }}
        >
          {variants &&
            variants.map((value, index) => {
              return renderChild(value, index);
            })}
          {variants && variants.length === 0 ? (
            <li className="list-group-item">
              <p className="font-weight-medium font-size-16 text-center">
                {intl.formatMessage({
                  id: "carts.orders.Không tìm thấy kết quả",
                  defaultMessage: "Không tìm thấy kết quả",
                })}
              </p>
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  return (
    <GlobalModal
      headerTitle={intl.formatMessage({
        id: "carts.orders.Chọn biến thể",
        defaultMessage: "Chọn biến thể",
      })}
      body={body()}
      show={props.isToggele}
      setShow={props.setShow}
      headerClose={true}
    />
  );
};

PopupVariant.propTypes = {
  isToggele: PropTypes.bool,
  setShow: PropTypes.func,
  product: PropTypes.object,
  onClick: PropTypes.func,
  hideOutStock: PropTypes.bool,
  allowOrderOutStock: PropTypes.bool,
};
