import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useShift } from "contexts/shift/shift-context";
import { getCustomerName } from "utils/Commons";
import { useCartContext } from ".";
import React from "react";
import { TeleportElementHeader } from "utils/TeleportElementHeader";
import { MAX_TAB_COUNT } from "./utils";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_SCOPES } from "models/constants";
import { useHistory } from "react-router-dom";
import Tooltip from "rc-tooltip";

export default function CartTabs() {
  const { shop_setting, current_location } = useApp();
  const cartContext = useCartContext();
  const { shift, closeShift, openShift } = useShift();
  const history = useHistory();

  useGlobalHotkeys({
    keys: [HOTKEYS.F1],
    callback: (e) => {
      cartContext.initNewCart();
    },
    enabled: cartContext?.shopping_carts?.length < MAX_TAB_COUNT,
    scopes: HOTKEY_SCOPES.CART,
  });

  const mainContent = () => {
    return (
      <TeleportElementHeader>
        <div className="carts-header">
          <div className="carts-tabs">
            {cartContext?.shopping_carts && cartContext?.shopping_carts.length
              ? cartContext.shopping_carts.map((cart) => {
                  let current =
                    cart?.key === cartContext.current_shopping_cart?.key;
                  let title = cart?.title;
                  //// tạm bỏ rule tab hiển thị theo tên Khách hàng
                  // if (cart.customer)
                  //   title = getCustomerName(
                  //     cart.customer.lastName,
                  //     cart.customer.firstName,
                  //     cart.customer.phone,
                  //     cart.customer.email
                  //   );
                  let widthCartTab =
                    cartContext?.shopping_carts.length > 1 ? "120px" : "unset";
                  return (
                    <div
                      key={cart.key}
                      className={`font-weight-semibold justify-content-between carts-tabs--tab${
                        current ? " selected" : ""
                      }`}
                      style={{ width: widthCartTab }}
                      onClick={() => {
                        if (current) return;
                        cartContext.switchCart(cart.key);
                      }}
                    >
                      <span>{title}</span>
                      {cartContext.shopping_carts.length > 1 ? (
                        <Button
                          className={"-mr-4px"}
                          icon="close"
                          plain
                          size="xs"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            cartContext.removeCart(cart.key, cart.id);
                          }}
                        />
                      ) : null}
                    </div>
                  );
                })
              : null}
            {cartContext?.shopping_carts?.length < MAX_TAB_COUNT ? (
              <div className="carts-tabs--tab-add">
                <Button
                  icon="add"
                  plain
                  size="sm"
                  onClick={() => {
                    cartContext.initNewCart();
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className="carts-extra">
            {shop_setting.shiftEnable && shift ? (
              <>
                {shift.status !== "empty" ? (
                  <Button
                    title="Đóng ca"
                    size="sm"
                    light
                    rounded
                    icon="power_setting_new"
                    className="ml-3"
                    onClick={() => closeShift()}
                  />
                ) : (
                  <Button
                    title="Mở ca"
                    status="primary"
                    size="sm"
                    rounded
                    icon="power_setting_new"
                    className="ml-3"
                    onClick={() => openShift()}
                  />
                )}
              </>
            ) : null}
          </div>
        </div>
      </TeleportElementHeader>
    );
  };

  return mainContent();
}
