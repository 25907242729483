import moment from "moment";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import { LayoutCardSection } from "screens-v2/layouts/layouts/omni-layout-card";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import DateGroup from "../components/date-group";
import OrderListItem from "./order-list-item";
import "../utils/index.css";
import EmptyState from "component-v2/empty-state";

export default function OrderListItems({
  loading,
  orders,
  page,
  updatePage,
  maxPage,
  currentOrderDetail,
}) {
  const intl = useIntl();
  if (!loading && (!orders || !orders.length)) return <NoData intl={intl} />;
  return (
    <div
      className="px-0 order-list--list-items"
      onScroll={(e) => {
        if (!loading && page < maxPage) {
          const bottom =
            e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 3;
          if (bottom) {
            updatePage(page + 1);
          }
        }
      }}
    >
      <div className="order-state--item">
        <ul className="list-group">
          {orders && orders.length
            ? orders.map((order, idx) => {
                if (
                  currentOrderDetail &&
                  order.orderId === currentOrderDetail.orderId
                ) {
                  order = currentOrderDetail;
                  // order.shipmentStatus = currentOrderDetail.shipmentStatus;
                  // order.paymentStatusId = currentOrderDetail.paymentStatusId;
                }
                const prev_date =
                  idx === 0
                    ? ""
                    : new Date(
                        moment(orders[idx - 1].orderDate),
                      ).toDateString();
                const curr_date = new Date(
                  moment(order.orderDate),
                ).toDateString();

                return (
                  <Fragment key={order.orderId}>
                    {curr_date !== prev_date ? (
                      <p className="order-state--title text-secondary">
                        <DateGroup date={curr_date} />
                      </p>
                    ) : null}
                    <OrderListItem order={order} />
                  </Fragment>
                );
              })
            : null}
        </ul>
      </div>
      {loading ? (
        <LoadingCircle />
      ) : (
        <div className="m-4 p-4 text-center font-italic content-tertiary">
          {intl.formatMessage({
            id: "orders.Tất cả đơn hàng đã được hiển thị",
            defaultMessage: "Tất cả đơn hàng đã được hiển thị",
          })}
        </div>
      )}
    </div>
  );
}

function NoData({ intl }) {
  return (
    <LayoutCardSection className="px-0 order-list--list-items text-center">
      <EmptyState
        iconName="shopping_cart"
        description={intl.formatMessage({
          id: "orders.searches.Không tìm thấy đơn hàng!",
          defaultMessage: "Không tìm thấy đơn hàng!",
        })}
      />
    </LayoutCardSection>
  );
}
