import { ENDPOINT_GATEWAY, useGetData } from "api/api-connect-base";
import listCountries from "@haravan/countries"
import { useEffect, useState } from "react";
export const useAddressSuggestions = ({ query }) => {
  const { data: suggestions } = useGetData(ENDPOINT_GATEWAY.commerce, query ? `search_services/address?query=${query}` : null)
  return { suggestions }
}
const mappedListCountries = listCountries.countries?.map(c => ({ id: c.i, name: c.n, code: c.c, provinces: c.provinces }));
const useAddressData = ({ countryId = 241, provinceId, districtId }) => {
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  useEffect(() => {
    setCountries(mappedListCountries || [])
  }, [])
  useEffect(() => {
    if (countryId && !!countries?.length) {
      const res = countries.find(c => c.id === countryId);
      const mapped = res?.provinces?.map(
        (p) => ({ id: p.i, name: p.n, code: p.c, ...p }
        ))
      setProvinces(mapped)
    } else {
      setProvinces([])
    }
  }, [countryId, countries]);

  useEffect(() => {
    if (countryId && provinceId && !!provinces?.length) {
      provinces?.find(p => p.id === provinceId)?.load().then(res => {
        const mapped = res?.districts?.map(
          (d) => ({ id: d.i, name: d.n, code: d.c, ...d }
          ))
        setDistricts(mapped)
      });
    } else {
      setDistricts([])
    }
  }, [countryId, provinceId, provinces]);

  useEffect(() => {
    if (countryId && provinceId && districtId && !!districts?.length) {
      const res = districts?.find(d => d.id === districtId)
      const mapped = res?.wards?.map(
        (w) => ({ id: w.i, name: w.n, code: w.c, ...w })
      );
      setWards(mapped)
    } else {
      setWards([])
    }
  }, [countryId, provinceId, districtId, districts])

  return { countries, provinces, districts, wards }
}

export default useAddressData