import * as HrvComponents from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import { toNumber } from "lodash";
import { useMemo } from "react";
import { useCartContext } from "screens-v2/carts-v2";
import { InputNumber } from "../components/InputNumber";
import { SelectionCustom } from "../components/SelectionCustom";
import Logo from "component-v2/logos/logos";
import { PaymentAuthCode } from "./paymentAuthCode";
import { BANK_QR_MIN_AMOUNT, MOMO_MIN_AMOUNT } from "models/constants";
import { PaymentQrMethods } from "models/payment-methods-model";

const AMOUNT_MAX = 50000000000;

export const PaymentMethodItem = ({
  method,
  handleRemovePaymentMethod,
  width,
  totalPaymentMethod,
}) => {
  const { current_shopping_cart, updateCurrentCart } = useCartContext();

  const hasMethodOnlyActivated = useMemo(
    () =>
      current_shopping_cart?.payment_methods?.some(
        (m) => m.enable && m.hasOnly,
      ),
    [current_shopping_cart?.payment_methods],
  );

  if (!current_shopping_cart?.payment_methods) return null;
  const { payment_methods } = current_shopping_cart;

  function handleSelectPaymentMethod(type) {
    let currentPaymentMethods = [...payment_methods];
    const oldMethodIndex = currentPaymentMethods.findIndex(
      (current) => current.type === method.type,
    );
    const oldMethod = currentPaymentMethods[oldMethodIndex];
    const newMethodIndex = currentPaymentMethods.findIndex(
      (current) => current.type === type,
    );
    const newMethod = currentPaymentMethods[newMethodIndex];
    const codMethodActivated = currentPaymentMethods.find(
      (current) => current.type === "cod" && current.enable,
    );

    if (newMethod.type === "cod" || codMethodActivated) {
      currentPaymentMethods = currentPaymentMethods.map((current) => ({
        ...current,
        enable: false,
      }));
    }

    if (newMethod.hasOnly) {
      currentPaymentMethods = currentPaymentMethods.map((current) => ({
        ...current,
        enable: current.hasOnly ? false : current.enable,
        amount: current.hasOnly ? 0 : current.amount,
      }));
    }

    if (oldMethodIndex !== -1) {
      currentPaymentMethods[oldMethodIndex] = {
        ...oldMethod,
        enable: false,
        amount: 0,
      };
    }
    if (newMethodIndex !== -1) {
      let amount = 0;
      if (newMethod.type === "cod") {
        amount = 0;
      } else if (oldMethod.type === "cod") {
        amount = current_shopping_cart?.summaries?.total_payment || 0;
      } else if (newMethod.type !== "cash" && oldMethod.type === "cash") {
        amount = validGatewayAmount(
          newMethod.type,
          current_shopping_cart?.summaries?.total_payment,
        );
      } else {
        amount = oldMethod.amount;
      }
      if (newMethod.isQR) {
        currentPaymentMethods = currentPaymentMethods.map((current) => ({
          ...current,
          enable: false,
        }));
        amount = current_shopping_cart?.summaries?.total_payment;
      }
      currentPaymentMethods[newMethodIndex] = {
        ...newMethod,
        enable: true,
        amount: amount,
        index: oldMethod.index,
      };
    }
    updateCurrentCart({ payment_methods: currentPaymentMethods });
  }

  function handleOnChangeMethodAmount(
    value,
    type,
    isAuthorizationCode = false,
  ) {
    const new_payment_methods = [...payment_methods];

    let currentPaymentMethodIndex = new_payment_methods.findIndex(
      (method) => method.type === type,
    );
    if (currentPaymentMethodIndex !== -1) {
      if (isAuthorizationCode) {
        new_payment_methods[currentPaymentMethodIndex].transaction_id = value;
      } else {
        new_payment_methods[currentPaymentMethodIndex].amount =
          validGatewayAmount(type, value);
      }
    }
    updateCurrentCart({ payment_methods: new_payment_methods });
  }

  /**
   * Validate số tiền user nhập
   */
  const validGatewayAmount = (type, amount) => {
    amount = toNumber(amount);
    const gwAmount = current_shopping_cart?.payment_methods?.reduce(
      (total, method) => {
        if (
          method.type !== "cash" &&
          method.type !== "point" &&
          method.type !== type &&
          method.enable
        ) {
          return total + method.amount;
        }
        return total;
      },
      0,
    );
    const priceSubTotal = current_shopping_cart?.summaries?.total_payment;
    const remainingAmount = +Number(
      priceSubTotal -
        current_shopping_cart?.payment_methods?.reduce((total, method) => {
          if (method.type !== type) {
            return total + method.amount;
          }
          return total;
        }, 0),
    ).toFixed(4);
    const amountMax = gwAmount < priceSubTotal ? priceSubTotal - gwAmount : 0;
    if (type !== "cash") {
      if (
        (amount > remainingAmount - 1 && amount < remainingAmount) ||
        (amount < remainingAmount + 1 && amount > remainingAmount)
      ) {
        amount = remainingAmount;
      }
      if (amount > amountMax) {
        amount = amountMax;
      }
    } else {
      if (
        (amount > remainingAmount - 1 && amount < remainingAmount) ||
        (amount < remainingAmount + 1 && amount > remainingAmount)
      ) {
        amount = remainingAmount;
      }
      if (amount > AMOUNT_MAX) {
        amount = AMOUNT_MAX;
      }
    }
    return amount;
  };

  return (
    <div className="l-pt-4 l-pb-4">
      <div className="d-flex align-items-center">
        <div className="flex-auto w-45">
          <SelectionCustom
            value={method.type}
            options={payment_methods}
            idField="type"
            valueField="d_name"
            onChange={(type) => handleSelectPaymentMethod(type)}
            width={width}
            prefix={
              method.isCustom ? (
                <HrvComponents.Avatar
                  arrColor="gray"
                  className="text-primary d-flex section-20"
                  shape="circle"
                  content={method.name}
                />
              ) : (
                <Logo
                  name={
                    method.isCustom
                      ? "account_balance_wallet"
                      : `${method.type}_xs`
                  }
                  size="xs"
                  className="d-flex"
                />
              )
            }
          >
            {payment_methods
              .filter((m) => !m.isHidden)
              .map((m, i) => {
                const isDisabled =
                  (!method.hasOnly && m.hasOnly && hasMethodOnlyActivated) ||
                  (m.type !== method.type && m.enable) ||
                  (!current_shopping_cart.isShipping && m.type === "cod") ||
                  (current_shopping_cart?.summaries?.total_payment <
                    MOMO_MIN_AMOUNT &&
                    m.paymentInstructionTypeId === PaymentQrMethods.momo_qr) ||
                  (current_shopping_cart?.summaries?.total_payment <
                    BANK_QR_MIN_AMOUNT &&
                    m.isQR &&
                    m.paymentInstructionTypeId !== PaymentQrMethods.momo_qr);
                return (
                  <SelectionCustom.Option
                    key={`${m.type}-${i}`}
                    value={m.type}
                    disabled={isDisabled}
                  >
                    <div className="d-flex align-items-center">
                      {m.isCustom ? (
                        <HrvComponents.Avatar
                          arrColor="gray"
                          className="text-primary mr-3 d-inline-block"
                          shape="circle"
                          content={m.name}
                        />
                      ) : (
                        <Logo name={m.type} size={"md"} className="mr-3" />
                      )}
                      {m.d_name}
                    </div>
                  </SelectionCustom.Option>
                );
              })}
          </SelectionCustom>
        </div>
        <div className="flex-stretch l-ml-4">
          {method.hasAuthorizationCode && (
            <PaymentAuthCode
              code={method.transaction_id}
              onUpdate={(value) =>
                handleOnChangeMethodAmount(value, method.type, true)
              }
            />
          )}
        </div>
        <div className="flex-auto w-30">
          <InputNumber
            currency
            value={Math.round(method.amount)}
            onChange={(value) => {
              handleOnChangeMethodAmount(value, method.type);
            }}
            disabled={method.type === "cod" || method.isQR}
          />
        </div>

        {totalPaymentMethod > 1 && (
          <div
            className="flex-auto cursor-pointer l-ml-4 btn-close l-ml-8"
            onClick={handleRemovePaymentMethod}
            style={{ height: "max-content", padding: "0.2rem" }}
          >
            <Icon name="close" />
          </div>
        )}
      </div>
    </div>
  );
};
