export const ScaleBarcodeMode = new Map([
  [1, {
    name: '2-5-5-1',
    barcodeLength: 5,
    desc: 'SBM_2551'
  }],
  [2, {
    name: '2-6-4-1',
    barcodeLength: 6,
    desc: 'SBM_2641'
  }],
  [3, {
    name: '2-4-6-1',
    barcodeLength: 4,
    desc: 'SBM_2461'
  }],
  [4, {
    name: '2-6-5-1',
    barcodeLength: 6,
    desc: 'SBM_1651'
  }],
  [5, {
    name: '2-5-6-1',
    barcodeLength: 5,
    desc: 'SBM_1561'
  }],
]);