import Icon from "component-v2/icons/icons";
import { useLayoutContext } from "contexts/Layout/layout-context";
import React from "react";

const GlobalToast = () => {
  const toastContext = useLayoutContext();
  const { type, message, show, showLeadIcon = false } = toastContext.toast;

  const iconType = React.useMemo(() => {
    let iconName = "";
    switch (type) {
      case "info":
        iconName = "info";
        break;
      case "success":
        iconName = "check_circle";
        break;
      case "alert":
        iconName = "warning";
        break;
      case "error":
        iconName = "info";
        break;
      default:
        iconName = "info";
        break;
    }

    return iconName;
  }, [type]);

  return (
    <div id="global-toasts" className={`l-toasts${show ? " show" : ""}`}>
      <div className={`l-toast l-toast-${type}`}>
        {showLeadIcon && (
          <Icon
            size="xs"
            name={iconType}
            color="var(--white)"
            className={"mr-2"}
          ></Icon>
        )}
        <span>{message}</span>
      </div>
    </div>
  );
};

export default GlobalToast;
