import React from "react";
import PropTypes from "prop-types";
import ModalAddNewAccount from "./modal-add-new-account";
import ModalAddNewReason from "./modal-add-new-reason";
import ModalAddNewObjectOther from "./modal-add-new-object-other";
import { paymentVoucherCreateModal } from "./payment-voucher-constant";

const RenderModal = ({
  openModal,
  formData,
  isEditModal,
  closeModal,
  bankList,
  reasons,
  objectOthers,
  handleCallApiSuccess,
  isCashReceipt,
}) => {
  const hanldeCallbackApiSuccess = (id) => {
    if (id) {
      handleCallApiSuccess(false);
    } else {
      handleCallApiSuccess(true);
    }
  };

  return (
    <>
      <ModalAddNewAccount
        isOpen={openModal === paymentVoucherCreateModal.ACCOUNT}
        isEditModal={formData[isEditModal]}
        editData={
          bankList.find((item) => item.id === formData[isEditModal]) || []
        }
        closeModal={() => {
          closeModal();
        }}
        handleCallApiSuccess={async (id) => {
          hanldeCallbackApiSuccess(id);
        }}
        isCashReceipt={isCashReceipt}
      />
      <ModalAddNewReason
        isOpen={openModal === paymentVoucherCreateModal.REASON}
        isEditModal={isEditModal}
        editData={reasons.find((item) => item.id === formData.subTypeId) || []}
        closeModal={() => {
          closeModal();
        }}
        handleCallApiSuccess={async (id) => {
          hanldeCallbackApiSuccess(id);
        }}
        isCashReceipt={isCashReceipt}
      />
      <ModalAddNewObjectOther
        isOpen={openModal === paymentVoucherCreateModal.OTHER}
        isEditModal={isEditModal}
        editData={
          objectOthers.find((item) => item.id === formData.objectId) || []
        }
        closeModal={() => {
          closeModal();
        }}
        handleCallApiSuccess={async (id) => {
          hanldeCallbackApiSuccess(id);
        }}
        isCashReceipt={isCashReceipt}
      />
    </>
  );
};

RenderModal.propTypes = {
  openModal: PropTypes.string,
  formData: PropTypes.object,
  isEditModal: PropTypes.any,
  closeModal: PropTypes.func,
  bankList: PropTypes.array,
  reasons: PropTypes.array,
  objectOthers: PropTypes.array,
  handleCallApiSuccess: PropTypes.func,
};

export default RenderModal;
