import { remove, concat } from "lodash";
import { FormattedMessage } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import React, { useEffect, useState } from "react";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { InputDecimal } from "component-v2/Input";
import { useHotkeysContext } from "react-hotkeys-hook";
import { HOTKEY_SCOPES } from "models/constants";

export default function RetryErrorOrderForm({
  intl,
  show,
  setShow,
  dataSuccess,
  setDataSuccess,
  dataSheet,
  setDataSheet,
  dataError,
  setDataError,
  setCurrentForm,
  addOrderLine,
  checkProductInWorkSheet,
}) {
  const [showModal, setShowModal] = useState(null);
  const { enableScope } = useHotkeysContext();
  useEffect(() => {
    if (show) setShowModal(true);
  }, [show]);
  useEffect(() => {
    if (showModal === false) handleCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const updateQuantityOrderLineError = (orderLineIndex, quantity) => {
    let currentOrderLines = [...dataError];
    currentOrderLines[orderLineIndex].quantity = quantity;
    setDataError(currentOrderLines);
  };

  const updateCodeOrderLineError = (orderLineIndex, code) => {
    let currentOrderLines = [...dataError];
    currentOrderLines[orderLineIndex].code = code;
    setDataError(currentOrderLines);
  };

  const removeOrderLineError = (orderLine, orderLineIndex) => {
    let currentOrderLines = [...dataError];
    let currentSheet = [...dataSheet];
    const afterSheet = remove(currentSheet, (e) => e.id !== orderLine.id);
    currentOrderLines.splice(orderLineIndex, 1);
    setDataError(currentOrderLines);
    setDataSheet(afterSheet);
  };

  const handleClickTryAgain = async (records = []) => {
    if (!records || records.length === 0) {
      addOrderLine(dataSuccess);
    } else {
      setCurrentForm(2);
      const { productListSuccess, productListError } =
        await checkProductInWorkSheet(records);
      if (productListSuccess && productListSuccess.length) {
        const listProductsSuccess = concat(dataSuccess, productListSuccess);
        setDataSuccess(listProductsSuccess);
      }
      if (productListError && productListError.length) {
        setDataError(productListError);
        setCurrentForm(3);
      } else {
        const listProducts = concat(dataSuccess, productListSuccess);
        addOrderLine(listProducts);
      }
    }
  };
  const handleCancel = () => {
    setShow(false);
    enableScope(HOTKEY_SCOPES.CART);
    setDataSuccess([]);
  };

  const body = () => (
    <div>
      <p className="content-primary mb-2">
        <FormattedMessage
          id="carts.orders.Đã kiểm tra thành công {a} sản phẩm"
          defaultMessage="Đã kiểm tra thành công {a} sản phẩm"
          values={{
            a: (
              <span className="font-weight-semibold ">
                {dataSuccess.length}/{dataSheet.length}
              </span>
            ),
          }}
        />
      </p>
      <p className="content-primary" style={{ marginBottom: "1.6rem" }}>
        <FormattedMessage
          id="carts.orders.Vui lòng kiểm tra lại thông tin các sản phẩm bị lỗi."
          defaultMessage="Vui lòng kiểm tra lại thông tin các sản phẩm bị lỗi."
        />
      </p>
      <div className="table-list-errors-wrapper">
        <table className="table table-list-errors">
          <thead>
            <tr>
              <th>
                {intl.formatMessage({
                  id: "carts.orders.STT",
                  defaultMessage: "STT",
                })}
              </th>
              <th>Barcode/SKU</th>
              <th>
                {intl.formatMessage({
                  id: "carts.orders.Số lượng",
                  defaultMessage: "Số lượng",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "carts.orders.Trạng thái",
                  defaultMessage: "Trạng thái",
                })}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="scrollable-y">
            {dataError.map((item, index) => {
              return (
                <tr key={index} className="import-order-item">
                  <td className="text-default">{item.location}</td>
                  <td className="text-default">
                    <div className="input-group h-100 import-input">
                      <input
                        type="text"
                        className="form-control input-field"
                        placeholder={intl.formatMessage({
                          id: "carts.orders.Nhập barcode/SKU",
                          defaultMessage: "Nhập barcode/SKU",
                        })}
                        value={item.code}
                        onChange={(e) =>
                          updateCodeOrderLineError(index, e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td className="text-default">
                    <div className="d-flex align-items-center">
                      <span
                        onClick={() => {
                          item.quantity > 1 &&
                            updateQuantityOrderLineError(
                              index,
                              Number(item.quantity) - 1,
                            );
                        }}
                        className="cursor-pointer"
                      >
                        <Icon
                          name="remove"
                          color={
                            item.quantity > 1
                              ? "var(--coolgray600)"
                              : "var(--coolgray300)"
                          }
                        />
                      </span>
                      <InputDecimal
                        className="form-control hrv-input-sm text-center input-field mx-3 max-width-100px"
                        value={item.quantity}
                        onChange={(event) => {
                          updateQuantityOrderLineError(
                            index,
                            event.target.value,
                          );
                        }}
                        min={0}
                        max={99999}
                      />
                      <span
                        onClick={() => {
                          updateQuantityOrderLineError(
                            index,
                            Number(item.quantity) + 1,
                          );
                        }}
                        className="cursor-pointer"
                      >
                        <Icon name="add" color={"var(--coolgray600)"} />
                      </span>
                    </div>
                  </td>
                  <td className="text-import--error">
                    <Badge status={"warning"} light>
                      {item.messageError}
                    </Badge>
                  </td>
                  <td className="text-right">
                    <Button
                      size="sm"
                      light
                      status="default"
                      onClick={() => removeOrderLineError(item, index)}
                      title={intl.formatMessage({
                        id: "carts.orders.Xóa",
                        defaultMessage: "Xóa",
                      })}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <GlobalModal
      size="lg"
      headerTitle={intl.formatMessage({
        id: "carts.orders.Lỗi nhập sản phẩm hàng loạt",
        defaultMessage: "Lỗi nhập sản phẩm hàng loạt",
      })}
      body={body()}
      show={showModal}
      setShow={setShowModal}
      onCancel={() => handleCancel()}
      onConfirm={() => handleClickTryAgain(dataError)}
      cancelTitle="Đóng"
      confirmTitle="Thử lại"
    />
  );
}
