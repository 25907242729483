import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import JsBarcode from "jsbarcode";
import { ERRORS } from "models/constants";
import { QRCodeSVG } from "qrcode.react";
import { toPaymentDisplayName } from "infrastructures/utils";
import { generatePaymentUrl, showOnlinePayment } from "utils/Commons";
import Button from "component-v2/buttons";
import { printOrder, TemplateType } from "./print-template";
import { Loading } from "@haravan/react-components";
import "./index.css";
import { useApp } from "contexts/app";
import { cloneDeep } from "lodash";
import { DiscountAllocationType } from "screens-v2/carts-v2/cart-helpers.service";

function PrintButton({
  orderData,
  sessionData,
  isOrderPrintBill,
  orderId,
  isNewOrder = false,
  totalRefund = null,
  refundOrderModel = null,
  isAutoPrint = false,
  isShowTitleButton = false,
  size = "md",
  hasTitle = false,
  currentRefund,
  refundSessionData,
  isDetail = false,
  onSetVATStatus = () => {},
  isPaymentOnline = false,
  placementTooltip,
  taxData,
}) {
  const appContext = useApp();
  const [dataMember, setDataMember] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [orderEInvoice, setOrderEInvoice] = useState({});
  const [orderDetail, setOrderDetail] = useState(null);
  const barcodeImg = useRef();
  const qrcodeSvg = useRef();
  const qrcodeVAT = useRef();
  const isHasRoleEinvoice = appContext.checkRoleAllow("com_api.einvoice.write");
  useEffect(() => {
    let printSettings =
      appContext.shop_setting && appContext.shop_setting.printSettings
        ? appContext.shop_setting.printSettings
        : {};
    if (printSettings && printSettings.autoPrint && isAutoPrint) {
      setLoading(true);
      clickPrintOrder(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getPointMember() {
    const response = await fetch(
      `/call/loy_api/v1/customers/${orderData.customerId}/profile`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
    );
    if (response.status === 401) {
      return Promise.reject({ code: ERRORS.LOGIN_REQUIRED });
    }
    if (response.status === 403) {
      return Promise.reject({ code: ERRORS.USER_NOT_PERMISSION });
    }
    if (response.status === 500) {
      return Promise.reject({ code: ERRORS.NETWORK_ERROR });
    }
    let dataRessponse = await response.json();
    return dataRessponse;
  }
  async function getProfileMember(data) {
    const result = await appContext.callAPI(
      "get",
      `/call/loy_api/v1/customerprofiles/${data}/orderinfo/${orderData.orderId}`,
    );
    return result;
  }
  const callPrintOrder = async (
    dataMember,
    order,
    isOrderPrintBill,
    refundOrderModel,
    isNewOrder,
    totalRefund,
    locationRefund,
  ) => {
    const templateType =
      appContext?.shop_setting?.printSettings?.printType === "html"
        ? refundOrderModel
          ? TemplateType.CUSTOM_HTML_REFUND
          : isDetail
            ? TemplateType.CUSTOM_HTML_ORDER_DETAIL
            : TemplateType.CUSTOM_HTML
        : null;
    order &&
      order.transactions.forEach((trans) => {
        trans.gateway = toPaymentDisplayName(trans.gateway);
        trans.paymentMethodName = toPaymentDisplayName(trans.gateway);
      });
    refundOrderModel &&
      refundOrderModel.orderModel.transactions.forEach((trans) => {
        trans.gateway = toPaymentDisplayName(trans.gateway);
        trans.paymentMethodName = toPaymentDisplayName(trans.gateway);
      });
    let template = await printOrder(
      appContext,
      order,
      barcodeImg?.current,
      refundOrderModel,
      isNewOrder,
      totalRefund,
      locationRefund,
      dataMember,
      order.checkoutToken && order.paymentStatusId === 5 && !order.amountPaid
        ? qrcodeSvg?.current
        : null,
      isOrderPrintBill,
      qrcodeVAT.current,
      templateType,
    );
    var contentWindow = document.getElementById("print-order");
    let contentPopup;
    if (contentWindow) {
      contentPopup = contentWindow.contentWindow.document;
      contentPopup.open();
      contentPopup.write(template);
      setTimeout(function () {
        contentPopup.close();
      }, 200);
    }
    setLoading(false);
  };

  const renderBarcode = (orderNumber) => {
    try {
      const barcodeTag = document.getElementById("barcode");
      if (barcodeTag) {
        barcodeTag.innerHTML = "";
      }

      JsBarcode("#barcode", orderNumber, {
        format: "code128",
        displayValue: false,
        height: 100,
        width: 3,
      });
    } catch (e) {}
  };

  const clickPrintOrder = async (isAutoPrint = false) => {
    setLoading(true);
    let printSettings =
      appContext.shop_setting && appContext.shop_setting.printSettings
        ? appContext.shop_setting.printSettings
        : {};
    let _dataMember = cloneDeep(dataMember);
    try {
      if (
        orderData &&
        orderData.customerId &&
        appContext.shop_loyalty?.enabled === true &&
        printSettings.showLevelNameLoyalty === true
      ) {
        if (orderData.dataMember) {
          setDataMember(() => ({}));
          _dataMember = orderData.dataMember;
        } else if (orderData.redeemName === undefined) {
          let profile = await getPointMember();
          if (
            appContext.shop_loyalty?.enabled &&
            profile &&
            profile.data &&
            profile.data.customer_profile_id &&
            profile.data.current_membership_level !== "Chưa là thành viên"
          ) {
            let dataMember = {}; ////
            setDataMember(dataMember);
            _dataMember = dataMember;
          } else {
            setDataMember({});
            _dataMember = {};
          }
        } else {
          let dataPoint = await getPointMember();
          if (
            dataPoint.data.customer_profile_id &&
            dataPoint.data.status !== 0
          ) {
            let dataMember = await getProfileMember(
              dataPoint.data.customer_profile_id,
            );
            if (dataMember.error) {
              setDataMember({});
              _dataMember = {};
            } else {
              setDataMember(dataMember);
              _dataMember = dataMember;
            }
          } else {
            setDataMember({});
            _dataMember = {};
          }
        }
      } else {
        setDataMember({});
        _dataMember = {};
      }
    } catch (error) {
      setDataMember({});
      _dataMember = {};
    }
    var printData = { ...refundOrderModel };
    if (
      refundOrderModel &&
      refundOrderModel.listOrderProducts &&
      refundOrderModel.listOrderProducts.length
    ) {
      printData.listOrderProducts = [];
      refundOrderModel.listOrderProducts.forEach((item) => {
        const sessionLine = refundSessionData?.lineItems?.find(
          (line) => line.id === item.id,
        );
        if (item.refundNumber !== 0) {
          printData.listOrderProducts.push({
            ...item,
            originalPrice: sessionLine?.price ?? item?.originalPrice,
            productPrice: sessionLine?.linePrice ?? item?.productPrice,
            totalPrice: sessionLine?.lineAmount ?? item?.totalPrice,
            discountAllocations: sessionLine?.discountAllocations,
          });
        }
      });
    }
    try {
      const startTime = Date.now();
      let result, orderEInvoiceData;
      while (Date.now() - startTime < 10000) {
        try {
          result = await appContext.callAPI(
            "get",
            `/call/retail_api/orders/${orderId ?? orderData?.orderId}`,
          );
          // Check if the received data matches the expected data
          setOrderDetail(result.data);
          if (result.data.orderEInvoice) {
            orderEInvoiceData = result.data.orderEInvoice;
            setOrderEInvoice(orderEInvoiceData);
            break;
          }
          // if (!isAutoPrint) {
          //   break;
          // }
          if (
            isDetail ||
            !taxData?.eInvoice?.length ||
            !appContext.shop_info.taxable
          ) {
            break;
          }
        } catch (error) {
          // Retry on API error
        }

        // Wait for a short time before making the next API call (e.g., 1 second)
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      if (orderData) {
        renderBarcode(orderData.orderNumber);
        let orderPrint = {
          ...result.data,
          orderProducts: result.data?.orderProducts?.map((product) => {
            const sessionLine = sessionData?.lineItems?.find(
              (line) => line.id === product.id,
            );
            return {
              ...product,
              originalPrice: sessionLine?.price ?? product?.originalPrice,
              productPrice: sessionLine?.linePrice ?? product?.productPrice,
              totalPrice: sessionLine?.lineAmount ?? product?.totalPrice,
              discountAllocations: sessionLine?.discountAllocations,
            };
          }),
          discountAllocations: (
            sessionData?.discountAllocations || result.data.discountAllocations
          )?.reduce((accumulator, cur) => {
            if (cur.type !== DiscountAllocationType.MANUAL_ORDER) {
              accumulator.push({
                ...cur,
                name: cur.name || cur.code,
              });
            }
            return accumulator;
          }, []),
          discountAmount: result.data.newCheckout
            ? sessionData?.discount
            : result.data.discountAmount,
          subTotal: result.data.newCheckout
            ? result.data.subTotalAfterDiscount
            : result.data.subTotal,
          orderEInvoice:
            orderEInvoiceData ||
            (Object.keys(orderEInvoice).length > 0 ? orderEInvoice : null),
          isVatFail:
            isAutoPrint &&
            isHasRoleEinvoice &&
            !orderEInvoiceData &&
            Object.keys(orderEInvoice).length <= 0 &&
            taxData?.eInvoice &&
            appContext.shop_info.taxable,
          rewardRedeemAmount: currentRefund?.refundRedeemAmount,
          noteAttributes: orderData?.noteAttributes || [],
          transactions: orderData?.transactions || [],
          totalMoneyPaidByCustomer: orderData?.totalMoneyPaidByCustomer,
        };
        await callPrintOrder(_dataMember, orderPrint, isOrderPrintBill);
      } else if (orderId) {
        renderBarcode(result.data.orderNumber);
        let currentLocation = appContext.current_location;
        callPrintOrder(
          _dataMember,
          {
            ...result.data,
            orderProducts: result.data?.orderProducts?.map((product) => {
              const sessionLine = sessionData?.lineItems?.find(
                (line) => line.id === product.id,
              );
              return {
                ...product,
                originalPrice: sessionLine?.price ?? product?.originalPrice,
                productPrice: sessionLine?.linePrice ?? product?.productPrice,
                totalPrice: sessionLine?.lineAmount ?? product?.totalPrice,
                discountAllocations: sessionLine?.discountAllocations,
              };
            }),
            discountAllocations: (
              sessionData?.discountAllocations ||
              result.data.discountAllocations
            )?.reduce((accumulator, cur) => {
              if (cur.type !== DiscountAllocationType.MANUAL_ORDER) {
                accumulator.push({
                  ...cur,
                  name: cur.name || cur.code,
                });
              }
              return accumulator;
            }, []),
            discountAmount: result.data.newCheckout
              ? sessionData?.discount
              : result.data.discountAmount,
            subTotal: result.data.newCheckout
              ? result.data.subTotalAfterDiscount
              : result.data.subTotal,
            noteAttributes: orderData?.noteAttributes || [],
            transactions:
              orderData?.transactions || result.data.transactions || [],
            rewardRedeemAmount: currentRefund?.refundRedeemAmount,
          },
          isOrderPrintBill,
          printData,
          isNewOrder,
          totalRefund,
          currentLocation,
        );
      }
      onSetVATStatus(
        isAutoPrint &&
          isHasRoleEinvoice &&
          !orderEInvoiceData &&
          Object.keys(orderEInvoice).length <= 0 &&
          taxData?.eInvoice?.length > 0 &&
          appContext.shop_info.taxable,
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Button size={size} status="default" light>
          <Loading className="print-button--loading-btn" size="pico" />
        </Button>
      ) : (
        <Button
          light
          size={size}
          icon={"print"}
          title={hasTitle ? "In đơn hàng" : ""}
          tooltipTitle={hasTitle ? "" : "In đơn hàng"}
          placementTooltip={placementTooltip}
          status={isShowTitleButton ? "primary" : "default"}
          plain={!isShowTitleButton}
          onClick={() => clickPrintOrder()}
          isBtnLoading={true}
        />
      )}
      <iframe title="print" id="print-order" style={{ display: "none" }} />
      <div style={{ display: "none" }}>
        <svg
          id="barcode"
          style={{ height: "60px", width: "180px" }}
          ref={barcodeImg}
        />
        {isPaymentOnline &&
          orderDetail &&
          showOnlinePayment(appContext.shop_info) && (
            <div id="qrcode" className="m-auto" ref={qrcodeSvg}>
              <QRCodeSVG
                value={generatePaymentUrl(
                  appContext.shop_info,
                  orderDetail.checkoutToken,
                )}
                includeMargin={true}
                size={170}
                level="M"
              />
            </div>
          )}
        <iframe title="print" id="print-order" style={{ display: "none" }} />
        <div style={{ display: "none" }}>
          <svg
            id="barcode"
            style={{ height: "60px", width: "180px" }}
            ref={barcodeImg}
          />
          {isPaymentOnline &&
            orderDetail &&
            showOnlinePayment(appContext.shop_info) && (
              <div id="qrcode" className="m-auto" ref={qrcodeSvg}>
                <QRCodeSVG
                  value={generatePaymentUrl(
                    appContext.shop_info,
                    orderDetail.checkoutToken,
                  )}
                  includeMargin={true}
                  size={170}
                  level="M"
                />
              </div>
            )}
          {orderEInvoice && orderEInvoice.qrCodeUrl && (
            <div id="qrcodeVAT" className="m-auto" ref={qrcodeVAT}>
              <QRCodeSVG
                value={orderEInvoice.qrCodeUrl}
                includeMargin={false}
                size={80}
              />
            </div>
          )}
          {isPaymentOnline &&
            orderDetail &&
            showOnlinePayment(appContext.shop_info) && (
              <div id="qrcode" className="m-auto" ref={qrcodeSvg}>
                <QRCodeSVG
                  value={generatePaymentUrl(
                    appContext.shop_info,
                    orderDetail.checkoutToken,
                  )}
                  includeMargin={true}
                  size={170}
                  level="M"
                />
              </div>
            )}
        </div>
      </div>
    </Fragment>
  );
}

export default memo(PrintButton);

PrintButton.propTypes = {
  orderId: PropTypes.any,
  orderData: PropTypes.any,
  isBtnLoading: PropTypes.any,
  isAutoPrint: PropTypes.any,
  sessionData: PropTypes.any,
  refundSessionData: PropTypes.any,
};
