import React from "react";
import Button from "component-v2/buttons";
const ArrowPagination = ({ total, currentPage, onChange, pageSize }) => {
  const nextPageDisabled = currentPage === Math.ceil(total / pageSize);
  const prevPageDisabled = currentPage === 1;
  const nextPage = () => !nextPageDisabled && onChange(currentPage + 1);
  const prevPage = () => !prevPageDisabled && onChange(currentPage - 1);
  return (
    <div className="d-flex justify-content-end l-p-8 l-border-top">
      <Button
        light
        icon="arrow_back_ios"
        disabled={prevPageDisabled}
        onClick={prevPage}
      />
      <Button
        light
        icon="arrow_forward_ios"
        disabled={nextPageDisabled}
        className="l-ml-8"
        onClick={nextPage}
      />
    </div>
  );
};

export default ArrowPagination;
