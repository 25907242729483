import {
  DateTimeShowType,
  toFormattedDate,
} from "infrastructures/format-helpers";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useApp } from "contexts/app";

export default function DateTimeShow({
  date,
  type = DateTimeShowType.mixed_long_date,
  format = "LLLL",
  className,
}) {
  const [formatted, setFormatted] = useState("");
  const { locale } = useApp();
  useEffect(() => {
    if (!date) {
      setFormatted("");
    } else {
      setFormatted(toFormattedDate(date, type, format));
    }
  }, [date, type, format, locale.language]);
  return (
    <span className={`datetime-show${className ? " " + className : ""}`}>
      {formatted}
    </span>
  );
}

DateTimeShow.propTypes = {
  date: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(DateTimeShowType)),
  format: PropTypes.string,
};
