import Icon from "component-v2/icons/icons";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEY_EVENTS, HOTKEY_SCOPES, HOTKEYS } from "models/constants";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import "./style.css";
const DiscountListModal = ({
  show,
  setShow,
  item,
  onClickPromotion,
  listPromotions,
}) => {
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        setShow(false);
      }
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  const body = () => {
    return (
      <div className="promotion-list container d-flex flex-column gap-8">
        {listPromotions?.map((promotion, index) => {
          return (
            <div
              key={`${promotion?.lineId}-${index}`}
              className="row d-flex cursor-pointer custom-variant-item l-p-8"
              onClick={() => {
                onClickPromotion?.(promotion);
              }}
            >
              <div className="col d-flex align-items-center">
                <Icon
                  className={"d-flex align-items-center"}
                  size="sm"
                  name={"card_giftcard"}
                  color={"var(--redrose600)"}
                />
                <div className="d-flex flex-wrap">
                  <p className="l-ml-8 l-mr-4 mb-0">{promotion.code}</p>
                  {!promotion.isCanApply && (
                    <span className="promotion-group-header-badge border-0 bg-transparent">
                      <Icon className="icon" name="check_mark" size="xs" />{" "}
                      {`Đã áp dụng`}
                    </span>
                  )}
                </div>
              </div>
              <div
                className="col d-flex flex-column justify-content-center"
                key={`${promotion?.lineId}=${index}`}
              >
                <span>{`${promotion?.label_1}`}</span>
                <span style={{ lineHeight: "24px" }}>
                  {`${promotion?.label_2}`}
                </span>
              </div>
              <div className="col d-flex flex-column gap-4 align-items-end justify-content-center">
                <span
                  className={`count-badge fit-content ${
                    promotion.buyQuantity >= promotion.minOrderAmount
                      ? "activated"
                      : ""
                  }`}
                >
                  {`Đã mua: ${`${promotion.buyQuantity}/${promotion.minOrderAmount}`}`}
                </span>
                <span
                  className={`count-badge fit-content ${
                    promotion.getQuantity >= promotion.maxDiscountAmount
                      ? "activated"
                      : ""
                  }`}
                >
                  {`KM đã chọn: ${promotion?.getQuantity}/${promotion?.maxDiscountAmount}`}
                </span>
              </div>
              <div className="col-1 d-flex align-items-center">
                <Icon className={""} size="2.4" name={"chevron_right"} />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <GlobalModal
      size={"lg"}
      className={"discount-recommend-modal"}
      isTitleCenter
      show={show}
      setShow={setShow}
      body={body()}
      headerTitle={
        <span className="truncate-60ch">
          {`Chương trình KM trên ${item?.productTitle || ""}`}
        </span>
      }
    ></GlobalModal>
  );
};

export default DiscountListModal;
