import React from "react";
import { Switch } from "@haravan/react-components";
import Logo from "component-v2/logos/logos";

const PaymentSettingItem = (props) => {
  const { method, onUpdateSetting, disabled, additionalInfo } = props;
  const handleSwitchChange = (method) => {
    onUpdateSetting(method);
  };
  return (
    <div
      className={`app-container padding-bottom-12 padding-top-12 ${disabled ? "content-tertiary" : ""}`}
    >
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex">
          <div
            key={`pay_${method.type}`}
            className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1`}
            style={{ width: "40px", height: "40px" }}
          >
            <Logo
              name={method.code}
              size="md"
              className={"align-self-center"}
            />
          </div>
          <span className="align-self-center">{method.name}</span>
        </div>
        <div className="min-width-fit-content">
          {additionalInfo}
          <Switch
            disabled={disabled}
            checked={method.isEnable}
            onChange={() => handleSwitchChange(method)}
          />
        </div>
      </div>
    </div>
  );
};
export default PaymentSettingItem;
