import { Input, InputRadio } from "@haravan/react-components";
import moment from "moment";
import NumberFormat from "react-number-format";

export const Info = ({ customerInfo, handleUpdateCustomer }) => {
  const onChangeBirthday = (e) => {
    if (e.formattedValue === "") {
      handleUpdateCustomer("birthday", "");
      return;
    }
    if (!e.formattedValue.includes("Y") && e.formattedValue !== "") {
      //chuyen dinh dang ngay input => MM/DD/YYY
      let myDate = moment(e.formattedValue, "DD/MM/YYYY").format("MM/DD/YYYY");
      if (myDate === "Invalid date") {
        handleUpdateCustomer(
          "birthday",
          moment(new Date()).format("DD/MM/YYYY"),
        );
        return;
      }
      let curr = new Date(myDate);
      curr.setDate(curr.getDate() + 1);
      var dateYear = moment(curr).format("YYYY");
      var nowYear = moment(Date.now.date).format("YYYY");
      var now = moment(Date.now.date).format("DD/MM/YYYY");
      //chuyen lai dinh dang => dd/mm/yyyy de show len
      var newday = moment(myDate, "MM/DD/YYYY").format("DD/MM/YYYY");
      if (dateYear > nowYear || dateYear < 1900) {
        handleUpdateCustomer("birthday", now);
      } else {
        handleUpdateCustomer("birthday", newday);
      }
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <InputRadio
          name="gender"
          className={`${customerInfo.gender === 1 ? "border-0 location-item--radio-button-checked" : "location-item--radio-button-uncheck"}`}
          checked={customerInfo.gender === 1}
          onChange={() => {
            handleUpdateCustomer("gender", 1);
          }}
        >
          Anh
        </InputRadio>
        <InputRadio
          name="gender"
          className={`${customerInfo.gender === 0 ? "border-0 location-item--radio-button-checked" : "location-item--radio-button-uncheck"} ml-3 mt-0`}
          checked={customerInfo.gender === 0}
          onChange={() => {
            handleUpdateCustomer("gender", 0);
          }}
        >
          Chị
        </InputRadio>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 pr-lg-2 mt-4">
          <p className="mb-1">Họ</p>
          <Input
            placeholder="Họ"
            value={customerInfo.lastName || ""}
            onChange={(value) => handleUpdateCustomer("lastName", value)}
          />
        </div>
        <div className="col-12 col-lg-6 pl-lg-2 mt-4">
          <p className="mb-1">Tên</p>
          <Input
            placeholder="Tên"
            value={customerInfo.firstName || ""}
            onChange={(value) => handleUpdateCustomer("firstName", value)}
          />
        </div>
        <div className="col-12 col-lg-6 pr-lg-2 mt-4">
          <p className="mb-1">Ngày sinh</p>
          <NumberFormat
            className={`hrv-next-input`}
            format="##/##/####"
            placeholder="dd/mm/yyyy"
            onValueChange={(value) => {
              onChangeBirthday(value);
            }}
            value={customerInfo.birthday || ""}
            mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
          />
        </div>
        <div className="col-12 col-lg-6 pl-lg-2 mt-4">
          <p className="mb-1">Số điện thoại</p>
          <Input
            placeholder="Số điện thoại"
            value={customerInfo.phone || ""}
            onChange={(value) => handleUpdateCustomer("phone", value)}
          />
        </div>
        <div className="col-12 mt-4">
          <p className="mb-1">Email</p>
          <Input
            placeholder="Email"
            value={customerInfo.email || ""}
            onChange={(value) => handleUpdateCustomer("email", value)}
          />
        </div>
      </div>
    </>
  );
};
