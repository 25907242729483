import { getCurrentOrderByCustomerId } from "api/customer-repository";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import {
  DateTimeShowType,
  toFormattedCurrency,
  toFormattedDate,
} from "infrastructures/format-helpers";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { OrderSourceEnums } from "screens-v2/carts-v2/cart-helpers.service";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import {
  paymentStatusValue,
  shipmentStatusValue,
} from "screens-v2/orders/utils/utils";
import { FormattedMessage } from "react-intl";
import Badge from "screens-v2/layouts/layouts/omni-badge";

export const CurrentOrders = ({ customerInfo }) => {
  const { callAPI } = useApp();
  const [currentOrder, setCurrentOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurrentOrder();
  }, []);

  async function getCurrentOrder() {
    setLoading(true);
    const resultOrder = await getCurrentOrderByCustomerId(
      callAPI,
      customerInfo?.id,
    );
    setLoading(false);
    setCurrentOrder(resultOrder.data.data);
  }

  if (!currentOrder && loading) {
    return (
      <div>
        <LoadingCircle />
      </div>
    );
  } else if (!currentOrder?.length) {
    return <div className="text-center">Không có đơn hàng gần đây</div>;
  }

  return (
    <>
      {currentOrder.map((order) => {
        return <RenderCurrentOrder order={order} />;
      })}
    </>
  );
};

const RenderCurrentOrder = ({ order }) => {
  const orderSourceMapping = (sourceName) => {
    let source = OrderSourceEnums.find((source) => source.name === sourceName);
    if (source) {
      return source.display;
    } else {
      return sourceName;
    }
  };

  const {
    contentValue: contentValueShipment,
    badgeValue: badgeValueShipment,
    colorValue: colorIconShipment,
  } = useMemo(() => shipmentStatusValue(order.shipmentStatus), [order]);
  const {
    contentValue: contentValuePayment,
    badgeValue: badgeValuePayment,
    colorValue: colorIconPayment,
  } = useMemo(() => paymentStatusValue(order.paymentStatusId), [order]);
  return (
    <Fragment key={order.orderId}>
      <div className="d-flex justify-content-between">
        <div>
          <p className="content-secondary font-size-12 m-0">
            {toFormattedDate(order.orderDate, DateTimeShowType.date)}
          </p>
          <p className="font-weight-medium mb-0 l-mt-4">{order.orderNumber}</p>
          <p className="content-secondary text-capitalize font-size-12 mb-0 l-mt-4">
            {orderSourceMapping(order.sourceName)}
          </p>
        </div>
        <div>
          {order.omniOrderStatus === 11 ? (
            <div className="text-right">
              <Badge
                className="align-self-end l-mb-4"
                light
                rounded
                status={"red"}
              >
                <FormattedMessage
                  id="orders.searches.Đã hủy"
                  defaultMessage="Đã hủy"
                />
              </Badge>
            </div>
          ) : null}
          {order.omniOrderStatus !== 11 && order.isArchived ? (
            <div className="text-right">
              <Badge
                className="align-self-end l-mb-4"
                light
                rounded
                status={"red"}
              >
                <FormattedMessage
                  id="orders.searches.Lưu trữ"
                  defaultMessage="Lưu trữ"
                />
              </Badge>
            </div>
          ) : null}
          {!order.isArchived ? (
            <>
              {contentValuePayment && (
                <div className="text-right">
                  <Badge
                    className="align-self-end l-mb-4"
                    light
                    rounded
                    status={badgeValuePayment}
                  >
                    <Icon
                      size="xs"
                      name={"payments"}
                      color={colorIconPayment}
                      className="mr-2"
                    />
                    {contentValuePayment}
                  </Badge>
                </div>
              )}
              {contentValueShipment && (
                <div className="text-right">
                  <Badge
                    className="align-self-end l-mb-4"
                    light
                    rounded
                    status={badgeValueShipment}
                  >
                    <Icon
                      size="xs"
                      name={"local_shipping"}
                      color={colorIconShipment}
                      className="mr-2"
                    />
                    <FormattedMessage
                      id={`carts.orders.shipments.${contentValueShipment}`}
                      defaultMessage={contentValueShipment}
                    />
                  </Badge>
                </div>
              )}
            </>
          ) : null}
          <p className="mt-3 text-right">
            {toFormattedCurrency(order.orderTotal)}
          </p>
        </div>
      </div>
      <hr className="my-3" />
    </Fragment>
  );
};
