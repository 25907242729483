import React from "react";
import PropTypes from 'prop-types';
import CurrencyShow from "component-v2/currency-show";
import { ImageCustom } from "component-v2/ImageCustom";

export const ProductDetailItem = (props) => {
  const {product} = props;
  const id = `product-detail--item`;

  return (
    <div id={id} className="row col-12 pointer list-group-item product-detail-item ml-1">
      <div className='col pr-0 pl-2'>
        <div className="product-detail-2-cols  prod-p-08 "  >
          <div className="product-detail-item-thumbnail" >
            <ImageCustom src={product?.productImgUrl} alt="image3" size='medium'/>
          </div>
          <div className="col product-detail-2-cols cart-product-item--info">
            <p className='product-detail-title'>{product.productName}</p>
            <p className='product-detail-sub-title'>{product.value1}</p>
            {product.barcode ?
              // <div className="d-flex  ">
              <span className="product-detail-title flex-row w-100 m-0">Barcode:
                <p className='product-detail-sub-title'>{product.barcode}</p>
              </span>
              // </div> 
              : null
            }
            {product.sku ?
              <span className="product-detail-title flex-row w-100 m-0">
                SKU: <p className='product-detail-sub-title'>{product.sku}</p>
              </span>
              : null
            }
          </div>
        </div>
      </div>

      <div className={`p-0 d-flex align-items-center justify-content-end col-auto`}>
        <div className='text-left'>
          <span className='ml-2 product-detail-sub-title content-primary '><CurrencyShow value={product.price} /></span>
        </div>
      </div>
    </div>
  );
};





ProductDetailItem.propTypes = {
  product: PropTypes.object
};

