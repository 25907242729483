import { useApp } from "contexts/app";
import { useEffect } from "react";
import { useState } from "react";
import { pricingDefault } from "screens-v2/carts-v2/cart-helpers.service";
import { useRefundContext } from "..";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";
import { ShopService } from "services";

export const PricingList = () => {
  const appContext = useApp();
  const { current_shopping_cart, updateCurrentSession } = useRefundContext();
  const [pricingList, setPricingList] = useState(appContext.defaultPricingList);
  useEffect(() => {
    getPricingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_shopping_cart?.customer?.id]);

  const getPricingList = async () => {
    if (current_shopping_cart?.customer?.id) {
      try {
        const response = await ShopService.getPricingList(
          appContext.callAPI,
          appContext.current_location.id,
          current_shopping_cart?.customer?.id,
        );
        const data = response ?? [];
        setPricingList([pricingDefault, ...data]);
      } catch (error) {}
    } else {
      setPricingList(appContext.defaultPricingList);
    }
  };

  if (current_shopping_cart?.cart_quantity_total <= 0) return null;

  return (
    <div className="l-pb-8">
      <div className="l-section--body">
        <SelectionCustom
          defaultActiveFirstOption={false}
          idField="id"
          options={pricingList}
          prefix={
            <label className="align-self-center label-in-selection">
              Bảng giá:{" "}
            </label>
          }
          valueField="name"
          showSearch
          showArrow={true}
          value={current_shopping_cart?.pricingApplied?.id}
          placeholder="Chọn giá trị"
          onChange={(value) => {
            updateCurrentSession({
              pricingApplied: value
                ? pricingList.find((p) => p.id === value)
                : pricingList[0],
              itemsOriginal: current_shopping_cart?.itemsOriginal,
            });
          }}
        >
          {pricingList.map((price) => {
            return (
              <SelectionCustom.Option key={price.id} value={price.id}>
                {price.name}
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
      </div>
    </div>
  );
};
