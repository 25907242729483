import { getDecimalValue } from "../utils/Commons";

export const callGetApiOrderDetails = async (callAPI, orderId) => {
  return await callAPI("get", `/call/retail_api/orders/${orderId}`);
};
export const cancelOrder = async (shop_setting, callAPI, order, note, shift) => {
  try {
    let products = [];
    order.orderProducts.forEach((product) => {
      let ratio = product.ratio || 1;
      let item = {
        id: product.id,
        restockableQuantity: product.restockableQuantity,
        returnableQuantity: product.returnableQuantity,
        lots: product.lots?.map((lot) => ({
          id: lot.id,
          quantity: lot.quantity / ratio - lot.returnQuantity / ratio,
        })),
      };
      products.push(item);
    });
    let cancelOrder = {
      cancelNote: note,
      cancelReasonOrderId: 90036,
      lineItems: products,
      isRestock: true,
      isSentEmailCancel: true,
      lazada_SumAllocate: true,
      totalRefunded: order.totalRefunded,
    };
    const result = await callAPI(
      "put",
      `/call/com_api/orders/${order.orderId}/cancel`,
      cancelOrder
    );
    if (result?.data) {
      if (shop_setting.shiftEnable) {
        let shiftTransaction = {};
        const { amountPaid, gateway } = order;
        if (gateway) {
          if (gateway.toLowerCase().indexOf("tiền") !== -1) {
            shiftTransaction.cashAmount = -amountPaid;
          } else if (gateway.toLowerCase().indexOf("visa") !== -1) {
            shiftTransaction.visaAmount = -amountPaid;
          } else if (gateway.toLowerCase() === "chuyển khoản") {
            shiftTransaction.transferAmount = -amountPaid;
          }
          if(shift)
          await callAPI('post', `/call/retail_api/shifts/${shift.id}/transaction`, shiftTransaction);
        }
      }
      return { data: result.data };
    } else {
      return { error: result.errors[0] };
    }
  } catch (err) {
    return err;
  }
};

export const validateCart = async (callAPI, model) => {
  return await callAPI("post", `/call/com_api/orders/cart/calculate_v2`, model);
};

export const getUserSales = async (callAPI, locationId) => {
  return null // await callAPI("get", `/call/com_api/locations/${locationId}/users`);
};

export const getLotsData = async (callAPI, orderLine, appContext) => {
  try {
    let url = `/call/com_api/locations/${appContext.current_location.id}/products/${orderLine.productId}/variants/${orderLine.productVariantsId}/lots?active=true&status=in_stock`;
    const response = await callAPI("get", url);
    if (response) {
      if (!response.errors || !response.errors.length) {
        const ratio = orderLine.selectedUnit ? orderLine.selectedUnit.ratio : 1;
        const data = (response.data || []).map((item) => ({
          ...item,
          quantity: getDecimalValue(appContext.shop_info, item.qty / ratio),
        }));
        return data;
      }
    }
    return [];
  } catch (err) { }
  return [];
};

export const createOrder = async (callAPI, model) => {
  return await callAPI("post", `/call/retail_api/order`, model);
};
export const updateOrderNotes = async (callAPI, orderId, model) => {
  return await callAPI("put", `/call/com_api/orders/${orderId}/note`, model);
}
export const getQRPaymentLink = async (callAPI, orderId) => {
  return await callAPI("post", `/call/retail_api/orders/${orderId}/payment_link`, {});
};
export const renewQRPaymentLink = async (callAPI, orderId, model) => {
  return await callAPI("post", `/call/retail_api/orders/${orderId}/re_payment_link`, model);
};
export const getPaymentTransactionStatus = async (callAPI, orderId, paymentTransactionId) => {
  return await callAPI("get", `/call/retail_api/orders/${orderId}/payment_transactions/${paymentTransactionId}`);
};
export const confirmOrder = async (callAPI, orderId) => {
  return await callAPI("put", `/call/com_api/orders/${orderId}/confirm`);
}
export const printOrderCustomTemplate = async (callAPI, orderId, typeId, model) => {
  return await callAPI('post', `/call/retail_api/orders/${orderId}/template/${typeId}/print`, model);
}
export const printTempOrderCustomTemplate = async (callAPI, typeId, model) => {
  return await callAPI('post', `/call/retail_api/orders/template/${typeId}/print`, model);
}