import React, { useEffect, useRef, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import "./index.css";
import { HostBridge, PayloadTypes, ErrorCode } from "@haravan/apps-host-bridge";
import Button from "component-v2/buttons";
import { getJWTToken } from "api/account-repository";
import { useCartContext } from "../../index";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { updateCheckoutSession } from "api/checkout-repository";
import { useApp } from "contexts/app";
import { FullScreenApps } from "configs";

export const AppBridgeDetail = ({ appDetail, callAPI }) => {
  const { current_shopping_cart, refreshCart, setLoadingCart } =
    useCartContext();
  const { showGlobalToast } = useLayoutContext();
  const { current_location } = useApp();
  const [appInfo, setAppInfo] = useState(appDetail);
  const [showModal, setShowModal] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(
    FullScreenApps.includes(appDetail.client_id),
  );
  const ref = useRef(null);

  useEffect(() => {
    if (appInfo.client_id !== appDetail.client_id) {
      let appIsFullScreen = FullScreenApps.includes(appDetail.client_id);
      setIsFullScreen(appIsFullScreen);
      setAppInfo(appDetail);
    }
  }, [appDetail]);

  const appReceiveMessageCallback = (message) => {
    switch (message.type) {
      case "dispatch":
        handleDispatch(message);
        break;
      default:
        break;
    }
  };

  const handleDispatch = (message) => {
    let apiKey = message?.source?.apiKey;
    switch (message.payload?.type) {
      case PayloadTypes.SESSION_TOKEN_REQUEST:
        if (apiKey) {
          if (apiKey === appInfo?.client_id) {
            handleSessionToken(message);
          }
        }
        break;
      case PayloadTypes.CART_REQUEST:
        handleGetCartInfo(message);
        break;
      case PayloadTypes.CART_UPDATE:
        handleUpdateCart(message);
        break;
      case PayloadTypes.CART_ACTION_CLOSE:
        if (apiKey) {
          if (apiKey === appInfo?.client_id) {
            handleCartActionClose();
          }
        }
        break;
      case PayloadTypes.LOADING_START:
        setLoadingCart(true);
        break;
      case PayloadTypes.LOADING_STOP:
        setLoadingCart(false);
        break;
      case PayloadTypes.TOAST_SHOW:
        handleToatShow(message);
        break;
      case PayloadTypes.STOREINFO_REQUEST:
        handleGetStoreInfo(message);
        break;
      default:
        break;
    }
  };

  const handleSessionToken = async (message) => {
    let id = message.payload?.payload?.id ?? undefined;
    let sessionToken = undefined;
    let errorCode = undefined;
    let rpToken = await getToken();
    if (rpToken) {
      if (!rpToken.error) {
        if (
          rpToken.data &&
          rpToken.data.payload &&
          rpToken.data.payload.sessionToken
        ) {
          sessionToken = rpToken.data.payload.sessionToken;
        }
      } else {
        if (rpToken.error_code) {
          if (rpToken.error_code.startsWith("error_not_found"))
            errorCode = ErrorCode.NOT_FOUND;
        }
      }
    }
    ref?.current?.sendSessionToken(id, sessionToken, errorCode);
  };

  const handleGetCartInfo = async (message) => {
    let id = message.payload?.payload?.id ?? undefined;
    const cart = getCart();
    ref?.current?.sendCart(id, cart);
  };
  const handleUpdateCart = async (message) => {
    let id = message.payload?.payload?.id ?? undefined;
    let lines = message.payload?.payload?.data?.lineItems ?? [];
    let attributes = message.payload?.payload?.data?.attributes ?? [];
    let redeemPoint = message.payload?.payload?.data?.redeemPoint;
    const updateModel = lines.map((line) => {
      return {
        ...(line.id && { id: line.id }),
        ...(!line.id &&
          line.productId && {
            productId: line.productId,
          }),
        ...(!line.id &&
          line.variantId && {
            variantId: line.variantId,
          }),
        ...(!line.id &&
          line.variantId &&
          line.variantUnitId && {
            variantUnitId: line.variantUnitId,
          }),
        quantity: line.quantity,
        ...(line.attributes && {
          attributes: line.attributes,
        }),
      };
    });

    await updateCheckoutSession(callAPI, current_shopping_cart.id, {
      lineItems: updateModel,
      ...(attributes && {
        attributes: attributes,
      }),
      ...(redeemPoint && {
        redeemPoint: Number(redeemPoint),
      }),
    })
      .then((res) => {
        refreshCart(res);
        ref?.current?.sendCart(id, getCart(res));
      })
      .catch(() => ref?.current?.sendCart(id, null));
  };
  const handleCartActionClose = async () => {
    setShow(false);
  };
  const handleToatShow = (msg) => {
    const { message, isError } = msg.payload?.payload;
    const type = !!isError ? "error" : "default";
    if (!!message?.length) {
      showGlobalToast(type, message);
    }
  };

  const getCart = (session = null) => {
    let currentSession = { ...(session ?? current_shopping_cart) };
    if (!currentSession || !currentSession?.id) return null;

    const lineItems = currentSession?.lineItems.map((x) => {
      return {
        id: x.id,
        seq: x.seq,
        imageUrl: x.imageUrl,
        productId: x.productId,
        productTitle: x.productTitle,
        pricingListId: x.pricingListId,
        priceOriginal: x.priceOriginal,
        price: x.price,
        linePrice: x.linePrice,
        lineDiscount: x.lineDiscount,
        lineAmount: x.lineAmount,
        lotSupport: x.lotSupport,
        requiresShipping: x.requiresShipping,
        variantId: x.variantId,
        quantity: x.quantity,
        barcode: x.barcode,
        sku: x.sku,
        unit: x.unit,
        unitRatio: x.unitRatio,
        variantTitle: x.variantTitle,
        variantUnitId: x.variantUnitId,
        notAllowPromotion: x.notAllowPromotion,
        discountAllocations: x.discountAllocations,
        attributes: x.attributes,
      };
    });
    return {
      id: current_shopping_cart.id,
      customerId: current_shopping_cart.customerId,
      fullName: current_shopping_cart.fullName,
      pricingListId: current_shopping_cart.pricingListId,
      pricingListName: current_shopping_cart.pricingListName,
      locationId: current_shopping_cart.locationId,
      locationName: current_shopping_cart.locationName,
      discountAllocations: current_shopping_cart.discountAllocations,
      lineItems: lineItems,
      attributes: currentSession.attributes,
      phone: current_shopping_cart.phone,
      ...(current_shopping_cart.loyalty?.profile?.available_points && {
        redeemPoint: current_shopping_cart.loyalty?.profile?.available_points,
      }),
    };
  };

  const getToken = async () => {
    try {
      return await getJWTToken(callAPI, appInfo.client_id);
    } catch (err) {
      return null;
    }
  };

  const handleGetStoreInfo = async (message) => {
    let id = message?.payload?.payload?.id ?? undefined;
    ref?.current?.sendStoreInfo(id, { locId: current_location?.id });
  };

  const setShow = (value) => {
    setShowModal(value);
  };

  const body = () => {
    return (
      <div className="app-bridge-detail">
        <HostBridge
          appReceiveMessage={appReceiveMessageCallback}
          id={appInfo?.client_id}
          iframeSrc={appInfo?.redirect_url}
          // iframeSrc="http://localhost:3001/app"
          key={appInfo?.client_id}
          ref={ref}
        />
      </div>
    );
  };

  return (
    <>
      <Button
        className={"me-3"}
        size="sm"
        light
        icon="card_giftcard"
        title={appInfo?.title}
        onClick={() => {
          setShowModal(true);
        }}
      />
      <GlobalModal
        className="app-bridge-detail-modal"
        fixHeight={true}
        fullScreen={isFullScreen ? true : false}
        size={isFullScreen ? "3xl" : "lg"}
        headerTitle={appInfo?.title}
        isTitleCenter
        body={body()}
        show={showModal}
        setShow={setShow}
      />
    </>
  );
};
