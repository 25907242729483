import { Redirect, Route, Switch } from "react-router-dom";
import ShiftProvider from "contexts/shift/shift-context";
import Settings from "screens-v2/settings";
import AuthenticatedRoute from "./AuthenticatedRoute";
import PaymentVoucherCreate from "screens-v2/cash-flow/payment-voucher-create";
import DailyReport from "screens-v2/daily-reports";
import CashReceiptsCreate from "screens-v2/cash-flow/cash-receipts-create";
import Layout from "layouts";
import OrderRouter from "screens-v2/orders";
import ProductRouter from "screens-v2/products";
import Report from "screens-v2/reports";
import HomeRoute from "./HomeRoute";
import HaraLoyaltyScreen from "screens-v2/hara-loyalty";
import RefundV2 from "screens-v2/refunds-v2";
import locations from "screens-v2/locations";
import ShiftRouter from "screens-v2/shifts";
import NotFound from "screens-v2/un-auth-screens/not-found";
import CartV2 from "screens-v2/carts-v2";
import { UpgradeShopPackage } from "components/UpgradeShopPackage/UpgradeShopPackage";
import { NotPermissions } from "components/NotPermissions/NotPermissions";

const Routes = ({ authenticated, app_ready, isShopExpired, user }) => {
  const hasNoRoles = !user?.roles || user?.roles.length === 0;
  return authenticated ? (
    app_ready ? (
      isShopExpired || hasNoRoles ? (
        isShopExpired ? (
        <Layout>
          <UpgradeShopPackage />
        </Layout>)
        : (
        <Layout>
          <Switch>
            <HomeRoute exact component={NotPermissions} />
          </Switch>
        </Layout>
        )
      ) : (
      <ShiftProvider>
        <Layout>
          <Switch>
            <HomeRoute path="/" exact component={CartV2} />
            <Route exact path="/intro">
              <Redirect replace to="/" />
            </Route>
            <AuthenticatedRoute exact path="/orders" component={OrderRouter} />
            <AuthenticatedRoute
              exact
              path="/orders/:id"
              component={OrderRouter}
            />
            <AuthenticatedRoute
              exact
              path="/orders/:orderId/refund"
              component={RefundV2}
            />
            <AuthenticatedRoute path="/products" component={ProductRouter} />
            <AuthenticatedRoute exact path="/shifts" component={ShiftRouter} />

            <AuthenticatedRoute
              exact
              path="/reports/sales"
              component={Report}
            />
            <AuthenticatedRoute
              exact
              path="/reports/daily"
              component={DailyReport}
            />
            <AuthenticatedRoute exact path="/locations" component={locations} />
            <AuthenticatedRoute
              exact
              path="/cash_receipts/new"
              component={CashReceiptsCreate}
            />
            <AuthenticatedRoute
              exact
              path="/payment_vouchers/new"
              component={PaymentVoucherCreate}
            />
            <AuthenticatedRoute
              exact
              path="/haraloyalty"
              component={HaraLoyaltyScreen}
              admin_only
            />
            <Route exact path="/settings">
              <Redirect replace to="/settings/user" />
            </Route>
            <AuthenticatedRoute
              exact
              path="/settings/user"
              component={Settings}
              owner_only
            />
            <AuthenticatedRoute
              exact
              path="/settings/general"
              component={Settings}
              owner_only
            />
            <AuthenticatedRoute
              exact
              path="/settings/sale"
              component={Settings}
              owner_only
            />
            <AuthenticatedRoute
              exact
              path="/settings/shift"
              component={Settings}
              owner_only
            />
            <AuthenticatedRoute
              exact
              path="/settings/bill"
              component={Settings}
              owner_only
            />
            <AuthenticatedRoute component={NotFound} />
          </Switch>
        </Layout>
      </ShiftProvider>
      )
    ) : null
  ) : null;
};

export default Routes;
