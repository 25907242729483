import Icon from "component-v2/icons/icons";
import { useMemo } from "react";
import { concatString } from "screens-v2/carts-v2/utils";
import { useRefundContext } from "screens-v2/refunds-v2";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { useApp } from "contexts/app";
import { Tooltip } from "@haravan/react-components";

export const AddressDelivery = ({ isShippingAddress }) => {
  const { shop_setting } = useApp();
  const { current_shopping_cart, updateCurrentSession } = useRefundContext();
  const addressDefault = useMemo(() => {
    if (current_shopping_cart?.customer?.customerAddress?.length) {
      return current_shopping_cart?.customer?.customerAddress.find(
        (address) => address.isDefault,
      );
    } else {
      return null;
    }
  }, [current_shopping_cart?.customer?.customerAddress]);

  if (shop_setting?.hideOrderShippingState) return null;

  const handleUpdateCurrentCart = (isShipping) => {
    updateCurrentSession({
      summaries: {
        ...current_shopping_cart.summaries,
        shipping_fee: !isShipping
          ? 0
          : current_shopping_cart.summaries.shipping_fee,
      },
      isShipping: isShipping,
    });
  };
  if (shop_setting.hideOrderShippingState) return null;

  if (!current_shopping_cart?.isShipping && !isShippingAddress)
    return (
      <span
        className="cursor-pointer l-mr-8"
        onClick={() => handleUpdateCurrentCart(true)}
      >
        <Badge className="border-0" light status={"info"} size="L" rounded>
          <span className="d-inline-flex align-items-center">
            <Icon name="add" color="var(--contentAccent)" className="l-mr-4" />
            <span>Giao hàng sau</span>
          </span>
        </Badge>
      </span>
    );
  if (current_shopping_cart?.isShipping && isShippingAddress)
    return (
      <>
        <div className="d-flex align-items-center l-mt-8">
          <p className="mr-4">Địa chỉ nhận hàng</p>
          <div className="l-section--body">
            {addressDefault?.provinceName ? (
              <Tooltip
                placement="bottomRight"
                trigger="hover"
                overlay={
                  <div>
                    <p className="content-on-color-primary font-size-12 lh-18 l-mb-4">
                      {concatString({
                        value: [
                          addressDefault.address1,
                          addressDefault.wardName,
                          addressDefault.districtName,
                          addressDefault.provinceName,
                          addressDefault.countryName,
                        ],
                        separator: ", ",
                      })}
                    </p>
                    <p className="content-tertiary font-size-12 mb-0 lh-18">
                      {concatString({
                        value: [
                          concatString({
                            value: [
                              addressDefault.firstName,
                              addressDefault.lastName,
                            ],
                          }),
                          addressDefault.phone,
                        ],
                        separator: " • ",
                      })}
                    </p>
                  </div>
                }
              >
                <p className="content-primary text-truncate text-right">
                  {concatString({
                    value: [
                      addressDefault.address1,
                      addressDefault.wardName,
                      addressDefault.districtName,
                      addressDefault.provinceName,
                    ],
                    separator: ", ",
                  })}
                </p>
              </Tooltip>
            ) : (
              <span className="content-tertiary">Chưa có thông tin</span>
            )}
          </div>
          <div
            className="flex-auto cursor-pointer btn-close l-ml-8"
            onClick={() => handleUpdateCurrentCart(false)}
            style={{ height: "max-content", padding: "0.2rem" }}
          >
            <Icon name="close" />
          </div>
        </div>
      </>
    );

  return null;
};
