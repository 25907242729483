import React from "react";
import PropTypes from 'prop-types';
import "./product.component.style.css";
import { ImageCustom } from "component-v2/ImageCustom";

export const ProductItem = (props) => {
  const {imgSrc, title} = props;
  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.value);
    }
  };
  return (
    <div className="prod-flex-2-cols prod-variant-child prod-p-08 " onClick={onClick} >
      <div className="prod-img-variant" >
        <ImageCustom size='medium' alt={title} src={imgSrc}/>
      </div>
      <div className="col prod-flex-2-cols p-0">
        <span className=" prod-sub-title-item">{title}</span>
        {props.children}
      </div>
    </div>
  );
};


ProductItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  imgSrc: PropTypes.string,
  imgSrcAlt: PropTypes.string,
  onClick: PropTypes.func

};