import { useApp } from "contexts/app";
import { useCartContext } from "..";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import Icon from "component-v2/icons/icons";
import React, { useMemo } from "react";
import { concatString } from "../utils";
import { Tooltip } from "@haravan/react-components";

export const Delivery = ({
  isShippingAddress,
  handleOpenModalCustomerInfo,
}) => {
  const { shop_setting } = useApp();
  const { current_shopping_cart, updateCurrentSession, updateCurrentCart } =
    useCartContext();

  const addressDefault = useMemo(() => {
    if (current_shopping_cart?.customer?.customerAddress?.length) {
      return current_shopping_cart.customer.customerAddress.find(
        (address) => address.isDefault,
      );
    } else {
      return null;
    }
  }, [current_shopping_cart?.customer?.customerAddress]);

  function handleUpdateCurrentCart(isShipping) {
    if (isShipping && current_shopping_cart.summaries.shipping_fee <= 0) {
      updateCurrentCart({
        summaries: {
          ...current_shopping_cart.summaries,
          shipping_fee: !isShipping
            ? 0
            : current_shopping_cart.summaries.shipping_fee,
        },
        isShipping: isShipping,
      });
    } else {
      updateCurrentSession({
        summaries: {
          ...current_shopping_cart.summaries,
          shipping_fee: !isShipping
            ? 0
            : current_shopping_cart.summaries.shipping_fee,
        },
        isShipping: isShipping,
      });
    }
  }

  if (shop_setting.hideOrderShippingState) return null;
  if (!current_shopping_cart?.isShipping && !isShippingAddress)
    return (
      <span
        className="cursor-pointer l-mr-8"
        onClick={() => handleUpdateCurrentCart(true)}
      >
        <Badge className="border-0" light status={"info"} size="L" rounded>
          <span className="d-inline-flex align-items-center">
            <Icon name="add" color="var(--contentAccent)" className="l-mr-4" />
            <span>Giao hàng sau</span>
          </span>
        </Badge>
      </span>
    );
  if (current_shopping_cart?.isShipping && isShippingAddress)
    return (
      <div className="flex-auto">
        <div className="d-flex align-items-center l-pt-8">
          <p
            className={`flex-auto l-mr-16 ${
              current_shopping_cart?.customer
                ? "content-accent cursor-pointer"
                : "content-secondary"
            }`}
            onClick={() =>
              current_shopping_cart?.customer &&
              handleOpenModalCustomerInfo(true)
            }
          >
            Địa chỉ nhận hàng
          </p>
          <div className="flex-stretch l-mr-4 min-w-0 text-right">
            {addressDefault?.provinceName ? (
              <Tooltip
                placement="bottomRight"
                trigger="hover"
                overlay={
                  <div>
                    <p className="content-on-color-primary font-size-12 lh-18 l-mb-4">
                      {concatString({
                        value: [
                          addressDefault.address1,
                          addressDefault.wardName,
                          addressDefault.districtName,
                          addressDefault.provinceName,
                          addressDefault.countryName,
                        ],
                        separator: ", ",
                      })}
                    </p>
                    <p className="content-tertiary font-size-12 mb-0 lh-18">
                      {concatString({
                        value: [
                          concatString({
                            value: [
                              addressDefault.firstName,
                              addressDefault.lastName,
                            ],
                          }),
                          addressDefault.phone,
                        ],
                        separator: " • ",
                      })}
                    </p>
                  </div>
                }
              >
                <p className="content-primary text-truncate text-right">
                  {concatString({
                    value: [
                      addressDefault.address1,
                      addressDefault.wardName,
                      addressDefault.districtName,
                      addressDefault.provinceName,
                    ],
                    separator: ", ",
                  })}
                </p>
              </Tooltip>
            ) : (
              <span className="content-tertiary">Chưa có thông tin</span>
            )}
          </div>
          <div
            className="flex-auto cursor-pointer btn-close l-ml-8"
            onClick={() => handleUpdateCurrentCart(false)}
            style={{ height: "max-content", padding: "0.2rem" }}
          >
            <Icon name="close" />
          </div>
        </div>
      </div>
    );

  return null;
};
