import { FormattedMessage } from "react-intl";

export default function StatusShow({ status, type }) {
  switch (type) {
    case "fulfillment":
      return renderFulfillmentStatus(status);
    case "payment":
      return renderPaymentStatus(status);
    default:
      return null;
  }
}

const renderFulfillmentStatus = (shipmentStatus) => {
  // if (!shipmentStatus || typeof shipmentStatus !== "number")
  //   return null;
  let value = "";
  switch (shipmentStatus) {
    case 7:
      value = "Chờ xử lý";
      break;
    case 1:
      value = "Chờ lấy hàng";
      break;
    case 2:
      value = "Đang đi lấy";
      break;
    case 3:
      value = "Đang giao hàng";
      break;
    case 4:
      value = "Đã giao hàng";
      break;
    case 5:
      value = "Hủy giao hàng";
      break;
    case 6:
      value = "Chuyển hoàn";
      break;
    case 8:
      value = "Không gặp khách";
      break;
    case 9:
      value = "Chờ chuyển hoàn";
      break;
    case "dahoanthanh":
      value = "Đã hoàn thành";
      break;
    case "chuagiaohang":
      value = "Chưa giao hàng";
      break;
    default:
      value = "Undefinded";
      break;
  }
  return (
    <FormattedMessage
      id={`carts.orders.shipments.${value}`}
      defaultMessage={value}
    />
  );
};

const renderPaymentStatus = (paymentId) => {
  if (!paymentId || typeof paymentId !== "number") return "Undefended";
  let value = "";
  switch (paymentId) {
    case 7:
      value = "Chưa thanh toán";
      break;
    case 5:
      value = "Chờ xử lý";
      break;
    case 2:
      value = "Đã thanh toán";
      break;
    case 8:
      value = "Đã hủy";
      break;
    case 6:
      value = "Đã nhập trả";
      break;
    case 4:
      value = "Thanh toán một phần";
      break;
    case 3:
      value = "Nhập trả một phần";
      break;
    case 1:
      value = "Đã xác thực";
      break;
    default:
      value = "Undefended";
      break;
  }
  return (
    <FormattedMessage
      id={`carts.orders.payments.${value}`}
      defaultMessage={value}
    />
  );
};
