import { getCustomerDetails } from "api/customer-repository";
import { useApp } from "contexts/app";
import { useEffect, useState } from "react";
import { useCartContext } from "..";
import { CustomerInfo } from "./customers/customerInfo";
import { CustomerInput } from "./customers/customerInput";
import { pricingDefault } from "../cart-helpers.service";

export const Customers = ({ width, showEditAddress, setShowEditAddress }) => {
  const { current_shopping_cart, updateCurrentSession, updateCurrentCart } =
    useCartContext();
  const { callAPI, shop_loyalty } = useApp();
  useEffect(() => {
    if (current_shopping_cart?.customer?.isNewCustomer) {
      handleGetCustomerDetails(current_shopping_cart.customer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_shopping_cart?.customer]);

  const handleGetCustomerDetails = async (customerId) => {
    try {
      const result = await getCustomerDetails(callAPI, customerId);
      if (result?.data) {
        updateCurrentSession({ customer: result.data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!current_shopping_cart?.customer) {
    return (
      <CustomerInput
        handleSelectCustomer={(customer) => {
          updateCurrentCart({ customer: customer });
          handleGetCustomerDetails(customer?.key);
        }}
      />
    );
  }
  return (
    <CustomerInfo
      customer={current_shopping_cart?.customer}
      removeCustomer={() => {
        updateCurrentSession({
          customer: null,
          loyalty: null,
          pricingApplied: pricingDefault,
          isShipping: false,
          summaries: {
            ...current_shopping_cart.summaries,
            shipping_fee: 0,
          },
          ignoreLoyalty: false,
        });
      }}
      showEditAddress={showEditAddress}
      setShowEditAddress={setShowEditAddress}
      reloadCustomer={() =>
        handleGetCustomerDetails(current_shopping_cart?.customer?.id)
      }
    />
  );
};
