import { BusinessProductCodeEnum } from "./FeaturesUtils";

export const OPEN_PRICING_LIST = [
  1000361058, 1000098547, 200000683045, 200000786579,
];

export const IS_OPEN_PRICING_LIST = (appContext) => {
  const supportPlans = [
    BusinessProductCodeEnum.GROW_092021,
    BusinessProductCodeEnum.SCALE_092021,
    BusinessProductCodeEnum.GROW,
    BusinessProductCodeEnum.SCALE,
    BusinessProductCodeEnum.PRO,
    BusinessProductCodeEnum.PRO_092021,
    BusinessProductCodeEnum.PRO_022024,
    BusinessProductCodeEnum.ADVANCED_022024,
    BusinessProductCodeEnum.GROW_022024,
    BusinessProductCodeEnum.SCALE_022024,
    BusinessProductCodeEnum.SCALE_022024_ENTERPRISE,
  ];
  return (
    appContext.checkStoreId(OPEN_PRICING_LIST) ||
    supportPlans.includes(appContext?.omniFeature?.product_code)
  );
};
