import { Route } from "react-router-dom";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import {
  LayoutCard,
  LayoutCardHeader,
} from "screens-v2/layouts/layouts/omni-layout-card";
import { ProductDetail } from "./product-detail/ProductDetail";
import { ProductInfo } from "./product-info/ProductInfo";
import "./utils/index.css";
import EmptyState from "component-v2/empty-state";

export default function ProductRouter(props) {
  const intl = useIntl();
  const layout = useLayoutContext();
  const { match } = props;
  const strPath = `/variants/:variantId`;
  const headerTitle = intl.formatMessage({
    id: "menus.Tra cứu sản phẩm",
    defaultMessage: "Tra cứu sản phẩm",
  });
  useEffect(() => {
    layout.setPageTitle(headerTitle);
    layout.setBreadcrumb([{ title: headerTitle, link: null }]);
    return () => {
      layout.setBreadcrumb(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutBody size="sm" className="border-top">
      <LayoutCardHeader title={headerTitle} />
      <LayoutCard className="product--over-view">
        <div className="product--info">
          <ProductInfo isExact={match.isExact} />
        </div>
        <div className="product--detail">
          {match?.isExact ? (
            <div className="empty-state-wrapper">
              <EmptyState
                iconName="local_offer"
                description={intl.formatMessage({
                  id: "products.searches.Vui lòng tìm kiếm hoặc nhập barcode để tra cứu thông tin",
                  defaultMessage:
                    "Vui lòng tìm kiếm hoặc nhập barcode để tra cứu thông tin",
                })}
              />
            </div>
          ) : null}
          <Route
            path={match.path + "/:productId" + strPath}
            component={ProductDetail}
          />
        </div>
      </LayoutCard>
    </LayoutBody>
  );
}
