import React, { useEffect, useState } from "react";
import Icon from "component-v2/icons/icons";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { useIntl } from "react-intl";
import Button from "component-v2/buttons";
import { Input } from "@haravan/react-components";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { cloneDeep, findIndex } from "lodash";
import { useApp } from "contexts/app";
import {
  createCustomPaymentMethod,
  deletePaymentMethod,
  updateCustomPaymentMethod,
} from "api/settings-repository";
import { isHTMLString, removeHTMLTags } from "utils/Commons";
const MAX_CUSTOM_PAYMENT_METHODS = 20;
const CustomPaymentMethod = ({
  customPaymentList,
  showCustomModal,
  currentMethod,
  setCurrentMethod,
  setShowCustomModal,
  handleCreateCustomGateway,
  handleDeletePaymentMethod,
  paymentMethods,
}) => {
  const intl = useIntl();
  const appContext = useApp();
  const [currentCustomMethod, setCurrentCustomMethod] = React.useState({
    name: "",
    code: null,
  });
  const layout = useLayoutContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentMethod) {
      const payment = customPaymentList.find(
        (item) => item.code === currentMethod,
      );
      setCurrentCustomMethod({
        name: payment.name,
        code: payment.code,
      });
    }
  }, [currentMethod]);

  const [showModalDeleteMethod, setShowModalDeleteMethod] =
    React.useState(false);
  useEffect(() => {
    if (!showCustomModal) {
      setCurrentMethod(null);
      setCurrentCustomMethod({
        name: "",
        id: null,
      });
    }
  }, [showCustomModal]);

  const _handleDeletePaymentMethod = async (currentCustomMethod) => {
    let updateData = cloneDeep(paymentMethods);
    const index = findIndex(updateData, { code: currentCustomMethod.code });
    updateData.splice(index, 1);
    currentCustomMethod.locationId = appContext.current_location?.id;
    setLoading(true);
    const response = await deletePaymentMethod(appContext, currentCustomMethod);
    setLoading(false);
    if (response.data) {
      setShowModalDeleteMethod(false);
      handleDeletePaymentMethod(updateData);
    } else if (response?.errors?.length) {
      layout.showGlobalToast("error", response?.errors[0]);
    }
  };

  const body = () => {
    return (
      <div>
        <p>Tên phương thức thanh toán</p>
        <Input
          placeholder="Nhập tên phương thức"
          className="mb-3"
          value={currentCustomMethod.name}
          onChange={(value) => {
            setCurrentCustomMethod({
              ...currentCustomMethod,
              name: value,
              code: currentMethod ? currentMethod : currentCustomMethod.code,
            });
          }}
          onFocus={(_, e) => {
            e.target.select();
          }}
          autoFocus={true}
        ></Input>
      </div>
    );
  };

  const handleSaveNewMethod = () => {
    if (!currentCustomMethod.name.trim()) {
      layout.showGlobalToast("error", "Chưa nhập tên phương thức thanh toán");
      return;
    }
    if (currentCustomMethod.name.length > 50) {
      layout.showGlobalToast("error", "Giới hạn tối đa 50 ký tự");
      return;
    }

    if (isHTMLString(currentCustomMethod.name)) {
      layout.showGlobalToast(
        "error",
        "Tên phương thức thanh toán không hợp lệ",
      );
      return;
    }

    if (
      paymentMethods.find(
        (item) =>
          item.name?.toLowerCase() === currentCustomMethod.name?.toLowerCase(),
      )
    ) {
      layout.showGlobalToast("error", "Tên phương thức đã tồn tại");
      return;
    }
    _handleCreateCustomGateway(currentCustomMethod);
  };

  const _handleCreateCustomGateway = async (gateway) => {
    const model = {
      name: removeHTMLTags(gateway.name),
      locationId: appContext.current_location?.id,
      code: gateway.code || null,
    };

    let response;
    let updateData = cloneDeep(paymentMethods);
    const index = findIndex(updateData, { code: model.code });
    setLoading(true);
    try {
      if (gateway.code) {
        // update
        updateData[index].name = gateway.name;
        updateData[index].code = gateway.name;
        response = await updateCustomPaymentMethod(appContext, model);
        setLoading(false);
      } else {
        const newMethod = {
          name: gateway.name,
          code: gateway.name,
          isCustom: true,
          isEnable: true,
        };
        updateData.push(newMethod);
        // create new
        response = await createCustomPaymentMethod(appContext, model);
        setLoading(false);
      }
      // .then fetch list custom method
      if (response.data) {
        handleCreateCustomGateway(updateData);
        setShowCustomModal(false);
      } else {
        layout.showGlobalToast("error", response.errors);
      }
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  const footer = () => {
    return (
      <div className="d-flex justify-content-between w-100">
        {currentMethod ? (
          <GlobalModal
            headerTitle={intl.formatMessage({
              id: "settings.generals.Xóa phương thức thanh toán",
              defaultMessage: "Xóa phương thức thanh toán",
            })}
            body={
              <>
                <p>Xác nhận xóa phương thức thanh toán</p>
                <p className="font-weight-medium">{currentCustomMethod.code}</p>
              </>
            }
            show={showModalDeleteMethod}
            setShow={setShowModalDeleteMethod}
            onCancel={() => setShowModalDeleteMethod(false)}
            footer={
              <>
                <Button
                  onClick={() => setShowModalDeleteMethod(false)}
                  light
                  children={<span>Quay lại</span>}
                />
                <Button
                  loading={loading}
                  onClick={() => {
                    _handleDeletePaymentMethod(currentCustomMethod);
                  }}
                  status="danger"
                  children={<span>Xác nhận xóa</span>}
                />
              </>
            }
          >
            <Button
              onClick={() => {
                setShowModalDeleteMethod(true);
              }}
              status="danger"
              children={<span>Xóa</span>}
            />
          </GlobalModal>
        ) : (
          <div></div>
        )}

        <div>
          <Button
            light
            onClick={() => {
              setShowCustomModal(false);
            }}
            children={<span>Hủy</span>}
          />
          <Button
            loading={loading}
            className={"ml-3"}
            onClick={() => handleSaveNewMethod()}
            status="primary"
            children={<span>Lưu</span>}
          />
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      className={`${showCustomModal && !showModalDeleteMethod ? "" : "d-none"}`}
      headerTitle={
        currentCustomMethod.code
          ? intl.formatMessage({
              id: "settings.generals.Chỉnh sửa phương thức thanh toán tùy chỉnh",
              defaultMessage: "Chỉnh sửa phương thức thanh toán tùy chỉnh",
            })
          : intl.formatMessage({
              id: "settings.generals.Thêm phương thức thanh toán tùy chỉnh",
              defaultMessage: "Thêm phương thức thanh toán tùy chỉnh",
            })
      }
      body={body()}
      show={showCustomModal}
      setShow={setShowCustomModal}
      onCancel={() => setShowCustomModal(false)}
      footer={footer()}
    >
      {customPaymentList.length < MAX_CUSTOM_PAYMENT_METHODS && (
        <div onClick={() => setShowCustomModal(true)} className="d-flex">
          <div
            className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1 cursor-pointer`}
            style={{ width: "40px", height: "40px" }}
          >
            <Icon className={"align-self-center"} name="add" />
          </div>
          <span className={"align-self-center cursor-pointer"}>Thêm</span>
        </div>
      )}
    </GlobalModal>
  );
};

export { CustomPaymentMethod };
