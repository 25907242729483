import React from "react";
import PropTypes from "prop-types";
import Icon from "./icons/icons";
import { ImageResize } from "@haravan/react-components";

export const ImageCustom = ({
  src,
  size = "small",
  alt = "image",
  sizeNoImage = "md",
  nameNoImage = "no_image_outline",
  className = "",
  onClick = () => {},
}) => {
  if (src) {
    return (
      <ImageResize
        onClick={onClick}
        className={className}
        size={size}
        alt={alt}
        src={src}
      />
    );
  }

  return (
    <div className="d-flex cursor-pointer align-items-center justify-content-center rounded-lg w-100 h-100 background-secondary" onClick={onClick}>
      <Icon name={nameNoImage} size={sizeNoImage} color="var(--coolgray400)" />
    </div>
  );
};

ImageCustom.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
  alt: PropTypes.string,
};
