import { Fragment, useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loading, Tooltip } from "@haravan/react-components";
import { isEmpty } from "lodash";
import RefundOrderModal from "./components/refund-order-modal";
import { diff } from "infrastructures/datetime-helpers";
import {
  getReportDetail,
  getTittleReportFollowOrderDate,
  getTittleReportFollowOrderDateDeal,
} from "../utils/utils";
import { FormattedMessage, useIntl } from "react-intl";
import NumberShow from "component-v2/number-show";
import CurrencyShow from "component-v2/currency-show";
import {
  DateTimeShowType,
  toFormattedCurrency,
  toFormattedDate,
  toFormattedNumber,
} from "infrastructures/format-helpers";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import { renderTooltipItem } from "../utils/highchart-utils";
import { useApp } from "contexts/app";
import { useWindowSize } from "hooks/useWindowSize";

const CHART_SERIES_DEFAULT = [
  {
    name: "Doanh thu",
    data: [0, 500000, 1000000],
    color: "#3C83F6",
    type: "line",
    yAxis: 1,
    events: {
      legendItemClick: function (e) {
        e.preventDefault();
      },
    },
    visible: false,
    marker: {
      symbol: "circle",
      radius: 5,
    },
    zIndex: 4,
  },
  {
    name: "Đơn hàng",
    data: [1],
    color: "#F97415",
    type: "column",
    events: {
      legendItemClick: function (e) {
        e.preventDefault();
      },
    },
    visible: false,
    marker: {
      symbol: "circle",
      radius: 5,
    },
    zIndex: 1,
  },
  {
    name: "Đã thu",
    data: [],
    color: "#14B8A5",
    type: "line",
    yAxis: 1,
    events: {
      legendItemClick: function (e) {
        e.preventDefault();
      },
    },
    visible: false,
    marker: {
      symbol: "circle",
      radius: 5,
    },
    zIndex: 3,
  },
  {
    name: "Thực nhận",
    data: [],
    color: "#6467F2",
    type: "line",
    yAxis: 1,
    events: {
      legendItemClick: function (e) {
        e.preventDefault();
      },
    },
    visible: false,
    marker: {
      symbol: "circle",
      radius: 5,
    },
    zIndex: 2,
  },
  {
    name: "Sản phẩm",
    data: [],
    color: "#D1D5DB",
    type: "column",
    events: {
      legendItemClick: function (e) {
        e.preventDefault();
      },
    },
    visible: false,
    marker: {
      symbol: "circle",
      radius: 5,
    },
    zIndex: 2,
  },
];

export default function GeneralTopProductReportChart({
  globalLoading,
  dateRange,
  channel,
  staff,
  reportData,
}) {
  const appContext = useApp();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const dataSeries = (data) => {
    return data.map((serie) => ({
      ...serie,
      name: intl.formatMessage({
        id: `reports.${serie.name}`,
        defaultMessage: serie.name,
      }),
    }));
  };
  const [chartOptions, setChartOptions] = useState({
    credits: { enabled: false },
    chart: {
      styleMode: true,
      className: "report--chart",
      height: null,
      style: {
        fontFamily: "Inter",
      },
    },
    title: { text: "" },
    xAxis: { categories: [] },
    yAxis: [
      {
        labels: {
          formatter: function () {
            return toFormattedNumber(Math.floor(this.value));
          },
          style: { color: "#000" },
        },
        title: {
          text: "",
          style: { color: "#000" },
        },
        opposite: true,
        tickInterval: 1,
        min: 0,
      },
      {
        gridLineWidth: 0,
        title: {
          text: "",
          style: {
            color: "#000",
          },
        },
        labels: {
          formatter: function () {
            return toFormattedCurrency(Math.floor(this.value));
          },
          style: {
            color: "#000000",
          },
        },
        min: 0,
      },
    ],
    tooltip: {
      useHTML: true,
      borderRadius: 4,
      borderWidth: 0,
      backgroundColor: "#FFFFFF",
      style: {
        boxShadow:
          "0px 0px 3px rgba(0, 0, 0, 0.12), 0px 4px 20px rgba(0, 0, 0, 0.16)",
      },
      formatter: function () {
        return this.points.reduce(
          function (s, point) {
            let name = point.series.name.split("(");
            return s + renderTooltipItem(name, point.color, point.y);
          },
          `<div style="font-weight: 500" class="font-size-12 content-secondary">${toFormattedDate(
            this.x,
            DateTimeShowType.date,
          )}</div>`,
        );
      },
      shared: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        maxPointWidth: 10,
      },
    },
    legend: {
      useHTML: true,
      verticalAlign: "bottom",
      layout: "horizontal",
      symbolPadding: 0,
      symbolHeight: 0,
      symbolRadius: 0,
      symbolWidth: 0,
      labelFormatter: function () {
        return (
          `<div class="report-chart-legend-custom ${
            this.visible ? "" : "report-chart-legend-custom--invisible"
          }">` +
          '<span class="legend-custom-symbol" style="background-color:' +
          this.color +
          '"></span>' +
          '<span class="legend-custom-label">' +
          this.name +
          "</span>" +
          "</div>"
        );
      },
    },
    series: dataSeries(CHART_SERIES_DEFAULT),
  });
  const [summary, setSummary] = useState({
    sumaryReport: {},
    sumaryOrderReport: {},
    sumaryOrderCancelReport: {},
    sumaryOrderPendingReport: {},
    totalReturnAmount: 0,
  });

  const [reportOrderRefundTable, setReportOrderRefundTable] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const refCallback = useRef(null);
  const { height } = useWindowSize();
  useEffect(() => {
    if (refCallback?.current) {
      let heightChart = refCallback?.current.getBoundingClientRect().height;
      setChartOptions((prev) => ({
        ...prev,
        chart: {
          ...prev.chart,
          height: heightChart - 10,
        },
      }));
    }
  }, [refCallback, height]);

  useEffect(() => {
    setLoading(true);
    if (!globalLoading) {
      const func = async () => {
        let onDay = diff(dateRange.startDate, dateRange.endDate, "days");
        let keySearch = onDay <= 1 ? "hour" : "";
        setSummary({
          sumaryReport: {},
          sumaryOrderReport: {},
          sumaryOrderCancelReport: {},
          totalReturnAmount: 0,
        });
        setReportOrderRefundTable([]);
        await onRefreshDataReport(keySearch);
        await onRefreshSumaryReport();
        setLoading(false);
      };
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLoading]);

  const onRefreshDataReport = async (keySearch) => {
    try {
      setChartOptions({
        ...chartOptions,
        xAxis: { categories: [] },
        series: [],
      });
      const reportResult = await getReportDetail(
        appContext,
        dateRange.startDate,
        dateRange.endDate,
        keySearch,
        channel,
        staff,
        reportData.orderRefund,
        reportData.queryRefund,
      );
      if (reportResult.key === "noData") {
        setChartOptions({
          ...chartOptions,
          chart: {
            ...chartOptions.chart,
            ignoreHiddenSeries: false,
          },
          xAxis: {
            labels: {
              enabled: false,
            },
          },
          series: dataSeries(CHART_SERIES_DEFAULT),
        });
        if (reportResult?.reportRefundTable?.length) {
          setReportOrderRefundTable(reportResult.reportRefundTable);
        }
      } else {
        setChartOptions({
          ...chartOptions,
          chart: {
            ...chartOptions.chart,
            ignoreHiddenSeries: true,
          },
          xAxis: {
            categories: reportResult.highcharts.categories,
            labels: {
              enabled: reportResult.key === "noData" ? false : true,
              formatter: function () {
                return toFormattedDate(
                  this.value,
                  keySearch ? DateTimeShowType.time : DateTimeShowType.custom,
                  "DD/MM",
                );
              },
            },
          },
          series: dataSeries(reportResult.highcharts.series),
        });
        setReportOrderRefundTable(reportResult.reportRefundTable);
      }
    } catch (e) {}
  };

  const onRefreshSumaryReport = async () => {
    const sumaryOrderResult = await getTittleReportFollowOrderDate(
      appContext,
      dateRange.startDate,
      dateRange.endDate,
      false,
      channel,
      staff,
    );
    const sumaryOrderCancelResult = await getTittleReportFollowOrderDateDeal(
      appContext,
      dateRange.startDate,
      dateRange.endDate,
      true,
      channel,
      staff,
    );
    const sumaryOrderPendingResult = await getTittleReportFollowOrderDate(
      appContext,
      dateRange.startDate,
      dateRange.endDate,
      false,
      channel,
      staff,
      false,
      true,
    );
    let sumaryReport = {};
    let sumaryReportRefund = {};
    let sumaryOrderReport = {};
    let sumaryOrderCancelReport = {};
    let sumaryOrderPendingReport = {};
    if (isEmpty(sumaryReport)) {
      reportData.summaryReport?.forEach((item) => {
        sumaryReport[item.name] = item.value;
      });
    }
    if (isEmpty(sumaryReportRefund)) {
      reportData.summaryReportRefund?.forEach((item) => {
        sumaryReportRefund[item.name] = item.value;
      });
    }
    if (isEmpty(sumaryOrderCancelReport)) {
      sumaryOrderCancelResult?.forEach((item) => {
        sumaryOrderCancelReport[item.name] = item.value;
      });
    }
    if (isEmpty(sumaryOrderReport)) {
      sumaryOrderResult?.forEach((item) => {
        sumaryOrderReport[item.name] = item.value;
      });
    }
    if (isEmpty(sumaryOrderPendingReport)) {
      sumaryOrderPendingResult?.forEach((item) => {
        sumaryOrderPendingReport[item.name] = item.value;
      });
    }
    let totalReturnAmount = 0;
    if (channel) {
      if (channel === "channelPOS") {
        totalReturnAmount = -(
          (sumaryReportRefund.ReturnsAmount
            ? Math.abs(sumaryReportRefund.ReturnsAmount)
            : 0) +
          (reportData.summaryRefund
            ? reportData.summaryRefund.refundAmountTotal
            : 0)
        );
      } else if (channel === "channelOnline") {
        totalReturnAmount = -(
          (sumaryReportRefund.ReturnsAmount
            ? Math.abs(sumaryReportRefund.ReturnsAmount)
            : 0) -
          (reportData.summaryRefund
            ? reportData.summaryRefund.refundAmountTotal
            : 0)
        );
      }
    } else {
      totalReturnAmount = sumaryReport.ReturnsAmount || 0;
    }
    setSummary({
      sumaryReport,
      sumaryOrderReport,
      sumaryOrderCancelReport,
      sumaryOrderPendingReport,
      totalReturnAmount,
    });
  };

  if (loading)
    return (
      <div
        style={{ minHeight: "99%" }}
        className="report__chartV2 padding-left-16 padding-right-16 d-flex justify-content-center align-items-center"
      >
        <Loading />
      </div>
    );

  return (
    <Fragment>
      <LayoutCard className="report__chartV2">
        <div className="flex-auto">
          <span className="font-weight-semibold font-size-16 mb-4">
            <FormattedMessage id="reports.Biểu đồ" defaultMessage="Biểu đồ" />
          </span>
        </div>
        <div className="flex-auto flex-row l-mt-16">
          <span className="flex-stretch font-weight-medium">
            <FormattedMessage
              id="reports.Tổng tiền"
              defaultMessage="Tổng tiền"
            />
          </span>
          <span className="flex-auto font-weight-medium">
            <FormattedMessage
              id="carts.orders.Số lượng"
              defaultMessage="Số lượng"
            />
          </span>
        </div>
        <div ref={refCallback} className="flex-stretch">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </LayoutCard>
      <LayoutCard>
        <table className="table-hover  report-table">
          <thead>
            <tr className="text-secondary">
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="reports.Tổng hóa đơn"
                  defaultMessage="Tổng hóa đơn"
                />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="reports.Giảm giá"
                  defaultMessage="Giảm giá"
                />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="reports.Giảm giá KHTT"
                  defaultMessage="Giảm giá KHTT"
                />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage id="reports.Phí VC" defaultMessage="Phí VC" />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage id="shifts.Đã thu" defaultMessage="Đã thu" />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="reports.Chưa thu"
                  defaultMessage="Chưa thu"
                />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="shifts.Hoàn trả"
                  defaultMessage="Hoàn trả"
                />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="reports.Đơn hủy"
                  defaultMessage="Đơn hủy"
                />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="reports.Hủy hàng"
                  defaultMessage="Hủy hàng"
                />
              </th>
              <th className="text-right pr-4" style={{ padding: "10px" }}>
                <FormattedMessage
                  id="reports.Sản phẩm"
                  defaultMessage="Sản phẩm"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="right" className="pr-4">
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Tổng tiền thu được dựa trên đơn hàng Bán, xác định bằng Doanh thu (+) Phí vận chuyển (-) Các khoản giảm giá",
                    defaultMessage:
                      "Tổng tiền thu được dựa trên đơn hàng Bán, xác định bằng Doanh thu (+) Phí vận chuyển (-) Các khoản giảm giá",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    {summary.sumaryReport.TotalSaleAmount ? (
                      <CurrencyShow
                        value={summary.sumaryReport.TotalSaleAmount}
                      />
                    ) : (
                      `0 ₫`
                    )}
                  </span>
                </Tooltip>
              </td>
              <td align="right" className="pr-4">
                <Tooltip
                  title={intl.formatMessage({
                    id: "orders.searches.Tiền khuyến mãi",
                    defaultMessage: "Tiền khuyến mãi",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    {summary.sumaryReport.DiscountAmount ? (
                      <CurrencyShow
                        value={summary.sumaryReport.DiscountAmount}
                      />
                    ) : (
                      `0 ₫`
                    )}
                  </span>
                </Tooltip>
              </td>
              <td align="right" className="pr-4">
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Tiền khuyến mãi khách hàng thân thiết",
                    defaultMessage: "Tiền khuyến mãi khách hàng thân thiết",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    {summary.sumaryReport.RedeemValue ? (
                      <CurrencyShow value={summary.sumaryReport.RedeemValue} />
                    ) : (
                      `0 ₫`
                    )}
                  </span>
                </Tooltip>
              </td>
              <td align="right" className="pr-4">
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Tiền vận chuyển",
                    defaultMessage: "Tiền vận chuyển",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    {summary.sumaryReport.ShippingAmount ? (
                      <CurrencyShow
                        value={summary.sumaryReport.ShippingAmount}
                      />
                    ) : (
                      `0 ₫`
                    )}
                  </span>
                </Tooltip>
              </td>
              <td align="right" className="pr-4">
                {summary.sumaryReport.PaidAmount ? (
                  <CurrencyShow value={summary.sumaryReport.PaidAmount} />
                ) : (
                  `0 ₫`
                )}
              </td>
              <td align="right" className="pr-4">
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Tổng tiền Chưa thu của những đơn hàng được tạo ra bởi chi nhánh và nhân viên theo bộ lọc",
                    defaultMessage:
                      "Tổng tiền Chưa thu của những đơn hàng được tạo ra bởi chi nhánh và nhân viên theo bộ lọc",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  {staff && staff.id !== null ? (
                    <span>
                      {summary.sumaryOrderPendingReport.TotalSaleAmount ? (
                        <CurrencyShow
                          value={
                            summary.sumaryOrderPendingReport.TotalSaleAmount -
                            summary.sumaryOrderPendingReport.PaidAmount
                          }
                        />
                      ) : (
                        `0 ₫`
                      )}
                    </span>
                  ) : (
                    <span>
                      {summary.sumaryOrderReport.TotalSaleAmount ? (
                        <CurrencyShow
                          value={
                            summary.sumaryOrderReport.TotalSaleAmount -
                            summary.sumaryOrderReport.PaidAmount
                          }
                        />
                      ) : (
                        `0 ₫`
                      )}
                    </span>
                  )}
                </Tooltip>
              </td>
              <td
                align="right"
                className={`pr-4  ${
                  summary.totalReturnAmount
                    ? "pointer text-primary underline"
                    : ""
                }`}
                onClick={() =>
                  summary.totalReturnAmount && setShowRefundModal(true)
                }
              >
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Tổng tiền các đơn hàng có thực hiện Hoàn trả",
                    defaultMessage:
                      "Tổng tiền các đơn hàng có thực hiện Hoàn trả",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    <CurrencyShow value={summary.totalReturnAmount} />
                  </span>
                </Tooltip>
              </td>
              <td align="right" className="pr-4" style={{ color: "#FF5A16" }}>
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Số lượng đơn hàng có trạng thái thanh toán là Hủy",
                    defaultMessage:
                      "Số lượng đơn hàng có trạng thái thanh toán là Hủy",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    {summary.sumaryOrderCancelReport.OrderCountSpecial ? (
                      <NumberShow
                        value={
                          summary.sumaryOrderCancelReport.OrderCountSpecial
                        }
                      />
                    ) : (
                      0
                    )}
                  </span>
                </Tooltip>
              </td>
              <td align="right" className="pr-4" style={{ color: "#FF5A16" }}>
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Tổng tiền các đơn hàng có trạng thái thanh toán là Hủy",
                    defaultMessage:
                      "Tổng tiền các đơn hàng có trạng thái thanh toán là Hủy",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    {summary.sumaryOrderCancelReport.ReturnsAmount ? (
                      <CurrencyShow
                        value={summary.sumaryOrderCancelReport.ReturnsAmount}
                      />
                    ) : (
                      `0 ₫`
                    )}
                  </span>
                </Tooltip>
              </td>
              <td align="right" className="pr-4">
                <Tooltip
                  title={intl.formatMessage({
                    id: "reports.Tổng số lượng sản phẩm đã bán được",
                  })}
                  overlayClassName="tooltip-custom"
                >
                  <span>
                    {summary.sumaryOrderCancelReport.Quantity ? (
                      <NumberShow value={summary.sumaryOrderReport.Quantity} />
                    ) : (
                      0
                    )}
                  </span>
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </LayoutCard>
      <RefundOrderModal
        showRefundModal={showRefundModal}
        setShowRefundModal={setShowRefundModal}
        reportOrderRefundTable={reportOrderRefundTable}
      />
      {showRefundModal && <div className="bg-blur"></div>}
    </Fragment>
  );
}
