class SiteUtils {
  getParamsFromUrl() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const entries = urlParams.entries();
      let params = {} as any;
      for (const pair of entries) {
        params[pair[0]] = encodeURIComponent(pair[1]);
      }
      return params;
    } catch (e) {
      return {};
    }
  }
}

const siteUtils = new SiteUtils();

export default siteUtils;
