import {
  forEach,
  groupBy,
  uniq,
  map,
  sortBy,
  forEachRight,
  sumBy,
  findIndex,
  orderBy,
  isUndefined,
  isNull,
} from "lodash";
import RandomColor from "../../../utils/randomColor";
import "moment";
import { toFormattedNumber } from "infrastructures/format-helpers";

export default class HighchartUtils {
  static convertFormOmniReport(
    headerReport,
    dataReport,
    dataOrderReportDate,
    dataReportRefund,
    keySearch,
    channel = null,
    headerReportRefund,
    orderRefund,
    queryRefund,
  ) {
    const reportRow = (rowItem, header = headerReport) => {
      let rowResult = {};
      forEach(header, (headerItem, index) => {
        rowResult = { ...rowResult, [headerItem.name]: rowItem[index] };
      });

      return { ...rowResult, Hour: rowResult.Hour };
    };
    let reportModels = [];
    let reportRefundModels = [];
    let reportOrderDateModels = [];
    forEach(dataReport, (item, index) => {
      reportModels.push(reportRow({ ...item }));
    });
    forEach(dataReportRefund, (item, index) => {
      reportRefundModels.push(reportRow({ ...item }, headerReportRefund));
    });
    forEach(dataOrderReportDate, (item, index) => {
      reportOrderDateModels.push(reportRow({ ...item }));
    });
    let highcharts = {
      categories: [],
      series: [],
    };

    let reportModelAfterMapRefund = reportModels;
    if (queryRefund) {
      for (let i = 0; i < queryRefund.length; i++) {
        for (let j = 0; j < reportModels.length; j++) {
          let flagBreakPoint = false;
          if (reportModelAfterMapRefund[j].Hour) {
            if (reportModelAfterMapRefund[j].Hour === queryRefund[i].date) {
              if (channel === "channelPOS") {
                reportModelAfterMapRefund[j].ReturnsAmount =
                  Math.abs(reportModelAfterMapRefund[j].ReturnsAmount) +
                  queryRefund[i].refundAmountTotal;
              } else if (channel === "channelOnline") {
                reportModelAfterMapRefund[j].ReturnsAmount =
                  (reportModelAfterMapRefund[j].ReturnsAmount
                    ? Math.abs(reportModelAfterMapRefund[j].ReturnsAmount)
                    : 0) - queryRefund[i].refundAmountTotal;
              }
              flagBreakPoint = true;
              break;
            } else if (
              !flagBreakPoint &&
              j === reportModelAfterMapRefund.length - 1
            ) {
              reportModelAfterMapRefund.push({
                Hour: queryRefund[i].date,
                OrderCountSpecial: 0,
                GrossSaleAmount: 0,
                DiscountAmount: 0,
                NetSaleAmount: 0,
                ShippingAmount: 0,
                TotalSaleAmount: 0,
                PaidAmount: 0,
                ReturnsAmount: queryRefund[i].refundAmountTotal,
                Quantity: 0,
                RedeemValue: 0,
              });
              flagBreakPoint = true;
              break;
            }
          } else {
            if (reportModelAfterMapRefund[j].Day === queryRefund[i].date) {
              if (channel === "channelPOS") {
                reportModelAfterMapRefund[j].ReturnsAmount =
                  Math.abs(reportModelAfterMapRefund[j].ReturnsAmount) +
                  queryRefund[i].refundAmountTotal;
              } else if (channel === "channelOnline") {
                reportModelAfterMapRefund[j].ReturnsAmount =
                  (reportModelAfterMapRefund[j].ReturnsAmount
                    ? Math.abs(reportModelAfterMapRefund[j].ReturnsAmount)
                    : 0) - queryRefund[i].refundAmountTotal;
              }
              flagBreakPoint = true;
              break;
            } else if (
              !flagBreakPoint &&
              j === reportModelAfterMapRefund.length - 1
            ) {
              reportModelAfterMapRefund.push({
                Day: queryRefund[i].date,
                OrderCountSpecial: 0,
                GrossSaleAmount: 0,
                DiscountAmount: 0,
                NetSaleAmount: 0,
                ShippingAmount: 0,
                TotalSaleAmount: 0,
                PaidAmount: 0,
                ReturnsAmount: queryRefund[i].refundAmountTotal,
                Quantity: 0,
                RedeemValue: 0,
              });
              flagBreakPoint = true;
              break;
            }
          }
        }
      }
    }

    let groupedByGateWay = groupBy(reportModelAfterMapRefund, "Gateway");
    let groupedByOrderDate = groupBy(reportOrderDateModels, "Gateway");
    // get the set of unique dates
    let categories = [];
    categories = uniq(
      map(
        sortBy(reportModelAfterMapRefund, (o) => {
          return o.Hour ? o.Hour : o.Day;
        }),
        keySearch === "hour" ? "Hour" : "Day",
      ),
    );
    highcharts.categories = categories;
    // for each product, perform the aggregation
    forEach(groupedByGateWay, (_values, key) => {
      // initialize the data array for each date
      let data = [];
      let data1 = [];
      let data2 = [];
      let dataQuantity = [];
      for (var i = 0; i < highcharts.categories.length; i++) {
        data.push(0);
      }
      // aggregate gross_revenue by date
      forEachRight(
        groupBy(groupedByGateWay[key], keySearch === "hour" ? "Hour" : "Day"),
        (dateValue, dateKey) => {
          // use the date as an array index
          highcharts.categories.forEach((date, index) => {
            if (dateKey === date) {
              //Doanh thu
              data[parseInt(index)] = sumBy(dateValue, (o) => {
                return o.NetSaleAmount;
              });
              //Đã thu
              data1[parseInt(index)] = sumBy(dateValue, (o) => {
                return o.PaidAmount;
              });
              //Thực nhận
              data2[parseInt(index)] = sumBy(dateValue, (o) => {
                return o.PaidAmount - Math.abs(o.ReturnsAmount);
              });
              //Số sản phẩm
              dataQuantity[parseInt(index)] = sumBy(dateValue, (o) => {
                return o.Quantity;
              });
            }
          });
        },
      );
      highcharts.series.push({
        name: "Doanh thu thuần",
        data: data,
        color: "#3C83F6",
        type: "line",
        yAxis: 1,
        marker: {
          symbol: "circle",
          radius: 5,
        },
        tooltip: {
          valueSuffix: " ₫",
        },
        zIndex: 4,
      });
      highcharts.series.push({
        name: "Đã thu",
        data: data1,
        color: "#14B8A5",
        type: "line",
        yAxis: 1,
        marker: {
          symbol: "circle",
          radius: 5,
        },
        visible: false,
        tooltip: {
          valueSuffix: " ₫",
        },
        zIndex: 3,
      });
      highcharts.series.push({
        name: "Thực nhận",
        data: data2,
        color: "#6467F2",
        type: "line",
        yAxis: 1,
        marker: {
          symbol: "circle",
          radius: 5,
        },
        visible: false,
        tooltip: {
          valueSuffix: " ₫",
        },
        zIndex: 2,
      });
      highcharts.series.push({
        name: "Sản phẩm",
        data: dataQuantity,
        color: "#D1D5DB",
        type: "column",
        marker: {
          symbol: "circle",
          radius: 5,
        },
        visible: false,
        zIndex: 1,
      });
    });

    forEach(groupedByOrderDate, (values, key) => {
      let data = [];
      for (let i = 0; i < highcharts.categories.length; i++) {
        const eCategories = highcharts.categories[i];
        for (let j = 0; j < groupedByOrderDate[key].length; j++) {
          const orderNumber = groupedByOrderDate[key][j];
          let orderNumberTime = orderNumber.Day || orderNumber.Hour;
          if (eCategories === orderNumberTime) {
            data[parseInt(i)] = orderNumber.OrderCountSpecial;
            break;
          } else {
            data[parseInt(i)] = 0;
          }
        }
      }
      highcharts.series.splice(1, 0, {
        name: "Đơn hàng",
        data: data,
        color: "#F97415",
        type: "column",
        marker: {
          symbol: "circle",
          radius: 5,
        },
        zIndex: 2,
      });
    });
    if (orderRefund) {
      if (channel === "channelPOS") {
        for (let i = 0; i < orderRefund.length; i++) {
          reportRefundModels.push({
            OrderNumber: orderRefund[i].orderNumber,
            Hour: orderRefund[i].createdAt,
            ReturnsAmount: orderRefund[i].amount,
            SourceName: orderRefund[i].orderSource,
          });
        }
      } else if (channel === "channelOnline") {
        let indexOfOrderRefund = -1;
        orderRefund.forEach((refund) => {
          indexOfOrderRefund = findIndex(reportRefundModels, (item) => {
            return item.OrderNumber === refund.orderNumber;
          });
        });
        if (indexOfOrderRefund !== -1) {
          reportRefundModels.splice(indexOfOrderRefund, 1);
        }
      }
    }
    reportRefundModels = orderBy(reportRefundModels, (o) => o.Hour, "desc");
    return {
      highcharts: highcharts,
      reportRefundTable: reportRefundModels,
    };
  }

  static convertReportRefundTable(
    dataReportRefund,
    headerReportRefund,
    channel,
    orderRefund,
  ) {
    const reportRow = (rowItem, header) => {
      let rowResult = {};
      forEach(header, (headerItem, index) => {
        rowResult = { ...rowResult, [headerItem.name]: rowItem[index] };
      });

      return { ...rowResult, Hour: rowResult.Hour };
    };

    let reportRefundTable = [];
    forEach(dataReportRefund, (item, index) => {
      reportRefundTable.push(reportRow({ ...item }, headerReportRefund));
    });

    if (orderRefund) {
      if (channel === "channelPOS") {
        for (let i = 0; i < orderRefund.length; i++) {
          reportRefundTable.push({
            OrderNumber: orderRefund[i].orderNumber,
            Hour: orderRefund[i].createdAt,
            ReturnsAmount: orderRefund[i].amount,
            SourceName: orderRefund[i].orderSource,
          });
        }
      } else if (channel === "channelOnline") {
        let indexOfOrderRefund = -1;
        orderRefund.forEach((refund) => {
          indexOfOrderRefund = findIndex(reportRefundTable, (item) => {
            return item.OrderNumber === refund.orderNumber;
          });
        });
        if (indexOfOrderRefund !== -1) {
          reportRefundTable.splice(indexOfOrderRefund, 1);
        }
      }
    }

    return reportRefundTable;
  }

  /**
   * Convert data payment method from Omni
   */
  static convertPaymentMethodOmniReport(
    headerReport,
    dataReport,
    summaryPaymentRefund,
    keySearch,
    channel,
  ) {
    const reportRow = (rowItem) => {
      let rowResult = {};
      forEach(headerReport, (headerItem, index) => {
        rowResult = { ...rowResult, [headerItem.name]: rowItem[index] };
      });
      return { ...rowResult };
    };
    let reportModels = [];
    forEach(dataReport, (item, index) => {
      reportModels.push(reportRow({ ...item }));
    });

    let highcharts = {
      categories: [],
      series: [],
    };
    // get the set of unique dates
    let categories = [];
    categories = uniq(map(reportModels, keySearch === "hour" ? "Hour" : "Day"));
    highcharts.categories = categories.reverse();
    // for each product, perform the aggregation
    let groupDataGateway = (reportModels) => {
      let dataGroup = groupBy(reportModels, "Gateway");
      let dataTable = [];
      forEach(dataGroup, (values, key) => {
        //group data for table
        let newDataTableRow = {};
        forEach(values, (value, index) => {
          forEach(headerReport, (headerItem, index) => {
            if (headerItem.type === "Measure") {
              if (
                !isUndefined(newDataTableRow[headerItem.name]) &&
                !isNull(newDataTableRow[headerItem.name])
              ) {
                newDataTableRow[headerItem.name] += value[headerItem.name];
              } else {
                newDataTableRow[headerItem.name] = value[headerItem.name];
              }
            } else {
              newDataTableRow[headerItem.name] = value[headerItem.name];
            }
          });
        });
        dataTable.push(newDataTableRow);
      });
      return dataTable;
    };
    let dataTableGateway = groupDataGateway(reportModels);
    // ASK: Buggy Code, chưa fix được vì không có data mẫu
    if (summaryPaymentRefund) {
      for (let i = 0; i < summaryPaymentRefund.length; i++) {
        for (let j = 0; j < dataTableGateway.length; j++) {
          let paymentRefund = "";
          if (summaryPaymentRefund[i].gatewayName === "cash") {
            paymentRefund = "Tiền mặt";
          } else if (summaryPaymentRefund[i].gatewayName === "visa") {
            paymentRefund = "Visa/Master";
          } else if (summaryPaymentRefund[i].gatewayName === "transfer") {
            paymentRefund = "Chuyển khoản";
          } else if (summaryPaymentRefund[i].gatewayName === "cod") {
            paymentRefund = "COD";
          }
          if (dataTableGateway[j].Gateway === paymentRefund) {
            if (channel === "channelPOS") {
              dataTableGateway[j].ReturnsAmount = -(
                Math.abs(dataTableGateway[j].ReturnsAmount) +
                summaryPaymentRefund[i].refundAmountTotal
              );
            } else if (channel === "channelOnline") {
              dataTableGateway[j].ReturnsAmount = -(
                Math.abs(dataTableGateway[j].ReturnsAmount) -
                summaryPaymentRefund[i].refundAmountTotal
              );
            }
          }
        }
      }
    }
    if (dataTableGateway.length > 0) {
      forEach(dataTableGateway, (value, key) => {
        highcharts.series.push({
          name: value.Gateway,
          data: [value.PaidAmount],
        });
        //group data for table
      });
    }
    highcharts.series.forEach((item, index) => {
      if (item.name.toLowerCase().indexOf("tiền") !== -1) {
        highcharts.series[index].color = "#3C83F6";
      } else if (item.name.toLowerCase().indexOf("visa") !== -1) {
        highcharts.series[index].color = "#F97415";
      } else if (item.name.toLowerCase().indexOf("chuyển khoản") !== -1) {
        highcharts.series[index].color = "#14B8A5";
      } else if (item.name.toLowerCase().indexOf("cod") !== -1) {
        highcharts.series[index].color = "#6467F2";
      } else if (item.name.toLowerCase().indexOf("khác") !== -1) {
        highcharts.series[index].color = "#FDBD41";
      } else {
        highcharts.series[index].color = RandomColor.getRandomColor();
      }
    });

    return {
      table: dataTableGateway,
      highcharts: highcharts,
    };
  }

  static convertFormOmniNoDataReport() {
    const highcharts = {
      categories: [],
      series: [],
    };

    return {
      table: [],
      key: "noData",
      highcharts: highcharts,
    };
  }

  static convertFormOmniReportProduct(headerReport, dataReport) {
    const reportRow = (rowItem) => {
      let rowResult = {};
      forEach(headerReport, (headerItem, index) => {
        rowResult = { ...rowResult, [headerItem.name]: rowItem[index] };
      });
      return rowResult;
    };

    let reportModels = [];
    forEach(dataReport, (item, index) => {
      reportModels.push(reportRow(item));
    });
    let highcharts = {
      series: [],
    };
    let data = [];
    for (const item of reportModels) {
      let dataObj = {};
      let ProductId = item.ProductId ? item.ProductId : "Sản phẩm tùy chọn";
      let VariantId = item.VariantId ? " - " + item.VariantId : "";
      dataObj.name = ProductId + VariantId;
      dataObj.y = item.Quantity;
      if (item.ProductId !== "shipping" && item.Quantity !== 0) {
        data.push(dataObj);
      }
    }
    highcharts.series.push({ data });
    return {
      table: reportModels,
      highcharts: highcharts,
    };
  }

  static convertTopProducts(reportData, reportDataLink) {
    try {
      let products = [];
      forEach(reportData, (item, index) => {
        if (item[2] !== 0) {
          products.push({
            id: reportDataLink[index][0],
            name: item[0],
            total_sale_amount: item[1],
            quantity: item[2],
          });
        }
      });
      return products;
    } catch (e) {
      return [];
    }
  }
}

export const renderTooltipItem = (name, color, value) => {
  return `<div class="d-flex justify-content-between align-items-start mt-3"> 
      <div class="report-chart-legend-custom p-0 none-box-shadow">
        <span class="legend-custom-symbol" style="background-color: ${color}"></span>
        <span class="legend-custom-label">${name}</span>
      </div>
      <span class="ml-5">${toFormattedNumber(value)}</span>
    </div>`;
};
