import { Line } from "rc-progress";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";

export default function ProcessOrderForm({ intl, currentProcess }) {
  return (
    <GlobalModal
      show={true}
      headerClose={false}
      size={"sm"}
      headerTitle={intl.formatMessage({
        id: "carts.orders.Đang nhập dữ liệu",
        defaultMessage: "Đang nhập dữ liệu",
      })}
      body={
        <Line
          percent={currentProcess}
          strokeWidth={2.5}
          strokeColor="#1078FF"
          trailColor="#DCDCDC"
          trailWidth={2.5}
        />
      }
    />
  );
}
