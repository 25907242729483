import { CreateCashFlow, CreateImages } from "api/payment-voucher-api";
import { isEqual } from "lodash";
import {
  EnumAccountTypeMapping,
  EnumIdObjectiveMapping,
} from "models/payment-voucher-models";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  LayoutCard,
  LayoutCardHeader,
  LayoutCardSection,
} from "screens-v2/layouts/layouts/omni-layout-card";
import { getFile } from "utils/Commons";
import AdditionalInformation from "./components/additional-information";
import ModalCreateSuccess from "./components/modal-create-success";
import PaymentInformation from "./components/payment-information";
import { formDataPaymentDefault } from "./components/payment-voucher-constant";
import "./index.css";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import BlockUnsavedPage from "component-v2/block-unsaved-page";
import Button from "component-v2/buttons";
import { UpgradeShopPackage } from "components/UpgradeShopPackage/UpgradeShopPackage";
import { ShopPlanFunctionEnum } from "utils/FeaturesUtils";

export default function PaymentVoucherCreate() {
  const appContext = useApp();
  const intl = useIntl();
  const history = useHistory();
  const [formData, setFormData] = useState(formDataPaymentDefault);
  const [loadingBtnSave, setLoadingBtnSave] = useState(false);
  const [idCreatedSuccess, setIdCreatedSuccess] = useState(null);
  const layout = useLayoutContext();

  useEffect(() => {
    layout.setPageTitle(
      intl.formatMessage({
        id: "menus.Tạo phiếu chi",
        defaultMessage: "Tạo phiếu chi",
      }),
    );
    layout.setBreadcrumb([{ title: "Tạo phiếu chi", link: null }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);
  useEffect(() => {
    if (
      !appContext.checkRoleAllow("com_api.cashflow.read") &&
      !appContext.checkRoleAllow("com_api.cashflow.write")
    ) {
      history.replace("/");
    }

    return () => {
      layout.setPageTitle("");
      layout.setBreadcrumb();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    const currentLocation = appContext.current_location || {};
    const models = {
      ...formData,
      createdLoc: currentLocation?.id,
    };

    if (
      !models.accountId &&
      models.accountTypeId === EnumAccountTypeMapping.Bank
    ) {
      const msg = "Vui lòng chọn tài khoản chi";
      layout.showGlobalToast(
        "default",
        intl.formatMessage({ id: `notifications.${msg}`, defaultMessage: msg }),
      );
      return;
    }
    if (!models?.subTypeId) {
      const msg = "Vui lòng chọn lý do";
      layout.showGlobalToast(
        "default",
        intl.formatMessage({ id: `notifications.${msg}`, defaultMessage: msg }),
      );
      return;
    }
    if (models.notes && models.notes.length > 500) {
      const msg = "Ghi chú không được nhập quá 500 ký tự";
      layout.showGlobalToast(
        "default",
        intl.formatMessage({ id: `notifications.${msg}`, defaultMessage: msg }),
      );
      return;
    }
    if (!models?.objectTypeId) {
      const msg = "Vui lòng chọn đối tượng nhận";
      layout.showGlobalToast(
        "default",
        intl.formatMessage({ id: `notifications.${msg}`, defaultMessage: msg }),
      );
      return;
    }
    if (!models?.objectId) {
      if (models?.objectTypeId === EnumIdObjectiveMapping.Cash) {
        const msg = "Vui lòng chọn chi nhánh nhận";
        layout.showGlobalToast(
          "default",
          intl.formatMessage({
            id: `notifications.${msg}`,
            defaultMessage: msg,
          }),
        );
        return;
      }
      if (models?.objectTypeId === EnumIdObjectiveMapping.Bank) {
        const msg = "Vui lòng chọn tài khoản nhận";
        layout.showGlobalToast(
          "default",
          intl.formatMessage({
            id: `notifications.${msg}`,
            defaultMessage: msg,
          }),
        );
        return;
      }
      const msg = "Vui lòng chọn tên đối tượng";
      layout.showGlobalToast(
        "default",
        intl.formatMessage({ id: `notifications.${msg}`, defaultMessage: msg }),
      );
      return;
    }
    if (!models?.createdLoc) {
      const msg = "Vui lòng chọn chi nhánh tạo phiếu";
      layout.showGlobalToast(
        "default",
        intl.formatMessage({ id: `notifications.${msg}`, defaultMessage: msg }),
      );
      return;
    }
    if (models?.images?.length) {
      const newImages = models?.images
        ?.map((file) => ({
          preiview: file.preview,
        }))
        .filter((file) => file);
      models.images = newImages;
    }
    setLoadingBtnSave(true);
    const response = await CreateCashFlow(appContext, models);

    try {
      if (!response.errors || response.errors?.length <= 0) {
        if (formData.images?.length) {
          let images = await getFile(formData.images);
          await CreateImages(appContext, images, response.data);
        }
        setFormData(formDataPaymentDefault);
        setIdCreatedSuccess(response.data);
        setLoadingBtnSave(false);
        return;
      } else {
        const msg = response.errors?.[0] || `Đã có lỗi xảy ra`;
        layout.showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${msg}`,
            defaultMessage: msg,
          }),
        );
        setLoadingBtnSave(false);
      }
    } catch (error) {
      setLoadingBtnSave(false);
    }
  };

  if (
    !appContext.checkShopPlanSupported(ShopPlanFunctionEnum.inventory_advance)
  ) {
    return <UpgradeShopPackage />;
  }

  return (
    <LayoutBody size="sm" className="border-top">
      <BlockUnsavedPage
        default_data={formDataPaymentDefault}
        changed_data={formData}
      >
        <LayoutCardHeader title="Tạo phiếu chi" />
        <LayoutCard>
          <PaymentInformation
            formData={formData}
            onChangeData={(value) => {
              setFormData((prev) => ({
                ...prev,
                ...value,
              }));
            }}
          />
          <AdditionalInformation
            formData={formData}
            onChangeData={(value, name) => {
              setFormData((prev) => ({
                ...prev,
                [name]: value,
              }));
            }}
          />
          <LayoutCardSection className="text-right">
            <Button
              status="primary"
              title="Lưu"
              disabled={isEqual(formDataPaymentDefault, formData)}
              loading={loadingBtnSave}
              onClick={() => {
                if (!loadingBtnSave) {
                  handleSave();
                }
              }}
            />
          </LayoutCardSection>
        </LayoutCard>
        <ModalCreateSuccess data={idCreatedSuccess} />
      </BlockUnsavedPage>
    </LayoutBody>
  );
}
