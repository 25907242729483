import React from "react";
import PropTypes from 'prop-types';
import {Pagination} from "@haravan/react-components";
import {FormattedMessage, useIntl} from 'react-intl';
import {ROLES} from 'models/constants';
import NumberShow from 'component-v2/number-show';
import EmptyState from "component-v2/empty-state";
import { useApp } from "contexts/app";


export const ProductDetailList = (props) => {
  const {checkRoleAllow} = useApp()
  const intl = useIntl();
  const {totalRecord, onPagination, isAvailableInventory, isFilter, data} = props;

  return (
    <div className="product--detail-m-tb-16">
      <div className="product-search-list padding-top-16 padding-bottom-16">
        <div className="row" style={{maxHeight: "459px", marginRight: "0"}}>
          <div className="col-12 pr-0">
            {!isAvailableInventory || !isFilter || data.length === 0 ?
              renderException({isAvailableInventory, isFilter, data, intl}) :
              <div className="product-detail-table-list">
                <table className="table">
                  <thead>
                    <tr>
                      <th><FormattedMessage id='products.searches.Kho' defaultMessage='Kho' /></th>
                      {(checkRoleAllow(ROLES.API_LOCATION_READ) || checkRoleAllow(ROLES.API_LOCATION_WRITE)) && <th><FormattedMessage id='products.searches.Địa chỉ kho' defaultMessage='Địa chỉ kho' /></th>}
                      <th className="text-right"><FormattedMessage id='products.details.Khả dụng' defaultMessage='Khả dụng' /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderData({data, checkRoleAllow})}
                  </tbody>
                </table>
                {/* Pagination */}
                {totalRecord > 10 &&
                  <div className="row">
                    <div className="col-12">
                      <div className="product-search-list--pagination padding-top-16">
                        <div className="search-pagination" style={{width: '100%'}}>
                          <Pagination
                            pageSizeOptions={[10, 20, 50]}
                            defaultPageSize={10} total={totalRecord}
                            onChange={(page, limit) => {onPagination(page, limit);}}
                            onChangePageSize={(page, limit) => onPagination(page, limit)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};


function renderException(args) {
  const {isAvailableInventory, isFilter, intl, data} = args;
  // if (data.length > 0) return null;

  if (isAvailableInventory && !isFilter) {
    return <div className="product-detail-inventory-empty"><EmptyState iconName="storefront" description={intl.formatMessage({id: 'products.searches.Sản phẩm không có sẵn tại bất cứ kho hàng nào', defaultMessage: 'Sản phẩm không có sẵn tại bất cứ kho hàng nào'})} /></div>;
  }

  if (!isAvailableInventory && isFilter) {
    return <div className="product-detail-inventory-empty"><EmptyState iconName="storefront" description={intl.formatMessage({id: 'products.searches.Không tìm thấy kho', defaultMessage: 'Không tìm thấy kho'})} /></div>;
  }
  if (data.length === 0) {
    return <div className="product-detail-inventory-empty"><EmptyState iconName="storefront" description={intl.formatMessage({id: 'products.searches.Sản phẩm không có quản lý tồn kho', defaultMessage: 'Sản phẩm không có quản lý tồn kho'})} /></div>;
  }
  return null;
}

function renderData(args) {
  let result = null;
  const {data, checkRoleAllow} = args;
  result = data.map((item, index) => {
    return (
      <tr key={index}>
        <td  className="text-left">{item.locationName}</td>
        {(checkRoleAllow(ROLES.API_LOCATION_READ) || checkRoleAllow(ROLES.API_LOCATION_WRITE)) && <td className="text-left">{item.locAddress}</td>}
        <td className="text-right"><NumberShow value={item.qty_OnHand - item.qty_Commited} /></td>
      </tr>
    );
  });
  return result;
}

ProductDetailList.propTypes = {
  isAvailableInventory: PropTypes.bool,
  isFilter: PropTypes.bool,
  totalRecord: PropTypes.number,
  onPagination: PropTypes.func,
  data: PropTypes.array
};