import GeneralTopProductReportChart from "./general-top-product-report-chart";
import GeneralTopProductReport from "./general-top-product-report";

export default function GeneralReportTab({
  globalLoading,
  channel,
  staff,
  dateRange,
  reportData,
}) {
  return (
    <div className="row h-100">
      <div className="col-9 pr-0 h-100">
        <div className="report_chart_general" id="report_chart_general">
          <GeneralTopProductReportChart
            key="report"
            globalLoading={globalLoading}
            dateRange={dateRange}
            channel={channel}
            staff={staff}
            reportData={reportData}
          />
        </div>
      </div>
      <div className="col-3" style={{ maxHeight: "58.2rem" }}>
        <GeneralTopProductReport
          dateRange={dateRange}
          channel={channel}
          staff={staff}
        />
      </div>
    </div>
  );
}
