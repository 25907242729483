import { Input, Tooltip } from "@haravan/react-components";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useEffect, useRef, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { useRefundContext } from "../..";
import { InputNumber } from "./InputNumber";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { DiscountAllocationType } from "screens-v2/carts-v2/cart-helpers.service";
import { toFormattedNumber } from "infrastructures/format-helpers";
import CouponBadge from "screens-v2/carts-v2/RightCart/components/couponBadge";
const DiscountOrderModes = {
  cash: "amount",
  percent: "percent",
  coupon: "coupon",
  manual: "manual",
};

const TabDiscountOrder = [
  {
    id: DiscountOrderModes.coupon,
    name: "Mã giảm giá",
  },
  {
    id: DiscountOrderModes.manual,
    name: "Giảm trực tiếp",
  },
];

export const ModalDiscountOrder = ({ show, setShow }) => {
  const { shop_setting } = useApp();
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const refCash = useRef(null);
  const refPercent = useRef(null);
  const refCoupon = useRef(null);
  const [discountOrderMode, setDiscountOrderMode] = useState(
    DiscountOrderModes.coupon,
  );
  const [manualDiscountMode, setManualDiscountMode] = useState(
    DiscountOrderModes.cash,
  );
  const { current_shopping_cart, updateCurrentSession, loadingCart } =
    useRefundContext();
  const couponDiscounts = current_shopping_cart?.discountAllocations?.filter(
    (d) =>
      d.type !== DiscountAllocationType.MANUAL_ORDER &&
      d.type !== DiscountAllocationType.MEMBERSHIP &&
      d.type !== DiscountAllocationType.REDEEM_POINT,
  );
  const [discountCash, setDiscountCash] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountCoupon, setDiscountCoupon] = useState("");
  const totalCouponAmount = useMemo(() => {
    if (couponDiscounts?.length) {
      return couponDiscounts?.reduce((sum, cur) => (sum += cur.amount), 0);
    }
    return 0;
  }, [couponDiscounts]);
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8, HOTKEYS.ENTER],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC || e.key === HOTKEY_EVENTS.F8) {
        setShow(false);
      }
      if (
        (e.key === HOTKEY_EVENTS.F8 || e.key === HOTKEY_EVENTS.ENTER) &&
        !loadingCart
      ) {
        if (discountOrderMode === DiscountOrderModes.coupon && !discountCoupon)
          return;
        updateOrderDiscountValue(
          discountOrderMode === DiscountOrderModes.manual
            ? manualDiscountMode
            : discountOrderMode,
          discountCash,
          discountPercent,
          [...couponDiscounts.map((c) => c.name), discountCoupon],
        );
      }
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });

  useEffect(() => {
    if (current_shopping_cart?.orderDiscountSelected) {
      const { orderDiscountSelected } = current_shopping_cart;
      let discountType = "";
      if (shop_setting.promotionEnable) {
        if (
          orderDiscountSelected?.discountTypeName === DiscountOrderModes.cash ||
          orderDiscountSelected?.discountTypeName === DiscountOrderModes.percent
        ) {
          discountType = DiscountOrderModes.manual;
          setManualDiscountMode(orderDiscountSelected?.discountTypeName);
        } else {
          discountType =
            orderDiscountSelected?.discountTypeName ||
            DiscountOrderModes.coupon;
        }
      } else {
        discountType = DiscountOrderModes.coupon;
      }
      setDiscountOrderMode(discountType);
      setDiscountCash(
        orderDiscountSelected?.discountTypeName === DiscountOrderModes.cash
          ? orderDiscountSelected?.discountAmount
          : 0,
      );
      setDiscountPercent(
        orderDiscountSelected?.discountTypeName === DiscountOrderModes.percent
          ? orderDiscountSelected?.discountPercent
          : 0,
      );
      setDiscountCoupon("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_shopping_cart?.orderDiscountSelected, show]);

  useEffect(() => {
    if (!shop_setting?.promotionEnable) {
      setDiscountOrderMode(DiscountOrderModes.coupon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop_setting?.promotionEnable]);

  const handleChangeDiscountAmount = (value) => {
    let num = value;
    let orderPriceTotal = 0;
    current_shopping_cart?.lineItems?.slice().forEach((orderLine) => {
      if (!orderLine.notAllowPromotion) {
        orderPriceTotal += orderLine.price * orderLine.quantity;
      }
    });
    if (
      current_shopping_cart?.loyalty?.discountCouponLoyalty?.length &&
      value.length > 0
    ) {
    }
    if (num > orderPriceTotal) {
      num = orderPriceTotal;
    }
    setDiscountCash(num);
    setDiscountCoupon("");
  };

  const handleChangePercentAmount = (value) => {
    value = value.toString();
    let percent = value;
    let orderPriceTotal = 0;

    current_shopping_cart?.lineItems?.slice().forEach((orderLine) => {
      if (!orderLine.notAllowPromotion) {
        orderPriceTotal += orderLine.price * orderLine.quantity;
      }
    });
    if (
      current_shopping_cart?.loyalty?.discountCouponLoyalty?.length &&
      value.length > 0
    ) {
    }
    const amount = (orderPriceTotal * percent) / 100;
    setDiscountCash(amount);
    setDiscountPercent(percent);
    setDiscountCoupon("");
  };

  const handleChangeCouponCode = (value) => {
    setDiscountCoupon(value.trim());
  };

  const updateOrderDiscountValue = (method, amount, percent, coupons) => {
    const isOrderHasProductNotAllowPromotion =
      current_shopping_cart?.lineItems?.some(
        (orderLine) => orderLine.notAllowPromotion,
      );
    if (
      isOrderHasProductNotAllowPromotion &&
      current_shopping_cart?.lineItems?.length === 1
    ) {
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: "notifications.Đơn hàng chứa sản phẩm không áp dụng khuyến mãi!",
          defaultMessage: "Đơn hàng chứa sản phẩm không áp dụng khuyến mãi!",
        }),
      );
    } else {
      if (method !== DiscountOrderModes.coupon) {
        setShow(false);
      }
      updateOrderDiscountSelected({
        discountTypeId: !!coupons?.length ? 5 : 0,
        discountTypeName: method,
        discountAmount: amount,
        coupons: coupons,
        discountPercent: percent,
      });
    }
  };

  const updateOrderDiscountSelected = async ({
    discountTypeId,
    discountTypeName,
    discountAmount,
    coupons,
    discountPercent,
  }) => {
    const couponCode = !!coupons?.length ? coupons?.[0] : "";
    const orderDiscountMethods = {
      none: { name: "Không", is_show: false },
      coupon: { name: "Mã khuyến mãi", is_show: false },
      percent: { name: "% giảm", is_show: false },
      amount: { name: "Số tiền giảm", is_show: false },
    };

    const orderDiscountSelected = {
      discountTypeId: discountTypeId,
      discountTypeName: discountTypeName,
      discountAmount: discountAmount,
      discountName: orderDiscountMethods[discountTypeName]?.name || "",
      discountPercent:
        discountTypeName === "percent" ? discountPercent || 0 : 0,
      hasPromotion: current_shopping_cart?.lineItems?.some(
        (orderLine) => orderLine.hasPromotion,
      ),
      coupons: coupons || [],
      isUseCouponCode: couponCode !== "",
      isCouponCode: couponCode !== "",
    };

    updateCurrentSession({
      orderDiscountSelected,
      loyalty: {
        ...current_shopping_cart.loyalty,
        redeemUsedAmount: 0,
        rewardRedeemAmount: 0,
        discountCouponLoyalty: "",
      },
    });
  };

  const discountManualBody = () => {
    return (
      <div className="d-flex">
        <div className="sale-mode flex-basis-50 mr-3 discount-mode-root ">
          <div
            className={`${
              manualDiscountMode === DiscountOrderModes.cash
                ? "sale-mode-active"
                : ""
            } ${
              manualDiscountMode !== DiscountOrderModes.cash ? "disabled" : ""
            }`}
            onClick={() => {
              resetDiscount();
              setManualDiscountMode(DiscountOrderModes.cash);
            }}
          >
            Giảm tiền
          </div>
          <div
            className={`${
              manualDiscountMode === DiscountOrderModes.percent
                ? "sale-mode-active"
                : ""
            } ${
              manualDiscountMode !== DiscountOrderModes.percent
                ? "disabled"
                : ""
            }`}
            onClick={() => {
              resetDiscount();
              setManualDiscountMode(DiscountOrderModes.percent);
            }}
          >
            Giảm %
          </div>
        </div>
        {manualDiscountMode === DiscountOrderModes.percent ? (
          <InputNumber
            id="discountPercent"
            min={0}
            max={100}
            value={discountPercent}
            onChange={(value) => {
              handleChangePercentAmount(value);
            }}
            className="flex-basis-50 text-right h-100"
            ref={refPercent}
            autoFocus={true}
          />
        ) : (
          <InputNumber
            id="discountCash"
            currency
            value={discountCash}
            onChange={(value) => {
              handleChangeDiscountAmount(value);
            }}
            ref={refCash}
            className="flex-basis-50 text-right h-100"
            autoFocus={true}
          />
        )}
      </div>
    );
  };

  const discountCouponBody = () => {
    return (
      <div>
        <div className="coupon-input-wrapper">
          <Input
            className="coupon-input"
            placeholder="Mã giảm giá"
            value={discountCoupon}
            onChange={(value) => handleChangeCouponCode(value)}
            ref={refCoupon}
            autoFocus={discountOrderMode === DiscountOrderModes.coupon}
            onFocus={(_, e) => e.currentTarget.select()}
          />
          <span className={`coupon-apply-btn mx-1`}>
            <Button
              title={"Áp dụng"}
              className={"flex-shrink-0"}
              light
              size="sm"
              plain
              disabled={loadingCart}
              onClick={() => {
                updateOrderDiscountValue(
                  discountOrderMode === DiscountOrderModes.manual
                    ? manualDiscountMode
                    : discountOrderMode,
                  discountCash,
                  discountPercent,
                  [...couponDiscounts.map((c) => c.name), discountCoupon],
                );
              }}
              badge={HOTKEYS.ENTER}
            />
          </span>
        </div>
        {!!couponDiscounts?.length && (
          <>
            <div className="d-flex flex-column mt-2">
              <span className="coupon-sum-span">
                Đã áp dụng {couponDiscounts?.length} mã khuyến mãi. Giảm tổng
                cộng {toFormattedNumber(totalCouponAmount || 0)}đ
              </span>
              <div className="d-inline-flex flex-wrap">
                <p className="m-0 content-secondary align-content-center l-mr-8">
                  Đang áp dụng
                </p>
                {couponDiscounts?.map((coupon) => {
                  return (
                    <CouponBadge
                      key={coupon.name}
                      className={"l-pr-8 l-mr-8 mt-2"}
                      couponLabel={coupon.name}
                      canRemove={true}
                      iconBackground={
                        coupon.type === DiscountAllocationType.SHIPPING
                          ? "var(--teal400)"
                          : "var(--orange300)"
                      }
                      badgeIcon={
                        coupon.type === DiscountAllocationType.SHIPPING
                          ? "local_shipping"
                          : "card_giftcard"
                      }
                      onClickRemove={(coupon) => {
                        const newCoupons = couponDiscounts.reduce(
                          (res, cur) => {
                            if (cur.name !== coupon) res.push(cur.name);
                            return res;
                          },
                          [],
                        );
                        updateOrderDiscountSelected({
                          discountTypeId: !!newCoupons?.length ? 5 : 0,
                          discountTypeName: DiscountOrderModes.coupon,
                          discountAmount: discountCash,
                          coupons: newCoupons,
                          discountPercent: discountPercent,
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const body = () => {
    let content = null;

    switch (discountOrderMode) {
      case DiscountOrderModes.manual:
        content = discountManualBody();
        break;
      case DiscountOrderModes.coupon:
        content = discountCouponBody();
        break;
      default:
        content = discountCouponBody();
        break;
    }
    return (
      <div>
        <div className="d-flex mb-3 l-tab-group">
          {TabDiscountOrder.map((discount) => {
            if (
              !shop_setting.promotionEnable &&
              discount.id === DiscountOrderModes.manual
            ) {
              return null;
            }
            return (
              <div
                key={discount.id}
                className={`p-3 font-weight-semibold rounded cursor-pointer ${
                  discount.id === discountOrderMode
                    ? "background-accent50 content-accent"
                    : discount.id === DiscountOrderModes.manual &&
                        !!couponDiscounts?.length &&
                        discountOrderMode === DiscountOrderModes.coupon
                      ? "content-tertiary"
                      : "content-secondary"
                }`}
                onClick={() => {
                  if (
                    !!couponDiscounts?.length &&
                    discount.id !== DiscountOrderModes.coupon
                  )
                    return;
                  setDiscountOrderMode(discount.id);
                }}
              >
                {discount.id === DiscountOrderModes.manual &&
                !!couponDiscounts?.length &&
                discountOrderMode === DiscountOrderModes.coupon ? (
                  <Tooltip
                    placement="top"
                    trigger="hover"
                    overlay={
                      <div className="font-weight-regular font-size-12 lh-18">
                        <p className="content-on-color-primary l-mb-4">
                          Giảm trực tiếp không áp dụng chung với mã giảm giá. Gỡ
                          các mã giảm giá để sử dụng tính năng này
                        </p>
                      </div>
                    }
                  >
                    {discount.name}
                  </Tooltip>
                ) : (
                  discount.name
                )}
              </div>
            );
          })}
        </div>
        {content}
      </div>
    );
  };

  function resetDiscount() {
    setDiscountCash(0);
    setDiscountPercent(0);
    setDiscountCoupon("");
  }

  const footer = () => (
    <div className="d-flex justify-content-between w-100">
      <Button
        onClick={() => {
          setShow(false);
          resetDiscount();
          updateOrderDiscountValue("", 0, 0, []);
        }}
        light
        className="font-size-14"
        title={"Xóa giảm giá"}
      />
      <div className="d-flex justify-content-center">
        <Button
          onClick={() => {
            setShow(false);
          }}
          light
          badge={HOTKEYS.ESC}
          className="font-size-14"
          title={"Hủy"}
        />
        <Button
          onClick={() => {
            setShow(false);
            if (
              discountOrderMode === DiscountOrderModes.coupon &&
              !discountCoupon
            )
              return;
            updateOrderDiscountValue(
              discountOrderMode === DiscountOrderModes.manual
                ? manualDiscountMode
                : discountOrderMode,
              discountCash,
              discountPercent,
              [...couponDiscounts.map((c) => c.name), discountCoupon],
            );
          }}
          badge={HOTKEYS.F8}
          status="primary"
          className="font-size-14 ml-3"
          title={"Xong"}
        />
      </div>
    </div>
  );

  return (
    <GlobalModal
      headerTitle={"Giảm giá đơn hàng"}
      isTitleCenter
      body={body()}
      footer={footer()}
      show={show}
      setShow={setShow}
    />
  );
};
