import React, { useRef } from "react";
import { v4 as uuid } from "uuid";
import { concat, map, trim } from "lodash";
import { FormattedMessage } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { useHotkeysContext } from "react-hotkeys-hook";

export default function ImportOrderForm({
  intl,
  show,
  setShow,
  setErrorMessage,
  setCurrentForm,
  setDataSheet,
  checkProductInWorkSheet,
  dataSuccess,
  setDataSuccess,
  setDataError,
  addOrderLine,
}) {
  const inputRef = useRef(null);
  const { enableScope } = useHotkeysContext();
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        setShow(false);
        enableScope(HOTKEY_SCOPES.CART);
      }
      if (e.key === HOTKEY_EVENTS.F8) {
        onChooseFiles();
      }
    },
    scopes: HOTKEY_SCOPES.MODAL,
    enabled: show,
  });
  function handleFileWithXlsx(files) {
    if (!files) return;
    const file = files[0];
    if (file && file.size > 7340032) {
      setErrorMessage(
        intl.formatMessage({
          id: "carts.orders.File tải lên vượt quá dung lượng tối đa cho phép, vui lòng kiểm tra lại!",
          defaultMessage:
            "File tải lên vượt quá dung lượng tối đa cho phép, vui lòng kiểm tra lại!",
        }),
      );
      setCurrentForm(4);
      return;
    }

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onloadstart = (e) => {
      if (e.lengthComputable) {
      }
    };
    reader.onprogress = (e) => {
      if (e.lengthComputable) {
      }
    };
    reader.onloadend = async (e) => {
      const bstr = e.target.result;
      const XLSX = await import("xlsx");
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: false,
        cellNF: false,
        cellText: false,
      });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      if (!ws || !ws.A1 || !ws.B1 || !ws.C1) {
        setErrorMessage(
          intl.formatMessage({
            id: "carts.orders.Dữ liệu tải lên không đúng định dạng, vui lòng kiểm tra lại!",
            defaultMessage:
              "Dữ liệu tải lên không đúng định dạng, vui lòng kiểm tra lại!",
          }),
        );
        setCurrentForm(4);
        return;
      }

      if (ws.A1) ws.A1.w = "location";
      if (ws.B1) ws.B1.w = "code";
      if (ws.C1) ws.C1.w = "quantity";

      /* Convert array of arrays */
      const dataSheetResult = XLSX.utils.sheet_to_json(ws, {
        raw: true,
        rawNumbers: true,
        header: 2,
      });

      map(dataSheetResult, (row) => {
        const id = uuid();
        row.messageError = "";
        row.error = false;
        row.id = id;
        row.code = trim(row.code);
      });

      if (dataSheetResult.length > 200) {
        setErrorMessage(
          intl.formatMessage({
            id: "carts.orders.Chỉ cho phép tải lên tối đa 200 dòng sản phẩm, vui lòng kiểm tra lại!",
            defaultMessage:
              "Chỉ cho phép tải lên tối đa 200 dòng sản phẩm, vui lòng kiểm tra lại!",
          }),
        );
        setCurrentForm(4);
        return;
      }

      setCurrentForm(2);
      setDataSheet(dataSheetResult);

      const { productListSuccess, productListError } =
        await checkProductInWorkSheet(dataSheetResult);
      if (productListSuccess && productListSuccess.length) {
        const listProducts = concat(dataSuccess, productListSuccess);
        setDataSuccess(listProducts);
      }
      if (productListError && productListError.length) {
        setDataError(productListError);
        setCurrentForm(3);
      } else {
        addOrderLine(productListSuccess);
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }

  const onChooseFiles = () => {
    inputRef.current.click();
  };

  const body = () => (
    <div>
      <div style={{ lineHeight: "2rem" }} className="content-primary">
        <FormattedMessage
          id="carts.orders.Chức năng này hỗ trợ bằng cách nhập dữ liệu từ file excel. Tải về file <a>excel mẫu</a>."
          defaultMessage="Chức năng này hỗ trợ bằng cách nhập dữ liệu từ file excel. Tải về file <a>excel mẫu</a>."
          values={{
            a: (content) => (
              <a
                download
                href="https://hstatic.net/0/0/global/template_import_cart.xlsx?v=1"
              >
                {content}
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
  const footer = () => (
    <div className="d-flex justify-content-between align-items-center">
      <label style={{ height: 0 }}>
        <input
          ref={inputRef}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          hidden
          onChange={(e) => handleFileWithXlsx(e?.target?.files)}
          type="file"
        />
      </label>
      <div>
        <Button
          size="md"
          light
          status="default"
          badge="ESC"
          onClick={() => {
            setShow(false);
            enableScope(HOTKEY_SCOPES.CART);
          }}
          className="mr-3"
          title="Đóng"
        />
        <Button
          size="md"
          status="primary"
          badge="F8"
          onClick={() => inputRef.current.click()}
          title={intl.formatMessage({
            id: "carts.orders.Chọn file",
            defaultMessage: "Chọn file",
          })}
        />
      </div>
    </div>
  );
  return (
    <GlobalModal
      size="lg"
      headerTitle={intl.formatMessage({
        id: "carts.orders.Nhập sản phẩm hàng loạt vào giỏ hàng",
        defaultMessage: "Nhập sản phẩm hàng loạt vào giỏ hàng",
      })}
      body={body()}
      footer={footer()}
      show={show}
      setShow={setShow}
    />
  );
}
