import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import * as HrvComponents from "@haravan/react-components";
import { getDecimalValue } from "../../../utils/Commons";
import { debounce, isEmpty } from "lodash";
import { removeDiacritics } from "utils/Commons";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DateTimeShowType,
  toFormattedDate,
} from "infrastructures/format-helpers";
import NumberShow from "component-v2/number-show";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { InputDecimal } from "component-v2/Input";
import { useApp } from "contexts/app";

function SelectLotDateModal({
  lotsSelected,
  handleOnSave,
  onCloseModal,
  isShowModal,
  handleModalAction,
  lotsData,
  isRefundMode = false,
}) {
  const { shop_info } = useApp();
  const intl = useIntl();
  const didMount = useRef(false);
  const [lots, setLots] = useState([]);
  const [lotsRender, setLotsRender] = useState([]);
  const [selectedLots, setSelectedLots] = useState(lotsSelected || []);
  const [textSearch, setTextSearch] = useState("");
  const totalQty = useMemo(
    () => selectedLots.reduce((total, lot) => total + lot.quantity, 0),
    [selectedLots],
  );

  useEffect(() => {
    if (lotsSelected) {
      setSelectedLots(lotsSelected);
    }
  }, [lotsSelected]);

  const mapLotSelected = (_selectedLots, _lots) => {
    const data = _lots.map((item) => {
      const selectedLot = _selectedLots.find((lot) => lot.id === item.id);
      return {
        ...item,
        isSelected: !!selectedLot,
        qtyInput: selectedLot ? selectedLot.quantity : 0,
      };
    });
    return data;
  };

  const getLots = async () => {
    const data = lotsData || [];
    const mappedData = mapLotSelected(selectedLots, data);
    setLots(mappedData);
    setLotsRender(mappedData);
  };

  useEffect(() => {
    isShowModal && getLots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowModal]);

  useEffect(() => {
    if (didMount.current) {
      const mappedData = mapLotSelected(selectedLots, lotsRender);
      setLotsRender(mappedData);
    } else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLots]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchLotDate = useCallback(
    debounce((text) => {
      setTextSearch(text);
      const _textSearch = removeDiacritics(text);
      const _dataFilterd = lots.filter((lot) =>
        removeDiacritics(lot.lotNo).includes(_textSearch),
      );
      const mappedData = mapLotSelected(selectedLots, _dataFilterd);
      setLotsRender(mappedData);
    }, 300),
    [lots, selectedLots],
  );

  const onChangeLotSelected = (item, isSelect) => {
    if (isSelect) {
      setSelectedLots((lots) => [
        ...lots,
        { ...item, isSelected: true, quantity: 0 },
      ]);
    } else {
      setSelectedLots((lots) => lots.filter((lot) => lot.id !== item.id));
    }
  };

  const onChangeQuantity = (item, event) => {
    const index = selectedLots.findIndex((lot) => lot.id === item.id);
    if (index !== -1) {
      const _items = {
        ...selectedLots[index],
        quantity: getDecimalValue(shop_info, event.target.value),
      };
      const _selectedLots = [...selectedLots];
      _selectedLots.splice(index, 1, _items);
      setSelectedLots(_selectedLots);
    }
  };

  const body = () => {
    return (
      <Fragment>
        <div className="hrv-form-group">
          <HrvComponents.InputHasIcon
            placeholder={intl.formatMessage({
              id: "carts.orders.Tìm lô",
              defaultMessage: "Tìm lô",
            })}
            prefix={<HrvComponents.Icon type="search" />}
            suffix={
              !isEmpty(textSearch) ? (
                <HrvComponents.Icon
                  type="times"
                  size={12}
                  onClick={() => onSearchLotDate("")}
                  className="cursor-pointer"
                />
              ) : null
            }
            value={textSearch}
            onChange={(value) => onSearchLotDate(value)}
          />
        </div>
        <div>
          {intl.formatMessage({
            id: "carts.orders.Số lượng",
            defaultMessage: "Số lượng",
          })}
          {": "}
          <NumberShow value={totalQty} />
        </div>
        <div className="lotdate-list-wrapper pt-3">
          {isEmpty(lotsRender) ? (
            <div className="text-secondary">
              {intl.formatMessage({
                id: "carts.orders.Không tìm thấy lô còn hàng!",
                defaultMessage: "Không tìm thấy lô còn hàng!",
              })}
            </div>
          ) : (
            lotsRender.map((lot, index) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-between mb-3"
                  key={lot.id}
                >
                  <HrvComponents.InputCheckbox
                    className="flex-basis-50"
                    onChange={(check) => onChangeLotSelected(lot, check)}
                    checked={lot.isSelected}
                  >
                    <FormattedMessage
                      id="carts.orders.Lô {a} · {b}"
                      defaultMessage="Lô {a} · {b}"
                      values={{
                        a: <span>{lot.lotNo}</span>,
                        b: (
                          <span>
                            {toFormattedDate(
                              lot.lotExpireDate,
                              DateTimeShowType.date,
                            )}
                          </span>
                        ),
                      }}
                    />
                  </HrvComponents.InputCheckbox>
                  <div className="d-flex align-items-center">
                    <div>
                      <InputDecimal
                        className="quantity_cart_item hrv-next-input w-100 text-right"
                        min={0}
                        max={isRefundMode ? lot.quantity : 99999}
                        value={lot.qtyInput || 0}
                        onChange={(e) => onChangeQuantity(lot, e)}
                        onFocus={(e) => e?.currentTarget.select()}
                        isDisabled={!lot.isSelected}
                      />
                    </div>
                    <span className="ml-3 mb-0 mr-1">{"/ "}</span>
                    <NumberShow value={lot.quantity} />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Fragment>
    );
  };

  return (
    <GlobalModal
      headerTitle={intl.formatMessage({
        id: "carts.orders.Chọn lô, hạn sử dụng",
        defaultMessage: "Chọn lô, hạn sử dụng",
      })}
      body={body()}
      show={isShowModal}
      setShow={(value) => handleModalAction(value)}
      confirmTitle={intl.formatMessage({
        id: "carts.orders.Xong",
        defaultMessage: "Xong",
      })}
      onCancel={() => {
        onCloseModal();
      }}
      onConfirm={() => {
        handleOnSave?.(selectedLots);
      }}
    />
  );
}

export default SelectLotDateModal;
