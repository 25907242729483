import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useShift } from "contexts/shift/shift-context";
import React from "react";
import { TeleportElementHeader } from "utils/TeleportElementHeader";

export default function ShiftHeader() {
  const { shop_setting } = useApp();
  const { shift, closeShift, openShift } = useShift();

  if (!shop_setting) return null;
  return (
    <TeleportElementHeader>
      <div className="carts-header">
        <div className="carts-tabs"></div>
        {shop_setting.shiftEnable && shift ? (
          <div className="carts-extra">
            {shift.status !== "empty" ? (
              <Button
                title="Đóng ca"
                size="sm"
                light
                rounded
                icon="power_setting_new"
                className="l-ml-16"
                onClick={() => closeShift()}
              />
            ) : (
              <Button
                title="Mở ca"
                status="primary"
                size="sm"
                rounded
                icon="power_setting_new"
                className="l-ml-16"
                onClick={() => openShift()}
              />
            )}
          </div>
        ) : null}
      </div>
    </TeleportElementHeader>
  );
}
