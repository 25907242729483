import { Textarea } from "@haravan/react-components";
import {
  getDefaultHTMLPrintTemplate,
  getHTMLTemplateTypes,
  testHTMLPrintTemplate,
} from "api/settings-repository";
import { Assets } from "assets";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useEffect, useState } from "react";
import { useLayoutContext } from "contexts/Layout/layout-context";
import "./style.css";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEY_EVENTS, HOTKEY_SCOPES, HOTKEYS } from "models/constants";
import { useHotkeysContext } from "react-hotkeys-hook";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";
const EditTemplate = ({
  initTemplate,
  template,
  setTemplate,
  selectedTemplateId,
  setSelectedTemplateId,
}) => {
  const { enableScope, disableScope } = useHotkeysContext();
  const {
    shop_setting: { shopLogoUrl, shopImageUrl },
    callAPI,
  } = useApp();
  const { showGlobalToast } = useLayoutContext();
  const [previewMode, setPreviewMode] = useState(false);
  const [templateTypes, setTemplateTypes] = useState([]);
  const [previewTemplate, setPreviewTemplate] = useState();
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [tempTypeId, setTempTypeId] = useState();
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC && expandView) {
        setExpandView(false);
        disableScope(HOTKEY_SCOPES.MODAL);
      }
    },
    enabled: expandView,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  useEffect(() => {
    getHTMLTemplateTypes(callAPI).then((res) => {
      if (!!res?.data.length && !res.error) {
        setTemplateTypes(res.data);
        setSelectedTemplateId(res.data[0]?.id);
      }
    });
  }, []);

  const restoreDefaultTemplate = (templateId) => {
    getDefaultHTMLPrintTemplate(callAPI, templateId).then((res) => {
      if (!!res?.data && !res?.error) {
        setTemplate(res.data?.templateHTML);
      }
      if (res?.error) {
        showGlobalToast("error", res?.message || "Có gì đó không ổn");
      }
    });
  };
  const previewHTMLTemplate = async () => {
    if (previewMode) {
      setPreviewMode(false);
      return;
    }
    testHTMLPrintTemplate(callAPI, {
      template_html: template,
      shop_logo_url: shopLogoUrl || Assets.icon.hrv_logo_print,
      shop_image_url: shopImageUrl || Assets.icon.hrv_logo_print,
      redeem_name: "Chương trình khách hàng thân thiết",
      redeem_used_amount: 1000,
      redeem_discount: 10,
      redeem_discount_type: "percent",
      note_attributes: [
        {
          name: "Tiền cần trả thêm",
          value: 100000,
        },
        {
          name: "Tiền khách đưa",
          value: 15000000,
        },
        {
          name: "Tiền thừa trả khách",
          value: 100000,
        },
      ],
      amount_refunded: 0,
      transactions: [
        {
          gateway: "Tiền mặt",
          transaction_amount: 14000000,
          type: "paid",
          transaction_name: "Xác nhận thanh toán",
          payment_method_name: "Tiền mặt",
          amount_transaction: 14000000,
        },
      ],
      order_notes: "Ghi chú đơn hàng",
      order_products: [
        {
          product_name: "Smart Tivi LED Samsung 40 inch UA40J5100AKXXV",
          variant_value: "Đen",
          sku: "SKU02249025",
          barcode: "BC999912",
          unit: "Cái",
          quantity: 1,
          original_price: 15000000,
          product_price: 11000000,
          total_price: 11000000,
          discount_allocations: [{ name: "LINEITEMS", value: 10000 }],
          properties: [],
          restock_quantity: 0,
          returned_quantity: 0,
        },
      ],
      discount_type: "percent",
      discount_percent: 10,
      refund_order: {
        order_products: [
          {
            product_name: "Smart Tivi LED Samsung 40 inch UA40J5100AKXXV",
            variant_value: "Đen",
            sku: "SKU02249025",
            barcode: "BC999912",
            unit: "Cái",
            quantity: 3,
            original_price: 15000000,
            product_price: 11000000,
            total_price: 11000000,
            discount_allocations: [{ name: "LINEITEMS", value: 10000 }],
            properties: [],
            restock_quantity: 0,
            returned_quantity: 0,
            product_refunded_quantity: 3,
            refund_number: 3,
          },
        ],
        transactions: [
          {
            gateway: "Tiền mặt",
            transaction_amount: 14000000,
            type: "paid",
            transaction_name: "Xác nhận thanh toán",
            payment_method_name: "Tiền mặt",
            amount_transaction: 14000000,
          },
        ],
        total_refunded: 15000000,
        order_number: "DH00000",
        is_new_order: true,
        refund_total_quantity: 2,
      },
      refund_order_total: 15000000,
      enabled_refund_point: true,
      reward_redeem_amount: 10,
      not_allow_refund_if_lower_price: false,
    }).then((res) => {
      if (!!res?.data && !res?.error) {
        setPreviewTemplate(res.data?.data);
        setPreviewMode(true);
      }
      if (res?.error) {
        showGlobalToast("error", res?.message || "Có gì đó không ổn");
      }
    });
  };
  return (
    <LayoutCard
      className={`l-mt-8 flex-fill edit-template-container ${
        expandView ? "full" : ""
      }`}
    >
      <div className={`app-container l-segment p-0 h-100`}>
        <div id="printOrder" className="printorder pd-20 flex-column h-100">
          <div className="d-flex justify-content-between align-items-center mb-16">
            <div className="d-flex align-items-center">
              {!previewMode && (
                <>
                  <span className="content-primary font-weight-bold l-mr-4">
                    Mẫu in:
                  </span>
                  <SelectionCustom
                    width={180}
                    options={templateTypes}
                    idField={"id"}
                    valueField={"templateName"}
                    className="template-select"
                    placeholder="Chọn mẫu in"
                    value={selectedTemplateId}
                    onChange={(value) => {
                      if (initTemplate !== template) {
                        setTempTypeId(value);
                        setShowBlockModal(true);
                      } else {
                        setSelectedTemplateId(value);
                      }
                    }}
                  >
                    {templateTypes?.map((item) => (
                      <SelectionCustom.Option value={item.id} key={item.id}>
                        {item.templateName}
                      </SelectionCustom.Option>
                    ))}
                  </SelectionCustom>
                </>
              )}
            </div>
            <div className="d-flex">
              {!previewMode && (
                <Button
                  light
                  className="l-mr-8"
                  title="Khôi phục mặc định"
                  onClick={() => restoreDefaultTemplate(selectedTemplateId)}
                />
              )}
              <Button
                light
                className="l-mr-8"
                title={previewMode ? "Quay lại" : "Xem trước"}
                onClick={() => previewHTMLTemplate()}
              />
              <Button
                tooltipTitle={expandView ? "Thu nhỏ" : "Phóng to"}
                light
                icon={"screenshot"}
                onClick={() => {
                  expandView
                    ? disableScope(HOTKEY_SCOPES.MODAL)
                    : enableScope(HOTKEY_SCOPES.MODAL);
                  setExpandView((prev) => !prev);
                }}
              ></Button>
            </div>
          </div>
          <Textarea
            spellCheck="false"
            value={template}
            onChange={(value) => setTemplate(value)}
            className={`code-textarea ${previewMode ? "hidden" : ""}`}
          />
          <GlobalModal
            headerTitle={"Hủy tất cả các thay đổi chưa lưu"}
            body={
              <div className="py-4">
                <span>
                  Nếu hủy thì mọi chỉnh sửa đã thực hiện kể từ lần lưu cuối sẽ
                  bị xóa.
                </span>
              </div>
            }
            footer={
              <div>
                <Button
                  light
                  title={"Ở lại"}
                  className="mr-3"
                  onClick={() => setShowBlockModal(false)}
                />
                <Button
                  status="danger"
                  title={"Hủy thay đổi"}
                  className=""
                  onClick={() => {
                    setSelectedTemplateId(tempTypeId);
                    setShowBlockModal(false);
                  }}
                />
              </div>
            }
            show={showBlockModal}
            setShow={setShowBlockModal}
          />
          {previewMode && (
            <div
              className={`${expandView ? "overflow-auto" : ""}`}
              dangerouslySetInnerHTML={{ __html: previewTemplate }}
            ></div>
          )}
        </div>
      </div>
    </LayoutCard>
  );
};

export default EditTemplate;
