import { Input, Textarea } from "@haravan/react-components";
import {
  CreateCashFlowReason,
  UpdateCashFlowReason,
} from "api/payment-voucher-api";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { isNil } from "lodash";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { RecordReportCheckbox } from "./record-report-checkbox";

const default_data = {
  id: null,
  name: "",
  description: "",
  pnL: false,
};

const ModalAddNewReason = ({
  isOpen,
  closeModal,
  handleCallApiSuccess,
  isEditModal,
  editData,
  isCashReceipt,
}) => {
  const appContext = useApp();
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(editData ? editData : default_data);
  const type = useMemo(() => {
    return isCashReceipt ? "thu" : "chi";
  }, [isCashReceipt]);
  useEffect(() => {
    if (isEditModal) setFormData(editData);
  }, [isEditModal, editData]);

  const validateData = (data) => {
    if (isNil(data.name)) {
      let message = "Tên lý do không được bỏ trống";
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      return true;
    }
    return false;
  };

  const handleOnChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearForm = () => {
    setFormData(default_data);
  };

  const handleCancel = () => {
    closeModal();
    clearForm();
  };

  const handleConfirm = async () => {
    let message = isEditModal
      ? `Cập nhật lý do ${type} thành công`
      : `Tạo lý do ${type} thành công`;
    setIsLoading(true);
    if (validateData(formData)) {
      setIsLoading(false);
      return;
    }
    const typeApi = isCashReceipt ? "cash_receipt" : "cash_payment";

    const res = isEditModal
      ? await UpdateCashFlowReason(appContext, formData, typeApi)
      : await CreateCashFlowReason(appContext, formData, typeApi);
    setIsLoading(false);
    if (res?.data) {
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      handleCallApiSuccess(formData.id);
      setTimeout(() => {
        handleCancel();
        clearForm();
      }, 400);
    } else if (res?.errors?.length) {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: `notifications.${res?.errors[0]}`,
          defaultMessage: res?.errors[0],
        }),
      );
    }
  };
  let message = isEditModal
    ? `Cập nhật lý do ${type}`
    : `Tạo mới lý do ${type}`;
  const body = () => (
    <>
      <div className="mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: `payment-vouchers.Lý do ${type}`,
            defaultMessage: `Lý do ${type}`,
          })}
        </label>
        <Input
          value={formData?.name || ""}
          onChange={(value) => handleOnChange("name", value)}
          type="text"
          className="text-black"
          placeholder={intl.formatMessage({
            id: `payment-vouchers.Nhập lý do ${type}`,
            defaultMessage: `Nhập lý do ${type}`,
          })}
          autoFocus={true}
        />
      </div>
      <div className="mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "payment-vouchers.Mô tả",
            defaultMessage: "Mô tả",
          })}
        </label>
        <Textarea
          rows={3}
          value={formData?.description || ""}
          onChange={(value) => handleOnChange("description", value)}
          className="text-black"
          placeholder={intl.formatMessage({
            id: "payment-vouchers.Nhập mô tả",
            defaultMessage: "Nhập mô tả",
          })}
        />
      </div>
      <RecordReportCheckbox
        isCashReceipt={isCashReceipt}
        checked={formData.pnL}
        handleOnChange={(check) => {
          handleOnChange("pnL", check);
        }}
      />
    </>
  );

  return (
    <GlobalModal
      size="lg"
      cancelTitle="Hủy bỏ"
      confirmTitle="Lưu"
      headerTitle={intl.formatMessage({
        id: `payment-vouchers.${message}`,
        defaultMessage: message,
      })}
      body={body()}
      show={isOpen}
      setShow={(value) => !value && closeModal()}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      loading={isLoading}
    />
  );
};

export default ModalAddNewReason;
