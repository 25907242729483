import React, { Component, Fragment } from "react";
import "./style.css";
import JsBarcode from "jsbarcode";
import { Assets } from "assets";
import { QRCodeSVG } from "qrcode.react";
import { FormattedMessage } from "react-intl";
export default class PrintTemplate extends Component {
  componentDidMount() {
    const { setting } = this.props;
    if (setting.barcode) {
      this.renderBarcode("DH00001");
    }
  }

  renderBarcode = (orderNumber) => {
    try {
      const barcodeTag = document.getElementById("barcode");
      if (barcodeTag) {
        barcodeTag.innerHTML = "";
      }

      JsBarcode("#barcode", orderNumber, {
        format: "code128",
        displayValue: false,
        height: 50,
      });
    } catch (e) {}
  };

  componentDidUpdate() {
    const { setting } = this.props;
    if (setting.barcode) {
      this.renderBarcode("DH00001");
    }
  }

  render() {
    const { intl } = this.props;
    const { imgUrl, shopImageUrl, setting } = this.props;
    return (
      <Fragment>
        <div id="printOrder" className="printorder pd-20">
          <div className="printorder">
            {setting.showLogo && (
              <div className="printtable m-b-5">
                <div className="printrow noborder">
                  <div
                    className="printcol"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <img
                      style={{ width: "180px", display: "inline-block" }}
                      src={imgUrl || Assets.icon.hrv_logo_print}
                      alt="no-data"
                    />
                  </div>
                </div>
              </div>
            )}
            {setting.showLogo && (
              <div className="m-b-5" style={{ marginTop: "0px" }}>
                <p
                  style={{
                    borderBottom: "1px dashed #000000",
                    height: "1px",
                    margin: "0",
                  }}
                />
              </div>
            )}

            <div className="printtable">
              <div className="printrow noborder">
                <div className="printrow">
                  <div className="printcol last" style={{ width: "100%" }}>
                    {setting.showLocation && (
                      <p>
                        {intl.formatMessage({
                          id: "carts.orders.Chi nhánh",
                          defaultMessage: "Chi nhánh",
                        })}
                        : Kho trung tâm
                      </p>
                    )}
                    {setting.shopAddress && (
                      <p>
                        {intl.formatMessage({
                          id: "settings.print-templates.Địa chỉ",
                          defaultMessage: "Địa chỉ",
                        })}
                        : 182 Lê Đại Hành, Phường 15, Quận 11, Tp. Hồ Chí Minh
                      </p>
                    )}
                    {setting.shopPhone && (
                      <p>
                        {intl.formatMessage({
                          id: "settings.print-templates.SĐT",
                          defaultMessage: "SĐT",
                        })}
                        : 0912345678
                      </p>
                    )}
                    {setting.shopWebsite && (
                      <p>Website: {setting.websiteUrl}</p>
                    )}
                    {setting.shopEmail && <p>Email: name@shop.com</p>}
                    {setting.nameSalesMan && (
                      <p>
                        {intl.formatMessage({
                          id: "settings.print-templates.Nhân viên",
                          defaultMessage: "Nhân viên",
                        })}
                        : Nhân viên 1
                      </p>
                    )}
                    {setting.billDay && (
                      <p>
                        {intl.formatMessage({
                          id: "settings.print-templates.Ngày bán",
                          defaultMessage: "Ngày bán",
                        })}
                        : 01/01/2019
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="printtable m-t-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {setting.orderNumber && (
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: "var(--font-weight-semibold)",
                  }}
                >
                  <span className="text-uppercase">Hóa đơn</span>: DH00001
                </span>
              )}
            </div>
            {setting.barcode && (
              <div className="printtable">
                <div className="printrow noborder">
                  <div
                    className="printcol barcode-wrapper"
                    style={{ width: "100%" }}
                  >
                    <svg id="barcode" />
                  </div>
                </div>
              </div>
            )}

            <div className="printtable">
              <div className="printrow noborder">
                <div className="printrow">
                  <div className="printcol last" style={{ width: "100%" }}>
                    {setting.customerName && (
                      <p>
                        <strong>
                          {intl.formatMessage({
                            id: "settings.print-templates.KH",
                            defaultMessage: "KH",
                          })}
                          :{" "}
                        </strong>{" "}
                        Nguyễn Văn A
                      </p>
                    )}
                    {setting.customerAdress && (
                      <p>
                        <strong>
                          {intl.formatMessage({
                            id: "settings.print-templates.Địa chỉ",
                            defaultMessage: "Địa chỉ",
                          })}
                          :{" "}
                        </strong>
                        56, Vân Côi, Phường 7
                      </p>
                    )}
                    {setting.customerPhone && (
                      <p>
                        <strong>
                          {intl.formatMessage({
                            id: "settings.print-templates.SĐT",
                            defaultMessage: "SĐT",
                          })}
                          :{" "}
                        </strong>
                        0987654321
                      </p>
                    )}
                    {setting.customerEmail && (
                      <p>
                        <strong>Email: </strong>{" "}
                        <span> a.nguyenvan@haravan.com</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="printtable" style={{ marginTop: "5px" }}>
              <div className="printrow noborder border-title">
                <div
                  className="printcol printcolheader"
                  style={{ width: "44%", textAlign: "left" }}
                >
                  {intl.formatMessage({
                    id: "settings.print-templates.Đgiá/Ggiảm",
                    defaultMessage: "Đgiá/Ggiảm",
                  })}
                </div>
                <div
                  className="printcol printcolheader"
                  style={{ width: "10%" }}
                >
                  {intl.formatMessage({
                    id: "settings.print-templates.SL",
                    defaultMessage: "SL",
                  })}
                </div>
                <div
                  className="printcol printcolheader number"
                  style={{ width: "45%" }}
                >
                  {intl.formatMessage({
                    id: "settings.print-templates.T.Tiền",
                    defaultMessage: "T.Tiền",
                  })}
                </div>
              </div>
              <div className="printrow noborder mg-b--5">
                <div
                  className="printrow noborder mg-b--5 print-80mm"
                  style={{ width: "100%" }}
                >
                  <div className="printcol">
                    <span>
                      Smart Tivi LED Samsung 40 inch UA40J5100AKXXV
                      {setting.isVariant && ` - Đen `}
                      {setting.skuCode && ` - SKU02249025 `}{" "}
                      {setting.barcodeVariant && `- BC999912`}
                    </span>
                  </div>
                </div>
                <div>
                  {setting.productNote ? (
                    <div
                      className="printrow noborder mg-b--5"
                      style={{ width: "100%" }}
                    >
                      <p>
                        {intl.formatMessage({
                          id: "settings.print-templates.Ghi chú sản phẩm",
                          defaultMessage: "Ghi chú sản phẩm",
                        })}
                      </p>
                    </div>
                  ) : null}
                  <div className="printrow noborder mg-b--5">
                    <div
                      className="printcol"
                      style={{ width: "44%", textAlign: "left" }}
                    >
                      <del>8,990,000</del>
                      <p>5,000,000</p>
                    </div>
                    <div className="printcol" style={{ width: "10%" }}>
                      <span>3</span>
                    </div>
                    <div className="printcol number" style={{ width: "45%" }}>
                      <span>15,000,000</span>
                    </div>
                  </div>
                </div>
                <div
                  className="printrow noborder text-align-center"
                  style={{ width: "100%" }}
                ></div>
              </div>
            </div>
            <div className="m-b-5" style={{ marginTop: "0px" }}>
              <p
                style={{
                  borderBottom: "1px dashed #000000",
                  height: "1px",
                }}
              />
            </div>
            <div className="table-print">
              <table style={{ float: "right", marginBottom: "5px" }}>
                <tbody>
                  {setting.totalQuantity && (
                    <tr>
                      <td className="text-right p-r-20">
                        {intl.formatMessage({
                          id: "settings.print-templates.Tổng số lượng",
                          defaultMessage: "Tổng số lượng",
                        })}
                        :
                      </td>
                      <td className="text-right">3</td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-right p-r-20">
                      {intl.formatMessage({
                        id: "settings.print-templates.Tổng tiền",
                        defaultMessage: "Tổng tiền",
                      })}
                      :
                    </td>
                    <td className="text-right">15,000,000</td>
                  </tr>
                  {setting.shipPrice && (
                    <tr>
                      <td className="text-right p-r-20">
                        {intl.formatMessage({
                          id: "settings.print-templates.Phí vận chuyển",
                          defaultMessage: "Phí vận chuyển",
                        })}
                        :
                      </td>
                      <td className="text-right">200,000</td>
                    </tr>
                  )}
                  {setting.discount && (
                    <Fragment>
                      <tr>
                        <td className="text-right p-r-20">
                          {intl.formatMessage({
                            id: "settings.print-templates.Khuyến mãi",
                            defaultMessage: "Khuyến mãi",
                          })}
                          :
                        </td>
                        <td className="text-right">100,000</td>
                      </tr>
                      <tr>
                        <td className="text-right p-r-20">
                          {intl.formatMessage({
                            id: "settings.print-templates.Khuyến mãi",
                            defaultMessage: "Khuyến mãi",
                          })}
                          (0%):
                        </td>
                        <td className="text-right">0</td>
                      </tr>
                      <tr>
                        <td className="text-right p-r-20">
                          {intl.formatMessage({
                            id: "settings.print-templates.Mã KM",
                            defaultMessage: "Mã KM",
                          })}
                          :
                        </td>
                        <td className="text-right">KHUYENMAI</td>
                      </tr>
                    </Fragment>
                  )}
                  <tr>
                    <td className="text-right p-r-20 printcolheader">
                      {intl.formatMessage({
                        id: "settings.print-templates.Thành tiền",
                        defaultMessage: "Thành tiền",
                      })}
                      :
                    </td>
                    <td className="text-right">15,100,000</td>
                  </tr>
                  {setting.totalToPay ? (
                    <tr>
                      <td className="text-right p-r-20">
                        {intl.formatMessage({
                          id: "settings.print-templates.Tiền phải thanh toán",
                          defaultMessage: "Tiền phải thanh toán",
                        })}
                        :
                      </td>
                      <td className="text-right">15,100,000</td>
                    </tr>
                  ) : null}
                  {setting.totalPaid ? (
                    <tr>
                      <td className="text-right p-r-20">
                        {intl.formatMessage({
                          id: "settings.print-templates.Tiền đã thanh toán",
                          defaultMessage: "Tiền đã thanh toán",
                        })}
                        :
                      </td>
                      <td className="text-right">15,100,000</td>
                    </tr>
                  ) : null}
                  {setting.customerPayingAmt && (
                    <tr>
                      <td className="text-right p-r-20 printcolheader">
                        {intl.formatMessage({
                          id: "settings.print-templates.Tiền khách đưa",
                          defaultMessage: "Tiền khách đưa",
                        })}
                        :
                      </td>
                      <td className="text-right">15,100,000</td>
                    </tr>
                  )}
                  {setting.paymentGateway && (
                    <Fragment>
                      <tr>
                        <td className="text-right p-r-20">
                          {intl.formatMessage({
                            id: "carts.orders.Tiền mặt",
                            defaultMessage: "Tiền mặt",
                          })}
                          :
                        </td>
                        <td className="text-right">100,000</td>
                      </tr>
                      <tr>
                        <td className="text-right p-r-20">
                          {intl.formatMessage({
                            id: "carts.orders.Chuyển khoản",
                            defaultMessage: "Chuyển khoản",
                          })}
                          :
                        </td>
                        <td className="text-right">15,000,000</td>
                      </tr>
                    </Fragment>
                  )}
                  {setting.cashPayCustomer && (
                    <tr>
                      <td className="text-right p-r-20 printcolheader">
                        {intl.formatMessage({
                          id: "settings.print-templates.Tiền thừa trả khách",
                          defaultMessage: "Tiền thừa trả khách",
                        })}
                        :
                      </td>
                      <td className="text-right">0</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {setting.note && (
              <div className="printtable" style={{ marginTop: "5px" }}>
                <div className="printrow noborder" style={{ fontSize: "13px" }}>
                  <i>
                    {intl.formatMessage({
                      id: "settings.print-templates.Ghi chú",
                      defaultMessage: "Ghi chú",
                    })}
                    :{" "}
                    {intl.formatMessage({
                      id: "settings.print-templates.Ghi chú đơn hàng!",
                      defaultMessage: "Ghi chú đơn hàng!",
                    })}
                  </i>
                </div>
              </div>
            )}
            {setting.showImage && (
              <div className="printtable m-b-5">
                <div className="printrow noborder">
                  <div
                    className="printcol"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <img
                      style={{
                        maxWidth: "500px",
                        minWidth: "50px",
                        display: "inline-block",
                      }}
                      src={shopImageUrl || Assets.icon.hrv_logo_print}
                      alt="no-data"
                    />
                  </div>
                </div>
              </div>
            )}
            {this.props.shop_loyalty?.enabled &&
              setting.showLevelNameLoyalty && (
                <div className="m-b-5" style={{ marginTop: "5px" }}>
                  <p
                    style={{
                      borderBottom: "1px dashed #000000",
                      height: "1px",
                    }}
                  />
                  <p
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {intl.formatMessage({
                      id: "settings.print-templates.Hạng thành viên",
                      defaultMessage: "Hạng thành viên",
                    })}
                    : <strong>Hạng Bạc</strong> -
                    {intl.formatMessage({
                      id: "settings.print-templates.Điểm thưởng hiện có",
                      defaultMessage: "Điểm thưởng hiện có",
                    })}
                    : <strong>1230</strong> -
                    {intl.formatMessage({
                      id: "settings.print-templates.Điểm tích được cho đơn hàng này",
                      defaultMessage: "Điểm tích được cho đơn hàng này",
                    })}
                    : <strong>1230</strong>
                  </p>
                </div>
              )}
            {setting.showEInvoiceBarcode && !setting.showEInvoiceInfo && (
              <div className="m-b-5">
                <div
                  style={{
                    borderBottom: "1px dashed #000000",
                    height: "1px",
                  }}
                />
                <div style={{ marginTop: "10px" }} className="text-center">
                  <h4 className="m-t-5 m-b-5 font-weight-bold">
                    THÔNG TIN HOÁ ĐƠN VAT
                  </h4>
                  <p className="m-t-5 m-b-5">Quét mã để xem chi tiết hoá đơn</p>
                  <QRCodeSVG
                    value={window.location.origin}
                    className="m-t-5"
                    size={68}
                  />
                </div>
              </div>
            )}
            {setting.showEInvoiceInfo && (
              <div className="m-b-5">
                <div
                  style={{
                    borderBottom: "1px dashed #000000",
                    height: "1px",
                  }}
                />
                <div style={{ marginTop: "10px" }}>
                  <h4 className="m-t-5 m-b-5 font-weight-bold">
                    THÔNG TIN HOÁ ĐƠN VAT
                  </h4>
                  <div className="d-flex m-t-5">
                    {setting.showEInvoiceBarcode && (
                      <div className="m-r-15">
                        <QRCodeSVG
                          value={window.location.origin}
                          className="m-t-5"
                          size={68}
                        />
                      </div>
                    )}
                    <div>
                      <div className="">
                        <FormattedMessage
                          id="Mã tra cứu hoá đơn: {x}"
                          defaultMessage="Mã tra cứu hoá đơn: {x}"
                          values={{
                            x: <span className="printcolheader">DH00001</span>,
                          }}
                        />
                      </div>
                      <div className="">
                        <FormattedMessage
                          id="Số hoá đơn: {x}"
                          defaultMessage="Số hoá đơn: {x}"
                          values={{
                            x: <span className="printcolheader">00000941</span>,
                          }}
                        />
                      </div>
                      <div className="">
                        <FormattedMessage
                          id="Ký hiệu mẫu hoá đơn: {x}"
                          defaultMessage="Ký hiệu mẫu hoá đơn: {x}"
                          values={{
                            x: <span className="printcolheader">1C22TYY</span>,
                          }}
                        />
                      </div>
                      <div className="">
                        <FormattedMessage
                          id="Mã cơ quan thuế: {x}"
                          defaultMessage="Mã cơ quan thuế: {x}"
                          values={{
                            x: (
                              <span className="printcolheader">
                                00E7A02CE9460D4789804BDA3829B5
                              </span>
                            ),
                          }}
                        />
                      </div>
                      <div className="">
                        <FormattedMessage
                          id="Link tra cứu: {x}"
                          defaultMessage="Link tra cứu: {x}"
                          values={{
                            x: <span>https://tracuuhddt78.hilo.com.vn/</span>,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {setting.showAcknowledgements && (
              <Fragment>
                <div className="m-b-5" style={{ marginTop: "10px" }}>
                  <p
                    style={{
                      borderBottom: "1px dashed #000000",
                      height: "1px",
                    }}
                  />
                </div>
                <div
                  className="printtable text-align-center"
                  style={{ marginTop: "5px" }}
                >
                  <div className="printrow noborder">
                    <div
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        fontSize: "13px",
                      }}
                    >
                      <p
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {setting.acknowledgements}
                      </p>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
