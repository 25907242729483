import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { debounce, startsWith } from "lodash";
import BarcodeReader from "react-barcode-reader";
import { useIntl } from "react-intl";

import { isScaleActive, setScaleActive } from "utils/ScaleUtils";
import { useCartContext } from "screens-v2/carts-v2";
import { ProductSearchInput, ProductSearchResult } from "./components";
import { ProductCartHelperService } from "./product-cart-helper.service";
import { ProductService } from "services";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { useOnClickOutside } from "hooks/useOnClickOutSide";
import Button from "component-v2/buttons";

export const CartSearch = (props) => {
  const appContext = useApp();
  const intl = useIntl();
  const layoutContext = useLayoutContext();
  const cartContext = useCartContext();
  const mode = null;
  const productService = new ProductService();
  const productCartHelper = new ProductCartHelperService(
    appContext.shop_setting
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState(
    productService.getDefaultProductValue()
  );
  const [searchText, setSearchText] = useState("");
  const [isElectronicScale, setIsElectronicScale] = useState(isScaleActive());
  const [isShowResult, setIsShowResult] = useState(false);
  const searchProductRef = useRef(null);
  const this_ref = useRef(null);
  const [mouseOverResult, setMouseOverResult] = useState(false);

  useOnClickOutside(this_ref, () => {
    !mouseOverResult && setIsShowResult(false);
  });

  const ID_FIRST_ITEM_RESULT = "search-product--item-0";

  let keys = [
    HOTKEYS.DOWN,
    HOTKEYS.UP,
    HOTKEYS.ENTER,
    HOTKEYS.F2,
    HOTKEYS.F3,
    HOTKEYS.F9,
    HOTKEYS.F10,
  ];

  useGlobalHotkeys({
    keys: keys,
    scopes: HOTKEY_SCOPES.CART,
    callback: (e) => {
      if (
        e.key === HOTKEYS.F9 &&
        cartContext?.current_shopping_cart?.lineItems?.length
      ) {
        setIsFocused(false);
        setIsShowResult(false);
        searchProductRef.current.blur();
      }

      if (e.key === HOTKEY_EVENTS.F2) {
        searchProductRef.current.focus();
      }
      if (e.key === HOTKEY_EVENTS.F3) {
        setIsShowResult(false);
      }
      if (e.key === HOTKEY_EVENTS.F10) {
        if (appContext.shop_info.scale_support) {
          setStatusES(!isElectronicScale);
          if (isElectronicScale) {
            searchProductRef.current.focus();
          } else {
            searchProductRef.current.blur();
            setIsFocused(false);
            setIsShowResult(false);
          }
        }
      }

      let activeElement = document.querySelector(".selected-active");
      if (activeElement?.id) {
        // di chuyển giữa các items
        if (activeElement.id?.startsWith("search-product--item-")) {
          if (e.key === HOTKEY_EVENTS.DOWN && activeElement.nextElementSibling) {
            activeElement.classList.remove("selected-active");
            activeElement.nextElementSibling.classList.add("selected-active");
            activeElement.nextElementSibling.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
          if (
            e.key === HOTKEY_EVENTS.UP &&
            activeElement?.previousElementSibling
          ) {
            activeElement.classList.remove("selected-active");
            activeElement?.previousElementSibling?.classList.add(
              "selected-active"
            );
            activeElement?.previousElementSibling?.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
          if (e.key === HOTKEY_EVENTS.ENTER) {
            activeElement.click();
          }
        }
      } else {
        let firstElement = document.querySelector(`#${ID_FIRST_ITEM_RESULT}`)
        if (firstElement) {
          firstElement.classList.add(
            "selected-active"
          );
        }
      }
    },
  });

  useEffect(() => {
    if (cartContext.current_shopping_cart) {
      setSearchText(cartContext.current_shopping_cart.searchText);
    }
  }, [cartContext.current_shopping_cart]);

  const setStatusES = (value) => {
    setScaleActive(value);
    setIsElectronicScale(value);
    // show note
    value
      ? layoutContext.showGlobalToast(
        "default",
        intl.formatMessage({
          id: "notifications.Sử dụng mã vạch cân điện tử",
          defaultMessage: "Sử dụng mã vạch cân điện tử",
        })
      )
      : layoutContext.showGlobalToast(
        "default",
        intl.formatMessage({
          id: "notifications.Ngưng sử dụng mã vạch cân điện tử",
          defaultMessage: "Ngưng sử dụng mã vạch cân điện tử",
        })
      );
  };

  const debounceSearch = useCallback(
    debounce(async (e) => {
      setIsLoading(true);
      // call api search product
      let products = await productService.searchProducts(
        appContext,
        mode,
        1,
        10,
        e
      );
      if (products) setProducts(products);
      setIsShowResult(true);
      setIsLoading(false);
      // searchProductRef.current.blur();
      // navigate tới ô search khi đứng ở item đầu tiên
    }, 400),
    []
  );

  // search activity
  const handleInputChanged = (e) => {
    debounceSearch(e.target.value);
    setSearchText(e.target.value);
    cartContext.current_shopping_cart.searchText = e.target.value;
  };

  const handleInputFocus = (event) => {
    event.target.select();
    setIsFocused(true);
    if (searchText.length > 0) {
      setIsShowResult(true);
    }
  };

  const handleScanBarcode = async (barcodeScan) => {
    let isSubmitOrderWaiting = cartContext.isSubmitOrderWaiting; // refactor sau đợi update flag ngoài cart context
    let isBarcodeCustomer =
      startsWith(barcodeScan, "HL") || startsWith(barcodeScan, "hl");

    if (isSubmitOrderWaiting) {
      return layoutContext.showGlobalToast(
        "default",
        intl.formatMessage({
          id: "notifications.Có đơn hàng đang được xử lý. Vui lòng đợi!",
          defaultMessage: "Có đơn hàng đang được xử lý. Vui lòng đợi!",
        })
      );
    }

    let barcode = barcodeScan;
    let lotNo = null;
    if (appContext.shop_setting.isBarcodePatternCustom) {
      try {
        const pattern = appContext.shop_setting.barcodePattern;
        const regex = new RegExp(pattern);
        const match = regex.exec(barcodeScan);
        if (match) {
          barcode = match.groups.barcode;
          lotNo = match.groups.lotNo;
        } else {
          barcode = null;
          lotNo = null;
        }
      } catch (error) {
        console.log(error);
        barcode = null;
        lotNo = null;
      }
    }
    
    if (!barcode || !barcode.length || (appContext.shop_setting.isBarcodePatternCustom && !lotNo?.length)) {
      return;
    }
    
    let isProductBarcode =
      !isBarcodeCustomer && barcode.indexOf(".coupon") === -1;
    if (isProductBarcode) {
      const { records, variantQtt, indexVariant } =
        await productCartHelper.searchOnBarcode(appContext, 1, 10, barcode, lotNo);
      if (records?.length && records.length === 1) {
        handleAddToCart(
          records[0],
          records[0].actualQuantity > 0 ? records[0].actualQuantity : 1
        );
      } else if (records?.length && variantQtt === 1) {
        handleAddToCart(
          records[indexVariant],
          records[indexVariant].actualQuantity > 0
            ? records[indexVariant].actualQuantity
            : 1
        );
      } else {
        await debounceSearch(barcode);
        setSearchText(barcode);
        cartContext.current_shopping_cart.searchText = barcode;
      }
    }
  };

  const onNextPage = async (page) => {
    // call api search product
    setIsLoading(true);
    let products = await productService.searchProducts(
      appContext,
      mode,
      page,
      10,
      searchText
    );
    if (products) setProducts(products);
    setIsLoading(false);
  };

  const handleAddToCart = async (variant, quantity = 1) => {
    setMouseOverResult(false);
    if (!productCartHelper.validateRuleCart(variant)) {
      layoutContext.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "notifications.Sản phẩm đã hết hàng!",
          defaultMessage: "Sản phẩm đã hết hàng!",
        })
      );
      return;
    }
    cartContext.addItemCurrentCart({ ...variant }, quantity);
    setIsFocused(false);
    setIsShowResult(false);
  };

  const handleKeywordClear = () => {
    setSearchText("");
    cartContext.current_shopping_cart.searchText = "";
    searchProductRef.current.focus();
  };

  const enableElectricScale = useMemo(() => {
    return appContext.shop_info.scale_support;
  }, [appContext.shop_info.scale_support]);

  return (
    <div className="d-flex" style={{ height: "4rem" }}>
      <div
        ref={(node) => (this_ref.current = node)}
        className="carts-products w-100"
      >
        <div className="position-relative">
          {/* Search Box */}
          <ProductSearchInput
            disabled={props.disabled}
            isFocused={isFocused}
            keyword={searchText}
            ref={searchProductRef}
            enableElectricScale={appContext.shop_info.scale_support}
            isElectricScale={isElectronicScale}
            onChange={handleInputChanged}
            onFocus={handleInputFocus}
            onESClick={() => {
              setStatusES(!isElectronicScale);
            }}
            onClearSearch={handleKeywordClear}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
          {/* Search result */}

          {isShowResult && searchText.length >= 2 && (
            <ProductSearchResult
              products={products}
              isLoading={isLoading}
              searchText={searchText}
              onNextPage={onNextPage}
              onClick={handleAddToCart}
              setMouseOverResult={setMouseOverResult}
              {...props}
            />
          )}
        </div>

        <BarcodeReader onScan={handleScanBarcode} minLength={3} />
      </div>
      {enableElectricScale ? (
        <Button
          className={"flex-shrink-0 ml-3 h-100"}
          tooltipTitle={
            isElectronicScale
              ? intl.formatMessage({
                id: "carts.orders.Chọn để ngừng sử dụng cân điện tử",
                defaultMessage: "Chọn để ngừng sử dụng cân điện tử",
              })
              : intl.formatMessage({
                id: "carts.orders.Chọn để sử dụng cân điện tử",
                defaultMessage: "Chọn để sử dụng cân điện tử",
              })
          }
          light
          icon="electronic_scale"
          iconRight={true}
          status={`${isElectronicScale ? "primary" : "default"}`}
          badge="F10"
          onClick={(e) => {
            e.target.blur();
            setStatusES(!isElectronicScale);
          }}
          key={"btn_es"}
        />
      ) : null}
    </div>
  );
};

CartSearch.defaultProps = {
  disabled: false,
  isRefundMode: false,
  waitingSubmit: false,
};

CartSearch.propTypes = {
  disabled: PropTypes.bool,
  isRefundMode: PropTypes.bool,
  waitingSubmit: PropTypes.bool,
};
