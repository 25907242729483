import { Fragment, useEffect, useRef, useState } from "react";
import "./style.css";
import * as HrvComponents from "@haravan/react-components";
import PrintTemplate from "component-v2/PrintTemplate/";
import { useIntl } from "react-intl";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  LayoutCard,
  LayoutCardSection,
  LayoutCardHeader,
} from "screens-v2/layouts/layouts/omni-layout-card";
import Button from "component-v2/buttons";
import {
  getHTMLPrintTemplate,
  testHTMLPrintTemplate,
  updateHTMLPrintTemplate,
  updateSettingPrint,
  uploadLogo,
} from "api/settings-repository";
import { useApp } from "contexts/app";
import EditTemplate from "component-v2/PrintTemplate/EditTemplate";
import { Assets } from "assets";
import BlockUnsavedPage from "component-v2/block-unsaved-page";
import { Fea_PrintHTML } from "configs";

const printSettingsDefault = (shop_setting) => {
  const { printSettings, shopLogoUrl, shopImageUrl } = shop_setting;
  return {
    printType: "default",
    autoPrint: printSettings?.autoPrint ?? false,
    allowPrintTempBill: printSettings?.allowPrintTempBill ?? false,
    showLogo: printSettings?.showLogo ?? true,
    showImage: false,
    shopLogoUrl: shopLogoUrl || "",
    shopImageUrl: shopImageUrl || "",
    acknowledgements: printSettings?.acknowledgements ?? "Thank You!",
    showAcknowledgements: printSettings?.showAcknowledgements ?? true,
    showLocation: true,
    barcode: true,
    billDay: true,
    cashPayCustomer: true,
    customerAdress: true,
    customerEmail: true,
    customerName: true,
    customerPhone: true,
    customerPayingAmt: true,
    discount: true,
    isVariant: true,
    nameSalesMan: true,
    note: true,
    orderNumber: true,
    price: true,
    productName: true,
    productType: true,
    quantity: true,
    shipPrice: true,
    shopAddress: true,
    shopEmail: true,
    shopName: true,
    shopPhone: true,
    shopWebsite: printSettings?.shopWebsite ?? true,
    websiteUrl: printSettings?.websiteUrl ?? "",
    skuCode: true,
    totalPrice: true,
    totalQuantity: true,
    unitPrice: true,
    barcodeVariant: false,
    paymentGateway: false,
    showLevelNameLoyalty: false,
    showAmountPaymentLoyalty: false,
    showPointMember: false,
    productNote: false,
    showEInvoiceInfo: false,
    showEInvoiceBarcode: false,
  };
};

function BillSetting({ settingReload }) {
  const { shop_setting, refreshShopSetting, shop_loyalty } = useApp();
  const intl = useIntl();
  const layout = useLayoutContext();
  const [isChangeLogo, setIsChangeLogo] = useState(false);
  const [isChangeImage, setIsChangeImage] = useState(false);
  const [shopLogoUrl, setShopLogoUrl] = useState("");
  const [shopImageUrl, setShopImageUrl] = useState("");
  const [printSettings, setPrintSettings] = useState({});
  const [initTemplate, setInitTemplate] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const testPrint = useRef(null);
  const appContext = useApp();
  useEffect(() => {
    const { printSettings, shopLogoUrl, shopImageUrl } = shop_setting;
    if (
      (printSettings && printSettings.printType === "default") ||
      !printSettings
    ) {
      setPrintSettings(printSettingsDefault(shop_setting));
    } else {
      setPrintSettings(printSettings);
    }
    setShopLogoUrl(shopLogoUrl);
    setShopImageUrl(shopImageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTemplateId) {
      getHTMLPrintTemplate(appContext.callAPI, selectedTemplateId).then(
        (res) => {
          if (res?.data && !res?.error) {
            setCurrentTemplate(res?.data?.templateHTML);
            setInitTemplate(res?.data?.templateHTML);
          }
        },
      );
    }
  }, [selectedTemplateId]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onChangeSetting = (settingKey, settingValue) => {
    let newPrintSettings = { ...printSettings };
    if (settingKey === "printType" && settingValue === "default") {
      newPrintSettings = printSettingsDefault(shop_setting);
    } else if (settingKey === "printType" && settingValue === "custom") {
      const printSettingCustom = {
        ...printSettingsDefault(shop_setting),
        printType: "custom",
        showLogo: true,
        showAcknowledgements: true,
      };
      newPrintSettings = printSettingCustom;
    }
    newPrintSettings[settingKey] = settingValue;
    setPrintSettings(newPrintSettings);
  };

  const onChangeShopLogo = async (listFiles) => {
    if (listFiles && listFiles.length > 0) {
      let file = listFiles[0];
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        let shopLogoUrl = await getBase64(listFiles[0]);
        setShopLogoUrl(shopLogoUrl);
        setIsChangeLogo(true);
      }
    } else {
      layout.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "notifications.Tệp tin không đúng định dạng!",
          defaultMessage: "Tệp tin không đúng định dạng!",
        }),
      );
    }
  };
  const onChangeShopImage = async (listFiles) => {
    const maxSize = 1024 * 1024; // 1 MB
    const maxWidth = 500;
    const maxHeight = 500;
    if (listFiles && listFiles.length > 0) {
      let file = listFiles[0];
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        let shopImageUrl = await getBase64(listFiles[0]);
        const img = new Image();
        img.src = shopImageUrl;
        img.onload = () => {
          if (
            file.size <= maxSize &&
            img.width <= maxWidth &&
            img.height <= maxHeight
          ) {
            setShopImageUrl(shopImageUrl);
            setIsChangeImage(true);
          } else {
            layout.showGlobalToast(
              "error",
              intl.formatMessage({
                id: "notifications.Ảnh tải lên vượt quá kích thước cho phép, Vui lòng kiểm tra lại",
                defaultMessage:
                  "Ảnh tải lên vượt quá kích thước cho phép, Vui lòng kiểm tra lại",
              }),
            );
          }
        };
      }
    } else {
      layout.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "notifications.Tệp tin không đúng định dạng!",
          defaultMessage: "Tệp tin không đúng định dạng!",
        }),
      );
    }
  };

  const savePrintSetting = async (settingDataUpdate) => {
    let printSettingUpdate = {};
    if (settingDataUpdate) {
      printSettingUpdate = settingDataUpdate;
    } else {
      if (printSettings.printType === "default") {
        printSettingUpdate.autoPrint = printSettings.autoPrint;
        printSettingUpdate.printType = "default";
        printSettingUpdate.showLogo = printSettings.showLogo;
        printSettingUpdate.showImage = printSettings.showImage;
        printSettingUpdate.acknowledgements = printSettings.acknowledgements;
        printSettingUpdate.showAcknowledgements =
          printSettings.showAcknowledgements;
        printSettingUpdate.shopWebsite = printSettings.shopWebsite;
        printSettingUpdate.websiteUrl = printSettings.websiteUrl;
        printSettingUpdate.allowPrintTempBill =
          printSettings.allowPrintTempBill;
      } else {
        printSettingUpdate = printSettings;
      }
    }
    if (printSettings.printType === "html") {
      const res = await updateHTMLPrintTemplate(
        appContext.callAPI,
        selectedTemplateId,
        { templateHTML: currentTemplate },
      );
      if (!!res?.data && !res.error) {
        setCurrentTemplate(res?.data?.templateHTML);
        setInitTemplate(res?.data?.templateHTML);
      }
      if (res?.error) {
        layout.showGlobalToast("error", res?.message || "Có gì đó không ổn");
        return;
      }
    }

    if (isChangeLogo) {
      let result = await uploadLogo(appContext, shopLogoUrl, "logo");
      if (result && result.url) printSettingUpdate.shopLogoUrl = result.url;
    }
    if (isChangeImage) {
      let result = await uploadLogo(appContext, shopImageUrl, "shopImage");
      if (result && result.url) printSettingUpdate.shopImageUrl = result.url;
    }
    const response = await updateSettingPrint(appContext, printSettingUpdate);
    if (response?.data) {
      await refreshShopSetting();
      layout.showGlobalToast(
        "default",
        "Cấu hình đã được cập nhật thành công!",
      );
    } else
      layout.showGlobalToast(
        "error",
        "Cấu hình cập nhật không thành công. Vui lòng thử lại!",
      );
    setIsChangeLogo(false);
  };
  const printTestOrder = async () => {
    let template = null;
    if (printSettings?.printType === "html") {
      template = await testHTMLPrintTemplate(appContext.callAPI, {
        template_html: currentTemplate,
        shop_logo_url: shopLogoUrl || Assets.icon.hrv_logo_print,
        shop_image_url: shopImageUrl || Assets.icon.hrv_logo_print,
      });
      if (template?.error) {
        layout.showGlobalToast(
          "error",
          template?.message || "Có gì đó không ổn",
        );
        return;
      }
    }
    var html = `<!DOCTYPE html>
          <html lang="en">
              <head>
                  <meta charset="utf-8" />
                  <title>Haravan</title>
                  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              </head>
              <body onload="window.print();window.close()">
              <style>
                @page 
                {
                    margin: 5mm;
                    size: auto;
                }
                
                p{
                  margin: 0;
                }
                body {
                  font-family: sans-serif;
                  display: block;
                  font-size: 14px;
                  color: #000;
                  margin: 0 auto;
                  padding-left: 10px;
                  padding-right: 10px;
                  line-height: 16px;
                  
              }
                .table-print {
                  display: flow-root;
                }
                .table-print table td {
                    border: none;
                }

                .printorder {
                  margin: 0 auto;
                  font-size: inherit;
                  font-family: inherit;
                }

                .printorder .printtable {
                  font-size: inherit;
                  font-family: inherit;
                  width: 100%;
                }
                .text-right {
                  text-align: right;
                }
                .p-r-20 {
                  padding-right: 20px;
                }
                .printorder .printrow {
                  clear: both;
                  width: 100%;
                  display: inline-table;
                }

                .printorder .printrow.last {
                  border-bottom: 1px solid #b7b7b7;
                }

                .printorder .printrow.noborder .printcol {
                  border: none;
                }

                .printorder .printcol {
                  display: table-cell;
                  border-left: 1px solid #b7b7b7;
                  border-top: 1px solid #b7b7b7;
                  vertical-align: middle;
                }

                .printorder .printcol.last {
                  border-right: 1px solid #b7b7b7;
                }

                .printorder .printcol.number {
                  text-align: right;
                }

                .printorder .printcolheader {
                  font-weight: bold;
                }
                .border-title {
                  border-bottom: 2px solid;
                  border-top: 2px solid;
                  padding-bottom: 3px;
                  padding-top: 3px;
                  margin-bottom: 5px;
                }
                .page-break {
                  display: block;
                  clear: both;
                  page-break-before: always;
                }

                .barcode-wrapper {
                  width: 100%;
                  overflow: hidden;
                  text-align: center;
                }

                .text-align-center {
                  text-align: center;
                }

                .barcode-container div:first-of-type {
                  width: 5px !important;
                }

                .barcode-container div:last-of-type {
                  width: 5px !important;
                }
                .m-b-10 {
                  margin-bottom: 10px;
                }
                .m-b-5 {
                  margin-bottom: 5px;
                }
                .m-t-10 {
                  margin-top: 10px;
                }
                .m-t-5 {
                  margin-top: 5px;
                }
                .m-r-15 {
                  margin-right: 15px;
                }
                .d-flex {
                  display: flex;
                }
                .text-center {
                  text-align: center;
                }
              </style>
              ${template?.data?.data || testPrint.current.innerHTML}
              </body>
          </html>`;
    var contentWindow =
      document.getElementById("print-order").contentWindow.document;
    if (contentWindow) {
      contentWindow.open();
      contentWindow.write(html);
      setTimeout(function () {
        contentWindow.close();
      }, 200);
    }
  };

  return (
    <Fragment>
      <div className="setting-bill--floating">
        <LayoutCard className="setting-bill--header mt-0">
          <Button
            plain
            className="l-mr-8"
            title="In thử"
            onClick={() => printTestOrder()}
          />
          <Button
            status="primary"
            title="Lưu thay đổi"
            onClick={() => savePrintSetting()}
          />
        </LayoutCard>
      </div>
      <BlockUnsavedPage
        default_data={initTemplate}
        changed_data={currentTemplate}
        blocked_title={"Hủy tất cả các thay đổi chưa lưu"}
        button_continue_title={"Ở lại"}
        blocked_message={
          "Nếu hủy thì mọi chỉnh sửa đã thực hiện kể từ lần lưu cuối sẽ bị xóa."
        }
        button_cancel_title={"Hủy thay đổi"}
        modal_classname="blocking-modal-sm"
      />
      <LayoutCard className="mt-0 bg-transparent flex-fill">
        <LayoutCardSection className="h-100 flex-column">
          <LayoutCardHeader title="Hóa đơn" />
          <p className="text-secondary mt-2">
            Cấu hình hóa đơn và máy in hóa đơn
          </p>

          <div className="my-4 border-top d-flex justify-content-between flex-fill">
            <div className="left-side mr-4">
              {/* Thiết lập kiểu in hóa đơn */}
              <div className="mt-4">
                <p className="text-gray font-weight-medium padding-bottom-16 text-secondary">
                  {intl.formatMessage({
                    id: "settings.print-templates.Thiết lập kiểu in hóa đơn",
                    defaultMessage: "Thiết lập kiểu in hóa đơn",
                  })}
                </p>
                <LayoutCard className="d-flex flex-column l-mt-8">
                  <div className="l-segment">
                    <p className="text-default font-weight-medium ">
                      <b>
                        {intl.formatMessage({
                          id: "settings.print-templates.Chọn mẫu in hóa đơn",
                          defaultMessage: "Chọn mẫu in hóa đơn",
                        })}
                      </b>
                    </p>
                    <div className="mt-4">
                      <HrvComponents.InputRadio
                        name="selectTypePrint"
                        checked={printSettings.printType === "default"}
                        onChange={() => onChangeSetting("printType", "default")}
                      >
                        <span
                          onClick={() =>
                            onChangeSetting("printType", "default")
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Mặc định",
                            defaultMessage: "Mặc định",
                          })}
                        </span>
                      </HrvComponents.InputRadio>
                    </div>
                    <div className="mt-4">
                      <HrvComponents.InputRadio
                        name="selectTypePrint"
                        checked={printSettings.printType === "custom"}
                        onChange={() => onChangeSetting("printType", "custom")}
                      >
                        <span
                          onClick={() => onChangeSetting("printType", "custom")}
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tùy chỉnh",
                            defaultMessage: "Tùy chỉnh",
                          })}
                        </span>
                      </HrvComponents.InputRadio>
                    </div>
                    {Fea_PrintHTML(appContext?.auth?.user?.orgid) && (
                      <div className="mt-4">
                        <HrvComponents.InputRadio
                          name="selectTypePrint"
                          checked={printSettings.printType === "html"}
                          onChange={() => onChangeSetting("printType", "html")}
                        >
                          <span
                            onClick={() => onChangeSetting("printType", "html")}
                          >
                            {intl.formatMessage({
                              id: "settings.print-templates.html",
                              defaultMessage: "HTML",
                            })}
                          </span>
                        </HrvComponents.InputRadio>
                      </div>
                    )}
                  </div>
                </LayoutCard>
              </div>

              {/* Thiết lập hóa đơn  */}
              {/* Thông tin cừa hàng bán */}
              {printSettings.printType !== "html" && (
                <div className="mt-4">
                  <p className="text-gray font-weight-medium text-secondary">
                    {intl.formatMessage({
                      id: "settings.print-templates.Thiết lập hóa đơn",
                      defaultMessage: "Thiết lập hóa đơn",
                    })}
                  </p>
                  <LayoutCard className="d-flex flex-column l-mt-8">
                    <div className="app-container l-segment">
                      <p className="text-default font-weight-medium">
                        <b>
                          {intl.formatMessage({
                            id: "settings.print-templates.Thông tin cửa hàng bán",
                            defaultMessage: "Thông tin cửa hàng bán",
                          })}
                        </b>
                      </p>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.showLogo}
                          onChange={(value) =>
                            onChangeSetting("showLogo", value)
                          }
                        >
                          Logo
                        </HrvComponents.InputCheckbox>
                      </div>

                      <div className="mt-4">
                        {shopLogoUrl && (
                          <div className="mt-3">
                            <img
                              className="bill-setting--bill-image"
                              width="150px"
                              alt="no-data"
                              src={shopLogoUrl}
                            />
                          </div>
                        )}
                        <HrvComponents.FileUpload
                          accept="image/*"
                          className="hrv-uploadfile--box mt-3"
                          onChange={(_listFiles, _listErrors) =>
                            onChangeShopLogo(_listFiles)
                          }
                        >
                          <Button className="" light status="primary">
                            Tải lên logo
                          </Button>
                        </HrvComponents.FileUpload>
                      </div>
                      {printSettings.printType === "default" && (
                        <Fragment>
                          <div className="mt-4">
                            <HrvComponents.InputCheckbox
                              checked={printSettings.shopWebsite}
                              onChange={(value) =>
                                onChangeSetting("shopWebsite", value)
                              }
                            >
                              Website
                            </HrvComponents.InputCheckbox>
                          </div>
                          {printSettings.shopWebsite ? (
                            <HrvComponents.Textarea
                              rows={3}
                              className="m-t-10"
                              value={printSettings.websiteUrl || ""}
                              placeholder={intl.formatMessage({
                                id: "settings.print-templates.Địa chỉ Website",
                                defaultMessage: "Địa chỉ Website",
                              })}
                              onChange={(value) =>
                                onChangeSetting("websiteUrl", value)
                              }
                            />
                          ) : (
                            ``
                          )}
                        </Fragment>
                      )}
                      {printSettings.printType === "default" && (
                        <Fragment>
                          <div className="mt-4">
                            <HrvComponents.InputCheckbox
                              checked={printSettings.showAcknowledgements}
                              onChange={(value) =>
                                onChangeSetting("showAcknowledgements", value)
                              }
                            >
                              {intl.formatMessage({
                                id: "settings.print-templates.Lời cảm ơn",
                                defaultMessage: "Lời cảm ơn",
                              })}
                            </HrvComponents.InputCheckbox>
                          </div>
                          <HrvComponents.Textarea
                            rows={3}
                            className="m-t-10"
                            value={printSettings.acknowledgements || ""}
                            onChange={(value) =>
                              onChangeSetting("acknowledgements", value)
                            }
                          />
                        </Fragment>
                      )}

                      {printSettings.printType === "custom" && (
                        <Fragment>
                          <div className="mt-4">
                            <HrvComponents.InputCheckbox
                              checked={printSettings.shopWebsite}
                              onChange={(value) =>
                                onChangeSetting("shopWebsite", value)
                              }
                            >
                              Website
                            </HrvComponents.InputCheckbox>
                            {printSettings.shopWebsite ? (
                              <HrvComponents.Textarea
                                rows={3}
                                className="m-t-10 mt-4"
                                value={printSettings.websiteUrl || ""}
                                placeholder={intl.formatMessage({
                                  id: "settings.print-templates.Địa chỉ Website",
                                  defaultMessage: "Địa chỉ Website",
                                })}
                                onChange={(value) =>
                                  onChangeSetting("websiteUrl", value)
                                }
                              />
                            ) : (
                              ``
                            )}
                          </div>
                          <div className="mt-4">
                            <HrvComponents.InputCheckbox
                              checked={printSettings.showLocation}
                              onChange={(value) =>
                                onChangeSetting("showLocation", value)
                              }
                            >
                              {intl.formatMessage({
                                id: "settings.print-templates.Tên chi nhánh",
                                defaultMessage: "Tên chi nhánh",
                              })}
                            </HrvComponents.InputCheckbox>
                          </div>
                          <div className="mt-4">
                            <HrvComponents.InputCheckbox
                              checked={printSettings.shopAddress}
                              onChange={(value) =>
                                onChangeSetting("shopAddress", value)
                              }
                            >
                              {intl.formatMessage({
                                id: "settings.print-templates.Địa chỉ",
                                defaultMessage: "Địa chỉ",
                              })}
                            </HrvComponents.InputCheckbox>
                          </div>

                          <div className="mt-4">
                            <HrvComponents.InputCheckbox
                              checked={printSettings.shopPhone}
                              onChange={(value) =>
                                onChangeSetting("shopPhone", value)
                              }
                            >
                              {intl.formatMessage({
                                id: "settings.print-templates.Số điện thoại",
                                defaultMessage: "Số điện thoại",
                              })}
                            </HrvComponents.InputCheckbox>
                          </div>
                          <div className="mt-4">
                            <HrvComponents.InputCheckbox
                              checked={printSettings.shopEmail}
                              onChange={(value) =>
                                onChangeSetting("shopEmail", value)
                              }
                            >
                              Email
                            </HrvComponents.InputCheckbox>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </LayoutCard>
                </div>
              )}

              {printSettings.printType === "custom" && (
                // Thông tin người bán
                <>
                  <LayoutCard className="d-flex flex-column l-mt-8">
                    <div className="l-segment">
                      <p className="text-gray font-weight-medium padding-bottom-16">
                        {intl.formatMessage({
                          id: "settings.print-templates.Thông tin người bán",
                          defaultMessage: "Thông tin người bán",
                        })}
                      </p>
                      <div className="mt-4 ">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.nameSalesMan}
                          onChange={(value) =>
                            onChangeSetting("nameSalesMan", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tên nhân viên bán hàng",
                            defaultMessage: "Tên nhân viên bán hàng",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.billDay}
                          onChange={(value) =>
                            onChangeSetting("billDay", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Ngày lập phiếu",
                            defaultMessage: "Ngày lập phiếu",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                    </div>
                  </LayoutCard>
                  {/* // Thông tin khách mua hàng */}
                  <LayoutCard className="d-flex flex-column l-mt-8">
                    <div className="l-segment">
                      <p className="text-gray font-weight-medium padding-bottom-16">
                        {intl.formatMessage({
                          id: "settings.print-templates.Thông tin khách mua hàng",
                          defaultMessage: "Thông tin khách mua hàng",
                        })}
                      </p>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.customerName}
                          onChange={(value) =>
                            onChangeSetting("customerName", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tên khách hàng",
                            defaultMessage: "Tên khách hàng",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.customerAdress}
                          onChange={(value) =>
                            onChangeSetting("customerAdress", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Địa chỉ",
                            defaultMessage: "Địa chỉ",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.customerEmail}
                          onChange={(value) =>
                            onChangeSetting("customerEmail", value)
                          }
                        >
                          Email
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.customerPhone}
                          onChange={(value) =>
                            onChangeSetting("customerPhone", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Số điện thoại",
                            defaultMessage: "Số điện thoại",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      {shop_loyalty?.enabled ? (
                        <div className="mt-4">
                          <HrvComponents.InputCheckbox
                            checked={printSettings.showLevelNameLoyalty}
                            onChange={(value) =>
                              onChangeSetting("showLevelNameLoyalty", value)
                            }
                          >
                            {intl.formatMessage({
                              id: "settings.print-templates.Thông tin thành viên",
                              defaultMessage: "Thông tin thành viên",
                            })}
                          </HrvComponents.InputCheckbox>
                        </div>
                      ) : (
                        ``
                      )}
                    </div>
                  </LayoutCard>
                  {/* Thông tin đơn hàng */}
                  <LayoutCard className="l-mt-8">
                    <div className="l-segment">
                      <p className="text-gray font-weight-medium padding-bottom-16">
                        {intl.formatMessage({
                          id: "settings.print-templates.Thông tin đơn hàng",
                          defaultMessage: "Thông tin đơn hàng",
                        })}
                      </p>

                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.orderNumber}
                          onChange={(value) =>
                            onChangeSetting("orderNumber", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Mã đơn hàng",
                            defaultMessage: "Mã đơn hàng",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.barcode}
                          onChange={(value) =>
                            onChangeSetting("barcode", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Barcode đơn hàng",
                            defaultMessage: "Barcode đơn hàng",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.skuCode}
                          onChange={(value) =>
                            onChangeSetting("skuCode", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.SKU sản phẩm",
                            defaultMessage: "SKU sản phẩm",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.isVariant}
                          onChange={(value) =>
                            onChangeSetting("isVariant", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Biến thể sản phẩm",
                            defaultMessage: "Biến thể sản phẩm",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.productNote}
                          onChange={(value) =>
                            onChangeSetting("productNote", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Ghi chú sản phẩm",
                            defaultMessage: "Ghi chú sản phẩm",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.barcodeVariant}
                          onChange={(value) =>
                            onChangeSetting("barcodeVariant", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Barcode sản phẩm",
                            defaultMessage: "Barcode sản phẩm",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.shipPrice}
                          onChange={(value) =>
                            onChangeSetting("shipPrice", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Phí vận chuyển",
                            defaultMessage: "Phí vận chuyển",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.totalQuantity}
                          onChange={(value) =>
                            onChangeSetting("totalQuantity", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tổng số lượng",
                            defaultMessage: "Tổng số lượng",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.discount}
                          onChange={(value) =>
                            onChangeSetting("discount", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Khuyến mãi",
                            defaultMessage: "Khuyến mãi",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.totalToPay}
                          onChange={(value) =>
                            onChangeSetting("totalToPay", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tiền phải thanh toán",
                            defaultMessage: "Tiền phải thanh toán",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.totalPaid}
                          onChange={(value) =>
                            onChangeSetting("totalPaid", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tiền đã thanh toán",
                            defaultMessage: "Tiền đã thanh toán",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.customerPayingAmt}
                          onChange={(value) =>
                            onChangeSetting("customerPayingAmt", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tiền khách đưa",
                            defaultMessage: "Tiền khách đưa",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.paymentGateway}
                          onChange={(value) =>
                            onChangeSetting("paymentGateway", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Phương thức thanh toán",
                            defaultMessage: "Phương thức thanh toán",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.cashPayCustomer}
                          onChange={(value) =>
                            onChangeSetting("cashPayCustomer", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Tiền thừa trả khách/Tiền cần trả thêm",
                            defaultMessage:
                              "Tiền thừa trả khách / Tiền cần trả thêm",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.note}
                          onChange={(value) => onChangeSetting("note", value)}
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Ghi chú",
                            defaultMessage: "Ghi chú",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.showEInvoiceInfo}
                          onChange={(value) =>
                            onChangeSetting("showEInvoiceInfo", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Thông tin hoá đơn điện tử",
                            defaultMessage: "Thông tin hoá đơn điện tử",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.showEInvoiceBarcode}
                          onChange={(value) =>
                            onChangeSetting("showEInvoiceBarcode", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Mã QR xem hoá đơn điện tử",
                            defaultMessage: "Mã QR xem hoá đơn điện tử",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.showAcknowledgements}
                          onChange={(value) =>
                            onChangeSetting("showAcknowledgements", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Lời cảm ơn",
                            defaultMessage: "Lời cảm ơn",
                          })}
                        </HrvComponents.InputCheckbox>
                      </div>
                      <HrvComponents.Textarea
                        rows={3}
                        className="m-t-10 mt-4"
                        value={printSettings.acknowledgements || ""}
                        onChange={(value) =>
                          onChangeSetting("acknowledgements", value)
                        }
                      />
                      <div className="mt-4">
                        <HrvComponents.InputCheckbox
                          checked={printSettings.showImage}
                          onChange={(value) =>
                            onChangeSetting("showImage", value)
                          }
                        >
                          {intl.formatMessage({
                            id: "settings.print-templates.Hình ảnh bổ sung",
                            defaultMessage: "Hình ảnh bổ sung ",
                          })}
                          <span className="text-secondary font-size-10">
                            {intl.formatMessage({
                              id: "Kích thước: nhỏ hơn 500x500px, nhỏ hơn 1mb",
                              defaultMessage:
                                "(Kích thước: dưới 500x500px, nhỏ hơn 1mb)",
                            })}
                          </span>
                        </HrvComponents.InputCheckbox>
                      </div>
                      <div className="mt-4">
                        {shopImageUrl && (
                          <div className="mt-3">
                            <img
                              className="bill-setting--bill-image"
                              width="150px"
                              alt="no-data"
                              src={shopImageUrl}
                            />
                          </div>
                        )}
                        <HrvComponents.FileUpload
                          accept="image/*"
                          className="hrv-uploadfile--box mt-3"
                          onChange={(_listFiles, _listErrors) =>
                            onChangeShopImage(_listFiles)
                          }
                        >
                          <Button className="" light status="primary">
                            Tải ảnh lên
                          </Button>
                        </HrvComponents.FileUpload>
                      </div>
                    </div>
                  </LayoutCard>
                </>
              )}
            </div>

            <div className="right-side flex-column">
              <p className="text-gray font-weight-medium text-secondary mt-4">
                {intl.formatMessage({
                  id: "settings.print-templates.Thiết lập kiểu in hóa đơn",
                  defaultMessage: "Thiết lập kiểu in hóa đơn",
                })}
              </p>
              {printSettings.printType === "html" ? (
                <EditTemplate
                  initTemplate={initTemplate}
                  setInitTemplate={setInitTemplate}
                  template={currentTemplate}
                  setTemplate={setCurrentTemplate}
                  selectedTemplateId={selectedTemplateId}
                  setSelectedTemplateId={setSelectedTemplateId}
                />
              ) : (
                <LayoutCard className={`l-mt-8`}>
                  <div className="app-container l-segment p-0" ref={testPrint}>
                    <PrintTemplate
                      imgUrl={shopLogoUrl}
                      shopImageUrl={shopImageUrl}
                      setting={printSettings}
                      intl={intl}
                      shop_loyalty={shop_loyalty}
                    />
                  </div>
                </LayoutCard>
              )}
            </div>
          </div>
          <iframe title="print" id="print-order" style={{ display: "none" }} />
        </LayoutCardSection>
      </LayoutCard>
    </Fragment>
  );
}

export default BillSetting;
