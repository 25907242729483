const FullScreenApps = [
  "47067a121785dbad9f2ad852f2cdb2a0",
  "a99e3b6a7512271b651db51178f88aba",
  "2e6e6c956f69ef3b6acd07bd1bc5a18f",
  "82627ea2b81b7c73a0e315a247104212",
];

const Fea_RecommendPromotions = (orgid) =>
  process.env.REACT_APP_FEA_RECOMMENDPROMOTIONS === "*" ||
  process.env.REACT_APP_FEA_RECOMMENDPROMOTIONS?.split(",").includes(orgid);

const Fea_PrintHTML = (orgid) =>
  process.env.REACT_APP_FEA_PRINTHTML === "*" ||
  process.env.REACT_APP_FEA_PRINTHTML?.split(",").includes(orgid);

const Fea_PinPricingList = (orgid) =>
  process.env.REACT_APP_FEA_PINPRICINGLIST === "*" ||
  process.env.REACT_APP_FEA_PINPRICINGLIST?.split(",").includes(orgid);

const Fea_BarcodePattern = (orgid) =>
  process.env.REACT_APP_FEA_BARCODEPATTERN === "*" ||
  process.env.REACT_APP_FEA_BARCODEPATTERN?.split(",").includes(orgid);

const Fea_ShiftManager = (orgid) =>
  process.env.REACT_APP_FEA_SHIFTMANAGER === "*" ||
  process.env.REACT_APP_FEA_SHIFTMANAGER?.split(",").includes(orgid);

const Fea_Adviser = (orgid) =>
  process.env.REACT_APP_FEA_ADVISER === "*" ||
  process.env.REACT_APP_FEA_ADVISER?.split(",").includes(orgid);

const Fea_CartAction = (orgid) =>
  process.env.REACT_APP_FEA_CARTACTION === "*" ||
  process.env.REACT_APP_FEA_CARTACTION?.split(",").includes(orgid);

export {
  FullScreenApps,
  Fea_RecommendPromotions,
  Fea_PrintHTML,
  Fea_PinPricingList,
  Fea_BarcodePattern,
  Fea_ShiftManager,
  Fea_Adviser,
  Fea_CartAction,
};
