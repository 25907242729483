
export const callSearchProducts = async (appContext, page, limit, query, location_id, collection_ids = null, product_type = null, vendor = null, tag_contains = null, isScale = false) => {
  let temp_params = `?page=${page}&limit=${limit}&query=${encodeURIComponent(query)}&location_id=${location_id}&channel=hararetail&direction=desc`;
  const setting = appContext.shop_setting
  let params = {
    page, limit, query,
    channel: 'hararetail',
    location_id,
    direction: 'desc',
  };
  if (collection_ids) {
    params.collection_ids = collection_ids;
    temp_params += `&collection_ids=${collection_ids}`;
  }
  if (product_type) {
    product_type = encodeURIComponent(product_type);
    params.product_type = product_type;
    temp_params += `&product_type=${product_type}`;
  }
  if (vendor) {
    vendor = encodeURIComponent(vendor);
    params.vendor = vendor;
    temp_params += `&vendor=${vendor}`;
  }
  if (tag_contains) {
    tag_contains = encodeURIComponent(tag_contains);
    params.tag_contains = tag_contains;
    temp_params += `&tag_contains=${tag_contains}`;
  }
  if (setting.hideProductOutOfStock && !setting.allowOrderProductOutOfStock) {
    params.status = 'in_stock';
    temp_params += '&status=in_stock';
  }
  if (isScale) {
    temp_params += '&scale=true';
  }
  return await appContext.callAPI('get', `/call/com_api/products` + temp_params);
};


export const getDetail = async (appContext, productId) => {
  return await appContext.callAPI('get', `/call/com_api/products/${productId}`);
};

export const getProductDetail = async (appContext, productId) => {
  return await appContext.callAPI('get', `/call/retail_api/products/${productId}`);
};

export const getVariantDetail = async (appContext, productId, variantId) => {
  return await appContext.callAPI('get', `/call/com_api/products/v2/${productId}/variants/${variantId}`);
};

export const getVendorAPI = async (appContext) => {
  return await appContext.callAPI('get', `/call/com_api/products/vendors`);
};

export const getProductTypeAPI = async (appContext) => {
  return await appContext.callAPI('get', `/call/com_api/products/types`);
};

export const getProductTagAPI = async (appContext) => {
  return await appContext.callAPI('get', `/call/com_api/products/tags`);
};


export const getCollectionAPI = async (appContext, page, limit, query = null) => {
  let temp_params = `?page=${page}&limit=${limit}`;
  if (query) {
    temp_params += `collection_type=custom&query=${query}`;
  }
  return await appContext.callAPI('get', `/call/com_api/products/collections/simple` + temp_params);
};