/*
  size: 'xs' = 2.4rem | 'sm' = 3.2rem | 'md' = 4.0rem | 'lg' = 4.8rem | 'xl' = 5.6rem
  status: 'primary' | 'warning' | 'danger' | ...
  rounded: button bo tròn
  light/plain: nút nền sáng/nút không viền
  
  stretch: bung bự chiều ngang
  noWrap: chiều cao không đổi, title hiện 3 chấm nếu bị ép nhỏ
  truyền children để custom nội dung nút
*/

import Tooltip from "rc-tooltip";
import Icon from "component-v2/icons/icons";
import { Fragment } from "react";
import { Loading } from "@haravan/react-components";

export default function Button({
  size = "md",
  status = "default",
  title,
  icon,
  iconColor,
  iconRight = false,
  badge,
  rounded = false,
  light = false,
  plain = false,
  disabled = false,
  // stretch = false,
  noWrap = true,
  className,
  children,
  onClick,
  dataToggle,
  tooltipTitle,
  placementTooltip = "top",
  loading = false,
  id,
}) {
  let _size = "md";
  let _icon_size = "sm";
  let _icon_color = iconColor ? iconColor : "unset";
  switch (size) {
    case null:
    case undefined:
    case "":
    case "md":
    default:
      break;
    case "xs":
      _size = "xs";
      _icon_size = "2";
      break;
    case "sm":
      _size = "sm";
      _icon_size = "sm";
      break;
    case "lg":
      _size = "lg";
      _icon_size = 2.4;
      break;
    case "xl":
      _size = "xl";
      _icon_size = "md";
      break;
  }
  let squared = false; // nếu button chỉ có icon: button sẽ vuông (padding bằng nhau)
  if (icon && !title && !badge) squared = true;

  const renderButton = () => {
    return (
      <button
        id={id}
        data-toggle={dataToggle}
        className={`${
          loading ? "position-relative" : ""
        }  l-button l-button-${status} l-button-${_size}${
          rounded ? " rounded" : ""
        }${squared ? " squared" : ""}${!plain && light ? " light" : ""}${
          plain ? " plain" : ""
        }${noWrap ? " nowrap" : ""}${className ? " " + className : ""}`}
        disabled={disabled}
        onClick={onClick}
      >
        {loading ? (
          <Loading
            size="pico"
            className="loading-btn loading-btn-custom position-absolute"
          />
        ) : (
          ""
        )}
        <span style={{ color: `${loading ? "transparent" : ""}` }}>
          {children ? (
            children
          ) : (
            <Fragment>
              {icon && !iconRight ? (
                <Icon
                  name={icon}
                  size={_icon_size}
                  color={_icon_color}
                  className="l-button-ico"
                />
              ) : null}
              {title ? <span className="l-button-title">{title}</span> : null}
              {badge ? (
                <span
                  className="l-button-badge"
                  style={{ background: !loading ? "" : "transparent" }}
                >
                  {badge}
                </span>
              ) : null}
              {icon && iconRight ? (
                <Icon
                  name={icon}
                  size={_icon_size}
                  color={_icon_color}
                  className="l-button-ico"
                />
              ) : null}
            </Fragment>
          )}
        </span>
      </button>
    );
  };

  if (icon && tooltipTitle) {
    return (
      <Tooltip overlay={tooltipTitle} placement={placementTooltip}>
        {renderButton()}
      </Tooltip>
    );
  }
  return <>{renderButton()}</>;
}
