import React from "react";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import OrderDetail from "./order-detail/order-detail";
import OrderList from "./order-list/order-list";
import "./utils/index.css";

export default function OrderRouter() {
  const intl = useIntl();
  const layout = useLayoutContext();
  useEffect(() => {
    layout.setPageTitle(
      intl.formatMessage({
        id: "orders.Tra cứu đơn hàng",
        defaultMessage: "Tra cứu đơn hàng",
      }),
    );
    layout.setBreadcrumb([
      {
        title: intl.formatMessage({
          id: "orders.Tra cứu đơn hàng",
          defaultMessage: "Tra cứu đơn hàng",
        }),
      },
    ]);
    return () => {
      layout.setPageTitle("");
      layout.setBreadcrumb(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [currentOrderDetail, setCurrentDetail] = React.useState(null);

  const getCurrentOrderDetail = (order) => {
    setCurrentDetail(order);
  };

  return (
    <LayoutBody size="ne" flexRow>
      <div className="order-list--list">
        <OrderList currentOrderDetail={currentOrderDetail} />
      </div>
      <div className="order-list--detail">
        <OrderDetail handleCbData={getCurrentOrderDetail} />
      </div>
    </LayoutBody>
  );
}
