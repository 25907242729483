import React, { useEffect, useState, useRef } from "react";
import { Selection, Loading } from "@haravan/react-components";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import NumberShow from "component-v2/number-show";
import "./product.style.css";
import { ProductItem, PopupVariant } from "./components";
import Icon from "component-v2/icons/icons";
import { ProductCartHelperService } from "./product-cart-helper.service";
import { useCartContext } from "screens-v2/carts-v2";
import EmptyState from "component-v2/empty-state";
import { ProductService } from "services";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import Button from "component-v2/buttons";
import { SaleUsers } from "../RightCart/saleUsers";
import { SelectionCustom } from "../RightCart/components/SelectionCustom";

export const QuickProducts = (props) => {
  const productService = new ProductService();
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  const cartContext = useCartContext();
  const appContext = useApp();
  const productCartHelper = new ProductCartHelperService(
    appContext.shop_setting,
  );
  //flag
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(true);
  const [isDiscountSuggest, setIsDiscountSuggest] = useState(false);
  const [isPopupVariant, setIsPopupVariant] = useState(false);
  // resource
  const [products, setProducts] = useState(
    productService.getDefaultProductValue(),
  );
  const [collections, setCollections] = useState(
    productService.getDefaultValue(),
  );
  const [collectionsProductType, setCollectionsProductType] = useState(
    productService.getDefaultValue(),
  );
  const [collectionsVendors, setCollectionsVendors] = useState(
    productService.getDefaultValue(),
  );
  const [collectionsTag, setCollectionsTag] = useState(
    productService.getDefaultValue(),
  );
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [setting, setSetting] = useState(null);
  // variable
  const [groupFilter, setGroupFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [vendorFilter, setVendorFilter] = useState(null);
  const [tagFilter, setTagFilter] = useState(null);
  const [filterProduct, setFilterProduct] = useState({});
  const menu_ref = useRef();

  const isActiveResetFilter =
    groupFilter || typeFilter || vendorFilter || tagFilter;

  useEffect(() => {
    getResources();
    setIsLoadingMore(false);
    setIsPopupVariant(false);
    setIsDiscountSuggest(false);
    setSetting(null);
  }, []);
  useEffect(() => {
    if (!isShowMenu) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMenu]);
  function handleOutsideClick(e) {
    // if (!menu_ref?.current.contains(e.target)) {
    //   setIsShowMenu(true)
    //   document.removeEventListener("click", handleOutsideClick, false)
    // }
  }

  const getResources = async () => {
    try {
      setIsLoading(true);
      if (collections.recordsCollect.length === 0) {
        let recordsCollect = [
          {
            id: null,
            name: intl.formatMessage({
              id: "carts.orders.Nhóm sản phẩm",
              defaultMessage: "Nhóm sản phẩm",
            }),
          },
        ];
        let collection = await productService.getCollection(appContext, 1, 10);
        if (collection) {
          recordsCollect = [
            ...recordsCollect,
            ...(collection.recordsCollect ?? []),
          ];
        }
        setCollections({ ...collection, recordsCollect });
      }

      if (collectionsProductType.recordsCollect.length === 0) {
        let types = await productService.getCollectionsProductType(appContext);
        let recordsCollect = [
          {
            id: null,
            name: intl.formatMessage({
              id: "carts.orders.Loại sản phẩm",
              defaultMessage: "Loại sản phẩm",
            }),
          },
        ];
        if (types && types.length > 0) {
          recordsCollect = [...recordsCollect, ...types];
        }
        setCollectionsProductType({
          ...collectionsProductType,
          recordsCollect,
        });
      }

      if (collectionsTag.recordsCollect.length === 0) {
        let recordsCollect = [
          {
            id: null,
            name: intl.formatMessage({
              id: "carts.orders.Được tag với",
              defaultMessage: "Được tag với",
            }),
          },
        ];
        let prodTags =
          await productService.getCollectionsProductTags(appContext);
        if (prodTags && prodTags.length > 0) {
          recordsCollect = [
            ...recordsCollect,
            ...prodTags.map((tag) => ({ id: tag, name: tag })),
          ];
        }
        setCollectionsTag({ ...collectionsTag, recordsCollect });
      }

      if (collectionsVendors.recordsCollect.length === 0) {
        let recordsCollect = [
          {
            id: null,
            name: intl.formatMessage({
              id: "carts.orders.Nhà cung cấp",
              defaultMessage: "Nhà cung cấp",
            }),
          },
        ];
        let vendors =
          await productService.getCollectionsProductVendors(appContext);
        if (vendors && vendors.length > 0) {
          recordsCollect = [...recordsCollect, ...vendors];
        }
        setCollectionsVendors({ ...collectionsVendors, recordsCollect });
      }
      if (products.records.length === 0) {
        let products = await productService.getProducts(appContext);
        if (products) {
          setProducts({ ...products });
        }
      }
      if (!setting) {
        let _setting = productCartHelper.getSetting();
        setSetting(_setting);
      }
      setIsLoading(false);
    } catch (error) {
      // force flag default
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const onOpenProductListing = () => {
    setIsShowMenu(!isShowMenu);
  };

  //#region filter
  const setFilter = async (type, value) => {
    let _filter = { groupFilter, typeFilter, tagFilter, vendorFilter };

    switch (type) {
      case "collection":
        setGroupFilter(value);
        _filter.groupFilter = value;
        if (!value) {
          let _colleciton = await productService.getCollection(appContext);
          if (_colleciton && _colleciton.recordsCollect.length > 0) {
            _colleciton.recordsCollect = [
              {
                id: null,
                name: intl.formatMessage({
                  id: "carts.orders.Nhóm sản phẩm",
                  defaultMessage: "Nhóm sản phẩm",
                }),
              },
              ..._colleciton.recordsCollect,
            ];
          }
          setCollections({ ..._colleciton });
        }
        break;
      case "type":
        setTypeFilter(value);
        _filter.typeFilter = value;
        break;
      case "vendor":
        setVendorFilter(value);
        _filter.vendorFilter = value;
        break;
      case "tag":
        setTagFilter(value);
        _filter.tagFilter = value;
        break;
      default:
        break;
    }
    return _filter;
  };

  const onChangeFilter = async (type, value) => {
    setIsLoading(true);
    let filter = await setFilter(type, value);
    setFilterProduct(filter);
    let _product = await productService.getProducts(appContext, filter);
    if (_product) {
      setProducts(_product);
    }
    setIsLoading(false);
  };

  const resetFilter = async () => {
    setIsLoading(true);
    setGroupFilter(null);
    setTypeFilter(null);
    setVendorFilter(null);
    setTagFilter(null);
    setCollections(productService.getDefaultValue());
    let _product = await productService.getProducts(appContext);
    if (_product) {
      setProducts(_product);
    }
    let _colleciton = await productService.getCollection(appContext);
    if (_colleciton) setCollections(_colleciton);
    setIsLoading(false);
  };

  const handleSearchGrProduct = debounce(async (str) => {
    let _colleciton = await productService.searchGroupProduct(
      appContext,
      1,
      10,
      str,
    );
    if (_colleciton) {
      setCollections({
        ..._colleciton,
        recordsCollect: [
          {
            id: null,
            name: intl.formatMessage({
              id: "carts.orders.Nhóm sản phẩm",
              defaultMessage: "Nhóm sản phẩm",
            }),
          },
          ..._colleciton.recordsCollect,
        ],
      });
    }
  }, 400);

  const onFilterSearch = async (keyword) => {
    await handleSearchGrProduct(keyword);
  };
  //#endregion
  const handleCollectionLoadMore = async (e) => {
    let scrolled =
      Math.round(e.target.scrollHeight) <=
      Math.round(e.target.scrollTop + e.target.clientHeight) + 2;
    if (scrolled && !isLoadingMore) {
      setIsLoadingMore(true);
      if (collections && collections.hasNextPage) {
        let _colleciton = await productService.getCollection(
          appContext,
          collections.currentPage + 1,
          10,
        );
        collections.recordsCollect = collections.recordsCollect.concat(
          _colleciton.recordsCollect,
        );
        collections.currentPage = _colleciton.currentPage;
        collections.hasNextPage = _colleciton.hasNextPage;
        setCollections(collections);
      }
      setIsLoadingMore(false);
    }
  };

  const handleTrackScrolling = async (e) => {
    const scrolled =
      Math.round(e.target.scrollHeight) <=
      Math.round(e.target.scrollTop + e.target.clientHeight) + 50;
    if (scrolled && !isLoadingMore) {
      setIsLoadingMore(true);
      if (products && products.hasNextPage) {
        let _product = await productService.getProducts(
          appContext,
          filterProduct,
          products.currentPage + 1,
          20,
        );
        products.records = products.records.concat(_product.records);
        products.currentPage = _product.currentPage;
        products.hasNextPage = _product.hasNextPage;
        setProducts(products);
      }
      setIsLoadingMore(false);
    }
  };

  const handleQuickSelect = (value) => {
    if (value.totalVariants > 1) {
      setIsPopupVariant(true);
      setSelectedProduct({ ...value });
    } else if (value.totalVariants === 1) {
      let variant = value.variants[0];
      handleAddCart(value, variant);
    }
  };

  const handleAddCart = (product, variant) => {
    if (!productCartHelper.validateRuleCart(variant)) {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: "notifications.Sản phẩm đã hết hàng!",
          defaultMessage: "Sản phẩm đã hết hàng!",
        }),
      );
      return;
    }
    let itemFormat = productCartHelper.getItemCartFormat(product, variant);
    cartContext.addItemCurrentCart({ ...itemFormat });
  };

  const renderCollection = (resources) => {
    if (resources.length === 0) return null;
    return resources.map((item, index) => {
      return (
        <SelectionCustom.Option value={item.id} key={index}>
          {item.name}
        </SelectionCustom.Option>
      );
    });
  };

  const renderProductTag = (product) => {
    if (product.totalVariants !== 1) {
      let hasInStocks = product.variants.some(
        (x) => x.quantity > 0 || x.isTrackingInventory === false,
      );
      let isNotTrackingVariants = product.variants.every(
        (x) => !x.isTrackingInventory,
      );
      return (
        <>
          {!hasInStocks &&
          !appContext.shop_setting?.allowOrderProductOutOfStock ? (
            <Badge status="error" light rounded className="prod-tag">
              <span className="text-danger ">Hết hàng</span>
            </Badge>
          ) : (
            <Badge status="default" light rounded className="prod-tag">
              <span className="content-secondary">
                {isNotTrackingVariants ? <Icon name="infinity" /> : "..."}
              </span>
            </Badge>
          )}
        </>
      );
    }

    if (product.totalVariants === 1) {
      if (product.variants[0].isTrackingInventory) {
        if (product.variants[0].quantity > 0) {
          return (
            <Badge status="default" light rounded className="prod-tag">
              <span className="content-secondary">
                <NumberShow value={product.variants[0].quantity} />
              </span>
            </Badge>
          );
        }
        if (product.variants[0].quantity <= 0)
          return (
            <Badge status="error" light rounded className="prod-tag">
              <span className="text-danger ">
                <NumberShow value={product.variants[0].quantity} />
              </span>
            </Badge>
          );
      } else {
        return (
          <Badge status="default" light rounded className="prod-tag">
            <span>
              <Icon name="infinity" />
            </span>
          </Badge>
        );
      }
    }
  };

  return (
    <div ref={menu_ref} className="carts-quickly-products">
      <div style={{ position: "relative" }}>
        <div
          className={`prod-btn-listing bg-white pointer ${!isShowMenu ? "" : "shadow-none"}`}
          style={{
            width: "100%",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            overflow: `${!isShowMenu ? "hidden" : ""}`,
          }}
        >
          {/* Section Action */}
          <div className={`prod-actions border-top`}>
            <div className="d-flex">
              <Button
                size="md"
                light
                icon="product_list"
                title="Danh sách sản phẩm"
                className={`w-100 d-flex align-items-center justify-content-center ${!isShowMenu ? "background-accent50" : ""}`}
                onClick={() => onOpenProductListing()}
              >
                <span className="d-inline-flex nowrap">
                  <Icon
                    name="product_list"
                    // size={_icon_size}
                    // color={_icon_color}
                    className="l-button-ico"
                  />
                  <span className="l-button-title">Danh sách sản phẩm</span>
                  <Icon
                    name={
                      isShowMenu ? "keyboard_arrow_up" : "keyboard_arrow_down"
                    }
                    // size={_icon_size}
                    color={"var(--contentAccent)"}
                    className="l-button-ico"
                  />
                </span>
              </Button>
            </div>
            <div style={{ maxWidth: "280px" }}>
              <SaleUsers />
            </div>
            <div className="l-mr-8"></div>
          </div>
          {/* Section Filter */}
          <div
            className={`prod-listing ${isShowMenu ? "" : "prod-listing-dropdown2"} `}
            style={{ bottom: isDiscountSuggest ? "8.5rem" : 0 }}
          >
            <div className="d-flex justify-content-between prod-listing-filters">
              <div className="filter-root w-100">
                <div
                  className={`prod-filter-content ${isActiveResetFilter ? "" : ""}`}
                  onClick={isActiveResetFilter ? resetFilter : null}
                >
                  <span className="">
                    {intl.formatMessage({
                      id: "carts.orders.Tất cả sản phẩm",
                      defaultMessage: "Tất cả sản phẩm",
                    })}
                  </span>
                </div>
                {/* collection filter */}
                {collections?.recordsCollect?.length > 0 || !isLoading ? (
                  <SelectionCustom
                    options={collections.recordsCollect}
                    idField="id"
                    valueField="name"
                    value={groupFilter}
                    showSearch
                    onChange={(value) => onChangeFilter("collection", value)}
                    onSearch={(str) => onFilterSearch(str)}
                    onPopupScroll={(e) => handleCollectionLoadMore(e)}
                    notFoundContent={intl.formatMessage({
                      id: "carts.orders.Không tìm thấy",
                      defaultMessage: "Không tìm thấy",
                    })}
                    placeholder={intl.formatMessage({
                      id: "carts.orders.Nhóm sản phẩm",
                      defaultMessage: "Nhóm sản phẩm",
                    })}
                  >
                    {renderCollection(collections.recordsCollect)}
                  </SelectionCustom>
                ) : (
                  <Selection
                    options={[{ id: "loading", name: "loading" }]}
                    idField="id"
                    valueField="name"
                    value={groupFilter}
                    onChange={(value) => onChangeFilter("group", value)}
                    notFoundContent={intl.formatMessage({
                      id: "carts.orders.Không tìm thấy",
                      defaultMessage: "Không tìm thấy",
                    })}
                  >
                    <Selection.Option
                      value="loading"
                      style={{ padding: "25px", background: "white" }}
                    >
                      <Loading size="thumb" />
                    </Selection.Option>
                  </Selection>
                )}
                {/*  product type */}
                {collectionsProductType?.recordsCollect?.length > 0 ||
                !isLoading ? (
                  <SelectionCustom
                    options={collectionsProductType.recordsCollect}
                    idField="id"
                    valueField="name"
                    showSearch
                    value={typeFilter}
                    onChange={(value) => onChangeFilter("type", value)}
                    placeholder={intl.formatMessage({
                      id: "carts.orders.Loại sản phẩm",
                      defaultMessage: "Loại sản phẩm",
                    })}
                  >
                    {renderCollection(collectionsProductType.recordsCollect)}
                  </SelectionCustom>
                ) : (
                  <Selection
                    options={[{ id: "loading", name: "loading" }]}
                    idField="id"
                    valueField="name"
                    value={typeFilter}
                    onChange={(value) => onChangeFilter("type", value)}
                    notFoundContent={intl.formatMessage({
                      id: "carts.orders.Không tìm thấy",
                      defaultMessage: "Không tìm thấy",
                    })}
                  >
                    <Selection.Option
                      value="loading"
                      style={{ padding: "25px", background: "white" }}
                    >
                      <Loading size="thumb" />
                    </Selection.Option>
                  </Selection>
                )}
                {/* vendor */}
                {collectionsVendors?.recordsCollect?.length > 0 ||
                !isLoading ? (
                  <SelectionCustom
                    options={collectionsVendors.recordsCollect}
                    idField="id"
                    valueField="name"
                    showSearch
                    value={vendorFilter}
                    onChange={(value) => onChangeFilter("vendor", value)}
                    placeholder={intl.formatMessage({
                      id: "carts.orders.Nhà cung cấp",
                      defaultMessage: "Nhà cung cấp",
                    })}
                  >
                    {/* <SelectionCustom.Option value={null} className="select-area">
                      {intl.formatMessage({
                        id: "carts.orders.Nhà cung cấp",
                        defaultMessage: "Nhà cung cấp",
                      })}
                    </SelectionCustom.Option> */}
                    {renderCollection(collectionsVendors.recordsCollect)}
                  </SelectionCustom>
                ) : (
                  <Selection
                    options={[{ id: "loading", name: "loading" }]}
                    idField="id"
                    valueField="name"
                    value={vendorFilter}
                    onChange={(value) => onChangeFilter("vendor", value)}
                    notFoundContent={intl.formatMessage({
                      id: "carts.orders.Không tìm thấy",
                      defaultMessage: "Không tìm thấy",
                    })}
                  >
                    <Selection.Option
                      value="loading"
                      style={{ padding: "25px", background: "white" }}
                    >
                      <Loading size="thumb" />
                    </Selection.Option>
                  </Selection>
                )}
                {/* tag */}
                {collectionsTag?.recordsCollect?.length > 0 || !isLoading ? (
                  <SelectionCustom
                    options={collectionsTag.recordsCollect}
                    idField="id"
                    valueField="name"
                    showSearch
                    value={tagFilter}
                    onChange={(value) => onChangeFilter("tag", value)}
                    placeholder={intl.formatMessage({
                      id: "carts.orders.Được tag với",
                      defaultMessage: "Được tag với",
                    })}
                  >
                    {/* <SelectionCustom.Option value={null} className="select-area">
                      {intl.formatMessage({
                        id: "carts.orders.Được tag với",
                        defaultMessage: "Được tag với",
                      })}
                    </SelectionCustom.Option> */}
                    {renderCollection(collectionsTag.recordsCollect)}
                  </SelectionCustom>
                ) : (
                  <Selection
                    value={tagFilter}
                    onChange={(value) => onChangeFilter("tag", value)}
                    notFoundContent={intl.formatMessage({
                      id: "carts.orders.Không tìm thấy",
                      defaultMessage: "Không tìm thấy",
                    })}
                  >
                    <Selection.Option
                      value="loading"
                      style={{ padding: "25px", background: "white" }}
                    >
                      <Loading size="thumb" />
                    </Selection.Option>
                  </Selection>
                )}
              </div>
              <div style={{ padding: "0.8rem 1.2rem" }}>
                <Button
                  onClick={() => {
                    setIsShowMenu((prev) => !prev);
                  }}
                  className={"rounded-circle"}
                  icon={"angle_down"}
                  light
                />
              </div>
            </div>
            {/* </div> */}
            {/* Section Body */}
            {!isLoading ? (
              <div
                className="prod-flex-4-cols p-16 prod-listing-list pointer"
                overflow="true"
                onScroll={handleTrackScrolling}
              >
                {products?.records?.length > 0 ? (
                  products.records.map((value, idx) => {
                    if (
                      value?.variants?.every(
                        (variant) =>
                          variant.quantity <= 0 && variant?.isTrackingInventory,
                      ) &&
                      setting.hideOutStock &&
                      !setting.allowOrderOutStock
                    ) {
                      return null;
                    }
                    return (
                      <ProductItem
                        key={idx}
                        imgSrc={value.url}
                        value={value}
                        title={value.productName}
                        onClick={handleQuickSelect}
                      >
                        {value.totalVariants === 1 &&
                          value.variants[0].isTrackingInventory}
                        {renderProductTag(value)}
                      </ProductItem>
                    );
                  })
                ) : (
                  <EmptyState
                    iconName="local_offer"
                    description={intl.formatMessage({
                      id: "carts.orders.Không tìm thấy sản phẩm nào",
                      defaultMessage: "Không tìm thấy sản phẩm nào",
                    })}
                  />
                )}
                {isLoadingMore && products.hasNextPage && (
                  <div className="w-100 d-flex justify-content-center">
                    <Loading size="pico" />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="prod-listing-none">
                  {!isShowMenu ? <Loading size="thumb" /> : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`popup ${isPopupVariant ? "popup-show" : "popup-hide"}`}>
        {isPopupVariant && (
          <PopupVariant
            showGlobalToast={showGlobalToast}
            callback={props.callback}
            intl={intl}
            isToggele={isPopupVariant}
            setShow={setIsPopupVariant}
            product={selectedProduct}
            onClick={handleAddCart}
            allowOrderOutStock={setting.allowOrderOutStock}
            hideOutStock={setting.hideOutStock}
          />
        )}
      </div>
      {isPopupVariant && <div className="bg-blur" />}
    </div>
  );
};
