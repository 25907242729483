import { getCurrentOrderByCustomerId } from "api/customer-repository";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import {
  DateTimeShowType,
  toFormattedCurrency,
  toFormattedDate,
} from "infrastructures/format-helpers";
import React, { Fragment, useEffect, useState } from "react";
import { OrderSourceEnums } from "screens-v2/carts-v2/cart-helpers.service";
import StatusShow from "component-v2/status-show";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";

export const CurrentOrders = ({ customerInfo }) => {
  const { callAPI } = useApp();
  const [currentOrder, setCurrentOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurrentOrder();
  }, []);

  async function getCurrentOrder() {
    setLoading(true);
    const resultOrder = await getCurrentOrderByCustomerId(
      callAPI,
      customerInfo?.id,
    );
    setLoading(false);
    setCurrentOrder(resultOrder.data.data);
  }

  if (!currentOrder && loading) {
    return (
      <div>
        <LoadingCircle />
      </div>
    );
  } else if (!currentOrder?.length) {
    return <div className="text-center">Không có đơn hàng gần đây</div>;
  }

  const orderSourceMapping = (sourceName) => {
    let source = OrderSourceEnums.find((source) => source.name === sourceName);
    if (source) {
      return source.display;
    } else {
      return sourceName;
    }
  };

  return (
    <>
      {currentOrder.map((order) => {
        return (
          <Fragment key={order.orderId}>
            <div className="d-flex justify-content-between">
              <div>
                <p className="content-secondary font-size-12 m-0">
                  {toFormattedDate(order.orderDate, DateTimeShowType.date)}
                </p>
                <p className="font-weight-medium mb-0 l-mt-4">
                  {order.orderNumber}
                </p>
                <p className="content-secondary text-capitalize font-size-12 mb-0 l-mt-4">
                  {orderSourceMapping(order.sourceName)}
                </p>
              </div>
              <div>
                <div className="text-right">
                  <span className="py-1 px-3 rounded-lg border-primary-300 background-disabled content-secondary">
                    <Icon name="payments" size="xs" />
                    <span className="ml-3 font-size-12">
                      <StatusShow
                        type="payment"
                        status={order.paymentStatusId}
                      />
                    </span>
                  </span>
                </div>
                <div className="mt-3 text-right">
                  <span className="mr-auto py-1 px-3 rounded-lg border-primary-300 background-disabled content-secondary">
                    <Icon name="local_shipping" size="xs" />
                    <span className="ml-3 font-size-12">
                      <StatusShow
                        type="fulfillment"
                        status={
                          order.fulFillMentStatusId === 2
                            ? "chuagiaohang"
                            : order.shipmentStatus
                        }
                      />
                    </span>
                  </span>
                </div>
                <p className="mt-3 text-right">
                  {toFormattedCurrency(order.orderTotal)}
                </p>
              </div>
            </div>
            <hr className="my-3" />
          </Fragment>
        );
      })}
    </>
  );
};
