import { Loading } from "@haravan/react-components";
import { callGetApiOrderDetails } from "api/order-repository";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { HOTKEYS } from "models/constants";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useCartContext } from "screens-v2/carts-v2";
import PrintButton from "screens-v2/orders/components/print-button";
import GlobalSimpleModal from "screens-v2/layouts/layouts/global-simple-modal";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { generatePaymentUrl } from "utils/Commons";

export function ModalCreateOrderOnlineSuccess({
  data,
  show,
  setShow,
  resetCart,
}) {
  const intl = useIntl();
  const { callAPI, shop_info } = useApp();
  const [currentOrder, setCurrentOrder] = useState();
  const { current_shopping_cart } = useCartContext();
  const [loading, setLoading] = useState(true);
  const [isVatFail, setIsVatFail] = useState(false);

  useEffect(() => {
    const orderId = data?.dataResult?.orderId;
    if (orderId && show) {
      getOrderDetails(orderId);
    }
    if (!show) {
      setIsVatFail(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, show]);

  const getOrderDetails = async (orderId) => {
    setLoading(true);
    const res = await callGetApiOrderDetails(callAPI, orderId);
    setLoading(false);
    if (res && res.data) {
      setCurrentOrder(res.data);
    }
  };
  const body = (
    <div>
      <iframe
        id="print-order"
        style={{ display: "none" }}
        title="print preview"
      />
      <div className="text-center font-weight-semibold font-size-16">
        <Icon name="checkbox_circle_line" size="xl" color="var(--green600)" />
        <p>
          {intl.formatMessage({
            id: "carts.orders.Tạo đơn thành công!",
            defaultMessage: "Tạo đơn thành công!",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "carts.orders.Mã đơn hàng",
            defaultMessage: "Mã đơn hàng",
          })}{" "}
          <span className="content-accent">
            {data?.dataResult?.orderNumber}
          </span>
        </p>
        {loading ? (
          <div
            style={{ height: "209px" }}
            className="d-flex align-items-center  justify-content-center"
          >
            <Loading size="icon" />
          </div>
        ) : currentOrder && currentOrder.checkoutToken ? (
          <div>
            <p>
              <small>
                {intl.formatMessage({
                  id: "carts.orders.Khách hàng quét mã này để thanh toán online",
                  defaultMessage: "Khách hàng quét mã này để thanh toán online",
                })}
              </small>
            </p>
            <QRCodeSVG
              value={generatePaymentUrl(shop_info, currentOrder.checkoutToken)}
              includeMargin={true}
              size={160}
            />
            <p>
              <small>
                {intl.formatMessage({
                  id: "carts.orders.Để kiểm tra chi tiết thanh toán",
                  defaultMessage: "Để kiểm tra chi tiết thanh toán",
                })}{" "}
                {currentOrder.orderId ? (
                  <NavLink
                    target={"_blank"}
                    to={`/orders/${currentOrder.orderId}`}
                    className="content-accent"
                  >
                    {intl.formatMessage({
                      id: "carts.orders.Xem chi tiết đơn hàng",
                      defaultMessage: "Xem chi tiết đơn hàng",
                    })}
                  </NavLink>
                ) : null}
              </small>
            </p>
          </div>
        ) : null}
      </div>
      <div className="w-100 d-flex justify-content-center mt-5">
        <Button
          light
          size="lg"
          badge={HOTKEYS.ESC}
          id="order.create.success_popup.new_button"
          onClick={() => {
            setShow(false);
            resetCart();
          }}
          status="secondary"
          className="mr-3 font-weight-semibold"
          title={"Tạo đơn mới"}
        />
        <PrintButton
          onSetVATStatus={setIsVatFail}
          intl={intl}
          orderId={null}
          isNewOrder={true}
          orderData={data?.orderData}
          sessionData={data?.orderData?.session}
          isBtnLoading={true}
          isAutoPrint={show}
          isShowTitleButton
          hasTitle
          size="lg"
          isPaymentOnline
          taxData={current_shopping_cart?.taxData}
        ></PrintButton>
      </div>
      {isVatFail ? (
        <Badge
          rounded
          size="L"
          className={"w-100 p-4 h-100 mt-4"}
          status="blue"
          light={true}
        >
          <span className="d-flex align-items-center text-black">
            <Icon
              name={"info_filled"}
              size={"sm"}
              color="var(--blue600)"
              className={"mr-3"}
            />
            {intl.formatMessage({
              id: "carts.orders.In hóa đơn không kèm phát hành VAT do xảy ra lỗi bên đơn vị phát hành",
              defaultMessage:
                "In hóa đơn không kèm phát hành VAT do xảy ra lỗi bên đơn vị phát hành",
            })}
          </span>
        </Badge>
      ) : null}
    </div>
  );
  return <GlobalSimpleModal body={body} show={show} setShow={setShow} />;
}
