import { useApp } from 'contexts/app';
import { debounce, isArray, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from "react";
export function useGetDataAndLoadMore({ searchText, limit, isCallApi, isLoadingMore, getDataApi }) {

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecord] = useState(0);
  const [errors, setErrors] = useState(null)
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [searchTextState, setSearchTextState] = useState(searchText)
  const appContext = useApp();

  useEffect(() => {
    if (!isNil(searchText) && searchText !== searchTextState) {
      setSearchTextState(searchText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(debounce((searchTextState, isLoadingMore = false, _currentPage = 1) => {
    getData(searchTextState, isLoadingMore, _currentPage)
  }, 500), []);

  useEffect(() => {
    if (currentPage === 1) {
      debounceSearch(searchTextState, isLoadingMore, currentPage);
    }
  }, [currentPage])

  useEffect(() => {
    if (isLoadingMore) {
      let _currentPage = isLoadingMore ? currentPage + 1 : currentPage
      setCurrentPage(_currentPage);
      debounceSearch(searchTextState, isLoadingMore, _currentPage);
    } else {
      // let currentPage = 1;
      // setCurrentPage(currentPage);
      // debounceSearch(searchTextState, false, currentPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMore])

  useEffect(() => {
    // if (isCallBack) {
    //   setCurrentPage(1);
    //   debounceSearch(searchTextState, isLoadingMore);
    //   return;
    // }
    if (!isNil(searchTextState) && isCallApi) {
      setCurrentPage(1);
      debounceSearch(searchTextState, isLoadingMore);
    }
    if (!isCallApi) {
      setSearchTextState('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextState, isCallApi])

  useEffect(() => {
    setHasLoadMore(currentPage < Math.ceil(totalRecord / limit));
  }, [totalRecord, currentPage, limit])

  const clearData = () => {
    setData([]);
    setIsLoading(true);
  }

  const getData = async (searchTextState = '', isLoadingMore = false, currentPage) => {
    !isLoadingMore && setIsLoading(true);
    const text = encodeURIComponent(searchTextState.trim())
    const response = await getDataApi(appContext, text, currentPage, limit)
    !isLoadingMore && setIsLoading(false);
    if (isArray(response?.data?.data)) {
      const dataRes = response.data.data;
      setTotalRecord(response.data.totalRecord)
      isLoadingMore ? setData(prev => ([
        ...prev,
        ...dataRes
      ])) : setData(dataRes);
    } else {
      setErrors(response?.data?.errors)
    }
  }

  return { data, errors, isLoading, hasLoadMore, clearData, debounceSearch };
}

useGetDataAndLoadMore.propTypes = {
  searchText: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  isCallApi: PropTypes.bool,
  getDataApi: PropTypes.func
}