import { memo, useMemo, useState } from "react";
import EmptyState from "component-v2/empty-state";
import { useOrderDetailData } from "hooks/useOrderData";
import {
  useGetPrintEInvoiceV2,
  useGetEInvoicesOrderV2,
} from "hooks/useVatData";
import { Fragment, useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import OrderDetailCustomer from "./order-detail-customer";
import OrderDetailHeader from "./order-detail-header";
import OrderDetailProducts from "./order-detail-products";
import OrderDetailSummary from "./order-detail-summary";
import { VatComponent } from "./order-detail-vat";
import { OrderDetailAttributes } from "./order-detail-attributes";
import { getCheckoutSession } from "api/checkout-repository";
import { useApp } from "contexts/app";

export default memo(function OrderDetail({ handleCbData }) {
  const intl = useIntl();
  const { callAPI } = useApp();
  const { id: orderId } = useParams();
  const { data: order, isLoading, mutate } = useOrderDetailData(orderId);

  const [session, setSession] = useState(null);

  const eInvoiceUrl = useMemo(() => {
    if (!orderId) return null;
    return `/orders/${orderId}/einvoices`;
  }, [orderId]);

  const { data: eInvoiceData } = useGetEInvoicesOrderV2(eInvoiceUrl);
  let eInvoiceId =
    eInvoiceData?.data &&
    Array.isArray(eInvoiceData?.data) &&
    eInvoiceData?.data?.length > 0
      ? eInvoiceData?.data?.[0]?.id
      : null;

  const eInvoicePrintUrl = useMemo(() => {
    if (!orderId || !eInvoiceId) return null;
    return `/orders/${orderId}/einvoices/${eInvoiceId}/print`;
  }, [orderId, eInvoiceId]);

  const { data: eInvoiceDataPrint } = useGetPrintEInvoiceV2(eInvoicePrintUrl);

  const sessionId = useMemo(() => {
    if (!order?.orderId) return null;
    return order.checkoutToken;
  }, [order]);

  useEffect(() => {
    if (!sessionId) return;

    const getSessionDetail = async () => {
      try {
        const res = await getCheckoutSession(callAPI, sessionId);
        setSession(res);
      } catch (error) {
        setSession(null);
      }
    };

    getSessionDetail();
    return () => {
      setSession(null);
    };
  }, [sessionId]);

  useEffect(() => {
    let timeout = null;

    if (order) {
      if (order?.fulfilledStatus === 1 || order?.fulfilledStatus === 4) {
        timeout = setTimeout(() => {
          mutate();
        }, [2000]);
      }
      handleCbData({ ...order, eInvoiceData, eInvoiceDataPrint });
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, eInvoiceData, eInvoiceDataPrint]);

  if (!isLoading && !order) return <NoData intl={intl} />;
  return (
    <Fragment>
      {order ? (
        <Fragment>
          <div className="order-list--detail-header flex-column">
            <OrderDetailHeader
              order={order}
              mutate={mutate}
              session={session}
            />
          </div>
          <div className="order-list--detail-body">
            <div className="order-list--detail-items">
              <div className="order-list--detail-count d-flex justify-content-start align-items-center">
                <span className="font-weight-normal">
                  {intl.formatMessage(
                    {
                      id: "orders.searches.Người bán: {x}",
                      defaultMessage: "Người bán: {x}",
                    },
                    {
                      x: (
                        <span className="font-weight-medium">
                          {order.userName || "--"}
                        </span>
                      ),
                    },
                  )}
                </span>
              </div>
              <OrderDetailProducts order={order} session={session} />
            </div>
            <div className="order-list--detail-info d-flex flex-column justify-content-between h-100 scrollable-y">
              <>
                <OrderDetailCustomer order={order} />
                <VatComponent
                  order={{ ...order, eInvoiceData, eInvoiceDataPrint }}
                  mutate={mutate}
                />
                <OrderDetailAttributes order={order} onSucceed={mutate} />
              </>
              <OrderDetailSummary
                order={order}
                mutate={mutate}
                session={session}
              />
            </div>
          </div>
        </Fragment>
      ) : null}
      {isLoading && <LoadingCircle size="md" overlay />}
    </Fragment>
  );
});

function NoData({ intl }) {
  return (
    <LayoutCard className="flex-stretch">
      <EmptyState
        iconName="arrow_back"
        description="Chọn đơn hàng để xem chi tiết"
      />
    </LayoutCard>
  );
}
