import { getShifts } from "api/shift-repository";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useShift } from "contexts/shift/shift-context";
import { createContext, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import ShiftHeader from "./components/shift-header";
import ShiftAddTransactionModal from "./shift-detail/shift-add-transaction-modal";
import ShiftDetail from "./shift-detail/shift-detail";
import ShiftList from "./shift-list/shift-list";
import "./utils/index.css";

function ShiftRouter() {
  const layout = useLayoutContext();
  const appContext = useApp();
  const { checkShiftReady, shift } = useShift();
  const location = useRef(appContext.current_location);
  const [shifts, setShifts] = useState([]);
  const [current_shift, setCurrentShift] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [showAddTransModal, setShowAddTransModal] = useState(false);
  const limitReached = useRef(false);
  useEffect(() => {
    layout.setPageTitle("Quản lý ca");
    layout.setBreadcrumb([{ title: "Quản lý ca" }]);
    checkShiftReady(false);
    return () => {
      layout.setPageTitle("");
      layout.setBreadcrumb(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callGetShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, shift.status]);

  async function callGetShifts() {
    if (!location.current?.id) return;
    setLoading(true);
    const limit = 20;
    const data = await getShifts(appContext, location.current.id, page, limit);
    if (data?.length < limit) limitReached.current = true;
    if (page === 1) {
      setShifts(data);
      if (data?.length) setCurrentShift(data[0]);
    } else if (data && data.length) {
      let _shifts = [...shifts, ...data];
      setShifts(_shifts);
    }
    setLoading(false);
  }

  function callNextPage() {
    if (!location.current?.id) return;
    if (!loading && !limitReached.current) {
      setPage(page + 1);
    }
  }
  return (
    <ShiftContext.Provider
      value={{
        shifts,
        current_shift,
        setCurrentShift,
        callNextPage,
        loading,
        limitReached: limitReached.current,
        showAddTransactionModal: () => {
          setShowAddTransModal(true);
        },
      }}
    >
      <LayoutBody size="ne" flexRow>
        <ShiftHeader />
        <ShiftList />
        <ShiftDetail />
      </LayoutBody>
      {current_shift?.status === "opening" ? (
        <ShiftAddTransactionModal
          shift={current_shift}
          show={showAddTransModal}
          setShow={setShowAddTransModal}
          onSuccess={() =>
            checkShiftReady(false, () => {
              if (page > 1) setPage(1);
              else callGetShifts();
            })
          }
        />
      ) : null}
    </ShiftContext.Provider>
  );
}

export default ShiftRouter;

const ShiftContext = createContext({
  shifts: [],
  current_shift: null,
  setCurrentShift: (_shift) => {},
  callNextPage: () => {},
  loading: false,
  limitReached: false,
  showAddTransactionModal: () => {},
});

export const useShiftContext = () => {
  const context = useContext(ShiftContext);
  return context;
};
