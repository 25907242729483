import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import NumberShow from "component-v2/number-show";
import LotDateList from "component-v2/LotDate";
import { ImageCustom } from "component-v2/ImageCustom";
import {
  DiscountAllocationType,
  SysAttributeKeys,
} from "screens-v2/carts-v2/cart-helpers.service";
import Icon from "component-v2/icons/icons";

export default function OrderDetailProductItem({
  product,
  sessionLine,
  newCheckout,
}) {
  const intl = useIntl();
  const productRefundedQuantity =
    product.restockQuantity + product.returnedQuantity;
  const adviser = JSON.parse(
    product?.properties?.find(
      (x) => x.name?.toLowerCase() === SysAttributeKeys.SYS_LINE_ADVISER,
    )?.value || null,
  );
  let promotionName = "";
  let properties = getProperties(
    product.discountAllocations,
    product.properties,
  );
  const lineItemDiscount =
    (newCheckout &&
      product?.discountAllocations?.map((d) =>
        d.type === DiscountAllocationType.LINE_DISCOUNT && !d.name
          ? {
              ...d,
              name: "Giảm giá tùy chỉnh",
            }
          : d,
      )) ||
    properties ||
    [];
  if (properties) {
    promotionName = lineItemDiscount
      .map((element) => {
        if (element.name?.toLowerCase().startsWith("x-haravan-")) return null;
        let name = getName(element);
        return name;
      })
      .join(", ");
  }
  function getName(promotion) {
    let promotionName = promotion.name;
    if (promotion.value && promotion.value.length > 0) {
      let arrs = promotion.value.split(" - ");
      if (arrs.length > 1) {
        promotionName = arrs.slice(1).join(" ");
      }
    }
    return promotionName;
  }
  function getProperties(discountAllocations, properties) {
    const discountIds = discountAllocations?.map((x) => x.discountId) || [];
    let newProperties = [];
    properties =
      properties?.filter(
        (x) => x.name?.toLowerCase() !== SysAttributeKeys.SYS_LINE_ADVISER,
      ) || [];
    if (properties?.length) {
      properties.map((property) => {
        let arrs = property.value.split(" - ");
        let id = null;
        if (arrs.length >= 1) {
          id = Number(arrs[0]?.trim());
        }
        if (!id || discountIds.indexOf(id) === -1) {
          newProperties.push(property);
        }
      });
    }
    return newProperties;
  }

  let unit = product.unit ? `${product.unit}` : "";
  return (
    <div className="variant">
      <div className="d-flex">
        <div className="prod-img-variant">
          <ImageCustom src={product?.imageUrl} size="small" alt="haravaner" />
        </div>
        <div
          className="cart-product-item--info flex-fill flex-column ml-3 mb-3"
          style={{ minWidth: "175px" }}
        >
          <div className="item-details">
            <div className="d-flex justify-content-between">
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {`${product.productName}`}
              </p>
              {unit && (
                <div className="w-50 text-right">
                  <Badge light status={"gray"}>
                    {unit}
                  </Badge>
                </div>
              )}
            </div>
            <p className="text-secondary">
              {product.variantValue ? product.variantValue : "Default Title"}
            </p>
            {product?.barcode && (
              <p className="text-secondary">{product?.barcode}</p>
            )}
            {product.lots?.length > 0 ? (
              <div className="d-flex">
                <LotDateList
                  orderLine={product}
                  allowEdit={false}
                  showReturnQty
                />
              </div>
            ) : null}
            {/* <p className='text-label font-size-12'>{product.barcode ? `Barcode: ${product.barcode}` : product.barcode}</p> */}
            {productRefundedQuantity > 0 && (
              <p className="text-warning">
                {intl.formatMessage({
                  id: "orders.searches.Đã nhập kho",
                  defaultMessage: "Đã nhập kho",
                })}{" "}
                <NumberShow value={productRefundedQuantity} />
              </p>
            )}
            <p className="font-size-12 text-promotion">
              {!newCheckout && promotionName
                ? intl.formatMessage({
                    id: `settings.print-templates.${promotionName}`,
                    defaultMessage: promotionName,
                  })
                : null}
              {newCheckout && product.discountAllocations?.length
                ? product.discountAllocations
                    .map((discountAllocation) => {
                      let code = discountAllocation.code;
                      if (!code || !code?.length) {
                        code =
                          discountAllocation.type ===
                          DiscountAllocationType.LINE_DISCOUNT
                            ? "Giảm giá tùy chỉnh"
                            : discountAllocation.type;
                      }
                      return code;
                    })
                    .join(", ")
                : null}
            </p>
            {product.pricingListId && (
              <Badge className="badge-light-default mt-2">
                {intl.formatMessage(
                  {
                    id: "Áp dụng bảng giá {x}",
                    defaultMessage: "Áp dụng bảng giá {x}",
                  },
                  { x: <strong>{product.pricingListName}</strong> },
                )}
              </Badge>
            )}
            {newCheckout && properties.length
              ? properties.map((property) => {
                  return (
                    <p
                      key={`${product.id}-${property.name}`}
                      className="font-size-12"
                    >
                      <span>
                        <strong>{property.name}</strong>: {property.value}
                      </span>
                    </p>
                  );
                })
              : null}
            {adviser && (
              <div className="d-flex flex-auto l-mr-4 text-secondary">
                <Icon name="sticky_note_2" color="var(--contentTertiary)" />
                <p className="mr-2">{adviser.name}</p>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between l-mt-8">
            <div className="d-flex flex-column">
              <span className="text-default">
                <CurrencyShow
                  value={newCheckout ? product.linePrice : product.productPrice}
                />
              </span>
              {(newCheckout &&
                sessionLine?.price > 0 &&
                sessionLine?.price > sessionLine?.linePrice) ||
              (product.originalPrice > 0 &&
                product.originalPrice > product.productPrice) ? (
                <strike className="text-secondary">
                  <CurrencyShow
                    value={sessionLine?.price ?? product.originalPrice}
                  />
                  <br />
                </strike>
              ) : null}
            </div>
            <span>
              x <NumberShow value={product.quantity} />
            </span>
            <CurrencyShow
              className={"font-weight-medium"}
              value={newCheckout ? product.lineAmount : product.totalPrice}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
