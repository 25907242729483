import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';
import Icon from "component-v2/icons/icons";

import { UnitSelection2 } from "component-v2/UnitSelection/index_backup";

import CurrencyShow from "component-v2/currency-show";
import NumberShow from "component-v2/number-show";
import { Assets } from 'assets';
import { Tooltip, ImageResize } from '@haravan/react-components';
import Button from "component-v2/buttons";
import { ImageCustom } from "component-v2/ImageCustom";

export const ProductSearchItem = (props) => {
  const intl = useIntl();
  const { product, index } = props;
  const id = `search-product--item-${index}`;
  let ratio = product.selectedUnit ? (product.selectedUnit.ratio || 1) : 1;

  const handleClickProduct = () => {
    props.onClick(product);
  };

  const handleChangeVariantUnit = (unit) => {
    let _variant = { ...product };
    _variant.selectedUnit = unit;
    _variant.variantUnitId = unit.id;
    _variant.price = unit.price;
    _variant.sku = unit.sku;
    _variant.barcode = unit.barcode;
    props.onClick(_variant);
  };

  const handleOpenProductInfo = () => {
    window.open(`${window.location.origin}/products/${product.productId}/variants/${product.id}`, "_blank");
  };
  return <div id={id} tabIndex={0} onClick={handleClickProduct} className='list-group-item cart-product-search-item'>
    <div>
      <div className="cart-product-item--image">
        <ImageCustom
          src={product ? product.productImgUrl : null}
          alt="product image"
        />
      </div>
    </div>
    <div className="l-table--col-stretch">
      <div className="tbl-list-item">
        <div className="flex-stretch flex-column">
          <p className="font-weight-medium content-primary">{product.productName}</p>
          <p className="content-secondary l-mt-4">{product.variantTitle}</p>
          <p className="content-secondary l-mt-4">{product.barcode}</p>
        </div>
      </div>
    </div>
    <div>
      <div className="tbl-list-item">
        <div className='text-left' onClick={(e) => e.stopPropagation()}>
          <UnitSelection2 setMouseOverResult={props.setMouseOverResult} options={product.units} selectedOption={product.selectedUnit} onChange={(unit) => handleChangeVariantUnit(unit)} />
        </div>
      </div>
    </div>
    <div className="l-table--col-number">
      <CurrencyShow value={product.price} />
    </div>
    <div className="l-table--col-number text-nowrap">
      {!product.isTrackingInventory
        ? <span className='prod-tag d-flex-center content-primary' ><Icon name="infinity" size="md" />&nbsp;khả dụng</span>
        : <p className={`mr-2${product.quantity < 0 ? ' text-danger' : ''} content-primary`}><NumberShow value={product.quantity / ratio} />&nbsp;khả dụng</p>
      }
    </div>
    <div className="">
      <Tooltip placement="topRight" title={intl.formatMessage({ id: 'products.details.Xem chi tiết sản phẩm', defaultMessage: 'Xem chi tiết sản phẩm' })} overlayClassName="tooltip-custom">
        <span>
          <Button
            size="sm"
            plain
            icon="info"
            title=""
            iconColor={"var(--contentAccent"}
            onClick={handleOpenProductInfo}
            className={`l-ml-8 cart-search-icon `}
            disabled={!product.isTrackingInventory ? true : false} ></Button>
        </span>
      </Tooltip>
    </div>
  </div>
};


ProductSearchItem.propTypes = {
  onClick: PropTypes.func,
  onOpenDetail: PropTypes.func,
  searchText: PropTypes.string,
  products: PropTypes.object,
  index: PropTypes.any,
  isShowUnit: PropTypes.bool
};