import { forEach } from 'lodash';
import { enableScale } from 'utils/ScaleUtils';
import { callSearchProducts } from "api/product-repository";
import { ScaleBarcodeMode } from 'modules/product/constants';

export class ProductCartHelperService {
  setting = null
  constructor(_setting) {
    this.setting = _setting
  }

  getItemCartFormat = (product, variant) => {
    const productId = product.id;
    const variantId = variant.id;
    const productTitle = product.productName;
    const imageUrl = variant.imageUrl || product.url;

    const newOrderLine = {
      ...variant,
      selectedUnit: variant?.units?.[0] || null,
      variantUnitId: variant.variantUnitId || variant?.units?.[0]?.id || null,
      notAllowPromotion: product.notAllowPromotion,
      variantId,
      productId,
      productTitle,
      imageUrl
    };
    return newOrderLine;
  };

  getSetting = () => {
    let hideProductOutOfStock = this.setting.hideProductOutOfStock
    let allowOrderProductOutOfStock = this.setting.allowOrderProductOutOfStock

    return {
      hideOutStock: hideProductOutOfStock,
      allowOrderOutStock: allowOrderProductOutOfStock,
    };
  };

  searchOnBarcode = async (appContext, page = 1, limit = 10, barcode, lotNo) => {
    try {
      let location = appContext.current_location;
      const scale_enabled = enableScale(appContext.shop_info.scale_support)
      let result = await callSearchProducts(appContext, page, limit, barcode, location.id, null, null, null, null, scale_enabled);
      let products = result.data.data || [];
      let records = [];
      let variantQtt = 0;
      let indexVariant = 0;
      const { shop_info: { scale_barcode_mode } } = appContext;
      let _barcode = scale_enabled && barcode ? String(barcode).trim().substr(2, ScaleBarcodeMode.get(scale_barcode_mode || 1).barcodeLength) : barcode;
      forEach(products, (product) => {
        let variants = product.variants || [];
        // eslint-disable-next-line array-callback-return
        variants.map((variant, index) => {
          if (variant.barcode === _barcode) {
            variantQtt += 1;
            indexVariant = index;
            variant.notAllowPromotion = product.notAllowPromotion;
            variant.variantId = variant.id;
            variant.productVariantsId = variant.id;
            variant.productId = product.id;
            variant.productTitle = product.productName;
            variant.imageUrl = variant.imageUrl || product.url;
            variant.lotNo = lotNo;
            variant.actualQuantity = scale_enabled ? variant.actualQuantity : 1;
            variant.units = variant.units ?? [];
            variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
            records = [...records, ...variants];
          }
        });
      });
      return {
        records,
        variantQtt,
        indexVariant
      };
    } catch (error) {
      return null;
    }
  };
  validateRuleCart = (variant) => {
    let isApprove = true;
    // check setting Out stock
    if (!this.setting.allowOrderProductOutOfStock
      && variant.isTrackingInventory && variant.quantity <= 0) {
      isApprove = false;
    }
    return isApprove;
  };

};


