import React, { useCallback, useMemo, useRef, useState } from "react";
import "./index.css";
import { Input, Popover } from "@haravan/react-components";
import { useAddressSuggestions } from "hooks/useAddressData";
import { useWindowSize } from "hooks/useWindowSize";
import { debounce } from "lodash";
import { getZoomTarget } from "utils/Commons";
import { getChromeVersion } from "infrastructures/utils";
export const isArrNullOrHaveNoItem = (arr) => {
  if (!arr || !arr.length) return true;
  return false;
};
export function InputAddressSuggestion(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(props.value)
  const { suggestions } = useAddressSuggestions({
    countryId: 241,
    query: searchValue,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    []
  )
  const handleOnchange = (value) => {
    debounceOnChange(value);
    props.onChange({ address: value });
  }
  const refInput = useRef(null);
  const { width: widthWindow, height } = useWindowSize();
  const zoomTarget = useMemo(() => getZoomTarget(widthWindow), [widthWindow])
  const chromeVer = getChromeVersion();
  const newZoomStandard = chromeVer && chromeVer >= 128 ? zoomTarget : 1;
  const parentPositionInput = useMemo(() => {
    return refInput?.current?.ref_input?.parentElement?.getBoundingClientRect();
  }, [refInput?.current, widthWindow, height]);
  const renderBody = () => {
    return (
      <div className="address-result-dropdown">
        {suggestions?.data?.map((option, index) => {
          return (
            <div
              key={index}
              className="suggestion-item"
              onClick={() => {
                props.onChange(option);
                setIsOpen(false);
              }}
            >
              {option?.address}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="l-section border-0 p-0 position-relative l-pb-8">
      <Popover
        onVisibleChange={(visible) => setIsOpen(visible)}
        placement="bottomRight"
        visible={!!suggestions?.data?.length && isOpen}
        content={
          <div
            style={{
              position: "fixed",
              top: (parentPositionInput?.top + parentPositionInput?.height) / newZoomStandard || 0,
              left: parentPositionInput?.left / newZoomStandard || 0,
              width: parentPositionInput?.width / newZoomStandard,
              zIndex: 100,
            }}
          >
            {renderBody()}
          </div>
        }>
        <Input
          disabled={props?.disabled}
          placeholder={"Nhập địa chỉ"}
          id={"billing"}
          value={props.value}
          onChange={(value) => handleOnchange(value)}
          ref={refInput}
        />
      </Popover>

    </div>
  );
}
