import { Loading } from "@haravan/react-components";
import { FormattedMessage } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import NumberShow from "component-v2/number-show";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import { Assets } from "assets";

export default function TopProductReport({ dataSelling, isLoading }) {
  const renderListProduct = (dataSelling) => {
    let result = null;
    if (dataSelling && dataSelling.length) {
      result = dataSelling.map((item, index) => {
        if (item.ProductId !== "shipping" && item.Quantity !== 0) {
          return (
            <tr key={index}>
              <th
                style={{ maxWidth: "300px" }}
                className="padding-left-16"
                scope="row"
              >
                {item.ProductId !== "--" ? (
                  item.ProductId || (
                    <FormattedMessage
                      id="reports.Sản phẩm tùy chọn"
                      defaultMessage="Sản phẩm tùy chọn"
                    />
                  )
                ) : (
                  <FormattedMessage
                    id="reports.Sản phẩm tùy chọn"
                    defaultMessage="Sản phẩm tùy chọn"
                  />
                )}
                {item.VariantId !== "--" && (
                  <p className="variant-name">{item.VariantId}</p>
                )}
              </th>
              <td className="text-right padding-right-16">
                <NumberShow value={item.Quantity} />
              </td>
              <td className="text-right padding-right-16">
                <NumberShow value={item.Inventory} />
              </td>
              <td className="text-right padding-right-16">
                <CurrencyShow value={item.GrossSaleAmount} />
              </td>
              <td className="text-right padding-right-16">
                <CurrencyShow value={item.DiscountAmount} />
              </td>
              <td className="text-right padding-right-16">
                <CurrencyShow value={item.NetSaleAmount} />
              </td>
            </tr>
          );
        } else {
          return null;
        }
      });
    } else {
      result = (
        <tr>
          <td colSpan="8">
            <div className="text-center p-4">
              <img src={Assets.icon.list_empty} alt="no-data" />
              <p className="content-secondary mt-3">
                <FormattedMessage
                  id="reports.Không có dữ liệu!"
                  defaultMessage="Không có dữ liệu!"
                />
              </p>
            </div>
          </td>
        </tr>
      );
    }
    return result;
  };

  if (isLoading) {
    return (
      <div className="col-12  mb-16">
        <LayoutCard>
          <div
            style={{ height: "450px" }}
            className="report__details d-flex justify-content-center align-items-center"
          >
            <Loading />
          </div>
        </LayoutCard>
      </div>
    );
  } else {
    return (
      <div className="col-12 mb-16">
        <LayoutCard className="report__details pd-16">
          <table className="table table-hover table-bordered report-product-table">
            <thead>
              <tr>
                <th className="text-left text-secondary padding-right-16">
                  <FormattedMessage
                    id="reports.Tên sản phẩm"
                    defaultMessage="Tên sản phẩm"
                  />
                </th>
                <th className="text-right text-secondary padding-right-16">
                  <FormattedMessage
                    id="reports.Số sản phẩm"
                    defaultMessage="Số sản phẩm"
                  />
                </th>
                <th className="text-right text-secondary padding-right-16">
                  <FormattedMessage
                    id="products.details.Khả dụng"
                    defaultMessage="Khả dụng"
                  />
                </th>
                <th className="text-right text-secondary padding-right-16">
                  <FormattedMessage
                    id="reports.Doanh thu"
                    defaultMessage="Doanh thu"
                  />
                </th>
                <th className="text-right text-secondary padding-right-16">
                  <FormattedMessage
                    id="reports.Giảm giá"
                    defaultMessage="Giảm giá"
                  />
                </th>
                <th className="text-right text-secondary padding-right-16">
                  <FormattedMessage
                    id="reports.Doanh thu thuần"
                    defaultMessage="Doanh thu thuần"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {renderListProduct(
                dataSelling && dataSelling.table ? dataSelling.table : null,
              )}
            </tbody>
          </table>
        </LayoutCard>
      </div>
    );
  }
}
