import { memo, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import SettingMenu from "./components/SettingMenu";
import "./style.css";
import UserSetting from "./components/UserSetting";
import GeneralSetting from "./components/GeneralSetting";
import SaleSetting from "./components/SaleSetting";
import ShiftSetting from "./components/ShiftSetting";
import BillSetting from "./components/BillSetting";
import { useIntl } from "react-intl";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import { CONSTANT_LINKS } from "models/links";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";

function Settings() {
  const { refreshShopSetting } = useApp();
  const intl = useIntl();
  const layout = useLayoutContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const func = async () => {
      layout.setBreadcrumb([
        {
          title: intl.formatMessage({
            id: "menus.Cấu hình",
            defaultMessage: "Cấu hình",
          }),
          link: null,
        },
      ]);
      await refreshShopSetting();
      setLoading(false);
    };
    func();
    return () => {
      layout.setBreadcrumb(null);
      layout.setPageTitle("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    layout.setPageTitle(
      intl.formatMessage({ id: "menus.Cấu hình", defaultMessage: "Cấu hình" }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);
  if (loading)
    return (
      <LayoutBody flexRow className="border-top setting-body-layout">
        <LoadingCircle size="md" className="m-auto" />
      </LayoutBody>
    );
  return (
    <LayoutBody size="ne" flexRow className="border-top setting-body-layout">
      <div className="settings-navigate">
        <LayoutCard className="d-flex flex-column">
          <SettingMenu />
        </LayoutCard>
      </div>
      <div className="settings-body flex-column">
        <Route
          path={CONSTANT_LINKS.route_setting_user}
          component={UserSetting}
        />
        <Route
          path={CONSTANT_LINKS.route_setting_general}
          component={GeneralSetting}
        />
        <Route
          path={CONSTANT_LINKS.route_setting_sale}
          component={SaleSetting}
        />
        <Route
          path={CONSTANT_LINKS.route_setting_shift}
          component={ShiftSetting}
        />
        <Route
          path={CONSTANT_LINKS.route_setting_bill}
          render={() => <BillSetting />}
        />
      </div>
    </LayoutBody>
  );
}

export default memo(Settings);
