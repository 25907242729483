import { Textarea } from "@haravan/react-components";
import {
  getActivityList,
  getShiftClosed,
  openShift,
} from "api/shift-repository";
import Banner from "component-v2/banners";
import { toNumber } from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { CurrencyUtils } from "utils";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app/index";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import { InputNumber } from "screens-v2/carts-v2/RightCart/components/InputNumber";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";

export default function OpenShiftModal({
  show,
  setShow,
  shiftCashTracking,
  onSucceeded,
  warning,
}) {
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  const appContext = useApp();
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [note, setNote] = useState("");
  const [ready, setReady] = useState(false);
  const [users, setUsers] = useState([
    { id: appContext.auth.user.id, name: appContext.auth.user.name },
  ]);
  const [assignShiftManager, setAssignShiftManager] = useState(null);
  useEffect(() => {
    async function init() {
      setReady(false);
      await appContext.refreshShopSetting();
      //get id shift close
      let old_shift = await getShiftClosed(
        appContext,
        appContext.current_location?.id,
      );
      if (old_shift) {
        const response = await getActivityList(appContext, old_shift.id);
        let _activities = response?.data?.reverse();
        if (!_activities.length) return;
        let shiftCashAmount = 0;
        shiftCashAmount =
          _activities && _activities[0] && _activities[0].shiftCashAmount;
        if (shiftCashAmount > 0) {
          setAdvanceAmount(shiftCashAmount);
          setNote(
            intl.formatMessage({
              id: "carts.orders.Tiền mặt chưa rút từ ca bán hàng trước",
              defaultMessage: "Tiền mặt chưa rút từ ca bán hàng trước",
            }),
          );
        }
      }
      setReady(true);
    }
    setAdvanceAmount(0);
    setNote("");
    if (show) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (appContext.staff_users?.length) {
      setUsers(appContext.staff_users);
      let userLogin = appContext.staff_users.find(
        (x) => x.id === appContext.auth.user.id,
      );
      setAssignShiftManager(userLogin);
    }
  }, [appContext.staff_users]);

  const onClose = (value) => {
    setNote("");
    setAdvanceAmount(0);
    setShow(value);
  };
  const handleAmountChanged = (value) => {
    value = value.toString();
    if (value.length === 0) value = 0;
    if (value[0] === "0" && value.length > 0) {
      value = value.substring(1);
    }
    let num = toNumber(CurrencyUtils.numberWithCommasActive(value));
    setAdvanceAmount(num);
  };

  const handleSubmitOpenShift = async () => {
    let shiftManager = null;
    if (
      appContext.shop_setting?.allowAssignShiftManager &&
      assignShiftManager
    ) {
      shiftManager = {
        id: assignShiftManager.id,
        userName: assignShiftManager.name,
        email: assignShiftManager.email,
      };
    }
    let result = await openShift(
      appContext,
      appContext.current_location?.id,
      appContext.current_location?.name,
      advanceAmount,
      note,
      shiftManager,
    );
    if (!result?.data || result?.error) {
      showGlobalToast("error", result?.message);
    } else {
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: "notifications.Mở ca thành công. Bắt đầu bán hàng!",
          defaultMessage: "Mở ca thành công. Bắt đầu bán hàng!",
        }),
      );
      onSucceeded?.(result?.data);
    }
  };
  const body = () => {
    const currentLocation = appContext.current_location || {};
    const locationName =
      currentLocation && currentLocation.locationName
        ? currentLocation.locationName
        : "--";
    return (
      <div>
        {ready ? null : <LoadingCircle overlay size="lg" />}
        {warning ? (
          <Banner status="warning" light title={warning} className="l-mb-16" />
        ) : null}
        <div className="l-table plain">
          <div>
            <div className="text-nowrap">
              {intl.formatMessage({
                id: "carts.orders.Chi nhánh",
                defaultMessage: "Chi nhánh",
              })}
              :
            </div>
            <div>{locationName}</div>
          </div>
          <div>
            <div className="text-nowrap">
              {intl.formatMessage({
                id: "carts.orders.Nhân viên",
                defaultMessage: "Nhân viên",
              })}
              :
            </div>
            <div>{appContext.auth.user.name}</div>
          </div>
          <div>
            <div className="text-nowrap">Email:</div>
            <div>{appContext.auth.user.email}</div>
          </div>
          {appContext.shop_setting?.allowAssignShiftManager && users?.length ? (
            <div>
              <div className="text-nowrap">Quản lý:</div>
              <div>
                <SelectionCustom
                  value={assignShiftManager?.id}
                  options={users}
                  idField="id"
                  valueField="name"
                  onChange={(value) => {
                    if (value !== assignShiftManager.id) {
                      let user = users.find((x) => x.id === value);
                      setAssignShiftManager(user);
                    }
                  }}
                  style={{ maxWidth: "280px", minWidth: "280px" }}
                >
                  {appContext.staff_users?.map((u) => {
                    return (
                      <SelectionCustom.Option key={u.id} value={u.id}>
                        <div className="d-flex align-items-center">
                          {u.name}
                        </div>
                      </SelectionCustom.Option>
                    );
                  })}
                </SelectionCustom>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          {shiftCashTracking && (
            <div className="l-mt-16">
              <label>
                {intl.formatMessage({
                  id: "carts.orders.Tiền tạm ứng đầu ca",
                  defaultMessage: "Tiền tạm ứng đầu ca",
                })}
              </label>
              <InputNumber
                onChange={(value) => handleAmountChanged(value)}
                value={advanceAmount}
                disabled={!ready}
                autoFocus={true}
              />
            </div>
          )}
          <div className="l-mt-16">
            <label>
              {intl.formatMessage({
                id: "carts.orders.Ghi chú",
                defaultMessage: "Ghi chú",
              })}
            </label>
            <Textarea
              onChange={(value) => setNote(value)}
              value={note}
              rows={2}
              placeholder={intl.formatMessage({
                id: "carts.orders.Nhập ghi chú cho ca bán hàng",
                defaultMessage: "Nhập ghi chú cho ca bán hàng",
              })}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <GlobalModal
      body={body()}
      show={show}
      setShow={onClose}
      onCancel={() => setShow(false)}
      loading={!ready}
      onConfirm={() => handleSubmitOpenShift()}
      headerTitle={intl.formatMessage({
        id: "carts.orders.Mở ca bán hàng",
        defaultMessage: "Mở ca bán hàng",
      })}
    />
  );
}
