import React, { useContext, useRef, useState } from "react";
import CloseShiftModal from "layouts/components/close-shift-modal";
import OpenShiftModal from "layouts/components/open-shift-modal";
import { GetCurrentShift } from "./utils";
import { useApp } from "contexts/app";

const context = React.createContext();

function ShiftProvider({ children }) {
  const appContext = useApp();
  const [shift, setShift] = useState({ current_shift: null, status: "empty" });

  const [showCloseShift, setShowCloseShift] = useState(false);
  const [showOpenShift, setShowOpenShift] = useState(false);
  const [warning_message, setWarningMessage] = useState("");
  const open_shift_needed = useRef(false);
  const [loading, setLoading] = useState(false);

  async function checkShiftOutdated() {
    setLoading(true);
    let _current_shift = await GetCurrentShift(
      appContext,
      appContext.current_location?.id,
    );
    let _shift = null;
    let _status = "empty";
    setLoading(false);

    if (_current_shift) {
      _shift = _current_shift;
      // kiểm tra ca còn hạn hay không
      const now = new Date();
      const shiftOpenAt = new Date(_shift.openAt);
      const hours = Math.floor(Math.abs(now - shiftOpenAt) / 36e5);
      const isSameDay =
        now.getFullYear() === shiftOpenAt.getFullYear() &&
        now.getMonth() === shiftOpenAt.getMonth() &&
        now.getDate() === shiftOpenAt.getDate();
      // - nếu còn hạn:
      if (!isSameDay || hours >= 18) _status = "outdated";
      else _status = "ready";
    }
    // shift: thông tin Ca hiện tại
    // status:
    // - 'empty': chưa có ca được mở
    // - 'outdated': ca đã hết hạn
    // - 'ready': ca sẵn sàng
    return { shift: _shift, status: _status };
  }

  function checkShiftReady(doOpenNewShift = false, callBack) {
    // không có setting quản lý ca:
    if (!appContext.shop_setting.shiftEnable) {
      callBack?.();
      return true;
    }
    checkShiftOutdated().then((check) => {
      setShift({ current_shift: check.shift, status: check.status });
      if (check.status === "ready") {
        callBack?.();
        return true;
      } else if (doOpenNewShift) {
        if (check.status === "outdated") {
          closeShift(
            "Chưa đóng ca cũ, vui lòng đóng ca cũ và mở ca mới để tiếp tục xử lý đơn hàng",
          );
          open_shift_needed.current = true;
        }
        if (check.status === "empty")
          openShift(
            "Chưa có ca được mở, vui lòng mở ca để tiếp tục xử lý đơn hàng",
          );
      }
    });
    return false;
  }

  function openShift(warning_message = "") {
    setWarningMessage(warning_message);
    setShowOpenShift(true);
  }
  function closeShift(warning_message = "") {
    setWarningMessage(warning_message);
    setShowCloseShift(true);
  }
  return (
    <context.Provider
      value={{
        shift,
        checkShiftReady,
        openShift: (warning_message = "") => openShift(warning_message),
        closeShift: (warning_message = "") => closeShift(warning_message),
        loading,
      }}
    >
      {children}
      {appContext.app_ready ? (
        <CloseShiftModal
          warning={warning_message}
          shift={shift.current_shift}
          shiftCashTracking={appContext.shop_setting?.shiftCashTracking}
          show={showCloseShift}
          setShow={setShowCloseShift}
          onSucceeded={() => {
            setShowCloseShift(false);
            setShift({ current_shift: null, status: "empty" });
            if (open_shift_needed.current) setShowOpenShift(true);
            open_shift_needed.current = false;
          }}
        />
      ) : null}
      {appContext.app_ready ? (
        <OpenShiftModal
          warning={warning_message}
          shiftCashTracking={appContext.shop_setting?.shiftCashTracking}
          show={showOpenShift}
          setShow={setShowOpenShift}
          onSucceeded={(_shift) => {
            setShowOpenShift(false);
            setShift({ current_shift: _shift, status: "ready" });
          }}
        />
      ) : null}
    </context.Provider>
  );
}
const useShift = () => {
  const app = useContext(context);
  return app;
};

export { useShift };
export default ShiftProvider;
