import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  toFormattedCurrency,
  toFormattedNumber,
} from "infrastructures/format-helpers";
import { forwardRef, useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useCartContext } from "..";
import { ModalDiscountOrder } from "./components/modalDiscountOrder";
import { ModalEditShippingFee } from "./components/modalEditShippingFee";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { sumBy } from "lodash";
import { PaymentMethods } from "./Payments/paymentMethods";
import CouponBadge from "./components/couponBadge";
import { DiscountAllocationType } from "../cart-helpers.service";

export const PaymentSummary = forwardRef(
  ({ totalMoneyPaidByCustomer }, ref) => {
    const {
      shop_setting,
      checkNotAllowUseCouponCodeWithLoyaltyDiscount,
      shop_info,
    } = useApp();
    const intl = useIntl();
    useLayoutContext();
    const [editShippingFee, setEditShippingFee] = useState(false);
    const [isDiscountOrder, setIsDiscountOrder] = useState(false);
    const { current_shopping_cart, updateCurrentSession } = useCartContext();
    const [showMoreVat, setShowMoreVat] = useState(true);

    useEffect(() => {
      if (current_shopping_cart?.vatValue) {
        if (
          current_shopping_cart?.vatValue?.sumNotIncludedVat <= 0 &&
          current_shopping_cart?.vatValue?.sumIncludedVat > 0
        ) {
          setShowMoreVat(true);
        }
      }
    }, [current_shopping_cart?.vatValue]);
    const orderDiscounts = current_shopping_cart?.discountAllocations?.filter(
      (d) =>
        d.type === DiscountAllocationType.MANUAL_ORDER ||
        d.type === DiscountAllocationType.ORDER ||
        d.type === DiscountAllocationType.SHIPPING,
    );

    const orderDiscountAmount = useMemo(() => {
      if (orderDiscounts?.length) {
        return orderDiscounts?.reduce((sum, cur) => (sum += cur.amount), 0);
      }
      return 0;
    }, [orderDiscounts]);

    if (!current_shopping_cart?.summaries) {
      return null;
    }
    const { summaries, discountAllocations } = current_shopping_cart;
    const shippingDiscount = discountAllocations?.find(
      (d) => d.type === DiscountAllocationType.SHIPPING,
    );

    const redeemRender = () => {
      if (!current_shopping_cart?.loyalty) {
        return null;
      }
      const { redeemConfig, redeemUsedAmount, rewardRedeemAmount } =
        current_shopping_cart?.loyalty;

      if (
        current_shopping_cart?.loyalty_program &&
        current_shopping_cart?.loyalty_program?.used_amount > 0 &&
        current_shopping_cart?.loyalty_program?.type
      ) {
        const isDisableRedeemButton = !rewardRedeemAmount;
        if (
          current_shopping_cart?.loyalty_program?.type === "membership" &&
          checkNotAllowUseCouponCodeWithLoyaltyDiscount() &&
          rewardRedeemAmount !== 1
        ) {
          const isLoyalCustomer =
            current_shopping_cart?.loyalty_program?.program_name ===
            "Chương trình khách hàng thân thiết";

          return (
            <div className="l-pt-4 l-pb-4 d-flex justify-content-between">
              <div
                title={`${
                  isLoyalCustomer
                    ? intl.formatMessage({
                        id: "loyalties.Khách hàng thân thiết",
                        defaultMessage: "Khách hàng thân thiết",
                      })
                    : current_shopping_cart?.loyalty_program?.program_name
                }`}
                className="d-flex align-items-center content-accent min-w-0 l-pr-16"
              >
                <div className="text-truncate">
                  {isLoyalCustomer
                    ? intl.formatMessage({
                        id: "loyalties.Khách hàng thân thiết",
                        defaultMessage: "Khách hàng thân thiết",
                      })
                    : current_shopping_cart?.loyalty_program?.program_name}
                </div>
                <span>
                  ({current_shopping_cart?.loyalty_program?.discount}%){" "}
                </span>
                <p
                  className="cursor-pointer ml-2"
                  onClick={() => {
                    updateCurrentSession({
                      orderDiscountSelected:
                        current_shopping_cart.orderDiscountSelected,
                      ignoreLoyalty: true,
                    });
                  }}
                >
                  <Icon name="close" className="d-flex" />
                </p>
              </div>
              <span>
                {toFormattedCurrency(
                  current_shopping_cart?.loyalty_program?.used_amount,
                )}
              </span>
            </div>
          );
        } else if (
          current_shopping_cart?.loyalty_program?.type === "rewards" &&
          rewardRedeemAmount
        ) {
          return (
            <div className="l-pt-4 l-pb-4 d-flex justify-content-between">
              <div className="d-flex align-items-center content-accent flex-stretch min-w-0 l-pr-16">
                <p
                  className={`text-line-1 text-truncate ${
                    !isDisableRedeemButton
                      ? "content-accent pointer"
                      : "content-accent"
                  }`}
                  title={current_shopping_cart?.loyalty_program?.program_name}
                >
                  {current_shopping_cart?.loyalty_program?.program_name}
                </p>
                {!isDisableRedeemButton && (
                  <p
                    className="cursor-pointer ml-2"
                    onClick={() => {
                      updateCurrentSession({
                        orderDiscountSelected:
                          current_shopping_cart.orderDiscountSelected,
                        loyalty: {
                          ...current_shopping_cart.loyalty,
                          rewardRedeemAmount: 0,
                        },
                      });
                    }}
                  >
                    <Icon name="close" className="d-flex" />
                  </p>
                )}
              </div>
              <span>
                {toFormattedCurrency(
                  current_shopping_cart?.loyalty_program?.used_amount || 0,
                )}
              </span>
            </div>
          );
        } else {
          return null;
        }
      }
      return null;
    };

    let discountValue = "";
    let newDiscountCode = "";
    if (current_shopping_cart?.orderDiscountSelected) {
      const { discountTypeName, discountPercent, discountCode } =
        current_shopping_cart?.orderDiscountSelected;
      if (current_shopping_cart?.loyalty?.discountCouponLoyalty) {
        const { discountCouponLoyalty } = current_shopping_cart?.loyalty;
        const codeDiscount4 = discountCouponLoyalty.slice(
          -3,
          discountCouponLoyalty.length,
        );
        newDiscountCode = "********" + codeDiscount4;
        discountValue =
          discountCouponLoyalty === "" ? null : `LOY${newDiscountCode}`;
      } else {
        discountValue =
          discountTypeName === "percent"
            ? `${discountPercent}%`
            : discountTypeName === "coupon"
              ? discountCode
              : "";
      }
    }

    const renderVatCondition = (shipping_discover, products = [], vatValue) => {
      let content = null;
      // trường hợp cả phí vận chuyển && phí mặc định đều có
      if (vatValue?.sumNotIncludedVat <= 0 && vatValue?.sumIncludedVat > 0) {
        content = (
          <div className="tax-price-detail">(đã bao gồm trong giá)</div>
        );
        return content;
      } else if (
        vatValue?.sumNotIncludedVat >= 0 &&
        vatValue?.sumIncludedVat <= 0
      ) {
        // giá chưa bao gồm thuế
        return content;
      } else {
        content = (
          <div className="tax-price-detail">
            {!!vatValue.sumIncludedVat && vatValue.sumIncludedVat > 0 && (
              <div className="d-flex flex-row justify-content-between">
                <span>Thuế đã bao gồm trong giá</span>
                <span>{toFormattedCurrency(vatValue.sumIncludedVat)}</span>
              </div>
            )}
            {!!vatValue.sumNotIncludedVat && vatValue.sumNotIncludedVat > 0 && (
              <div className="d-flex flex-row justify-content-between">
                <span>Thuế phải trả</span>
                <span>{toFormattedCurrency(vatValue.sumNotIncludedVat)}</span>
              </div>
            )}
          </div>
        );
      }

      return content;
    };

    const vatRender = () => {
      if (!shop_info.taxable) return null;
      let products = current_shopping_cart?.lineItems;
      const shipping_discover = current_shopping_cart.shippingValue;

      // if (!products || products?.length === 0) return null;
      let vatValue = current_shopping_cart.vatValue;

      const isVatSameCondition =
        vatValue.sumIncludedVat > 0 && vatValue.sumNotIncludedVat > 0;

      return (
        <>
          <div className="l-pt-4 l-pb-4 d-flex justify-content-between">
            <div className="d-flex flex-row">
              <p>VAT</p>
              {isVatSameCondition && (
                <span
                  onClick={() => {
                    setShowMoreVat((prev) => !prev);
                  }}
                  className="l-ml-4 cursor-pointer"
                >
                  {showMoreVat ? (
                    <Icon
                      name="keyboard_arrow_down"
                      color="var(--coolgray600)"
                    />
                  ) : (
                    <Icon
                      className={"rotate--90"}
                      color="var(--coolgray600)"
                      name="keyboard_arrow_down"
                    />
                  )}
                </span>
              )}
            </div>
            {/* <p>{toFormattedCurrency(summaries.subtotal)}</p> */}
            <p>
              {current_shopping_cart?.lineItems?.length > 0
                ? toFormattedCurrency(vatValue.vatSum)
                : 0}
            </p>
          </div>
          {current_shopping_cart?.lineItems?.length > 0 &&
          showMoreVat &&
          (isVatSameCondition || vatValue.vatSum > 0)
            ? renderVatCondition(shipping_discover, products, vatValue)
            : null}
        </>
      );
    };

    return (
      <div className="carts-summary" ref={ref}>
        <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
          <div className="d-flex justify-content-start align-items-center">
            <p>Tổng tiền hàng</p>
            <Badge
              className="l-ml-8 border-0 font-weight-semibold"
              light
              status={"gray"}
            >
              {`${toFormattedNumber(
                sumBy(
                  current_shopping_cart?.lineItems,
                  (item) => item.quantity,
                ) || 0,
              )} sản phẩm`}
            </Badge>
          </div>
          <p>{toFormattedCurrency(summaries.subtotal)}</p>
        </div>

        {shop_setting.shippingPriceEnable ? (
          <div className="l-pt-4 l-pb-4 d-flex justify-content-between">
            <p
              className={`${
                current_shopping_cart?.lineItems?.length ? "content-accent" : ""
              }  cursor-pointer`}
              onClick={() => {
                current_shopping_cart?.lineItems?.length &&
                  setEditShippingFee(true);
              }}
            >
              Phí vận chuyển
            </p>
            {/* {
            shippingDiscount ? <span className="d-flex">
              <del className="content-tertiary mr-1">{toFormattedCurrency(summaries.shipping_fee)}</del>
              <p>{toFormattedCurrency(summaries.shipping_fee - shippingDiscount?.amount)}</p>
            </span>
              : <p>{toFormattedCurrency(summaries.shipping_fee)}</p>
          } */}
            <span className="d-flex">
              <p>{toFormattedCurrency(summaries.shipping_fee)}</p>
            </span>
          </div>
        ) : null}
        {!!current_shopping_cart?.lineItems?.length && (
          <div className="l-pt-4 l-pb-4 d-flex flex-column justify-content-between">
            <p
              className={`content-accent cursor-pointer d-flex`}
              onClick={() => {
                current_shopping_cart?.lineItems?.length &&
                  setIsDiscountOrder(true);
              }}
            >
              Khuyến mãi
            </p>
            <div className="d-inline-flex flex-wrap">
              {!!current_shopping_cart?.discountAllocations?.length
                ? current_shopping_cart?.discountAllocations
                    ?.filter(
                      (d) =>
                        d.type !== DiscountAllocationType.MANUAL_ORDER &&
                        d.type !== DiscountAllocationType.MEMBERSHIP &&
                        d.type !== DiscountAllocationType.REDEEM_POINT,
                    )
                    ?.map((coupon) => {
                      return (
                        <CouponBadge
                          key={coupon.name}
                          className={"l-pr-8 l-mr-8 mt-2"}
                          couponLabel={coupon.name}
                          iconBackground={
                            coupon.type === DiscountAllocationType.SHIPPING
                              ? "var(--teal400)"
                              : "var(--orange300)"
                          }
                          badgeIcon={
                            coupon.type === DiscountAllocationType.SHIPPING
                              ? "local_shipping"
                              : "card_giftcard"
                          }
                        />
                      );
                    })
                : ""}
            </div>
          </div>
        )}
        {orderDiscountAmount !== 0 && (
          <div className="l-pt-4 l-pb-4 d-flex justify-content-between">
            <p className={"cursor-pointer d-flex"}>Giảm giá đơn hàng</p>
            <p>{toFormattedCurrency(orderDiscountAmount || 0)}</p>
          </div>
        )}
        {redeemRender()}
        {vatRender()}
        <div className="l-pt-4 d-flex justify-content-between font-weight-semibold">
          <p>Khách cần trả</p>
          <p className="font-size-16">
            {toFormattedCurrency(summaries.total_payment)}
          </p>
        </div>
        {!!current_shopping_cart?.lineItems?.length && (
          <hr className="my-0 l-mt-8 border-dotted w-100" />
        )}
        <PaymentMethods />
        <ModalEditShippingFee
          show={editShippingFee}
          setShow={setEditShippingFee}
        />
        <ModalDiscountOrder
          show={isDiscountOrder}
          setShow={setIsDiscountOrder}
        />
      </div>
    );
  },
);
