import { forEach, find, trim, findIndex } from "lodash";
import {
  callSearchProducts,
  getCollectionAPI,
  getProductTagAPI,
  getProductTypeAPI,
  getVendorAPI,
  getDetail,
} from "api/product-repository";
import { enableScale } from "utils/ScaleUtils";

export class ProductService {
  //#region get func
  getDefaultValue = () => {
    return {
      hasNextPage: false,
      recordsCollect: [],
      currentPage: 1,
      totalPage: 0,
      total: 0,
    };
  };

  getDefaultProductValue = () => {
    return {
      hasNextPage: false,
      records: [],
      currentPage: 1,
      totalPage: 0,
      total: 0,
    };
  };

  async getCollectionsProductType(appContext) {
    try {
      const result = await getProductTypeAPI(appContext);
      if (result.errors) throw result.errorCodes;
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async getCollectionsProductVendors(appContext) {
    try {
      const result = await getVendorAPI(appContext);
      if (result.errors) throw result.errorCodes;
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async getCollectionsProductTags(appContext) {
    try {
      const result = await getProductTagAPI(appContext);
      if (result.errors) throw result.errorCodes;
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async getCollection(appContext, page = 1, limit = 10) {
    try {
      let result = await getCollectionAPI(appContext, page, limit);
      let temp = this.getDefaultValue();
      let total = result.data.totalRecord || 0;
      temp.total = total;
      temp.recordsCollect = result.data.data || [];
      temp.hasNextPage = total > page * limit;
      temp.currentPage = page;
      temp.totalPage = Math.ceil(total / limit);
      return temp;
    } catch (error) {
      return null;
    }
  }

  async getProducts(appContext, filter = {}, page = 1, limit = 20) {
    try {
      const location = appContext.current_location;

      let result = await callSearchProducts(
        appContext,
        page,
        limit,
        "",
        location.id,
        filter.groupFilter,
        filter.typeFilter,
        filter.vendorFilter,
        filter.tagFilter
      );
      let temp = this.getDefaultProductValue();
      let total = result.data.totalRecord || 0;
      temp.total = total;
      temp.records = result.data.data || [];
      temp.hasNextPage = total > page * limit;
      temp.currentPage = page;
      temp.totalPage = Math.ceil(total / limit);
      return temp;
    } catch (error) {
      return null;
    }
  }

  async getDetailProduct(appContext, productID, variantID = "") {
    try {
      const result = await getDetail(appContext, productID);
      if (result?.data?.productVariants?.length) {
        let productImgUrl = result?.data?.images?.length
          ? result.data.images[0].url
          : "";
        let idxVariant = findIndex(
          result.data.productVariants,
          (variant) => variant.id.toString() === variantID
        );
        if (idxVariant > -1) {
          let _variant = result.data.productVariants[idxVariant];
          return {
            ..._variant,
            productName: result.data.productName,
            productImgUrl: _variant.imageUrl
              ? _variant.imageUrl
              : productImgUrl,
          };
        }
      }
      return null;
    } catch (error) {
      console.log("product detail:", error.message);
      return null;
    }
  }

  //#endregion

  //#region set

  //#endregion

  //#region helper
  _getVariantSearchFormat = (productArg, variantArg) => {
    let _variant = { ...variantArg };
    _variant["notAllowPromotion"] = productArg.notAllowPromotion;
    _variant["productVariantsId"] = variantArg.id;
    _variant["productId"] = productArg.id;
    _variant["productName"] = productArg.productName;
    _variant["productImgUrl"] = variantArg.imageUrl || productArg.url;
    _variant["units"] = variantArg.units || [];
    _variant["selectedUnit"] = _variant.units.find((unit) =>
      variantArg.variantUnitId
        ? unit.id === variantArg.variantUnitId
        : unit.base
    );
    _variant["variantUnitId"] = _variant.selectedUnit
      ? _variant.selectedUnit.id
      : null;
    _variant["price"] = _variant.selectedUnit
      ? _variant.selectedUnit.price
      : variantArg.price;
    _variant["sku"] = _variant.selectedUnit
      ? _variant.selectedUnit.sku
      : variantArg.sku;
    _variant["barcode"] = _variant.selectedUnit
      ? _variant.selectedUnit.barcode
      : variantArg.barcode;
    return _variant;
  };

  _searchOnResult = (content, keyword) => {
    if (content) {
      return content.toLowerCase().includes(keyword.toLowerCase());
    } else {
      return false;
    }
  };

  _filterProductByName = (listProducts, keyword) => {
    if (keyword) {
      const product = find(listProducts, (p) => {
        return (
          trim(p.productName.toLowerCase()) === trim(keyword.toLowerCase())
        );
      });
      return product;
    } else {
      return false;
    }
  };

  //search collection

  searchGroupProduct = async (appContext, page, limit, str) => {
    try {
      let result = await getCollectionAPI(appContext, page, limit, str);
      let temp = this.getDefaultValue();
      let total = result.data.totalRecord || 0;
      temp.total = total;
      temp.recordsCollect = result.data.data || [];
      temp.hasNextPage = total > page * limit;
      temp.currentPage = page;
      temp.totalPage = Math.ceil(total / limit);
      return temp;
    } catch (error) {
      return null;
    }
  };
  //search product
  searchProducts = async (appContext, mode, page = 1, limit = 20, keyword) => {
    try {
      let location = appContext.current_location;
      let hideOutStock = appContext.shop_setting.hideProductOutOfStock;
      let allowOrderOutStock =
        appContext.shop_setting.allowOrderProductOutOfStock;

      let result = await callSearchProducts(
        appContext,
        page,
        limit,
        keyword,
        location.id,
        null,
        null,
        null,
        null,
        enableScale(appContext.shop_info.scale_support)
      );
      let products = result.data.data || [];
      let records = [];
      let total = result.data.totalRecord || 0;
      let hasNextPage = total > page * limit;
      let currentPage = page;
      let variantList = [];
      let variantExact = [];
      let variantResult = [];

      let productByName = this._filterProductByName(products, keyword);

      if (hideOutStock && !allowOrderOutStock) {
        if (!productByName) {
          forEach(products, (product) => {
            let variants = product.variants || [];
            let isSearchOnResult = this._searchOnResult(
              product.productName,
              keyword
            );
            forEach(variants, (variant) => {
              if (
                !variant.isTrackingInventory ||
                variant.quantity > 0 ||
                mode === "search"
              ) {
                let temp = this._getVariantSearchFormat(product, variant);

                if (isSearchOnResult) {
                  variantResult.push(temp);
                } else if (
                  (temp.sku && temp.sku === keyword) ||
                  (temp.barcode && temp.barcode === keyword)
                ) {
                  variantExact.push(temp);
                } else {
                  variantList.push(temp);
                }
              }
            });
          });
        }

        if (productByName) {
          let variants = productByName.variants || [];
          forEach(variants, (variant) => {
            if (
              !variant.isTrackingInventory ||
              variant.quantity > 0 ||
              mode === "search"
            ) {
              let temp = this._getVariantSearchFormat(productByName, variant);
              variantResult.push(temp);
            }
          });

          if (products && products.length > 1) {
            forEach(products, (product) => {
              let variantsProduct = product.variants || [];
              let isSearchOnResult = this._searchOnResult(
                product.productName,
                keyword
              );
              if (isSearchOnResult && productByName.id !== product.id) {
                forEach(variantsProduct, (variant) => {
                  if (
                    !variant.isTrackingInventory ||
                    variant.quantity > 0 ||
                    mode === "search"
                  ) {
                    let temp = this._getVariantSearchFormat(product, variant);
                    variantResult.push(temp);
                  }
                });
              }
            });
          }
        }
      } else {
        if (!productByName) {
          forEach(products, (product) => {
            let variants = product.variants || [];
            let isSearchOnResult = this._searchOnResult(
              product.productName,
              keyword
            );
            forEach(variants, (variant) => {
              let temp = this._getVariantSearchFormat(product, variant);

              if (isSearchOnResult) {
                variantResult.push(temp);
              } else if (
                this._searchOnResult(temp.barcode, keyword) ||
                this._searchOnResult(temp.sku, keyword)
              ) {
                variantExact.push(temp);
              } else {
                variantList.push(temp);
              }
            });
          });
        }

        if (productByName) {
          let variants = productByName.variants || [];
          forEach(variants, (variant) => {
            let temp = this._getVariantSearchFormat(productByName, variant);
            variantResult.push(temp);
          });

          if (products && products.length > 1) {
            forEach(products, (product) => {
              let variantsProduct = product.variants || [];
              let isSearchOnResult = this._searchOnResult(
                product.productName,
                keyword
              );
              if (isSearchOnResult && productByName.id !== product.id) {
                forEach(variantsProduct, (variant) => {
                  let temp = this._getVariantSearchFormat(product, variant);
                  variantResult.push(temp);
                });
              }
            });
          }
        }
      }

      if (variantExact.length > 0) {
        records = [...variantExact, ...variantResult];
      } else {
        records = [...variantList, ...variantResult];
      }
      return {
        records,
        total,
        hasNextPage,
        currentPage,
      };
    } catch (error) {
      return null;
    }
  };
  //#endregion
}
