import { useCartContext } from "screens-v2/carts-v2";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";

export const ModalRemoveAllProductCart = ({ show, setShow }) => {
  const { removeAllSessionProduct } = useCartContext();

  const body = () => {
    return (
      <p className="mb-0">
        Toàn bộ sản phẩm trong giỏ hàng sẽ bị xóa. Bạn có chắc chắn muốn xoá
        không?
      </p>
    );
  };

  return (
    <GlobalModal
      headerTitle={"Xóa toàn bộ sản phẩm"}
      isTitleCenter
      body={body()}
      show={show}
      setShow={setShow}
      onCancel={() => setShow(false)}
      onConfirm={() => {
        removeAllSessionProduct();
        setShow(false);
      }}
    />
  );
};
