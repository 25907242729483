import { Textarea } from "@haravan/react-components";
import { closeShift, getShiftTransactionSummary } from "api/shift-repository";
import Banner from "component-v2/banners";
import { find, toNumber } from "lodash";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { CurrencyUtils } from "utils";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app/index";
import CurrencyShow from "component-v2/currency-show";
import { InputNumber } from "screens-v2/carts-v2/RightCart/components/InputNumber";

export default function CloseShiftModal({
  shift,
  shiftCashTracking,
  show,
  setShow,
  onSucceeded,
  warning,
}) {
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  const appContext = useApp();
  const [formMode, setFormMode] = useState(0);
  const [note, setNote] = useState("");
  const [activityType, setActivityType] = useState("withdraw");
  const [moneyProcess, setMoneyProcess] = useState({
    withDrawAmount: 0,
    shiftCashAmount: 0,
    payIntoAmount: 0,
    cashAmoutShiftCurrent: 0,
  });
  const [ready, setReady] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [loading, setLoading] = useState(true);
  const currentLocation = appContext.current_location || {};
  const locationName =
    currentLocation && currentLocation.locationName
      ? currentLocation.locationName
      : "--";

  useEffect(() => {
    setNote("");
    setFormMode(0);
    setMoneyProcess({
      withDrawAmount: 0,
      shiftCashAmount: 0,
      payIntoAmount: 0,
      cashAmoutShiftCurrent: 0,
    });
    if (shift?.id && show) {
      setReady(false);
      _getShiftTransactionSummary();
      if (!shiftCashTracking) setFormMode(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift, show]);

  async function _getShiftTransactionSummary() {
    setLoading(true);
    const response = await getShiftTransactionSummary(appContext, shift.id);
    setLoading(false);
    const shiftTransactions = response?.data ? response.data : [];
    if (!shiftTransactions) return;
    setReady(true);
    let cashAmoutShiftCurrent = 0;
    const cashTransaction = find(shiftTransactions, (x) => {
      return x.gatewayName === "cash";
    });
    if (cashTransaction) {
      cashAmoutShiftCurrent =
        cashTransaction.salesAmountTotal - cashTransaction.refundAmountTotal;
      setMoneyProcess((prev) => ({
        ...prev,
        cashAmoutShiftCurrent,
      }));
    }
  }

  /**
   * Số tiền đếm được
   */
  const handleShiftCashAmountTotalChanged = (value) => {
    value = value.toString();
    if (value.length === 0) value = 0;
    if (value[0] === "0" && value.length > 0) {
      value = value.substring(1);
    }
    let num = toNumber(CurrencyUtils.numberWithCommasActive(value));
    setMoneyProcess((prev) => ({
      ...prev,
      shiftCashAmount: num,
      withDrawAmount: num,
    }));
  };

  /**
   * Số tiền rút/nạp
   */
  const handleActivityAmountChanged = (value, type) => {
    value = value.toString();
    if (value.length === 0) value = 0;
    if (value[0] === "0" && value.length > 0) {
      value = value.substring(1);
    }
    let num = toNumber(CurrencyUtils.numberWithCommasActive(value));
    if (type === "withdraw") {
      //Số tiền rút ko được lớn hơn số tiền thực tế đếm được
      const { shiftCashAmount } = moneyProcess;
      if (num > shiftCashAmount) {
        num = shiftCashAmount;
      }
      setMoneyProcess((prev) => ({
        ...prev,
        withDrawAmount: num,
      }));
    } else {
      setMoneyProcess((prev) => ({
        ...prev,
        payIntoAmount: num,
      }));
    }
  };

  const handleConfirmCashAmountTotal = () => {
    setShowBanner(false);
    setFormMode(1);
  };

  const onClose = (value) => {
    setNote("");
    setMoneyProcess({
      withDrawAmount: 0,
      shiftCashAmount: 0,
      payIntoAmount: 0,
      cashAmoutShiftCurrent: 0,
    });
    setShow(value);
  };

  const onSubmitCloseShift = async () => {
    const { withDrawAmount, payIntoAmount, shiftCashAmount } = moneyProcess;
    const activityAmount =
      activityType === "withdraw" ? withDrawAmount : payIntoAmount;
    let location = appContext.current_location;
    let result = await closeShift(
      appContext,
      shift?.id,
      location?.id,
      location.name,
      shiftCashTracking,
      activityType,
      activityAmount,
      shiftCashAmount,
      note,
    );
    if (result.error) {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: result.message,
          defaultMessage: result.message,
        }),
      );
    } else {
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: "notifications.Đóng ca thành công!",
          defaultMessage: "Đóng ca thành công!",
        }),
      );
      onSucceeded?.();
    }
  };

  const noteForm = () => {
    return (
      <div className="l-mt-16">
        <label>
          {intl.formatMessage({
            id: "carts.orders.Ghi chú",
            defaultMessage: "Ghi chú",
          })}
        </label>
        <Textarea
          rows={2}
          onChange={(value) => setNote(value)}
          value={note}
          placeholder="Ghi chú cho ca bán hàng"
        />
      </div>
    );
  };

  const confirmCloseShiftForm = () => {
    const { shiftCashAmount, cashAmoutShiftCurrent } = moneyProcess;
    const cashAmountTotal = shift.activityAmount + cashAmoutShiftCurrent;
    return (
      <Fragment>
        <Banner
          status="warning"
          light
          className="l-mt-16"
          title={
            cashAmountTotal < 0 ? (
              <span>
                {intl.formatMessage({
                  id: "shifts.Tiền mặt trong ca",
                  defaultMessage: "Tiền mặt trong ca",
                })}
                :{" "}
                <b>
                  <CurrencyShow value={Math.round(cashAmountTotal)} />
                </b>
              </span>
            ) : (
              <span>
                Số tiền cần có trong ca của bạn:{" "}
                <b>
                  <CurrencyShow value={Math.round(cashAmountTotal)} />
                </b>
              </span>
            )
          }
        />
        <div className="l-mt-16">
          <label>
            {intl.formatMessage({
              id: "carts.orders.Số tiền đếm được",
              defaultMessage: "Số tiền đếm được",
            })}
          </label>
          <InputNumber
            value={shiftCashAmount}
            onChange={(value) => {
              handleShiftCashAmountTotalChanged(value);
            }}
            className="w-100 my-3 text-right"
          />
        </div>
        {noteForm()}
      </Fragment>
    );
  };

  const warningAmountRender = (countTotal, cashAmountTotal) => {
    if (cashAmountTotal < 0) {
      return (
        <span>
          {" "}
          {intl.formatMessage({
            id: "carts.orders.Số tiền mong đợi",
            defaultMessage: "Số tiền mong đợi",
          })}
          :{" "}
          <b>
            <CurrencyShow value={Math.abs(Math.round(countTotal))} />
          </b>{" "}
        </span>
      );
    }
    if (countTotal === 0) {
      return (
        <span>
          {" "}
          {intl.formatMessage({
            id: "carts.orders.Chính xác với số tiền mong đợi",
            defaultMessage: "Chính xác với số tiền mong đợi",
          })}
        </span>
      );
    } else if (countTotal > 0) {
      return (
        <span>
          {" "}
          {intl.formatMessage({
            id: "carts.orders.Cao hơn mong đợi",
            defaultMessage: "Cao hơn mong đợi",
          })}
          :{" "}
          <b>
            <CurrencyShow value={Math.abs(Math.round(countTotal))} />
          </b>
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          {intl.formatMessage({
            id: "carts.orders.Thấp hơn mong đợi",
            defaultMessage: "Thấp hơn mong đợi",
          })}
          :{" "}
          <b>
            <CurrencyShow value={Math.abs(Math.round(countTotal))} />
          </b>{" "}
        </span>
      );
    }
  };

  const closeShiftForm = () => {
    const {
      shiftCashAmount,
      cashAmoutShiftCurrent,
      withDrawAmount,
      payIntoAmount,
    } = moneyProcess;
    const cashAmountTotal = shift.activityAmount + cashAmoutShiftCurrent;
    const countTotal = shiftCashAmount - cashAmountTotal;

    return (
      <Fragment>
        <div className="l-mt-16">
          <Banner
            status="success"
            light
            className="l-mt-16"
            title={
              <span>
                {intl.formatMessage({
                  id: "carts.orders.Số tiền đếm được",
                  defaultMessage: "Số tiền đếm được",
                })}
                :{" "}
                <b>
                  <CurrencyShow value={Math.round(shiftCashAmount)} />
                </b>
                .{warningAmountRender(countTotal, cashAmountTotal)}
              </span>
            }
          />
        </div>
        <div className="l-mt-16">
          <div className="l-tab-group l-mb-8">
            <label
              className={`l-tab${activityType === "withdraw" ? " active" : ""}`}
              onClick={() => {
                setActivityType("withdraw");
              }}
            >
              {intl.formatMessage({
                id: "carts.orders.Rút tiền",
                defaultMessage: "Rút tiền",
              })}
            </label>
            <label
              className={`l-tab${activityType === "payinto" ? " active" : ""}`}
              onClick={() => {
                setActivityType("payinto");
              }}
            >
              {intl.formatMessage({
                id: "carts.orders.Nạp tiền",
                defaultMessage: "Nạp tiền",
              })}
            </label>
          </div>
          {activityType === "withdraw" ? (
            <div>
              <InputNumber
                onChange={(value) =>
                  handleActivityAmountChanged(value, "withdraw")
                }
                autoFocus={true}
                value={withDrawAmount}
                className="text-right"
              />
              <p className="padding-top-16 font-size-12 content-secondary">
                {intl.formatMessage({
                  id: "carts.orders.Có thể rút tổng cộng",
                  defaultMessage: "Có thể rút tổng cộng",
                })}
                :{" "}
                <CurrencyShow
                  className="content-primary"
                  value={Math.round(shiftCashAmount)}
                />
              </p>
            </div>
          ) : null}
          {activityType === "payinto" ? (
            <InputNumber
              onChange={(value) =>
                handleActivityAmountChanged(value, "payinto")
              }
              autoFocus={true}
              value={payIntoAmount}
              className="text-right"
            />
          ) : null}
        </div>
        {noteForm()}
      </Fragment>
    );
  };

  const body = () => {
    if (!shift) return null;
    return (
      <div>
        {warning && showBanner ? (
          <Banner status="warning" light title={warning} className="l-mb-16" />
        ) : null}
        <div className="l-table plain">
          <div>
            <div className="text-nowrap">
              {intl.formatMessage({
                id: "carts.orders.Chi nhánh",
                defaultMessage: "Chi nhánh",
              })}
              :
            </div>
            <div>{locationName}</div>
          </div>
          <div>
            <div className="text-nowrap">
              {intl.formatMessage({
                id: "carts.orders.Nhân viên",
                defaultMessage: "Nhân viên",
              })}
              :
            </div>
            <div>{appContext.auth.user.name}</div>
          </div>
          <div>
            <div className="text-nowrap">Email:</div>
            <div>{appContext.auth.user.email}</div>
          </div>
          {shift.shiftManager?.id ? (
            <div>
              <div className="text-nowrap">Quản lý:</div>
              <div>{shift.shiftManager?.userName}</div>
            </div>
          ) : null}
        </div>
        <div id="customer-update-info">
          {!shiftCashTracking
            ? noteForm()
            : formMode === 0
              ? confirmCloseShiftForm()
              : closeShiftForm()}
        </div>
      </div>
    );
  };

  const footerTitle = () =>
    formMode === 0 || !shiftCashTracking ? "Hủy bỏ" : "Quay lại";

  const footerAction = () =>
    formMode === 0 || !shiftCashTracking ? setShow(false) : setFormMode(0);
  const confirmTitle = () => (formMode === 0 ? "Xác nhận" : "Đóng ca");
  const confirmAction = () =>
    formMode === 0 ? handleConfirmCashAmountTotal() : onSubmitCloseShift();

  return (
    <GlobalModal
      show={show}
      setShow={onClose}
      headerTitle={intl.formatMessage({
        id: "carts.orders.Đóng ca bán hàng",
        defaultMessage: "Đóng ca bán hàng",
      })}
      body={body()}
      useEscapeKey={true}
      onCancel={() => footerAction()}
      onConfirm={() => confirmAction()}
      confirm
      loadingOverlay={loading}
      confirmTitle={confirmTitle()}
      cancelTitle={footerTitle()}
    />
  );
}
