import { useState } from "react";
import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import { Tooltip } from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import PropTypes from "prop-types";
import { useRefundContext } from "..";
import { toFormattedCurrency } from "infrastructures/format-helpers";

export const RefundItemsSummary = (props) => {
  const intl = useIntl();
  const { order, loyaltyEnabled } = props;
  const { current_refund } = useRefundContext()
  const [showMoreVat, setShowMoreVat] = useState(true);

  const renderVatCondition = (vatValue) => {
    let content = null;
    // trường hợp cả phí vận chuyển && phí mặc định đều có 
    if (vatValue?.sumNotIncludedVat <= 0 && vatValue?.sumIncludedVat > 0) {
      content = <div className="ml-4 pt-2 border-0">(đã bao gồm trong giá)</div>;
      return content;
    } else if (vatValue?.sumNotIncludedVat >= 0 && vatValue?.sumIncludedVat <= 0) {
      // giá chưa bao gồm thuế
      return content;
    } else {
      content =
        <div className='ml-4 pt-2 border-0'>
          {(!!vatValue.sumIncludedVat && vatValue.sumIncludedVat > 0) && <div className='d-flex flex-row justify-content-between'>
            <span>Thuế đã bao gồm trong giá</span>
            <span>{toFormattedCurrency(vatValue.sumIncludedVat)}</span>
          </div>}
          {(!!vatValue.sumNotIncludedVat && vatValue.sumNotIncludedVat > 0) && <div className='d-flex flex-row justify-content-between'>
            <span>Thuế phải trả</span>
            <span>{toFormattedCurrency(vatValue.sumNotIncludedVat)}</span>
          </div>}
        </div>
    }

    return content;
  }

  const vatRender = () => {
    // if (!shop_info.taxable) return null;

    let vatValue = current_refund.vatValue;

    const isVatSameCondition = (vatValue.sumIncludedVat > 0 && vatValue.sumNotIncludedVat > 0);
    return <>
      <div className="d-flex flex-row l-pt-8">
        <div className="d-flex flex-row refund-items-summary-label">
          <p className="">VAT</p>
          {isVatSameCondition && <span
            onClick={() => {
              setShowMoreVat(prev => !prev)
            }}
            className="ml-3 cursor-pointer">
            {showMoreVat ? <Icon Icon name='keyboard_arrow_up'></Icon> :
              <Icon name='keyboard_arrow_down'></Icon>}
          </span>
          }
        </div>
        {/* <p>{toFormattedCurrency(summaries.subtotal)}</p> */}
        <span className="refund-items-summary-value">{current_refund?.lineItems?.length > 0 ? toFormattedCurrency(vatValue.vatSum) : 0}</span>
      </div >
      {current_refund?.lineItems?.length > 0 && showMoreVat && (isVatSameCondition || vatValue.vatSum > 0) ? renderVatCondition(vatValue) : null}
    </>

  }
  return (
    <div style={{ padding: '1.2rem' }}>
      <div className="d-flex flex-row">
        <p className="refund-items-summary-label">
          {intl.formatMessage({ id: "carts.refunds.Tiền khách đã hoàn trả lần trước", defaultMessage: "Tiền khách đã hoàn trả lần trước" })}
        </p>
        <span className={"refund-items-summary-value"} >{order && <CurrencyShow value={order.amountRefuned} />}</span>
      </div>
      <div className="d-flex flex-row l-pt-8">
        <p className="refund-items-summary-label">
          {intl.formatMessage({ id: "orders.searches.Phí vận chuyển", defaultMessage: "Phí vận chuyển" })}
        </p>
        <span className={"refund-items-summary-value"} >{order && <CurrencyShow value={order.orderShippingFee} />}</span>
      </div>
      <div className="d-flex flex-row l-pt-8">
        <p className="refund-items-summary-label">
          {intl.formatMessage({ id: "carts.refunds.Giảm giá", defaultMessage: "Giảm giá" })}
        </p>
        <span className={"refund-items-summary-value "}>{order && <CurrencyShow value={order.discountAmount} />}</span>
      </div>
      {loyaltyEnabled &&
        <div className="d-flex flex-row l-pt-8">
          <p className="refund-items-summary-label">
            {intl.formatMessage({ id: "carts.refunds.Giảm giá KHTT", defaultMessage: "Giảm giá KHTT" })}
            <Tooltip placement="top"
              title={intl.formatMessage({ id: 'carts.refunds.Nếu loại chương trình khách hàng thân thiết là "Chiết khấu đơn hàng theo hạng thành viên", Haravan POS sẽ không hoàn lại số tiền đã chiết khấu cho khách hàng khi đổi/trả.', defaultMessage: 'Nếu loại chương trình khách hàng thân thiết là "Chiết khấu đơn hàng theo hạng thành viên", Haravan POS sẽ không hoàn lại số tiền đã chiết khấu cho khách hàng khi đổi/trả.' })}
              overlayClassName="tooltip-custom-width">
              <span className="icon-w-12 ml-2 mg-icon"><Icon name="help" color="var(--contentTertiary)" /></span>
            </Tooltip>
          </p>
          <span className={"refund-items-summary-value "}>{order && <CurrencyShow value={order.redeemUsedAmount} />}</span>
        </div>
      }
      {vatRender()}
    </div>
  );
};


RefundItemsSummary.propTypes = {
  order: PropTypes.object,
  loyaltyEnabled: PropTypes.bool,
};
