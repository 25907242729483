import { EventBus } from "utils";
import {
  HTTP_STATUS_TOKEN_EXPIRED,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_FORBIDDEN,
  TOKEN_EXPIRED,
  UNAUTHORIZED_REQUEST,
  UNPERMISSION_REQUEST,
} from "../models/constants";

class HttpErrorHandler {
  static handle(response) {
    if (response.status === HTTP_STATUS_TOKEN_EXPIRED) {
      EventBus.emit(TOKEN_EXPIRED);
    }
    if (response.status === HTTP_STATUS_UNAUTHORIZED) {
      EventBus.emit(UNAUTHORIZED_REQUEST);
    }
    if (response.status === HTTP_STATUS_FORBIDDEN) {
      EventBus.emit(UNPERMISSION_REQUEST);
    }
  }
}

export default HttpErrorHandler;
