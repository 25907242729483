import { toFormattedCurrency } from "infrastructures/format-helpers";
import {
  SYSTEM_GUEST_EMAIL,
  initCustomerInfoDetail,
} from "infrastructures/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { concatString } from "screens-v2/carts-v2/utils";
import Badge from "screens-v2/layouts/layouts/omni-badge";

export default function OrderDetailCustomer({ order }) {
  const cusName = concatString({
    value: [order?.shippingLastName, order?.shippingFirstName],
    separator: " ",
  });
  const { customerInfo } = order;
  const { name, email, phone } = initCustomerInfoDetail(
    cusName,
    customerInfo?.email === SYSTEM_GUEST_EMAIL
      ? customerInfo?.email
      : order.customerEmail,
    order.shippingPhone,
  );
  const intl = useIntl();
  const renderCustomerDebt = () => {
    if (
      customerInfo.email === SYSTEM_GUEST_EMAIL ||
      !customerInfo.hasOwnProperty("debt")
    )
      return null;

    if (customerInfo && customerInfo?.debt === 0) {
      return (
        <Badge status="default" light className="mr-3 border-0">
          <FormattedMessage id="Không có nợ" defaultMessage="Không có nợ" />
        </Badge>
      );
    }

    return (
      <Badge
        status={customerInfo?.debt < 0 ? "red" : "green"}
        light
        className="mr-3 border-0"
      >
        <FormattedMessage
          id={"Khách nợ: {x}"}
          defaultMessage={"Khách nợ: {x}"}
          values={{
            x: (
              <span
                className={
                  customerInfo?.debt < 0 ? "text-danger" : "text-success"
                }
              >
                {toFormattedCurrency(-customerInfo?.debt)}
              </span>
            ),
          }}
        />
      </Badge>
    );
  };

  return (
    <div className="order-detail--customer-info order-detail-right-section">
      <div className="l-pb-8 right-main--cover d-flex flex-column justify-content-between bg-white border-bottom">
        <div className="l-section--with-title">
          <label className="content-secondary">
            {intl.formatMessage({
              id: "carts.orders.Khách hàng",
              defaultMessage: "Khách hàng",
            })}
          </label>
          <div className="l-section--body">
            {name && <p className="mb-0 lh-20">{name}</p>}
            {phone || email ? (
              <p className="mb-0 l-pt-4 l-text-smaller">
                {concatString({
                  value: [phone, email],
                  separator: " • ",
                })}
              </p>
            ) : null}
            {renderCustomerDebt()}
          </div>
        </div>
      </div>
      {/*
      order.isShipping && (
        <div className="l-pt-8 l-pb-8 right-main--cover d-flex flex-column justify-content-between bg-white border-bottom">
          <div className="l-section--with-title">
            <label className="content-secondary">{intl.formatMessage({ id: 'carts.orders.Giao hàng', defaultMessage: 'Giao hàng' })}</label>
            <div className="l-section--body">
              <p className="mb-0 lh-20">
                {concatString({
                  value: [
                    order.shippingAddress,
                    order.shippingWardName,
                    order.shippingDistrictName,
                    order.shippingProvinceName,
                    order.shippingCountryName,
                  ],
                  separator: ", ",
                })}
              </p>
              {order.shipFullName || order.shippingPhone ? (
                <p className="mb-0 l-pt-4 l-text-smaller">
                  {concatString({
                    value: [
                      order.shipFullName,
                      order.shippingPhone,
                    ],
                    separator: " • ",
                  })}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      )
                */}
    </div>
  );
}
