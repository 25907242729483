import React from "react";
import EmptyState from "component-v2/empty-state";

function CartEmpty({ intl }) {
  return (
    <EmptyState
      iconName="shopping_cart"
      title={intl.formatMessage({
        id: "carts.orders.Giỏ hàng trống",
        defaultMessage: "Giỏ hàng trống",
      })}
      description={intl.formatMessage({
        id: "products.searches.Tìm sản phẩm hoặc quét barcode để thêm sản phẩm",
        defaultMessage: "Tìm sản phẩm hoặc quét barcode để thêm sản phẩm",
      })}
    />
  );
}
export { CartEmpty };
