import { useApp } from "contexts/app";
import { useEffect } from "react";
import { useRefundContext } from "..";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";

export const SaleUsers = () => {
  const { shop_setting } = useApp();
  const { staff_users } = useApp();
  const {
    salesMan,
    setSalesMan,
    current_shopping_cart,
    updateCurrentSession,
    updateCurrentCart,
  } = useRefundContext();
  useEffect(() => {
    if (!salesMan && staff_users?.length) {
      setSalesMan(staff_users?.find((user) => user.default));
      updateCurrentCart({
        salesMan: staff_users?.find((user) => user.default),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff_users, salesMan]);
  let settings = shop_setting;
  let allowChangeSeller = settings ? settings.allowChangeSeller : true;

  return (
    <SelectionCustom
      value={salesMan?.id}
      options={staff_users}
      idField="id"
      valueField="name"
      onChange={(value) => {
        if (value !== salesMan) {
          const newSaleMan = staff_users.find((item) => item.id === value);
          setSalesMan(newSaleMan);
          updateCurrentSession({ salesMan: newSaleMan });
        }
      }}
      prefix={
        <label className="align-self-center label-in-selection">
          Nhân viên bán:{" "}
        </label>
      }
      placeholder={"Chọn nhân viên bán"}
      disabled={!allowChangeSeller}
    >
      {staff_users?.map((u) => {
        return (
          <SelectionCustom.Option key={u.id} value={u.id}>
            <div className="d-flex align-items-center">{u.name}</div>
          </SelectionCustom.Option>
        );
      })}
    </SelectionCustom>
  );
};
