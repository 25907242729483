import React from "react";
import Button from "component-v2/buttons";
import { useRefundContext } from "../index";

export const RefundCartFunction = (props) => {
  const refundContext = useRefundContext();

  const handleRemoveNewCart = () => {
    refundContext.updateCurrentSession({ lineItems: [], cart_quantity_total: 0 });
  }

  return (
    <div className="carts-functions border-bottom l-pb-8">
      <div className="flex-stretch">
        <Button
          size="sm"
          light
          icon="delete"
          title="Xóa tất cả"
          disabled={refundContext.current_shopping_cart?.cart_quantity_total > 0 ? false : true}
          onClick={handleRemoveNewCart}
        />
      </div>
      <div className="flex-auto"></div>
    </div>
  );
};