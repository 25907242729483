import {
  toFormattedCurrency,
  toFormattedNumber,
} from "infrastructures/format-helpers";
import React from "react";
import { useCartContext } from "screens-v2/carts-v2";

export const Loyalty = () => {
  const { current_shopping_cart } = useCartContext();
  if (!current_shopping_cart?.loyalty?.profile) {
    return (
      <div className="content-secondary text-center">
        Không tìm thấy thông tin khách hàng
      </div>
    );
  }

  const { loyalty } = current_shopping_cart;

  return (
    <div className="row">
      <div className="col-12 col-lg-6 pr-lg-2">
        <div className="l-section py-3 px-4">
          <p className="font-size-12">Điểm thưởng hiện có</p>
          <p className="font-weight-semibold mt-3">
            {toFormattedNumber(loyalty?.profile?.available_points)}
          </p>
        </div>
      </div>

      <div className="col-12 col-lg-6 pl-lg-2">
        <div className="l-section py-3 px-4">
          <p className="font-size-12">Điểm không khả dụng</p>
          <p className="font-weight-semibold mt-3">
            {toFormattedNumber(loyalty?.profile?.unavailable_points)}
          </p>
        </div>
      </div>

      <div className="col-12 col-lg-6 mt-3 pr-lg-2">
        <div className="l-section py-3 px-4">
          <p className="font-size-12">Điểm đã sử dụng</p>
          <p className="font-weight-semibold mt-3">
            {toFormattedNumber(loyalty?.profile?.used_points)}
          </p>
        </div>
      </div>

      <div className="col-12 col-lg-6 mt-3 pl-lg-2">
        <div className="l-section py-3 px-4">
          <p className="font-size-12">Điểm xếp hạng</p>
          <p className="font-weight-semibold mt-3">
            {toFormattedNumber(
              loyalty?.profile?.current_membership_level_point,
            )}
          </p>
        </div>
      </div>

      <div className="col-12 col-lg-6 mt-3 pr-lg-2">
        <div className="l-section py-3 px-4">
          <p className="font-size-12">Hạng thành viên</p>
          <p className="font-weight-semibold mt-3">
            Hạng {loyalty?.profile?.current_membership_level}
          </p>
        </div>
      </div>

      <div className="col-12 col-lg-6 mt-3 pl-lg-2">
        <div className="l-section py-3 px-4">
          <p className="font-size-12">Tổng chi tiêu</p>
          <p className="font-weight-semibold mt-3">
            {toFormattedCurrency(loyalty?.profile?.total_spent)}
          </p>
        </div>
      </div>
    </div>
  );
};
