import { toFormattedNumber } from "infrastructures/format-helpers";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useRefundContext } from "screens-v2/refunds-v2";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { InputNumber } from "./InputNumber";

export const ModalEditPriceRefund = ({ show, setShow }) => {
  const intl = useIntl();
  const [price, setPrice] = useState(0);
  const { updateCurrentRefund, current_refund } = useRefundContext();
  const refShippingFee = useRef(null);

  useEffect(() => {
    setPrice(current_refund?.priceUserRefund || 0);
  }, [current_refund?.priceUserRefund]);

  const body = () => {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center my-4">
          <span>
            {intl.formatMessage({
              id: "carts.refunds.Tổng tiền có thể hoàn trả",
              defaultMessage: "Tổng tiền có thể hoàn trả",
            })}
          </span>
          <span>{toFormattedNumber(current_refund?.priceUserCanRefund)}</span>
        </div>
        <label>
          {intl.formatMessage({
            id: "carts.refunds.Tổng tiền hoàn trả",
            defaultMessage: "Tổng tiền hoàn trả",
          })}
        </label>
        <InputNumber
          ref={refShippingFee}
          max={current_refund?.priceUserCanRefund}
          currency
          value={price}
          onChange={(value) => {
            setPrice(value);
          }}
          autoFocus
        />
      </React.Fragment>
    );
  };

  return (
    <GlobalModal
      headerTitle={intl.formatMessage({
        id: "carts.refunds.Tổng tiền hoàn trả",
        defaultMessage: "Tổng tiền hoàn trả",
      })}
      isTitleCenter
      body={body()}
      show={show}
      onConfirm={() => {
        setShow(false);
        updateCurrentRefund({
          priceUserRefund: price,
        });
      }}
      onCancel={() => {
        setShow(false);
      }}
      confirmTitle="Xong"
      setShow={setShow}
    />
  );
};
