import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { HOTKEYS } from "models/constants";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useCartContext } from "screens-v2/carts-v2";
import PrintButton from "screens-v2/orders/components/print-button";
import GlobalSimpleModal from "screens-v2/layouts/layouts/global-simple-modal";
import Badge from "screens-v2/layouts/layouts/omni-badge";

export const ModalCreateOrderSuccess = ({ show, setShow, data }) => {
  const intl = useIntl();
  const history = useHistory();
  const [isVatFail, setIsVatFail] = useState(false);
  const { current_shopping_cart } = useCartContext();
  const redirectChangePaymentMethod = () => {
    if (data?.orderId) {
      history.push(
        getConstantLinkDetail(CONSTANT_LINKS.route_order_detail, data?.orderId),
        { showConfirmPayment: true },
      );
    }
  };
  const redirectCancelOrder = () => {
    if (data?.orderId) {
      history.push(
        getConstantLinkDetail(CONSTANT_LINKS.route_order_detail, data?.orderId),
        { isCancelOrder: true },
      );
    }
  };
  useEffect(() => {
    if (!show) {
      setIsVatFail(false);
    }
  }, [show]);

  const body = () => (
    <div className="p-4">
      <iframe
        id="print-order"
        style={{ display: "none" }}
        title="print preview"
      />
      <div className="text-center font-weight-semibold font-size-24">
        {(data?.orderData?.paymentStatus === "paid" &&
          !!data?.orderData?.isQRPayment) ||
        !data?.orderData?.isQRPayment ||
        data?.dataResult?.paymentStatus === "Paid" ? (
          <Icon name="checkbox_circle_line" size="xl" color="var(--green600)" />
        ) : (
          <Icon name="highlight_off" size="xl" color="var(--green600)" />
        )}
        <p className="mt-4">
          {data?.orderData?.transactions?.length ||
          data?.dataResult?.paymentStatus === "Paid"
            ? "Thanh toán"
            : "Đặt hàng"}{" "}
          {(data?.orderData?.paymentStatus === "paid" &&
            !!data?.orderData?.isQRPayment) ||
          !data?.orderData?.isQRPayment ||
          data?.dataResult?.paymentStatus === "Paid"
            ? "thành công"
            : "thất bại"}
        </p>
        <p>
          đơn hàng{" "}
          <span className="content-accent">
            {data?.dataResult.orderNumber || data?.dataResult.orderName}
          </span>
        </p>
      </div>
      <div className="w-100 d-flex justify-content-center mt-5">
        {(data?.orderData?.paymentStatus === "paid" &&
          !!data?.orderData?.isQRPayment) ||
        (data?.orderData && !data?.orderData?.isQRPayment) ? (
          <>
            <Button
              title={"Tạo đơn mới"}
              id="order.create.success_popup.new_button"
              onClick={() => setShow(false)}
              light
              size="lg"
              badge={HOTKEYS.ESC}
              className="mr-3 font-weight-semibold font-size-16"
            ></Button>
            <PrintButton
              showPopup={show}
              onSetVATStatus={setIsVatFail}
              intl={intl}
              orderId={null}
              isNewOrder={true}
              orderData={data?.orderData}
              sessionData={data?.orderData?.session}
              isBtnLoading={true}
              isAutoPrint={show}
              isShowTitleButton
              hasTitle
              size="lg"
              taxData={current_shopping_cart?.taxData}
            ></PrintButton>
          </>
        ) : data?.orderData ? (
          <>
            <Button
              title={"Huỷ đơn hàng"}
              id="order.create.success_popup.new_button"
              onClick={() => redirectCancelOrder()}
              light
              size="lg"
              className="mr-3 font-weight-semibold font-size-16"
            ></Button>
            <Button
              title={"Đổi phương thức khác"}
              id="order.create.success_popup.new_button"
              onClick={() => redirectChangePaymentMethod()}
              light
              size="lg"
              className="mr-3 font-weight-semibold font-size-16"
            ></Button>
          </>
        ) : null}
      </div>
      {isVatFail ? (
        <Badge
          rounded
          size="L"
          className={"w-100 p-4 h-100 mt-4"}
          status="blue"
          light={true}
        >
          <span className="d-flex align-items-center text-black">
            <Icon
              name={"info_filled"}
              size={"sm"}
              color="var(--blue600)"
              className={"mr-3"}
            />
            {intl.formatMessage({
              id: "carts.orders.In hóa đơn không kèm phát hành VAT do xảy ra lỗi bên đơn vị phát hành",
              defaultMessage:
                "In hóa đơn không kèm phát hành VAT do xảy ra lỗi bên đơn vị phát hành",
            })}
          </span>
        </Badge>
      ) : null}
    </div>
  );

  return (
    <GlobalSimpleModal
      body={body()}
      show={show}
      setShow={(value) => {
        setShow(value);
      }}
    />
  );
};
