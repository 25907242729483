class AppService {
  getApps = async (callAPI) => {
    try {
      const response = await callAPI(
        "get",
        `/call/hac_api/apps/com/service?is_embedded=true&view=25`
      );
      if (response.error) throw response.errorCodes;
      return response.data;
    } catch (error) {
      return null;
    }
  };
}
const appService = new AppService();
export default appService;
