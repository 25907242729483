import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { cloneDeep, isNumber, toNumber } from "lodash";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useIntl } from "react-intl";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import { RefundModel, RefundCartModel } from "./utils";
import { useOrderDetailData } from "hooks/useOrderData";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import { RefundListItem } from "./refund-list-item/refund-list-item";
import "./index.css";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import { toFormattedNumber } from "infrastructures/format-helpers";
import {
  SysAttributeKeys,
  checkValidRedeem,
  paymentMethodsDefault,
  pricingDefault,
  processingDiscover,
} from "../carts-v2/cart-helpers.service";
import { isScaleActive, setScaleActive } from "utils/ScaleUtils";

import {
  getOrderProduct,
  hasRefundItem,
  refundQuantityTotal,
  updateOrderLineRefund,
  addItemCart,
  removeItemCart,
  updateItemCart,
  maxPriceUserCanRefundPoint,
  getPointWhenRefund,
} from "./refund.service";
import {
  RefundCartList,
  RefundCartSearchBox,
  RefundCartFunction,
} from "./refund-cart-list";
import { useApp } from "contexts/app";
import { useShift } from "contexts/shift/shift-context";
import { RightCart } from "./RightCart";
import EmptyState from "component-v2/empty-state";
import RefundOtherLocationModal from "./components/refund-other-location-modal";
import BlockUnsavedPage from "component-v2/block-unsaved-page";
import { SaleUsers } from "./RightCart/saleUsers";
import _ from "lodash";
import Button from "component-v2/buttons";
import {
  createCheckoutSession,
  deleteCheckoutSession,
  getCheckoutSession,
  updateCheckoutSession,
} from "api/checkout-repository";
import { memoizedGetProductDetail } from "screens-v2/carts-v2";

export const DiscountAllocationType = {
  LINE_ITEM: "LINEITEM",
  LINE_DISCOUNT: "LINEDISCOUNT",
  MANUAL_ORDER: "MANUALORDER",
  REDEEM_POINT: "REDEEMPOINT",
  ORDER: "ORDER",
  MEMBERSHIP: "MEMBERSHIP",
  PRODUCT: "PRODUCT",
  SAME_PRICE: "SAMEPRICE",
  SHIPPING: "SHIPPING",
};
export const ManualDiscountType = {
  Amount: 0,
  Percent: 1,
};

export default function Refund() {
  const appContext = useApp();
  const storeId = parseInt(appContext?.auth?.user?.orgid);
  const intl = useIntl();
  const layout = useLayoutContext();
  const { checkShiftReady } = useShift();
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [current_refund, setCurrentRefund] = useState(null);
  const [current_order, setCurrentOrder] = useState(null);
  const [current_shopping_cart, setCurrentShoppingCart] = useState(null);
  const [isSubmitRefundOrderWaiting, setIsSubmitRefundOrderWaiting] =
    useState(false);
  const [blockingPage, setBlockingPage] = useState(false);
  const [salesMan, setSalesMan] = useState(null);
  const { orderId } = useParams();
  const history = useHistory();
  const initl = useIntl();
  const { data: order, errors } = useOrderDetailData(orderId, {
    revalidateOnFocus: false,
  });
  const [lotDateState, setLotDateState] = useState({
    isShowLotDateModal: false,
    currentOrderLineLotDate: null,
  });
  const [new_current_shopping_cart, setNewCurrentShoppingCart] = useState(null);
  const [isElectronicScale, setIsElectronicScale] = useState(isScaleActive());
  const enableElectricScale = useMemo(() => {
    return appContext.shop_info.scale_support;
  }, [appContext.shop_info.scale_support]);

  const methodDefaults = paymentMethodsDefault(appContext.shop_setting);
  useEffect(() => {
    // reset pttt khi xóa hết sản phẩm mua mới
    if (current_shopping_cart?.cart_quantity_total === 0) {
      let currentPaymentMethods = _.cloneDeep(methodDefaults);
      currentPaymentMethods.find((item) => item.type === "cash").amount =
        Math.abs(finalPrice());
      updateCurrentCart({ payment_methods: currentPaymentMethods });
    }
    // ------
    if (
      current_refund?.refund_quantity_total > 0 ||
      current_shopping_cart?.lineItems?.length
    ) {
      setBlockingPage(true);
    } else {
      setBlockingPage(false);
    }
  }, [current_refund?.refund_quantity_total, current_shopping_cart?.lineItems]);

  //#region sideEffect
  useEffect(() => {
    checkShiftReady(true);
    if (!orderId || isNaN(orderId)) {
      history.replace(CONSTANT_LINKS.route_orders);
      return;
    }
    layout.setBreadcrumb([{ title: `Đổi trả...`, link: null }]);
    layout.setPageTitle("Đổi trả...");
    return () => {
      layout.setBreadcrumb(null);
      layout.setPageTitle("");
      layout.setBackLink(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (new_current_shopping_cart) {
      checkCartDiscover(new_current_shopping_cart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_current_shopping_cart]);

  useEffect(() => {
    setLoadingRefund(true);
    if (order) {
      // check setting allowRefundOrderAllLocation
      // get customer
      if (
        (!appContext?.shop_setting?.allowRefundOrderAllLocation &&
          (order?.locationId !== currentLocation.id ||
            order?.sourceName !== "pos")) ||
        !order.isShowRefund ||
        order.shipmentStatus !== 4 ||
        !(
          order.paymentStatusId === 2 ||
          ((order.paymentStatusId === 3 || order.paymentStatusId === 6) &&
            !appContext?.shop_setting?.refundOnlyOnceEnable)
        )
      ) {
        history.replace(CONSTANT_LINKS.route_orders);
      }
      initNewRefund(order).finally(() => setLoadingRefund(false));
      layout.setBreadcrumb([
        {
          title: `Đổi trả đơn ${order.orderNumber}`,
        },
      ]);
      layout.setBackLink(
        getConstantLinkDetail(
          CONSTANT_LINKS.route_order_detail,
          order.orderNumber,
        ),
      );
    }
    if (!order && !!errors?.length) {
      layout.showGlobalToast(
        "error",
        initl.formatMessage({
          id: "notifications.Đơn hàng không tồn tại",
          defaultMessage: "Đơn hàng không tồn tại",
        }),
      );
      setTimeout(() => {
        history.replace(CONSTANT_LINKS.route_orders);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);
  useEffect(() => {
    if (current_refund?.priceUserRefund) {
      let amount =
        current_shopping_cart?.summaries?.total_payment -
        current_refund?.priceUserRefund;
      if (
        amount < 0 &&
        appContext.shop_setting.notAllowRefundIfLowerPrice &&
        current_shopping_cart?.lineItems?.length
      ) {
        amount = 0;
      }
      methodDefaults.find((method) => method.type === "cash").amount =
        Math.abs(amount) || 0;
      setCurrentShoppingCart({
        ...current_shopping_cart,
        payment_methods: methodDefaults,
      });
    }
  }, [current_refund?.priceUserRefund]);

  //#endregion

  //#region internal func
  // REFUND
  async function initNewRefund(order) {
    let new_refund = new RefundModel();
    let _hasRefund = hasRefundItem(order.orderProducts);
    let _products = getOrderProduct(appContext.shop_info, order.orderProducts);
    let _refundQuantity = refundQuantityTotal(_products);
    new_refund.isHasRefundItem = _hasRefund;
    new_refund.lineItems = _products;
    new_refund.refund_quantity_total = _refundQuantity;
    new_refund.priceUserCanRefund = order.refunded;
    new_refund.order = order;
    if (!!order?.checkoutToken) {
      new_refund.session = await getCheckoutSession(
        appContext.callAPI,
        order?.checkoutToken,
      );
    }

    const vatValue = {
      vatSum: order.totalTaxIncluded + order.totalTaxNotIncluded,
      sumIncludedVat: order.totalTaxIncluded,
      sumNotIncludedVat: order.totalTaxNotIncluded,
    };
    let taxData = {
      billingCompany: order.billingCompany,
      billingTaxCode: order.billingTaxCode,
      billingEmail: order.billingEmail,
      billingAddress: order.billingAddress,
      address: {
        billingCountry: order.billingCountry || null,
        billingCountryName: order.billingCountryName || null,
        billingProvince: order.billingProvince || null,
        billingProvinceName: order.billingProvinceName || null,
        billingDistrict: order.billingDistrict || null,
        billingDistrictName: order.billingDistrictName || null,
        billingWard: order.billingWard || null,
        billingWardName: order.billingWardName || null,
        billingCity: order.billingCity || null,
        billingAddress: order.billingAddress || null,
      },
      eInvoiceInfo: {
        company: order.eInvoiceInfo?.company || false,
        name: order.eInvoiceInfo?.name || "",
        taxCode: order.eInvoiceInfo?.taxCode || "",
        address: order.eInvoiceInfo?.address || "",
        email: order.eInvoiceInfo?.email || "",
        save: order.eInvoiceInfo?.save || false,
        submit: !!(order.eInvoiceInfo?.company || order.eInvoiceInfo?.name),
      },
      oldEInvoiceInfo: {
        company: order.eInvoiceInfo?.company || false,
        name: order.eInvoiceInfo?.name || "",
        taxCode: order.eInvoiceInfo?.taxCode || "",
        address: order.eInvoiceInfo?.address || "",
        email: order.eInvoiceInfo?.email || "",
        save: order.eInvoiceInfo?.save || false,
        submit: !!(order.eInvoiceInfo?.company || order.eInvoiceInfo?.name),
      },
      isInformationSaved: false,
    };
    let taxRequired = order.requestEInvoice ? true : false;
    setCurrentShoppingCart({
      ...new RefundCartModel(),
      taxData: {
        ...current_shopping_cart?.taxData,
        eInvoiceInfo: {
          ...taxData?.eInvoiceInfo,
        },
      },
      payment_methods: methodDefaults,
      pricingApplied: pricingDefault,
    });
    setCurrentRefund({
      ...new_refund,
      vatValue,
      taxData,
      taxRequired,
      eInvoice: current_refund?.eInvoice || [],
    });
    setCurrentOrder(order);
  }

  // công thức tính toán
  async function updateItemCurrentRefund(
    orderLine,
    orderLineIndex,
    restockableQuantity,
    isAutoFillLots = true,
  ) {
    current_refund.lastestAction += 1;
    let _newProducts = updateOrderLineRefund(
      appContext.shop_info,
      orderLine,
      orderLineIndex,
      restockableQuantity,
      current_refund.lineItems,
      isAutoFillLots,
    );
    if (_newProducts.length > 0) {
      current_refund.refund_quantity_total = refundQuantityTotal(_newProducts);
      current_refund.lineItems = _newProducts;
    }
    const _maxPriceUserCanRefundPoint = maxPriceUserCanRefundPoint(
      appContext.shop_loyalty,
      current_refund,
      current_refund.priceUserCanRefund,
    );
    // công thức tính tiền hoàn trả thuế
    // tiền thuế cần hoàn trả = (tổng thuế (totalTax) / tổng số lượng của 1 line sp (quantity)) * số lượng sp hoàn trả (refundNumber)
    const isTaxIncluded = current_refund?.order?.productTaxIncluded;
    const taxRefund = isTaxIncluded
      ? 0
      : _newProducts.reduce(
          (sum, item) =>
            sum + (item.totalTax / item?.quantity) * item.refundNumber,
          0,
        );
    const responseRefundPoint = await getPointWhenRefund(appContext.callAPI, {
      orderId,
      maxPriceUserCanRefund: Math.round(_maxPriceUserCanRefundPoint),
    });
    let newCurrentRefund = {
      ...current_refund,
      refundRedeemAmount: responseRefundPoint.rewardRedeemAmount || 0,
      refundRedeemPoint: responseRefundPoint.rewardRedeemPoint || 0,
      priceUserRefund: +Number(
        toNumber(_maxPriceUserCanRefundPoint) +
          taxRefund -
          responseRefundPoint.rewardRedeemAmount,
      ).toFixed(4),
    };

    setCurrentRefund(newCurrentRefund);
  }

  function updateCurrentRefund({ ...args }) {
    setCurrentRefund((prev) => ({
      ...prev,
      ...args,
    }));
  }

  function priceSubTotal({ totalPrice = 0, redeemUsedAmount = 0 }) {
    let total = totalPrice - redeemUsedAmount;
    return +Number(total).toFixed(4);
  }

  function finalPrice() {
    if (current_shopping_cart?.lineItems?.length) {
      let amount =
        current_refund?.priceUserRefund +
        current_refund?.refundRedeemAmount -
        current_shopping_cart?.summaries?.total_payment;
      if (amount >= 0 && appContext.shop_setting.notAllowRefundIfLowerPrice) {
        amount = 0;
      }
      return +Number(amount).toFixed(4);
    } else {
      return current_refund?.priceUserRefund;
    }
  }

  // SHOPPING CART
  function initNewRefundCart() {
    let new_shopping_cart = new RefundCartModel();
    setCurrentShoppingCart({
      ...new_shopping_cart,
      payment_methods: methodDefaults,
      pricingApplied: pricingDefault,
    });
  }

  async function addItemCurrentCart(item, quantity = 1) {
    let _new_cart = await addItemCart(
      appContext.callAPI,
      cloneDeep(current_shopping_cart),
      item,
      quantity,
      appContext,
    );
    if (_new_cart) {
      setNewCurrentShoppingCart({ ..._new_cart, fields: ["lineItems"] });
    }
  }

  function removeItemCurrentCart(key, index) {
    let _new_cart = removeItemCart(current_shopping_cart, key, index);
    setNewCurrentShoppingCart({ ..._new_cart, fields: ["lineItems"] });
  }

  function updateCurrentSession({ ...args }) {
    setNewCurrentShoppingCart({
      ...current_shopping_cart,
      ...args,
      fields: args ? Object.keys(args) : [],
    });
  }

  function updateItemCurrentCart(
    orderLine,
    orderLineIndex,
    quantity,
    updateFields = [],
    autoFillLot = true,
  ) {
    let _new_cart = updateItemCart(
      appContext.shop_info,
      current_shopping_cart,
      orderLine,
      orderLineIndex,
      quantity,
      updateFields,
      autoFillLot,
    );
    setNewCurrentShoppingCart({
      ..._new_cart,
      fields: ["lineItems"],
    });
  }

  function updateCurrentCart({ ...args }) {
    const newCurrentCart = {
      ...current_shopping_cart,
      ...args,
      // payment_methods: args?.payment_methods || methodDefaults,
    };
    setCurrentShoppingCart(newCurrentCart);
  }

  const checkCartDiscover = async (currentCart) => {
    try {
      const oldCart = { ...current_shopping_cart };
      let newCurrentCart = cloneDeep(currentCart);
      let { fields: updateFields } = newCurrentCart;
      const location = appContext.current_location;
      let result = newCurrentCart;
      const oldLines = cloneDeep(oldCart.lineItems || []);
      let lineUpdate = [];
      const lineRemoved = newCurrentCart.lineItems?.filter(
        (line) => line.quantity === 0,
      );
      if (lineRemoved?.length) {
        lineRemoved.map((line) =>
          lineUpdate.push({ id: line.id, quantity: 0 }),
        );
      }
      const lineItems = newCurrentCart.lineItems?.filter(
        (item) => item.quantity > 0,
      );
      const currentOrderLines = lineItems?.map((line, index) => {
        let item = oldLines?.find((oldLine) => oldLine.id === line.id);
        let newItem = {
          ...(line.id && { id: line.id }),
          ...(!line.variantId &&
            !line.productTitle && {
              name: line.productName,
            }),
          ...(line.productId && {
            productId: line.productId,
          }),
          ...(line.variantId && {
            variantId: line.variantId,
          }),
          ...(line.variantId &&
            line.variantUnitId && {
              variantUnitId: line.variantUnitId,
            }),
          quantity: line.quantity,
          ...((isNumber(line.discountAmountPerLine) || !line.variantId) && {
            price: line.variantId
              ? Number(
                  (
                    line.originalPrice -
                    (isNumber(line.discountAmountPerLine)
                      ? line.discountAmountPerLine
                      : 0)
                  ).toFixed(4),
                )
              : line.price,
          }),
          ...(line.isShipping !== null && {
            requiresShipping: line.requiresShipping || line.isShipping,
          }),
          ...(line.discountValue && {
            discountType:
              line.discountType === "percent"
                ? ManualDiscountType.Percent
                : ManualDiscountType.Amount,
            discountValue: line.discountValue,
          }),
          ...(line.attributes &&
            line.attributes?.length && {
              attributes: line.attributes,
            }),
          seq: index,
        };
        let updatedProperties = {};
        if (item && item.id) {
          if ((item.name || null) !== (newItem.name || null)) {
            updatedProperties.name = line.productName;
          }
          if (item.productId !== newItem.productId) {
            updatedProperties.productId = newItem.productId;
          }
          if (item.variantId !== newItem.variantId) {
            updatedProperties.variantId = newItem.variantId;
          }
          if (
            line?.fields?.includes("price") ||
            line?.fields?.includes("quantity") ||
            item.variantUnitId !== newItem.variantUnitId
          ) {
            updatedProperties.variantUnitId = newItem.variantUnitId;
          }
          if (
            line?.fields?.includes("variantUnitId") ||
            line?.fields?.includes("quantity") ||
            item.quantity !== newItem.quantity ||
            lineRemoved.length > 0
          ) {
            updatedProperties.quantity = newItem.quantity;
          }
          if (
            line?.fields?.includes("price") ||
            (item.discountValue || "") !== (line.discountValue || "")
          ) {
            updatedProperties = {
              ...updatedProperties,
              priceReason: line.discountDescription || "",
              discountType: newItem.discountType,
              discount: line.discountValue,
            };
          }
          if (line?.fields?.includes("quantity") && line.lotSupport) {
            updatedProperties.attributes = [
              {
                key: SysAttributeKeys.SYS_LINE_LOTS,
                value: JSON.stringify(
                  !!line?.lots?.length
                    ? line?.lots?.map((l) => ({
                        id: l.id,
                        quantity: l.quantity,
                        lotNo: l.lotNo,
                      }))
                    : [],
                ),
              },
            ];
          }
          if (item.seq != newItem.seq) {
            updatedProperties.seq = index;
            updatedProperties.quantity = newItem.quantity;
            updatedProperties.variantUnitId = newItem.variantUnitId;
          }
          if (item.requiresShipping !== newItem.requiresShipping) {
            updatedProperties.requiresShipping = newItem.requiresShipping;
          }
        } else {
          lineUpdate.push({
            id: line.id,
            ...newItem,
          });
        }
        if (Object.keys(updatedProperties).length > 0) {
          lineUpdate.push({
            id: line.id,
            ...updatedProperties,
          });
        }
        return newItem;
      });
      const totalQuantity = currentOrderLines?.reduce(
        (sum, cur) => sum + cur.quantity,
        0,
      );
      let newLineItems;
      if (
        updateFields?.includes("lineItems") ||
        updateFields?.includes("payment_methods")
      ) {
        newLineItems = {
          lineItems: lineUpdate || [],
          ...(totalQuantity === 0 && {
            shippingMethodId: 0,
            shipping: 0,
            shippingMethodName: "",
            discount: 0,
          }),
        };
      }
      let newDiscount;
      if (updateFields.includes("orderDiscountSelected")) {
        if (updateFields.includes("loyalty")) {
          const { loyalty } = newCurrentCart;
          newDiscount = {
            redeemPoint: loyalty?.rewardRedeemAmount,
          };
        }
        if (
          newCurrentCart.orderDiscountSelected?.discountTypeName === "amount" ||
          newCurrentCart.orderDiscountSelected?.discountTypeName === "percent"
        ) {
          let discountValue =
            newCurrentCart.orderDiscountSelected?.discountTypeName === "percent"
              ? newCurrentCart.orderDiscountSelected?.discountPercent || 0
              : newCurrentCart.orderDiscountSelected?.discountAmount || 0;
          if (discountValue == undefined) {
            discountValue = 0;
          } else {
            discountValue = Number(discountValue);
          }
          newDiscount = {
            ...newDiscount,
            discount: discountValue,
            discountReason: "ORDER DISCOUNT",
            discountType:
              newCurrentCart.orderDiscountSelected?.discountTypeName ===
              "percent"
                ? ManualDiscountType.Percent
                : ManualDiscountType.Amount,
            coupons: [],
          };
        } else if (
          newCurrentCart.orderDiscountSelected?.discountTypeName === "coupon" ||
          !newCurrentCart.orderDiscountSelected?.discountTypeName
        ) {
          newDiscount = {
            ...newDiscount,
            discount: 0,
            coupons: newCurrentCart.orderDiscountSelected?.coupons || [],
          };
        }
      }
      const newAttributes = updateFields?.includes("noteAttributes") && {
        attributes: newCurrentCart.noteAttributes || null,
      };
      const newPricingList = updateFields?.includes("pricingApplied") && {
        pricingListId: newCurrentCart?.pricingApplied?.id || null,
      };
      const newShippingFee = updateFields?.includes("summaries") && {
        shipping: newCurrentCart.summaries?.shipping_fee,
        ...(newCurrentCart.summaries?.shipping_fee > 0 ||
        newCurrentCart?.shippingMethodId
          ? {
              shippingMethodId: newCurrentCart?.shippingMethodId,
              ...(!newCurrentCart?.shippingMethodId && {
                shippingMethodName: newCurrentCart?.shippingMethodName,
              }),
            }
          : { shippingMethodId: 0, shippingMethodName: "" }),
        ...(newCurrentCart.isShipping &&
          newCurrentCart.customerId &&
          !newCurrentCart.shippingCountryId && {
            customerId: newCurrentCart.customerId,
          }),
      };
      const newOrderNote = updateFields?.includes("note") && {
        note: newCurrentCart.note,
      };
      const newTaxData = updateFields?.includes("taxData") && {
        eInvoiceInfo: {
          ...newCurrentCart.taxData?.eInvoiceInfo,
        },
      };
      const newCustomer = updateFields?.includes("customer")
        ? newCurrentCart?.customer?.key || newCurrentCart?.customer?.id
          ? {
              customerId:
                newCurrentCart.customer?.key || newCurrentCart.customer?.id,
              fullName:
                newCurrentCart.customer?.lastName ||
                newCurrentCart.customer?.firstName
                  ? `${newCurrentCart.customer?.lastName || ""} ${
                      newCurrentCart.customer?.firstName || ""
                    }`.trim()
                  : "",
              phone: newCurrentCart.customer?.phone,
              email: newCurrentCart.customer?.email,
            }
          : {
              fullName: "",
              phone: "",
              email: "",
              customerId: null,
              shippingAddress: null,
              shippingCountryId: null,
              shippingProvinceId: null,
              shippingDistrictId: null,
              shippingWardId: null,
            }
        : null;

      const newSaleMan = updateFields?.includes("salesMan") && {
        saleUserId: newCurrentCart.salesMan?.id || null,
      };
      const ignoreLoyalty = updateFields?.includes("ignoreLoyalty") && {
        disallowLoyaltyProgram: newCurrentCart?.ignoreLoyalty || false,
      };
      const paymentMethodActive =
        newCurrentCart?.payment_methods?.find(
          (method) => method.enable && !method.isHidden,
        )?.name || "Tiền mặt";
      let needUpdating = false;
      if (
        !!newCustomer ||
        !!newAttributes ||
        !!newPricingList ||
        !!newShippingFee ||
        !!newOrderNote ||
        !!newDiscount ||
        !!newTaxData ||
        !!newLineItems ||
        !!newSaleMan ||
        !!ignoreLoyalty
      ) {
        needUpdating = true;
      }
      if (!newCurrentCart?.id) {
        setLoadingRefund(true);
        const newCoupons =
          newCurrentCart.orderDiscountSelected?.discountTypeName === "coupon"
            ? newCurrentCart.orderDiscountSelected?.coupons
            : [];
        const eInvoiceInfo = newCurrentCart.taxData?.eInvoiceInfo?.name
          ? {
              ...newCurrentCart.taxData?.eInvoiceInfo,
              submit: true,
            }
          : null;
        await createCheckoutSession(appContext.callAPI, {
          storeId: storeId,
          channel: "pos",
          ...((newCurrentCart.customer?.key || newCurrentCart.customer?.id) && {
            customerId:
              newCurrentCart.customer?.key || newCurrentCart.customer?.id,
            fullName:
              newCurrentCart.customer?.lastName ||
              newCurrentCart.customer?.firstName
                ? `${newCurrentCart.customer?.lastName || ""} ${
                    newCurrentCart.customer?.firstName || ""
                  }`.trim()
                : "",
            ...(newCurrentCart.customer?.phone && {
              phone: newCurrentCart.customer?.phone,
            }),
            ...(newCurrentCart.customer?.email && {
              email: newCurrentCart.customer?.email,
            }),
          }),
          ...(newCoupons?.length && {
            coupons: newCoupons,
          }),
          ...newDiscount,
          attributes: [
            ...(newCurrentCart.noteAttributes || []),
            {
              key: SysAttributeKeys.SYS_REF_ORDER,
              value: current_order.orderId?.toString() || "",
            },
          ],
          ...(eInvoiceInfo && {
            eInvoiceInfo: eInvoiceInfo,
          }),
          lineItems: currentOrderLines || [],
          ...(newCurrentCart?.pricingApplied?.id && {
            pricingListId: newCurrentCart?.pricingApplied?.id,
          }),
          shipping: newCurrentCart.summaries?.shipping_fee,
          note: newCurrentCart.note,
          locationId: location?.id,
          createdUserId: appContext?.auth?.user?.id,
          allowPurchaseWhenSoldOut:
            appContext.shop_setting.allowOrderProductOutOfStock,
          saleUserId:
            newCurrentCart.salesMan?.id ||
            salesMan?.id ||
            newCurrentCart.customer?.id ||
            null,
          paymentMethodName: paymentMethodActive,
          disallowLoyaltyProgram: true,
        })
          .then((res) => {
            if (res) {
              result = { ...res };
            }
          })
          .catch((err) => {});
      } else if (needUpdating) {
        setLoadingRefund(true);
        await updateCheckoutSession(appContext.callAPI, newCurrentCart?.id, {
          ...newCustomer,
          ...newDiscount,
          ...newAttributes,
          ...newPricingList,
          ...newShippingFee,
          ...newOrderNote,
          ...newTaxData,
          ...newLineItems,
          ...newSaleMan,
          ...ignoreLoyalty,
        }).then(async (res) => {
          if (res && !res?.error && res?.status === 200) {
            result = { ...res };
          } else {
            result = await getCheckoutSession(
              appContext.callAPI,
              newCurrentCart?.id,
            );
          }
        });
      }
      //reset loyalty redeem
      if (newCurrentCart?.loyalty?.redeemConfig) {
        const { loyalty } = newCurrentCart;
        const redeemUsedAmount = await checkValidRedeem(appContext, {
          productHasPromotion: false,
          currentCart: newCurrentCart,
        });
        newCurrentCart.loyalty = {
          ...loyalty,
          redeemUsedAmount,
        };
        newCurrentCart = {
          ...newCurrentCart,
          summaries: {
            ...newCurrentCart.summaries,
            total_payment: priceSubTotal({
              totalPrice: result.total_price,
              redeemUsedAmount: newCurrentCart.loyalty.redeemUsedAmount,
            }),
          },
        };
      }
      await processingDiscover({
        currentCart: newCurrentCart,
        newCart: result,
        showGlobalToast: layout.showGlobalToast,
        appContext,
        methodDefaults,
        intl,
        isRefund: true,
      })
        .then((res) => {
          updateCurrentCart({ ...res, ready: true });
          if (
            !res?.requiresShipping &&
            !!newCurrentCart.summaries?.shipping_fee
          ) {
            layout.showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.Không áp dụng phí vận chuyển vì có sản phẩm không hỗ trợ vận chuyển`,
                defaultMessage:
                  "Không áp dụng phí vận chuyển vì có sản phẩm không hỗ trợ vận chuyển",
              }),
            );
          }
          setLoadingRefund(false);
        })
        .catch((e) => {
          layout.showGlobalToast(
            "error",
            intl.formatMessage({
              id: `notifications.${"Đã có lỗi xảy ra, xin vui lòng thử lại."}`,
              defaultMessage: "Đã có lỗi xảy ra, xin vui lòng thử lại.",
            }),
          );
        });
    } catch (e) {
      updateCurrentCart({
        ...currentCart,
        lineItems: currentCart?.lineItems,
      });
      setLoadingRefund(false);
    }
    setNewCurrentShoppingCart(null);
  };

  const onUpdateUnit = (currentLineItem, unit) => {
    const currentOrderLines = [...current_shopping_cart?.lineItems];
    let indexChange =
      currentOrderLines &&
      currentOrderLines.findIndex((item) => item.id === currentLineItem.id);
    let lineUpdate = { ...currentOrderLines[indexChange] };
    currentOrderLines[indexChange] = {
      ...lineUpdate,
      selectedUnit: unit,
      variantUnitId: unit.id,
      price: unit.price,
      totalPrice: unit.price,
      originalPrice: unit.price,
      isCalculatePromotion: true,
      discountAmount: 0,
      discountAmountPerLine: 0,
      discountPromotion: 0,
      discountType: null,
      discountTypeId: null,
      discountValue: 0,
      sku: unit.sku,
      barcode: unit.barcode,
      fields: ["variantUnitId"],
    };
    setNewCurrentShoppingCart({
      ...current_shopping_cart,
      lineItems: currentOrderLines,
      fields: ["lineItems"],
    });
  };
  const currentLocation = appContext.current_location;

  const setStatusES = (value) => {
    setScaleActive(value);
    setIsElectronicScale(value);
    // show note
    value
      ? layout.showGlobalToast(
          "default",
          initl.formatMessage({
            id: "notifications.Sử dụng mã vạch cân điện tử",
            defaultMessage: "Sử dụng mã vạch cân điện tử",
          }),
        )
      : layout.showGlobalToast(
          "default",
          initl.formatMessage({
            id: "notifications.Ngưng sử dụng mã vạch cân điện tử",
            defaultMessage: "Ngưng sử dụng mã vạch cân điện tử",
          }),
        );
  };

  //#endregion
  return (
    <RefundContext.Provider
      value={{
        current_refund,
        current_shopping_cart,
        current_order,
        isSubmitRefundOrderWaiting,
        loadingRefund,
        setLoadingRefund,
        updateItemCurrentRefund,
        addItemCurrentCart,
        removeItemCurrentCart,
        updateItemCurrentCart,
        updateCurrentCart,
        onUpdateUnit,
        lotDateState,
        setLotDateState,
        salesMan,
        setSalesMan,
        priceSubTotal,
        updateCurrentRefund,
        setIsSubmitRefundOrderWaiting,
        setBlockingPage,
        updateCurrentSession,
        initNewRefundCart,
        finalPrice,
        isElectronicScale,
        setStatusES,
        methodDefaults,
      }}
    >
      <BlockUnsavedPage
        blocked={blockingPage}
        blocked_title="Huỷ đổi trả"
        blocked_message={`Bạn muốn huỷ đổi trả cho đơn hàng ${current_order?.orderNumber}?`}
        button_cancel_title="Hủy đổi trả"
        button_continue_title="Tiếp tục đổi trả"
        onConfirmedCallback={async () => {
          if (current_shopping_cart?.id) {
            memoizedGetProductDetail.clearCache();
            await deleteCheckoutSession(
              appContext.callAPI,
              current_shopping_cart.id,
            );
          }
        }}
      >
        <LayoutBody size="ne" flexRow className="l-cart-container">
          {!appContext.shop_setting.allowRefundOrderAllLocation &&
          order &&
          order.locationId !== currentLocation.id ? (
            <RefundOtherLocationModal
              orderNumber={order.orderNumber}
              locationName={order.locationName}
            />
          ) : null}
          <div className="left-main d-flex flex-row">
            <div className="refund-items">
              <RefundListItem />
            </div>
            <div className="new-order-items">
              <div className="d-flex flex-column justify-content-between bg-white h-100">
                <div className="d-flex flex-column l-segment flex-stretch scrollable-y">
                  <div className="d-flex flex-row justify-content-between l-text-larger">
                    {initl.formatMessage({
                      id: "carts.refunds.Sản phẩm mua mới",
                      defaultMessage: "Sản phẩm mua mới",
                    })}
                    {enableElectricScale &&
                    current_refund?.refund_quantity_total > 0 ? (
                      <Button
                        tooltipTitle={
                          isElectronicScale
                            ? initl.formatMessage({
                                id: "carts.orders.Chọn để ngừng sử dụng cân điện tử",
                                defaultMessage:
                                  "Chọn để ngừng sử dụng cân điện tử",
                              })
                            : initl.formatMessage({
                                id: "carts.orders.Chọn để sử dụng cân điện tử",
                                defaultMessage: "Chọn để sử dụng cân điện tử",
                              })
                        }
                        size="sm"
                        light
                        icon="electronic_scale"
                        iconRight
                        status={`${isElectronicScale ? "primary" : "default"}`}
                        badge="F10"
                        onClick={() => setStatusES(!isElectronicScale)}
                      />
                    ) : null}
                  </div>
                  {current_refund?.refund_quantity_total > 0 ? (
                    <>
                      <RefundCartSearchBox />
                      <RefundCartFunction />
                    </>
                  ) : null}
                  {current_refund?.refund_quantity_total > 0 ? (
                    <>
                      {current_shopping_cart?.cart_quantity_total > 0 ? (
                        <div className="l-pb-8 l-pt-8 border-bottom">
                          {initl.formatMessage({
                            id: "carts.refunds.Sản phẩm mới",
                            defaultMessage: "Sản phẩm mới",
                          })}
                          <span className="badge badge-quantity ml-2">
                            {toFormattedNumber(
                              current_shopping_cart?.cart_quantity_total,
                            )}
                          </span>
                        </div>
                      ) : null}
                      <div className="scrollable-y ">
                        <RefundCartList />
                      </div>
                    </>
                  ) : (
                    <div className="l-segment flex-stretch">
                      <EmptyState
                        iconName="arrow_back"
                        description="Vui lòng chọn sản phẩm cần nhập trả trước khi thêm sản phẩm mua mới"
                      />
                    </div>
                  )}
                </div>
                <div className="l-segment">
                  <div
                    className=""
                    style={{ width: "fit-content", maxWidth: "280px" }}
                  >
                    <SaleUsers />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-main refund-cart-info">
            <RightCart />
          </div>
          {(loadingRefund || isSubmitRefundOrderWaiting) && (
            <div className="app-loading">
              <LoadingCircle overlay className="h-100" showLoading />
            </div>
          )}
        </LayoutBody>
      </BlockUnsavedPage>
    </RefundContext.Provider>
  );
}

const RefundContext = createContext({
  current_refund: {},
  current_shopping_cart: {},
  current_order: {},
  isSubmitRefundOrderWaiting: false,
  setIsSubmitRefundOrderWaiting: null,
  loadingRefund: false,
  setLoadingRefund: (value) => {},
  updateItemCurrentRefund: async (
    orderLine,
    orderLineIndex,
    restockableQuantity,
    isAutoFillLots = true,
  ) => {},
  updateCurrentCart: ({ ...args }) => {},
  updateCurrentSession: ({ ...args }) => {},
  addItemCurrentCart: (item, quantity) => {},
  removeItemCurrentCart: (key, index) => {},
  updateItemCurrentCart: () => {},
  onUpdateUnit: () => {},
  lotDateState: {},
  setLotDateState: null,
  salesMan: null,
  setSalesMan: null,
  priceSubTotal: () => {},
  updateCurrentRefund: () => {},
  setBlockingPage: () => {},
  initNewRefundCart: () => {},
  finalPrice: null,
  methodDefaults: null,
  isElectronicScale: false,
});

export const useRefundContext = () => {
  const context = useContext(RefundContext);
  return context;
};
