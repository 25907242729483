import { toFormattedNumber } from "infrastructures/format-helpers";
import { toNumber } from "lodash";
import { forwardRef } from "react";
import { CurrencyUtils } from "utils";

export const InputNumber = forwardRef(
  (
    {
      disabled,
      value,
      onFocus,
      max,
      min,
      onChange,
      placeholder,
      className,
      currency = false,
      autoFocus = false,
      id = null,
    },
    ref,
  ) => {
    const handleOnChange = (value) => {
      value = value.toString();
      if (value.length === 0) value = 0;
      if (value[0] === "0" && value.length > 0) {
        value = value.substring(1);
      }

      let num = toNumber(CurrencyUtils.numberWithCommasActive(value));
      if (num >= max) {
        num = max;
      }
      if (num <= min) {
        num = min;
      }
      onChange && onChange(num);
    };

    return (
      <div id={id} className="position-relative w-100">
        <input
          autoFocus={autoFocus}
          ref={ref}
          disabled={disabled}
          value={toFormattedNumber(value)}
          onChange={(e) => {
            handleOnChange(e.target.value);
          }}
          onFocus={(e) => {
            e?.currentTarget?.select();
            onFocus && onFocus(e);
          }}
          placeholder={placeholder}
          type="text"
          className={`text-right 
            w-100 hrv-next-input ${className}`}
        ></input>
        {/* ${currency ? "input-number" : "" }   */}
        {/* {currency && (
          <span className="pl-3 input-number-suffix d-flex align-items-center">
            ₫ 
          </span>
        )} */}
      </div>
    );
  },
);
