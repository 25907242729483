import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useAddressData from "hooks/useAddressData";
import { Selection } from "@haravan/react-components";
import { useIntl } from "react-intl";

const address_default = {
  countryId: 241,
  countryName: "Vietnam",
  provinceId: null,
  provinceName: "",
  districtId: null,
  districtName: "",
  wardId: null,
  wardName: "",
};

const Addresses = ({
  handleOnChange,
  onlyVN = false,
  countryId,
  provinceId,
  districtId,
  wardId,
}) => {
  const intl = useIntl();

  const [addresses, setAddresses] = useState(address_default);

  const { countries, provinces, districts, wards } = useAddressData({
    countryId: addresses.countryId,
    provinceId: addresses.provinceId,
    districtId: addresses.districtId,
  });

  const handleOnChangeAddress = (value) => {
    const newAddresses = { ...addresses, ...value };
    setAddresses(newAddresses);
    handleOnChange(newAddresses);
  };

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      countryId,
    }));
  }, [countryId]);

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      provinceId,
    }));
  }, [provinceId]);

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      districtId,
    }));
  }, [districtId]);

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      wardId,
    }));
  }, [wardId]);

  const notFoundContent = intl.formatMessage({
    id: "carts.orders.Không tìm thấy",
    defaultMessage: "Không tìm thấy",
  });
  return (
    <>
      {!onlyVN && (
        <div className="col-6 mb-3">
          <label className="pb-2">
            {intl.formatMessage({
              id: "payment-vouchers.Quốc gia",
              defaultMessage: "Quốc gia",
            })}
          </label>
          <Selection
            showSearch={true}
            placeholder={intl.formatMessage({
              id: "payment-vouchers.Quốc gia",
              defaultMessage: "Quốc gia",
            })}
            value={addresses.countryId}
            notFoundContent={notFoundContent}
            onChange={(value) => {
              const countrySelected = countries.find(
                (item) => item.id === value,
              );
              handleOnChangeAddress({
                countryId: value,
                countryName: countrySelected?.name,
                provinceId: null,
                provinceName: "",
                districtId: null,
                districtName: "",
                wardId: null,
                wardName: "",
              });
            }}
            className={`cart-input`}
          >
            {countries?.map((option) => {
              return (
                <Selection.Option value={option.id} key={option.id}>
                  {option.name}
                </Selection.Option>
              );
            })}
          </Selection>
        </div>
      )}
      <div className="col-6 mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "carts.orders.Tỉnh/Thành phố",
            defaultMessage: "Tỉnh/Thành phố",
          })}
        </label>
        <Selection
          showSearch={true}
          placeholder={`${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Tỉnh/Thành phố", defaultMessage: "Tỉnh/Thành phố" })}`}
          value={
            addresses.provinceId ||
            `${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Tỉnh/Thành phố", defaultMessage: "Tỉnh/Thành phố" })}`
          }
          notFoundContent={notFoundContent}
          onChange={(value) => {
            const provinceSelected = provinces.find(
              (item) => item.id === value,
            );
            handleOnChangeAddress({
              provinceId: value,
              provinceName: provinceSelected?.name,
              districtId: null,
              districtName: "",
              wardId: null,
              wardName: "",
            });
          }}
          className={`cart-input`}
        >
          {provinces?.map((option) => {
            return (
              <Selection.Option value={option.id} key={option.id}>
                {option.name}
              </Selection.Option>
            );
          })}
        </Selection>
      </div>
      <div className="col-6 mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "carts.orders.Quận/Huyện",
            defaultMessage: "Quận/Huyện",
          })}
        </label>
        <Selection
          showSearch={true}
          placeholder={`${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Quận/Huyện", defaultMessage: "Quận/Huyện" })}`}
          value={
            addresses.districtId ||
            `${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Quận/Huyện", defaultMessage: "Quận/Huyện" })}`
          }
          notFoundContent={notFoundContent}
          onChange={(value) => {
            const districtSelected = districts.find(
              (item) => item.id === value,
            );
            handleOnChangeAddress({
              districtId: value,
              districtName: districtSelected?.name,
              wardId: null,
              wardName: "",
            });
          }}
          className={`cart-input`}
        >
          {districts?.map((option) => {
            return (
              <Selection.Option value={option.id} key={option.id}>
                {option.name}
              </Selection.Option>
            );
          })}
        </Selection>
      </div>
      <div className="col-6 mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "carts.orders.Phường/Xã",
            defaultMessage: "Phường/Xã",
          })}
        </label>
        <Selection
          showSearch={true}
          placeholder={`${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Phường/Xã", defaultMessage: "Phường/Xã" })}`}
          value={
            addresses.wardId ||
            `${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Phường/Xã", defaultMessage: "Phường/Xã" })}`
          }
          notFoundContent={notFoundContent}
          onChange={(value) => {
            const wardSelected = wards.find((item) => item.id === value);
            handleOnChangeAddress({
              wardId: value,
              wardName: wardSelected?.name,
            });
          }}
          className={`cart-input`}
        >
          {wards?.map((option) => {
            return (
              <Selection.Option value={option.id} key={option.id}>
                {option.name}
              </Selection.Option>
            );
          })}
        </Selection>
      </div>
    </>
  );
};

Addresses.propTypes = {
  handleOnChange: PropTypes.func,
  onlyVN: PropTypes.bool,
};

export default Addresses;
