import {
  getActivityList,
  getTransactionHistory,
  getTransactionSummary,
} from "api/shift-repository";
import Button from "component-v2/buttons";
import EmptyState from "component-v2/empty-state";
import { useApp } from "contexts/app";
import { DateTimeShowType } from "infrastructures/format-helpers";
import { find, sumBy } from "lodash";
import { useEffect, useState } from "react";
import DateTimeShow from "component-v2/datetime-show";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import { useShiftContext } from "..";
import ShiftDetailActivities from "./shift-detail-activities";
import ShiftDetailCards from "./shift-detail-cards";
import ShiftDetailPaymentSummary from "./shift-detail-payment-summary";

export default function ShiftDetail() {
  const appContext = useApp();
  const { current_shift, showAddTransactionModal } = useShiftContext();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState(null);
  const [summaries, setSummaries] = useState(null);
  const [activities, setActivities] = useState(null);
  const [orders, setOrders] = useState(null);
  const isShiftCashTracking = appContext.shop_setting.shiftCashTracking;
  useEffect(() => {
    if (!current_shift) return;
    callGetMoreDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_shift]);

  async function callGetMoreDetails() {
    setLoading(true);
    const trans_sum_res = await getTransactionSummary(
      appContext,
      current_shift.id,
    );
    const _tran_summary = trans_sum_res?.data?.filter(
      (x) => x.gatewayName !== "cod",
    );
    setTransactions(_tran_summary);

    let cashTotal = current_shift.activityAmount;
    const cashTransaction = find(_tran_summary, (x) => {
      return x.gatewayName === "cash";
    });
    if (cashTransaction) {
      cashTotal +=
        cashTransaction.salesAmountTotal - cashTransaction.refundAmountTotal;
    }
    const salesTotal = sumBy(_tran_summary, (x) => {
      return x.salesAmountTotal;
    });
    const refundTotal = sumBy(_tran_summary, (x) => {
      return x.refundAmountTotal;
    });
    const amountTotal = salesTotal - refundTotal;
    setSummaries({ cashTotal, salesTotal, refundTotal, amountTotal });

    const trans_his_res = await getTransactionHistory(
      appContext,
      current_shift.id,
    );
    setOrders(trans_his_res?.data);

    const act_res = await getActivityList(appContext, current_shift.id);
    let _activities = act_res?.data?.reverse();
    // if(current_shift.status === 'opening' && _activities?.length){
    //   _activities.unshift({
    //     ..._activities[0],
    //     type: 'progress',
    //     shiftCashAmount: cashTotal || 0
    //   })
    // }
    setActivities(_activities);
    setLoading(false);
  }

  if (!current_shift) {
    return (
      <EmptyState iconName="arrow_back" description="Chọn ca để xem chi tiết" />
    );
  }
  let _same_day = true;
  let _open = current_shift.openAt ? new Date(current_shift.openAt) : null;
  let _close = current_shift.closedAt ? new Date(current_shift.closedAt) : null;
  if (_open && _close) {
    _same_day =
      _open.getFullYear() === _close.getFullYear() &&
      _open.getMonth() === _close.getMonth() &&
      _open.getDate() === _close.getDate();
  }
  return (
    <div className="shift-detail">
      <div className="shift-detail--header">
        <div className="flex-stretch">
          <div>
            <span className="font-weight-semibold">
              <DateTimeShow
                date={current_shift.openAt}
                type={DateTimeShowType.date}
              />
            </span>
            <span className="l-ml-8 text-secondary">
              <DateTimeShow
                date={current_shift.openAt}
                type={DateTimeShowType.custom}
                format={"HH:mm:ss"}
              />{" "}
              -{" "}
              {current_shift.status === "closed" ? (
                <DateTimeShow
                  date={current_shift.closedAt}
                  type={DateTimeShowType.custom}
                  format={_same_day ? "HH:mm:ss" : "DD/MM/YYYY HH:mm:ss"}
                />
              ) : (
                "Hiện tại"
              )}
            </span>
            {current_shift?.shiftManager ? (
              <span className="font-weight-semibold">
                {" "}
                | Quản lý ca: {current_shift?.shiftManager.userName}{" "}
              </span>
            ) : null}
          </div>
          <div className="l-mt-4">
            {current_shift.status === "closed" ? (
              <Badge light rounded status={"gray"}>
                Đã đóng
              </Badge>
            ) : (
              <Badge light rounded status={"green"}>
                Đang mở
              </Badge>
            )}
          </div>
        </div>
        {isShiftCashTracking && current_shift.status !== "closed" ? (
          <div className="flex-auto">
            <Button
              light
              className=" mr-3 d-inline-flex align-items-center "
              status="primary"
              title="Điều chỉnh"
              onClick={showAddTransactionModal}
            />
          </div>
        ) : null}
      </div>
      <div className="shift-detail--body position-relative">
        {loading ? <LoadingCircle size="md" className="h-100" /> : null}
        {!loading && summaries ? (
          <ShiftDetailCards summaries={summaries} />
        ) : null}
        {!loading && summaries ? (
          <ShiftDetailPaymentSummary
            summaries={summaries}
            transactions={transactions}
          />
        ) : null}
        {!loading ? (
          <ShiftDetailActivities activities={activities} orders={orders} />
        ) : null}
      </div>
    </div>
  );
}
