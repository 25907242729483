import { getCustomerDetails } from "api/customer-repository";
import { useApp } from "contexts/app";
import { useEffect, useState } from "react";
import { useRefundContext } from "..";
import { CustomerInfo } from "./customers/customerInfo";
import { Loading } from "@haravan/react-components";
import { SYSTEM_GUEST_EMAIL } from "infrastructures/utils";

export const Customers = ({ width }) => {
  const {
    current_shopping_cart,
    updateCurrentSession,
    updateCurrentCart,
    current_order,
  } = useRefundContext();
  const { callAPI } = useApp();
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  useEffect(() => {
    if (current_order?.customerId) {
      handleGetCustomerDetails(current_order?.customerId);
    }
  }, [current_order?.customerId]);

  const handleGetCustomerDetails = async (customerId) => {
    try {
      const result = await getCustomerDetails(callAPI, customerId);
      if (result?.data) {
        setCurrentCustomer(result.data || null);
        updateCurrentCart({
          customer:
            result?.data.email !== SYSTEM_GUEST_EMAIL ? result.data : null,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  if (loading) return <Loading size="pico" />;

  return (
    currentCustomer && (
      <div className="l-pb-8">
        <CustomerInfo
          customer={current_shopping_cart?.customer}
          removeCustomer={() => {
            updateCurrentSession({ customer: null, loyalty: null });
          }}
          showEditAddress={showEditAddress}
          setShowEditAddress={setShowEditAddress}
          reloadCustomer={() =>
            handleGetCustomerDetails(current_shopping_cart?.customer?.id)
          }
        />
      </div>
    )
  );
};
