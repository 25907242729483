import { useCartContext } from "..";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import Icon from "component-v2/icons/icons";
import React, { useEffect, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";
import { useIntl } from "react-intl";
import { Input } from "@haravan/react-components";
import _ from "lodash";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { useLayoutContext } from "contexts/Layout/layout-context";
const MAX_ATTR_COUNT = 10;
export const NoteAttributes = ({
  isDetail,
  showAttributeDetail,
  setShowAttributeDetail,
}) => {
  const intl = useIntl();
  const { current_shopping_cart, updateCurrentSession, updateCurrentCart } =
    useCartContext();
  const [show, setShow] = useState(false);
  const [attributes, setAttributes] = useState([{ key: "", value: "" }]);
  const { showGlobalToast } = useLayoutContext();
  const handleUpdateCartAttributes = () => {
    updateCurrentSession({
      noteAttributes: [...attributes],
    });
  };

  const handleAddAttributes = () => {
    if (attributes.length < MAX_ATTR_COUNT) {
      setAttributes((prev) => [...prev, { key: "", value: "" }]);
    }
  };
  const handleRemoveAttributes = (removeIndex) => {
    Promise.resolve()
      .then(() =>
        setAttributes((prev) => {
          let temp = [...prev];
          temp.splice(removeIndex, 1);
          return temp;
        }),
      )
      .then(() => validateNoteAttributes(false));
  };
  useEffect(() => {
    if (!!current_shopping_cart?.noteAttributes?.length) {
      setShowAttributeDetail(true);
      setAttributes([...current_shopping_cart?.noteAttributes]);
    } else {
      if (!current_shopping_cart?.noteAttributes) {
        setShowAttributeDetail(false);
      }
      setAttributes([{ key: "", value: "" }]);
    }
  }, [current_shopping_cart?.noteAttributes]);

  const validateNoteAttributes = (showToast = true) => {
    let validateElements = document.getElementById("attr-form");
    if (!validateElements) return;
    let inputs = Array.prototype.filter.call(
      validateElements,
      function (element) {
        return element.nodeName === "INPUT";
      },
    );
    let firstErr = null;
    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i];
      let inputValue = input.value;
      if (input.required && input.value?.trim()?.length === 0) {
        if (!input?.classList?.contains("err")) {
          input.classList.add("err");
        }
        if (!firstErr && showToast) {
          firstErr = input;
          showGlobalToast("error", "Tên thuộc tính không được bỏ trống");
        }
        continue;
      }
      if (
        input?.classList?.contains("attr-form-name") &&
        input.value?.length > 100
      ) {
        if (!input?.classList?.contains("err")) {
          input.classList.add("err");
        }
        if (!firstErr && showToast) {
          firstErr = input;
          showGlobalToast("error", "Độ dài tên thuộc tính không quá 100 ký tự");
        }
        continue;
      }
      if (
        input?.classList?.contains("attr-form-value") &&
        input.value?.length > 500
      ) {
        if (!input?.classList?.contains("err")) {
          input.classList.add("err");
        }
        if (!firstErr && showToast) {
          firstErr = input;
          showGlobalToast(
            "error",
            "Độ dài giá trị thuộc tính không quá 500 ký tự",
          );
        }
        continue;
      }
      let duplicateIndex = input?.classList?.contains("attr-form-name")
        ? inputs.findIndex(
            (x, index) =>
              x?.value === inputValue &&
              index !== i &&
              x?.classList?.contains("attr-form-name"),
          )
        : -1;
      if (duplicateIndex !== -1) {
        if (!input?.classList?.contains("err")) {
          input.classList.add("err");
        }
        if (!inputs[duplicateIndex].classList?.contains("err")) {
          inputs[duplicateIndex].classList.add("err");
        }
        if (!firstErr && showToast) {
          firstErr = input;
          showGlobalToast("error", "Tên thuộc tính không được trùng");
        }
        continue;
      }
      if (input?.classList?.contains("err")) {
        input?.classList?.remove("err");
      }
    }
    if (firstErr && showToast) {
      firstErr.focus();
      return false;
    } else {
      return true;
    }
  };
  const handleClearAttributes = () => {
    if (!current_shopping_cart?.noteAttributes?.length) {
      updateCurrentCart({ noteAttributes: null });
    } else {
      updateCurrentSession({
        noteAttributes: null,
      });
    }
    setAttributes(null);
    setShowAttributeDetail(false);
  };
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        handleCancel();
      }
      if (e.key === HOTKEY_EVENTS.F8) {
        handleSaveAttributes();
      }
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  const body = () => {
    return (
      <form id="attr-form">
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              <label className="margin-0 w-100 font-weight-semibold">
                {intl.formatMessage({
                  id: "carts.orders.Tên thuộc tính",
                  defaultMessage: "Tên thuộc tính",
                })}
              </label>
              <label className="margin-0 w-100 font-weight-semibold">
                {intl.formatMessage({
                  id: "carts.orders.Giá trị",
                  defaultMessage: "Giá trị",
                })}
              </label>
              <div
                className="flex-auto btn-close"
                style={{ height: "max-content", padding: "0.2rem" }}
              ></div>
            </div>
            {attributes?.map((attribute, index) => {
              return (
                <div
                  key={`-${index}`}
                  className="d-flex l-mb-8 gap-8 align-items-center"
                >
                  <Input
                    placeholder="Nhập tên thuộc tính"
                    value={attribute?.key}
                    onChange={(value) => {
                      attributes[index] = {
                        key: value,
                        value: attributes[index]?.value,
                      };
                    }}
                    autoFocus={true}
                    onFocus={(_, e) => {
                      e.currentTarget.select();
                    }}
                    onBlur={(_, e) => {
                      if (
                        e.currentTarget?.value?.length > 0 &&
                        e.currentTarget?.value?.length <= 100
                      ) {
                        e.currentTarget?.classList?.remove("err");
                      }
                    }}
                    required={true}
                    className="attr-form-name"
                  />
                  <Input
                    placeholder="Nhập giá trị"
                    value={attribute?.value}
                    onChange={(value) => {
                      attributes[index] = {
                        key: attributes[index]?.key,
                        value,
                      };
                    }}
                    onFocus={(_, e) => e.currentTarget.select()}
                    onBlur={(_, e) => {
                      if (e.currentTarget?.value?.length <= 500) {
                        e.currentTarget?.classList?.remove("err");
                      }
                    }}
                    className="attr-form-value"
                  />
                  <div className="h-100 l-ml-8 cursor-pointer d-flex align-items-center">
                    <div
                      className="flex-auto btn-close"
                      onClick={() => handleRemoveAttributes(index)}
                      style={{ height: "max-content", padding: "0.2rem" }}
                    >
                      <Icon name="close" />
                    </div>
                  </div>
                </div>
              );
            })}
            {attributes?.length < MAX_ATTR_COUNT && (
              <span
                className="d-inline-flex align-items-center cursor-pointer"
                onClick={handleAddAttributes}
              >
                <Icon
                  name="add"
                  color="var(--contentAccent)"
                  className="l-mr-4"
                />
                <span>Thêm thuộc tính</span>
              </span>
            )}
          </div>
        </div>
      </form>
    );
  };
  const handleSaveAttributes = () => {
    if (attributes?.length > 0 && validateNoteAttributes()) {
      handleUpdateCartAttributes();
      setShow(false);
    } else if (attributes?.length < 0) {
      updateCurrentSession({
        noteAttributes: [],
      });
      setAttributes([]);
    }
  };
  const handleCancel = () => {
    setAttributes(
      current_shopping_cart?.noteAttributes?.length > 0
        ? [...current_shopping_cart?.noteAttributes]
        : [{ key: "", value: "" }],
    );
    setShow(false);
  };
  const footer = () => {
    return (
      <>
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <Button
            className="mr-2 light text-uppercase"
            onClick={handleCancel}
            title="Hủy"
            badge={HOTKEYS.ESC}
          />
          <Button
            status="primary"
            onClick={() => handleSaveAttributes()}
            disabled={attributes?.length <= 0}
            title="Xác nhận"
            badge={HOTKEYS.F8}
          />
        </div>
      </>
    );
  };
  return (
    <>
      {!current_shopping_cart?.noteAttributes?.length &&
      !isDetail &&
      !showAttributeDetail ? (
        <>
          <span
            className="cursor-pointer l-mr-8"
            onClick={() => {
              setShow(true);
              setShowAttributeDetail(true);
              updateCurrentCart({
                ...current_shopping_cart,
                noteAttributes: [],
              });
            }}
          >
            <Badge className="border-0" light status={"info"} size="L" rounded>
              <span className="d-inline-flex align-items-center">
                <Icon
                  name="add"
                  color="var(--contentAccent)"
                  className="l-mr-4"
                />
                <span>Thuộc tính</span>
              </span>
            </Badge>
          </span>
        </>
      ) : current_shopping_cart?.noteAttributes && attributes && isDetail ? (
        <div className="flex-auto">
          <div className="d-flex justify-content-between align-items-center l-pt-8">
            <p
              className="flex-auto content-accent cursor-pointer l-mr-16"
              onClick={() => setShow(true)}
            >
              {`Thuộc tính (${current_shopping_cart?.noteAttributes?.length})`}
            </p>
            <div className="flex-stretch l-mr-4 min-w-0 text-right">
              {current_shopping_cart?.noteAttributes?.length > 0 ? (
                <p className="content-primary text-truncate text-right">
                  {current_shopping_cart?.noteAttributes?.map(
                    (attribute, index) => (
                      <React.Fragment key={`${attribute?.key}-${index}`}>
                        {`${attribute?.key}: ${attribute?.value}${
                          index <
                          current_shopping_cart?.noteAttributes?.length - 1
                            ? ";"
                            : ""
                        }`}{" "}
                      </React.Fragment>
                    ),
                  )}
                </p>
              ) : (
                <span className="content-tertiary">Chưa có thuộc tính</span>
              )}
            </div>
            <div
              className="flex-auto cursor-pointer btn-close l-ml-8"
              onClick={() => handleClearAttributes()}
              style={{ height: "max-content", padding: "0.2rem" }}
            >
              <Icon name="close" />
            </div>
          </div>
        </div>
      ) : null}
      <GlobalModal
        headerTitle={<span>Thuộc tính</span>}
        footer={footer()}
        onCancel={handleCancel}
        body={body()}
        show={show}
        setShow={setShow}
        fixHeight={true}
      ></GlobalModal>
    </>
  );
};
