import React from "react";
import { CurrencyUtils } from "utils";
import * as HrvComponents from "@haravan/react-components";

import { useIntl } from "react-intl";
import { Input } from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import Logo from "component-v2/logos/logos";
const PartialPaymentInput = ({
  gatewaysSelected,
  hasExternalId,
  onChangeValue,
  value,
  type,
  name,
  handleClickClose,
  transaction_id,
  onChangeTransactionId,
  disableEditAmount,
}) => {
  const intl = useIntl();
  const handleInputChanged = (value) => {
    if (value.length === 0) value = 0;
    if (value[0] === "0" && value.length > 0) {
      value = value.substring(1);
    }
    const num = CurrencyUtils.numberWithCommasActive(value);
    onChangeValue(type, parseFloat(num));
  };

  const hanldeFocus = (e) => {
    e?.currentTarget?.select();
  };

  return (
    <div className="d-flex flex-row justify-content-between">
      <div className="d-flex pb-3 flex-row justify-content-between flex-grow-1">
        <div className="d-flex partial-info--value-wrapper">
          <div
            className="d-flex justify-content-center background-tertiary rounded-circle p-1"
            style={{ width: "40px", height: "40px" }}
          >
            {gatewaysSelected[type].isCustom ? (
              <HrvComponents.Avatar
                arrColor="gray"
                className="text-primary"
                shape="circle"
                content={gatewaysSelected[type].name}
              ></HrvComponents.Avatar>
            ) : (
              <Logo name={type} size="md" />
            )}
          </div>
          <span className="align-self-center mx-3 omni-flex-1 text-break">
            {name}
          </span>
        </div>
        <div
          className={
            "d-flex flex-row partial-value--value-wrapper justify-content-end"
          }
        >
          <div
            className={`d-flex align-items-start cart-input new-input-focused w-100`}
          >
            <Input
              value={CurrencyUtils.formatMoney(value || 0)}
              onChange={handleInputChanged}
              onFocus={hanldeFocus}
              type="text"
              className="text-right text-black"
              disabled={disableEditAmount || false}
            />
          </div>
          {hasExternalId && (
            <div className="ml-3 w-100">
              <Input
                placeholder={intl.formatMessage({
                  id: "carts.orders.Mã chuẩn chi",
                  defaultMessage: "Mã chuẩn chi",
                })}
                className="text-right text-black"
                type="text"
                value={transaction_id || ""}
                onChange={(value) => onChangeTransactionId(type, value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="text-center align-self-start mt-3 ml-4">
        <span className="cursor-pointer" onClick={() => handleClickClose(type)}>
          <Icon name={"close"} />
        </span>
      </div>
    </div>
  );
};

export default PartialPaymentInput;
