import { Assets } from "assets";
import "moment-timezone";
import {
  printOrderCustomTemplate,
  printTempOrderCustomTemplate,
} from "api/order-repository";
import { getHTMLPrintTemplate } from "api/settings-repository";
import { TemplateType } from "./print-template";

export async function getPrintTemplateCustomHTML({
  printSettings,
  appContext,
  location,
  order,
  refundOrderModel,
  shopLogoUrl,
  shopImageUrl,
  shopAddress,
  totalQtt,
  taxable,
  typeId,
  totalRefund,
  isNewOrder,
}) {
  function renderTotalQtt(lstProducts) {
    let totalQtt = 0;
    lstProducts.forEach((product) => {
      totalQtt += product.refundNumber;
    });
    return totalQtt;
  }
  const savedTemplate = await getHTMLPrintTemplate(appContext.callAPI, typeId);
  const no_additional_payment =
    (totalRefund ? totalRefund : refundOrderModel?.orderModel.totalRefunded) -
      (isNewOrder ? order.orderTotal : 0) >=
    0;
  const printData = {
    total_money_paid_by_customer: order.totalMoneyPaidByCustomer,
    ...(typeId === TemplateType.TEMP_BILL_HTML && {
      total_quantity: totalQtt,
      order_sub_total: order.subTotal,
      shipping_fee: order.orderShippingFee,
      total_tax_included: order.totalTaxIncluded,
      total_tax_not_included: order.totalTaxNotIncluded,
      discount_allocations: order.discountAllocations,
      total_paid: order.totalPaid,
      amount_paid: order.amountPaid,
      amount_to_pay:
        order.totalPaid > order.amountPaid && order.amountPaid !== 0
          ? order.totalPaid - order.amountPaid
          : null,
      amount_refunded: order.amountRefuned,
      total_refunded: order.totalRefunded,
      redeem_name: order.redeemName,
      redeem_used_amount: order.redeemUsedAmount,
      redeem_discount: order.redeemDiscount,
      redeem_discount_type: order.redeemDiscountType,
      transactions: order.transactions?.map((t) => ({
        type: t.type,
        transaction_name: t.transactionName,
        gateway: t.gateway,
        transaction_amount: t.transactionAmount,
        payment_method_name: t.paymentMethodName,
        amount_transaction: t.amountTransaction,
      })),
      note_attributes: order.noteAttributes,
      order_notes: order.orderNotes,
      shop_info: {
        location_name: location.locationName,
        shop_address: shopAddress,
        shop_phone: location.phone,
        shop_website: printSettings.websiteUrl,
        shop_email: location.email,
      },
      sales_man: order.userName,
      order_date: order.orderDate,
      customer: {
        customer_id: order.customerId,
        customer_name: order.shipFullName
          ? order.shipFullName
          : order.billFullName !== " " && order.billFullName
          ? order.billFullName
          : null,
        customer_phone: order.shippingPhone || order.billingPhone,
        customer_email: order.customerEmail || order.billingEmail,
      },
      shipping_address: {
        address: order.shippingAddress || order.billingAddress,
        ward_name: order.wardName,
        district_name: order.districtName,
        province_name: order.shippingProvinceName,
        country_name: order.shippingCountryName || order.billingCountryName,
      },
      taxable,
      order_products: order.orderProducts?.map((product) => ({
        product_name: product.productName,
        variant_value: product.variantValue,
        sku: product.sku,
        barcode: product.barcode,
        unit: product.unit,
        quantity: product.quantity,
        original_price: product.originalPrice,
        product_price: product.productPrice,
        total_price: product.totalPrice,
        discount_allocations: product.discountAllocations?.map((d) => ({
          name: d.name,
          value: d.value,
        })),
        properties: product.properties,
        restock_quantity: product.restockQuantity,
        returned_quantity: product.returnedQuantity,
        product_refunded_quantity:
          product.restockQuantity + product.returnedQuantity,
      })),
      shop_logo_url: shopLogoUrl || Assets.icon.hrv_logo_print,
      shop_image_url: shopImageUrl || Assets.icon.hrv_logo_print,
      acknowledgements: printSettings.acknowledgements,
      discount_amount: order.discountAmount,
    }),
    ...(typeId === TemplateType.CUSTOM_HTML_REFUND &&
      refundOrderModel && {
        is_new_order: isNewOrder,
        order_sub_total: order.subTotal,
        total_tax_included: order.totalTaxIncluded,
        total_tax_not_included: order.totalTaxNotIncluded,
        total_refunded: order.totalRefunded,
        refund_order: {
          order_products: refundOrderModel?.listOrderProducts?.map(
            (product) => ({
              product_name: product.productName,
              variant_value: product.variantValue,
              sku: product.sku,
              barcode: product.barcode,
              unit: product.unit,
              quantity: product.quantity,
              original_price: product.originalPrice,
              product_price: product.productPrice,
              total_price: product.totalPrice,
              discount_allocations: product.discountAllocations?.map((d) => ({
                name: d.name,
                value: d.value,
              })),
              properties: product.properties,
              restock_quantity: product.restockQuantity,
              returned_quantity: product.returnedQuantity,
              product_refunded_quantity:
                product.restockQuantity + product.returnableQuantity,
              refund_number: product.refundNumber,
            })
          ),
          transactions: refundOrderModel?.orderModel?.transactions?.map(
            (t) => ({
              type: t.type,
              transaction_name: t.transactionName,
              gateway: t.gateway,
              transaction_amount: t.transactionAmount,
              payment_method_name: t.paymentMethodName,
              amount_transaction: isNewOrder
                ? !appContext.shop_setting.notAllowRefundIfLowerPrice &&
                  t.amountTransaction -
                    order.subTotal -
                    order.totalTaxNotIncluded >
                    0
                  ? t.amountTransaction - order.subTotal
                  : 0
                : t.amountTransaction,
            })
          ),
          total_refunded: totalRefund
            ? totalRefund
            : refundOrderModel?.orderModel.totalRefunded,
          refund_total_quantity: renderTotalQtt(
            refundOrderModel?.listOrderProducts
          ),
          no_additional_payment: no_additional_payment,
          change_amount:
            (totalRefund
              ? appContext.shop_setting.notAllowRefundIfLowerPrice && isNewOrder
                ? order.subTotal
                : totalRefund
              : refundOrderModel?.orderModel.totalRefunded) - order.orderTotal,
          additional_amount:
            (totalRefund
              ? totalRefund
              : refundOrderModel?.orderModel.totalRefunded) -
            (isNewOrder ? order.orderTotal : 0),
        },
        transactions: order?.transactions?.map((t) => ({
          type: t.type,
          transaction_name: t.transactionName,
          gateway: t.gateway,
          transaction_amount: t.transactionAmount,
          payment_method_name: t.paymentMethodName,
          amount_transaction:
            t.paymentMethodName === "Tiền mặt"
              ? t.amountTransaction -
                (totalRefund
                  ? totalRefund
                  : refundOrderModel?.orderModel.totalRefunded)
              : t.amountTransaction,
        })),
      }),
  };
  const printTemplate = await (order.orderId
    ? printOrderCustomTemplate(appContext.callAPI, order.orderId, typeId, {
        ...printData,
      })
    : printTempOrderCustomTemplate(appContext.callAPI, typeId, {
        template_html: savedTemplate?.data?.templateHTML,
        ...printData,
      }));
  if (printTemplate?.error) {
    throw new Error(printTemplate?.message || "Có gì đó không ổn");
  }

  let template = `${printTemplate?.data?.data}`;
  return template;
}
