import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import * as HrvComponents from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import { debounce } from "lodash";
import LotDateList from "component-v2/LotDate/index";
import { useRefundContext } from "../../index";
import { getDecimalValue } from "utils/Commons";
import CurrencyShow from "component-v2/currency-show";
import { InputQuantity } from "component-v2/Input";
import SelectLotDateModal from "component-v2/LotDate/SelectLotDateModal";
import { DiscountAllocationType, productQuantityMax } from "screens-v2/carts-v2/cart-helpers.service";
import { OrderLineDiscountForm } from "../OrderLineDiscountForm";
import { useApp } from "contexts/app";
import { ImageCustom } from "component-v2/ImageCustom";
import { UnitSelection2 } from "component-v2/UnitSelection/index_backup";
import { getLotsData } from "api/order-repository";

export const RefundCartItem = (props) => {
  const { shop_info, shop_setting, callAPI } = useApp();
  const appContext = useApp();
  const [_quantity, setQuantity] = useState(() => props.item.quantity);
  const [showModal, setShowModal] = useState(false);
  const { removeItemCurrentCart, current_shopping_cart } = useRefundContext();
  const refundContext = useRefundContext();

  useEffect(() => {
    setQuantity(props.item.quantity);
  }, [props.item, props.item.quantity]);

  const { item, index, intl } = props;
  const enableSubtract = item.quantity > 1;
  // const units = item.units ? getUnits(item) : [];
  const orderLineErr = current_shopping_cart?.orderLineErr || {};
  const has_promotion = item.promotionRef;
  let promotionName = "";
  let textNotEdit = intl.formatMessage({
    id: "carts.orders.Sản phẩm khuyến mãi không được sửa giá",
    defaultMessage: "Sản phẩm khuyến mãi không được sửa giá",
  });
  const lineItemDiscount = (item?.discountAllocations?.map((d) =>
    d.type === DiscountAllocationType.LINE_DISCOUNT && !d.name ?
      {
        ...d,
        name: "Giảm giá tùy chỉnh"
      } : d)) || item.properties || [];
  if (lineItemDiscount) {
    promotionName = lineItemDiscount
      .map((element) => {
        if (element.name?.toLowerCase().startsWith('x-haravan-')) return null;
        return getName(element);
      })
      .join(", ");
  }
  function getName(promotion){
    let promotionName = promotion.name;
    if (promotion.value && promotion.value.length > 0) {
      let arrs = promotion.value.split(' - ');
      if(arrs.length > 1) {
        promotionName = arrs.slice(1).join(' ');
      }
    }
    return promotionName;
  }

  if (item.discountValue) {
    //Discount type = 2 : percent
    //Discount type = 1 : amount
    if (item.discountTypeId === 2) {
      item.price =
        Number((item.originalPrice -
        item.discountPromotion -
        ((item.originalPrice - item.discountPromotion) * item.discountValue) /
        100).toFixed(4));
    } else if (item.discountTypeId === 1) {
      let t = item.originalPrice - item.discountPromotion - item.discountValue;
      if (t < 0) t = 0;
      item.price = t;
    }
  }

  const handleOnChange = (e) => {
    let value = getDecimalValue(shop_info, e.target.value);
    if (value > productQuantityMax) {
      value = productQuantityMax;
    }
    if (!value) value = 1;
    debounceOnChange(value);
  };

  const debounceOnChange = useCallback(
    debounce((value) => {
      setQuantity(value);
      refundContext.updateItemCurrentCart(item, index, value, ["quantity"]);
    }, 500),
    [item]
  );

  const handleDecreaseQuantity = () => {
    let value = getDecimalValue(shop_info, _quantity - 1);
    if (value > 0) {
      setQuantity(value);
      debounceOnChange(value);
    }
  };

  const handleInCreaseQuantity = () => {
    let value = getDecimalValue(shop_info, _quantity + 1);
    if (value <= productQuantityMax) {
      setQuantity(value);
      debounceOnChange(value);
    }
  };

  const onChangeUnit = (currentLineItem, unit) => {
    refundContext.onUpdateUnit(currentLineItem, unit);
  };

  const onUpdateLotDate = (lotTarget) => {
    let indexChange = item.lots.findIndex((lot) => lot.id === lotTarget.id);
    let lots = [...item.lots];
    lots.splice(indexChange, 1, lotTarget);
    item.lots = lots;
    let totalQty = lots.reduce((total, lot) => total + lot.quantity, 0);
    refundContext.updateItemCurrentCart(item, indexChange, totalQty, ["quantity"], false);
  };

  const onDeleteLotDate = (lotTarget) => {
    let indexChange = props.index;
    let lots = item.lots.filter((lot) => lot.id !== lotTarget.id);
    item.lots = lots;
    let totalQty = lots.reduce((total, lot) => total + lot.quantity, 0) || 1;
    item.quantity = totalQty;
    refundContext.updateItemCurrentCart(item, indexChange, totalQty, ["quantity"], false);
  };

  const handleOpenModalLotDate = async () => {
    let lotsData = item.lotsData;
    if (!item.lotsData?.length) {
      lotsData = await getLotsData(
        callAPI,
        { ...item, productVariantsId: item.variantId },
        appContext
      )
    }
    const currentLineItems = [...current_shopping_cart?.lineItems];
    const index = current_shopping_cart.lineItems?.findIndex((l) => l.id === item.id);
    if (index !== -1) {
      currentLineItems[index].lotsData = lotsData;
    }
    refundContext.updateCurrentCart({
      lineItems: currentLineItems,
      items: currentLineItems
    })
    refundContext.setLotDateState({
      ...refundContext.lotDateState,
      currentOrderLineLotDate: { ...item, lotsData },
      isShowLotDateModal: true,
    });
  };

  const handleOnSaveLotDateModal = (selectedLots) => {
    const _selectedLots = selectedLots.filter((lot) => lot.quantity > 0);
    const currentLineOrderId =
      refundContext.lotDateState.currentOrderLineLotDate.id;
    let currentShoppingCartItems = _.cloneDeep(
      refundContext.current_shopping_cart.lineItems
    );

    let totalQty = _selectedLots.reduce(
      (total, lot) => total + lot.quantity,
      0
    );

    let currentOrderItem = currentShoppingCartItems.findIndex(
      (item) => item.id === currentLineOrderId
    );
    currentShoppingCartItems[currentOrderItem].lots = _selectedLots;
    refundContext.updateItemCurrentCart(
      currentShoppingCartItems[currentOrderItem],
      currentOrderItem,
      totalQty,
      ["quantity"],
      false
    );
    refundContext.setLotDateState({
      ...refundContext.lotDateState,
      isShowLotDateModal: false,
    });
  };

  const handleOnCloseLotDateModal = () => {
    refundContext.setLotDateState({
      ...refundContext.lotDateState,
      currentOrderLineLotDate: null,
      isShowLotDateModal: false,
    });
  };

  const renderOrderLineDiscountForm = useCallback(() => {
    return <OrderLineDiscountForm
      showModal={showModal}
      setShowModal={setShowModal}
      currentLineData={item}
      index={index}
    />
  }, [item, showModal])

  return (
    <React.Fragment>
      <div className="flex-column refund-cart-item-root">
        <div className="flex-row refund-cart-item">
          <div className=" flex-auto refund-cart-item-left">
            <span
              className="mr-3"
              onClick={() => removeItemCurrentCart(item.id, index)}
              style={{ cursor: "pointer", alignSelf: 'center' }}
            >
              <Icon name="delete" />
            </span>
            <div className="refund-items-img flex-shrink-0 mr-3">
              <ImageCustom
                src={item?.imageUrl}
                alt="product image"
                size="thumb"
                className="p-0"
              />
            </div>
          </div>
          <div className="flex-stretch refund-cart-item-middle ml-3">
            <p
              className="font-weight-medium  content-primary"
              style={{
                color: `${orderLineErr.variantId && orderLineErr.variantId === item.id ? "red" : ""}`,
              }}
            >
              {item.productName}
            </p>
            <p className="content-secondary l-mt-4">{item.variantTitle}</p>
            {item.barcode && (
              <p className="content-secondary l-mt-4">{item.barcode}</p>
            )}
            {item.pricingInValid && (
              <p className="content-warning l-mt-4">
                Không áp dụng với bảng giá hiện tại
              </p>
            )}
            {item.notAllowPromotion && (
              <p className="font-size-12 content-danger l-mt-4">
                {
                  <span>
                    {intl.formatMessage({
                      id: "carts.orders.Không áp dụng CTKM",
                      defaultMessage: "Không áp dụng CTKM",
                    })}
                  </span>
                }
              </p>
            )}
            {!!item.lineErrors?.length && item.lineErrors?.map((error, index) =>
              <p key={`${error.refData}-${index}`} className="content-danger l-mt-4">
                {
                  <span>
                    {intl.formatMessage({
                      id: `notifications.${error?.message}`,
                      defaultMessage: `${error?.message}`,
                    })}
                  </span>
                }
              </p>
            )}
            <p className="font-size-12 content-danger l-mt-4">
              {promotionName
                ? intl.formatMessage({
                  id: `settings.print-templates.${promotionName}`,
                  defaultMessage: promotionName,
                })
                : null}
            </p>
            {!current_shopping_cart?.payment_methods?.some(
              (method) => method.type === "cod" && method.enable
            ) &&
              !current_shopping_cart?.isShipping &&
              item.lotSupport ? (
              <LotDateList
                onUpdateLotDate={(lot) => {
                  onUpdateLotDate(lot);
                }}
                onDeleteLotDate={(lot) => {
                  onDeleteLotDate(lot);
                }}
                orderLine={item}
                handleOpenModalLotDate={handleOpenModalLotDate}
              />
            ) : null}
          </div>
          <div className="flex-auto refund-cart-item-right">
            {item.units && item.units.length > 0 && (
              // <HrvComponents.Badges status="primary" size="large">
              <UnitSelection2
                isDisabled={item.promotionRef}
                options={item.units}
                selectedOption={item.selectedUnit}
                onChange={(unit) => onChangeUnit(item, unit)}
              />
              // </HrvComponents.Badges>
            )}
          </div>
        </div>
        <div className="flex-row refund-cart-item-second-row">
          {/* <p className="content-primary">{item.price}</p> */}
          <div
            className="w-25"
            onClick={() => {
              if (!item.notAllowPromotion) {
                shop_setting.promotionEnable &&
                  !has_promotion &&
                  setShowModal(true);
              }
            }}
          >
            <HrvComponents.Tooltip
              title={`${has_promotion ? textNotEdit : ""}`}
              overlayClassName="tooltip-promotion"
            >
              <p
                className={`${shop_setting.promotionEnable && !item.notAllowPromotion
                  ? "cursor-pointer content-accent"
                  : ""
                  }`}
              >
                <CurrencyShow value={item.price} />
              </p>
            </HrvComponents.Tooltip>
            <p className="">
              {item.originalPrice > 0 &&
                Math.round(item.originalPrice) > Math.round(item.price) && (
                  <strike className="cursor-pointer content-tertiary">
                    <CurrencyShow value={item.originalPrice} />
                  </strike>
                )}
            </p>
          </div>

          <InputQuantity
            className={`refund-quantity-cart-item ${item.stockUnavailable ? "" : "input-quantity-unavailable"
              }`}
            enableSubtract={enableSubtract}
            enableAdd={true}
            disabled={false}
            value={_quantity}
            onChange={handleOnChange}
            max={productQuantityMax}
            onSubtract={() => enableSubtract && handleDecreaseQuantity()}
            onAdd={() => handleInCreaseQuantity()}
            onBlur={(e) => props?.setItemSelected(null)}
            onFocus={(e) => {
              props?.setItemSelected(index);
            }}
          />
          <div className="w-25 text-right">
            <CurrencyShow value={item.price * item.quantity} />
          </div>
        </div>
      </div>
      {renderOrderLineDiscountForm()}


      <SelectLotDateModal
        lotsSelected={refundContext.lotDateState.currentOrderLineLotDate?.lots}
        lotsData={refundContext.lotDateState.currentOrderLineLotDate?.lotsData}
        handleOnSave={handleOnSaveLotDateModal}
        onCloseModal={handleOnCloseLotDateModal}
        isShowModal={refundContext.lotDateState.isShowLotDateModal}
        handleModalAction={(value) =>
          refundContext.setLotDateState({
            ...refundContext.lotDateState,
            isShowLotDateModal: value,
          })
        }
      />
    </React.Fragment>
  );
};
