import * as HrvComponents from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import { memo, useEffect, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { PopupVatDetail } from "../../../component-v2/VAT/PopupVatDetail";
import { PopupVatInfo } from "../../../component-v2/VAT/PopupVatInfo";
import { actionVAT, taxStatusValue } from "../utils/utils";
const taxNameDefault = "Người mua không lấy hóa đơn";

export const VatComponent = memo(({ order, mutate }) => {
  const { showGlobalToast } = useLayoutContext();
  const [openPopupVat, setOpenPopupVat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxRequired, setTaxRequired] = useState(false);
  const [extendPopoverVat, setExtendPopoverVat] = useState(false);
  const [openPopupVatDetail, setOpenPopupVatDetail] = useState(false);
  const vatTrackingDetailUrl = order?.eInvoiceDataPrint?.data;
  const vatDataDetail = order?.eInvoiceData?.data;
  const appContext = useApp();
  const { id: orderId } = useParams();

  const {
    billingCountry,
    billingCountryName,
    billingProvince,
    billingProvinceName,
    billingDistrict,
    billingDistrictName,
    billingWard,
    billingWardName,
    billingCity,
    billingAddress,
    billingCompany,
    billingTaxCode,
    billingEmail,
    billingZipCode,
    eInvoiceInfo,
  } = order || {};

  useEffect(() => {
    const updatedData = {
      status: order?.eInvoiceData?.data?.[0]?.status || 0,
      address: {
        billingCountry: billingCountry || null,
        billingCountryName: billingCountryName || null,
        billingProvince: billingProvince || null,
        billingProvinceName: billingProvinceName || null,
        billingDistrict: billingDistrict || null,
        billingDistrictName: billingDistrictName || null,
        billingWard: billingWard || null,
        billingWardName: billingWardName || null,
        billingCity: billingCity || null,
        billingAddress: billingAddress || null,
      },
      billingCompany: billingCompany || null,
      billingTaxCode: billingTaxCode || null,
      billingEmail: billingEmail || null,
      billingAddress: billingAddress || null,
      billingZipCode: billingZipCode || null,
      eInvoiceInfo: eInvoiceInfo || {
        address: "",
        company: false,
        name: "",
        taxCode: "",
        email: "",
        save: true,
      },
    };
    setBillingData({
      ...updatedData,
    });
  }, [order]);

  const [billingData, setBillingData] = useReducer(
    (current, update) => ({
      ...current,
      ...update,
    }),
    {
      status: order?.eInvoiceData?.data?.[0]?.status || 0,
      address: {
        billingCountry: billingCountry || null,
        billingCountryName: billingCountryName || null,
        billingProvince: billingProvince || null,
        billingProvinceName: billingProvinceName || null,
        billingDistrict: billingDistrict || null,
        billingDistrictName: billingDistrictName || null,
        billingWard: billingWard || null,
        billingWardName: billingWardName || null,
        billingCity: billingCity || null,
        billingAddress: billingAddress || null,
      },
      billingCompany: billingCompany || null,
      billingTaxCode: billingTaxCode || null,
      billingEmail: billingEmail || null,
      billingAddress: billingAddress || null,
      billingZipCode: billingZipCode || null,
      eInvoiceInfo: eInvoiceInfo || {
        address: "",
        company: false,
        name: "",
        taxCode: "",
        email: "",
        save: true,
      },
    },
  );

  const intl = useIntl();

  const statusValue = taxStatusValue(billingData.status);

  const contentPopoverVAT = () => {
    const { Menu, MenuItem } = HrvComponents;
    const { action: actions } = statusValue;

    const handleOnClickActionPopup = (action) => {
      switch (action.id) {
        case actionVAT.detailMultiVAT.id:
          setExtendPopoverVat(false);
          setOpenPopupVatDetail(true);
          break;
        case actionVAT.detailVAT.id:
          window.open(vatTrackingDetailUrl, "_blank");
          break;
        default:
          break;
      }
    };

    if (!actions) return null;

    return (
      <Menu>
        {actions.map((action) => (
          <MenuItem
            key={action.id}
            disabled={
              (action.id === actionVAT.detailVAT.id && !vatTrackingDetailUrl) ||
              (action.id === actionVAT.detailMultiVAT.id &&
                (!vatDataDetail || vatDataDetail.length === 0))
            }
            onClick={() => handleOnClickActionPopup(action)}
          >
            {action.title}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const apiUrl = `/call/com_api/orders/${orderId}/einvoice_info`; // Replace with your actual API endpoint
  const updateBilling = async (payload) => {
    setLoading(true);
    try {
      const response = await appContext.callAPI("put", apiUrl, payload);

      if (response.data) {
        mutate({ ...order, ...{ eInvoiceInfo: payload } });
        setOpenPopupVat((prev) => !prev);
      } else {
        let message =
          response.errors || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
        showGlobalToast("error", message);
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
    setLoading(false);
  };

  const handleSavePopupVat = async (data) => {
    const updatedData = {
      ...billingData,
      ...data,
    };
    setBillingData(updatedData);
    setTaxRequired(true);

    const payloadBillingUpdate = {
      company: updatedData.eInvoiceInfo?.company,
      name: updatedData.eInvoiceInfo?.name,
      taxCode: updatedData.eInvoiceInfo?.taxCode,
      address: updatedData.eInvoiceInfo?.address,
      email: updatedData.eInvoiceInfo?.email,
      save: updatedData.eInvoiceInfo?.save,
    };

    updateBilling(payloadBillingUpdate);
  };

  const renderVatInfo = () => {
    const { billingCompany } = billingData;
    const { eInvoiceData } = order;
    return (
      <div className="l-section--body">
        {(eInvoiceInfo?.name ||
          billingCompany ||
          (eInvoiceData && eInvoiceData?.data?.length > 0)) && (
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between">
              {eInvoiceInfo?.name || billingCompany ? (
                <span style={{ maxWidth: "200px" }} className="text-truncate">
                  {eInvoiceInfo?.name || billingCompany}
                </span>
              ) : (
                <span style={{ maxWidth: "200px" }} className="text-truncate">
                  {taxNameDefault}
                </span>
              )}

              <div className="flex-auto cursor-pointer icon-w-14 icon-secondary btn-close l-ml-8">
                <HrvComponents.Popover
                  trigger="click"
                  onVisibleChange={(visible) => {
                    setExtendPopoverVat(visible);
                  }}
                  placement="bottomRight"
                  visible={extendPopoverVat}
                  content={contentPopoverVAT()}
                >
                  <HrvComponents.Button status="link-no-pding">
                    <Icon className={"cursor-pointer"} name={"three_dots"} />
                  </HrvComponents.Button>
                </HrvComponents.Popover>
              </div>
            </div>

            <Badge
              style={{ maxWidth: "fit-content" }}
              className="l-mb-4 mt-2"
              light
              rounded
              status={statusValue.badgeValue}
            >
              {intl.formatMessage({
                id: statusValue.title,
                defaultMessage: statusValue.title,
              })}
            </Badge>
          </div>
        )}

        {!eInvoiceInfo?.name &&
          !billingCompany &&
          !eInvoiceData?.data?.length && (
            <span className="content-tertiary">Chưa có thông tin hoá đơn</span>
          )}
      </div>
    );
  };

  const onCancelPopupVat = () => {
    setOpenPopupVat(false);
  };

  const onCancelPopupVatDetail = () => {
    setOpenPopupVatDetail(false);
  };

  if (
    !appContext?.shop_info?.taxable &&
    (!eInvoiceInfo || !billingCompany) &&
    !order.eInvoiceData?.data?.length
  )
    return null;

  return (
    <div
      className={`${
        order?.noteAttributes?.length > 0 ? "" : "flex-fill"
      } order-detail-right-section pt-0`}
    >
      <div className="l-section--with-title">
        <label
          className="content-accent cursor-pointer text-truncate"
          onClick={() => setOpenPopupVat((prev) => !prev.openPopupVat)}
        >
          {intl.formatMessage({
            id: "orders.searches.Hóa đơn VAT",
            defaultMessage: "Hóa đơn VAT",
          })}
        </label>
        {renderVatInfo()}
      </div>

      <PopupVatInfo
        isDetail={true}
        loading={loading}
        autoFillEmail={{
          title: "Sử dụng email của khách",
          value: order?.customer?.email || "",
        }}
        autoFillCompanyAddress={{
          title: "Sử dụng địa chỉ giao hàng",
          value: {
            countryId: order?.customer?.customerAddress?.[0].countryId,
            countryName: order?.customer?.customerAddress?.[0].countryName,
            provinceId: order?.customer?.customerAddress?.[0].provinceId,
            provinceName: order?.customer?.customerAddress?.[0].provinceName,
            districtName: order?.customer?.customerAddress?.[0].districtName,
            districtId: order?.customer?.customerAddress?.[0].districtId,
            wardId: order?.customer?.customerAddress?.[0].wardId,
            wardName: order?.customer?.customerAddress?.[0].wardName,
            city: order?.customer?.customerAddress?.[0].city,
            zipCode: order?.customer?.customerAddress?.[0].zipCode,
            countryCode: order?.customer?.customerAddress?.[0].countryCode,
            provinceCode: order?.customer?.customerAddress?.[0].provinceCode,
            districtCode: order?.customer?.customerAddress?.[0].districtCode,
            wardCode: order?.customer?.customerAddress?.[0].wardCode,
            address: order?.customer?.customerAddress?.[0].address1,
          },
        }}
        enableEdit={statusValue.id === 0}
        formData={billingData}
        onSave={(data) => {
          handleSavePopupVat(data);
        }}
        setOpen={(value) => setOpenPopupVat(value)}
        isOpen={openPopupVat}
        onCancel={onCancelPopupVat}
      />
      <PopupVatDetail
        billingData={billingData}
        vatDataDetail={vatDataDetail}
        setOpen={(value) => setOpenPopupVatDetail(value)}
        isOpen={openPopupVatDetail}
        onCancel={onCancelPopupVatDetail}
      />
    </div>
  );
});
