import { useApp } from "contexts/app";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const HomeRoute = ({ component: C, props: cProps, ...rest }) => {
  const { auth, current_location } = useApp();
  if (!auth.authenticated)
    return <Route render={(props) => <Redirect to={`intro`} />} />;
  if (!current_location)
    return (
      <Route
        render={(props) =>
          props.location.pathname === "/locations" ? (
            <C {...props} {...cProps} />
          ) : (
            <Redirect to={`/locations`} />
          )
        }
      />
    );

  return <Route {...rest} render={(props) => <C {...props} {...cProps} />} />;
};
export default HomeRoute;
