export const SaleSettingModels = (settings) => {
  return {
    "Tồn kho": [
      {
        title: "Bán hàng khi hết tồn kho",
        desc: "Khi hết tồn kho hệ thống vẫn cho phép Bán hàng. Hệ thống sẽ ghi nhận giá trị tồn kho âm",
        key: "allowOrderProductOutOfStock",
      },
      {
        title: "Ẩn sản phẩm hết hàng",
        desc: "Không hiển thị sản phẩm hết hàng trên danh sách sản phẩm",
        key: "hideProductOutOfStock",
        isHidden: settings["allowOrderProductOutOfStock"],
      },
      {
        title: "Tạo sản phẩm tùy ý",
        desc: "Cho phép nhân viên tạo sản phẩm ngoài danh mục hàng hóa đang có",
        key: "allowCreateCustomProduct",
      },
    ],
    "Đổi trả hàng": [
      {
        title: "Đổi hoặc trả hàng khác chi nhánh",
        desc: "Khách hàng mua hàng ở chi nhánh A có thể đổi hoặc trả hàng tại chi nhánh B",
        key: "allowRefundOrderAllLocation",
      },
      {
        title: "Chỉ cho phép đổi hàng",
        desc: "Chỉ cho phép đổi sản phẩm, không cho phép trả hàng",
        key: "onlyAllowExchange",
      },
      {
        title: "Không hoàn tiền cho khách khi đổi trả hàng có giá trị nhỏ hơn",
        desc: "Khách trả hàng và mua sản phẩm mới với giá trị thấp hơn sẽ không được hoàn lại số tiền chênh lệch",
        key: "notAllowRefundIfLowerPrice",
      },
      {
        title: "Đổi/Trả một lần",
        desc: "Chỉ cho phép đơn hàng được đổi hoặc trả một lần duy nhất",
        key: "refundOnlyOnceEnable",
      },
    ],
    "Đơn hàng": [
      {
        title: "Hủy đơn hàng tại Haravan POS",
        desc: "Cho phép nhân viên tại cửa hàng hủy đơn hàng",
        key: "allowCancelOrder",
      },
      {
        title: "Trạng thái đơn hàng mặc định là Đã xác thực",
        desc: "Các đơn hàng tại Haravan POS mặc định sẽ có trạng thái là Đã xác thực",
        key: "isConfirmOrderEnable",
      },
      {
        title: "Chỉ hiển thị đơn hàng có kênh bán hàng là Haravan POS",
        desc: "Đơn hàng bán từ các kênh khác sẽ không hiển thị trên Haravan POS",
        key: "allowDisplayOnlineOrder",
      },
      {
        title: "Gửi email xác nhận đơn hàng",
        desc: "Hệ thống sẽ tự động gửi email xác nhận đơn hàng đến khách hàng",
        key: "isSendEmailConfirm",
      },
      {
        title: "Tắt lựa chọn giao hàng sau",
        desc: "Màn hình bán hàng sẽ không hiển thị lựa chọn giao hàng",
        key: "hideOrderShippingState",
      },
      {
        title: "Nhập sản phẩm hàng loạt vào giỏ hàng",
        desc: "Cho phép nhân viên tạo đơn hàng bằng cách tải dữ liệu từ file excel",
        key: "isImportOrder",
      },
    ],
  };
};

export const SettingMenuModels = [
  {
    code: "settings.staffs.Quản lý nhân viên",
    title: "Quản lý nhân viên",
    desc_id:
      "settings.staffs.Cấu hình tài khoản và chi nhánh cho nhân viên bán hàng",
    desc: "Cấu hình tài khoản và chi nhánh cho nhân viên bán hàng",
    icon: "account_circle",
    link: "/settings/user",
  },
  {
    code: "settings.carts.Bán hàng",
    title: "Bán hàng",
    desc_id:
      "settings.carts.Chọn cấu hình tồn kho, cấu hình này sẽ sử dụng cho tất cả các chi nhánh",
    desc: "Chọn cấu hình tồn kho, cấu hình này sẽ sử dung cho tất cả các chi nhánh",
    icon: "shopping_cart",
    link: "/settings/sale",
  },
  {
    code: "settings.shifts.Quản lý ca",
    title: "Quản lý ca",
    desc_id: "settings.shifts.Cấu hình quản lý ca cho thiết bị",
    desc: "Cấu hình quản lý ca cho thiết bị",
    icon: "schedule",
    link: "/settings/shift",
  },
  {
    code: "settings.print-templates.Hoá đơn",
    title: "Hóa đơn",
    desc_id: "settings.print-templates.Cấu hình hoá đơn và máy in hoá đơn",
    desc: "Cấu hình hoá đơn và máy in hoá đơn",
    icon: "print",
    link: "/settings/bill",
  },
  {
    code: "settings.generals.Cấu hình chung",
    title: "Cấu hình chung",
    desc_id: "settings.generals.Cấu hình chung cho cửa hàng",
    desc: "Cấu hình chung cho cửa hàng",
    icon: "settings",
    link: "/settings/general",
  },
];
