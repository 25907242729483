import PropTypes from "prop-types";
import {
  LayoutCardSection,
  LayoutCardSectionSection,
} from "screens-v2/layouts/layouts/omni-layout-card";
import { FormattedMessage, useIntl } from "react-intl";
import { Textarea, Input } from "@haravan/react-components";
import { UploadImages } from "component-v2/upload-images";

const AdditionalInformation = ({ formData, onChangeData }) => {
  const intl = useIntl();
  return (
    <LayoutCardSection>
      <p className="font-weight-semibold font-size-16 pb-4 d-flex flex-column">
        <FormattedMessage
          id="payment-vouchers.Thông tin bổ sung <span>Không bắt buộc</span>"
          defaultMessage="Thông tin bổ sung <span>Không bắt buộc</span>"
          values={{
            span: (a) => (
              <span className="text-secondary font-weight-medium font-size-12">
                {a}
              </span>
            ),
          }}
        />
      </p>
      <LayoutCardSectionSection className={"row"}>
        <div className="col-12 col-md-6">
          <label className="pb-2">
            <FormattedMessage
              id="payment-vouchers.Mã tham chiếu"
              defaultMessage="Mã tham chiếu"
            />
          </label>
          <Input
            value={formData.refNumber}
            onChange={(value) => {
              onChangeData(value, "refNumber");
            }}
            type="text"
            className=" py-3 text-black hrv-ui-textarea"
            placeholder={intl.formatMessage({
              id: "payment-vouchers.Nhập mã tham chiếu",
              defaultMessage: "Nhập mã tham chiếu",
            })}
          />
        </div>
        <div className="col-12 col-md-6">
          <label className="pb-2">
            <FormattedMessage
              id="carts.orders.Ghi chú"
              defaultMessage="Ghi chú"
            />
          </label>
          <Textarea
            placeholder={intl.formatMessage({
              id: "carts.orders.Nhập ghi chú",
              defaultMessage: "Nhập ghi chú",
            })}
            className="l-section w-100 py-3 text-black"
            rows={1}
            autoResize={false}
            value={formData.notes}
            onChange={(value) => {
              onChangeData(value, "notes");
            }}
          />
        </div>
        <div className="col-12">
          <div>
            <p className="font-weight-semibold pb-2">
              <FormattedMessage
                id="payment-vouchers.Chứng từ"
                defaultMessage="Chứng từ"
              />
            </p>
            <p className="pb-4 font-size-12 text-secondary">
              <FormattedMessage
                id="payment-vouchers.Tải lên tối đa 2 ảnh"
                defaultMessage="Tải lên tối đa 2 ảnh"
              />
            </p>
            <div className="">
              <UploadImages
                maxFiles={2}
                listImage={formData.images}
                onSelectFile={(files) => {
                  onChangeData(files, "images");
                }}
                messageMaxFiles={intl.formatMessage({
                  id: "payment-vouchers.Tối đa chỉ 2 ảnh chứng từ thanh toán",
                  defaultMessage: "Tối đa chỉ 2 ảnh chứng từ thanh toán",
                })}
              />
            </div>
          </div>
        </div>
      </LayoutCardSectionSection>
    </LayoutCardSection>
  );
};

AdditionalInformation.propTypes = {
  formData: PropTypes.object,
  onChangeData: PropTypes.func,
};

export default AdditionalInformation;
