import React from "react";
import InputDecimal from "./InputDecimal";
import PropTypes from "prop-types";
import Button from "component-v2/buttons";

function InputQuantity({
  enableSubtract,
  enableAdd,
  value,
  disabled,
  onChange,
  onSubtract,
  onAdd,
  wrapperClassName,
  className,
  min,
  max,
  onBlur,
  onFocus,
  showAction = true,
  size = "sm",
  textRight = false,
}) {
  return (
    <div
      className={`d-flex flex-nowrap justify-content-center align-items-center ${wrapperClassName}`}
    >
      {showAction && (
        <Button
          light
          onClick={onSubtract}
          icon="remove"
          disabled={!enableSubtract}
          size="sm"
          plain
        />
      )}
      <InputDecimal
        className={`hrv-next-input quantity_cart_item mx-2 ${textRight ? "text-right" : "text-center"} hrv-input-${size} ${className}`}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        min={min}
        max={max}
        onBlur={onBlur}
        onFocus={onFocus}
        maxWidthWidth={90}
      />
      {showAction && (
        <Button
          light
          onClick={onAdd}
          icon="add"
          disabled={!enableAdd}
          size="sm"
          plain
        />
      )}
    </div>
  );
}

InputQuantity.defaultProps = {
  enableSubtract: true,
  enableAdd: true,
  onChange: () => {},
  onSubtract: () => {},
  onAdd: () => {},
  onBlur: () => {},
};

InputQuantity.propTypes = {
  enableSubtract: PropTypes.bool,
  enableAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onSubtract: PropTypes.func,
  onAdd: PropTypes.func,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  showAction: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md"]),
  textRight: PropTypes.bool,
};

export default InputQuantity;
