export const ERRORS = {
  LOGIN_REQUIRED: "LOGIN_REQUIRED",
  USER_NOT_PERMISSION: "USER_NOT_PERMISSION",
  NETWORK_ERROR: "NETWORK_ERROR",
};

export const TOKEN_EXPIRED = "tokenExpired";
export const UNAUTHORIZED_REQUEST = "unauthorized";
export const AUTHORIZED = "authorized";
export const UNPERMISSION_REQUEST = "unpermission";

export const HTTP_STATUS_TOKEN_EXPIRED = 456;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;

export const ROLES = {
  API_PRODUCT_READ: "com_api.product.read",
  API_PRODUCT_WRITE: "com_api.product.write",
  API_ORDER_WRITE: "com_api.order.write",
  API_LOCATION_READ: "com_api.location.read",
  API_LOCATION_WRITE: "com_api.location.write",
};

// đọc theo css màu sắc trong main.css. hệ thống chỉ nên dùng các màu này
export const COLORS = {
  primary: "--primary",
  secondary: "--secondary",
};

export const ScaleBarcodeMode = new Map([
  [
    1,
    {
      name: "2-5-5-1",
      barcodeLength: 5,
      desc: "SBM_2551",
    },
  ],
  [
    2,
    {
      name: "2-6-4-1",
      barcodeLength: 6,
      desc: "SBM_2641",
    },
  ],
  [
    3,
    {
      name: "2-4-6-1",
      barcodeLength: 4,
      desc: "SBM_2461",
    },
  ],
  [
    4,
    {
      name: "2-6-5-1",
      barcodeLength: 6,
      desc: "SBM_1651",
    },
  ],
  [
    5,
    {
      name: "2-5-6-1",
      barcodeLength: 5,
      desc: "SBM_1561",
    },
  ],
]);

export const HOTKEYS = {
  ESC: "ESC", // or ESCAPE
  ENTER: "ENTER", // or RETURN
  BACKSPACE: "BACKSPACE",
  TAB: "TAB",
  CLEAR: "CLEAR",
  SPACE: "SPACE",
  UP: "UP",
  DOWN: "DOWN",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  HOME: "HOME",
  END: "END",
  PAGE_UP: "PAGEUP",
  PAGE_DOWN: "PAGEDOWN",
  INSERT: "INS",
  DELETE: "DEL", // or DELETE
  F1: "F1",
  F2: "F2",
  F3: "F3",
  F4: "F4",
  F5: "F5",
  F6: "F6",
  F7: "F7",
  F8: "F8",
  F9: "F9",
  F10: "F10",
  F11: "F11",
  F12: "F12",
};

// event.key trả về khi bấm phím
export const HOTKEY_EVENTS = {
  ESC: "Escape",
  UP: "ArrowUp",
  DOWN: "ArrowDown",
  LEFT: "ArrowLeft",
  RIGHT: "ArrowRight",
  ENTER: "Enter",
  HOME: "Home",
  F1: "F1",
  F2: "F2",
  F3: "F3",
  F4: "F4",
  F5: "F5",
  F6: "F6",
  F7: "F7",
  F8: "F8",
  F9: "F9",
  F10: "F10",
  F11: "F11",
  F12: "F12",
};

export const HOTKEY_SCOPES = {
  MODAL: "scopeModal", // dùng cho các Modals, Slide Cards,...
  CART: "scopeCart", // dùng trong màn hình Tạo đơn
  REFUND: "scopeRefund", // dùng trong màn hình Hoàn trả
  PRODUCT: "scopeProduct", // dùng trong màn hình Tìm kiếm sản phẩm
};

export const PaymentMethodEnum = {
  MOMO_QR: 21,
  MOMO_VTS_QR: 45,
  ZALOPAY_VIETQR: 48,
  ZaloPay_ApplePay: 49,
  ZaloPay_QRCode: 22,
  ZaloPay_ATM: 23,
  ZaloPay_CC: 24,
  VNPayment: 8,
  VNPay_ApplePay: 51,
};

export const MOMO_MIN_AMOUNT = 1000;
export const BANK_QR_MIN_AMOUNT = 10000;
