import { InputDatetimePicker } from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { useIntl } from "react-intl";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";
import {
  LayoutCardItemStack,
  LayoutCardItemStackWrapper,
} from "screens-v2/layouts/layouts/omni-layout-card";

export default function DailyReportHeader({
  user,
  setUser,
  dateRange,
  setDateRange,
  loading,
}) {
  const intl = useIntl();
  const { current_location, staff_users } = useApp();
  const currentLocation = current_location || {};
  const staff =
    staff_users && staff_users.length
      ? [
          {
            id: 0,
            name: intl.formatMessage({
              id: "reports.daily-report.Tất cả nhân viên",
              defaultMessage: "Tất cả nhân viên",
            }),
          },
          ...staff_users,
        ]
      : [{ id: 0, name: "Tất cả nhân viên" }];

  return (
    <LayoutCardItemStackWrapper>
      <LayoutCardItemStack className="min-w-200">
        <InputDatetimePicker
          disabled={loading}
          placement="right"
          showCustomRangeLabel={false}
          showRanges={false}
          alwaysShowCalendars={true}
          singleDatePicker={true}
          timePicker={false}
          showIconClose={false}
          minDate="01-01-2014"
          maxDate={new Date()}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={(startDate, endDate) => {
            setDateRange({ startDate, endDate });
          }}
        ></InputDatetimePicker>
      </LayoutCardItemStack>
      <LayoutCardItemStack className="min-w-200">
        <SelectionCustom
          options={staff}
          idField="id"
          valueField="name"
          disabled={loading}
          style={{ lineHeight: "1.6rem", width: "100%" }}
          placeholder={intl.formatMessage({
            id: "reports.daily-report.Nhân viên bán hàng",
            defaultMessage: "Nhân viên bán hàng",
          })}
          value={user?.id && Number(user?.id)}
          onChange={(value) => {
            if (value === 0) {
              setUser({ id: 0, name: "Tất cả nhân viên" });
            } else {
              const user = staff.find((u) => u.id === value);
              setUser(user);
            }
          }}
          title={user ? user.name : ""}
        >
          {staff?.map((user) => {
            return (
              <SelectionCustom.Option key={user.id} value={user.id}>
                {user.name}
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
      </LayoutCardItemStack>
      <LayoutCardItemStack>
        <div className="d-flex align-items-center location-info">
          <Icon size="2" name="storefront" />
          <span className="ml-2">{currentLocation?.locationName}</span>
        </div>
      </LayoutCardItemStack>
    </LayoutCardItemStackWrapper>
  );
}
