import { Assets } from "assets";
import "./index.css";
import Button from "component-v2/buttons";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useEffect } from "react";
import { useApp } from "contexts/app";

export default function HaraLoyaltyScreen() {
  const { shop_loyalty } = useApp();
  const layoutContext = useLayoutContext();
  useEffect(() => {
    layoutContext.setPageTitle("Haraloyalty");
    layoutContext.setBreadcrumb([{ title: "Haraloyalty" }]);
    return () => {
      layoutContext.setPageTitle("");
      layoutContext.setBreadcrumb(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LayoutBody size="ne" className="loyalty-body-layout">
      <LayoutCard className="hara-loy">
        <div className="hara-loy-illustration">
          <img src={Assets.icon.onboarding_haraloyalty} alt="" />
        </div>
        <div className="hara-loy-info">
          <p>Cùng HaraLoyalty gia tăng lượng khách hàng trung thành của bạn</p>
          <p className="l-mt-8 font-size-16 content-secondary">
            Mang khách hàng quay trở lại lần thứ hai, thứ ba và hơn thế nữa với
            các chương trình đổi thưởng hấp dẫn.
          </p>
          {shop_loyalty?.enabled ? (
            <Button
              status="primary"
              size="lg"
              className="l-mt-16"
              title="Truy cập Haraloyalty"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_LEGAGY_LOY}/?src=pos`,
                  "_blank",
                )
              }
            />
          ) : (
            <Button
              status="primary"
              size="lg"
              className="l-mt-16"
              title="Kích hoạt"
              icon="arrow_circle_right"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_LEGAGY_LOY}/?action=register`,
                  "_blank",
                )
              }
            />
          )}
        </div>
      </LayoutCard>
    </LayoutBody>
  );
}
