import { ENDPOINT_GATEWAY, useGetDataSWR, useGetInfiniteDataSWR } from "api/api-connect-base";

export const useOrderDetailData = (orderId, options = null) => {
  let endpoint = '/orders/' + orderId
  if (!orderId || isNaN(orderId)) endpoint = null
  const a = useGetDataSWR(ENDPOINT_GATEWAY.retail, endpoint, null, options)
  const data = a?.data?.data;
  return { data, isValidating: a.isValidating, isLoading : a.isLoading, mutate: a.mutate, errors: a.error || a?.data?.errors }
}
export const useOrdersData = (locationId, query, financialStatus, shipmentStatus, orderStatus, source, limit = 20) => {
  let endpoint = '/orders'
  if (!limit) endpoint = null
  let params = { limit, page: 1 }
  if (locationId) params.location_id = locationId
  if (financialStatus) params.financial_status = financialStatus
  if (query) params.query = query
  if (source) params.source = source
  if (shipmentStatus) params.shipment_status = shipmentStatus
  if (orderStatus) params.order_status = orderStatus
  const a = useGetInfiniteDataSWR(ENDPOINT_GATEWAY.retail, endpoint, params)
  const data = a?.data;
  return { data, isValidating: a.isValidating, size: a.size, setSize: a.setSize }
}