import moment from "moment";
import { FormattedMessage } from "react-intl";
class DateUtils {
  getTime() {
    return new Date().getTime();
  }
  getNamesTimezone(timezone) {
    const moment_timezone_names = [
      { tz: "0", name: "Etc/GMT+0" },
      { tz: "1", name: "Etc/GMT+1" },
      { tz: "2", name: "Etc/GMT+2" },
      { tz: "3", name: "Etc/GMT+3" },
      { tz: "4", name: "Etc/GMT+4" },
      { tz: "5", name: "Etc/GMT+5" },
      { tz: "6", name: "Etc/GMT+6" },
      { tz: "7", name: "Asia/Ho_Chi_Minh" },
      { tz: "8", name: "Asia/Manila" },
      { tz: "9", name: "Etc/GMT+9" },
      { tz: "10", name: "Etc/GMT+10" },
      { tz: "11", name: "Etc/GMT+11" },
      { tz: "12", name: "Etc/GMT+12" },
      { tz: "-0", name: "Etc/GMT-0" },
      { tz: "-1", name: "Etc/GMT-1" },
      { tz: "-2", name: "Etc/GMT-2" },
      { tz: "-3", name: "Etc/GMT-3" },
      { tz: "-4", name: "Etc/GMT-4" },
      { tz: "-5", name: "Etc/GMT-5" },
      { tz: "-6", name: "Etc/GMT-6" },
      { tz: "-7", name: "Etc/GMT-7" },
      { tz: "-8", name: "Etc/GMT-8" },
      { tz: "-9", name: "Etc/GMT-9" },
      { tz: "-10", name: "Etc/GMT-10" },
      { tz: "-11", name: "Etc/GMT-11" },
      { tz: "-12", name: "Etc/GMT-12" },
      { tz: "-13", name: "Etc/GMT-13" },
      { tz: "-14", name: "Etc/GMT-14" },
    ];

    let tz = moment_timezone_names.find((t) => t.tz === timezone);
    let tzname = tz ? tz.name : "Asia/Ho_Chi_Minh";
    return tzname;
  }

  getListDate = {
    //Hôm nay
    startToDay: moment().startOf("days").format(),
    endToDay: moment().endOf("days").format(),

    // Hôm qua
    startYesterDay: moment().subtract(1, "days").startOf("days").format(),
    endYesterDay: moment().subtract(1, "days").endOf("days").format(),

    // Tuần này
    startWeekDay: moment().startOf("week").format(),
    endWeekDay: moment().endOf("days").format(),

    // 7 ngày trước
    start7Day: moment().subtract(6, "days").startOf("days").format(),
    end7Day: moment().endOf("days").format(),

    // 30 ngày trước
    start30Day: moment().subtract(29, "days").startOf("days").format(),
    end30Day: moment().endOf("days").format(),

    // Tháng này
    startThisMonth: moment().startOf("month").format(),
    endThisMonth: moment().endOf("days").format(),

    // Tháng trước
    startLastMonth: moment().subtract(1, "month").startOf("month").format(),
    endLastMonth: moment().subtract(1, "month").endOf("month").format(),
  };

  getLabelDateTime(startDate, endDate) {
    let labelDateTime = "";
    if (
      moment(startDate).format() === this.getListDate.startToDay &&
      moment(endDate).format() === this.getListDate.endToDay
    ) {
      labelDateTime = (
        <FormattedMessage id="reports.Hôm nay" defaultMessage="Hôm nay" />
      );
    } else if (
      moment(startDate).format() === this.getListDate.startYesterDay &&
      moment(endDate).format() === this.getListDate.endYesterDay
    ) {
      labelDateTime = (
        <FormattedMessage id="reports.Hôm qua" defaultMessage="Hôm qua" />
      );
    } else if (
      moment(startDate).format() === this.getListDate.startWeekDay &&
      moment(endDate).format() === this.getListDate.endWeekDay
    ) {
      labelDateTime = (
        <FormattedMessage id="reports.Tuần này" defaultMessage="Tuần này" />
      );
    } else if (
      moment(startDate).format() === this.getListDate.startThisMonth &&
      moment(endDate).format() === this.getListDate.endThisMonth
    ) {
      labelDateTime = (
        <FormattedMessage id="reports.Tháng này" defaultMessage="Tháng này" />
      );
    } else if (
      moment(startDate).format() === this.getListDate.startLastMonth &&
      moment(endDate).format() === this.getListDate.endLastMonth
    ) {
      labelDateTime = (
        <FormattedMessage
          id="reports.Tháng trước"
          defaultMessage="Tháng trước"
        />
      );
    } else if (
      moment(startDate).format() === this.getListDate.start7Day &&
      moment(endDate).format() === this.getListDate.end7Day
    ) {
      labelDateTime = (
        <FormattedMessage
          id="reports.7 ngày trước"
          defaultMessage="7 ngày trước"
        />
      );
    } else if (
      moment(startDate).format() === this.getListDate.start30Day &&
      moment(endDate).format() === this.getListDate.end30Day
    ) {
      labelDateTime = (
        <FormattedMessage
          id="reports.30 ngày trước"
          defaultMessage="30 ngày trước"
        />
      );
    } else {
      if (moment(startDate).format("L") === moment(endDate).format("L")) {
        labelDateTime = moment(startDate).format("DD-MM-YYYY");
      } else {
        labelDateTime = (
          <FormattedMessage
            id="reports.Từ {a} đến {b}"
            defaultMessage="Từ {a} đến {b}"
            values={{
              a: <span>{moment(startDate).format("DD-MM-YYYY")}</span>,
              b: <span>{moment(endDate).format("DD-MM-YYYY")}</span>,
            }}
          />
        );
      }
    }
    return labelDateTime;
  }
}

const dateUtils = new DateUtils();

export default dateUtils;
