import { InputCheckbox, Textarea } from "@haravan/react-components";
import { postFeedback } from "api/common-repositoty";
import { ScreenCapture } from "component-v2/FeedBack/components/ScreenCaptureV2";
import Button from "component-v2/buttons";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Icon from "component-v2/icons/icons";
import { getBrowserInfo } from "utils/Commons";
import "./style.css";

export const FeedbackModal = ({ show, setShow }) => {
  const [contentFeedback, setContentFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFeedBackCanvas, setIsFeedBackCanvas] = useState(true);
  const [screenCapture, setScreenCapture] = useState("");
  const refIsCapturing = useRef(false);
  const refCapture = useRef(null);
  const appContext = useApp();
  const { showGlobalToast } = useLayoutContext();

  const sendFeedback = async () => {
    const browser = getBrowserInfo();
    if (browser.plugins && browser.plugins.length) {
      browser.plugins = [...browser.plugins].map((p) => p.name);
    }
    let feedback = {
      note: contentFeedback,
      screenshot: isFeedBackCanvas,
      image: isFeedBackCanvas ? screenCapture : "",
      solution: {
        width: document.documentElement.clientWidth + window.scrollX,
        height: document.documentElement.clientHeight + window.scrollY,
      },
      url: window.location.href,
      browser: browser,
    };
    setLoading(true);
    const response = await postFeedback(appContext, feedback);
    setLoading(false);

    if (!response.errors?.length) {
      setShow(false);
      showGlobalToast("default", "Bạn đã gửi góp ý thành công");
    } else {
      showGlobalToast("error", response?.errors?.[0]);
    }
  };

  useEffect(() => {
    if (!show) {
      if (!refIsCapturing.current) setContentFeedback("");
      setScreenCapture("");
      setIsFeedBackCanvas(true);
    }
  }, [show]);

  const handleScreenCapture = (screenCapture) => {
    refIsCapturing.current = false;
    setScreenCapture(screenCapture);
    setShow(true);
  };

  const body = () => {
    return (
      <>
        <div>
          <label htmlFor="idTextFeedBack">Nội dung</label>
          <Textarea
            id="idTextFeedBack"
            rows={3}
            value={contentFeedback}
            onChange={(value) => setContentFeedback(value)}
            placeholder="Nhập nội dung góp ý"
          />
        </div>
        <div className="py-3 hrv-feedback--isScreenShoot">
          <InputCheckbox
            id="hrv-feedback--isScreenShoot"
            checked={isFeedBackCanvas}
            onChange={(e) => setIsFeedBackCanvas(e)}
          >
            <span>
              <FormattedMessage
                id="feedbacks.Chụp màn hình"
                defaultMessage="Chụp màn hình"
              />
            </span>
          </InputCheckbox>
        </div>
        <div
          className={`hrv-feedback--canvas ${
            isFeedBackCanvas ? "d-block" : "d-none"
          }`}
          style={{
            border: screenCapture
              ? "none"
              : "0.1rem dashed var(--borderAccent)",
          }}
        >
          <div
            className={`hrv-feedback-canvas-action hrv-feedback-canvas--hover`}
            onClick={() => {
              refCapture.current?.click();
              setShow(false);
            }}
          >
            <Icon
              className={screenCapture ? "icon-captured" : ""}
              name="capture"
              color={"var(--contentAccent)"}
            />
            <p
              className="mb-0 mt-3"
              style={{
                color: screenCapture ? "var(--white)" : "var(--contentAccent)",
              }}
            >
              <FormattedMessage
                id="feedbacks.Click để chọn vùng chụp màn hình"
                defaultMessage="Click để chọn vùng chụp màn hình"
              />
            </p>
          </div>
          {screenCapture && <div className="hrv-feedback--canvas-overlay" />}
          {screenCapture && (
            <div className="hrv-feedback--canvas-screenshot">
              <img alt="screenshot" src={screenCapture} />
            </div>
          )}
        </div>
        <div className="mt-4">
          <FormattedMessage
            id="feedbacks.Đến trang <a>Hướng dẫn</a> để yêu cầu thay đổi nội dung vì lý do pháp lý. Phản hồi của bạn, thông tin bổ sung và email sẽ được gửi đến Haravan. {d}Xem thêm <b>Quy định sử dụng</b> và <c>Chính sách bảo mật</c> của Haravan."
            defaultMessage="Đến trang <a>Hướng dẫn</a> để yêu cầu thay đổi nội dung vì lý do pháp lý. Phản hồi của bạn, thông tin bổ sung và email sẽ được gửi đến Haravan. {d}Xem thêm <b>Quy định sử dụng</b> và <c>Chính sách bảo mật</c> của Haravan."
            values={{
              a: (chunks) => (
                <a
                  target="_blank"
                  className="content-accent"
                  href="//huongdan.haravan.com?utm_source=myharavan.com&amp;utm_medium=referral&amp;utm_campaign=huongdan"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              b: (b) => (
                <a
                  href="//www.haravan.com/pages/quy-dinh"
                  target="_blank"
                  className="content-accent"
                  rel="noopener noreferrer"
                >
                  {b}
                </a>
              ),
              c: (c) => (
                <a
                  href="//www.haravan.com/pages/chinh-sach"
                  target="_blank"
                  className="content-accent"
                  rel="noopener noreferrer"
                >
                  {c}
                </a>
              ),
              d: <br />,
            }}
          />
        </div>
      </>
    );
  };

  const footer = () => {
    return (
      <Button
        title={"Gửi"}
        status="primary"
        size="md"
        onClick={sendFeedback}
        loading={loading}
        disabled={!contentFeedback}
      ></Button>
    );
  };

  return (
    <React.Fragment>
      <GlobalModal
        headerTitle={"Gửi góp ý"}
        body={body()}
        footer={footer()}
        show={show}
        setShow={setShow}
        onCancel={() => setShow(false)}
      />
      <ScreenCapture onEndCapture={handleScreenCapture}>
        {({ onStartCapture }) => (
          <div
            ref={refCapture}
            onClick={() => {
              refIsCapturing.current = true;
              onStartCapture();
            }}
          ></div>
        )}
      </ScreenCapture>
    </React.Fragment>
  );
};
