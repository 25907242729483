import React from "react";
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { InputHasIcon } from "@haravan/react-components";
import { ROLES } from 'models/constants';
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";


export const ProductDetailFilter = (props) => {
  const { checkRoleAllow } = useApp()
  const intl = useIntl();
  const { isDisabled, selectedProvince, selectedDistrict,
    districtId, districts, provinceId, provinces, keyword, onClearSearch, onInputChange } = props;
  const notFoundContent = intl.formatMessage({ id: 'carts.orders.Không tìm thấy', defaultMessage: 'Không tìm thấy' });
  return (
    <div className="row product--detail-m-tb-16">
      {(checkRoleAllow(ROLES.API_LOCATION_READ) || checkRoleAllow(ROLES.API_LOCATION_WRITE)) &&
        <div className="col-4 p-0">
          <SelectionCustom
            options={provinces}
            showSearch
            idField="id"
            valueField="name"
            disabled={isDisabled}
            onChange={selectedProvince}
            value={provinceId || null}
            placeholder={intl.formatMessage({ id: 'products.searches.Tất cả Tỉnh/Thành Phố', defaultMessage: 'Tất cả Tỉnh/Thành Phố' })}
            notFoundContent={notFoundContent}
            className='selection-default'
          >
            {showProvinces([
              {
                id: null,
                name: intl.formatMessage({ id: 'products.searches.Tất cả Tỉnh/Thành Phố', defaultMessage: 'Tất cả Tỉnh/Thành Phố' })
              },
              ...provinces
            ])}
          </SelectionCustom>
        </div>
      }

      {(checkRoleAllow(ROLES.API_LOCATION_READ) || checkRoleAllow(ROLES.API_LOCATION_WRITE)) &&
        <div className="col-4 ">
          <SelectionCustom
            options={districts}
            showSearch
            idField="id"
            valueField="name"
            disabled={isDisabled || !provinceId}
            onChange={selectedDistrict}
            value={districtId ? districtId : !provinceId ? null : null}
            notFoundContent={notFoundContent}
            className='selection-default'
            placeholder={intl.formatMessage({ id: 'products.searches.Tất cả Quận/Huyện', defaultMessage: 'Tất cả Quận/Huyện' })}>
            {showDistricts([
              ...(provinceId !== '' ? [{
                id: null,
                name: intl.formatMessage({ id: 'products.searches.Tất cả Quận/Huyện', defaultMessage: 'Tất cả Quận/Huyện' })
              }] : []),
              ...districts
            ])}
          </SelectionCustom>
        </div>
      }

      <div className={`${(checkRoleAllow(ROLES.API_LOCATION_READ) || checkRoleAllow(ROLES.API_LOCATION_WRITE)) ? `col-4` : `col-12`} p-0`}>
        <InputHasIcon
          prefix={<Icon name={"search"} color="var(--contentSecondary)" />}
          suffix={keyword ? <span className="cursor-pointer" onClick={() => onClearSearch()}><Icon name={"close"} color="var(--contentSecondary)" /></span> : null}
          onChange={(value) => {
            onInputChange(value)
          }}
          value={keyword}
          placeholder={intl.formatMessage({ id: 'products.searches.Tìm kiếm theo tên kho', defaultMessage: 'Tìm kiếm theo tên kho' })}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};



function showProvinces(provinces) {
  let result = null;
  if (!!provinces?.length) {
    result = provinces.map(province => {
      return (
        <SelectionCustom.Option className='select-area' value={province.id} key={province.id}>{province.name}</SelectionCustom.Option>
      );
    });
  }
  return result;
}

function showDistricts(districts) {
  let result = null;
  if (!!districts?.length) {
    return districts.map(district => {
      return (
        <SelectionCustom.Option className='select-area' key={district.id} value={district.id}>{district.name}</SelectionCustom.Option>
      );
    });
  }
  return result;
}


ProductDetailFilter.propTypes = {
  provinces: PropTypes.array,
  districts: PropTypes.array,
  selectedProvince: PropTypes.func,
  selectedDistrict: PropTypes.func,
  districtId: PropTypes.any,
  provinceId: PropTypes.any,
  isDisabled: PropTypes.bool,
  onClearSearch: PropTypes.func,
  onInputChange: PropTypes.func,
  keyword: PropTypes.string
};

