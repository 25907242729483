import CurrencyShow from "component-v2/currency-show";

export default function ShiftDetailCards({ summaries }) {
  return (
    <div className="flex-row">
      <div className="flex-stretch l-pr-8">
        <div className="shift-detail--card">
          <div className="font-weight-medium text-ellipsis">
            Tiền mặt trong ca
          </div>
          <div>
            <CurrencyShow value={Math.round(summaries.cashTotal || 0)} />
          </div>
        </div>
      </div>
      <div className="flex-stretch l-pl-8">
        <div className="shift-detail--card">
          <div className="font-weight-medium text-ellipsis">
            Tổng thực nhận trong ca
          </div>
          <div>
            <CurrencyShow value={Math.round(summaries.amountTotal || 0)} />
          </div>
        </div>
      </div>
    </div>
  );
}
