import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import BlockingComponent from "./BlockingNavigation/blocking";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import Button from "component-v2/buttons";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
export default function BlockUnsavedPage({
  children,
  default_data,
  changed_data,
  blocked = false,
  blocked_title = "Hủy tất cả các thay đổi chưa lưu",
  blocked_message = "Nếu bạn hủy các thay đổi này thì mọi chỉnh sửa đã thực hiện kể từ lần lưu cuối cùng sẽ bị xóa.",
  button_continue_title = "Tiếp tục chỉnh sửa",
  button_cancel_title = "Hủy chỉnh sửa",
  modal_classname = "",
  onConfirmedCallback,
  // ActionList,
}) {
  const intl = useIntl();
  const history = useHistory();

  const [prompt_massage, setPromptMessage] = useState({
    title: blocked_title,
    message: blocked_message,
  }); // message cảnh báo
  //Check block
  const [blockingNavigate, setBlockingNavigate] = useState(blocked);
  //Check Show modal when blocked
  const [showModal, setShowModal] = useState(false);
  const [navigationInfo, setNavigationInfo] = useState(null);
  //Confirm exit when blocked
  const [hasConfirm, setHasConfirm] = useState(false);
  useEffect(() => {
    if (!showModal) setShowModal(false);
  }, [showModal]);

  useEffect(() => {
    setBlockingNavigate(blocked);
  }, [blocked]);

  useEffect(() => {
    setPromptMessage((prev) => ({
      blocked_title,
      blocked_message,
    }));
  }, [blocked_title, blocked_message]);

  useEffect(() => {
    if (default_data || changed_data) {
      if (!isEqual(default_data, changed_data)) {
        setBlockingNavigate(true);
      } else {
        setBlockingNavigate(false);
      }
    }
  }, [default_data, changed_data]);

  useEffect(() => {
    if (!blockingNavigate && hasConfirm) {
      if (navigationInfo) {
        if (navigationInfo.action === "POP") {
          history.goBack();
        } else {
          history.push(navigationInfo.location.pathname);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasConfirm, blockingNavigate]);

  const onCancel = () => {
    setShowModal(false);
    setHasConfirm(false);
  };

  const onConfirmed = () => {
    onConfirmedCallback?.();
    setBlockingNavigate(false);
    setHasConfirm(true);
  };

  const body = () => (
    <div className="py-4">
      <span>
        {prompt_massage?.blocked_message
          ? intl.formatMessage({
              id: `systems.sticky-headers.${prompt_massage?.blocked_message}`,
              defaultMessage: prompt_massage?.blocked_message,
            })
          : ""}
      </span>
    </div>
  );
  const footer = () => (
    <div>
      <Button light className="mr-3" onClick={onCancel}>
        {intl.formatMessage({
          id: `systems.sticky-headers.${button_continue_title}`,
          defaultMessage: button_continue_title,
        })}
      </Button>
      <Button status="danger" className="" onClick={onConfirmed}>
        {intl.formatMessage({
          id: `systems.sticky-headers.${button_cancel_title}`,
          defaultMessage: button_cancel_title,
        })}
      </Button>
    </div>
  );

  const blockingModal = () => {
    return (
      <GlobalModal
        className={modal_classname}
        headerTitle={
          prompt_massage?.blocked_title
            ? intl.formatMessage({
                id: `systems.sticky-headers.${prompt_massage?.blocked_title}`,
                defaultMessage: prompt_massage?.blocked_title,
              })
            : ""
        }
        body={body()}
        footer={footer()}
        show={showModal}
        setShow={setShowModal}
      />
    );
  };

  return (
    <BlockingComponent
      promptBeforeTransition={(navigateToNext, action) => {
        if (!blockingNavigate) return true;
        if (navigateToNext.pathname !== history?.location?.pathname) {
          setNavigationInfo({ location: navigateToNext, action });
          setShowModal(true);
        }
        return false;
      }}
      isBlockingNavigate={blockingNavigate}
    >
      {children}
      {blockingModal()}
    </BlockingComponent>
  );
}

BlockUnsavedPage.propTypes = {
  children: PropTypes.any,
  default_data: PropTypes.any, // data ban đầu
  changed_data: PropTypes.any, // data sau thay đổi
  blocked: PropTypes.bool, // biến kiểm tra có block hay ko, dùng trong trường hợp component cha cần biết có block hay ko
  prevent_block: PropTypes.bool, // chặn ko cho block (trong 1 số trường hợp)
  blocked_title: PropTypes.string,
  blocked_message: PropTypes.string,
  modal_classname: PropTypes.string,
  // ActionList, // Component các button chức năng
};
