import { Textarea } from "@haravan/react-components";
import { useCartContext } from "..";
import Icon from "component-v2/icons/icons";

export const Notes = () => {
  const { current_shopping_cart, updateCurrentCart } = useCartContext();
  return (
    <div className="l-pb-4 position-relative">
      <Textarea
        placeholder="Ghi chú đơn hàng"
        className="l-section w-100 max-3-rows pl-4 has-close"
        rows={1}
        autoResize={true}
        value={current_shopping_cart?.orderNote}
        onChange={(value) => {
          updateCurrentCart({ orderNote: value });
        }}
      />
      {!!current_shopping_cart?.orderNote && (
        <div
          className="flex-auto cursor-pointer btn-close l-ml-8"
          onClick={() => updateCurrentCart({ orderNote: "" })}
          style={{
            height: "max-content",
            padding: "0.2rem",
            position: "absolute",
            right: "0.8rem",
            top: "0.8rem",
          }}
        >
          <Icon name="close" />
        </div>
      )}
    </div>
  );
};
