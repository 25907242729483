import { Assets } from "assets";
import Button from "component-v2/buttons";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { useEffect, useState } from "react";
import { useHotkeysContext } from "react-hotkeys-hook";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";

export default function ErrorOrderForm({ show, setShow, errorMessage, intl }) {
  const [showModal, setShowModal] = useState(null);
  const { enableScope } = useHotkeysContext();
  useEffect(() => {
    if (show) setShowModal(true);
  }, [show]);
  useEffect(() => {
    if (showModal === false) setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        setShow(false);
        enableScope(HOTKEY_SCOPES.CART);
      }
    },
    scopes: HOTKEY_SCOPES.MODAL,
    enabled: show,
  });

  const body = () => (
    <div className="text-center">
      <img
        src={Assets.icon.checkDisable}
        width="50"
        height="50"
        alt="haravaner"
        className="mb-2"
      />
      <p
        style={{ padding: "10px 0px", lineHeight: "2rem" }}
        className="content-primary"
      >
        {errorMessage}
      </p>
      <Button
        size="md"
        status="primary"
        title={intl.formatMessage({
          id: "carts.orders.Đóng",
          defaultMessage: "ĐÓNG",
        })}
        onClick={() => {
          enableScope(HOTKEY_SCOPES.CART);
          setShow(false);
        }}
        badge={HOTKEYS.ESC}
      ></Button>
    </div>
  );
  return <GlobalModal body={body()} show={showModal} setShow={setShowModal} />;
}
