import { useHistory, useParams } from "react-router-dom";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import { Fragment, useEffect, useRef, useState } from "react";
import OrderListSearch from "./order-list-search";
import OrderListItems from "./order-list-items";
import { useOrdersData } from "hooks/useOrderData";
import _ from "lodash";
import { useApp } from "contexts/app";

const PAGE_LIMIT = 20;
export default function OrderList({ currentOrderDetail }) {
  const { current_location, shop_setting } = useApp();
  const locationId = current_location.id;
  const allowRefundOrderAllLocation = shop_setting.allowRefundOrderAllLocation;
  const allowDisplayOnlineOrder = shop_setting.allowDisplayOnlineOrder;
  const { id: orderId } = useParams();
  const history = useHistory();
  const [textSearch, setTextSearch] = useState("");
  const [status, setStatus] = useState(null);
  // nếu
  const {
    data: orderPaging,
    isValidating: loading,
    size,
    setSize,
  } = useOrdersData(
    allowRefundOrderAllLocation && textSearch ? null : locationId, // location
    encodeURIComponent(textSearch),
    status?.financialStatus,
    status?.shipmentStatus,
    status?.orderStatus,
    allowDisplayOnlineOrder ? "pos" : null, // chỉ lấy từ pos/tất cả
    PAGE_LIMIT,
  );
  const [orders, setOrders] = useState(null);
  let firstPage = useRef([]);
  let maxPage = useRef(1);

  useEffect(() => {
    if (currentOrderDetail) {
      const _orders = _.cloneDeep(orders);

      const foundIndex = _orders.findIndex(
        (item) => item.orderId === currentOrderDetail.orderId,
      );
      _orders[foundIndex] = currentOrderDetail;
      setOrders(_orders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrderDetail]);

  useEffect(() => {
    let orders = [];
    if (orderPaging && orderPaging.length) {
      // lưu lại và cập nhật trang đầu tiên => do trang đầu sẽ được cập nhật nếu có đơn mới
      if (orderPaging[0].data?.data) {
        if (size === 1) {
          // nếu chỉ có trang 1: reset trang 1 không cần giữ đơn cũ
          firstPage.current = orderPaging[0].data.data.slice();
        } else {
          firstPage.current = orderPaging[0].data.data.concat(
            firstPage.current.filter(
              (d) =>
                orderPaging[0].data.data.findIndex(
                  (o) => o.orderId === d.orderId,
                ) < 0,
            ),
          );
        }
        maxPage.current = Math.ceil(
          Number(orderPaging[0].data.totalRecord) / PAGE_LIMIT,
        );
        orders = firstPage.current.slice();
      } else {
        firstPage.current = [];
        maxPage.current = 1;
      }
      // nối từ trang 2 trở đi vào danh sách đơn
      orderPaging.forEach((aPage, index) => {
        if (index === 0) return;
        if (aPage.data?.data) {
          orders = orders.concat(
            aPage.data.data.filter(
              (d) => orders.findIndex((o) => o.orderId === d.orderId) < 0,
            ),
          );
        }
      });
    }
    // chọn đơn hàng đầu tiên nếu chưa có đơn được chọn
    if (!orderId && orders && orders.length) {
      history.replace(
        getConstantLinkDetail(
          CONSTANT_LINKS.route_order_detail,
          orders[0].orderId,
        ),
      );
    }
    setOrders(orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPaging]);

  useEffect(() => {
    firstPage.current = [];
    setSize(1);
    maxPage.current = 1;
  }, [textSearch, status, setSize]);
  return (
    <Fragment>
      <OrderListSearch
        loading={loading}
        textSearch={textSearch}
        updateTextSearch={setTextSearch}
        status={status}
        updateStatus={setStatus}
      />
      <OrderListItems
        currentOrderDetail={currentOrderDetail}
        loading={loading}
        page={size}
        updatePage={setSize}
        orders={orders}
        maxPage={maxPage.current}
      />
    </Fragment>
  );
}
