import { useEffect, useState } from "react";
import ReportHeader from "./report-header";
import "./utils/style.css";
import { DateUtils } from "utils";
import ReportPaymentMethodInfo from "./report-payment-method-info";
import GeneralReportTab from "./tab-general/general-tab";
import BestSellerTab from "./tab-top-product/best-seller-tab";
import PaymentMethodTab from "./tab-payment-method/payment-method-tab";
import { getDataRefund, getTittleReport } from "./utils/utils";
import { useIntl } from "react-intl";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";

const GENERAL_TAB_KEY = "general-tab";
const PAYMENT_TAB_KEY = "payment-tab";
const BESTSELLER_TAB_KEY = "best-seller-tab";

export default function Report() {
  const appContext = useApp();
  const intl = useIntl();
  const layout = useLayoutContext();
  const [channel, setChannel] = useState("channelPOS");
  const [staff, setStaff] = useState({ id: null, name: "Tất cả nhân viên" });
  const [dateRange, setDateRange] = useState({
    startDate: DateUtils.getListDate.startToDay,
    endDate: DateUtils.getListDate.endToDay,
  });
  const [labelDateTime, setLabelDateTime] = useState("Hôm nay");
  const [activeTab, setActiveTab] = useState(GENERAL_TAB_KEY);
  const [report, setReport] = useState({
    summaryReport: null,
    summaryReportRefund: null,
    summaryRefund: null,
    queryRefund: null,
    summaryPaymentRefund: null,
    orderRefund: null,
  });
  const [globalLoading, setGlobalLoading] = useState(true);
  useEffect(() => {
    layout.setPageTitle(
      intl.formatMessage({
        id: "reports.Báo cáo bán hàng",
        defaultMessage: "Báo cáo bán hàng",
      }),
    );
    layout.setBreadcrumb([
      {
        title: intl.formatMessage({
          id: "reports.Báo cáo bán hàng",
          defaultMessage: "Báo cáo bán hàng",
        }),
        link: null,
      },
    ]);
    return () => {
      layout.setPageTitle("");
      layout.setBreadcrumb();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  useEffect(() => {
    const func = async () => {
      setGlobalLoading(true);
      let labelDateTime = DateUtils.getLabelDateTime(
        dateRange.startDate,
        dateRange.endDate,
      );
      setLabelDateTime(labelDateTime);
      let generalReport = await getTittleReport(
        appContext,
        dateRange.startDate,
        dateRange.endDate,
        channel,
        staff,
      );
      let dataRefund = await getDataRefund(
        appContext,
        dateRange.startDate,
        dateRange.endDate,
      );
      setReport({
        summaryReport: generalReport.summaryReport,
        summaryReportRefund: generalReport.summaryReportRefund,
        summaryRefund: dataRefund.summaryRefund,
        queryRefund: dataRefund.queryRefund,
        summaryPaymentRefund: dataRefund.summaryPaymentRefund,
        orderRefund: dataRefund.orderRefund,
      });
      setGlobalLoading(false);
    };
    func();
  }, [dateRange, channel, staff]);
  return (
    <LayoutBody
      size="ne"
      flexRow
      className="main-background report-menu border-top"
    >
      <div
        className={`reportV2 ${
          activeTab === "bestSeller" ? "height-unset" : ""
        }`}
      >
        <div className=" row">
          <div className=" col-12">
            <ReportHeader
              globalLoading={globalLoading}
              label={labelDateTime}
              dateRange={dateRange}
              setDateRange={setDateRange}
              channel={channel}
              setChannel={setChannel}
              staff={staff}
              setStaff={setStaff}
              title={intl.formatMessage({
                id: "reports.Báo cáo bán hàng",
                defaultMessage: "Báo cáo bán hàng",
              })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ReportPaymentMethodInfo
              globalLoading={globalLoading}
              dateRange={dateRange}
              channel={channel}
              staff={staff}
              summaryReport={report.summaryReport}
              summaryRefund={report.summaryRefund}
            />
          </div>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <div className="row mt-16">
            <div className="col-12 l-tab-group">
              <span
                onClick={() => {
                  setActiveTab(GENERAL_TAB_KEY);
                }}
                className={`${
                  activeTab === GENERAL_TAB_KEY ? "active" : ""
                } l-tab`}
                href="#report-general"
                data-toggle="pill"
                role="tab"
              >
                {intl.formatMessage({
                  id: "reports.Tổng quan",
                  defaultMessage: "Tổng quan",
                })}
              </span>
              <span
                onClick={() => {
                  setActiveTab(PAYMENT_TAB_KEY);
                }}
                className={`${
                  activeTab === PAYMENT_TAB_KEY ? "active" : ""
                } l-tab`}
                href="#report-payment-method"
                data-toggle="pill"
                role="tab"
              >
                {intl.formatMessage({
                  id: "payment-vouchers.Phương thức thanh toán",
                  defaultMessage: "Phương thức thanh toán",
                })}
              </span>
              <span
                onClick={() => {
                  setActiveTab(BESTSELLER_TAB_KEY);
                }}
                className={`${
                  activeTab === BESTSELLER_TAB_KEY ? "active" : ""
                } l-tab`}
                href="#report-best-seller"
                data-toggle="pill"
                role="tab"
              >
                {intl.formatMessage({
                  id: "reports.Sản phẩm bán chạy",
                  defaultMessage: "Sản phẩm bán chạy",
                })}
              </span>
            </div>
          </div>
          <div
            className="report-tab-content flex-grow-1 l-mb-16"
            id="pills-tabContent"
            role="tabpanel"
          >
            <div
              id="report-general"
              className={`tab-pane fade${
                activeTab === GENERAL_TAB_KEY ? " show active" : ""
              }`}
            >
              {activeTab === GENERAL_TAB_KEY ? (
                <GeneralReportTab
                  globalLoading={globalLoading}
                  dateRange={dateRange}
                  channel={channel}
                  staff={staff}
                  reportData={report}
                />
              ) : null}
            </div>
            <div
              id="report-payment-method"
              className={`tab-pane fade${
                activeTab === PAYMENT_TAB_KEY ? " show active" : ""
              }`}
            >
              {activeTab === PAYMENT_TAB_KEY ? (
                <PaymentMethodTab
                  globalLoading={globalLoading}
                  dateRange={dateRange}
                  channel={channel}
                  staff={staff}
                  summaryPaymentRefund={report.summaryPaymentRefund}
                />
              ) : null}
            </div>
            <div
              id="report-best-seller"
              className={`tab-pane fade${
                activeTab === BESTSELLER_TAB_KEY ? " show active" : ""
              }`}
            >
              {activeTab === BESTSELLER_TAB_KEY ? (
                <BestSellerTab
                  dateRange={dateRange}
                  channel={channel}
                  staff={staff}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </LayoutBody>
  );
}
