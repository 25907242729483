import { useState } from "react";
import PaymentSettingItem from "../PaymentSettingItem";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import {
  disablePaymentMethod,
  enablePaymentMethod,
} from "api/settings-repository";
import { useLayoutContext } from "contexts/Layout/layout-context";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { CustomPaymentMethod } from "../CustomPaymentMethodModal";
import { Switch } from "@haravan/react-components";
import _ from "lodash";
import * as HrvComponents from "@haravan/react-components";
import Icon from "component-v2/icons/icons";

const EditGatewayModal = ({ show, setShow }) => {
  const appContext = useApp();
  const layout = useLayoutContext();
  const intl = useIntl();
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [currentMethod, setCurrentMethod] = useState(null);
  const [methods, setMethods] = useState(
    appContext.shop_setting.paymentMethods,
  );

  const handleCreateCustomGateway = async (updateData) => {
    setMethods(updateData);
    appContext.updateShopSetting({ paymentMethods: updateData });
  };

  const renderCustomMethodList = () => {
    const customPaymentList = methods.filter(
      (method) => method.isCustom === true,
    );
    if (!customPaymentList) return null;

    return customPaymentList.map((method, index) => {
      return (
        <div
          key={method.name}
          className="d-flex flex-row justify-content-between my-3"
        >
          <div className="d-flex flex-row" style={{ flexBasis: "65%" }}>
            <div
              className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1`}
              style={{ width: "40px", height: "40px" }}
            >
              <HrvComponents.Avatar
                arrColor="gray"
                className="text-primary"
                shape="circle"
                content={method.name}
              ></HrvComponents.Avatar>
            </div>
            <span
              className={"align-self-center text-break"}
              style={{ flexBasis: "85%" }}
            >
              {method.name}
            </span>
          </div>
          <div className="text-right" style={{ flexBasis: "30%" }}>
            <Button
              onClick={() => {
                setCurrentMethod(method.code);
                setShowCustomModal(true);
              }}
              light
              children={<span>Chỉnh sửa</span>}
            />
            <Switch
              className="ml-3"
              checked={method.isEnable}
              onChange={() => handleUpdateGateway(method)}
            />
          </div>
        </div>
      );
    });
  };

  const handleUpdateGateway = async (method) => {
    const model = {
      code: method.code,
      locationId: appContext.current_location?.id,
    };
    let response = null;
    let updateData = _.cloneDeep(methods);
    const index = _.findIndex(updateData, { code: model.code });

    if (method.isEnable) {
      updateData[index].isEnable = false;
      response = await disablePaymentMethod(appContext, model);
    } else {
      updateData[index].isEnable = true;
      response = await enablePaymentMethod(appContext, model);
    }
    if (response?.data) {
      setMethods(updateData);
      appContext.updateShopSetting({ paymentMethods: updateData });
      layout.showGlobalToast(
        "default",
        "Cấu hình đã được cập nhật thành công!",
      );
    } else {
      layout.showGlobalToast(
        "error",
        "Cấu hình cập nhật không thành công. Vui lòng thử lại!",
      );
    }
  };

  const handleDeletePaymentMethod = async (updateData) => {
    if (updateData) {
      setMethods(updateData);
      setShowCustomModal(false);
      appContext.updateShopSetting({ paymentMethods: updateData });
    }
  };

  const body = () => {
    return (
      <>
        {/* momoqr */}
        <div className="border-bottom mb-4">
          <p className="font-weight-medium">Phương thức thanh toán QR</p>
          {methods
            ?.filter((method) => method.isQr && method.isCustom === false)
            ?.map((method) => {
              method.isEnable = !method.paymentInstructionId
                ? false
                : method.isEnable;
              return (
                <PaymentSettingItem
                  disabled={!method.paymentInstructionId}
                  key={method.name}
                  method={method}
                  onUpdateSetting={handleUpdateGateway}
                  additionalInfo={
                    !method.paymentInstructionId && (
                      <span className="content-warning l-mr-8 align-middle">
                        Phương thức này chưa được thiết lập
                      </span>
                    )
                  }
                />
              );
            })}
          <div className="app-container padding-bottom-12 padding-top-12 cursor-pointer">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div
                className="d-flex"
                onClick={() =>
                  window.open(
                    `${appContext.shop_info.seller_domain}/settings/payments`,
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
              >
                <div
                  className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1`}
                  style={{ width: "40px", height: "40px" }}
                >
                  <Icon
                    name={"settings"}
                    color={"var(--contentSecondary)"}
                    className={"d-flex align-self-center"}
                  />
                </div>
                <p className="align-self-center text-decoration-none m-0">
                  Thiết lập cổng thanh toán tại MyHaravan
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom">
          <p className="font-weight-medium">Phương thức mặc định</p>
          {methods
            .filter((method) => !method.isQr && method.isCustom === false)
            .map((method) => (
              <PaymentSettingItem
                key={method.name}
                method={method}
                onUpdateSetting={handleUpdateGateway}
              />
            ))}
        </div>
        <div>
          <p className="font-weight-medium mt-4">
            Phương thức thanh toán tùy chỉnh
          </p>
          {/* <span className='text-secondary'>Đơn hàng sẽ được đánh dấu là "đã thanh toán" nếu chọn thanh toán bằng các phương thức này. Haravan POS sẽ không xử lý cá thanh toán tùy chỉnh.</span> */}
          {renderCustomMethodList()}
          <div className="d-flex flex-row">
            {/* Button Thêm  (Modal Add new, Edit gateway*/}
            <CustomPaymentMethod
              handleDeletePaymentMethod={(updateData) =>
                handleDeletePaymentMethod(updateData)
              }
              handleCreateCustomGateway={(updateData) =>
                handleCreateCustomGateway(updateData)
              }
              setCurrentMethod={setCurrentMethod}
              currentMethod={currentMethod}
              showCustomModal={showCustomModal}
              setShowCustomModal={setShowCustomModal}
              paymentMethods={methods}
              customPaymentList={methods.filter(
                (method) => method.isCustom === true,
              )}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <GlobalModal
      className={`${show && !showCustomModal ? "" : "d-none"}`}
      headerTitle={intl.formatMessage({
        id: "settings.generals.Thêm phương thức thanh toán",
        defaultMessage: "Thêm phương thức thanh toán",
      })}
      body={body()}
      show={show}
      setShow={setShow}
      onCancel={() => setShow(false)}
      cancelTitle="Đóng"
    ></GlobalModal>
  );
};

export default EditGatewayModal;
