import * as HrvComponents from "@haravan/react-components";
import LotDateList from "component-v2/LotDate/index";
import SelectLotDateModal from "component-v2/LotDate/SelectLotDateModal";
import _, { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { getDecimalValue } from "utils/Commons";
import { InputQuantity } from "component-v2/Input";
import { UnitSelection2 } from "component-v2/UnitSelection/index_backup";
import { useCartContext } from "../../index";
import { OrderLineDiscountForm } from "../OrderLineDiscountForm";
import Button from "component-v2/buttons";
import "./style.css";
import {
  DiscountAllocationType,
  productQuantityMax,
} from "screens-v2/carts-v2/cart-helpers.service";
import CurrencyShow from "component-v2/currency-show";
import { useApp } from "contexts/app";
import { ImageCustom } from "component-v2/ImageCustom";
import DiscountRecommendModal from "../DiscountRecommendModal";
import Icon from "component-v2/icons/icons";
import { useOnClickOutside } from "hooks/useOnClickOutSide";
import DiscountListModal from "../DiscountRecommendModal/DiscountListModal";
import { getLotsData } from "api/order-repository";
import { SelectionCustom } from "../../RightCart/components/SelectionCustom";
import { Fea_Adviser } from "configs";
function CartItem(props) {
  const { shop_info, shop_setting, callAPI, staff_users } = useApp();
  const appContext = useApp();
  const [_quantity, setQuantity] = useState(() => props.item.quantity);
  const [showModal, setShowModal] = useState(false);
  const [showRecommendModal, setShowRecommendModal] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState(false);
  const [showMorePromotions, setShowMorePromotions] = useState(false);
  const [listPromotions, setListPromotions] = useState([]);
  const [lotDateState, setLotDateState] = useState({
    isShowLotDateModal: false,
    currentOrderLineLotDate: null,
  });
  const {
    updateItemCurrentCart,
    removeItemCurrentCart,
    current_shopping_cart,
    onUpdateUnit,
    updateCurrentCart,
    onUpdateLineAdviser,
  } = useCartContext();
  const adviserRef = useRef(null);
  const showMoreRef = useRef(null);
  useOnClickOutside(showMoreRef, () => {
    setShowMorePromotions(false);
  });

  useEffect(() => {
    setQuantity(props.item.quantity);
  }, [props.item, props.item.quantity]);

  const { item, index, intl } = props;
  // const units = item.units ? getUnits(item) : [];
  const orderLineErr = current_shopping_cart?.orderLineErr || {};
  const has_promotion = item.promotionRef;
  const promotionAppliedQuantity = useMemo(() => {
    if (item.isPromotionGroupChild) {
      return current_shopping_cart?.lineItems?.reduce(
        (sum, l) =>
          !l.isPromotionGroupHeader && l.promotionId === item.promotionId
            ? (sum += l.quantity)
            : sum,
        0,
      );
    }
    return 0;
  }, [item, current_shopping_cart?.lineItems]);
  let promotionName = "";
  let properties = getProperties(item.discountAllocations, item.attributes);
  let textNotEdit = intl.formatMessage({
    id: "carts.orders.Sản phẩm khuyến mãi không được sửa giá",
    defaultMessage: "Sản phẩm khuyến mãi không được sửa giá",
  });
  if (item.discountAllocations) {
    promotionName = item.discountAllocations
      .map((element) => {
        let name = element.name;
        if (!name || !name.length)
          name =
            element.type === DiscountAllocationType.LINE_DISCOUNT
              ? "Giảm giá tùy chỉnh"
              : element.type;
        if (
          item.isPromotionGroupChild &&
          item.promotionId === element.discountId
        ) {
          name = "";
        }
        return name;
      })
      .filter(Boolean)
      .join(" | ");
  }

  function getProperties(discountAllocations, properties) {
    const discountIds = discountAllocations?.map((x) => x.discountId) || [];
    let newProperties = [];
    if (properties?.length) {
      properties.map((property) => {
        let arrs = property.value.split(" - ");
        let id = null;
        if (arrs.length >= 1) {
          id = Number(arrs[0]?.trim());
        }
        if (!id || discountIds.indexOf(id) === -1) {
          newProperties.push(property);
        }
      });
    }
    return newProperties;
  }

  // item.price = item.originalPrice - item.discountPromotion;
  if (item.discountValue) {
    //Discount type = 2 : percent
    //Discount type = 1 : amount
    if (item.discountTypeId === 2) {
      item.price = Number(
        (
          item.originalPrice -
          item.discountPromotion -
          ((item.originalPrice - item.discountPromotion) * item.discountValue) /
            100
        ).toFixed(4),
      );
    } else if (item.discountTypeId === 1) {
      let t = item.originalPrice - item.discountPromotion - item.discountValue;
      if (t < 0) t = 0;
      item.price = t;
    }
  }

  const handleOnChange = (e) => {
    let value = getDecimalValue(shop_info, e.target.value);
    if (value > productQuantityMax) {
      value = productQuantityMax;
    }
    if (!value) value = 1;
    debounceOnChange(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((value) => {
      setQuantity(value);
      updateItemCurrentCart(item, index, value, ["quantity"]);
    }, 500),
    [item, current_shopping_cart],
  );

  const handleDecreaseQuantity = () => {
    let value = getDecimalValue(shop_info, _quantity - 1);
    if (value > 0) {
      setQuantity(value);
      debounceOnChange(value);
    }
  };

  const handleInCreaseQuantity = () => {
    let value = getDecimalValue(shop_info, _quantity + 1);
    if (value <= productQuantityMax) {
      setQuantity(value);
      debounceOnChange(value);
    }
  };

  const onChangeUnit = (currentLineItem, unit) => {
    onUpdateUnit(currentLineItem, unit);
  };

  const onUpdateLotDate = (lotTarget) => {
    let indexChange = item.lots.findIndex((lot) => lot.id === lotTarget.id);
    let lots = [...item.lots];
    lots.splice(indexChange, 1, lotTarget);
    item.lots = lots;
    let totalQty = lots.reduce((total, lot) => total + lot.quantity, 0);
    updateItemCurrentCart(item, index, totalQty, ["quantity"], false);
  };

  const onDeleteLotDate = (lotTarget) => {
    let lots = item.lots.filter((lot) => lot.id !== lotTarget.id);
    item.lots = lots;
    let totalQty = lots.reduce((total, lot) => total + lot.quantity, 0) || 1;
    item.quantity = totalQty;
    updateItemCurrentCart(item, index, totalQty, ["quantity"], false);
  };

  const handleOpenModalLotDate = async () => {
    let lotsData = item.lotsData;
    if (!item.lotsData?.length) {
      lotsData = await getLotsData(
        callAPI,
        { ...item, productVariantsId: item.variantId },
        appContext,
      );
    }
    const currentLineItems = [...current_shopping_cart?.lineItems];
    const index = current_shopping_cart.lineItems?.findIndex(
      (l) => l.id === item.id,
    );
    if (index !== -1) {
      currentLineItems[index].lotsData = lotsData;
    }
    updateCurrentCart({
      lineItems: currentLineItems,
      items: currentLineItems,
    });
    setLotDateState({
      ...lotDateState,
      currentOrderLineLotDate: { ...item, lotsData },
      isShowLotDateModal: true,
    });
  };

  const handleOnSaveLotDateModal = (selectedLots) => {
    const _selectedLots = selectedLots.filter((lot) => lot.quantity > 0);
    const currentLineOrderId = lotDateState.currentOrderLineLotDate.id;
    let currentShoppingCartItems = _.cloneDeep(current_shopping_cart.lineItems);

    let totalQty = _selectedLots.reduce(
      (total, lot) => total + lot.quantity,
      0,
    );

    let currentOrderItem = currentShoppingCartItems.findIndex(
      (item) => item.id === currentLineOrderId,
    );
    currentShoppingCartItems[currentOrderItem].lots = _selectedLots;
    updateItemCurrentCart(
      currentShoppingCartItems[currentOrderItem],
      currentOrderItem,
      totalQty,
      ["quantity"],
      false,
    );
    setLotDateState({
      ...lotDateState,
      isShowLotDateModal: false,
    });
  };

  const handleOnCloseLotDateModal = () => {
    setLotDateState({
      ...lotDateState,
      currentOrderLineLotDate: null,
      isShowLotDateModal: false,
    });
  };

  const enableSubtract = item.quantity > 1;

  const itemDetailCell = () => (
    <div className="tbl-list-item">
      <div className="flex-stretch flex-column select-not-border">
        <p
          className="font-weight-medium content-primary"
          style={{
            color: `${
              item.id && orderLineErr.variantId === item.id ? "red" : ""
            }`,
          }}
        >
          {item.productTitle}
        </p>
        <p className="content-secondary l-mt-4">{item.variantTitle}</p>
        <p className="content-secondary l-mt-4">{item.barcode}</p>
        {item.pricingInValid && (
          <p className="content-warning l-mt-4">
            Không áp dụng với bảng giá hiện tại
          </p>
        )}
        {item.notAllowPromotion && (
          <p className="content-danger l-mt-4">
            {
              <span>
                {intl.formatMessage({
                  id: "carts.orders.Không áp dụng CTKM",
                  defaultMessage: "Không áp dụng CTKM",
                })}
              </span>
            }
          </p>
        )}
        {!!item.lineErrors?.length &&
          item.lineErrors?.map((error, index) => (
            <p
              key={`${error.refData}-${index}`}
              className="content-danger l-mt-4"
            >
              {
                <span>
                  {intl.formatMessage({
                    id: `notifications.${error?.message}`,
                    defaultMessage: `${error?.message}`,
                  })}
                </span>
              }
            </p>
          ))}
        {promotionName && (
          <p className="content-danger l-mt-4 l-pl-4 promotion-badge">
            {intl.formatMessage({
              id: `settings.print-templates.${promotionName}`,
              defaultMessage: promotionName,
            })}
          </p>
        )}
        {!current_shopping_cart?.payment_methods?.some(
          (method) => method.type === "cod" && method.enable,
        ) &&
        !current_shopping_cart?.isShipping &&
        item.lotSupport ? (
          <LotDateList
            onUpdateLotDate={(lot) => {
              onUpdateLotDate(lot);
            }}
            onDeleteLotDate={(lot) => {
              onDeleteLotDate(lot);
            }}
            orderLine={item}
            handleOpenModalLotDate={handleOpenModalLotDate}
          />
        ) : null}
        {/* ghi chú theo line */}
        {item?.adviser?.id || item.isShowAdviser ? (
          <div className="full-width d-flex align-items-center">
            <SelectionCustom
              inputId={`input-adviser-${item.id}`}
              key={`adviser-${item.id}`}
              style={{ maxWidth: "200px" }}
              width={180}
              options={staff_users}
              value={item?.adviser?.id}
              idField="id"
              valueField="name"
              placeholder="Chọn nhân viên"
              firstICon={"sticky_note_2"}
              onChange={(value) => {
                let user = staff_users.find((u) => u.id === value);
                onUpdateLineAdviser(item, user);
              }}
            >
              {staff_users?.map((staff) => {
                return (
                  <SelectionCustom.Option key={staff.id} value={staff.id}>
                    <div className="d-flex align-items-center">
                      {staff.name}
                    </div>
                  </SelectionCustom.Option>
                );
              })}
            </SelectionCustom>
            <Button
              className={"-mr-4px"}
              icon="close"
              plain
              size="xs"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const currentLineItems = [...current_shopping_cart?.lineItems];
                let index = currentLineItems.findIndex((l) => l.id === item.id);
                if (index !== -1) {
                  currentLineItems[index].isShowAdviser = false;
                  updateCurrentCart({ lineItems: currentLineItems });
                }
                if (item.adviser?.id) {
                  onUpdateLineAdviser(item, null);
                }
              }}
            />
          </div>
        ) : null}

        {properties?.length
          ? properties.map((property) => {
              if (
                property.key?.startsWith("sys.") ||
                property.key?.startsWith("x-haravan-")
              )
                return null;
              return (
                <span key={`${item.id}-${property.key}`}>
                  <strong>{property.key}</strong>: {property.value}
                </span>
              );
            })
          : null}
        <div className="mt-2 d-flex flex-wrap gap-4">
          {item.isPromotionGroupHeader && (
            <span
              className="promotion-group-header-badge cursor-pointer"
              onClick={() => {
                if (item.linePromotions) {
                  setListPromotions(
                    item.linePromotions?.filter((r) => r.isCanApply === false),
                  );
                  setShowMorePromotions(true);
                }
              }}
            >
              <Icon className="icon" name="check_mark" size="xs" />
              <span>{`Điều kiện KM`}</span>
            </span>
          )}
          {!!item?.canApplyPromotions?.length && (
            <div
              key={`${item?.canApplyPromotions[0]?.lineId}-${index}`}
              className="d-flex cursor-pointer"
              onClick={
                item?.canApplyPromotions?.length === 1
                  ? () => {
                      setSelectedPromotion(item?.canApplyPromotions[0]);
                      setShowRecommendModal(true);
                      setListPromotions(item?.linePromotions);
                    }
                  : () => {
                      setShowMorePromotions(true);
                      setListPromotions(item?.linePromotions);
                    }
              }
            >
              <div
                className="more-promotion-badge d-flex content-secondary align-items-center"
                key={`${item?.canApplyPromotions[0]?.lineId}=${index}`}
              >
                <span className="truncate-20ch">
                  {`[${item?.canApplyPromotions[0].buyQuantity}/${item?.canApplyPromotions[0].minOrderAmount}]`}
                  {` ${item?.canApplyPromotions[0]?.message}`}
                </span>
                <Icon size="2" name={"chevron_right"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {item.isPromotionGroupChild && !item.isPromotionGroupSibling && (
        <tr>
          <td className="border-0 pt-0 pb-0"></td>
          <td className="border-0 p-0 pr-3" colSpan={6}>
            <div className="promotion-group-caption">
              <div className="d-flex align-items-center">
                <Icon
                  className={"d-flex align-items-center"}
                  size="sm"
                  name={"card_giftcard"}
                  color={"var(--redrose600)"}
                />
                <p className="l-ml-4" style={{ fontWeight: 500 }}>
                  {item.promotionGroupCode}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="count-badge activated">
                  {`KM đã chọn: ${promotionAppliedQuantity}/${item.parentPromotion?.maxDiscountAmount}`}
                </span>
                <span
                  className="l-ml-8 cursor-pointer"
                  onClick={() => {
                    if (item.parentPromotion) {
                      setSelectedPromotion(item.parentPromotion);
                      setShowRecommendModal(true);
                      setListPromotions([item.parentPromotion]);
                    }
                  }}
                >
                  <Icon name="edit_pencil" />
                </span>
              </div>
            </div>
          </td>
        </tr>
      )}
      <tr
        className={`promotion-group ${
          item?.promotionGroup && item.isPromotionApplied
            ? item?.isPromotionGroupHeader
              ? "head"
              : "child"
            : ""
        }`}
      >
        <td>
          {!item.isPromotionGroupChild && (
            <span style={{ cursor: "pointer" }}>
              <Button
                icon="delete"
                plain
                onClick={() => removeItemCurrentCart(item.id, index)}
                size="sm"
              />
            </span>
          )}
        </td>
        <td
          colSpan={
            !item.isPromotionGroupChild &&
            current_shopping_cart?.reOrderLineItems
              ? 2
              : 1
          }
        >
          <div className="d-flex align-items-center">
            <div
              className={`${
                item.isPromotionGroupChild
                  ? "d-flex h-auto align-items-center"
                  : "d-flex justify-content-left"
              }`}
            >
              {item.isPromotionGroupChild && (
                <span className="l-m-8" style={{ cursor: "pointer" }}>
                  <Button
                    icon="delete"
                    plain
                    onClick={() => removeItemCurrentCart(item.id, index)}
                    size="sm"
                  />
                </span>
              )}
              <div className="cart-product-item--image">
                <ImageCustom src={item?.imageUrl} alt="product image" />
              </div>
            </div>
            {!item.isPromotionGroupChild &&
              current_shopping_cart?.reOrderLineItems && (
                <div className="l-ml-16">{itemDetailCell()}</div>
              )}
          </div>
        </td>
        {(item.isPromotionGroupChild ||
          !current_shopping_cart?.reOrderLineItems) && (
          <td className="l-table--col-stretch">{itemDetailCell()}</td>
        )}
        <td>
          <div className="tbl-list-item">
            {item.units && item.units.length > 0 && (
              <UnitSelection2
                isDisabled={item.promotionRef}
                options={item.units}
                selectedOption={item.selectedUnit}
                onChange={(unit) => onChangeUnit(item, unit)}
              />
            )}
          </div>
        </td>

        <td className="l-table--col-number">
          <div
            className="tbl-list-item position-relative"
            onClick={() => {
              if (!item.notAllowPromotion) {
                shop_setting.promotionEnable &&
                  !has_promotion &&
                  setShowModal(true);
              }
            }}
          >
            <HrvComponents.Tooltip
              title={`${has_promotion ? textNotEdit : ""}`}
              overlayClassName="tooltip-promotion"
            >
              <p
                className={`${
                  shop_setting.promotionEnable && !item.notAllowPromotion
                    ? "cursor-pointer content-accent"
                    : ""
                }`}
              >
                <CurrencyShow value={item.price} />
              </p>
            </HrvComponents.Tooltip>
            <p className="tbl-discount-price">
              {item.originalPrice > 0 &&
                Math.round(item.originalPrice) > Math.round(item.price) && (
                  <strike className="cursor-pointer content-tertiary">
                    <CurrencyShow value={item.originalPrice} />
                  </strike>
                )}
            </p>
          </div>
        </td>
        <td className="l-table--col-adjust">
          <HrvComponents.Tooltip
            placement="bottom"
            title={
              !item.stockUnavailable ? (
                ""
              ) : (
                <FormattedMessage
                  id="Khả dụng"
                  defaultMessage="Khả dụng: {x}"
                  values={{ x: item.availableQuantity }}
                />
              )
            }
            overlayClassName="tooltip-custom"
          >
            <div>
              <InputQuantity
                className={
                  !item.stockUnavailable
                    ? "hrv-input-sm "
                    : "hrv-input-sm hrv-next-input-unavailable "
                }
                enableSubtract={enableSubtract}
                enableAdd={true}
                disabled={false}
                value={_quantity}
                onChange={handleOnChange}
                max={productQuantityMax}
                onSubtract={() => enableSubtract && handleDecreaseQuantity()}
                onAdd={() => handleInCreaseQuantity()}
                onBlur={(e) => props?.setItemSelected(null)}
                onFocus={(e) => {
                  props?.setItemSelected(index);
                }}
              />
            </div>
          </HrvComponents.Tooltip>
        </td>
        <td className="l-table--col-number">
          <div className="tbl-list-item">
            <CurrencyShow value={item.price * item.quantity} />
          </div>
        </td>
        {Fea_Adviser(appContext.auth?.user?.orgid) ? (
          <td className="l-table--col-action">
            <div className="tbl-list-item">
              <Button
                icon="sticky_note_2"
                plain
                onClick={() => {
                  let isShow = !item.isShowAdviser;
                  if (item.adviser?.id) {
                    isShow = true;
                  }
                  const currentLineItems = [
                    ...current_shopping_cart?.lineItems,
                  ];
                  let index = currentLineItems.findIndex(
                    (l) => l.id === item.id,
                  );
                  if (index !== -1) {
                    currentLineItems[index].isShowAdviser = isShow;
                    updateCurrentCart({ lineItems: currentLineItems });
                    if (isShow) {
                      let element = document.getElementById(
                        `input-adviser-${item.id}`,
                      );
                      element?.click();
                    }
                  }
                }}
              />
            </div>
          </td>
        ) : null}
      </tr>
      {(!!item.linePromotions?.length || !!item.parentPromotion) && (
        <>
          <DiscountRecommendModal
            item={item}
            promotion={selectedPromotion}
            show={showRecommendModal}
            setShow={setShowRecommendModal}
            onClickBack={() => {
              setShowRecommendModal(false);
              setShowMorePromotions(true);
            }}
          />
          <DiscountListModal
            show={showMorePromotions}
            listPromotions={listPromotions}
            setShow={setShowMorePromotions}
            item={item}
            onClickPromotion={(selected) => {
              setShowMorePromotions(false);
              setSelectedPromotion(selected);
              setShowRecommendModal(true);
            }}
          />
        </>
      )}
      <OrderLineDiscountForm
        showModal={showModal}
        setShowModal={setShowModal}
        currentLineData={item}
        index={index}
      />
      <SelectLotDateModal
        lotsSelected={lotDateState.currentOrderLineLotDate?.lots}
        lotsData={lotDateState.currentOrderLineLotDate?.lotsData}
        handleOnSave={handleOnSaveLotDateModal}
        onCloseModal={handleOnCloseLotDateModal}
        isShowModal={lotDateState.isShowLotDateModal}
        handleModalAction={(value) =>
          setLotDateState({
            ...lotDateState,
            isShowLotDateModal: value,
          })
        }
      />
    </React.Fragment>
  );
}

export { CartItem };
