import { Input, InputRadio } from "@haravan/react-components";
import { createCustomer } from "api/customer-repository";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { isNil } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import NumberFormat from "react-number-format";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { isValidPhoneNumber, validateEmail } from "utils/Commons";
import AddressesGlobal from "./addressesGlobal";

const customerDefault = {
  lastName: "",
  firstName: "",
  gender: 1,
  phone: null,
  email: "",
  birthday: "",
  customerAddress: [
    {
      address1: "",
      provinceId: null,
      districtId: null,
      wardId: null,
      phone: "",
    },
  ],
};

export const ModalAddNewCustomer = ({
  show,
  setShow,
  handleCallApiSuccess,
}) => {
  const { callAPI } = useApp();
  const [customerInfo, setCustomerInfo] = useState(customerDefault);
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  const [customerAddress, setCustomerAddress] = useState(
    customerDefault.customerAddress[0],
  );
  const [loadingBtnSave, setLoadingBtnSave] = useState(false);

  useEffect(() => {
    if (show) {
      setCustomerInfo(customerDefault);
      setCustomerAddress(customerDefault.customerAddress[0]);
    }
  }, [show]);

  const onChangeBirthday = (e) => {
    if (e.formattedValue === "") {
      handleUpdateCustomer("birthday", "");
      return;
    }
    if (!e.formattedValue.includes("Y") && e.formattedValue !== "") {
      //chuyen dinh dang ngay input => MM/DD/YYY
      let myDate = moment(e.formattedValue, "DD/MM/YYYY").format("MM/DD/YYYY");
      if (myDate === "Invalid date") {
        handleUpdateCustomer(
          "birthday",
          moment(new Date()).format("DD/MM/YYYY"),
        );
        return;
      }
      let curr = new Date(myDate);
      curr.setDate(curr.getDate() + 1);
      var dateYear = moment(curr).format("YYYY");
      var nowYear = moment(Date.now.date).format("YYYY");
      var now = moment(Date.now.date).format("DD/MM/YYYY");
      //chuyen lai dinh dang => dd/mm/yyyy de show len
      var newday = moment(myDate, "MM/DD/YYYY").format("DD/MM/YYYY");
      if (dateYear > nowYear || dateYear < 1900) {
        handleUpdateCustomer("birthday", now);
      } else {
        handleUpdateCustomer("birthday", newday);
      }
    }
  };
  useEffect(() => {
    if (typeof show !== "boolean" && isValidPhoneNumber(show)) {
      setCustomerInfo((prev) => ({
        ...prev,
        phone: show,
      }));
    }
  }, [show]);

  const handleUpdateCustomer = (key, value) => {
    setCustomerInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validate = () => {
    if (
      isNil(customerInfo.firstName) &&
      isNil(customerInfo.lastName) &&
      isNil(customerInfo.email)
    ) {
      let message = "Khách hàng phải có họ tên, địa chỉ email";
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      return true;
    }
    if (
      !isNil(customerInfo.lastName) &&
      customerInfo.lastName.trim().length > 100
    ) {
      let message = "Họ không được vượt quá 100 ký tự";
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      return true;
    }
    if (
      !isNil(customerInfo.firstName) &&
      customerInfo.firstName.trim().length > 100
    ) {
      let message = "Tên không được vượt quá 100 ký tự";
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      return true;
    }
    if (!isNil(customerInfo.email) && !validateEmail(customerInfo.email)) {
      let message = "Email không hợp lệ";
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      return true;
    }
    if (!isNil(customerInfo.email) && customerInfo.email.length > 50) {
      let message = "Địa chỉ Email không được quá 50 ký tự";
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      return true;
    }
    if (!isNil(customerInfo.phone)) {
      if (isNaN(Number(customerInfo.phone))) {
        let message = "Số điện thoại không hợp lệ";
        showGlobalToast(
          "default",
          intl.formatMessage({
            id: `notifications.${message}`,
            defaultMessage: message,
          }),
        );
        return true;
      }
      if (!isValidPhoneNumber(customerInfo.phone)) {
        let message = "Độ dài số điện thoại phải từ 8 đến 15 ký tự";
        showGlobalToast(
          "default",
          intl.formatMessage({
            id: `notifications.${message}`,
            defaultMessage: message,
          }),
        );
        return true;
      }
    }
    return false;
  };

  async function handleCreateCustomer() {
    if (validate()) return;
    const customer = {
      lastName: customerInfo.lastName,
      firstName: customerInfo.firstName,
      gender: customerInfo.gender,
      phone: customerInfo.phone,
      email: customerInfo.email,
      birthday: moment(customerInfo.birthday).isValid()
        ? moment(customerInfo.birthday, "DD/MM/YYYY").format("YYYY/MM/DD")
        : "",
      customerAddress: [
        {
          address1: customerAddress.address1,
          provinceId: customerAddress.provinceId,
          districtId: customerAddress.districtId,
          wardId: customerAddress.wardId,
          phone: customerInfo.phone,
        },
      ],
    };
    setLoadingBtnSave(true);
    const result = await createCustomer(callAPI, customer);
    setLoadingBtnSave(false);
    if (result.data) {
      let message = "Tạo khách hàng thành công";
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      handleCallApiSuccess(result.data);
      setShow(null);
      setCustomerInfo(customerDefault);
    } else if (result?.errors?.length) {
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${result?.errors[0]}`,
          defaultMessage: result?.errors[0],
        }),
      );
    }
  }

  const body = () => {
    return (
      <div className="modal-create-customer">
        <div className="d-flex align-items-center mb-2">
          <InputRadio
            name="gender"
            checked={customerInfo.gender === 1}
            onChange={() => {
              handleUpdateCustomer("gender", 1);
            }}
          >
            Anh
          </InputRadio>
          <InputRadio
            className="ml-3 mt-0"
            name="gender"
            checked={customerInfo.gender === 0}
            onChange={() => {
              handleUpdateCustomer("gender", 0);
            }}
          >
            Chị
          </InputRadio>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 mt-3">
            <p className="mb-2">Họ</p>
            <Input
              placeholder="Họ"
              value={customerInfo.lastName || ""}
              onChange={(value) => handleUpdateCustomer("lastName", value)}
            />
          </div>
          <div className="col-12 col-lg-6 mt-3">
            <p className="mb-2">Tên</p>
            <Input
              placeholder="Tên"
              value={customerInfo.firstName || ""}
              onChange={(value) => handleUpdateCustomer("firstName", value)}
            />
          </div>
          <div className="col-12 col-lg-6 mt-3">
            <p className="mb-2">Ngày sinh</p>
            <NumberFormat
              className={`hrv-next-input`}
              format="##/##/####"
              placeholder="dd/mm/yyyy"
              onValueChange={(value) => {
                onChangeBirthday(value);
              }}
              value={customerInfo.birthday || ""}
              mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
            />
          </div>
          <div className="col-12 col-lg-6 mt-3">
            <p className="mb-2">Số điện thoại</p>
            <Input
              placeholder="Số điện thoại"
              value={customerInfo.phone || ""}
              onChange={(value) => handleUpdateCustomer("phone", value)}
            />
          </div>
          <div className="col-12 col-lg-6 mt-3">
            <p className="mb-2">Email</p>
            <Input
              placeholder="Email"
              value={customerInfo.email || ""}
              onChange={(value) => handleUpdateCustomer("email", value)}
            />
          </div>
          <AddressesGlobal
            onlyVN
            countryId={customerAddress[0]?.countryId || 241}
            provinceId={customerAddress[0]?.provinceId}
            districtId={customerAddress[0]?.districtId}
            wardId={customerAddress[0]?.wardId}
            handleOnChange={(data) => {
              setCustomerAddress((prev) => ({
                ...prev,
                ...data,
              }));
            }}
          />
          <div className="col-12 mt-3">
            <p className="mb-2">Địa chỉ</p>
            <Input
              placeholder="Địa chỉ"
              value={customerAddress.address1 || ""}
              onChange={(value) =>
                setCustomerAddress((prev) => ({
                  ...prev,
                  address1: value,
                }))
              }
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      headerTitle={"Tạo khách hàng mới"}
      isTitleCenter
      body={body()}
      onCancel={() => setShow(null)}
      onConfirm={() => handleCreateCustomer()}
      confirmTitle="Lưu"
      loading={loadingBtnSave}
      show={!!show}
      setShow={(value) => setShow(value ? value : null)}
    />
  );
};

export default ModalAddNewCustomer;
