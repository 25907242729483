import React from "react";
import { Switch } from "@haravan/react-components";
import { SaleSettingModels } from "models/setting-models";
import { useIntl } from "react-intl";
import { updateSettingSelling } from "api/settings-repository";
import { useMemo } from "react";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  LayoutCard,
  LayoutCardSection,
  LayoutCardHeader,
} from "screens-v2/layouts/layouts/omni-layout-card";
import { useApp } from "contexts/app";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";

function SaleSetting() {
  const intl = useIntl();
  const layout = useLayoutContext();
  const { shop_setting, refreshShopSetting, current_location } = useApp();
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const saleSettingModels = SaleSettingModels(shop_setting);
  const saleValues = useMemo(() => {
    return {
      allowOrderProductOutOfStock: shop_setting?.allowOrderProductOutOfStock,
      hideProductOutOfStock: shop_setting?.hideProductOutOfStock,
      allowCreateCustomProduct: shop_setting?.allowCreateCustomProduct,
      onlyAllowExchange: shop_setting?.onlyAllowExchange,
      allowRefundOrderAllLocation: shop_setting?.allowRefundOrderAllLocation,
      notAllowRefundIfLowerPrice: shop_setting?.notAllowRefundIfLowerPrice,
      refundOnlyOnceEnable: shop_setting?.refundOnlyOnceEnable,
      allowCancelOrder: shop_setting?.allowCancelOrder,
      isConfirmOrderEnable: shop_setting?.isConfirmOrderEnable,
      allowDisplayOnlineOrder: shop_setting?.allowDisplayOnlineOrder,
      isSendEmailConfirm: shop_setting?.isSendEmailConfirm,
      hideOrderShippingState: shop_setting?.hideOrderShippingState,
      isImportOrder: shop_setting?.isImportOrder,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop_setting]);
  const appContext = useApp();
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        setShowWarningModal(false);
      }
      if (e.key === HOTKEY_EVENTS.F8) {
        setShowWarningModal(false);
        onUpdateSetting("allowOrderProductOutOfStock");
      }
    },
    enabled: showWarningModal,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  const onUpdateSetting = async (settingKey) => {
    const value = !shop_setting[settingKey];
    let model = {
      ...saleValues,
      [settingKey]: value,
    };
    if (
      settingKey === "allowOrderProductOutOfStock" &&
      value &&
      shop_setting.hideProductOutOfStock
    ) {
      model = {
        ...model,
        hideProductOutOfStock: false,
      };
    } else if (
      settingKey === "allowCreateCustomProduct" &&
      value &&
      shop_setting.allowCreateCustomProduct
    ) {
      model = {
        ...model,
        allowCreateCustomProduct: false,
      };
    }
    const response = await updateSettingSelling(
      appContext,
      model,
      current_location.id,
    );
    if (response.data) {
      await refreshShopSetting();
      layout.showGlobalToast(
        "default",
        "Cấu hình đã được cập nhật thành công!",
      );
    } else {
      layout.showGlobalToast(
        "error",
        "Cấu hình cập nhật không thành công. Vui lòng thử lại!",
      );
    }
  };

  return (
    <LayoutCard className="bg-transparent">
      <LayoutCardSection className="pt-0">
        <LayoutCardHeader title="Bán hàng" />
        <p className="text-secondary mt-2">
          Chọn cấu hình bán hàng áp dụng cho tất cả các chi nhánh
        </p>
        {Object.keys(saleSettingModels).map((sale, idx) => {
          return (
            <div
              className={`l-mt-16${idx === 0 ? " border-top l-pt-16" : ""}`}
              key={sale}
            >
              <p className="text-gray font-weight-medium padding-bottom-16 text-secondary">
                {intl.formatMessage({
                  id: `settings.carts.${sale}`,
                  defaultMessage: sale,
                })}
              </p>
              <LayoutCard className="d-flex flex-column l-mt-8">
                {saleSettingModels[sale].map((item) => {
                  if (item.isHidden) return null;
                  return (
                    <div
                      className="l-segment app-container padding-16"
                      key={item.key}
                    >
                      <div className="row">
                        <div className="col">
                          <div>
                            <p className="text-default font-weight-medium">
                              {intl.formatMessage({
                                id: `settings.carts.${item.title}`,
                                defaultMessage: item.title,
                              })}
                            </p>
                            <p className="text-secondary font-size-12">
                              {intl.formatMessage({
                                id: `settings.carts.${item.desc}`,
                                defaultMessage: item.desc,
                              })}
                            </p>
                          </div>
                        </div>
                        <div className="col-auto text-right">
                          <Switch
                            onChange={() => {
                              if (
                                item.key === "allowOrderProductOutOfStock" &&
                                shop_setting[item.key] === true &&
                                shop_setting.isNewPos === true
                              ) {
                                setShowWarningModal(true);
                              } else {
                                onUpdateSetting(item.key);
                              }
                            }}
                            checked={saleValues[item.key]}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </LayoutCard>
            </div>
          );
        })}
      </LayoutCardSection>
      <GlobalModal
        headerTitle="Tắt bán hàng khi hết tồn kho"
        isTitleCenter
        show={showWarningModal}
        setShow={setShowWarningModal}
        body={
          <p className="margin-top-10 text-custom-color">
            {intl.formatMessage({
              id: "carts.orders.Các đơn chưa hoàn thành tại màn hình bán hàng được tạo ra trước thời điểm thay đổi cấu hình vẫn có thể bán sản phẩm kho hết tồn.",
              defaultMessage:
                "Các đơn chưa hoàn thành tại màn hình bán hàng được tạo ra trước thời điểm thay đổi cấu hình vẫn có thể bán sản phẩm kho hết tồn.",
            })}
          </p>
        }
        footer={
          <>
            <div className="mt-4 d-flex justify-content-end align-items-center">
              <Button
                className="mr-2 light text-uppercase"
                onClick={() => {
                  setShowWarningModal(false);
                }}
                title="Hủy"
                badge={HOTKEYS.ESC}
              />
              <Button
                status="primary"
                onClick={() => {
                  setShowWarningModal(false);
                  onUpdateSetting("allowOrderProductOutOfStock");
                }}
                title="Xác nhận"
                badge={HOTKEYS.F8}
              />
            </div>
          </>
        }
      />
    </LayoutCard>
  );
}

export default SaleSetting;
