import EmptyState from "component-v2/empty-state";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import CurrencyShow from "component-v2/currency-show";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import ShiftDetailActivitiesItem from "./shift-detail-activities-item";

export default function ShiftDetailActivities({ activities, orders }) {
  const [tab, setTab] = useState(1);
  const _count = orders?.length;
  return (
    <div>
      <div className="l-tab-group l-mt-16">
        <div
          className={`l-tab${tab === 1 ? " active" : ""}`}
          onClick={() => setTab(1)}
        >
          Hoạt động
        </div>
        <div
          className={`l-tab${tab === 2 ? " active" : ""}`}
          onClick={() => setTab(2)}
        >
          Đơn hàng
          {_count ? (
            <>
              &nbsp;
              <Badge
                status={tab === 2 ? "info" : "gray"}
                light={tab !== 2}
                rounded
                size="S"
              >
                {_count}
              </Badge>
            </>
          ) : null}
        </div>
      </div>
      <div className="l-mt-8">
        {tab === 1 ? <ActivitiesBody activities={activities} /> : null}
        {tab === 2 ? <OrdersBody orders={orders} /> : null}
      </div>
    </div>
  );
}
function ActivitiesBody({ activities }) {
  return (
    <div className="l-table align-middle">
      {!activities?.length ? (
        <div>
          <div className="position-relative">
            <EmptyState
              iconName="format_list_bulleted"
              description="Không có dữ liệu"
            />
          </div>
        </div>
      ) : null}
      {activities?.map((activity, idx) => {
        return (
          <>
            <ShiftDetailActivitiesItem key={idx} activity={activity} />
          </>
        );
      })}
    </div>
  );
}
function OrdersBody({ orders }) {
  return (
    <div className="l-table">
      <div className="l-table-head">
        <div>Mã đơn hàng</div>
        <div className="l-table--col-number">Đã thu</div>
        <div className="l-table--col-number">Hoàn trả</div>
        <div className="l-table--col-number">Thực nhận</div>
      </div>
      {!orders?.length ? (
        <div className="position-relative" style={{ height: "20rem" }}>
          <EmptyState
            className="position-absolute"
            iconName="format_list_bulleted"
            description="Không có dữ liệu"
          />
        </div>
      ) : null}
      {orders?.map((order, idx) => {
        return (
          <div key={order.orderId + "-" + idx}>
            <div>
              <NavLink to={`/orders/${order.orderId}`}>
                {order.orderNumber}
              </NavLink>
            </div>
            <div className="l-table--col-number">
              <CurrencyShow value={order.salesAmountTotal} />
            </div>
            <div className="l-table--col-number">
              <CurrencyShow value={order.refundAmountTotal} />
            </div>
            <div className="l-table--col-number">
              <CurrencyShow
                value={order.salesAmountTotal - order.refundAmountTotal}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
