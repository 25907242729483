import Icon from "component-v2/icons/icons";
import { DateTimeShowType } from "infrastructures/format-helpers";
import { FormattedMessage } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import DateTimeShow from "component-v2/datetime-show";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { useShiftContext } from "..";

export default function ShiftListItem({ item, onClick = null }) {
  const shiftContext = useShiftContext();
  let totalPrice = 0;
  if (item.status === "opening")
    totalPrice = shiftContext.current_shift?.netAmountTotal;
  else {
    const {
      cashAmount,
      visaAmount,
      transferAmount,
      momoAmount,
      zaloPayAmount,
      vnPayAmount,
      mocaAmount,
      cashAmountRefund,
      visaAmountRefund,
      transferAmountRefund,
      momoAmountRefund,
      zaloPayAmountRefund,
      vnPayAmountRefund,
      mocaAmountRefund,
      otherPaymentAmount,
      otherPaymentAmountRefund,
    } = item;
    const salesAmountTotal =
      cashAmount +
      visaAmount +
      transferAmount +
      momoAmount +
      zaloPayAmount +
      vnPayAmount +
      mocaAmount +
      otherPaymentAmount;
    const refundAmountTotal =
      cashAmountRefund +
      visaAmountRefund +
      transferAmountRefund +
      momoAmountRefund +
      zaloPayAmountRefund +
      vnPayAmountRefund +
      mocaAmountRefund +
      otherPaymentAmountRefund;
    totalPrice = salesAmountTotal - refundAmountTotal;
  }
  let _selected = shiftContext.current_shift?.id === item.id;
  let _same_day = true;
  let _open = item.openAt ? new Date(item.openAt) : null;
  let _close = item.closedAt ? new Date(item.closedAt) : null;
  if (_open && _close) {
    _same_day =
      _open.getFullYear() === _close.getFullYear() &&
      _open.getMonth() === _close.getMonth() &&
      _open.getDate() === _close.getDate();
  }
  return (
    <div
      className={`shift-list--item${_selected ? " selected" : ""}`}
      onClick={(e) => {
        if (_selected) return;
        onClick(e, item);
      }}
    >
      <div className="flex-stretch flex-column">
        <span className="font-weight-medium">
          <DateTimeShow date={item.openAt} type="date" />
          {item.note || item.lastActivityNote ? (
            <Icon
              name="note"
              size="1.6"
              color="var(--contentTertiary)"
              className="l-ml-4"
            />
          ) : (
            ""
          )}
        </span>
        <span className="content-secondary l-mt-4">
          <DateTimeShow
            date={item.openAt}
            type={DateTimeShowType.custom}
            format={"HH:mm"}
          />
          &nbsp;-&nbsp;
          {item.status === "closed" ? (
            <DateTimeShow
              date={item.closedAt}
              type={DateTimeShowType.custom}
              format={_same_day ? "HH:mm" : "DD/MM/YYYY HH:mm"}
            />
          ) : (
            <FormattedMessage id="shifts.Đang mở" defaultMessage="Đang mở" />
          )}
        </span>
      </div>
      <div className="flex-auto flex-column">
        <span className="text-right">
          {item.status === "closed" ? (
            <Badge light rounded status={"gray"}>
              Đã đóng
            </Badge>
          ) : (
            <Badge light rounded status={"green"}>
              Đang mở
            </Badge>
          )}
        </span>
        <span className="l-mt-4 text-right">
          <CurrencyShow value={Math.round(totalPrice ?? 0)} />
        </span>
      </div>
    </div>
  );
}
