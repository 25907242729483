class RandomColor {
  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    if (
      color !== "#3C83F6" &&
      color !== "#F97415" &&
      color !== "#14B8A5" &&
      color !== "#6467F2" &&
      color !== "#FDBD41"
    ) {
      return color;
    }
  }
}

const randomColor = new RandomColor();

export default randomColor;
