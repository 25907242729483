import { InputHasIcon, Loading } from "@haravan/react-components";
import { getCustomers } from "api/customer-repository";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { useOnClickOutside } from "hooks/useOnClickOutSide";
import { useWindowSize } from "hooks/useWindowSize";
import { debounce } from "lodash";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { useCallback, useMemo, useRef, useState } from "react";
import { concatString } from "screens-v2/carts-v2/utils";
import { getZoomTarget, isValidPhoneNumber } from "utils/Commons";
import { ModalCreateCustomer } from "../components/modalCreateCustomer";
import Tooltip from "rc-tooltip";
import { getChromeVersion } from "infrastructures/utils";

const INPUT_SEARCH_CUSTOMER_ID = "cart-input-search-customer";
export const CustomerInput = ({ width, handleSelectCustomer }) => {
  const { checkRoleAllow, callAPI } = useApp();
  const [searchText, setSearchText] = useState("");
  const [customers, setCustomers] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [showModalCreateCustomer, setShowModalCreateCustomer] = useState(null);
  const [isShowResult, setIsShowResult] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const allowWriteCustomer = checkRoleAllow("com_api.customer.write");
  const intObserver = useRef(null);
  const this_ref = useRef(null);
  const refInput = useRef(null);
  const refFirstItem = useRef(null);
  const refDropdown = useRef(null);
  const { width: widthWindow, height } = useWindowSize();
  const [mouseOverResult, setMouseOverResult] = useState(false);
  const zoomTarget = useMemo(() => getZoomTarget(widthWindow), [widthWindow]);
  const chromeVer = getChromeVersion();
  const newZoomStandard = chromeVer && chromeVer >= 128 ? zoomTarget : 1;
  useOnClickOutside(this_ref, () => {
    !mouseOverResult && setIsShowResult(false);
    setIsFocused(false);
  });

  const parentPositionInput = useMemo(() => {
    return refInput?.current?.ref_Input?.ref_input?.parentElement?.getBoundingClientRect();
  }, [refInput?.current, widthWindow, height]);

  useGlobalHotkeys({
    keys: [
      HOTKEYS.F2,
      HOTKEYS.F3,
      HOTKEYS.F8,
      HOTKEYS.DOWN,
      HOTKEYS.UP,
      HOTKEYS.ENTER,
    ],
    scopes: HOTKEY_SCOPES.CART,
    enabled: !isLoadingMore && !loadingCustomer,
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.F2 || e.key === HOTKEY_EVENTS.F8) {
        setIsFocused(false);
        setIsShowResult(false);
      }

      if (e.key === HOTKEY_EVENTS.F3) {
        let input = document.getElementById(INPUT_SEARCH_CUSTOMER_ID);
        if (input) {
          input.focus();
        }
      }

      let activeElement = document.querySelector(".selected-active");

      if (activeElement?.id) {
        // di chuyển giữa các items
        if (activeElement.id?.startsWith("search-customer--item-")) {
          if (
            e.key === HOTKEY_EVENTS.DOWN &&
            activeElement.nextElementSibling
          ) {
            activeElement.classList.remove("selected-active");
            activeElement.nextElementSibling.classList.add("selected-active");
            activeElement.nextElementSibling.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }

          if (
            e.key === HOTKEY_EVENTS.UP &&
            activeElement.previousElementSibling
          ) {
            activeElement.classList.remove("selected-active");
            activeElement.previousElementSibling.classList.add(
              "selected-active",
            );

            activeElement.previousElementSibling.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
          if (e.key === HOTKEY_EVENTS.ENTER) {
            activeElement.click();
          }
        }
      }
    },
  });

  const lastCustomerRef = useCallback(
    (customer) => {
      if (isLoadingMore) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((data) => {
        if (data[0].isIntersecting && hasNextPage) {
          handleGetCustomers(searchText, true, customers);
        } else {
          setIsLoadingMore(false);
        }
      });

      if (customer) intObserver.current.observe(customer);
    },
    [isLoadingMore, hasNextPage],
  );

  const handleInputFocus = (event) => {
    event?.target?.select();
    setIsFocused(true);
    setIsShowResult(true);
  };

  const handleInputChanged = async (value) => {
    setSearchText(value);
    if (value.length >= 2) {
      setLoadingCustomer(true);
    }
    debounceSearch(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(async (value) => {
      await handleGetCustomers(value);
    }, 500),
    [],
  );

  const handleGetCustomers = async (
    searchText,
    isLoadingMore = false,
    data = [],
  ) => {
    try {
      if (!searchText) {
        setCustomers([]);
        return;
      } else if (searchText.length >= 2) {
        setIsShowResult(true);
        isLoadingMore ? setIsLoadingMore(true) : setLoadingCustomer(true);
        const text = encodeURIComponent(searchText.trim());
        const page =
          data?.length || 0 ? Math.round(customers?.length / 10) + 1 : 1;
        const result = await getCustomers(callAPI, text, page);
        let customersDraft = result.data.data;
        if (customersDraft.length > 0) {
          customersDraft = customersDraft
            .filter((c) => c.value !== "guest@haravan.com")
            .map((c) => {
              return { ...c, email: c.value };
            });
          // this.isScanCusLoy = false;
          // this.totalRecord = result.data.totalRecord;
          if (isLoadingMore) {
            customersDraft = [...data, ...customersDraft];
          }
          if (result.data.totalRecord > customersDraft.length + 1) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
        } else {
          // const customerLoy = await this.customerScanLoyalty(text);
          // this.customers = [customerLoy.data] || [];
          // this.isScanCusLoy = true;
        }
        setCustomers(customersDraft);
        setLoadingCustomer(false);
        setIsLoadingMore(false);
      }
    } catch (e) {
      setCustomers([]);
      setLoadingCustomer(false);
      setIsLoadingMore(false);
    }
  };

  let content = () => {
    return (
      <>
        {customers.map((customer, i) => {
          return (
            <div
              tabIndex={0}
              id={`search-customer--item-${i}`}
              ref={
                customers?.length === i + 1
                  ? lastCustomerRef
                  : i === 0
                    ? refFirstItem
                    : null
              }
              key={customer.key}
              className={`l-section d-flex align-items-center justify-content-start customer-select cursor-pointer list-group-item cart-product-search-item ${i === 0 ? "selected-active" : ""}`}
              onClick={() => {
                handleSelectCustomer(customer);
              }}
            >
              <Icon name="user" className="ml-2 mr-4" size="2.4" />
              <div>
                <p className="font-weight-semibold text-break content-primary l-mb-4">
                  {customer.name || "--"}
                </p>
                <p className="font-size-12 content-secondary m-0">
                  {concatString({
                    value: [customer.phone, customer.email],
                    separator: " · ",
                  })}
                </p>
              </div>
            </div>
          );
        })}
        {isLoadingMore && (
          <div className="m-auto">
            <Loading size="pico" />
          </div>
        )}
      </>
    );
  };

  const contentNotFound = () => {
    return (
      <>
        {allowWriteCustomer ? (
          <>
            {!isValidPhoneNumber(searchText?.trim()) ? (
              <div
                ref={refFirstItem}
                tabIndex={0}
                id={`search-customer--item-0`}
                className="selected-active border-0 d-flex content-accent font-weight-semibold align-items-center justify-content-start customer-select cursor-pointer list-group-item cart-product-search-item"
                onClick={() => {
                  setShowModalCreateCustomer(true);
                  setIsShowResult(false);
                  refInput?.current?.ref_Input?.ref_input?.blur();
                }}
              >
                <Icon
                  name="add"
                  color="var(--contentAccent)"
                  className="ml-2 mr-4"
                />
                <p className="mb-0">Tạo khách mới</p>
              </div>
            ) : null}
            {isValidPhoneNumber(searchText?.trim()) &&
              (!customers?.length ||
                !customers.every(
                  (customer) => customer.phone === searchText?.trim(),
                )) && (
                <div
                  tabIndex={0}
                  id={`search-customer--item-0`}
                  className="border-0 d-flex content-accent font-weight-semibold align-items-center justify-content-start customer-select cursor-pointer list-group-item cart-product-search-item selected-active"
                  onClick={() => {
                    setShowModalCreateCustomer(searchText?.trim());
                    setIsShowResult(false);
                    refInput?.current?.ref_Input?.ref_input?.blur();
                  }}
                >
                  <Icon
                    name="add"
                    color="var(--contentAccent)"
                    className="ml-2 mr-4"
                  />
                  <p className="mb-0">Tạo khách mới với số {searchText}</p>
                </div>
              )}
          </>
        ) : null}
        <div className="py-5 border-top">
          <div className="d-flex justify-content-center">
            <div className="bg-light rounded-circle p-4 m-atuo">
              <Icon name="user" size="lg" color="var(--truegray300)" />
            </div>
          </div>
          <p className="font-size-16 font-weight-semibold py-3 text-center">
            Không tìm thấy khách hàng
          </p>
        </div>
      </>
    );
  };

  const contentLoading = () => {
    return <Loading />;
  };

  const renderContent = () => {
    return isShowResult ? (
      <div
        onMouseOver={(e) => {
          setMouseOverResult(true);
        }}
        onMouseOut={(e) => {
          setMouseOverResult(false);
        }}
        onTouchStart={(e) => {}}
        className="cart-search-customer-result-modal py-0 mt-3 w-100"
      >
        <div
          ref={refDropdown}
          className="l-section-group scrollable border-0"
          style={{ width: width ? `${width}px` : "unset", maxHeight: "300px" }}
        >
          {searchText?.length >= 2
            ? loadingCustomer
              ? contentLoading()
              : !customers?.length
                ? contentNotFound()
                : content()
            : null}
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <div
        ref={(node) => (this_ref.current = node)}
        className="l-section border-0 p-0 position-relative l-pb-8"
      >
        {!isFocused && searchText.length === 0 ? (
          <span className="cart-search-customer-hotkey" onClick={() => {}}>
            F3
          </span>
        ) : null}
        <InputHasIcon
          autoComplete="off"
          ref={refInput}
          id={INPUT_SEARCH_CUSTOMER_ID}
          className="background-secondary"
          placeholder="Tìm khách hàng"
          prefix={<Icon name="search" />}
          value={searchText}
          onChange={(value) => handleInputChanged(value)}
          onFocus={(value, e) => {
            handleInputFocus(e);
          }}
          suffix={
            <div className="d-flex">
              {searchText?.length ? (
                <span
                  className="cursor-pointer mr-2"
                  onClick={() => {
                    setSearchText("");
                    setIsFocused(false);
                  }}
                >
                  <Icon name="close" />
                </span>
              ) : null}
              <Tooltip
                placement="left"
                showArrow={false}
                overlay={<span>Tạo khách mới</span>}
              >
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setShowModalCreateCustomer(true);
                  }}
                >
                  <Icon name="add" color="var(--contentAccent)" />
                </div>
              </Tooltip>
            </div>
          }
        />
        <div
          style={{
            position: "fixed",
            top:
              (parentPositionInput?.top + parentPositionInput?.height) /
                newZoomStandard || 0,
            left: (parentPositionInput?.left || 0) / newZoomStandard || 0,
            width: (parentPositionInput?.width || 0) / newZoomStandard,
            zIndex: 100,
          }}
        >
          {renderContent()}
        </div>
      </div>
      <ModalCreateCustomer
        show={showModalCreateCustomer}
        setShow={setShowModalCreateCustomer}
      />
    </>
  );
};
