import { HttpError } from "errors";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import useSWRImmutable from "swr/immutable";
import { useApp } from "contexts/app";

const HTTP_METHOD = {
  POST: "POST",
  PUT: "PUT",
  GET: "GET",
  DELETE: "DELETE",
};
export const ENDPOINT_GATEWAY = {
  retail: "/call/retail_api",
  commerce: "/call/com_api",
  loyalty: "/call/loy_api",
  buyxgety_api: "/call/buyxgety_api/js",
  combo_api: "/call/combo_api/js",
};

const fetcherJson = async (
  appContext,
  endpoint,
  headers,
  method,
  body = null,
  timeout = null
) => {
  let response = null;
  switch (method) {
    case HTTP_METHOD.GET:
      response = await callGet(endpoint);
      break;
    case HTTP_METHOD.POST:
      response = await callPost(endpoint, body);
      break;
    case HTTP_METHOD.PUT:
      response = await callPut(endpoint, body);
      break;
    case HTTP_METHOD.DELETE:
      response = await callDelete(endpoint);
      break;
    default:
      break;
  }
  if (!response?.ok) {
    if (response?.status === 401) appContext.setRequireLogin(true);
    HttpError.handle(response);
  }
  return await response.json();
};

function toEndpoint(gateway, endpoint, params) {
  if (!endpoint) return null;
  endpoint = endpoint?.replace(/^\/+/, "");
  endpoint = gateway + "/" + endpoint;
  let query = "";
  if (params) {
    Object.keys(params).forEach(function (element) {
      if (element && params[element] != null)
        query += "&" + element + "=" + params[element];
    });
    if (query) endpoint += "?" + query.replace(/^&/, "");
  }
  return endpoint;
}
export function useGetData(gateway, endpoint, params) {
  let timeout = 0;
  if (gateway === ENDPOINT_GATEWAY.loyalty) {
    timeout = 60000;
  } else {
    timeout = 3000;
  }
  endpoint = toEndpoint(gateway, endpoint, params);
  const app = useApp();
  const { data, error, isValidating, isLoading, mutate } = useSWRImmutable(
    app.auth.access_token ? endpoint : null,
    (url) =>
      fetcherJson(
        app,
        url,
        {
          access_token: app.auth.access_token,
        },
        HTTP_METHOD.GET,
        null,
        timeout
      )
  );
  return { data, error, isValidating, isLoading, mutate };
}
export function useGetDataSWR(gateway, endpoint, params, options = null) {
  let timeout = 0;
  if (gateway === ENDPOINT_GATEWAY.loyalty) {
    timeout = 60000;
  } else {
    timeout = 3000;
  }
  endpoint = toEndpoint(gateway, endpoint, params);
  const app = useApp();
  const { data, error, isValidating, isLoading, mutate } = useSWR(
    app.auth.authenticated && endpoint ? endpoint : null,
    (url) =>
      fetcherJson(
        app,
        url,
        {
          access_token: app.auth.access_token,
        },
        HTTP_METHOD.GET,
        null,
        timeout
      ),
    options
  );
  return { data, error, isValidating, isLoading, mutate };
}

export function useGetInfiniteDataSWR(
  gateway,
  endpoint,
  params,
  options = null
) {
  let timeout = 3000;
  const getKey = (pageIndex) => {
    params.page = pageIndex + 1;
    return toEndpoint(gateway, endpoint, params);
  };
  const app = useApp();
  const { data, error, isValidating, mutate, size, isLoading, setSize } =
    useSWRInfinite(
      app.auth.authenticated ? getKey : null,
      (url) =>
        fetcherJson(
          app,
          url,
          {
            access_token: app.auth.access_token,
          },
          HTTP_METHOD.GET,
          null,
          timeout
        ),
      options
    );
  return { data, error, isValidating, mutate, size, isLoading, setSize };
}

export async function usePostData(gateway, endpoint, params, body = null) {
  const app = useApp();
  endpoint = toEndpoint(gateway, endpoint, params);
  let response = await fetcherJson(
    app,
    endpoint,
    {
      access_token: app.auth.access_token,
    },
    HTTP_METHOD.POST,
    body
  );
  if (response.status === 401) app.setRequireLogin(true);
  return response;
}

export async function usePutData(gateway, endpoint, params, body = null) {
  const app = useApp();
  endpoint = toEndpoint(gateway, endpoint, params);
  let response = await fetcherJson(
    app,
    endpoint,
    {
      access_token: app.auth.access_token,
    },
    HTTP_METHOD.PUT,
    body
  );
  if (response.status === 401) app.setRequireLogin(true);
  return response;
}

export async function useDeleteData(gateway, endpoint, params) {
  const app = useApp();
  endpoint = toEndpoint(gateway, endpoint, params);
  let response = await fetcherJson(
    app,
    endpoint,
    {
      access_token: app.auth.access_token,
    },
    HTTP_METHOD.DELETE
  );
  if (response.status === 401) app.setRequireLogin(true);
  return response;
}

let env = {
  baseUrl: "/",
  layout: null,
};

export const Env = env;

export async function callGet(endpoint) {
  let setTimeout = 0;
  if (endpoint.indexOf("/loy_api/") === -1) {
    setTimeout = 60000;
  } else {
    setTimeout = 3000;
  }
  const response = await fetch(endpoint, {
    method: "get",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    setTimeout: setTimeout,
  });
  return await response;
}

export async function callPost(endpoint, bodyData) {
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    });
    return await response;
  } catch (error) {}
}

export async function callPut(endpoint, bodyData) {
  try {
    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    });
    return await response;
  } catch (error) {}
}

export async function callDelete(endpoint) {
  try {
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await response;
  } catch (error) {}
}
export async function callUploadFile(endpoint, bodyData) {
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      body: bodyData,
    });
    return await response;
  } catch (error) {}
}
export async function callUploadFileMulti(endpoint, blob) {
  let input = new FormData();
  for (var i = 0; i !== blob.length; i++) {
    input.append("files", blob[i]);
  }
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      body: input,
    });
    return await response;
  } catch (error) {}
}
