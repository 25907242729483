import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";

export default function DenyAccessModal() {
  const { setting_required, auth } = useApp();
  const intl = useIntl();
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (setting_required) setShow(true);
  }, [setting_required]);

  const body = (
    <div className="text-center">
      <div className="l-empty-state--icon">
        <Icon size={4} name="settings" color="var(--contentTertiary)" />
      </div>
      <div className="l-mt-8 font-weight-semibold">
        Chào <span className="text-primary">{auth.user?.name}</span>! Cửa hàng
        của bạn chưa được kích hoạt
      </div>
      <p className="l-mt-8">
        Vui lòng liên hệ tài khoản Chủ cửa hàng để đăng nhập kích hoạt cửa hàng
        lần đầu
      </p>
    </div>
  );
  const footer = (
    <div className="mx-auto">
      <a
        status="primary"
        size="md"
        href="/auth/logout"
        title={intl.formatMessage({
          id: "menus.Đăng xuất",
          defaultMessage: "Đăng xuất",
        })}
      ></a>
    </div>
  );

  return (
    <GlobalModal
      body={body}
      footer={footer}
      show={show}
      headerClose={false}
      useEscapeKey={false}
    />
  );
}
