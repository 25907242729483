import Icon from "component-v2/icons/icons";
import React from "react";

export const NotPermissions = () => {
  return (
    <div className="omni-layout-body full-size p-4">
      <div className="h-100 w-100 bg-white d-flex align-items-center justify-content-center" style={{ borderRadius: "10px" }}>
        <div className="text-center">
          <div
            className="background-secondary p-4 rounded-circle m-auto"
            style={{ width: "max-content" }}
          >
            <Icon name={"lock_person"} size="lg"/>
          </div>
          <p className="font-weight-semibold font-size-20 my-4">
            Bạn chưa được cấp phép sử dụng tính năng này
          </p>
          <p className="content-secondary font-size-16 my-4">
            Vui lòng liên hệ chủ cửa hàng để được phân quyền sử dụng
          </p>
        </div>
      </div>
    </div>
  );
};
