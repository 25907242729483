import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { HOTKEYS } from "models/constants";
import GlobalSimpleModal from "screens-v2/layouts/layouts/global-simple-modal";

export const ModalCreateOrderSuccessQR = ({ show, setShow, data }) => {
  const body = () => (
    <div className="p-4">
      <div className="text-center font-weight-semibold font-size-24">
        <Icon name="checkbox_circle_line" size="xl" color="var(--green600)" />
        <p className="mt-4">
          Đã tạo đơn{" "}
          <span className="content-accent">
            {data?.dataResult?.orderNumber}
          </span>
        </p>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <p className="content-secondary m-0">
          Bạn có thể tiếp tục thanh toán đơn hàng tại mục Tra Cứu Đơn Hàng
        </p>
      </div>
      <div className="w-100 d-flex justify-content-center mt-5">
        <Button
          title={"Tạo đơn mới"}
          id="order.create.success_popup.new_button"
          onClick={() => setShow(false)}
          light
          size="lg"
          badge={HOTKEYS.ESC}
          className="mr-3 font-weight-semibold font-size-16"
        ></Button>
      </div>
    </div>
  );

  return (
    <GlobalSimpleModal
      body={body()}
      show={show}
      setShow={(value) => {
        setShow(value);
      }}
    />
  );
};
