import React, { useState, useRef, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { debounce, startsWith } from "lodash";
import BarcodeReader from "react-barcode-reader";

import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import "./refund-cart.css";
import { useRefundContext } from "../index";
import { ProductService } from "services";
import { ProductCartHelperService } from "screens-v2/carts-v2/Products/product-cart-helper.service";
import { isScaleActive, setScaleActive } from "utils/ScaleUtils";
import {
  RefundCartSearchInput,
  RefundCartSearchResult,
} from "./refund-cart-search";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { useOnClickOutside } from "hooks/useOnClickOutSide";

export const RefundCartSearchBox = (props) => {
  const intl = useIntl();
  const layoutContext = useLayoutContext();
  const appContext = useApp();
  const refundContext = useRefundContext();
  const ID_FIRST_ITEM_RESULT = "search-product--item-0";
  const mode = null;
  const productCartHelper = new ProductCartHelperService(
    appContext.shop_setting
  );
  const productService = new ProductService();
  const [disabled, setDisabled] = useState(props.disabled);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState(
    productService.getDefaultProductValue()
  );
  const [searchText, setSearchText] = useState("");
  const [isShowResult, setIsShowResult] = useState(false);
  const searchProductRef = useRef(null);
  const this_ref = useRef(null);
  const [mouseOverResult, setMouseOverResult] = useState(false);

  let keys = [
    HOTKEYS.DOWN,
    HOTKEYS.UP,
    HOTKEYS.ENTER,
    HOTKEYS.F2,
    HOTKEYS.F3,
    HOTKEYS.F9,
    HOTKEYS.F10,
  ];

  useGlobalHotkeys({
    keys: keys,
    scopes: HOTKEY_SCOPES.CART,
    callback: (e) => {
      if (
        e.key === HOTKEYS.F9 &&
        refundContext?.current_shopping_cart?.lineItems?.length
      ) {
        setIsFocused(false);
        setIsShowResult(false);
        searchProductRef.current.blur();
      }

      if (e.key === HOTKEY_EVENTS.F2) {
        searchProductRef.current.focus();
      }
      if (e.key === HOTKEY_EVENTS.F3) {
        setIsShowResult(false);
      }
      if (e.key === HOTKEY_EVENTS.F10) {
        if (appContext.shop_info.scale_support) {
          refundContext.setStatusES(!refundContext.isElectronicScale);
          if (refundContext.isElectronicScale) {
            searchProductRef.current.focus();
          } else {
            searchProductRef.current.blur();
            setIsFocused(false);
            setIsShowResult(false);
          }
        }
      }

      let activeElement = document.activeElement;
      if (activeElement.id) {
        // navigate tới item đầu tiên khi đứng ở ô search
        if (
          activeElement.id === "refund_cart_search_product" &&
          e.key === HOTKEY_EVENTS.DOWN
        ) {
          let search_item = document.getElementById(ID_FIRST_ITEM_RESULT);
          if (search_item) search_item.focus();
          return;
        }

        // navigate tới ô search khi đứng ở item đầu tiên
        if (
          activeElement.id === ID_FIRST_ITEM_RESULT &&
          e.key === HOTKEY_EVENTS.UP
        ) {
          let search_item = document.getElementById(
            "refund_cart_search_product"
          );
          search_item.focus();
          return;
        }
        // di chuyển giữa các items
        if (activeElement.id?.startsWith("search-product--item-")) {
          if (e.key === HOTKEY_EVENTS.DOWN && activeElement.nextElementSibling)
            activeElement.nextElementSibling.focus();
          if (
            e.key === HOTKEY_EVENTS.UP &&
            activeElement.previousElementSibling
          )
            activeElement.previousElementSibling.focus();
          if (e.key === HOTKEY_EVENTS.ENTER) {
            activeElement.click();
          }
        }
      }
    },
  });

  useOnClickOutside(this_ref, () => {
    !mouseOverResult && setIsShowResult(false);
  });

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  const debounceSearch = useCallback(
    debounce(async (e) => {
      setIsLoading(true);
      // call api search product
      let products = await productService.searchProducts(
        appContext,
        mode,
        1,
        10,
        e
      );
      if (products) setProducts(products);
      setIsShowResult(true);
      setIsLoading(false);
      // navigate tới ô search khi đứng ở item đầu tiên
    }, 400),
    []
  );

  // search activity
  const handleInputChanged = (e) => {
    debounceSearch(e.target.value);
    setSearchText(e.target.value);
  };

  const handleInputFocus = (event) => {
    event.target.select();
    setIsFocused(true);
    if (searchText.length > 0) {
      setIsShowResult(true);
    }
  };

  const handleScanBarcode = async (barcodeScan) => {
    let isSubmitOrderWaiting = refundContext.isSubmitOrderWaiting; // refactor sau đợi update flag ngoài cart context
    let isBarcodeCustomer =
      startsWith(barcodeScan, "HL") || startsWith(barcodeScan, "hl");

    if (isSubmitOrderWaiting) {
      return layoutContext.showGlobalToast(
        "default",
        intl.formatMessage({
          id: "notifications.Có đơn hàng đang được xử lý. Vui lòng đợi!",
          defaultMessage: "Có đơn hàng đang được xử lý. Vui lòng đợi!",
        })
      );
    }

    let barcode = barcodeScan;
    let lotNo = null;
    if (appContext.shop_setting.isBarcodePatternCustom) {
      try {
        const pattern = appContext.shop_setting.barcodePattern;
        const regex = new RegExp(pattern);
        const match = regex.exec(barcodeScan);
        if (match) {
          barcode = match.groups.barcode;
          lotNo = match.groups.lotNo;
        } else {
          barcode = null;
          lotNo = null;
        }
      } catch (error) {
        console.log(error);
        barcode = null;
        lotNo = null;
      }
    }
    
    if (!barcode || !barcode.length || (appContext.shop_setting.isBarcodePatternCustom && !lotNo?.length)) {
      return;
    }

    let isProductBarcode =
      !isBarcodeCustomer && barcode.indexOf(".coupon") === -1;
    if (isProductBarcode) {
      const { records, variantQtt, indexVariant } =
        await productCartHelper.searchOnBarcode(appContext, 1, 10, barcode, lotNo);
      if (records?.length && records.length === 1) {
        handleAddToCart(
          records[0],
          records[0].actualQuantity > 0 ? records[0].actualQuantity : 1
        );
      } else if (records?.length && variantQtt === 1) {
        handleAddToCart(
          records[indexVariant],
          records[indexVariant].actualQuantity > 0
            ? records[indexVariant].actualQuantity
            : 1
        );
      } else {
        await debounceSearch(barcode);
        setSearchText(barcode);
      }
    }
  };

  const onNextPage = async (page) => {
    // call api search product
    setIsLoading(true);
    let products = await productService.searchProducts(
      appContext,
      mode,
      page,
      10,
      searchText
    );
    if (products) setProducts(products);
    setIsLoading(false);
  };

  const handleAddToCart = async (variant, quantity = 1) => {
    if (!productCartHelper.validateRuleCart(variant)) {
      layoutContext.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "notifications.Sản phẩm đã hết hàng!",
          defaultMessage: "Sản phẩm đã hết hàng!",
        })
      );
      return;
    }
    // add to do
    refundContext.addItemCurrentCart({ ...variant }, quantity);
    setIsFocused(false);
    setIsShowResult(false);
  };

  const handleKeywordClear = () => {
    setSearchText("");
  };

  return (
    <div className="w-100 refund-cart-root">
      <div ref={(node) => (this_ref.current = node)}>
        {/* Search Box */}
        <RefundCartSearchInput
          disabled={disabled}
          isFocused={isFocused}
          keyword={searchText}
          ref={searchProductRef}
          enableElectricScale={appContext.shop_info.scale_support}
          isElectricScale={refundContext.isElectronicScale}
          onChange={handleInputChanged}
          onFocus={handleInputFocus}
          onESClick={() => {
            refundContext.setStatusES(!refundContext.isElectronicScale);
          }}
          onClearSearch={handleKeywordClear}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        {/* Search result */}
        {isShowResult && searchText.length >= 2 && (
          <RefundCartSearchResult
            products={products}
            isLoading={isLoading}
            searchText={searchText}
            onNextPage={onNextPage}
            onClick={handleAddToCart}
            setMouseOverResult={setMouseOverResult}
            {...props}
          />
        )}
      </div>
      <BarcodeReader onScan={handleScanBarcode} minLength={3} />
    </div>
  );
};
