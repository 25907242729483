import PropTypes from "prop-types";

/* LayoutCard */
const layout_body_type = ["primary", "secondary"];
export function LayoutCard({ children, id, type, className }) {
  return (
    <div
      id={id}
      className={`omni-layout-card card-${type}${
        className ? " " + className : ""
      }`}
    >
      {children ? children : null}
    </div>
  );
}
LayoutCard.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(layout_body_type),
  className: PropTypes.string,
};
LayoutCard.defaultProps = {
  type: "primary",
};

/* LayoutCardHeader */
export function LayoutCardHeader({
  children,
  className,
  title,
  hasLine,
  rightContent,
}) {
  return (
    <div
      className={`omni-layout-card--header${hasLine ? " line-bottom" : ""}${
        className ? " " + className : ""
      }`}
    >
      {children ? (
        children
      ) : rightContent ? (
        <div className="row align-items-center">
          <div className="col">
            <span className="header-title">{title ? title : ""}</span>
          </div>
          <div className="col-auto">{rightContent}</div>
        </div>
      ) : (
        <span className="header-title">{title ? title : ""}</span>
      )}
    </div>
  );
}
LayoutCardHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  hasLine: PropTypes.string,
  rightContent: PropTypes.any,
};

/* LayoutCardSection */
export function LayoutCardSection({ children, id, className, onScroll }) {
  return (
    <div
      id={id}
      className={`omni-layout-card--section${className ? " " + className : ""}`}
      onScroll={onScroll}
    >
      {children ? children : null}
    </div>
  );
}
LayoutCardSection.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

/* LayoutCardSectionSection */
export function LayoutCardSectionSection({ children, className, title }) {
  return (
    <div
      className={`omni-layout-card--section-section${
        className ? " " + className : ""
      }`}
    >
      {title ? <p className="header-title">{title}</p> : null}
      {children}
    </div>
  );
}
LayoutCardSectionSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
};

/* LayoutCardItem */
export function LayoutCardItem({
  children,
  id,
  className,
  title,
  nogap = false,
  onClick,
}) {
  return (
    <div
      className={`omni-layout-card--item${nogap ? " no-gap" : ""}${
        onClick ? " clickable" : ""
      }${className ? " " + className : ""}`}
      onClick={onClick ? () => onClick(id) : null}
    >
      <div className="omni-layout-card--item-inner">
        {children ? (
          children
        ) : title ? (
          <span className="header-title">{title}</span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
LayoutCardItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

/* LayoutCardItemStackWrapper */
export function LayoutCardItemStackWrapper({ children, className, nowrap }) {
  return (
    <div
      className={`omni-layout-card--item-stack-cover${
        nowrap ? " stack-nowrap" : ""
      }${className ? " " + className : ""}`}
    >
      {children}
    </div>
  );
}
LayoutCardItemStackWrapper.propTypes = {
  className: PropTypes.string,
  nowrap: PropTypes.bool,
};

/* LayoutCardItemStack */
export function LayoutCardItemStack({ children, className, fill, nowrap }) {
  return (
    <div
      className={`omni-layout-card--item-stack${fill ? " fill" : ""}${
        nowrap ? " stack-nowrap" : ""
      }${className ? " " + className : ""}`}
    >
      {children}
    </div>
  );
}
LayoutCardItemStack.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.bool,
};
