import { Assets } from "assets";
import { HOTKEYS } from "models/constants";

export function CartPageKeyboardGuides(scale_support) {
  return {
    NEW_TAB: {
      key: HOTKEYS.F1,
      name: "Mở tab bán hàng mới",
      isHidden: false
    },
    SEARCH_PRODUCT: {
      key: HOTKEYS.F2,
      name: "Tìm sản phẩm",
      isHidden: false
    },
    SEARCH_CUSTOMER: {
      key: HOTKEYS.F3,
      name: "Tìm khách hàng",
      isHidden: false
    },
    SAVE_DONE: {
      key: HOTKEYS.F8,
      name: "Lưu / Xong",
      isHidden: false
    },
    PAYMENT: {
      key: HOTKEYS.F9,
      name: "Thanh toán hóa đơn",
      isHidden: false
    },
    SWITCH_DIGITAL_SCALE: {
      key: HOTKEYS.F10,
      name: "Bật / Tắt cân điện tử",
      isHidden: !scale_support
    },
    CANCEL_BACK: {
      key: HOTKEYS.ESC,
      name: "Hủy / Quay lại",
      isHidden: false
    },
    FOCUS_ON_CART_FIRST_ITEM: {
      key: HOTKEYS.HOME,
      name: "Chuyển đến ô số lượng",
      isHidden: false
    },
    FOCUS_ON_CART_UP: {
      key: HOTKEYS.UP,
      name: "Chuyển lên dòng",
      icon: Assets.icons_svg.keyboard_up
    },
    FOCUS_ON_CART_DOWN: {
      key: HOTKEYS.DOWN,
      name: "Chuyển xuống dòng",
      icon: Assets.icons_svg.keyboard_down,
      isHidden: false
    },
    INCREASE_QUANTITY: {
      key: HOTKEYS.RIGHT,
      name: "Tăng số lượng",
      icon: Assets.icons_svg.keyboard_right,
      isHidden: false
    },
    DECREASE_QUANTITY: {
      key: HOTKEYS.LEFT,
      name: "Giảm số lượng",
      icon: Assets.icons_svg.keyboard_left,
      isHidden: false
    },
  }
}