import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useDailyReportSummaryData } from "hooks/useReportData";
import { getZTime } from "infrastructures/datetime-helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import {
  LayoutCard,
  LayoutCardHeader,
  LayoutCardItem,
  LayoutCardItemStack,
  LayoutCardItemStackWrapper,
} from "screens-v2/layouts/layouts/omni-layout-card";
import DailyReportResult from "./components/daily-report-results";
import DailyReportSummaries from "./components/daily-report-summaries";
import DailyReportSummaryCards from "./components/daily-report-summary-cards";
import DailyReportHeader from "./daily-report-header";
import "./utils/index.css";

export default function DailyReport() {
  const appContext = useApp();
  const layout = useLayoutContext();
  const intl = useIntl();
  const currentLocation = appContext.current_location || {};
  const [user, setUser] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
  });
  // swr call summary data
  const { data: summaryData, isValidating: loading } =
    useDailyReportSummaryData(
      getZTime(dateRange?.startDate),
      getZTime(dateRange?.endDate),
      currentLocation?.id,
      user?.id,
    );
  // summary list
  const [summaries, setSummaries] = useState(null);
  // selected summary
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    layout.setPageTitle(
      intl.formatMessage({
        id: "reports.daily-report.Báo cáo cuối ngày",
        defaultMessage: "Báo cáo cuối ngày",
      }),
    );
    layout.setBreadcrumb([
      {
        title: intl.formatMessage({
          id: "reports.daily-report.Báo cáo cuối ngày",
          defaultMessage: "Báo cáo cuối ngày",
        }),
        link: null,
      },
    ]);
    return () => {
      layout.setPageTitle("");
      layout.setBreadcrumb();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);
  useEffect(() => {
    setUser({ id: 0, name: "Tất cả nhân viên" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let summaries = [
      {
        accountId: 0,
        accountName: "Tất cả",
        totalExpenses: 0,
        totalReceived: 0,
        totalRemain: 0,
      },
    ];
    if (summaryData && summaryData.data) {
      summaryData.data.forEach((d) => {
        summaries[0].totalExpenses += d.totalExpenses;
        summaries[0].totalReceived += d.totalReceived;

        d.totalRemain = d.totalExpenses + d.totalReceived;
        summaries[0].totalRemain += d.totalRemain;
        summaries.push(d);
      });
    }
    setSummaries(summaries);
    setSummary(summaries[0]);
  }, [summaryData]);
  return (
    <LayoutBody
      size="ne"
      className="main-background pd-16 daily-report border-top"
    >
      <LayoutCardHeader
        title={intl.formatMessage({
          id: "reports.daily-report.Báo cáo cuối ngày",
          defaultMessage: "Báo cáo cuối ngày",
        })}
      />
      <LayoutCard>
        <LayoutCardItem>
          <DailyReportHeader
            user={user}
            setUser={setUser}
            dateRange={dateRange}
            setDateRange={({ startDate, endDate }) => {
              setDateRange({ startDate, endDate });
            }}
            loading={loading}
          />
        </LayoutCardItem>
      </LayoutCard>
      <LayoutCard>
        <LayoutCardItem>
          <DailyReportSummaryCards summary={summary} />
        </LayoutCardItem>
      </LayoutCard>
      <LayoutCardItem className="no-padding-x">
        <LayoutCardItemStackWrapper nowrap className="align-items-start">
          <LayoutCardItemStack className="daily-report-summaries mt-4">
            <LayoutCard className="ghosty">
              <LayoutCardHeader className="text-center">
                <span className="header-title font-size-16">
                  {intl.formatMessage({
                    id: "reports.daily-report.Chọn phương thức thanh toán",
                    defaultMessage: "Chọn phương thức thanh toán",
                  })}
                </span>
              </LayoutCardHeader>
              <DailyReportSummaries
                loading={loading}
                summaries={summaries}
                selectedItem={summary}
                updateSelectedItem={setSummary}
              />
            </LayoutCard>
          </LayoutCardItemStack>
          <LayoutCardItemStack fill className="mt-0">
            <LayoutCard>
              <DailyReportResult
                dateRange={dateRange}
                user={user}
                location={currentLocation}
                summary={summary}
              />
            </LayoutCard>
          </LayoutCardItemStack>
        </LayoutCardItemStackWrapper>
      </LayoutCardItem>
    </LayoutBody>
  );
}
