// import "./index.css"
import { FormattedMessage, useIntl } from "react-intl";
import { useRefundContext } from "screens-v2/refunds-v2";
import CurrencyShow from "component-v2/currency-show";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";

export const ModalConfirmPartialPayment = ({
  sendOrder,
  show,
  setShow,
  totalMoneyPaidByCustomer,
}) => {
  const intl = useIntl();
  const { current_shopping_cart } = useRefundContext();

  const amountPaid = totalMoneyPaidByCustomer;
  const amountTotal = current_shopping_cart?.summaries?.total_payment;
  const body = () => (
    <div className="partial-payment-content">
      <FormattedMessage
        id="carts.orders.Ghi nhận đã thanh toán {a} cho đơn hàng {b}.{c} Nhấn <d>XÁC NHẬN</d> để tiếp tục."
        defaultMessage="Ghi nhận đã thanh toán {a} cho đơn hàng {b}.{c} Nhấn <d>XÁC NHẬN</d> để tiếp tục."
        values={{
          a: (
            <span>
              <CurrencyShow value={amountPaid} />
            </span>
          ),
          b: (
            <span>
              <CurrencyShow value={amountTotal} />
            </span>
          ),
          d: (content) => <span className="text-blue">{content}</span>,
          c: <br />,
        }}
      />
    </div>
  );

  return (
    <GlobalModal
      headerTitle={intl.formatMessage({
        id: "carts.orders.Thanh toán chưa hoàn tất",
        defaultMessage: "Thanh toán chưa hoàn tất",
      })}
      body={body()}
      show={show}
      setShow={setShow}
      onCancel={() => setShow(false)}
      onConfirm={() => {
        sendOrder();
        setShow(false);
      }}
    />
  );
};
