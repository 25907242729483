import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { useCartContext } from "..";
import { PopupVatInfo } from "../../../component-v2/VAT/PopupVatInfo";
import { concatString } from "../utils";
import Tooltip from "rc-tooltip";
import _ from "lodash";
import { useLayoutContext } from "contexts/Layout/layout-context";

export const defaultTaxData = {
  billingCompany: "",
  billingTaxCode: "",
  billingEmail: "",
  billingAddress: "",
  address: null,
  eInvoiceSetting: null,
  eInvoice: null,
  eInvoiceInfo: {
    address: "",
    company: false,
    name: "",
    taxCode: "",
    email: "",
    save: false,
    submit: false,
  },
};

export const VAT = ({ isVATInfo }) => {
  const { showGlobalToast } = useLayoutContext();
  const {
    openPopupVat,
    setOpenPopupVat,
    openRemoveVat,
    setOpenRemoveVat,
    taxRequired,
    setTaxRequired,
    eInvoice,
    setEInvoice,
  } = useVATContext();
  const { shop_info: shopInfo } = useApp();
  const { current_shopping_cart, updateCurrentSession } = useCartContext();
  const [taxData, setTaxData] = useState({ ...defaultTaxData, eInvoice });
  const handleSavePopupVat = (data) => {
    setTaxRequired(true);
    if (
      !_.isEqual(
        current_shopping_cart?.customer?.eInvoiceInfo,
        data?.eInvoiceInfo,
      )
    ) {
      updateCurrentSession({
        taxData: {
          ...data,
          eInvoiceInfo: {
            ...data?.eInvoiceInfo,
            save: data?.eInvoiceInfo?.save || false,
            submit: true,
          },
          isEdited: true,
        },
        eInvoiceInfo: {
          ...data?.eInvoiceInfo,
          save: data?.eInvoiceInfo?.save || false,
          submit: true,
        },
      });
    } else {
      updateCurrentSession({
        taxData: {
          ...data,
          eInvoiceInfo: {
            ...data?.eInvoiceInfo,
            save: data?.eInvoiceInfo?.save || false,
            submit: true,
          },
          isEdited: true,
        },
        eInvoiceInfo: {
          ...data?.eInvoiceInfo,
          save: data?.eInvoiceInfo?.save || false,
          submit: true,
        },
      });
    }
    setOpenPopupVat(false);
  };

  useEffect(() => {
    if (
      !current_shopping_cart?.customer?.eInvoiceInfo?.submit &&
      !!taxData?.eInvoiceInfo?.name
    ) {
      setTaxData({ taxData: { ...defaultTaxData, eInvoice } });
      setTaxRequired(false);
    }
  }, [current_shopping_cart?.customer]);
  useEffect(() => {
    if (current_shopping_cart?.taxData) {
      setTaxData({ ...current_shopping_cart?.taxData });
      if (current_shopping_cart?.taxData?.eInvoiceInfo?.submit) {
        setTaxRequired(true);
      } else {
        setTaxRequired(false);
      }
    }
  }, [current_shopping_cart?.taxData]);

  const removeVat = () => {
    setTaxRequired(false);
    setOpenRemoveVat(false);
    updateCurrentSession({
      taxData: {
        ...defaultTaxData,
        eInvoiceInfo: {
          ...current_shopping_cart?.taxData?.eInvoiceInfo,
          submit: false,
        },
        eInvoice,
      },
    });
  };

  const popupRemoveVat = () => {
    return (
      <GlobalModal
        size={"md"}
        headerTitle={"Hủy yêu cầu xuất hóa đơn VAT"}
        isTitleCenter
        body={<span>Thông tin hóa đơn đã nhập sẽ không được lưu.</span>}
        confirmButtonClassName={"l-button l-button-danger l-button-md"}
        onCancel={() => setOpenRemoveVat(false)}
        onConfirm={removeVat}
        confirmTitle={"Gỡ bỏ"}
        show={openRemoveVat}
        setShow={(value) => setOpenRemoveVat(value)}
      />
    );
  };

  const renderPopupVAT = () => {
    return (
      <PopupVatInfo
        autoFillEmail={{
          title: "Sử dụng email của khách",
          value: current_shopping_cart?.customer?.email || "",
        }}
        autoFillCompanyAddress={{
          title: "Sử dụng địa chỉ giao hàng",
          value: {
            billingCountry:
              current_shopping_cart?.customer?.customerAddress?.[0]?.countryId,
            billingCountryName:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.countryName,
            billingProvince:
              current_shopping_cart?.customer?.customerAddress?.[0]?.provinceId,
            billingProvinceName:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.provinceName,
            billingDistrictName:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.districtName,
            billingDistrict:
              current_shopping_cart?.customer?.customerAddress?.[0]?.districtId,
            billingWard:
              current_shopping_cart?.customer?.customerAddress?.[0]?.wardId,
            billingWardName:
              current_shopping_cart?.customer?.customerAddress?.[0]?.wardName,
            city: current_shopping_cart?.customer?.customerAddress?.[0]?.city,
            zipCode:
              current_shopping_cart?.customer?.customerAddress?.[0]?.zipCode,
            countryCode:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.countryCode,
            provinceCode:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.provinceCode,
            districtCode:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.districtCode,
            wardCode:
              current_shopping_cart?.customer?.customerAddress?.[0]?.wardCode,
            billingAddress:
              current_shopping_cart?.customer?.customerAddress?.[0]?.address1,
            address:
              current_shopping_cart?.customer?.customerAddress?.[0]?.address1,
          },
        }}
        // context={this.context}
        formData={taxData}
        onSave={(data) => handleSavePopupVat(data)}
        setOpen={(value) => setOpenPopupVat(value)}
        isOpen={openPopupVat}
        onCancel={() => setOpenPopupVat(false)}
      />
    );
  };
  if (!shopInfo.taxable || !current_shopping_cart) return null;
  if (!taxRequired && !isVATInfo)
    return (
      <Fragment>
        <span
          className="cursor-pointer"
          onClick={() => {
            setOpenPopupVat((prev) => !prev);
            if (current_shopping_cart?.customer?.eInvoiceInfo?.name) {
              setTaxData({
                ...current_shopping_cart?.taxData,
                eInvoiceInfo: {
                  ...current_shopping_cart?.customer?.eInvoiceInfo,
                },
              });
            }
          }}
        >
          <Badge className="border-0" light status={"info"} size="L" rounded>
            <span className="d-inline-flex align-items-center">
              <Icon
                name="add"
                color="var(--contentAccent)"
                className="l-mr-4"
              />
              <span>Hóa đơn VAT</span>
            </span>
          </Badge>
        </span>
        {renderPopupVAT()}
      </Fragment>
    );
  if (
    taxRequired &&
    isVATInfo &&
    !!current_shopping_cart?.taxData?.eInvoiceInfo?.submit
  )
    return (
      <div className="flex-auto">
        <div className="d-flex justify-content-between align-items-center l-pt-8">
          <p
            className="flex-auto content-accent cursor-pointer l-mr-16"
            onClick={() => setOpenPopupVat((prev) => !prev.openPopupVat)}
          >
            Hóa đơn VAT
          </p>
          <div className="l-mr-4 min-w-0 ml-auto">
            {current_shopping_cart.taxData.eInvoiceInfo?.name ||
            current_shopping_cart.taxData.billingCompany ? (
              <Tooltip
                showArrow={false}
                placement="bottomRight"
                overlay={
                  <div>
                    <p className="content-on-color-primary font-size-12 lh-18 l-mb-4">
                      {current_shopping_cart.taxData.eInvoiceInfo?.name ||
                        current_shopping_cart.taxData.billingCompany}
                    </p>
                    <ul
                      className="font-size-12 lh-18 content-tertiary m-0"
                      style={{ paddingInlineStart: "3rem" }}
                    >
                      {(current_shopping_cart.taxData.eInvoiceInfo?.taxCode ||
                        current_shopping_cart.taxData.billingTaxCode) && (
                        <li>
                          {current_shopping_cart.taxData.eInvoiceInfo
                            ?.taxCode ||
                            current_shopping_cart.taxData.billingTaxCode}
                        </li>
                      )}
                      {(current_shopping_cart.taxData.eInvoiceInfo?.email ||
                        current_shopping_cart.taxData.billingEmail) && (
                        <li>
                          {current_shopping_cart.taxData.eInvoiceInfo?.email ||
                            current_shopping_cart.taxData.billingEmail}
                        </li>
                      )}
                      {(current_shopping_cart.taxData.eInvoiceInfo?.address ||
                        current_shopping_cart.taxData.address) && (
                        <li>
                          {current_shopping_cart.taxData.eInvoiceInfo
                            ?.address ||
                            concatString({
                              value: [
                                current_shopping_cart.taxData.address
                                  .billingAddress,
                                current_shopping_cart.taxData.address
                                  .billingWardName,
                                current_shopping_cart.taxData.address
                                  .billingDistrictName,
                                current_shopping_cart.taxData.address
                                  .billingProvinceName,
                                current_shopping_cart.taxData.address
                                  .billingCountryName,
                              ],
                              separator: ", ",
                            })}
                        </li>
                      )}
                    </ul>
                  </div>
                }
              >
                <p className="text-truncate text-right">
                  {current_shopping_cart.taxData.eInvoiceInfo?.name ||
                    current_shopping_cart.taxData.billingCompany}
                </p>
              </Tooltip>
            ) : null}
          </div>
          <div
            className="flex-auto cursor-pointer btn-close l-ml-8"
            onClick={() => setOpenRemoveVat(true)}
            style={{ height: "max-content", padding: "0.2rem" }}
          >
            <Icon name="close" />
          </div>
        </div>
        {renderPopupVAT()}
        {popupRemoveVat()}
      </div>
    );
  return null;
};

export const VATContext = createContext({
  openPopupVat: false,
  setOpenPopupVat: () => {},
  openRemoveVat: false,
  setOpenRemoveVat: () => {},
  taxRequired: false,
  setTaxRequired: () => {},
  eInvoice: null,
  setEInvoice: () => {},
});

export const useVATContext = () => {
  const context = useContext(VATContext);
  return context;
};
