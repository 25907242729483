import React, { useEffect, useState } from "react";
import { Popover } from "@haravan/react-components";
import { FormattedMessage, useIntl } from "react-intl";
import NumberShow from "component-v2/number-show";
import {
  DateTimeShowType,
  toFormattedDate,
} from "infrastructures/format-helpers";
import Button from "component-v2/buttons";
import { InputDecimal } from "components/Input";
import { useLayoutContext } from "contexts/Layout/layout-context";

const LotDatePopover = (props) => {
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const {
    children,
    data,
    onUpdate = () => {},
    isRefund = false,
    ...rest
  } = props;
  const [value, setValue] = useState(data.quantity);

  useEffect(() => {
    if (isOpen) setValue(data.quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOk = () => {
    if (value > 0) {
      onUpdate({ ...data, quantity: value });
      setIsOpen(false);
    } else {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: "notifications.Số lượng phải lớn hơn 0!",
          defaultMessage: "Số lượng phải lớn hơn 0!",
        }),
      );
    }
  };

  const content = (
    <div className="popover-lotdate-content-wrapper p-4">
      <div className="d-flex justify-content-between align-items-start">
        <p className="no-wrap mr-3 pt-1">
          <FormattedMessage
            id="carts.orders.Lô {a} hạn sử dụng đến {b}"
            defaultMessage="Lô {a} hạn sử dụng đến {b}"
            values={{
              a: <b>{data.lotNo}</b>,
              b: (
                <span>
                  {toFormattedDate(data.lotExpireDate, DateTimeShowType.date)}
                </span>
              ),
            }}
          />
        </p>
        <div className="text-right">
          <InputDecimal
            className="hrv-next-input text-right"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            min={0}
            max={isRefund ? data.maxQty : 99999}
          />
          {isRefund && (
            <p className="text-secondary small">
              /{<NumberShow value={data.maxQty} />}
            </p>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center mt-4">
        <Button
          status="default"
          onClick={() => setIsOpen(false)}
          light
          className="btn btn-cancel mr-2"
        >
          <FormattedMessage id="orders.searches.Hủy" defaultMessage="Hủy" />
        </Button>
        <Button status="primary" onClick={handleOk} className="btn-save">
          <FormattedMessage id="carts.orders.Lưu" defaultMessage="Lưu" />
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      onVisibleChange={(visible) => setIsOpen(visible)}
      placement="bottomLeft"
      visible={isOpen}
      content={content}
      {...rest}
    >
      {children}
    </Popover>
  );
};

export default LotDatePopover;
