import { PINNED_PRICING_CACHE_KEY, useApp } from "contexts/app";
import { useEffect } from "react";
import { useState } from "react";
import { pricingDefault } from "../cart-helpers.service";
import { useCartContext } from "..";
import { SelectionCustom } from "./components/SelectionCustom";
import Icon from "component-v2/icons/icons";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { Fea_PinPricingList } from "configs";
import { ShopService } from "services";

export const PricingList = () => {
  const appContext = useApp();
  const pinnedPricingLists = JSON.parse(
    localStorage.getItem(PINNED_PRICING_CACHE_KEY) || "[]",
  );
  const {
    current_shopping_cart,
    updateCurrentSession,
    setPricingList,
    pricingList,
    pinnedPricingList,
    setPinnedPricingList,
  } = useCartContext();
  const [showPinModal, setShowPinModal] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  useEffect(() => {
    getPricingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_shopping_cart?.customer?.id]);
  useEffect(() => {
    if (
      current_shopping_cart?.pricingApplied?.id &&
      current_shopping_cart?.pricingApplied?.id === pinnedPricingList?.id
    ) {
      setIsPinned(true);
    } else {
      setIsPinned(false);
    }
  }, [current_shopping_cart?.pricingApplied?.id, pinnedPricingList]);

  const getPricingList = async () => {
    if (current_shopping_cart?.customer?.id) {
      const response = await ShopService.getPricingList(
        appContext.callAPI,
        appContext.current_location.id,
        current_shopping_cart?.customer?.id,
      );
      const data = response ?? [];
      setPricingList([pricingDefault, ...data]);
    } else {
      setPricingList(appContext.defaultPricingList);
    }
  };
  const body = () => {
    return (
      <div>
        {
          <p>
            {isPinned
              ? "Bạn muốn gỡ cố định (ghim) bảng giá này?"
              : "Bạn muốn cố định (ghim) bảng giá này cho các đơn tiếp theo?"}
          </p>
        }
      </div>
    );
  };
  const handlePinPricingList = (shouldPin) => {
    const lists = [...(!!pinnedPricingLists?.length ? pinnedPricingLists : [])];
    const pinnedIndex = pinnedPricingLists?.findIndex(
      (l) =>
        l.userId === appContext?.auth?.user?.id &&
        l.locationId === appContext?.current_location?.id,
    );
    const pinned = {
      id: current_shopping_cart?.pricingApplied?.id,
      userId: appContext?.auth?.user?.id,
      locationId: appContext?.current_location?.id,
    };
    if (pinnedIndex >= 0) {
      if (shouldPin) {
        lists[pinnedIndex] = pinned;
      } else {
        lists.splice(pinnedIndex, 1);
      }
    } else if (shouldPin) {
      lists.push(pinned);
    }
    localStorage.setItem(PINNED_PRICING_CACHE_KEY, JSON.stringify(lists));
    setPinnedPricingList(shouldPin ? pinned : null);
    setShowPinModal(false);
  };
  // <div className="pricing-list-selection-wrapper">
  return (
    <div className="l-pb-8">
      <div className="l-section--body position-relative pricing-list-select-wrapper">
        <SelectionCustom
          value={current_shopping_cart?.pricingApplied?.id}
          options={pricingList}
          idField="id"
          valueField="name"
          onChange={(value) => {
            updateCurrentSession({
              pricingApplied: value
                ? pricingList.find((p) => p.id === value)
                : pricingList[0],
              itemsOriginal: current_shopping_cart?.itemsOriginal,
            });
          }}
          prefix={
            <label className="align-self-center label-in-selection">
              Bảng giá:{" "}
            </label>
          }
          placeholder="Chọn giá trị"
          // style={{ maxWidth: "280px" }}
        >
          {pricingList?.map((price) => {
            return (
              <SelectionCustom.Option key={price.id} value={price.id}>
                <div className="d-flex align-items-center">{price.name}</div>
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
        {Fea_PinPricingList(appContext?.auth?.user?.orgid) &&
          current_shopping_cart?.pricingApplied?.id && (
            <div
              className="position-absolute pin-button cursor-pointer"
              onClick={() => setShowPinModal(true)}
            >
              <Icon
                className="icon"
                name={
                  current_shopping_cart?.pricingApplied?.id ===
                  pinnedPricingList?.id
                    ? "pin_filled"
                    : "pin"
                }
                size="sm"
              />
            </div>
          )}
      </div>
      <GlobalModal
        headerTitle={isPinned ? "Gỡ ghim bảng giá" : "Ghim bảng giá"}
        body={body()}
        show={showPinModal}
        setShow={setShowPinModal}
        onCancel={() => {
          setShowPinModal(false);
        }}
        confirmTitle={isPinned ? "Gỡ Ghim" : "Ghim"}
        onConfirm={() => handlePinPricingList(!isPinned)}
      />
    </div>
  );
};
