import { useEffect, useRef, useState } from "react";
import { useRefundContext } from "screens-v2/refunds-v2";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { InputNumber } from "./InputNumber";
import { Input, InputRadio } from "@haravan/react-components";
import { toFormattedCurrency } from "infrastructures/format-helpers";

const MAX_SHIPPING_FEE = 50000000000;

export const ModalEditShippingFee = ({ show, setShow }) => {
  const [shippingFee, setShippingFee] = useState(0);
  const { updateCurrentSession, current_shopping_cart } = useRefundContext();
  const cartRequireShipping = current_shopping_cart?.lineItems?.some(
    (line) => line.requiresShipping,
  );
  const [shippingMethods, setShippingMethods] = useState([]);
  const [shippingMethodName, setShippingMethodName] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(0);
  const refShippingFee = useRef(null);
  useEffect(() => {
    if (current_shopping_cart?.shippingMethodName) {
      setShippingMethodName(
        !current_shopping_cart?.shippingMethodId
          ? current_shopping_cart.shippingMethodName
          : "",
      );
    }
    setSelectedMethod(current_shopping_cart?.shippingMethodId);
  }, [
    current_shopping_cart?.shippingMethodId,
    current_shopping_cart?.shippingMethodName,
  ]);

  useEffect(() => {
    setShippingFee(current_shopping_cart?.summaries?.shipping_fee || 0);
  }, [current_shopping_cart?.summaries?.shipping_fee]);

  useEffect(() => {
    if (current_shopping_cart?.shippingMethods) {
      setShippingMethods(
        current_shopping_cart.shippingMethods?.filter((m) => m.id),
      );
    }
  }, [current_shopping_cart?.shippingMethods]);
  const confirmShippingMethod = () => {
    setShow(false);
    updateCurrentSession({
      summaries: {
        ...current_shopping_cart.summaries,
        shipping_fee: shippingFee,
      },
      shippingMethodName: shippingMethodName,
      shippingMethodId: selectedMethod,
    });
  };
  const onCancel = () => {
    setShow(false);
    setShippingFee(current_shopping_cart.shipping || 0);
  };
  useEffect(() => {
    if (selectedMethod === 0) {
      refShippingFee?.current?.focus();
    }
  }, [selectedMethod]);

  const onSelectCustomMethod = () => {
    setSelectedMethod(0);
  };
  const body = () => {
    return (
      <div className="container p-0">
        {shippingMethods?.map((method) => {
          return (
            <div className="mb-4 d-flex justify-content-between">
              <InputRadio
                name="selectShippingMethod"
                checked={selectedMethod === method.id}
                onChange={() => setSelectedMethod(method.id)}
              >
                <span onClick={() => setSelectedMethod(method.id)}>
                  {method.name}
                </span>
              </InputRadio>
              <span>
                {method.price > 0
                  ? toFormattedCurrency(method.price)
                  : "Miễn phí"}
              </span>
            </div>
          );
        })}
        {current_shopping_cart.isShipping &&
          cartRequireShipping &&
          !shippingMethods?.length && (
            <div className="mb-4">
              <InputRadio name="selectShippingMethod" checked={false} disabled>
                <span>Chưa chọn địa chỉ giao hàng (giao hàng sau)</span>
              </InputRadio>
            </div>
          )}
        <div className="mb-4">
          <InputRadio
            name="selectShippingMethod"
            checked={!selectedMethod}
            onChange={() => onSelectCustomMethod()}
          >
            <span onClick={() => onSelectCustomMethod()}>Tùy chỉnh</span>
          </InputRadio>
        </div>
        <div className="d-flex row">
          <div className="col-sm-7">
            <Input
              placeholder={
                current_shopping_cart.isShipping
                  ? "Giao hàng sau"
                  : "Tại cửa hàng"
              }
              value={shippingMethodName}
              onChange={(value) => {
                setShippingMethodName(value);
              }}
              onFocus={() => setSelectedMethod(0)}
              disabled={!!selectedMethod}
            />
          </div>
          <div className="col-sm-5">
            <InputNumber
              ref={refShippingFee}
              max={MAX_SHIPPING_FEE}
              currency
              value={shippingFee}
              onChange={(value) => setShippingFee(value)}
              disabled={!!selectedMethod}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      headerTitle={"Chọn phí vận chuyển"}
      isTitleCenter
      body={body()}
      show={show}
      setShow={setShow}
      onCancel={onCancel}
      confirmTitle={"Thêm phí vận chuyển"}
      onConfirm={() => confirmShippingMethod()}
    />
  );
};
