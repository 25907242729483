import { getLotsData } from "api/order-repository";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useShift } from "contexts/shift/shift-context";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { getDecimalValue } from "utils/Commons";

const sumTotalQtyLot = (lots) =>
  +(lots || [])
    .map((i) => i?.quantity ?? 0)
    .reduce((prev, curr) => prev + curr, 0);

const carrierModel = {
  id: 0,
  name: "",
  packageId: 1390170,
  packageName: "Khác",
  isActived: true,
  isShowPackageDimension: true,
  isShowPaymentMethod: false,
  isShowPaidBy: false,
  packageHeight: 0,
  packageLength: 0,
  packageWidth: 0,
  isShowCouponCode: false,
  carrierDiscountCode: "",
  isViewBefore: false,
  isShowViewBefore: true,
  isShownAllowTest: true,
  allowTest: false,
  isViewSenderInfo: false,
  transportType: 0,
  hasInsurance: false,
  paidByReceiver: false,
  isPickupTime: false,
  isPickupDateTime: false,
  codFee: 0,
  fee: 0,
  isInsurance: false,
  insurancePrice: 0,
};

export default function OrderShipmentConfirmModal({
  orderId,
  onSucceeded,
  order,
}) {
  const layout = useLayoutContext();
  const intl = useIntl();
  const { checkShiftReady } = useShift();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_order, setOrder] = useState(order);
  const appContext = useApp();

  useEffect(() => {
    setOrder(order);
  }, [order]);

  async function confirmFulfilled() {
    setLoading(true);
    const currentLocation = appContext.current_location;

    let orderProducts = cloneDeep(_order.orderProducts);

    const isInValidQuantity = (orderProducts ?? []).some((product) => {
      return product?.lotSupport
        ? sumTotalQtyLot(product.lots || []) !== product.quantity
        : false;
    });

    if (isInValidQuantity) {
      layout.showGlobalToast(
        "error",
        "Số lượng của lô phải bằng số lượng cần giao hàng",
      );
      setLoading(false);
      return;
    }

    orderProducts = orderProducts?.filter(
      (p) => p.quantityNotFulfilled - p.restockQuantity > 0,
    );
    orderProducts = Array.isArray(orderProducts)
      ? orderProducts.map((product) => {
          let clone = { ...product };
          if (clone?.lotSupport)
            clone.lots = (product.lots || [])
              .filter((lot) => lot.quantity !== 0)
              .map((l) => ({
                lotExpireDate: l.lotExpireDate,
                lotNo: l.lotNo,
                quantity: l.quantity,
              }));
          return clone;
        })
      : orderProducts;

    let request = {
      carrierShippingService: carrierModel,
      order: {
        ...order,
        orderProducts: null,
        gHNPaymentType: 1,
        gHN2018PaymentType: 1,
        viettelPaymentType: 1,
        shipchungPaymentType: 1,
        proShipPaymentType: 1,
        vNPostPaymentType: 1,
        vNPostPlusPaymentType: 1,
        flexShipPaymentType: 1,
        gHTKPaymentType: 1,
        ninjavanPaymentType: 1,
        locationId: currentLocation.id,
        assignedLocationId: currentLocation.id,
        isCallNewCreateShipping: true,
        totalWeight: order.totalWeight || 0,
      },
      isSentEmail: true,
      listDetail: orderProducts,
    };

    const response = await appContext.callAPI(
      "post",
      `/call/com_api/orders/${orderId}/fulfillment_async`,
      request,
    );
    if (response.data) {
      setShow(false);
      if (onSucceeded) onSucceeded();
    } else {
      layout.showGlobalToast(
        "error",
        response?.errors[0] || "Xác nhận giao hàng thất bại!",
      );
    }
    setLoading(false);
  }

  function fillLots(lotsData, quantity) {
    let mappedData = lotsData.reduce(
      (obj, lot, index) => {
        if (obj.quantityCanAdd === 0) return obj;
        let _lot = { ...lot };
        if (
          obj.quantityCanAdd > 0 &&
          lot.quantity > 0 &&
          index + 1 !== lotsData.length
        ) {
          if (lot.quantity >= obj.quantityCanAdd) {
            _lot.quantity = obj.quantityCanAdd;
            obj.quantityCanAdd = 0;
          } else {
            _lot.quantity = lot.quantity;
            obj.quantityCanAdd = getDecimalValue(
              appContext.shop_info,
              obj.quantityCanAdd - lot.quantity,
            );
          }
          obj.lots.push(_lot);
        } else if (obj.quantityCanAdd > 0 && index + 1 === lotsData.length) {
          _lot.quantity = obj.quantityCanAdd;
          obj.lots.push(_lot);
        }
        return obj;
      },
      { lots: [], quantityCanAdd: quantity },
    );
    return mappedData.lots;
  }

  const handleConfirmFulfill = () => {
    const draftOrderProduct = cloneDeep(_order.orderProducts);
    if (draftOrderProduct?.length) {
      draftOrderProduct.forEach(async (item) => {
        if (item.lotSupport) {
          const lotsData = await getLotsData(
            appContext.callAPI,
            item,
            appContext,
          );
          item.lotsData = lotsData;
          item.lots = fillLots(lotsData, item.quantity);
        }
      });
      setOrder((prev) => ({
        ...prev,
        orderProducts: draftOrderProduct,
      }));
    }
    setShow(true);
  };

  const body = () => {
    return (
      <span>
        <FormattedMessage
          id="order.Xác nhận đã giao hàng cho đơn {a}?"
          defaultMessage="Xác nhận đã giao hàng cho đơn {a}?"
          values={{
            a: `${order?.orderNumber}`,
          }}
        />
      </span>
    );
  };

  return (
    <GlobalModal
      headerTitle={intl.formatMessage({
        id: "orders.searches.Xác nhận giao hàng",
        defaultMessage: "Xác nhận giao hàng",
      })}
      body={body()}
      show={show}
      setShow={setShow}
      onCancel={() => setShow(false)}
      onConfirm={confirmFulfilled}
      loading={loading}
    >
      <Button
        loading={loading}
        status="primary"
        light
        className="mr-3"
        title="Xác nhận giao hàng"
        onClick={() =>
          checkShiftReady(true, () => {
            handleConfirmFulfill();
          })
        }
      />
    </GlobalModal>
  );
}
