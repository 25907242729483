import { Assets } from "assets";
import {
  toFormattedCurrency,
  toFormattedNumber,
} from "infrastructures/format-helpers";
import "moment-timezone";
import eng from "../../../infrastructures/locales/en-messages.json";
import { DiscountAllocationType } from "screens-v2/carts-v2/cart-helpers.service";

export function getPrintTemplateRefund({
  printSettings,
  barcodeRender,
  location,
  order,
  locale,
  shopLogoUrl,
  shopImageUrl,
  shopAddress,
  orderDate,
  taxable,
  totalQtt,
  appContext,
  dataMember,
  renderAmountTransaction,
  qrcodeVAT,
  qrcodeSvg,
  isNewOrder,
  refundOrderModel,
  totalRefund,
}) {
  function renderTotalQtt(lstProducts) {
    let totalQtt = 0;
    lstProducts.forEach((product) => {
      totalQtt += product.refundNumber;
    });
    return totalQtt;
  }

  const notAllowRefundIfLowerPrice =
    appContext.shop_setting.notAllowRefundIfLowerPrice;

  const renderStyle = `
   <style>
      @page {
      margin-top: 4mm;
      margin-right: 7mm;
      margin-left: 7mm;
      margin-bottom: 4mm;
      }
      .qrcode svg
      {
         
      }
      html,
      body {
      width: 100%;
      }
      table {
      font-size: 13px;
      }
      .table{
      display: flow-root;
      }
      body {
      font-family: sans-serif;
      display: block;
      font-size: 13px;
      color: #000;
      margin: 0 auto;
      line-height: 16px;
      }
      body {
      position: relative;
      width: 100%;
      -webkit-touch-callout: none;
      -webkit-text-size-adjust: none;
      -webkit-user-select: none;
      -webkit-highlight: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      .printorder {
      margin: 0 auto;
      font-size: inherit;
      font-family: inherit;
      }
      .printorder .printtable {
      font-size: inherit;
      font-family: inherit;
      width: 100%;
      }
      .text-right {
      text-align: right;
      }
      .text-center {
      text-align: center;
      }
      .p-r-20 {
      padding-right: 20px;
      }
      .printorder .printrow {
      clear: both;
      width: 100%;
      display: table;
      }
      .printorder .printrow.last {
      border-bottom: 1px solid #B7B7B7;
      }
      .printorder .printrow.noborder .printcol {
      border: none;
      }
      .printorder .printcol {
      display: table-cell;
      border-left: 1px solid #B7B7B7;
      border-top: 1px solid #B7B7B7;
      vertical-align: middle;
      }
      .printorder .printcol.last {
      border-right: 1px solid #B7B7B7;
      }
      .printorder .printcol.number {
      text-align: right;
      }
      .printorder .printcolheader {
      font-weight: bold;
      }
      .border-title {
      border-bottom: 2px solid;
      border-top: 2px solid;
      padding-bottom: 3px;
      padding-top: 3px;
      margin-bottom: 5px;
      }
      .page-break {
      display: block;
      clear: both;
      page-break-before: always;
      }
      .barcode-wrapper {
      width: 100%;
      overflow: hidden;
      text-align: center;
      }
      p{
      margin: 0 !important;
      }
      .text-align-center {
      text-align: center;
      }
      .barcode-container div:first-of-type {
      width: 5px !important;
      }
      .barcode-container div:last-of-type {
      width: 5px !important;
      }
      .m-b-10 {
      margin-bottom: 10px;
      }
      .m-b-5 {
      margin-bottom: 5px;
      }
      .m-t-10 {
      margin-top: 10px;
      }
      .m-t-5 {
      margin-top: 5px;
      }
      #barcode {
      max-height: 70px;
      max-width: 220px;
      }
      .barcode-wrapper svg
      {
      max-width: 100% !important;
      }
      
   </style>
   `;

  const renderSummary = `
   ${
     refundOrderModel?.listOrderProducts?.length
       ? `
               ${
                 printSettings.totalQuantity && !isNewOrder
                   ? `
               <tr>
                  <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Tổng số lượng trả"]) || "Tổng số lượng trả"}:</td>
                  <td class="text-right" style="width:100px">${toFormattedNumber(renderTotalQtt(refundOrderModel.listOrderProducts))}</td>
               </tr>
               `
                   : ``
               }
               ${
                 !isNewOrder
                   ? `
               <tr>
               <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Tổng tiền hoàn trả"]) || "Tổng tiền hoàn trả"}:</td>
               <td class="text-right" style='white-space: nowrap' style="width:100px">${toFormattedCurrency(totalRefund ? totalRefund : refundOrderModel.orderModel.totalRefunded)}</td>
            </tr>
               `
                   : ``
               }
               ${
                 printSettings.totalQuantity
                   ? `
               ${
                 isNewOrder
                   ? `
               <tr>
                  <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Tổng số lượng mua"]) || "Tổng số lượng mua"}:</td>
                  <td class="text-right" style="width:100px">${toFormattedNumber(totalQtt)}</td>
               </tr>
               `
                   : ``
               }
               `
                   : ``
               }
               ${
                 isNewOrder
                   ? `<tr>
                  <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Tổng tiền hàng mua"]) || "Tổng tiền hàng mua"}:</td>
                  <td class="text-right" style='white-space: nowrap' style="width:100px">${toFormattedCurrency(order.subTotal)}</td>
               </tr>`
                   : ""
               }
               ${
                 order.redeemName &&
                 order.redeemUsedAmount > 0 &&
                 order.redeemDiscountType &&
                 order.redeemDiscountType !== "percent" &&
                 appContext?.shop_loyalty.enabled_refund_point &&
                 order.rewardRedeemAmount > 0
                   ? `
               <tr>
                  <td class="text-right p-r-20">${order.redeemName}${order.redeemDiscount ? `(${order.redeemDiscount}%)` : ``}:</td>
                  <td class="text-right" style='white-space: nowrap' style="width:100px">-${toFormattedCurrency(order.rewardRedeemAmount)}</td>
               </tr>
               `
                   : ``
               }
               ${
                 printSettings && printSettings.shipPrice
                   ? `
               <tr>
                  <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Phí vận chuyển"]) || "Phí vận chuyển"}:</td>
                  <td class="text-right" style='white-space: nowrap' style="width:100px">${toFormattedCurrency(order.orderShippingFee)}</td>
               </tr>
               `
                   : ``
               }
               ${
                 isNewOrder && order.discountAmount
                   ? `
               <tr>
                  <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Khuyến mãi"]) || "Khuyến mãi"}${order.discountType && order.discountType === "percent" ? ` (${order.discountPercent}%)` : ""}:</td>
                  <td class="text-right" style='white-space: nowrap' style="width:100px">${toFormattedCurrency(isNewOrder ? order.discountAmount : 0)}</td>
               </tr>
               `
                   : ``
               }
               ${
                 !!order.discountAllocations &&
                 order.discountAllocations.length > 0
                   ? order.discountAllocations
                       .map((discountItem, index) => {
                         const td =
                           index === 0
                             ? `<td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Mã khuyến mãi"]) || "Mã khuyến mãi"}:</td>`
                             : `<td class="text-right p-r-20"></td>`;
                         return `<tr>${td}<td class="text-right" style="width:100px">${discountItem.name}</td>
                  </tr>`;
                       })
                       .join("")
                   : ``
               }
         ${
           taxable
             ? `
         ${
           order.totalTaxIncluded && order.totalTaxNotIncluded
             ? `
            <tr>
               <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.VAT trong giá bán"]) || "VAT trong giá bán"}:</td>
               <td class="text-right"><span bstyle='white-space: nowrap' style="width:100px">${toFormattedCurrency(order.totalTaxIncluded)}</span></td>
            </tr>`
             : ""
         }
         ${
           order.totalTaxNotIncluded
             ? `
            <tr>
               <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.VAT cần trả"]) || "VAT cần trả"}:</td>
               <td class="text-right"><span style='white-space: nowrap' style="width:100px">${toFormattedCurrency(order.totalTaxNotIncluded)}</span></td>
            </tr>`
             : ""
         }
      `
             : ""
         }

         ${
           isNewOrder
             ? `
         <tr style="margin-top: 0px;width: 100%">
                  <td colspan="2" style="padding-top:5px;padding-bottom:5px">
                     <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
                  </td>
         </tr>
         <tr>
            <td class="text-right p-r-20"><b>${(locale === "en" && eng["settings.print-templates.Tổng tiền hoàn trả"]) || "Tổng tiền mua"}:</b></td>
            <td class="text-right" style="width:100px"><b>${toFormattedCurrency(order.orderTotal)}</b></td>
         </tr>
         ${
           taxable && order.totalTaxIncluded && !order.totalTaxNotIncluded
             ? `<tr>
         <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.(Giá đã bao gồm VAT)"]) || "(Giá đã bao gồm VAT)"}</td>
         </tr>`
             : ""
         }
         <tr>
            <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Tổng tiền hoàn trả"]) || "Tổng tiền trả"}:</td>
            <td class="text-right" style="width:100px">${toFormattedCurrency(totalRefund ? totalRefund : refundOrderModel.orderModel.totalRefunded)}</td>
         </tr>
         `
             : ``
         }

               <tr style="margin-top: 0px;width: 100%">
                  <td colspan="2" style="padding-top:5px;padding-bottom:5px">
                     <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
                  </td>
               </tr>
               ${
                 (totalRefund
                   ? totalRefund
                   : refundOrderModel.orderModel.totalRefunded) -
                   (isNewOrder ? order.orderTotal : 0) >=
                 0
                   ? `
               <tr>
                  <td class="text-right p-r-20"><b>${(locale === "en" && eng["settings.print-templates.Tiền trả khách"]) || "Tiền trả khách"}:</b></td>
                  <td class="text-right"><b style='white-space: nowrap' style="width:100px">${toFormattedCurrency(Math.abs((totalRefund ? (appContext.shop_setting.notAllowRefundIfLowerPrice && isNewOrder ? order.subTotal : totalRefund) : refundOrderModel.orderModel.totalRefunded) - order.orderTotal))}</b></td>
               </tr>
               ${
                 printSettings.paymentGateway &&
                 refundOrderModel?.orderModel?.transactions
                   ? refundOrderModel.orderModel.transactions
                       .map((transaction) => {
                         return `
               ${
                 (isNewOrder
                   ? notAllowRefundIfLowerPrice
                     ? 0
                     : transaction.amountTransaction -
                       order.subTotal -
                       order.totalTaxNotIncluded
                   : transaction.amountTransaction) > 0
                   ? `
               <tr>
                  <td class="text-right p-r-20">${transaction.paymentMethodName === "Tiền mặt" ? (locale === "en" && eng["carts.orders.Tiền mặt"]) || "Tiền mặt" : transaction.paymentMethodName}:</td>
                  <td class="text-right" style='white-space: nowrap' style="width:100px">${toFormattedCurrency(isNewOrder ? (notAllowRefundIfLowerPrice ? 0 : transaction.amountTransaction - order.subTotal) : transaction.amountTransaction)}</td>
               </tr>
               `
                   : ``
               }
               `;
                       })
                       .join("")
                   : ``
               }
               `
                   : `
               <tr>
                  <td class="text-right p-r-20"><b>${(locale === "en" && eng["settings.print-templates.Tiền khách trả thêm"]) || "Tiền khách trả thêm"}:</b></td>
                  <td class="text-right"><b style='white-space: nowrap' style="width:100px">${toFormattedCurrency(Math.abs((totalRefund ? totalRefund : refundOrderModel.orderModel.totalRefunded) - (isNewOrder ? order.orderTotal : 0)))}</b></td>
               </tr>
               ${
                 printSettings.paymentGateway &&
                 refundOrderModel &&
                 refundOrderModel.orderModel &&
                 refundOrderModel.orderModel.transactions &&
                 order &&
                 order.transactions &&
                 order.transactions.length
                   ? order.transactions
                       .map((transaction) => {
                         return `
               ${
                 (transaction.transactionName === "Xác nhận thanh toán" ||
                   transaction?.type === "paid") &&
                 (transaction.paymentMethodName === "Tiền mặt"
                   ? transaction.amountTransaction -
                     (totalRefund
                       ? totalRefund
                       : refundOrderModel.orderModel.totalRefunded)
                   : transaction.amountTransaction > 0)
                   ? `
               <tr>
                  <td class="text-right p-r-20">${transaction.paymentMethodName === "Tiền mặt" ? (locale === "en" && eng["carts.orders.Tiền mặt"]) || "Tiền mặt" : transaction.paymentMethodName}:</td>
                  <td class="text-right" style='white-space: nowrap' style="width:100px">${toFormattedCurrency(transaction.paymentMethodName === "Tiền mặt" ? transaction.amountTransaction - (totalRefund ? totalRefund : refundOrderModel.orderModel.totalRefunded) : transaction.amountTransaction)}</td>
               </tr>
               `
                   : ``
               }
               `;
                       })
                       .join("")
                   : ``
               }
               `
               }
               `
       : ``
   }
   `;

  const renderBillInfo = `
   <div class="printrow">
               <div class="printcol last" style="width: 100%">
                  ${
                    printSettings.showLocation
                      ? `
                  <p>${(locale === "en" && eng["carts.orders.Chi nhánh"]) || "Chi nhánh"}: ${location.locationName ? location.locationName : "--"}</p>
                  `
                      : ``
                  }
                  ${
                    printSettings.shopAddress
                      ? `
                  <p>${(locale === "en" && eng["settings.print-templates.Địa chỉ"]) || "Địa chỉ"}: ${shopAddress}
                  </p>
                  `
                      : ``
                  }
                  ${
                    printSettings.shopPhone
                      ? `
                  <p>${(locale === "en" && eng["settings.print-templates.Số ĐT"]) || "Số ĐT"}: ${location.phone ? location.phone : "--"}</p>
                  `
                      : ``
                  }
                  ${
                    printSettings.shopWebsite
                      ? `
                  <p>Website: ${printSettings && (printSettings.websiteUrl || "--")}</p>
                  `
                      : ``
                  }
                  ${
                    printSettings.shopEmail
                      ? `
                  <p>Email: ${location.email ? location.email : "--"}</p>
                  `
                      : ``
                  }
                  ${
                    printSettings.nameSalesMan
                      ? `
                  <p>${(locale === "en" && eng["carts.orders.Nhân viên"]) || "Nhân viên"}: ${order.userName ? order.userName : "--"}</p>
                  `
                      : ``
                  }
                  ${
                    printSettings.billDay
                      ? `
                  <p>${(locale === "en" && eng["settings.print-templates.Ngày bán"]) || "Ngày bán"}: ${orderDate}</p>
                  `
                      : ``
                  }
               </div>
            </div>
   `;

  const renderCustomerInfo = `
   <div class="printrow">
               ${
                 printSettings.customerName
                   ? `
               <p><strong>${(locale === "en" && eng["settings.print-templates.KH"]) || "KH"}: </strong>${order.shipFullName ? order.shipFullName : order.billFullName !== " " && order.billFullName ? order.billFullName : (locale === "en" && eng["orders.searches.Khách lẻ"]) || "Khách lẻ"}</p>
               `
                   : ``
               }
               ${
                 order.shipFullName ||
                 (order.billFullName !== " " && order.billFullName)
                   ? `
               ${
                 printSettings.customerAdress
                   ? `
               <p>
                  <strong>${(locale === "en" && eng["settings.print-templates.Địa chỉ"]) || "Địa chỉ"}: </strong>
                  ${order.shippingAddress ? order.shippingAddress + " -" : order.billingAddress ? order.billingAddress + " -" : ""}
                  ${order.wardName ? order.wardName + " -" : ""}
                  ${order.districtName ? order.districtName + " -" : ""}
                  ${order.shippingProvinceName ? order.shippingProvinceName + " -" : order.billingProvinceName ? order.billingProvinceName + " -" : ""}
                  ${order.shippingCountryName ? order.shippingCountryName : order.billingCountryName ? order.billingCountryName + " -" : ""}
               </p>
               `
                   : ``
               }
               ${
                 printSettings.customerPhone
                   ? `
               <p><strong>${(locale === "en" && eng["settings.print-templates.SĐT"]) || "SĐT"}: </strong>${order.shippingPhone ? order.shippingPhone : order.billingPhone ? order.billingPhone : "--"}</p>
               `
                   : ``
               }
               ${
                 printSettings.customerEmail
                   ? `
               <p><strong>Email: </strong> <span> ${order.customerEmail ? order.customerEmail : order.billingEmail ? order.billingEmail : "--"}</span></p>
               `
                   : ``
               }
               `
                   : ``
               }
            </div>
   `;

  const renderVAT = `
   ${
     order.orderEInvoice &&
     qrcodeVAT &&
     printSettings.showEInvoiceBarcode &&
     !printSettings.showEInvoiceInfo
       ? `<div class="m-t-5 m-b-10">
         <div style="margin-top: 10px;" class="text-center">
            <h4 class="m-t-5 m-b-5 font-weight-bold">THÔNG TIN HOÁ ĐƠN VAT</h4>
            <p class="m-t-5 m-b-5">Quét mã để xem chi tiết hoá đơn</p>
            ${qrcodeVAT.outerHTML}
         </div>
      </div>`
       : ""
   }
      ${
        order.orderEInvoice && printSettings.showEInvoiceInfo
          ? `<div class="m-t-5 m-b-10" >
         <div style="margin-top: 10px;">
            <h4 class="m-t-5 m-b-5 font-weight-bold">THÔNG TIN HOÁ ĐƠN VAT</h4>
            <div class="m-t-5" style="display: flex">
               ${
                 printSettings.showEInvoiceBarcode && qrcodeVAT
                   ? `<div style="margin-right: 15px; margin-top: 5px">
                  ${qrcodeVAT.outerHTML}
               </div>`
                   : ``
               }
               <div>
                  <p class='m-b-10'>
                     ${(locale === "en" && eng["Mã tra cứu hoá đơn: "]) || "Mã tra cứu hoá đơn: "}
                     <span class="printcolheader">
                        ${order.orderEInvoice.haravanExternalCode || ""}
                     </span>
                  </p>
                  <p class='m-b-10'>
                     ${(locale === "en" && eng["Số hoá đơn: "]) || "Số hoá đơn: "}
                     <span class="printcolheader">
                        ${order.orderEInvoice.invoiceNumber || ""}
                     </span>
                  </p>
                  <p class='m-b-10'>
                     ${(locale === "en" && eng["Ký hiệu mẫu hoá đơn: "]) || "Ký hiệu mẫu hoá đơn: "}
                     <span class="printcolheader">
                        ${order.orderEInvoice.invoiceTemplate || ""}
                        ${(order.orderEInvoice.invoicePattern || "").charAt(0)}${order.orderEInvoice.invoiceSerialNumber || ""}
                     </span>
                  </p>
                  <p class='m-b-10'>
                     ${(locale === "en" && eng["Mã cơ quan thuế: "]) || "Mã cơ quan thuế: "}
                     <span class="printcolheader">
                        ${order.orderEInvoice.invoiceTaxCode || ""}
                     </span>
                  </p>
                  <p class='m-b-10'>
                     ${(locale === "en" && eng["Link tra cứu: "]) || "Link tra cứu: "}
                     <span>
                        ${order.orderEInvoice.trackingUrl || ""}
                     </span>
                  </p>
               </div>
            </div>
         </div>
      </div>`
          : ``
      }
   `;

  const renderLoyalty = `
   ${
     printSettings.showLevelNameLoyalty &&
     appContext.shop_loyalty?.enabled &&
     order.customerId &&
     dataMember &&
     dataMember.status === 1 &&
     dataMember.data.current_membership_level !== "Chưa là thành viên"
       ? `
      <div style="margin-top: 0px;">
         <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
      </div>
      <div class="m-t-5">
         <div>
            <p style="font-size: 13px; text-align:left">
               ${(locale === "en" && eng["settings.print-templates.Hạng thành viên"]) || "Hạng thành viên"}: <strong>${dataMember.data.customer_level_name}</strong> - ${(locale === "en" && eng["settings.print-templates.Điểm thưởng hiện có"]) || "Điểm thưởng hiện có"}: <strong>${dataMember.data.amount_payment}</strong>${renderAmountTransaction ? ` - ${(locale === "en" && eng["settings.print-templates.Điểm tích được cho đơn hàng này"]) || "Điểm tích được cho đơn hàng này"}: <strong>${dataMember.data.amount_transaction}</strong>` : ""} 
            </p>
         </div>
      </div>
      `
       : ``
   }
   `;

  const renderProductListRefund = `
   ${
     refundOrderModel?.listOrderProducts
       ? `
      <div class="text-left"><b>${(locale === "en" && eng["settings.print-templates.Trả hàng"]) || "Trả hàng"}: ${refundOrderModel?.orderModel?.OrderNumber}</b></div>
      `
       : ``
   }
      <div class="printtable" style="margin-top: 10px;">
      <div class="printrow noborder border-title" >
         <div class="printcol printcolheader" style="width: 44%;text-align: left;" >
            ${(locale === "en" && eng["settings.print-templates.Đgiá/Ggiảm"]) || "Đgiá/Ggiảm"}
         </div>
         <div class="printcol printcolheader" style="width: 10%" >${(locale === "en" && eng["settings.print-templates.SL"]) || "SL"}</div>
         <div class="printcol printcolheader number" style="width: 45%;" >
            ${(locale === "en" && eng["settings.print-templates.T.Tiền"]) || "T.Tiền"}
         </div>
      </div>
      <div class="printrow noborder mg-b--5">
         ${
           refundOrderModel?.listOrderProducts?.length
             ? refundOrderModel.listOrderProducts
                 .map((product, index) => {
                   const productRefundedQuantity =
                     product.restockQuantity + product.returnableQuantity;
                   const discountAllocations =
                     product.discountAllocations || product.properties;
                   return `
         <div class="printrow noborder mg-b--5" style="width: 100%;">
            <div class="printcol">
               ${product.productName ? `<span>${product.productName}</span>` : ""}
               ${product.productName && printSettings.isVariant && product.variantValue && product.variantValue !== "Default Title" ? `<span> - ${product.variantValue}</span>` : ""}
               ${printSettings.skuCode && product.sku ? `<span> - ${product.sku}</span>` : ""}
               ${printSettings.barcodeVariant && product.barcode ? `<span> - ${product.barcode}</span>` : ""}
               ${product.unit ? `<span>(${product.unit})</span>` : ""}
            </div>
         </div>
         ${
           discountAllocations &&
           discountAllocations.length > 0 &&
           printSettings.productNote
             ? `
         <div class="printrow noborder m-b-5">
            ${discountAllocations
              .map((element) => {
                let name = element.name;
                if (!name || !name.length)
                  name =
                    element.type === DiscountAllocationType.LINE_DISCOUNT
                      ? "Giảm giá tùy chỉnh"
                      : element.type;
                if (element.value && element.value.length > 0) {
                  let arrs = element.value.split(" - ");
                  if (arrs.length > 1) {
                    name = arrs.slice(1).join(" ");
                  }
                }
                return name;
              })
              .filter(Boolean)
              .join(", ")}
         </div>
         `
             : ""
         }
         <div class="printrow noborder mg-b--5">
            <div class="printcol" style="width: 44%;text-align: left;">
               ${
                 product.productPrice !== product.originalPrice
                   ? `<del style='white-space: nowrap'>${toFormattedCurrency(product.originalPrice)}</del>`
                   : ``
               }
               <p style='white-space: nowrap'>${toFormattedCurrency(product.productPrice)}</p>
            </div>
            <div class="printcol" style="width: 10%;">
               <span>${toFormattedNumber(product.refundNumber)}</span>
            </div>
            <div class="printcol number" style="width: 45%;">
               <span style='white-space: nowrap'>${toFormattedCurrency(product.productPrice * product.refundNumber)}</span>
            </div>
         </div>
         <div class="printrow noborder m-b-5">
            <p>${productRefundedQuantity > 0 ? `${(locale === "en" && eng["orders.searches.Đã nhập kho"]) || "Đã nhập kho"} ${productRefundedQuantity}` : ``}</p>
         </div>
         ${
           index + 1 !== refundOrderModel.listOrderProducts.length
             ? ` 
         <div style="margin-bottom: 5px; padding-left: 10px; padding-right: 10px">
            <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
         </div>
         `
             : ``
         }
         `;
                 })
                 .join("")
             : ``
         }
      </div>
   </div>      
   `;

  const renderProductListNew = `
   ${
     isNewOrder && refundOrderModel?.listOrderProducts
       ? `
      <div style="margin-top: 5px;margin-bottom: 10px;">
         <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
      </div>
      <div class="text-left"><b>${(locale === "en" && eng["settings.print-templates.Mua hàng"]) || "Mua hàng"}: ${order?.orderNumber}</b></div>
      `
       : ``
   }
      ${
        isNewOrder && refundOrderModel && order?.orderProducts?.length
          ? `
      <div class="printtable" style="margin-top: 10px;">
         <div class="printrow noborder border-title" >
            <div class="printcol printcolheader" style="width: 44%;text-align: left;" >
               ${(locale === "en" && eng["settings.print-templates.Đgiá/Ggiảm"]) || "Đgiá/Ggiảm"}
            </div>
            <div class="printcol printcolheader" style="width: 10%" >${(locale === "en" && eng["settings.print-templates.SL"]) || "SL"}</div>
            <div class="printcol printcolheader number" style="width: 45%;" >
               ${(locale === "en" && eng["settings.print-templates.T.Tiền"]) || "T.Tiền"}
            </div>
         </div>
         <div class="printrow noborder mg-b--5">
            ${
              refundOrderModel?.listOrderProducts.length &&
              order?.orderProducts.length
                ? order.orderProducts
                    .map((product, index) => {
                      const discountAllocations =
                        product.discountAllocations || product.properties;
                      return `
            <div class="printrow noborder mg-b--5" style="width: 100%;">
               <div class="printcol">
                  ${product.productName ? `<span>${product.productName}</span>` : ""}
                  ${product.productName && printSettings.isVariant && product.variantValue && product.variantValue !== "Default Title" ? `<span> - ${product.variantValue}</span>` : ""}
                  ${printSettings.skuCode && product.sku ? `<span> - ${product.sku}</span>` : ""}
                  ${printSettings.barcodeVariant && product.barcode ? `<span> - ${product.barcode}</span>` : ""}
                  ${product.unit ? `<span>(${product.unit})</span>` : ""}
               </div>
            </div>
            ${
              discountAllocations &&
              discountAllocations.length > 0 &&
              printSettings.productNote
                ? `
            <div class="printrow noborder m-b-5">
               ${discountAllocations
                 .map((element) => {
                   let name = element.name;
                   if (!name || !name.length)
                     name =
                       element.type === DiscountAllocationType.LINE_DISCOUNT
                         ? "Giảm giá tùy chỉnh"
                         : element.type;
                   if (element.value && element.value.length > 0) {
                     let arrs = element.value.split(" - ");
                     if (arrs.length > 1) {
                       name = arrs.slice(1).join(" ");
                     }
                   }
                   return name;
                 })
                 .filter(Boolean)
                 .join(", ")}
            </div>
            `
                : ""
            }
            <div class="printrow noborder mg-b--5">
               <div class="printcol" style="width: 44%;text-align: left;">
                  ${
                    product.productPrice !== product.originalPrice
                      ? `<del style='white-space: nowrap'>${toFormattedCurrency(product.originalPrice)}</del>`
                      : ``
                  }
                  <p style='white-space: nowrap'>${toFormattedCurrency(product.productPrice)}</p>
               </div>
               <div class="printcol" style="width: 10%;">
                  <span>${toFormattedNumber(product.quantity)}</span>
               </div>
               <div class="printcol number" style="width: 45%;">
                  <span style='white-space: nowrap'>${toFormattedCurrency(product.totalPrice)}</span>
               </div>
            </div>
            ${
              index + 1 !== order.orderProducts.length
                ? ` 
            <div style="margin-bottom: 5px; padding-left: 10px; padding-right: 10px">
               <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
            </div>
            `
                : ``
            }
            `;
                    })
                    .join("")
                : ``
            }
         </div>
      </div>
      `
          : ``
      }  
   `;

  let template = `
<div id="printOrder" class="printorder">
   ${renderStyle}
   <div class="printorder">
      ${
        printSettings.showLogo
          ? `
      <div class="printtable">
         <div class="printrow noborder">
            <div class="printcol" style="width: 100%; text-align: center;">
               <img style="max-width: 220px; display: inline-block;" src=${shopLogoUrl || Assets.icon.hrv_logo_print}>
            </div>
         </div>
      </div>
      `
          : ``
      }
      ${
        printSettings.showLogo
          ? `
      <div style="margin-top: 0px;margin-bottom: 10px;">
         <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
      </div>
      `
          : ``
      }
      <div class="printtable">
         <div class="printrow noborder">
            ${renderBillInfo}
         </div>
      </div>
      ${
        printSettings.orderNumber
          ? `
      <div class="printtable m-t-5"
         style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
         <span style="text-align: center;font-weight: 600">&nbsp;${(locale === "en" && eng["settings.print-templates.Hóa đơn"]) || "Hóa đơn"}: ${order.orderNumber}&nbsp;</span>
      </div>
      `
          : ``
      }
      ${
        printSettings.barcode
          ? `
      <div class="printtable">
         <div class="printrow noborder">
            <div class="printcol barcode-wrapper" style="width: 100%;">
               ${barcodeRender}
            </div>
         </div>
      </div>
      `
          : ``
      }
      <div class="printtable" style="margin-bottom: 10px">
         <div class="printrow noborder">
            ${renderCustomerInfo}
         </div>
      </div>
      ${renderProductListRefund}
      ${renderProductListNew}
      <div style="margin-top: 5px;margin-bottom: 10px;">
         <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
      </div>
      <div class="table" style="display: flex;justify-content: flex-end;">
         <table style="width:100%;margin-bottom: 5px;">
            <tbody style="style="width:100%">
               ${renderSummary}
            </tbody>
         </table>
      </div>
      ${
        printSettings && printSettings.note
          ? `
      ${
        order.orderNotes
          ? `
      <div class="printtable" style="margin-top: 5px;">
         <div class="printrow noborder" style="font-size: 13px"><i>${(locale === "en" && eng["settings.print-templates.Ghi chú"]) || "Ghi chú"}: ${order.orderNotes}</i></div>
      </div>
      `
          : ``
      }
      `
          : ``
      }
      ${
        printSettings.showImage
          ? `
      <div class="printtable">
         <div class="printrow noborder">
            <div class="printcol" style="width: 100%; text-align: center;">
               <img style="max-width: 500px; min-width: 50px; display: inline-block;" src=${shopImageUrl || Assets.icon.hrv_logo_print}>
            </div>
         </div>
      </div>
      `
          : ``
      }
      ${renderLoyalty}
      ${renderVAT}
      ${
        qrcodeSvg
          ? `
			<div class="printtable text-align-center">
			 <div class="printrow noborder" style="white-space: pre-line; text-align: center;  margin-top:5px !important;">
				<p style="font-size: 13px; white-space: pre-wrap; word-break: break-word; ">${(locale === "en" && eng["settings.print-templates.Quét mã QR code này để thanh toán online"]) || "Quét mã QR code này để thanh toán online"}</p>
			 </div>
		  </div>
		  <div class="printtable">
			 <div class="printrow noborder">
				<div class="printcol barcode-wrapper qrcode" style="width: 100%;">    
				   ${qrcodeSvg.outerHTML}
				</div>
			 </div>
		  </div>
		  `
          : ``
      }
      ${
        printSettings.showAcknowledgements
          ? `
      <div style="margin-bottom: 10px;">
         <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
      </div>
      <div class="printtable text-align-center" ">
         <div class="printrow noborder" style="white-space: pre-line; text-align: center">
            <p style="font-size: 13px; white-space: pre-wrap; word-break: break-word">${printSettings && (printSettings.acknowledgements || "Thank you!")}</p>
         </div>
      </div>
      `
          : ``
      }
   </div>
</div>
`;
  return template;
}
