import Button from "component-v2/buttons";
import EmptyState from "component-v2/empty-state";

export default function NotReadyPage() {
  return (
    <EmptyState
      description="Có lỗi trong quá trình truy cập dữ liệu"
      button={
        <Button
          light
          title="Load lại trang"
          className="display-block margin-auto"
          onClick={() => window.location.reload(false)}
        />
      }
    />
  );
}
