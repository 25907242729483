import React, { useEffect, useState } from "react";
import TopProductReportChart from "./top-product-report-chart";
import TopProductReport from "./top-product-report";
import { getReportProductDetail } from "../utils/utils";
import { Loading } from "@haravan/react-components";
import { useApp } from "contexts/app";

export default function BestSellerTab({ dateRange, channel, staff }) {
  const appContext = useApp();
  const [dataSelling, setDataSelling] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const func = async () => {
      setIsLoading(true);
      const dataSelling = await getReportProductDetail(
        appContext,
        dateRange.startDate,
        dateRange.endDate,
        channel,
        staff,
      );
      setDataSelling(dataSelling);
      setIsLoading(false);
    };
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, channel, staff]);

  if (isLoading) {
    return (
      <div className="padding-top-16 col-12">
        <div
          style={{ height: "450px" }}
          className="product__chart d-flex justify-content-center align-items-center"
        >
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className="report-best-seller">
      <div className="row">
        <TopProductReportChart
          dataSelling={dataSelling}
          isLoading={isLoading}
        />
        <TopProductReport dataSelling={dataSelling} isLoading={isLoading} />
      </div>
    </div>
  );
}
