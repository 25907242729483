class ProductUtils {
  formatProductVariantTitle(variant: any) {
    let productVariantTitle = "";
    if (variant.value1) {
      productVariantTitle += variant.value1;
    }
    if (variant.value2) {
      productVariantTitle += variant.value2;
    }
    if (variant.value3) {
      productVariantTitle += variant.value3;
    }
    return productVariantTitle;
  }

  formatText = (text: string) => {
    var str = text.toString();
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      " ",
    );
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str;
  };
}

const productUtils = new ProductUtils();

export default productUtils;
