import React, { Fragment } from "react";
import { useIntl } from "react-intl";

export default function DateGroup({ date }) {
  const intl = useIntl();
  const formatViDay = (input) => {
    if (input) {
      var d = new Date(input);
      var currentDay = new Date();
      var weekday = [
        "Chủ nhật",
        "Thứ hai",
        "Thứ ba",
        "Thứ tư",
        "Thứ năm",
        "Thứ sáu",
        "Thứ bảy",
      ];
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var n = weekday[d.getDay()];
      var date = d.getDate();
      if (parseInt(date) < 10) {
        date = "0" + date;
      }
      var month = months[d.getMonth()];
      var year = d.getFullYear();
      if (
        d.getDate() === currentDay.getDate() &&
        d.getMonth() === currentDay.getMonth() &&
        d.getFullYear() === currentDay.getFullYear()
      ) {
        return "Hôm nay";
      } else if (
        d.getDate() === currentDay.getDate() - 1 &&
        d.getMonth() === currentDay.getMonth() &&
        d.getFullYear() === currentDay.getFullYear()
      ) {
        return "Hôm qua";
      } else {
        return (
          intl.formatMessage({
            id: `orders.searches.${n}`,
            defaultMessage: n,
          }) +
          " - " +
          date +
          "/" +
          month +
          "/" +
          year
        );
      }
    }
  };

  return <Fragment>{formatViDay(date)}</Fragment>;
}
