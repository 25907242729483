import { Textarea } from "@haravan/react-components";
import {
  addShiftActivity,
  getShiftTransactionSummary,
} from "api/shift-repository";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { find } from "lodash";
import { HOTKEYS, HOTKEY_SCOPES } from "models/constants";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { InputNumber } from "screens-v2/carts-v2/RightCart/components/InputNumber";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";

// Modal Điều chỉnh tiền trong ca đang mở
export default function ShiftAddTransactionModal({
  shift,
  show,
  setShow,
  onSuccess,
}) {
  const intl = useIntl();
  const appContext = useApp();
  const layoutContext = useLayoutContext();
  const [is_withdraw, setIsWithdraw] = useState(true);
  const [withdraw_amount, setWithdrawAmount] = useState(0);
  const [topup_amount, setTopupAmount] = useState(0);
  const [note, setNote] = useState("");
  const [cashAmoutShiftCurrent, setCashAmoutShiftCurrent] = useState(0);
  useGlobalHotkeys({
    keys: HOTKEYS.F8,
    callback: (e) => {
      handleSubmitActivity(e);
    },
    scopes: HOTKEY_SCOPES.MODAL,
    enabled: show,
  });
  useEffect(() => {
    const func = async () => {
      const response = await getShiftTransactionSummary(appContext, shift.id);
      let shiftTransactions = response?.data ? response.data : [];
      let cashAmoutShiftCurrent = 0;
      const cashTransaction = find(shiftTransactions, (x) => {
        return x.gatewayName === "cash";
      });
      if (cashTransaction) {
        cashAmoutShiftCurrent =
          cashTransaction.salesAmountTotal - cashTransaction.refundAmountTotal;
        setCashAmoutShiftCurrent(cashAmoutShiftCurrent);
      }
    };
    if (show) {
      setIsWithdraw(true);
      setWithdrawAmount(0);
      setTopupAmount(0);
      setNote("");
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleWithdrawAmountChanged = (value) => {
    let num = value;
    //Số tiền rút ko được lớn hơn số tiền mặt thực tế tại cửa hàng
    if (shift.activityAmount + cashAmoutShiftCurrent <= 0) {
      num = 0;
    } else if (num > shift.activityAmount + cashAmoutShiftCurrent) {
      num = cashAmoutShiftCurrent + shift.activityAmount;
    }
    setWithdrawAmount(num);
  };

  const handleTopupAmountChanged = (value) => {
    setTopupAmount(value);
  };

  const handleInputChanged = (value) => {
    setNote(value);
  };

  const handleSubmitActivity = async (event) => {
    if (
      (is_withdraw && withdraw_amount <= 0) ||
      (!is_withdraw && topup_amount <= 0)
    )
      return;
    let activityType = is_withdraw ? "withdraw" : "payinto";
    let amount = is_withdraw ? withdraw_amount : topup_amount;
    event.preventDefault();
    const response = await addShiftActivity(
      appContext,
      appContext.current_location,
      shift.id,
      activityType,
      amount,
      note,
    );
    if (response?.data) {
      layoutContext.showGlobalToast("default", "Điều chỉnh ca thành công");
      setShow(false);
      onSuccess?.();
    }
  };

  const footer = () => {
    return (
      <div>
        <Button
          light
          onClick={() => setShow(false)}
          title="Hủy"
          badge={HOTKEYS.ESC}
        />
        <Button
          status="primary"
          className="l-ml-8"
          title="Xác nhận"
          badge={HOTKEYS.F8}
          disabled={
            (is_withdraw && withdraw_amount <= 0) ||
            (!is_withdraw && topup_amount <= 0)
          }
          onClick={(event) => {
            handleSubmitActivity(event);
          }}
        />
      </div>
    );
  };

  const body = () => {
    return (
      <>
        <div className="l-table plain">
          <div>
            <div>
              {intl.formatMessage({
                id: "orders.searches.Nhân viên",
                defaultMessage: "Nhân viên",
              })}
              :
            </div>
            <div>{appContext.auth.user.name}</div>
          </div>
          <div>
            <div>
              {intl.formatMessage({
                id: "orders.searches.Email",
                defaultMessage: "Email",
              })}
              :
            </div>
            <div>{appContext.auth.user.email}</div>
          </div>
        </div>
        <div className="l-tab-group l-mt-16 l-mb-8">
          <div
            className={`l-tab${is_withdraw ? " active" : ""}`}
            onClick={() => {
              setIsWithdraw(true);
            }}
          >
            {intl.formatMessage({
              id: "shifts.Rút tiền",
              defaultMessage: "Rút tiền",
            })}
          </div>
          <div
            className={`l-tab${!is_withdraw ? " active" : ""}`}
            onClick={() => {
              setIsWithdraw(false);
            }}
          >
            {intl.formatMessage({
              id: "carts.orders.Nạp tiền",
              defaultMessage: "Nạp tiền",
            })}
          </div>
        </div>
        <div>
          {is_withdraw ? (
            <InputNumber
              onChange={(value) => handleWithdrawAmountChanged(value)}
              autoFocus={is_withdraw}
              // className="text-right"
              value={withdraw_amount}
            />
          ) : (
            <InputNumber
              onChange={(value) => handleTopupAmountChanged(value)}
              autoFocus={!is_withdraw}
              // className="text-right"
              value={topup_amount}
            />
          )}
        </div>

        <label className="mt-3">Ghi chú</label>
        <Textarea
          rows={3}
          className="text-black"
          onChange={(value) => handleInputChanged(value)}
          value={note}
          type="text"
          placeholder={intl.formatMessage({
            id: "carts.orders.Nhập ghi chú cho ca bán hàng",
            defaultMessage: "Nhập ghi chú cho ca bán hàng",
          })}
        />
      </>
    );
  };

  return (
    <GlobalModal
      headerTitle="Điều chỉnh ca bán hàng"
      body={body()}
      footer={footer()}
      show={show}
      setShow={(show) => {
        setShow(show);
      }}
      onCancel={() => {
        setShow(false);
      }}
    />
  );
}
