import Icon from "component-v2/icons/icons";
import { Fragment } from "react";

// status: default, success, info, warning, danger
// icon: đã có icon mặc định, dùng icon = [tên_icon] để custom
// closeable: đang phát triển

export default function Banner({
  status = "default",
  title,
  description,
  showIcon = true,
  icon,
  light = false,
  className,
  closeable = false,
  children,
}) {
  let _icon_name = "error_filled";
  let _icon_color = "unset";
  switch (status) {
    case "success":
      _icon_name = "checkbox_circle_filled";
      break;
    case "info":
      _icon_name = "info_filled";
      break;
    case "warning":
      _icon_name = "warning";
      break;
    case "danger":
      _icon_name = "error_filled";
      break;
    default:
      _icon_name = "error_filled";
      break;
  }
  return (
    <div
      className={`l-banner l-banner-${status}${light ? " light" : ""}${className ? " " + className : ""}`}
    >
      {children ? (
        children
      ) : (
        <Fragment>
          {showIcon ? (
            <div className="l-banner-prefix">
              <Icon name={icon ? icon : _icon_name} color={_icon_color} />
            </div>
          ) : null}
          <div className="l-banner-body">
            <div className="l-banner-title">{title}</div>
            <div className="l-banner-desc">{description}</div>
          </div>
          {closeable ? (
            <div className="l-banner-subfix">
              <Icon
                name="close"
                color={light ? "var(--contentSecondary)" : _icon_color}
              />
            </div>
          ) : null}
        </Fragment>
      )}
    </div>
  );
}
