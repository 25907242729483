import { DateUtils } from "utils";

export async function getShifts(appContext, locationId, page = 1, limit = 20) {
  const response = await appContext.callAPI('get', `/call/retail_api/shifts/search/?locationId=${locationId}&timestamp=${DateUtils.getTime()}&limit=${limit}&page=${page}`)
  return response?.data
}
export async function addShiftActivity(appContext, location, shiftId, type, amount, note) {
  const activityModel = {
    locationId: location.id,
    locationName: location.name,
    type: type,
    amount: amount,
    note: note
  };
  const response = await appContext.callAPI('post', `/call/retail_api/shifts/${shiftId}/activity`, activityModel);
  return response
}
export async function getShiftTransactionSummary(appContext, shiftId) {
  const response = await appContext.callAPI('get', `/call/retail_api/transactions/${shiftId}/summary`);
  return response
};
export const getCurrentShift = async (appContext, location) => {
  return await appContext.callAPI('get', `/call/retail_api/shifts/checkin/?locationId=${location}&timestamp=${DateUtils.getTime()}`);
};
export const getActivityList = async (appContext, shiftId) => {
  const response = await appContext.callAPI('post', `/call/retail_api/shifts/${shiftId}/activities`);
  return response
};

export const getTransactionSummary = async (appContext, shiftId) => {
  const response = await appContext.callAPI('get', `/call/retail_api/transactions/${shiftId}/summary`);
  return response
};

export const getSummaryShiftReport = async ({appContext, from_date, to_date, location_ids}) => {
  const response = await appContext.callAPI('get', `/call/com_api/pos/cash_flows/Summary?source=hararetail&from_date=${from_date}&to_date=${to_date}&location_ids=${location_ids}&payment_reason_not_contains=19&receipt_reason_not_contains=18`);
  return response
};

export const getListCashFlowReport = async ({appContext, from_date, to_date, location_ids, limit = 20,  after, before}) => {

  let path = `/call/com_api/cash_flows/report?source=hararetail&from_date=${from_date}&to_date=${to_date}&location_ids=${location_ids}&limit=${limit}&payment_reason_not_contains=19&receipt_reason_not_contains=18`

  if(after) {
    path += `&after=${after}`
  }

  if(before) {
    path += `&before=${before}`
  }

  const response = await appContext.callAPI('get', path);
  return response
};

export const getTransactionHistory = async (appContext, shiftId) => {
  const response = await appContext.callAPI('get', `/call/retail_api/transactions/${shiftId}/history`);
  return response
};

//////////////////////////////////////////////////////////////////////
export const getShiftClosed = async (appContext, locationId) => {
  try {
    const result = await appContext.callAPI('get', `/call/retail_api/shifts/closed?locationId=${locationId}`);
    if (result && result.data) {
      return result.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};
export const closeShift = async (appContext, shiftId, locationId, locationName, shiftCashTracking, activityType, activityAmount, shiftCashAmount, activityNote) => {
  const closeModel = {
    locationId,
    locationName,
    activityType: activityType,
    activityAmount: activityAmount,
    shiftCashAmount: shiftCashAmount,
    note: activityNote,
    isShiftCashTracking: shiftCashTracking
  };

  const result = await appContext.callAPI("post", `/call/retail_api/shifts/${shiftId}/close`, closeModel);
  return result
};
export const openShift = async (appContext, locationId, locationName, advanceAmount, note, shiftManager) => {
  if(!locationId) return null
  const shiftModel = {
    locationId,
    locationName,
    advanceAmount,
    note,
    shiftManager
  };
  const result = await appContext.callAPI("post", '/call/retail_api/shifts/open', shiftModel);
  return result;
};