import React from "react";
import PropTypes from 'prop-types';
import {useIntl} from "react-intl";
import {Loading} from "@haravan/react-components";
import {ArrowPagination} from 'components';
import {RefundCartSearchItem} from "./refund-cart-search-item";
import EmptyState from "component-v2/empty-state";


export const RefundCartSearchResult = (props) => {
  const intl = useIntl();
  const {isLoading, searchText, products} = props;
  const isShowUnit = products?.records?.some(product => !!product.variantUnitId);

  return (
    <>
      {
        isLoading ?
          <div className='refund-cart-search-result-modal'>
            < Loading size="pico" />
          </div >
          :
          <div className='refund-cart-search-result-modal scrollable-y' style={{maxHeight: products?.hasNextPage ? '30rem' : '25.6rem'}}>
            <ul className="list-group">
              {
                products?.records?.length > 0 && products.records.map((product, index) => {
                  return (
                    <RefundCartSearchItem key={product.id} product={product} index={index} isShowUnit={isShowUnit} {...props} onClick={props.onClick} />
                  );
                })
              }
            </ul>
            {products?.total >= 20 && (
              <div className='row pt-2 pb-2'>
                <div className='col-10' />
                <div className='col-2'>
                  <ArrowPagination
                    total={products.total}
                    currentPage={products.currentPage}
                    pageSize={20}
                    onChange={props.onNextPage}
                  />
                </div>
              </div>
            )}

          </div>
      }
      {
        searchText.length > 0 && (!products || products.records?.length === 0) && !isLoading && <div className="refund-cart-search-result-modal scrollable-y" style={{height: "25.6rem"}}><EmptyState iconName="shopping_cart" description={intl.formatMessage({id: 'carts.orders.Không tìm thấy sản phẩm nào', defaultMessage: 'Không tìm thấy sản phẩm nào'})} /></div>
      }
    </>
  );
};



RefundCartSearchResult.propTypes = {
  isLoading: PropTypes.bool,
  searchText: PropTypes.string,
  products: PropTypes.object,
  onClick: PropTypes.func
};