import { InputHasIcon } from "@haravan/react-components";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  LayoutCardSection,
  LayoutCardSectionSection,
} from "screens-v2/layouts/layouts/omni-layout-card";
import { OrderFilterStatuses } from "../utils/utils";
import BarcodeReader from "react-barcode-reader";
import Icon from "component-v2/icons/icons";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";
export default function OrderListSearch({
  loading,
  textSearch,
  updateTextSearch,
  status,
  updateStatus,
}) {
  const intl = useIntl();
  const [text, setText] = useState(textSearch ? textSearch : "");
  useEffect(() => {
    const delayFunc = setTimeout(() => {
      updateTextSearch(text);
    }, 400);
    return () => clearTimeout(delayFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);
  return (
    <LayoutCardSection className="order-list--list-search">
      <LayoutCardSectionSection className="input-group line-horizon">
        <InputHasIcon
          prefix={<Icon name={"search"} />}
          type="text"
          className="form-control h-100 w-75 cart-search-input"
          onChange={(value) => setText(value)}
          value={text}
          placeholder={intl.formatMessage({
            id: "orders.searches.Mã đơn hàng, SĐT, Tên, Email",
            defaultMessage: "Mã đơn hàng, SĐT, Tên, Email",
          })}
          suffix={
            text && (
              <span
                onClick={() => {
                  setText("");
                  updateTextSearch("");
                }}
                className="cursor-pointer"
              >
                <Icon name={"close"} />
              </span>
            )
          }
        />
      </LayoutCardSectionSection>
      <LayoutCardSectionSection className="l-pt-4 l-mt-8">
        <div className="wrap-dropdown-filter">
          <SelectionCustom
            options={OrderFilterStatuses}
            idField="id"
            valueField="name"
            value={status ? status.id : 0}
            disabled={loading}
            onChange={(value) => {
              let exist = OrderFilterStatuses.find((s) => s.id === value);
              updateStatus(exist);
            }}
          >
            {OrderFilterStatuses.map((item) => {
              return (
                <SelectionCustom.Option key={item.id} value={item.id}>
                  {intl.formatMessage({
                    id: `orders.searches.${item.name}`,
                    defaultMessage: item.name,
                  })}
                </SelectionCustom.Option>
              );
            })}
          </SelectionCustom>
          <BarcodeReader />
        </div>
      </LayoutCardSectionSection>
    </LayoutCardSection>
  );
}
