import React, { useEffect, useLayoutEffect } from 'react'
import { createPortal } from "react-dom";

const targetIdHeader = 'l-header-wrapper'
const TeleportElementHeader = ({ children }) => {
  const [mounted, setMounted] = React.useState(false);

  const modalEl = React.useRef();
  useEffect(() => {
    setMounted(true);
  }, []);

  useLayoutEffect(() => {
    if (mounted) {
      let headerWrapperCover = document.getElementById(targetIdHeader);
      modalEl.current = headerWrapperCover
    }
  }, [mounted]);

  return modalEl.current
        ? createPortal(children, modalEl.current) : null
}

export { TeleportElementHeader };