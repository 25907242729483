import Logo from "component-v2/logos/logos";
import { PaymentMethods, PaymentQrMethods } from "models/payment-methods-model";
import { Fragment } from "react";
import CurrencyShow from "component-v2/currency-show";
import * as HrvComponents from "@haravan/react-components";

export default function ShiftDetailPaymentSummary({ summaries, transactions }) {
  let _offline_payments = transactions?.filter(
    (t) => t.paymentFrom !== "online",
  );
  let _online_payments = transactions?.filter(
    (t) => t.paymentFrom === "online",
  );

  return (
    <div className="l-table l-mt-16">
      <div className="l-table-head">
        <div>Phương thức thanh toán</div>
        <div className="l-table--col-number">Đã thu</div>
        <div className="l-table--col-number">Đã chi</div>
        <div className="l-table--col-number">Thực nhận</div>
      </div>
      <div>
        <div
          className="font-weight-semibold"
          style={{ padding: "1.4rem 0.8rem" }}
        >
          Tổng
        </div>
        <div className="font-weight-medium l-table--col-number">
          <CurrencyShow value={Number(summaries.salesTotal).toFixed(4)} />
        </div>
        <div className="font-weight-medium l-table--col-number">
          <CurrencyShow value={Number(summaries.refundTotal).toFixed(4)} />
        </div>
        <div className="font-weight-medium l-table--col-number">
          <CurrencyShow value={Number(summaries.amountTotal).toFixed(4)} />
        </div>
      </div>
      {_offline_payments?.map((transaction) => {
        if (!transaction) return null;
        const total =
          transaction.salesAmountTotal - transaction.refundAmountTotal;
        const isHasIcon =
          PaymentMethods[transaction.gatewayName?.toLowerCase()] ||
          transaction.paymentMethodId;
        const qrIconName = isHasIcon
          ? Object.keys(PaymentQrMethods)?.find(
              (key) => PaymentQrMethods[key] === transaction.paymentMethodId,
            )
          : "";
        return (
          <div key={transaction.gatewayName}>
            <div className="d-flex">
              {!isHasIcon ? (
                <HrvComponents.Avatar
                  size={"large"}
                  arrColor="gray"
                  className="text-primary"
                  shape="circle"
                  content={transaction.gatewayName}
                ></HrvComponents.Avatar>
              ) : transaction.paymentMethodId ? (
                <Logo name={qrIconName} size={4} className="payment-logo" />
              ) : (
                <Logo
                  name={transaction.gatewayName.toLowerCase()}
                  size={4}
                  className="payment-logo"
                />
              )}
              <span className="l-ml-8 font-weight-semibold align-self-center">
                {PaymentMethods[transaction.gatewayName?.toLowerCase()] ||
                  transaction.gatewayName}
              </span>
            </div>
            <div className="l-table--col-number">
              <CurrencyShow value={Math.round(transaction.salesAmountTotal)} />
            </div>
            <div className="l-table--col-number">
              <CurrencyShow value={Math.round(transaction.refundAmountTotal)} />
            </div>
            <div className="l-table--col-number">
              <CurrencyShow value={Math.round(total)} />
            </div>
          </div>
        );
      })}
      {_online_payments?.length ? (
        <Fragment>
          <div>
            <div>
              <Logo name="online" size={4} className="payment-logo" />
              <span className="l-ml-8 font-weight-semibold cursor-pointer">
                {PaymentMethods.online}
              </span>
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          {_online_payments.map((transaction) => {
            const total =
              transaction.salesAmountTotal - transaction.refundAmountTotal;
            return (
              <div key={transaction.gatewayName}>
                <div>
                  <div style={{ marginLeft: "4.8rem" }}>
                    {transaction.gatewayName}
                  </div>
                </div>
                <div className="l-table--col-number">
                  <CurrencyShow
                    value={Math.round(transaction.salesAmountTotal)}
                  />
                </div>
                <div className="l-table--col-number">
                  <CurrencyShow
                    value={Math.round(transaction.refundAmountTotal)}
                  />
                </div>
                <div className="l-table--col-number">
                  <CurrencyShow value={Math.round(total)} />
                </div>
              </div>
            );
          })}
        </Fragment>
      ) : null}
    </div>
  );
}
