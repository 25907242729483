import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function BlockingComponent({ children, promptBeforeTransition, isBlockingNavigate }) {

  const history = useHistory();

  let unblock = () => { };
  let unloadHandler = (event) => {
    return `Are you sure you want to leave?`
  };

  useEffect(() => {
    if (!isBlockingNavigate) {
      unblockNavigation();
    } else {
      blockingNavigation();
    }

    return () => {
      if (promptBeforeTransition) {
        unblockNavigation()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockingNavigate])

  const blockingNavigation = () => {
    if (promptBeforeTransition) {
      window.onbeforeunload = unloadHandler;
      unblock = history.block(promptBeforeTransition);
    }
  }

  const unblockNavigation = () => {
    unblock && unblock();
    unblock = null;
    window.onbeforeunload = null;
  };
  return children;
}

BlockingComponent.propTypes = {
  promptBeforeTransition: PropTypes.any,
  isBlockingNavigate: PropTypes.bool,
  children: PropTypes.any
}

export default BlockingComponent;