import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductService } from "services";
import { useIntl } from "react-intl";
import useAddressData from "hooks/useAddressData";
import {
  ProductDetailItem,
  ProductDetailFilter,
  ProductDetailList,
} from "../components";
import { ProductHelperService } from "../product-helper.service";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import { useApp } from "contexts/app";
import EmptyState from "component-v2/empty-state";

export const ProductDetail = (props) => {
  const appContext = useApp();
  const _service = new ProductService();
  const _productHelper = new ProductHelperService();
  const intl = useIntl();
  const [item, setItem] = useState(null);
  const param = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeProvince, setIsChangeProvince] = useState(false);
  const [isChangeDistrict, setIsChangeDistrict] = useState(false);
  const [isAvailableInventory, setIsAvailableInventory] = useState(false);
  const [isLoadingIventory, setIsLoadingIventory] = useState(false);
  const [isFilter, setIsFilter] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [listInventory, setListInventory] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [productID, setProductID] = useState(null);
  const [variantID, setVariantID] = useState(null);
  const [provinceId, setProvinceId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const { provinces, districts } = useAddressData({ provinceId });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDetail = async (args) => {
    setIsLoading(true);
    const { productId, variantId } = args;
    let result = await _service.getDetailProduct(
      appContext,
      productId,
      variantId,
    );
    if (result) setItem(result);
    setIsLoading(false);
  };

  const loadInventory = async (args) => {
    setIsLoading(true);
    const { variantId } = args;
    let result = await _productHelper.getDetailProductInventory(
      appContext,
      variantId,
    );
    if (result) {
      setListInventory(result.listInventory);
      setTotalRecord(result.totalRecord);
      setIsAvailableInventory(result.isAvailable);
    }
    setIsLoading(false);
  };

  const loadingDefault = async (param) => {
    await loadDetail({
      productId: param.productId,
      variantId: param.variantId,
    });
    await loadInventory({ variantId: param.variantId });
  };

  useEffect(() => {
    setItem(null);
    setProvinceId("");
    setDistrictId("");
    setKeyword("");
    setProductID(param.productId);
    setVariantID(param.variantId);
    loadingDefault(param);
  }, [param.productId, param.variantId]);

  useEffect(() => {
    if (isChangeProvince) {
      setDistrictId("");
    }
    setIsChangeDistrict(false);
    setIsChangeProvince(false);
  }, [isChangeProvince, isChangeDistrict]);

  useEffect(() => {
    //  set api call inventory change
    onFireInventory();
  }, [provinceId, districtId, keyword]);

  useEffect(() => {
    if (listInventory.length > 0) {
      onFireInventory();
    }
  }, [listInventory]);

  //#region  filter

  const onFireInventory = (page = 1, limt = 10) => {
    setIsLoadingIventory(true);
    let result = _productHelper.filterInventory(
      { provinceId, districtId, keySearch: keyword },
      listInventory,
      page,
      limt,
    );
    if (result) {
      setTotalRecord(result.totalRecord);
      setListFilter(result.listShopAvailable);
    }
    setIsLoadingIventory(false);
  };
  const selectedProvince = (value) => {
    if (value) {
      setProvinceId(value);
      if (value !== provinceId) {
        setDistrictId(null);
        setIsChangeProvince(true);
      }
    } else {
      setProvinceId(null);
      setDistrictId(null);
      setKeyword("");
    }
  };

  const selectedDistrict = (value) => {
    if (value !== "") {
      setDistrictId(value);
      if (value !== districtId) {
        setIsChangeDistrict(true);
      }
    } else {
      selectedProvince(provinceId);
      setDistrictId(null);
    }
  };

  const handleKeywordClear = () => {
    setKeyword("");
  };

  //#endregion

  return (
    <Fragment>
      {isLoading ? (
        <LoadingCircle size="md" overlay />
      ) : (
        item && <ProductDetailItem product={item} />
      )}
      {!isLoading && item ? (
        <div>
          <span className="product-detail-title product--detail-m-tb-16">
            {intl.formatMessage({
              id: "products.searches.Tình trạng tồn kho",
              defaultMessage: "Tình trạng tồn kho",
            })}
          </span>
          <ProductDetailFilter
            provinces={provinces}
            districts={districts}
            districtId={districtId}
            provinceId={provinceId}
            isDisabled={false}
            selectedDistrict={selectedDistrict}
            selectedProvince={selectedProvince}
            onClearSearch={handleKeywordClear}
            onInputChange={setKeyword}
            keyword={keyword}
          />
          <ProductDetailList
            isAvailableInventory={isAvailableInventory}
            isFilter={isFilter}
            totalRecord={totalRecord}
            data={listFilter}
            onPagination={onFireInventory}
          />
        </div>
      ) : (
        <EmptyState
          iconName="local_offer"
          description={intl.formatMessage({
            id: "products.searches.Vui lòng tìm kiếm hoặc nhập barcode để tra cứu thông tin",
            defaultMessage:
              "Vui lòng tìm kiếm hoặc nhập barcode để tra cứu thông tin",
          })}
        />
      )}
    </Fragment>
  );
};
