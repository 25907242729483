import PropTypes from "prop-types";

export default function LoadingCircle({
  className,
  size = "md",
  customSize = 32,
  overlay = false,
  showLoading = true,
}) {
  let clss_size = ` loading-circle-${size}`;
  let half_custom_pixels = 16;
  if (size === "custom") {
    if (!customSize || Number.isNaN(customSize)) customSize = 32;
    if (customSize < 0) customSize = customSize * -1;
    half_custom_pixels = Math.round(customSize / 2);
    clss_size = " loading-circle-custom";
  }
  let clss = `omni-loading-circle${clss_size}${
    overlay ? " loading-overlay" : ""
  }${className ? " " + className : ""}`;
  return (
    <div
      className={clss}
      style={size === "custom" ? { minHeight: customSize + 20 } : null}
    >
      {showLoading && (
        <span
          className="loading-circle"
          style={
            size === "custom"
              ? {
                  width: customSize,
                  height: customSize,
                  marginTop: half_custom_pixels * -1,
                  marginLeft: half_custom_pixels * -1,
                }
              : null
          }
        />
      )}
      <div className="loading-circle--backdrop" />
    </div>
  );
}

LoadingCircle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg", "custom"]),
  customSize: PropTypes.number,
  overlay: PropTypes.bool,
};
