import React, { useReducer, useEffect } from 'react'
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { Input, InputRadio } from "@haravan/react-components";
import { FormattedMessage, useIntl } from "react-intl";
import * as HrvComponents from "@haravan/react-components";
import { useLayoutContext } from "contexts/Layout/layout-context";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import Icon from 'component-v2/icons/icons';
import { isValidTaxCode, validateEmail } from 'utils/Commons';
import Button from "component-v2/buttons";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { InputAddressSuggestion } from 'component-v2/VAT/InputAddressSuggestion';
const PopupVatInfo = (props) => {
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const {
    formData,
    onSave = () => { },
    onCancel = () => { },
    autoFillEmail = {
      title: 'Sử dụng email của khách',
      value: '',
    },
    autoFillCompanyAddress = {
      title: 'Sử dụng địa chỉ giao hàng',
      value: null,
    },
    modalTitle = 'Thông tin hóa đơn VAT',
    isOpen,
    enableEdit = true,
    loading = false,
    isDetail = false,
  } = props;


  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC && onCancel) {
        handleChangeData('defaultFormData', { ...state.defaultFormData });
        onCancel()
      }
      if (e.key === HOTKEY_EVENTS.F8 && onSaveData) {
        onSaveData();
      }
    },
    enabled: isOpen && enableEdit,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  const initState = {
    defaultFormData: formData,
    formData: formData,
  }
  const [state, setState] = useReducer((current, update) => ({
    ...current,
    ...update,
  }),
    initState
  )

  useEffect(() => {
    isOpen && setState({
      defaultFormData: formData,
      formData: formData
    })
  }, [isOpen]);

  const handleChangeData = (field, value) => {
    setState({ [field]: value })
  };

  const showToastError = (messageId, defaultMessage) => {
    showGlobalToast("error", intl.formatMessage({ id: messageId, defaultMessage }));
  };

  const validateField = (value, messageId, defaultMessage) => {
    let isValid = true
    if (!value || !value.trim()) {
      showToastError(messageId, defaultMessage);
      isValid = false
    }
    return isValid;
  };

  const onSaveData = async () => {
    const { formData } = state;
    if (!validateField(formData?.eInvoiceInfo?.name, "notifications.Chưa nhập tên công ty", "Chưa nhập tên")) {
      return null
    }

    if (formData?.eInvoiceInfo?.name.length > 200) {
      showToastError("notifications.Tên công ty không được vượt quá 200 ký tự", "Tên công ty không được vượt quá 200 ký tự");
      return null;
    }

    if (!validateField(formData?.eInvoiceInfo?.taxCode, "notifications.Chưa nhập mã số thuế", "Chưa nhập mã số thuế")) {
      return null;
    }

    if (!isValidTaxCode(formData?.eInvoiceInfo?.taxCode)) {
      showToastError(
        "notifications.Mã số thuế phải có độ dài từ 10 đến 50 ký tự, chỉ bao gồm chữ số và dấu '-'",
        "Mã số thuế phải có độ dài từ 10 đến 50 ký tự, chỉ bao gồm chữ số và dấu '-'"
      );
      return null;
    }

    if (!validateField(formData?.eInvoiceInfo?.email, "notifications.Chưa nhập email", "Chưa nhập email")) {
      return null;
    }

    if (!validateEmail(formData?.eInvoiceInfo?.email)) {
      showToastError("notifications.Email hóa đơn không hợp lệ", "Email hóa đơn không hợp lệ");
      return null;
    }

    if (!validateField(formData?.eInvoiceInfo?.address, "notifications.Chưa nhập địa chỉ công ty", "Chưa nhập địa chỉ công ty")) {
      return null;
    }

    if (formData?.eInvoiceInfo?.address.length > 500) {
      showToastError("notifications.Địa chỉ không được vượt quá 500 ký tự", "Địa chỉ không được vượt quá 500 ký tự");
      return null;
    }

    onSave(formData);
  };

  const body = () => {
    return (
      <div className=''>
        {!isDetail ?
          <Badge rounded size="L" className={'w-100 p-4 h-100 mb-3'} status="blue" light={true}>
            <span className='d-flex align-items-center text-black'>
              <Icon name={'info_filled'} size={'sm'} color="var(--blue600)" className={'mr-3'} />
              {intl.formatMessage({
                id: "carts.orders.Hóa đơn VAT sẽ được yêu cầu xuất khi đơn hàng được tạo thành công",
                defaultMessage: "Hóa đơn VAT sẽ được yêu cầu xuất khi đơn hàng được tạo thành công",
              })}
            </span>
          </Badge> : null}
        <div className='d-flex align-items-center justify-content-start mb-3'>
          <div>
            <InputRadio
              id='personal'
              value={false}
              checked={!state.formData.eInvoiceInfo?.company}
              onChange={() => {
                handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, company: false } });
              }}
            >
              <FormattedMessage id='Cá nhân' defaultMessage='Cá nhân' />
            </InputRadio>
          </div>
          <div className='ml-5'>
            <InputRadio
              id='company'
              value={true}
              checked={state.formData.eInvoiceInfo?.company}
              onChange={() => {
                handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, company: true } });
              }}
            >
              <FormattedMessage id='Công ty' defaultMessage='Công ty' />
            </InputRadio>
          </div>

        </div>
        <div className="row">
          <div className='col-sm-6 col-12 mb-3 d-flex flex-column'>
            <label className='label-input-group text-left'>
              {
                state.formData?.eInvoiceInfo?.company
                  ? <FormattedMessage id='Công ty' defaultMessage='Công ty' />
                  : <FormattedMessage id='Tên' defaultMessage='Tên' />
              }
            </label>
            <Input
              placeholder={`Nhập tên${state.formData.eInvoiceInfo?.company ? ' công ty' : ''} `}
              id={'name'}
              value={state.formData.eInvoiceInfo?.name ?? ''}
              onChange={(value) => {
                handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, name: value } });
              }}
            />
          </div>
          <div className='col-sm-6 col-12 mb-3 d-flex flex-column'>
            <label className='label-input-group text-left'>
              {intl.formatMessage({
                id: "carts.orders.Mã số thuế",
                defaultMessage: "Mã số thuế",
              })}
            </label>
            <Input
              disabled={!enableEdit}
              placeholder='Nhập mã số thuế'
              id={'number'}
              value={state.formData?.eInvoiceInfo?.taxCode ?? ''}
              onChange={(value) => {
                handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, taxCode: value } });
              }}
            />
          </div>
        </div>
        <div className='mb-3 d-flex flex-column'>
          <div className='align-items-center d-flex justify-content-between'>
            <label className='label-input-group text-left'>
              {intl.formatMessage({
                id: "carts.orders.Email",
                defaultMessage: "Email",
              })}
            </label>
            {enableEdit ?
              <div
                onClick={() => {
                  autoFillEmail.value &&
                    handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, email: autoFillEmail.value } });
                }}
                className="content-accent cursor-pointer"
              >
                {autoFillEmail.title}
              </div> : null}
          </div>
          <Input
            disabled={!enableEdit}
            placeholder='Nhập email'
            id={'email'}
            value={state.formData?.eInvoiceInfo?.email ?? ''}
            onChange={(value) => {
              handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, email: value } });
            }}
          />
        </div>
        <div className='mb-3 d-flex flex-column'>
          <div className='align-items-center d-flex justify-content-between'>
            <label className='label-input-group text-left'>
              {`Địa chỉ${state.formData?.eInvoiceInfo?.company ? ' công ty' : ''}`}
            </label>
            {enableEdit ?
              <div
                onClick={() => {
                  if (autoFillCompanyAddress?.value?.address) {
                    handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, address: autoFillCompanyAddress.value.address } });
                  }
                }}
                className="content-accent cursor-pointer"
              >
                {autoFillCompanyAddress.title}
              </div> : null}
          </div>
          <InputAddressSuggestion
            disabled={!enableEdit}
            value={state.formData?.eInvoiceInfo?.address ?? ''}
            onChange={(value) => {
              handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, address: value?.address } });
            }}
          />
        </div>
        {
          (
            <div className='d-flex mt-3'>
              <HrvComponents.InputCheckbox
                name='save'
                checked={state.formData?.eInvoiceInfo?.save}
                className=''
                onChange={(value) => {
                  handleChangeData('formData', { ...state.formData, eInvoiceInfo: { ...state.formData.eInvoiceInfo, save: value } });
                }}
              >
                {intl.formatMessage({
                  id: "carts.orders.Lưu làm thông tin xuất hóa đơn mặc định",
                  defaultMessage: "Lưu làm thông tin xuất hóa đơn mặc định",
                })}
              </HrvComponents.InputCheckbox>
            </div>
          )
        }
      </div >
    );
  }

  const renderFooter = () => {
    return (
      <>
        <Button
          title={
            intl.formatMessage({
              id: "systems.popups.Hủy",
              defaultMessage: "Hủy",
            })
          }
          light
          size="md"
          onClick={() => {
            handleChangeData('defaultFormData', { ...state.defaultFormData });
            onCancel()
          }}
          badge={HOTKEYS.ESC}
        ></Button>
        {enableEdit ? <Button
          title={intl.formatMessage({
            id: "systems.popups.Xác nhận",
            defaultMessage: "Xác nhận",
          })
          }
          status="primary"
          size="md"
          onClick={onSaveData}
          loading={loading}
          badge={HOTKEYS.F8}
        ></Button> : null}
      </>
    )
  }

  return (
    <GlobalModal
      size={'md'}
      headerTitle={modalTitle}
      isTitleCenter
      body={body()}
      onCancel={() => {
        handleChangeData('defaultFormData', { ...state.defaultFormData });
        onCancel()
      }}
      confirmTitle="Lưu"
      show={isOpen}
      setShow={(value) => props.setOpen(value ? value : null)}
      footer={renderFooter()}
    />
  );
}

export { PopupVatInfo }