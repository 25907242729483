import { Input, Loading } from "@haravan/react-components";
import { GetCustomers } from "api/payment-voucher-api";
import { Assets } from "assets";
import { useGetDataAndLoadMore } from "hooks/useGetDataAndLoadMore";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import ModalAddNewCustomer from "../modal-add-new-customer";
import "./selections.css";

const SelectionCustomer = ({
  disabled,
  placeholder,
  handleOnChange,
  value,
}) => {
  const limit = 10;
  const _resultRef = useRef(null);
  const refInput = useRef(null);
  const [openDropdown, setOpenDropDown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isCallApi, setIsCallApi] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [customerIdCreated, setCustomerIdCreated] = useState(null);
  const this_ref = useRef(null);

  const { data, isLoading, hasLoadMore, clearData } = useGetDataAndLoadMore({
    searchText,
    limit,
    isCallApi,
    isLoadingMore,
    getDataApi: GetCustomers,
  });
  const intl = useIntl();

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown]);
  function handleOutsideClick(e) {
    if (!this_ref.current.contains(e.target)) {
      setOpenDropDown(false);
      setIsCallApi(false);
      clearData();
      document.removeEventListener("click", handleOutsideClick, false);
    }
  }

  useEffect(() => {
    if (!value) {
      setSelectedCustomer(null);
      setSearchText("");
    }
  }, [value]);

  useEffect(() => {
    setIsLoadingMore(false);
    if (customerIdCreated) {
      let customerCreated = data?.find((item) => {
        return item?.key === customerIdCreated;
      });
      customerCreated && handleSelectedCustomer(customerCreated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, customerIdCreated]);

  useEffect(() => {
    if (selectedCustomer) {
      setSearchText(
        selectedCustomer?.name ||
          selectedCustomer?.value ||
          selectedCustomer?.phone ||
          "--",
      );
    }
  }, [selectedCustomer]);

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsCallApi(false);
    setOpenDropDown(false);
    clearData();
    setCustomerIdCreated(null);
    handleOnChange(customer.key);
  };

  const handleScroll = (e) => {
    if (_resultRef?.current) {
      const isScrollEnd =
        _resultRef?.current.clientHeight + _resultRef?.current.scrollTop + 50 >=
        _resultRef?.current.scrollHeight;
      if (isScrollEnd && hasLoadMore && !isLoading) {
        setIsLoadingMore(true);
      }
    }
  };

  const renderPopupContent = () => {
    return (
      <div
        id="selection-wrapper-result"
        className="selection-wrapper scrollable-y w-100 mt-3 position-absolute"
        style={{ maxHeight: "345px" }}
        onScroll={() => handleScroll()}
        ref={_resultRef}
      >
        {isLoading ? (
          <li className="list-group-item" style={{ height: "69px" }}>
            <Loading size="pico" />
          </li>
        ) : (
          <ul className="list-group">
            {
              <li
                onClick={() => {
                  setOpenCreateModal(true);
                  setOpenDropDown(false);
                  setIsCallApi(false);
                }}
                className="list-group-item pointer selection-item cursor-pointer"
              >
                <div className="d-flex align-items-center btn-create-link">
                  <p className="font-size-20 mr-2">+</p>
                  <p>
                    {intl.formatMessage({
                      id: "payment-vouchers.Tạo mới",
                      defaultMessage: "Tạo mới",
                    })}
                  </p>
                </div>
              </li>
            }
            {data.map((customer) => {
              return (
                <li
                  key={customer.key}
                  onClick={() => handleSelectedCustomer(customer)}
                  className={`list-group-item pointer selection-item ${
                    customer.key === selectedCustomer?.key
                      ? "selection-item-active"
                      : ""
                  }`}
                >
                  <div className="d-flex flex-column">
                    <div>
                      <p
                        className="text-default font-size-14 font-weight-500 text-line-1 text-break text-truncate no-wordwrap"
                        title={customer?.name}
                      >
                        {customer?.name || customer?.phone || "--"}{" "}
                      </p>
                      <p
                        className="text-primary font-size-14 text-break text-line-1"
                        title={customer.value}
                      >
                        {customer?.value || "--"}
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
            {isLoadingMore && (
              <li className="list-group-item padding-8">
                <Loading size="pico" />
              </li>
            )}
            {!data.length && !isLoading && (
              <>
                <li className="list-group-item padding-16 text-center text-black">
                  {searchText
                    ? intl.formatMessage({
                        id: "carts.orders.Không tìm thấy khách hàng",
                        defaultMessage: "Không tìm thấy khách hàng",
                      })
                    : intl.formatMessage({
                        id: "payment-vouchers.Không có dữ liệu",
                        defaultMessage: "Không có dữ liệu",
                      })}
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    );
  };

  return (
    <>
      <div ref={(node) => (this_ref.current = node)}>
        <div className={`input-group cart-input new-input-focused`}>
          <div
            onClick={() => {
              refInput.current.onSetFocus();
            }}
            id="selection-wrapper-input"
            className="hrv-next-input text-right position-relative cursor-pointer hrv-select-selection"
            style={{ height: "max-content" }}
          >
            <Input
              id="search_customer"
              type="text"
              className="text-black py-0 bg-white mr-4 selection-input w-100 border-0"
              placeholder={placeholder}
              value={searchText}
              onChange={(value) => {
                setSearchText(value);
              }}
              onFocus={() => {
                setOpenDropDown(true);
                setIsCallApi(true);
              }}
              style={{ borderBottom: "none" }}
              disabled={disabled}
              autoComplete="off"
              ref={refInput}
            />
            <span
              onClick={() => {
                setOpenDropDown(true);
                setIsCallApi(true);
              }}
              className="hrv-select-arrow cursor-pointer"
              unselectable="on"
              style={{ userSelect: "none" }}
            >
              <svg
                className="hrv-svg-inline-block hrv-svg-next-icon-rotate-90 hrv-select-arrow-icon"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
              >
                <path d="M6.47 4L5.53 4.94L8.58333 8L5.53 11.06L6.47 12L10.47 8L6.47 4Z"></path>
              </svg>
            </span>
          </div>
          {openDropdown && renderPopupContent()}
        </div>
      </div>

      <ModalAddNewCustomer
        show={openCreateModal}
        setShow={() => {
          setOpenCreateModal(false);
        }}
        handleCallApiSuccess={async (id) => {
          handleOnChange(id);
          setIsCallApi(true);
          if (id) {
            setIsCallApi(true);
            setSearchText("");
            setCustomerIdCreated(id);
          }
        }}
      />
    </>
  );
};

SelectionCustomer.propTypes = {
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  value: PropTypes.number,
};

export default SelectionCustomer;
