import { Suspense, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./routes";
import "./layouts/styles/layout.css";
import "./layouts/styles/style.css";
import "./layouts/styles/component.css";
import "./layouts/styles/material.css";
import "./screens-v2/layouts/layouts/omni-style.css";
import "@haravan/react-components/dist/style.min.css";
import { useApp } from "contexts/app";
import { IntlProvider } from "react-intl";
import messages_en from "infrastructures/locales/en-messages.json";
import messages_vi from "infrastructures/locales/vi-messages.json";
import GlobalToast from "layouts/components/global-toast";
import LayoutProvider from "contexts/Layout/layout-context";
import LoginRequiredModal from "layouts/components/login-required-modal";
import { HotkeysProvider } from "react-hotkeys-hook";
import DenyAccessModal from "layouts/components/deny-access-modal";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import NotReadyPage from "layouts/components/not-ready-page";

function App(props) {
  const {
    auth,
    app_loaded,
    app_ready,
    shop_info,
    locale,
    login_required,
    setting_required,
    isShopExpired
  } = useApp();
  const [translated_messages, setTranslatedMessages] = useState("");
  const { authenticated, user } = auth;

  useEffect(() => {
    switch (locale.language) {
      case "en":
        setTranslatedMessages(messages_en);
        break;
      case "vi":
      default:
        setTranslatedMessages(messages_vi);
        break;
    }
  }, [locale]);

  if (app_loaded) {
    if (auth.authenticated && !login_required && !app_ready)
      return <NotReadyPage />;
    return (
      <IntlProvider
        locale={locale.language}
        defaultLocale="vi"
        messages={translated_messages}
      >
        <HotkeysProvider>
          <LayoutProvider>
            <Suspense
              fallback={
                <div className="flex-stretch">
                  <LoadingCircle size="md" overlay />
                </div>
              }
            >
              <GlobalToast />
              <Routes
                authenticated={authenticated}
                app_ready={app_ready && !setting_required}
                isShopExpired={isShopExpired}
                user={user}
              />
            </Suspense>
            <LoginRequiredModal />
            <DenyAccessModal />
          </LayoutProvider>
        </HotkeysProvider>
      </IntlProvider>
    );
  } else {
    return (
      <div className="flex-stretch">
        <LoadingCircle size="md" overlay />
      </div>
    );
  }
}

export default withRouter(App);
