import { toNumber, sumBy, cloneDeep } from "lodash";
import { getDecimalValue } from "utils/Commons";
import { getLotsData } from "api/order-repository";
import {
  paymentMethodsDefault,
  productQuantityMax,
  SysAttributeKeys,
} from "../carts-v2/cart-helpers.service";
import {
  checkRefundPointSuccess,
  refundPointManually,
} from "api/common-repositoty";

export const refundQuantityTotal = (orderProducts) => {
  if (orderProducts.length === 0) return 0;
  let refundNumber = 0;
  orderProducts.forEach((orderLine) => {
    refundNumber += orderLine.refundNumber;
  });
  return refundNumber;
};

export const hasRefundItem = (orderProducts) => {
  let hasRefundItem = false;
  orderProducts.slice().forEach((product) => {
    if (product.refundNumber > 0) {
      hasRefundItem = true;
    }
  });

  return hasRefundItem;
};

export const getOrderProduct = (shop_info, orderProducts) => {
  let temp = [];

  const cloneOrderProducts = cloneDeep(orderProducts);

  cloneOrderProducts?.forEach((item) => {
    let ratio = item.ratio || 1;
    let lots = (item.lots || []).reduce((lots, l) => {
      let quantity = getDecimalValue(
        shop_info,
        l.quantity / ratio - l.returnQuantity / ratio
      );
      if (quantity > 0) {
        lots.push({ ...l, quantity, maxQty: quantity });
      }
      return lots;
    }, []);

    item.refundNumber = item.refundNumber ?? 0; // item.restockableQuantity;
    item.lots = [];
    item.lotsData = lots;
    temp.push(item);
  });
  return temp;
};

/**
 * Cập nhật số lượng sản phẩm trong giỏ
 */
export const updateOrderLineRefund = (
  shop_info,
  orderLine,
  orderLineIndex,
  restockableQuantity,
  orderProducts,
  isAutoFillLots = true
) => {
  restockableQuantity = toNumber(
    restockableQuantity >= 0 ? restockableQuantity : 0
  );

  const currentOrderLines = orderProducts.slice();
  currentOrderLines.splice(orderLineIndex, 1, {
    ...orderLine,
    refundNumber: restockableQuantity,
    lots:
      isAutoFillLots && orderLine.lotsData && orderLine.lotsData.length > 0
        ? autoFillLots(shop_info, orderLine.lotsData, restockableQuantity)
        : orderLine.lots,
  });
  return currentOrderLines;
};

export const updatePriceUserRefund = (amount, refundRedeemAmount) => {
  return toNumber(amount) - refundRedeemAmount;
};

export const autoFillLots = (shop_info, lotsData, quantity, lotNo) => {
  if (lotNo?.length) {
    lotsData = lotsData.filter((l) => l.lotNo === lotNo);
  }
  let mappedData = lotsData.reduce(
    (obj, lot, index) => {
      if (obj.quantityCanAdd === 0) return obj;
      let _lot = { ...lot };
      if (
        obj.quantityCanAdd > 0 &&
        lot.quantity > 0 &&
        index + 1 !== lotsData.length
      ) {
        if (lot.quantity >= obj.quantityCanAdd) {
          _lot.quantity = obj.quantityCanAdd;
          obj.quantityCanAdd = 0;
        } else {
          _lot.quantity = lot.quantity;
          obj.quantityCanAdd = getDecimalValue(
            shop_info,
            obj.quantityCanAdd - lot.quantity
          );
        }
        obj.lots.push(_lot);
      } else if (obj.quantityCanAdd > 0 && index + 1 === lotsData.length) {
        _lot.quantity = obj.quantityCanAdd;
        obj.lots.push(_lot);
      }
      return obj;
    },
    { lots: [], quantityCanAdd: quantity }
  );
  return mappedData.lots;
};
export function updateLots(
  lineLots = [],
  lotsData = [],
  quantity,
  shop_info,
  lotNo = null
) {
  let remainQuantity =
    quantity - lineLots?.reduce((sum, cur) => (sum += cur.quantity), 0);
  let mappedData = lineLots;
  if (remainQuantity < 0) {
    for (let i = mappedData?.length - 1; i >= 0; i--) {
      if (remainQuantity === 0) break;
      const lineLot = mappedData[i];
      if (lineLot.quantity + remainQuantity <= 0) {
        remainQuantity = remainQuantity + lineLot.quantity;
        lineLot.quantity = 0;
      } else {
        lineLot.quantity += remainQuantity;
        remainQuantity = 0;
      }
      mappedData[i] = { ...lineLot };
    }
  } else {
    if (!lotNo?.length) {
      mappedData = lineLots?.map((lineLot, index) => {
        if (remainQuantity === 0) return lineLot;
        const data = lotsData?.find((l) => l.id === lineLot.id);
        const lotQuantity = lineLot.quantity;
        if (lineLot.quantity >= data.qty) {
          if (index + 1 === lineLots?.length) {
            lineLot.quantity += remainQuantity;
            remainQuantity = 0;
          }
          return { ...lineLot };
        } else {
          if (lineLot.quantity + remainQuantity >= data.qty) {
            lineLot.quantity = data.qty;
            remainQuantity = getDecimalValue(
              shop_info,
              remainQuantity - (data.qty - lotQuantity)
            );
          } else {
            lineLot.quantity += remainQuantity;
            remainQuantity = 0;
          }
        }
        return { ...lineLot };
      });
    } else {
      let existItem = lineLots?.find((x) => x.lotNo === lotNo);
      if (!existItem) {
        let newLot = lotsData?.find((x) => x.lotNo === lotNo);
        if (newLot) {
          newLot.quantity = remainQuantity;
          const losts = [...lineLots, newLot];
          return losts;
        }
      } else {
        mappedData = lineLots?.map((lineLot, index) => {
          if (remainQuantity === 0 || lineLot.id !== existItem.id)
            return lineLot;
          const data = lotsData?.find((l) => l.id === lineLot.id);
          const lotQuantity = lineLot.quantity;
          if (lineLot.quantity >= data.qty) {
            if (index + 1 === lineLots?.length) {
              lineLot.quantity += remainQuantity;
              remainQuantity = 0;
            }
            return { ...lineLot };
          } else {
            if (lineLot.quantity + remainQuantity >= data.qty) {
              lineLot.quantity = data.qty;
              remainQuantity = getDecimalValue(
                shop_info,
                remainQuantity - (data.qty - lotQuantity)
              );
            } else {
              lineLot.quantity += remainQuantity;
              remainQuantity = 0;
            }
          }
          return { ...lineLot };
        });
      }
    }
  }
  return mappedData?.filter((lot) => lot.quantity > 0);
}
// cart list

export const cartQuantityTotal = (cartItems) => {
  if (cartItems.length === 0) return 0;
  let refundNumber = sumBy(cartItems, (item) => item.quantity);
  return refundNumber;
};

export const addItemCart = async (
  callAPI,
  src,
  item,
  quantity = 1,
  appContext
) => {
  let current_shopping_cart = src;
  item.key = [item.productId, item.productVariantsId, item.variantUnitId]
    .filter(Boolean)
    .join("-");
  let _current_cart = { ...current_shopping_cart };
  let isScanBarcode =
    appContext.shop_setting.isBarcodePatternCustom && item.lotNo?.length > 0;
  let lotsData = [];
  let index = _current_cart.lineItems?.findIndex(
    (line) => line.variantId === item.id && line.productId === item.productId
  );

  // Check lô có tồn tại hay không
  if (item.lotSupport) {
    let isHasLot =
      index > -1 && _current_cart.lineItems[index].lots?.length > 0;
    if (index > -1) lotsData = _current_cart.lineItems[index].lotsData;

    if (
      (isScanBarcode && index === -1) ||
      (index > -1 && isHasLot && lotsData?.length === 0)
    ) {
      lotsData = await getLotsData(appContext.callAPI, item, appContext);
    }
    // Nếu có lotNo mà không có tồn tại trong lotsData thì không thêm vào giỏ hàng
    if (isScanBarcode) {
      let existLot = lotsData?.find((l) => l.lotNo === item.lotNo);
      if (!existLot) {
        return null;
      }
    }
  }
  if (!lotsData) lotsData = [];

  if (index > -1 && !item?.isCustomProduct) {
    _current_cart.lineItems[index].quantity += quantity;
    if (item.lotSupport) {
      _current_cart.lineItems[index].lotsData = lotsData;
      _current_cart.lineItems[index].lots = updateLots(
        _current_cart.lineItems[index].lots,
        lotsData,
        _current_cart.lineItems[index].quantity,
        appContext.shop_info,
        item.lotNo
      );
      _current_cart.lineItems[index].fields = ["quantity"];
    }
  } else {
    // delete item.isCustomProduct;
    item.quantity = quantity;
    if (item.lotSupport) {
      item.lotsData = lotsData;
      item.lots = autoFillLots(appContext.shop_info, lotsData, 1, item.lotNo);
      let lotSelected = item.lots.find((x) => (x.lotNo = item.lotNo));
      if (lotSelected) {
        item.attributes = [
          {
            key: SysAttributeKeys.SYS_LINE_LOTS,
            value: JSON.stringify([{ id: lotSelected.id, quantity: 1 }]),
          },
        ];
      }
    }
    _current_cart.lineItems?.unshift({
      productId: item.productId,
      variantId: item.id,
      variantTitle: item.variantTitle,
      variantUnitId: item.variantUnitId,
      name: item.productTitle,
      quantity: item.quantity,
      price: item.price,
      imageUrl: item.imageUrl,
      autoFillLot: true,
      attributes: item.attributes,
    });
  }
  _current_cart.itemsOriginal = _current_cart.lineItems || [];
  _current_cart.cart_quantity_total = cartQuantityTotal(
    _current_cart.lineItems
  );
  return _current_cart;
};

export const removeItemCart = (src, id, index) => {
  let _current_cart = { ...src };
  if (_current_cart.lineItems[index].id === id) {
    _current_cart.lineItems[index].quantity = 0;
  }
  if (
    _current_cart.itemsOriginal &&
    _current_cart.itemsOriginal[index]?.id === id
  )
    _current_cart.itemsOriginal.splice(index, 1);
  _current_cart.cart_quantity_total = cartQuantityTotal(
    _current_cart.lineItems
  );
  return _current_cart;
};

export const updateItemCart = (
  shop_info,
  src,
  orderLine,
  orderLineIndex,
  quantity,
  updateFields = [],
  isAutoFillLot = true
) => {
  let _current_cart = { ...src };
  const currentOrderLines = [..._current_cart?.lineItems];

  let _quantity = toNumber(quantity);
  if (_quantity === 0) {
    _quantity = 1;
  } else if (_quantity > productQuantityMax) {
    _quantity = productQuantityMax;
    isAutoFillLot = true;
  }
  currentOrderLines.splice(orderLineIndex, 1, {
    ...orderLine,
    quantity: _quantity,
    discountAmount: orderLine.discountAmountPerLine * _quantity,
    isCalculatePromotion: true,
    lots:
      orderLine.lotSupport && isAutoFillLot
        ? updateLots(orderLine.lots, orderLine.lotsData, _quantity, shop_info)
        : orderLine.lots,
    fields: updateFields,
  });
  _current_cart.lineItems = currentOrderLines;
  _current_cart.cart_quantity_total = cartQuantityTotal(
    _current_cart.lineItems
  );
  return _current_cart;
};

//refund point
export function maxPriceUserCanRefundPoint(
  shop_loyalty,
  currentOrder,
  priceUserCanRefund
) {
  let maxPriceUserCanRefund = 0;
  if (currentOrder) {
    const {
      subTotal,
      discountAmount,
      redeemUsedAmount,
      redeemDiscountType,
      discountTypeId,
    } = currentOrder.order;
    const orderProducts = currentOrder?.lineItems;

    let subTotalOrder = subTotal;
    let discountAmountOrder = discountAmount;
    orderProducts.forEach((product) => {
      if (product.notAllowPromotion && orderProducts.length > 1) {
        subTotalOrder -= product.totalPrice;
      }
    });
    if (discountTypeId === 3) {
      discountAmountOrder = 0;
    }
    if (discountAmountOrder + redeemUsedAmount > 0) {
      orderProducts.slice().forEach((product) => {
        //[Đơn giá - [(Thành tiền/Tổng đơn hàng chưa trừ KM)/Số lượng SP]*KM]*SL trả
        let discount = 0;
        //Nếu sản phẩm không áp dụng khuyến mãi thì khi trả sản phẩm không trừ khuyến mãi
        if (!product.notAllowPromotion) {
          if (
            redeemDiscountType !== "percent" &&
            shop_loyalty?.enabled_refund_point
          ) {
            // neu thanh toan diem thi không tính vào, enable refund point
            discount = discountAmountOrder;
          } else {
            discount = discountAmountOrder + redeemUsedAmount;
          }
        }
        maxPriceUserCanRefund +=
          (product.productPrice -
            (product.totalPrice /
              (subTotalOrder === 0 ? subTotal : subTotalOrder) /
              product.quantity) *
              discount) *
          product.refundNumber;
      });

      if (
        (redeemDiscountType !== "percent" &&
          !shop_loyalty?.enabled_refund_point &&
          toNumber(maxPriceUserCanRefund.toFixed(4)) >= priceUserCanRefund) ||
        maxPriceUserCanRefund >= priceUserCanRefund
      ) {
        maxPriceUserCanRefund = priceUserCanRefund;
      }
    } else {
      orderProducts.slice().forEach((product) => {
        maxPriceUserCanRefund += product.productPrice * product.refundNumber;
      });
    }
  }
  return maxPriceUserCanRefund;
}

export async function getPointWhenRefund(callAPI, arrModelRefund) {
  try {
    const { orderId, maxPriceUserCanRefund } = arrModelRefund;
    if (maxPriceUserCanRefund === 0) {
      return { rewardRedeemAmount: 0, rewardRedeemPoint: 0 };
    }
    const result = await callAPI(
      "get",
      `/call/loy_api/v1/redeems/check_refund_point_usage/?order_id=${orderId}&amount=${maxPriceUserCanRefund}`
    );
    if (result.status === 1) {
      const { point, amount } = result.data;
      return {
        rewardRedeemAmount: toNumber(amount),
        rewardRedeemPoint: toNumber(point),
      };
    } else {
      return { rewardRedeemAmount: 0, rewardRedeemPoint: 0 };
    }
  } catch (error) {
    return { rewardRedeemAmount: 0, rewardRedeemPoint: 0 };
  }
}

/**
 * Mặc định phương thức hoàn trả tiền mặt
 */
export const resetOrderGetwayDefaultForRefundMod = (
  shopSetting,
  orderLines,
  currentRefund,
  priceSubTotal
) => {
  let amount = 0;
  if (orderLines?.length) {
    //Tổng thành tiền đơn hàng mới - Số tiền hoàn trả
    let amountTotal =
      priceSubTotal -
      (currentRefund.priceUserRefund + currentRefund.refundRedeemAmount);
    if (amountTotal < 0 && shopSetting.notAllowRefundIfLowerPrice) {
      amountTotal = 0;
    }
    amount = Math.abs(amountTotal);
  } else {
    amount = currentRefund.priceUserRefund;
  }
  const newPaymentMethodsDefault = [...paymentMethodsDefault(shopSetting)];
  newPaymentMethodsDefault[0].amount = Number(amount).toFixed(4);
  return newPaymentMethodsDefault;
};

export async function checkRefundedPoint(
  count,
  refOrderId,
  refundId,
  callback,
  appContext
) {
  if (count === 9) {
    let pointModel = {
      amount: this.maxPriceUserCanRefundPoint,
      refund_id: refundId,
      order_id: refOrderId,
    };
    await refundPointManually(appContext, pointModel);
  }

  if (count < 15) {
    await SleepTask(1000);
    const success = await checkRefundPointSuccess(
      appContext,
      refOrderId,
      refundId
    );
    if (success === 1) {
      return callback(true);
    } else {
      await checkRefundedPoint(
        ++count,
        refOrderId,
        refundId,
        callback,
        appContext
      );
    }
  } else {
    return callback(false);
  }
}

const SleepTask = async (parTime) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve("result");
    }, parTime)
  );
};
