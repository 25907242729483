import React, {forwardRef} from "react";
import PropTypes from 'prop-types';

import Icon from "component-v2/icons/icons";



export const ProductSearchBox = forwardRef((props, ref) => {
  const {disabled, keyword, onChange, onFocus, onBlur, isFocused, onClearSearch} = props;

  return (
    <div className='product-info--search w-100 '>
      <div className="input-group h-100">
        <div className="input-group-prepend">
          <span className="h-100 input-group-text  icon-secondary product-info--search-icon">
            <Icon name={"search"} color="var(--contentSecondary)" />
          </span>
        </div>
        <input
          id="product_info_search"
          type="text"
          autoComplete="off"
          ref={ref}
          disabled={disabled}
          className='form-control h-100 w-75 product-info--search-input '
          placeholder="Tìm sản phẩm hoặc nhập barcode"
          value={keyword}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <div className="input-group-prepend" style={{marginLeft: "1px"}} >
          {keyword.length > 0 &&
            <span className="h-100 input-group-text  icon-secondary product-info--search-icon" onClick={onClearSearch}>
              <Icon name={"close"} color="var(--contentSecondary)" size="sm" />
            </span>
          }
        </div>

      </div>
    </div>
  );
});


ProductSearchBox.propTypes = {
  isFocused: PropTypes.bool,
  keyword: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClearSearch: PropTypes.func,
};

