import React, { useState } from "react";
import "./style.css";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";

export const UnitSelection2 = ({
  options = [],
  selectedOption,
  onChange = (unit) => {},
  isDisabled = false,
  setMouseOverResult,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);

  const onVisibleChange = (visible) => {
    setShowFilter(visible);
    // LayoutStore.setDisableOutsideTag(visible);
  };

  return selectedOption ? (
    (options && options.length === 1) || isDisabled ? (
      <span className="no-wrap">{selectedOption.unit}</span>
    ) : (
      <SelectionCustom
        options={options}
        idField="id"
        valueField="unit"
        disabled={isDisabled}
        style={{ lineHeight: "1.6rem", width: "100%" }}
        placeholder="Chọn đơn vị"
        value={selectedOption.id}
        onChange={(value) => {
          const unit = options.find((u) => u.id === value);
          onChange(unit);
        }}
        title={selectedOption.unit}
      >
        {options.map((unit, index) => {
          return (
            <SelectionCustom.Option key={`unit-${index}`} value={unit.id}>
              {unit.unit}
            </SelectionCustom.Option>
          );
        })}
      </SelectionCustom>
    )
  ) : null;
};
