import React from "react";
import InputDecimal from "./InputDecimal";
import PropTypes from "prop-types";
import Icon from "component-v2/icons/icons";

function InputQuantity({
  enableSubtract,
  enableAdd,
  value,
  disabled,
  onChange,
  onSubtract,
  onAdd,
  wrapperClassName,
  className,
  min,
  max,
  onBlur,
  onFocus,
}) {
  return (
    <div
      className={`d-flex flex-nowrap justify-content-center align-items-center ${wrapperClassName}`}
    >
      <span onClick={onSubtract} className="cursor-pointer">
        {/* <span className={`icon-w-13 ${enableSubtract ? 'icon-default' : 'icon-secondary'}`}>
          {Assets.icons.remove}
        </span> */}
        <Icon
          name="remove"
          color={enableSubtract ? "" : "var(--coolgray400)"}
        />
      </span>
      <InputDecimal
        className={`hrv-next-input quantity_cart_item p-2 mx-2 text-center ${className}`}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        min={min}
        max={max}
        onBlur={onBlur}
        onFocus={onFocus}
        maxWidth={90}
      />
      <span onClick={onAdd} className="cursor-pointer">
        {/* <span className={`icon-w-13 ${enableAdd ? 'icon-default' : 'icon-secondary'}`}>
          {Assets.icons.plus}
        </span> */}
        <Icon name="add" color={enableAdd ? "" : "var(--coolgray400)"} />
      </span>
    </div>
  );
}

InputQuantity.defaultProps = {
  enableSubtract: true,
  enableAdd: true,
  onChange: () => {},
  onSubtract: () => {},
  onAdd: () => {},
  onBlur: () => {},
};

InputQuantity.propTypes = {
  enableSubtract: PropTypes.bool,
  enableAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onSubtract: PropTypes.func,
  onAdd: PropTypes.func,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default InputQuantity;
