import { useState } from "react";
import { Icon, Tooltip } from "@haravan/react-components";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import LotDatePopover from "../LotDatePopover";
import { useIntl } from "react-intl";
import {
  DateTimeShowType,
  toFormattedDate,
  toFormattedNumber,
} from "infrastructures/format-helpers";

const LotDateBadge = ({
  className = "",
  data = {},
  allowDelete = true,
  onDelete = () => {},
  onUpdate = () => {},
  showReturnQty = false,
  isRefund = false,
  ratio,
}) => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);

  // type : 'enter' | 'leave'
  const handleHover = (type) => (e) => {
    if (type === "enter") {
      setVisible(true);
    } else setVisible(false);
  };

  const expireDate = intl.formatMessage({
    id: "carts.orders.Hạn sử dụng đến",
    defaultMessage: "Hạn sử dụng đến",
  });

  const hoverText = ` ${expireDate} ${toFormattedDate(
    data.lotExpireDate,
    DateTimeShowType.date,
  )}`;

  let isShowReturnQty = showReturnQty && data.returnQuantity > 0;
  const quantity = !data?.quantity || !ratio ? 0 : data.quantity / ratio;
  const returnQuantity = data?.returnQuantity / ratio || 0;
  return (
    <LotDatePopover
      trigger={allowDelete ? "click" : ""}
      onUpdate={onUpdate}
      data={data}
      isRefund={isRefund}
    >
      <Tooltip
        placement="top"
        title={hoverText}
        visible={hoverText ? visible : false}
      >
        <div
          onMouseEnter={handleHover("enter")}
          onMouseLeave={handleHover("leave")}
          className={`${className} white-space-nowrap d-inline-block mr-2`}
        >
          {/* <Badges
            className={`border status-tag mb-2 ${isShowReturnQty ? 'return-quantity-badge' : 'status-tag--draft'} `}
            status='default'
            size='large'
          >
          </Badges> */}

          <Badge size="M" status="default" light={true}>
            <span className="d-flex align-items-center ">
              <div className="cursor-pointer content-secondary font-size-12 font-height-16">
                {`${data.lotNo} x ${toFormattedNumber(quantity)}${
                  isShowReturnQty
                    ? ` (${intl.formatMessage({
                        id: "carts.refunds.nhập kho",
                        defaultMessage: "nhập kho",
                      })} ${toFormattedNumber(returnQuantity)})`
                    : ""
                }`}
              </div>
              {allowDelete && (
                <Icon
                  onClick={(e) => {
                    onDelete(data);
                    e.stopPropagation();
                  }}
                  className="cursor-pointer ml-2 content-secondary"
                  type="times"
                  theme="regular"
                />
              )}
            </span>
          </Badge>
        </div>
      </Tooltip>
    </LotDatePopover>
  );
};

export default LotDateBadge;
