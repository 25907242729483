import { Input, Tooltip } from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import Logo from "component-v2/logos/logos";
import { useApp } from "contexts/app";
import { sumBy, toNumber } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useRefundContext } from "screens-v2/refunds-v2";
import { resetOrderGetwayDefaultForRefundMod } from "screens-v2/refunds-v2/refund.service";
import { InputNumber } from "../components/InputNumber";
import * as HrvComponents from "@haravan/react-components";
import { PaymentMethodItem } from "./paymentMethodItem";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import _ from "lodash";
import { SYSTEM_GUEST_EMAIL } from "infrastructures/utils";
const AMOUNT_MAX = 50000000000;

export const PaymentMethods = ({ totalMoneyPaidByCustomer = 0 }) => {
  const { auth, shop_setting } = useApp();
  const intl = useIntl();
  const {
    current_shopping_cart,
    updateCurrentCart,
    current_refund,
    priceSubTotal,
    updateCurrentRefund,
    finalPrice,
    methodDefaults,
  } = useRefundContext();
  const refPaymentMethods = useRef(null);

  useEffect(() => {
    //set default payment methods
    if (current_shopping_cart?.payment_methods?.length) {
      const paymentMethodEnabled =
        current_shopping_cart?.payment_methods.filter(
          (method) => method.enable,
        );
      if (
        current_refund &&
        paymentMethodEnabled?.length &&
        paymentMethodEnabled?.length <= 1
        // && paymentMethodEnabled[0].type === "cash"
      ) {
        if (paymentMethodEnabled[0].type === "cash") {
          const paymentMethodsDefault = resetOrderGetwayDefaultForRefundMod(
            shop_setting,
            current_shopping_cart?.lineItems,
            current_refund,
            priceSubTotal({
              totalPrice: current_shopping_cart?.summaries?.total_payment,
              redeemUsedAmount: 0,
            }),
          );
          updateCurrentCart({ payment_methods: paymentMethodsDefault });
        } else if (
          !payment_methods.find(
            (method) => method.type === "cod" && method.enable,
          )
        ) {
          let currentPaymentMethods = _.cloneDeep(methodDefaults);
          currentPaymentMethods[0].amount = Math.abs(finalPrice());
          updateCurrentCart({ payment_methods: currentPaymentMethods });
        } else {
          const currentPaymentMethods = _.cloneDeep(
            current_shopping_cart.payment_methods,
          );
          let currentPaymentMethodsIndexModify =
            currentPaymentMethods.findIndex((obj) => {
              return obj.enable === true;
            });
          currentPaymentMethods[currentPaymentMethodsIndexModify].amount =
            current_refund.priceUserRefund;

          updateCurrentCart({ payment_methods: currentPaymentMethods });
        }
      }
    }
  }, [current_shopping_cart?.summaries?.total_payment, current_refund]);

  const payment_methods_active = useMemo(() => {
    return current_shopping_cart?.payment_methods?.filter(
      (method) => method.enable,
    );
  }, [current_shopping_cart?.payment_methods]);
  const isCodActive = useMemo(() => {
    return !!current_shopping_cart?.payment_methods?.find(
      (method) => method.type === "cod" && method.enable,
    );
  }, [current_shopping_cart?.payment_methods]);

  const nextMethodIndex = useMemo(() => {
    const hasMethodOnlyActivated = payment_methods_active?.some(
      (m) => m.hasOnly,
    );
    return current_shopping_cart?.payment_methods?.findIndex((current) =>
      hasMethodOnlyActivated
        ? !current.enable &&
          current.type !== "cod" &&
          !current.hasOnly &&
          !current.isHidden
        : !current.enable && current.type !== "cod" && !current.isHidden,
    );
  }, [payment_methods_active, current_shopping_cart?.payment_methods]);
  if (!current_shopping_cart?.payment_methods) return null;
  const { payment_methods } = current_shopping_cart;

  function handleRemovePaymentMethod(method) {
    updateCurrentCart({
      payment_methods: [...payment_methods].map((m) => ({
        ...m,
        enable: m.type === method.type ? false : m.enable,
        amount: m.type === method.type ? 0 : m.amount,
        index: m.enable && m.index > method.index ? m.index - 1 : m.index,
      })),
    });
  }

  function handleAddPaymentMethod() {
    const currentPaymentMethods = [...payment_methods];
    if (nextMethodIndex !== -1) {
      const nextMethod = currentPaymentMethods[nextMethodIndex];
      const totalMoneyPaidByCustomer = sumBy(
        currentPaymentMethods.filter((method) => method.enable),
        (method) => method.amount,
      );

      let amount =
        totalMoneyPaidByCustomer <
        current_shopping_cart?.summaries?.subtotal -
          (current_refund.priceUserRefund + current_refund.refundRedeemAmount)
          ? current_shopping_cart?.summaries?.subtotal -
            (current_refund.priceUserRefund +
              current_refund.refundRedeemAmount) -
            totalMoneyPaidByCustomer
          : 0;

      if (amount < 0 && shop_setting.notAllowRefundIfLowerPrice) {
        amount = 0;
      }
      currentPaymentMethods[nextMethodIndex] = {
        ...nextMethod,
        enable: true,
        amount: +Number(amount)?.toFixed(4),
        index: payment_methods_active.length,
      };
      updateCurrentCart({ payment_methods: currentPaymentMethods });
    }
  }

  const totalFinalPrice = toNumber(
    Math.abs(
      current_refund?.priceUserRefund +
        current_refund?.refundRedeemAmount -
        current_shopping_cart?.summaries.total_payment,
    ).toFixed(4),
  );

  // if (finalPrice() === 0) {
  // return <div className="d-flex flex-column l-pt-8 l-pb-4 d-flex justify-content-between">
  //   <div className="d-flex flex-row justify-content-between py-2">
  //     <p className={`font-weight-semibold`}>Tiền thừa trả khách</p>
  //     <p>0</p>
  //   </div>
  // </div>
  // }

  return (
    <div className="payment-methods-wrapper" ref={refPaymentMethods}>
      {!current_refund?.refund_quantity_total <= 0 &&
        payment_methods_active.length > 0 && (
          <React.Fragment>
            <div className="l-pt-8 l-pb-4">
              {finalPrice() > 0 ||
              (finalPrice() === 0 &&
                !current_shopping_cart?.lineItems?.length) ? (
                <p>Phương thức hoàn trả</p>
              ) : (
                <p>Khách thanh toán</p>
              )}
            </div>
            <div className="">
              {payment_methods_active.map((method, i) => {
                return (
                  <PaymentMethodItem
                    key={i}
                    method={method}
                    handleRemovePaymentMethod={() =>
                      handleRemovePaymentMethod(method)
                    }
                    width={refPaymentMethods.current?.offsetWidth}
                  />
                );
              })}
            </div>
          </React.Fragment>
        )}
      {current_refund?.refund_quantity_total > 0 &&
        !isCodActive &&
        nextMethodIndex !== -1 &&
        (finalPrice() > 0 ||
        (finalPrice() === 0 &&
          !current_shopping_cart?.lineItems?.length) ? null : (
          <div className="l-pt-8 l-pb-8">
            <span
              className="d-inline-flex align-items-center cursor-pointer content-accent"
              onClick={() => handleAddPaymentMethod()}
            >
              <Icon
                name="add"
                color="var(--contentAccent)"
                className="l-mr-4"
              />
              <span>Thêm phương thức</span>
            </span>
          </div>
        ))}
      {current_refund?.refund_quantity_total > 0 && (
        <div className="mt-2">
          <hr className="my-0 border-dotted" />
          {finalPrice() > 0 ||
          (finalPrice() === 0 && !current_shopping_cart?.lineItems?.length) ? (
            <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
              <p className={`font-weight-semibold`}>Tiền trả khách</p>
              <p className={`font-weight-semibold`}>
                {toFormattedCurrency(finalPrice() || 0)}
              </p>
            </div>
          ) : finalPrice() === 0 ? (
            <div className="d-flex flex-column l-pt-8 l-pb-4 d-flex justify-content-between">
              <div className="d-flex flex-row justify-content-between py-2">
                <p className={`font-weight-semibold`}>Tiền thừa trả khách</p>
                <p>0</p>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column l-pt-8 l-pb-4 d-flex justify-content-between">
              <div className="d-flex flex-row justify-content-between py-2">
                <p className={`font-weight-semibold`}>Tiền khách đưa</p>
                <p className={`font-weight-semibold`}>
                  {toFormattedCurrency(
                    Math.abs(toNumber(totalMoneyPaidByCustomer)),
                  )}
                </p>
              </div>
              <div className="d-flex flex-row justify-content-between py-2">
                <p className={`font-weight-semibold`}>
                  {toNumber(totalMoneyPaidByCustomer) -
                    Math.abs(toNumber(finalPrice())) >
                  0
                    ? "Tiền thừa trả khách"
                    : !current_shopping_cart.customer ||
                        current_shopping_cart.customer?.email ===
                          SYSTEM_GUEST_EMAIL
                      ? "Tiền khách cần trả thêm"
                      : "Tính vào công nợ"}
                </p>
                <p className={`font-weight-semibold`}>
                  {toFormattedCurrency(
                    Math.abs(
                      Math.round(
                        toNumber(totalMoneyPaidByCustomer) - totalFinalPrice,
                      ),
                    ),
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
