import { useState } from "react";
import PropTypes from "prop-types";
import {
  LayoutCardSection,
  LayoutCardSectionSection,
} from "screens-v2/layouts/layouts/omni-layout-card";
import {
  CashReceiptFieldModels,
  conditionReasonToRenderSelect,
  EnumAccountTypeMapping,
  EnumIdObjectiveMapping,
  InventoryModeEnum,
  objectiveList,
  PaymentVoucherFieldModels,
  PaymentVoucherFieldType,
} from "models/payment-voucher-models";
import { InputCheckbox, Input } from "@haravan/react-components";
import { CurrencyUtils } from "utils";
import { RecordReportCheckbox } from "./record-report-checkbox";
import RenderModal from "./render-modal";
import {
  MAX_AMOUNT,
  paymentVoucherCreateModal,
} from "./payment-voucher-constant";
import { useEffect } from "react";
import {
  GetAssignedLocations,
  GetCashFlowBankAccount,
  GetCashFlowReason,
  GetObject,
} from "api/payment-voucher-api";
import { maxBy } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import "../index.css";
import { useApp } from "contexts/app";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";

const PaymentInformation = ({ formData, onChangeData, isCashReceipt }) => {
  const appContext = useApp();
  const intl = useIntl();

  const conditionObjectiveList = objectiveList.filter((obj) =>
    appContext.shop_info.inventory_method !== InventoryModeEnum.advance
      ? obj.id !== EnumIdObjectiveMapping.Supplier
      : true,
  );

  const [openModal, setOpenModal] = useState(null);
  const [isEditModal, setIsEditModal] = useState(null);
  const [objectiveName, setObjectiveName] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [objectives, setObjectives] = useState(conditionObjectiveList);
  const [locations, setLocations] = useState([]);
  const [objectOthers, setObjectOthers] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [fieldSelectedCreate, setFieldSelectedCreate] = useState(null);

  const PaymentVoucherFields = isCashReceipt
    ? CashReceiptFieldModels({
        formData,
        bankList,
        reasons,
        objectives,
        locations,
        objectOthers,
      })
    : PaymentVoucherFieldModels({
        formData,
        bankList,
        reasons,
        objectives,
        locations,
        objectOthers,
      });

  useEffect(() => {
    setObjectives(conditionObjectiveList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData.accountTypeId === EnumAccountTypeMapping.Bank) {
      const receiptReasonSplice = reasons.filter(
        (rec) =>
          rec?.name !== conditionReasonToRenderSelect.InternalTransferMoney,
      );
      setReasons(receiptReasonSplice);
      getBankList();
    } else {
      getReason();
    }
    onChangeData({
      objectId: null,
      debt: false,
      accountId: 0,
      subTypeId: null,
      objectTypeId: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.accountTypeId]);

  useEffect(() => {
    if (formData.subTypeId) {
      const filtered = conditionObjectiveList.filter((objective) => {
        if (
          formData.subTypeId ===
          reasons.find(
            (reason) =>
              reason.name ===
              conditionReasonToRenderSelect.InternalTransferMoney,
          )?.id
        ) {
          return objective.id === EnumIdObjectiveMapping.Cash;
        }
        if (
          formData.subTypeId ===
          reasons.find(
            (reason) =>
              reason.name === conditionReasonToRenderSelect.WithdrawMoney,
          )?.id
        ) {
          if (formData.accountTypeId === EnumAccountTypeMapping.Cash) {
            return objective.id === EnumIdObjectiveMapping.Bank;
          }
          return [
            EnumIdObjectiveMapping.Cash,
            EnumIdObjectiveMapping.Bank,
          ].includes(objective.id);
        }
        return [
          EnumIdObjectiveMapping.Customer,
          EnumIdObjectiveMapping.Supplier,
          EnumIdObjectiveMapping.Other,
        ].includes(objective.id);
      });
      onChangeData({ objectTypeId: null });
      setObjectives(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.subTypeId]);

  useEffect(() => {
    if (objectiveName) setObjectiveName(null);
    onChangeData({ objectId: null, debt: false });
    if (formData.objectTypeId === EnumIdObjectiveMapping.Other) {
      getObject();
    }
    if (formData.objectTypeId === EnumIdObjectiveMapping.Bank) {
      getBankList();
      onChangeData({ paymentMethod: 2 });
    } else {
      onChangeData({ paymentMethod: 1 });
    }
    if (formData.objectTypeId === EnumAccountTypeMapping.Cash) {
      getLocations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.objectTypeId]);

  useEffect(() => {
    if (objectiveName && !formData?.amount && objectiveName?.debt > 0) {
      onChangeData({ amount: objectiveName.debt });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveName]);

  const getLocations = async () => {
    const response = await GetAssignedLocations(appContext, "holdstock");
    if (response?.data?.length) {
      setLocations(response.data);
    }
  };

  const getBankList = async (isCreated = false) => {
    const response = await GetCashFlowBankAccount(appContext);
    if (response?.data?.length) {
      setBankList(response.data);
      if (isCreated) {
        const newestId = maxBy(response.data, "id")["id"];
        onChangeData({ [fieldSelectedCreate]: newestId });
      }
    }
  };

  const getReason = async (isCreated = false) => {
    const response = await GetCashFlowReason(
      appContext,
      isCashReceipt ? "cash_receipt" : "cash_payment",
    );
    if (response?.data?.length) {
      let data = response.data.filter(
        (item) => item.id !== 18 && item.id !== 19,
      );

      setReasons(data);
      if (isCreated) {
        const dataCreated = data.find(
          (item) => item.id === maxBy(response.data, "id")["id"],
        );
        onChangeData({
          subTypeId: dataCreated?.id,
          pnL: dataCreated?.pnL || formData?.pnL,
        });
      }
    }
  };

  const getObject = async (isCreated = false) => {
    const response = await GetObject(appContext);
    if (response?.data?.length) {
      setObjectOthers(response.data);
      if (isCreated) {
        const dataCreated = response.data.find(
          (item) => item.id === maxBy(response.data, "id")["id"],
        );
        onChangeData({ objectId: dataCreated?.id });
      }
    }
  };

  const handleToggleModal = (type) => {
    setFieldSelectedCreate(type);
    switch (type) {
      case "accountId":
        setOpenModal(paymentVoucherCreateModal.ACCOUNT);
        break;
      case "subTypeId":
        setOpenModal(paymentVoucherCreateModal.REASON);
        break;
      case "objectId":
        if ([EnumIdObjectiveMapping.Bank].includes(formData["objectTypeId"])) {
          setOpenModal(paymentVoucherCreateModal.ACCOUNT);
        } else if (
          [
            EnumIdObjectiveMapping.Other,
            EnumIdObjectiveMapping.Customer,
          ].includes(formData["objectTypeId"])
        ) {
          setOpenModal(paymentVoucherCreateModal.OTHER);
        }
        break;
      default:
        break;
    }
  };

  const handleInputChanged = (value, name) => {
    value = value.toString();
    if (value.length === 0) value = 0;
    if (value[0] === "0" && value.length > 0) {
      value = value.substring(1);
    }
    let num = CurrencyUtils.numberWithCommasActive(value);
    if (Number(num) > MAX_AMOUNT) num = MAX_AMOUNT;
    onChangeData({ [name]: num });
  };
  const notFoundContent = intl.formatMessage({
    id: "carts.orders.Không tìm thấy",
    defaultMessage: "Không tìm thấy",
  });
  return (
    <>
      <LayoutCardSection>
        <p className="font-weight-semibold font-size-16 pb-4">
          <FormattedMessage
            id={`payment-vouchers.Thông tin phiếu ${
              isCashReceipt ? "thu" : "chi"
            }`}
            defaultMessage={`Thông tin phiếu ${isCashReceipt ? "thu" : "chi"}`}
          />
        </p>
        <LayoutCardSectionSection className={"row"}>
          {Object.keys(PaymentVoucherFields).map((fieldName) => {
            let objectField = PaymentVoucherFields[fieldName];
            const {
              placeHolder,
              name,
              options,
              hasCreateNew,
              showSearch,
              disabled,
              hasEdit,
              isHidden,
              hasPullCol,
              type,
              hasCustomRender,
            } = objectField;
            const newOptions = hasCreateNew
              ? [{ id: 0, name: "Tạo mới", isSystem: true }, ...options]
              : options;
            if (isHidden && hasPullCol)
              return <div key={fieldName} className="col-12 col-md-6 m-0" />;
            if (isHidden) return null;
            else if (type === PaymentVoucherFieldType.selection) {
              const optionHasEdit =
                hasEdit &&
                !!formData[name] &&
                !options.find((item) => item.id === formData[name])?.isSystem;
              const debtAmount = objectiveName?.debt || 0;
              return (
                <div className="col-12 col-md-6" key={fieldName}>
                  <label className="pb-2 w-100">
                    <div className="d-flex justify-content-between">
                      <p>
                        {intl.formatMessage({
                          id: `payment-vouchers.${fieldName}`,
                          defaultMessage: fieldName,
                        })}
                      </p>
                      {optionHasEdit ? (
                        <p
                          className="ml-2 cursor-pointer text-primary"
                          onClick={() => {
                            handleToggleModal(name);
                            setIsEditModal(name);
                          }}
                        >
                          Cập nhật
                        </p>
                      ) : null}
                    </div>
                  </label>
                  {hasCustomRender?.() ? (
                    <>
                      {hasCustomRender({
                        placeholder:
                          placeHolder !== ""
                            ? intl.formatMessage({
                                id: `payment-vouchers.${placeHolder}`,
                                defaultMessage: placeHolder,
                              })
                            : "",
                        disabled: disabled,
                        handleOnChange: (data) => {
                          setObjectiveName(data);
                          onChangeData({ [name]: data });
                        },
                        value: formData[name],
                        handleToggleModal: () => handleToggleModal(name),
                        optionCreateNew: hasCreateNew ? (
                          <SelectionCustom.Option
                            className="border-bottom"
                            value={0}
                          >
                            <div className="d-flex align-items-center btn-create-link">
                              <span>
                                +{" "}
                                {intl.formatMessage({
                                  id: "payment-vouchers.Tạo mới",
                                  defaultMessage: "Tạo mới",
                                })}
                              </span>
                            </div>
                          </SelectionCustom.Option>
                        ) : null,
                      })}
                    </>
                  ) : (
                    <div className="">
                      <SelectionCustom
                        options={newOptions}
                        idField="id"
                        valueField="name"
                        defaultActiveFirstOption={false}
                        showSearch={showSearch}
                        placeholder={
                          placeHolder !== ""
                            ? intl.formatMessage({
                                id: `payment-vouchers.${placeHolder}`,
                                defaultMessage: placeHolder,
                              })
                            : ""
                        }
                        value={
                          formData[name] ||
                          intl.formatMessage({
                            id: `payment-vouchers.${placeHolder}`,
                            defaultMessage: placeHolder,
                          })
                        }
                        notFoundContent={notFoundContent}
                        onChange={(value) => {
                          if (value) {
                            if (name === "subTypeId") {
                              const dataSelected = reasons.find(
                                (item) => item.id === value,
                              );
                              onChangeData({
                                [name]: value,
                                pnL: dataSelected?.pnL,
                              });
                            } else {
                              onChangeData({ [name]: value });
                            }
                          } else if (hasCreateNew) {
                            // handleToggleModal(name);
                            handleToggleModal(name);
                          }
                        }}
                        className={`cart-input`}
                        disabled={disabled}
                      >
                        {newOptions?.map((option) => {
                          if (option.id === 0 && option.name === "Tạo mới") {
                            return (
                              <SelectionCustom.Option
                                className="border-bottom"
                                value={option.id}
                                key={option.id}
                              >
                                <div className="d-flex align-items-center btn-create-link">
                                  <span>
                                    +{" "}
                                    {intl.formatMessage({
                                      id: "payment-vouchers.Tạo mới",
                                      defaultMessage: "Tạo mới",
                                    })}
                                  </span>
                                </div>
                              </SelectionCustom.Option>
                            );
                          }
                          if (name === "accountId") {
                            return (
                              <SelectionCustom.Option
                                className="border-bottom"
                                value={option.id}
                                key={option.id}
                              >
                                <div className="d-flex flex-column">
                                  {intl.formatMessage({
                                    id: `payment-vouchers.${option.name}`,
                                    defaultMessage: option.name,
                                  })}
                                  <span className="text-secondary text--bank-code-custom hidden-selection">{`${
                                    option.bankCode ? `${option.bankCode}` : ""
                                  } ${
                                    option.bankAccNumber
                                      ? `- ${option.bankAccNumber}`
                                      : ""
                                  }`}</span>
                                </div>
                              </SelectionCustom.Option>
                            );
                          }

                          if (
                            option.id === 1 ||
                            option.name === "Chuyển khoản" ||
                            (option?.isSystem && option.isSystem === true) ||
                            option.isSearch !== undefined
                          ) {
                            return (
                              <SelectionCustom.Option
                                className="border-bottom"
                                value={option.id}
                                key={option.id}
                              >
                                {intl.formatMessage({
                                  id: `payment-vouchers.${option.name}`,
                                  defaultMessage: option.name,
                                })}
                              </SelectionCustom.Option>
                            );
                          } else {
                            return (
                              <SelectionCustom.Option
                                className="border-bottom"
                                value={option.id}
                                key={option.id}
                              >
                                {option.name}
                              </SelectionCustom.Option>
                            );
                          }
                        })}
                      </SelectionCustom>
                    </div>
                  )}
                  {name === "objectId" &&
                  formData.objectTypeId === EnumIdObjectiveMapping.Supplier &&
                  formData?.objectId &&
                  debtAmount ? (
                    debtAmount > 0 ? (
                      <div>
                        <FormattedMessage
                          id="payment-vouchers.Nợ phải trả"
                          defaultMessage="Nợ phải trả"
                        />{" "}
                        : <CurrencyShow value={debtAmount} />
                      </div>
                    ) : (
                      <div>
                        <FormattedMessage
                          id="payment-vouchers.Nợ phải thu"
                          defaultMessage="Nợ phải thu"
                        />{" "}
                        : <CurrencyShow value={debtAmount} />
                      </div>
                    )
                  ) : null}
                </div>
              );
            } else if (type === PaymentVoucherFieldType.number) {
              return (
                <div className="col-12 col-md-6" key={fieldName}>
                  <label className="pb-2">
                    {intl.formatMessage({
                      id: `payment-vouchers.${fieldName}`,
                      defaultMessage: fieldName,
                    })}
                  </label>
                  <div
                    className={`d-flex align-items-center cart-input new-input-focused`}
                  >
                    <Input
                      className="text-right"
                      placeholder={intl.formatMessage({
                        id: `payment-vouchers.${placeHolder}`,
                        defaultMessage: placeHolder,
                      })}
                      value={CurrencyUtils.formatMoney(
                        Math.round(formData[name]),
                      )}
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      onChange={(value) => handleInputChanged(value, name)}
                      onFocus={(value, e) => {
                        e.target.select();
                      }}
                      type="text"
                    />
                  </div>
                </div>
              );
            }
            return null;
          })}
          {[
            EnumIdObjectiveMapping.Supplier,
            EnumIdObjectiveMapping.Carrier,
            EnumIdObjectiveMapping.Customer,
          ].includes(formData.objectTypeId) && (
            <div className="col-12">
              <InputCheckbox
                checked={formData.debt}
                onChange={(check) => {
                  onChangeData({ debt: check });
                }}
              >
                {intl.formatMessage({
                  id: "payment-vouchers.Ghi nhận vào công nợ",
                  defaultMessage: "Ghi nhận vào công nợ",
                })}
              </InputCheckbox>
            </div>
          )}
          <div className="col-12">
            <RecordReportCheckbox
              isCashReceipt={isCashReceipt}
              checked={formData.pnL}
              disabled={
                !formData?.subTypeId ||
                [
                  conditionReasonToRenderSelect.InternalTransferMoney,
                  conditionReasonToRenderSelect.WithdrawMoney,
                ].includes(
                  reasons.find((obj) => obj?.id === formData?.subTypeId)?.name,
                )
              }
              handleOnChange={(check) => {
                onChangeData({ pnL: check });
              }}
            />
          </div>
        </LayoutCardSectionSection>
      </LayoutCardSection>
      <RenderModal
        openModal={openModal}
        bankList={bankList}
        reasons={reasons}
        objectOthers={objectOthers}
        closeModal={() => {
          setOpenModal(null);
          setIsEditModal(null);
        }}
        isCashReceipt={isCashReceipt}
        formData={formData}
        isEditModal={isEditModal}
        handleCallApiSuccess={async (value) => {
          switch (openModal) {
            case paymentVoucherCreateModal.ACCOUNT:
              await getBankList(value);
              break;
            case paymentVoucherCreateModal.REASON:
              await getReason(value);
              break;
            case paymentVoucherCreateModal.OTHER:
              await getObject(value);
              break;
            default:
              break;
          }
        }}
      />
    </>
  );
};

PaymentInformation.prropTypes = {
  PaymentVoucherFields: PropTypes.object,
  formData: PropTypes.object,
  objectiveName: PropTypes.object,
  setObjectiveName: PropTypes.func,
  toggleModalEdit: PropTypes.func,
  onChangeData: PropTypes.func,
  reasons: PropTypes.array,
  handleInputChanged: PropTypes.func,
  setIsEditModal: PropTypes.func,
  isEditModal: PropTypes.string,
  objectOthers: PropTypes.array,
  getReason: PropTypes.func,
  getObject: PropTypes.func,
};

export default PaymentInformation;
