import v5 from "uuid";
import { Fragment, memo, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "./global-modal.css";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { useHotkeysContext } from "react-hotkeys-hook";

const MODAL_COVER_ID = "root-modal-cover";
export default memo(function GlobalSimpleModal({
  id, // unique id
  children,
  size, // 'sm', 'md', 'lg', 'xl'
  fixHeight = false,
  body,
  show = false,
  setShow,
  className,
  useEscapeKey = true,
  closeCallback,
}) {
  const { enableScope, disableScope } = useHotkeysContext();
  let modalCover = document.getElementById(MODAL_COVER_ID);
  if (!modalCover) {
    modalCover = document.createElement("div");
    modalCover.setAttribute("id", MODAL_COVER_ID);
    document.getElementById("root").appendChild(modalCover);
  }
  const thisKey = useRef();
  const modalEl = useRef();
  const modalRef = null;
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.ENTER],
    scopes: HOTKEY_SCOPES.MODAL,
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ENTER) {
        return;
      }
      closeCallback && closeCallback();
      enableScope(HOTKEY_SCOPES.CART);
      setShow(false);
    },
    enabled: show && useEscapeKey,
  });

  useEffect(() => {
    let key = id;
    if (!key) {
      key = v5();
    }
    thisKey.current = key;
    return () => {
      if (thisKey.current) {
        const ex = document.getElementById(thisKey.current);
        if (ex) ex.outerHTML = "";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (show) {
      enableScope(HOTKEY_SCOPES.MODAL);
      disableScope(HOTKEY_SCOPES.CART);
      modalEl.current = document.createElement("div");
      modalEl.current.setAttribute("id", thisKey.current);
      modalCover.appendChild(modalEl.current);
    } else {
      const ex = document.getElementById(thisKey.current);
      if (ex) ex.outerHTML = "";
      enableScope(HOTKEY_SCOPES.CART);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Fragment>
      {children}
      {modalEl.current
        ? createPortal(
            <Fragment>
              <div
                ref={modalRef}
                tabIndex={-1}
                className="modal"
                style={{ display: show ? "block" : "none" }}
              >
                <div
                  className={`modal-dialog${
                    size ? " modal-" + size : ""
                  } modal-dialog-scrollable modal-dialog-centered${
                    className ? " " + className : ""
                  }`}
                >
                  <div
                    className="modal-content position-relative"
                    style={{ height: fixHeight ? "56rem" : "unset" }}
                  >
                    <div className="modal-body">{body}</div>
                  </div>
                </div>
                {show ? (
                  <div
                    className="modal-backdrop show sfdsdf"
                    onClick={() => setShow(false)}
                  />
                ) : null}
              </div>
            </Fragment>,
            modalEl.current,
          )
        : null}
    </Fragment>
  );
});
