import React from "react";
import { NavLink } from "react-router-dom";
import { useIntl } from "react-intl";
import "./menu.css";
import { SettingMenuModels } from "models/setting-models";
import Icon from "component-v2/icons/icons";

export default function SettingMenu() {
  const intl = useIntl();
  return (
    <div className="menu-setting p-3">
      {SettingMenuModels.map((menu) => {
        return (
          <NavLink
            className="text-decoration-none font-weight-medium"
            to={menu.link}
            key={menu.title}
          >
            <div className="d-flex flex-row menu-setting--chil p-4">
              <Icon className={"mr-3"} name={menu.icon} />
              <span className="content-primary">
                <p>
                  {intl.formatMessage({
                    id: menu.code,
                    defaultMessage: menu.title,
                  })}
                </p>
                {/* <p>{intl.formatMessage({id: menu.desc_id, defaultMessage: menu.desc})}</p> */}
              </span>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}
