import React, { useEffect, useState } from "react";
import { Loading } from "@haravan/react-components";
import { ImageLazyLoad } from "components";
import { Assets } from "assets";
import { getTopProduct } from "../utils/utils";
import { FormattedMessage, useIntl } from "react-intl";
import NumberShow from "component-v2/number-show";
import CurrencyShow from "component-v2/currency-show";
import { LayoutCard } from "screens-v2/layouts/layouts/omni-layout-card";
import { useApp } from "contexts/app";

export default function GeneralTopProductReport({ dateRange, channel, staff }) {
  const appContext = useApp();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [topProducts, setTopProducts] = useState([]);

  useEffect(() => {
    const func = async () => {
      setIsLoading(true);
      const topProducts = await getTopProduct(
        appContext,
        dateRange.startDate,
        dateRange.endDate,
        channel,
        staff,
      );
      setIsLoading(false);
      setTopProducts(topProducts);
    };
    func();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, channel, staff]);

  function renderLoading() {
    return (
      <div
        style={{ height: "90%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Loading />
      </div>
    );
  }
  function renderNoData() {
    return (
      <div
        style={{ marginTop: "100px", flexDirection: "column" }}
        className="d-flex justify-content-center align-items-center"
      >
        <img src={Assets.icon.list_empty} alt="no-data" />
        <p className="m-t-20 content-secondary">
          {intl.formatMessage({
            id: "reports.Không có dữ liệu!",
            defaultMessage: "Không có dữ liệu!",
          })}
        </p>
      </div>
    );
  }

  return (
    <LayoutCard className="report__top-product-selling">
      <p className="font-weight-semibold font-size-16 text-default mb-16">
        {intl.formatMessage({
          id: "reports.Sản phẩm bán chạy",
          defaultMessage: "Sản phẩm bán chạy",
        })}
      </p>
      {isLoading ? (
        renderLoading()
      ) : (
        <div
          className="overflow-auto"
          style={{ maxHeight: "calc(100% - 30px)" }}
        >
          {topProducts?.length
            ? topProducts.map((product, index) => {
                if (product.id !== "shipping" && product.id !== "--") {
                  return (
                    <React.Fragment key={"tp-sllr-prdct-" + product.id}>
                      <div
                        className={`d-flex top-product`}
                        key={"tp-sllr-prdct-" + product.id}
                      >
                        <div className="pr-0">
                          <div className="d-flex justify-content-center align-items-center top-product__img">
                            <ImageLazyLoad
                              appContext={appContext}
                              product_id={product.id}
                              className="img-w-auto"
                            />
                          </div>
                        </div>
                        <div className="pl-3">
                          {product.id === 0 ? (
                            <p className="top-product__name mb-1">
                              <FormattedMessage
                                id="reports.Sản phẩm tùy chọn"
                                defaultMessage="Sản phẩm tùy chọn"
                              />
                            </p>
                          ) : (
                            <p className="top-product__name mb-1">
                              {product.name}
                            </p>
                          )}
                          <p className="text-secondary mb-1">
                            <FormattedMessage
                              id="reports.Đã bán được {a} sản phẩm"
                              defaultMessage="Đã bán được {a} sản phẩm"
                              values={{
                                a: (
                                  <span>
                                    <NumberShow value={product.quantity} />
                                  </span>
                                ),
                              }}
                            />
                          </p>
                          <p className="text-secondary">
                            {intl.formatMessage({
                              id: "reports.Doanh thu thuần",
                              defaultMessage: "Doanh thu thuần",
                            })}
                            :{" "}
                            <b>
                              <CurrencyShow value={product.total_sale_amount} />
                            </b>{" "}
                          </p>
                        </div>
                      </div>
                      {index !== topProducts.length - 1 && <hr />}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              })
            : renderNoData()}
        </div>
      )}
    </LayoutCard>
  );
}
