import React from "react";
import PropTypes from "prop-types";
import { InputCheckbox, Tooltip } from "@haravan/react-components";
import { useIntl } from "react-intl";
import Icon from "component-v2/icons/icons";

export const RecordReportCheckbox = ({
  checked,
  disabled,
  handleOnChange,
  isCashReceipt,
}) => {
  const intl = useIntl();
  const titleTooltip = isCashReceipt
    ? intl.formatMessage({
        id: "payment-vouchers.Chọn khi khoản tiền này là thu nhập thêm trong quá trình bán hàng của cửa hàng. Không nên chọn trong trường hợp thu tiền hoàn trả từ Nhà cung cấp...",
        defaultMessage:
          "Chọn khi khoản tiền này là thu nhập thêm trong quá trình bán hàng của cửa hàng. Không nên chọn trong trường hợp thu tiền hoàn trả từ Nhà cung cấp...",
      })
    : intl.formatMessage({
        id: "payment-vouchers.Chọn khi khoản tiền này là chi phí vận hành phát sinh trong quá trình bán hàng của cửa hàng. Không nên chọn trong trường hợp trả tiền hàng cho Nhà cung cấp...",
        defaultMessage:
          "Chọn khi khoản tiền này là chi phí vận hành phát sinh trong quá trình bán hàng của cửa hàng. Không nên chọn trong trường hợp trả tiền hàng cho Nhà cung cấp...",
      });

  return (
    <InputCheckbox
      checked={checked}
      disabled={disabled}
      onChange={handleOnChange}
      className="text-black"
    >
      <div className="d-flex align-items-center">
        <p className="mr-2">
          {intl.formatMessage({
            id: "payment-vouchers.Ghi nhận vào báo cáo kết quả kinh doanh",
            defaultMessage: "Ghi nhận vào báo cáo kết quả kinh doanh",
          })}
        </p>
        <Tooltip
          className="bg-black"
          overlayStyle={{ zIndex: "10002" }}
          placement="right"
          title={titleTooltip}
        >
          <p className="icon-secondary" style={{ marginTop: "-1px" }}>
            <Icon size="xs" name={"help_outline"} />
          </p>
        </Tooltip>
      </div>
    </InputCheckbox>
  );
};

RecordReportCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func,
};
