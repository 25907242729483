import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import Icon from "component-v2/icons/icons";
import { ProductCustomModal } from "screens-v2/carts-v2/components/ProductCustomModal";

export const ProductSearchInput = forwardRef(
  (
    {
      disabled,
      keyword,
      onChange,
      onFocus,
      onBlur,
      isElectricScale,
      enableElectricScale,
      onClearSearch,
      onESClick,
      isFocused,
    },
    ref
  ) => {
    const intl = useIntl();
    const { shop_setting } = useApp();
    const [isCreateProductCustom, setIsCreateProductCustom] = useState(false);

    const handleHotKeyClick = () => {
      let dom = document.getElementById("search_cart_product");
      if (dom) dom.focus();
    };
    return (
      <div className="w-100 position-relative">
        {!isFocused && keyword?.length === 0 ? (
          <span className="cart-search-hotkey" onClick={handleHotKeyClick}>
            F2
          </span>
        ) : null}

        <div className="cart-search-container">
          <span className={`cart-search-icon`}>
            <Icon name={"search"} color="var(--contentSecondary)" />
          </span>
          <input
            id="search_cart_product"
            type="text"
            autoComplete="off"
            ref={ref}
            disabled={disabled}
            className=" cart-search-box h-100"
            placeholder={intl.formatMessage({
              id: "carts.orders.products.searches.Tìm sản phẩm hoặc nhập barcode",
              defaultMessage: "Tìm sản phẩm hoặc nhập barcode",
            })}
            value={keyword}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {keyword?.length > 0 && (
            <Button
              size="sm"
              plain
              icon="close"
              title=""
              className={`l-ml-8 cart-search-icon cart-btn-remove`}
              onClick={onClearSearch}
            />
          )}
        </div>
        {
          shop_setting?.allowCreateCustomProduct ? <>
              <span className={`cart-search-icon-blur ml-3`}>
              <Button
                tooltipTitle="Tạo sản phẩm tùy ý"
                className={"flex-shrink-0"}
                light
                size="sm"
                plain
                onClick={() => {
                  setIsCreateProductCustom(true);
                }}
                icon={"add"}
                status="primary"
                iconColor={"var(--contentAccent)"}
              />
            </span>
            <ProductCustomModal
              show={isCreateProductCustom}
              setShow={setIsCreateProductCustom}
            />
          </> : null
        }
        
      </div>
    );
  }
);

ProductSearchInput.propTypes = {
  isFocused: PropTypes.bool,
  isElectricScale: PropTypes.bool,
  enableElectricScale: PropTypes.bool,
  keyword: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onESClick: PropTypes.func,
  onClearSearch: PropTypes.func,
};
