import { useEffect, useState } from "react";
import { LocationItem } from "./components/LocationItem";
import { FormattedMessage, useIntl } from "react-intl";
import { LayoutBody } from "screens-v2/layouts/layouts/omni-layout-body";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  LayoutCard,
  LayoutCardSection,
} from "screens-v2/layouts/layouts/omni-layout-card";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import { useApp } from "contexts/app";
import EmptyState from "component-v2/empty-state";
import Button from "component-v2/buttons";
import LocationProvider from "contexts/location";

function Location(props) {
  const layout = useLayoutContext();
  const intl = useIntl();
  const { auth, shop_info, shop_locations, refreshShopLocations } = useApp();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const func = async () => {
      layout.setPageTitle(
        intl.formatMessage({
          id: "carts.orders.Đổi chi nhánh",
          defaultMessage: "Đổi chi nhánh",
        }),
      );
      layout.setBreadcrumb([{ title: "Đổi chi nhánh", link: null }]);
      await refreshShopLocations();
      setLoading(false);
    };
    func();
    return () => {
      layout.setBreadcrumb(null);
      layout.setPageTitle("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLocationList = () => {
    const omnipower_location = `${shop_info.buyer_domain}admin/settings/locations/`;

    return (
      <ul className="location--listItem list-unstyled">
        {auth.user.is_admin && (
          <LayoutCard>
            <LayoutCardSection className="d-flex justify-content-between">
              <div>
                <div className="location--item-name">
                  <FormattedMessage
                    id="locations.Thêm mới chi nhánh bán hàng tại MyHaravan"
                    defaultMessage="Thêm mới chi nhánh bán hàng tại MyHaravan"
                  />
                </div>
                <div className="location--item-address text-secondary">
                  <FormattedMessage
                    id="locations.MyHaravan sẽ là nơi quản lý tập trung cho toàn bộ hệ thống vận hành của cửa hàng."
                    defaultMessage="MyHaravan sẽ là nơi quản lý tập trung cho toàn bộ hệ thống vận hành của cửa hàng."
                  />
                </div>
              </div>
              <div className="align-self-center">
                <Button
                  onClick={() => window.open(omnipower_location, "_blank")}
                  status="primary"
                  iconName="open_new"
                  title="Thêm chi nhánh"
                />
              </div>
            </LayoutCardSection>
          </LayoutCard>
        )}
        <LayoutCard>
          {shop_locations.map((location) => {
            return (
              <LocationItem
                {...props}
                key={location.id}
                location={location}
                showGlobalToast={layout.showGlobalToast}
              />
            );
          })}
        </LayoutCard>
      </ul>
    );
  };

  if (loading) return renderLoading();

  if (!shop_locations?.length)
    return (
      <EmptyState
        iconName="storefront"
        description="Không có thông tin chi nhánh hoặc bạn chưa được cấp quyền bán tại bất kỳ chi nhánh nào"
      />
    );

  return (
    <LocationProvider>
      <LayoutBody size="sm" className="l-border-top">
        <div className="d-flex justify-content-between mt-4">
          <p className="font-size-18 font-height-28 font-weight-semibold">
            <FormattedMessage
              id={`Đổi chi nhánh`}
              defaultMessage={`Đổi chi nhánh`}
            />
          </p>
        </div>
        {renderLocationList()}
      </LayoutBody>
    </LocationProvider>
  );
}

function renderLoading() {
  return (
    <LayoutBody>
      <LoadingCircle size="md" overlay />
    </LayoutBody>
  );
}

export default Location;
