import { useApp } from "contexts/app";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthenticatedRoute = ({
  component: C,
  props: cProps,
  admin_only = false,
  owner_only = false,
  ...rest
}) => {
  const { auth, current_location } = useApp();
  let _admin_only = false;
  let _owner_only = false;
  if (admin_only) _admin_only = !auth.user.is_admin;
  if (owner_only)
    _owner_only = !(
      auth.user.roles?.includes("admin") ||
      auth.user.roles?.includes("com_api.admin")
    );
  if (!auth.authenticated)
    return (
      <Route
        render={(props) => (
          <Redirect
            to={`/login?returnUrl=${props.location.pathname}${props.location.search}`}
          />
        )}
      />
    );
  if (!current_location)
    return (
      <Route
        render={(props) =>
          props.location.pathname === "/locations" ? (
            <C {...props} {...cProps} />
          ) : (
            <Redirect to={`/locations`} />
          )
        }
      />
    );

  return (
    <Route
      {...rest}
      render={(props) =>
        _admin_only || _owner_only ? (
          <Redirect to={"/"} />
        ) : (
          <C {...props} {...cProps} />
        )
      }
    />
  );
};
export default AuthenticatedRoute;
