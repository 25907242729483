import { getCurrentShift } from "api/shift-repository";

export async function GetCurrentShift(appContext, location) {
  if (!location) return null;
  try {
    const result = await getCurrentShift(appContext, location);
    if (result.data) {
      return result.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
