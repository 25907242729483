export const CONSTANT_LINKS = {
  route_orders: "/orders",
  route_order_detail: "/orders/:id",
  route_refund_order: "/orders/:id/refund",
  route_products: "/products",
  route_settings: "/settings",
  route_setting_user: "/settings/user",
  route_setting_general: "/settings/general",
  route_setting_sale: "/settings/sale",
  route_setting_shift: "/settings/shift",
  route_setting_bill: "/settings/bill",
  route_register: "/register",
};
export const getConstantLinkDetail = (link, value, alias = "id") => {
  alias = `:${alias}`;
  return link.replace(alias, value + "");
};
