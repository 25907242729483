import { Tooltip } from "@haravan/react-components";
import { Assets } from "assets";
import { useDropzone } from "react-dropzone";
import "./styles.css";
import PropTypes from "prop-types";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import NumberShow from "../number-show";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { ImageCustom } from "component-v2/ImageCustom";

const MAX_IMAGE_NUMBER_ALLOW_DEFAULT = 10;
const acceptType = ".jpeg,.png,.gif,.jpg";

export function UploadImages({
  maxFiles = MAX_IMAGE_NUMBER_ALLOW_DEFAULT,
  imageMaxSize = 20,
  onSelectFile,
  listImage,
  messageMaxFiles,
}) {
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  const [openModalPreview, setOpenModalPreview] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    maxFiles,
    accept: {
      "image/*": acceptType.split(","),
    },
    onDrop: (acceptedFiles, fileRejections) =>
      handleOnDrop(acceptedFiles, fileRejections),
  });

  function checkImageSize(file) {
    if (file.type) {
      let size = file.size,
        maxSize = imageMaxSize * 1024 * 1024;
      if (maxSize >= size) return true;
    }

    return false;
  }

  function handleOnDrop(acceptedFiles, fileRejections) {
    const MAX_IMAGE_NUMBER_ALLOW = maxFiles || MAX_IMAGE_NUMBER_ALLOW_DEFAULT;
    if (acceptedFiles && acceptedFiles.length > 0) {
      let listImageCurrent = [...listImage],
        error = false;

      acceptedFiles.forEach((item) => {
        if (checkImageSize(item)) {
          Object.assign(item, {
            preview: URL.createObjectURL(item),
          });
          listImageCurrent.push(item);
        } else error = true;
      });

      //validate file extension
      let wrongFormat = false;
      const allowedFormats = acceptType.split(",");
      listImageCurrent.some((file) => {
        // eslint-disable-next-line array-callback-return
        allowedFormats.some((extension, index) => {
          const formatPatt = new RegExp(`\\${extension}$`, "i");
          const currentFileName = file.name.toLocaleLowerCase();
          if (formatPatt.test(currentFileName)) return true;
          if (allowedFormats.length - 1 === index) wrongFormat = true;
        });
        return wrongFormat;
      });
      if (wrongFormat) {
        let message =
          "File không đúng định dạng. Hệ thống chỉ hỗ trợ hình ảnh định dạng: PNG, JPG, JPEG, GIF.";
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${message}`,
            defaultMessage: message,
          }),
        );
        return;
      }

      if (listImageCurrent.length > MAX_IMAGE_NUMBER_ALLOW) {
        showGlobalToast(
          "default",
          <FormattedMessage
            id="notifications.Xin chọn tối đa {a} hình ảnh"
            defaultMessage="Xin chọn tối đa {a} hình ảnh"
            values={{
              a: (
                <span>
                  <NumberShow value={MAX_IMAGE_NUMBER_ALLOW} />
                </span>
              ),
            }}
          />,
        );
        return;
      }

      if (error) {
        let message = `Dung lượng file vượt quá ${imageMaxSize} MB. Vui lòng kiểm tra lại.`;
        showGlobalToast(
          "default",
          intl.formatMessage({
            id: `notifications.${message}`,
            defaultMessage: message,
          }),
        );
        onSelectFile(null);
      } else {
        onSelectFile(listImageCurrent);
      }
    } else {
      if (fileRejections.length > MAX_IMAGE_NUMBER_ALLOW) {
        showGlobalToast(
          "default",
          <FormattedMessage
            id="notifications.Xin chọn tối đa {a} hình ảnh"
            defaultMessage="Xin chọn tối đa {a} hình ảnh"
            values={{
              a: (
                <span>
                  <NumberShow value={MAX_IMAGE_NUMBER_ALLOW} />
                </span>
              ),
            }}
          />,
        );
        return;
      }
      if (checkNumberOfImageOverMax(fileRejections, MAX_IMAGE_NUMBER_ALLOW))
        return;

      //validate file extension
      let hasErrorType = fileRejections?.some(
        (file) => file.errors?.[0]?.code === "file-invalid-type",
      );

      if (hasErrorType) {
        let message =
          "File không đúng định dạng. Hệ thống chỉ hỗ trợ hình ảnh định dạng: PNG, JPG, JPEG, GIF.";
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${message}`,
            defaultMessage: message,
          }),
        );
        return;
      }
      let message = "Đã có lỗi xảy ra, xin vui lòng thử lại.";
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
    }
  }

  function checkNumberOfImageOverMax(list, max) {
    if (list.length > max) {
      showGlobalToast(
        "default",
        <FormattedMessage
          id="notifications.Xin chọn tối đa {a} hình ảnh"
          defaultMessage="Xin chọn tối đa {a} hình ảnh"
          values={{
            a: (
              <span>
                <NumberShow value={max} />
              </span>
            ),
          }}
        />,
      );
      return true;
    }
    return false;
  }

  const files = listImage.map((file) => {
    return (
      <div className="btn-upload-image-item" key={file.name}>
        <ImageCustom
          className="item__image--wrapper-item"
          src={file?.preview}
          alt={file.name}
        />
        <div className="item__image--wrapper-action">
          <span
            className="cursor-pointer mr-3"
            onClick={() => setOpenModalPreview(file)}
          >
            <Tooltip
              title={intl.formatMessage({
                id: "payment-vouchers.Xem thử",
                defaultMessage: "Xem thử",
              })}
            >
              {Assets.icons_svg.eye_open}
            </Tooltip>
          </span>
          <span
            className="cursor-pointer"
            onClick={() => handleRemoveFile(file)}
          >
            <Tooltip
              title={intl.formatMessage({
                id: "payment-vouchers.Xóa hình ảnh",
                defaultMessage: "Xóa hình ảnh",
              })}
            >
              {Assets.icons_svg.trash}
            </Tooltip>
          </span>
        </div>
      </div>
    );
  });

  function handleRemoveFile(file) {
    const newUploadedFiles = [...listImage];
    let findFile = newUploadedFiles.findIndex(
      (fileItem) => fileItem.path === file.path,
    );
    if (findFile > -1) {
      newUploadedFiles.splice(findFile, 1);
    }
    onSelectFile(newUploadedFiles);
  }

  function renderModalPreview() {
    return (
      <GlobalModal
        headerTitle={intl.formatMessage({
          id: "payment-vouchers.Hình ảnh",
          defaultMessage: "Hình ảnh",
        })}
        body={
          <ImageCustom
            className="w-100 h-auto"
            src={openModalPreview?.preview}
            alt={openModalPreview?.name}
          />
        }
        show={openModalPreview}
        setShow={setOpenModalPreview}
        onCancel={() => setOpenModalPreview(null)}
      />
    );
  }

  return (
    <div className="d-flex flex-wrap">
      {files}
      {listImage?.length < maxFiles ? (
        <div
          {...getRootProps({
            className: "upload-images btn-upload-image-item",
          })}
        >
          <div className="upload-image-zone cursor-pointer">
            <input {...getInputProps()} />
            {Assets.icons_svg.upload}
            <p className="btn-upload-image-title mt-3">
              {intl.formatMessage({
                id: "payment-vouchers.Thêm ảnh",
                defaultMessage: "Thêm ảnh",
              })}
            </p>
          </div>
        </div>
      ) : null}
      {renderModalPreview()}
    </div>
  );
}

UploadImages.propTypes = {
  maxFiles: PropTypes.number,
  imageMaxSize: PropTypes.number,
  onSelectFile: PropTypes.func,
  messageMaxFiles: PropTypes.string,
};
