import Icon from "component-v2/icons/icons";
import { DateTimeShowType } from "infrastructures/format-helpers";
import CurrencyShow from "component-v2/currency-show";
import DateTimeShow from "component-v2/datetime-show";
import { ActivityTypes } from "../utils/utils";
import { useApp } from "contexts/app";

export default function ShiftDetailActivitiesItem({ activity }) {
  const { shop_setting } = useApp();
  const _shift_cash_tracking = shop_setting.shiftCashTracking;
  const _activity_type = ActivityTypes[activity.type];
  const _activity_amount =
    activity.type === "withdraw" ? activity.amount * -1 : activity.amount;
  const className = `flex-stretch ${
    !activity.staffUser?.email ? "d-flex align-items-center" : ""
  }`;
  return (
    <div>
      <div>
        <div className="flex-row">
          <div className="l-img-placeholder l-mr-8">
            <Icon name="account_circle" size={2.4} />
          </div>
          <div className={className}>
            <p>Thu ngân: {activity.staffUser?.userName}</p>
            <p className="l-mt-4 content-secondary">
              {activity.staffUser?.email}
            </p>
          </div>
        </div>
      </div>
      <div>
        <p>
          <span className="font-weight-semibold">
            {_activity_type.name}&nbsp;
          </span>
          {_shift_cash_tracking && activity.type !== "open" ? (
            <span>
              {_activity_type.prefix}
              <CurrencyShow colored value={Math.round(_activity_amount)} />
            </span>
          ) : null}
        </p>
        <p className="l-mt-4 content-secondary">
          <DateTimeShow
            date={activity.createdAt}
            type={DateTimeShowType.date_time}
          />
        </p>
        {activity.note ? (
          <p className="l-mt-4">
            <Icon name="sticky_note_2" size="xs" />
            &nbsp;{activity.note}
          </p>
        ) : null}
      </div>
      {_shift_cash_tracking ? (
        <div className="text-right">
          <p className="content-secondary text-nowrap">
            {_activity_type.label}
          </p>
          <p className="l-mt-4">
            <CurrencyShow value={Math.round(activity.shiftCashAmount)} />
          </p>
        </div>
      ) : null}
    </div>
  );
}
