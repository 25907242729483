import PropTypes from "prop-types";

/* Bo sung them props scrollable */
const sizes = ["ne", "lg", "md", "sm"];
export function LayoutBody({
  className,
  size = "lg",
  fullSize = true,
  flexRow = false,
  children,
}) {
  return (
    <div
      className={`omni-layout-body${fullSize ? " full-size" : ""}${
        className ? " " + className : ""
      }`}
    >
      <div
        className={`omni-layout-body-${size}${
          flexRow ? " flex-row" : " flex-column"
        }`}
      >
        {children}
      </div>
    </div>
  );
}

LayoutBody.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(sizes),
};

LayoutBody.defaultProps = {
  size: "lg",
};
