import moment from "moment";
import "moment-timezone";

export const diff = (date1, date2, type = "days") => {
  let end = moment(date2);
  let start = moment(date1);
  let result = end.diff(start, type);
  return result ? Math.abs(result) : 0;
};

export function getZTime(date) {
  if (!date) return null;
  return date.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}
