class CurrencyUtils {
  formatMoneyVND(input) {
    function floatToString(numeric, decimals) {
      var amount = numeric.toFixed(decimals).toString();
      amount.replace(".", ".");
      if (amount.match("^[.]+")) {
        return "0" + amount;
      } else {
        return amount;
      }
    }
    var cents = input;
    if (typeof cents === "string") cents = cents.replace(",", "");
    cents = Number(cents);

    var patt = /\{\{\s*(\w+)\s*\}\}/;
    var formatString = "{{amount}}";

    function addCommas(moneyString) {
      let str = `$1,`;
      return moneyString.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, str);
    }

    var value = addCommas(floatToString(cents, 0));

    return formatString.replace(patt, value + "₫");
  }

  formatMoney(cents) {
    if (cents === 0) {
      return 0;
    }
    return String(cents).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  }

  numberWithCommasActive = (value) => {
    value = value.toString().replace(/[^0-9]/g, "");
    const parts = value.split(".");
    return parts.join(".");
  };

  renderTitleReward = (item) => {
    let formatText = "";
    switch (item.promotion) {
      case 3:
        formatText = ` Miễn phí vận chuyển đối với mức phí vận chuyển nhỏ hơn hoặc bằng ${this.formatMoneyVND(
          item.promotion_value,
        )}`;
        break;
      case 2:
        formatText = `Voucher mua hàng giảm ${item.promotion_value} %`;
        break;
      default:
        formatText = `Đổi ${this.formatMoney(
          item.condition_value,
        )} điểm lấy coupon ${this.formatMoneyVND(item.promotion_value)}`;
    }
    return formatText;
  };
}

const currencyUtils = new CurrencyUtils();

export default currencyUtils;
