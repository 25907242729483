import { ENDPOINT_GATEWAY, useGetDataSWR } from "api/api-connect-base";

export const useGetEInvoicesOrder = (orderId) => {
  let endpoint = `/orders/${orderId}/einvoices`;
  const a = useGetDataSWR(ENDPOINT_GATEWAY.commerce, endpoint);
  const data = a?.data;
  return { data, isValidating: a.isValidating, mutate: a.mutate };
};

export const useGetPrintEInvoice = (orderId, eInvoiceId) => {
  let endpoint = `/orders/${orderId}/einvoices/${eInvoiceId}/print`;
  const a = useGetDataSWR(ENDPOINT_GATEWAY.commerce, endpoint);
  const data = a?.data;
  return { data, isValidating: a.isValidating, mutate: a.mutate };
};

export const useGetEInvoicesOrderV2 = (endpoint) => {
  const a = useGetDataSWR(ENDPOINT_GATEWAY.commerce, endpoint);
  const data = a?.data;
  return { data, isValidating: a.isValidating, mutate: a.mutate };
};

export const useGetPrintEInvoiceV2 = (endpoint) => {
  // let endpoint = `/orders/${orderId}/einvoices/${eInvoiceId}/print`
  const a = useGetDataSWR(ENDPOINT_GATEWAY.commerce, endpoint);
  const data = a?.data;
  return { data, isValidating: a.isValidating, mutate: a.mutate };
};
