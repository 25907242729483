import { memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Button from "component-v2/buttons";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
export default memo(function RefundOtherLocation({
  orderNumber,
  locationName,
}) {
  const history = useHistory();
  const intl = useIntl();

  const body = (
    <div className="d-flex justify-content-center align-items-center flex-column py-4">
      <p className="mb-4 text-center" style={{ lineHeight: "2rem" }}>
        <FormattedMessage
          id="carts.orders.Cửa hàng không cho phép đổi trả hàng khác chi nhánh.{c} <d>Đơn hàng {a} được mua tại {b}</d>"
          defaultMessage="Cửa hàng không cho phép đổi trả hàng khác chi nhánh.{c} <d>Đơn hàng {a} được mua tại {b}</d>"
          values={{
            a: <span>{orderNumber}</span>,
            b: <span>{locationName}</span>,
            c: <br />,
            d: (d) => <span className="content-warning">{d}</span>,
          }}
        />
      </p>
      <div className="mx-auto">
        <Button
          onClick={() => {
            history.push("/orders");
          }}
          status="primary"
          className=""
        >
          {intl
            .formatMessage({
              id: "carts.orders.Đóng",
              defaultMessage: "ĐÓNG",
            })
            .toUpperCase()}
        </Button>
      </div>
    </div>
  );

  return (
    <GlobalModal
      headerClose={false}
      body={body}
      show={true}
      setShow={(show) => {}}
    />
  );
});
