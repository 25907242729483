import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { useEffect, useState } from "react";
import { useCartContext } from "..";
import ImportCartItems from "../components/import-data";
import { ModalRemoveAllProductCart } from "../RightCart/components/modalRemoveAllProductCart";
import { AppBridge } from "screens-v2/carts-v2/components/apps";

export default function CartFunctions() {
  const { shop_setting } = useApp();
  let { current_shopping_cart } = useCartContext();
  const import_enable =
    shop_setting.isImportOrder && !current_shopping_cart?.lineItems?.length;
  const [showModalRemoveProduct, setShowModalRemoveProduct] = useState(false);

  return (
    <div className="carts-functions">
      <div className="flex-stretch">
        <Button
          size="sm"
          light
          icon="delete"
          title="Xóa tất cả"
          disabled={current_shopping_cart?.lineItems?.length ? false : true}
          onClick={() => {
            setShowModalRemoveProduct(true);
          }}
        />
      </div>
      <div className="flex-auto">
        {/* 
    <Button size="sm" light icon='card_giftcard' iconColor='var(--purple500)' title='Khuyến mãi' badge='F7' className='l-ml-8'/>
    <Button size="sm" light icon='join_full' iconColor='var(--purple500)' title='Combo' className='l-ml-8'/>
    */}
        <AppBridge />
        {import_enable && <ImportCartItems />}
        <ModalRemoveAllProductCart
          show={showModalRemoveProduct}
          setShow={setShowModalRemoveProduct}
        />
      </div>
    </div>
  );
}
