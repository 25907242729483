import React, { useContext } from "react";
import { useApp } from "contexts/app";

const context = React.createContext();

function LocationProvider({ children }) {
  const appContext = useApp();

  function handleSetCurrentLocation(location) {
    const locationId = location?.id;
    appContext.setCurrentLocation(locationId);
  }

  return (
    <context.Provider
      value={{
        handleSetCurrentLocation,
      }}
    >
      {children}
    </context.Provider>
  );
}
const useLocation = () => {
  const app = useContext(context);
  return app;
};

export { useLocation };
export default LocationProvider;
