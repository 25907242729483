import v5 from "uuid";
import { Fragment, memo, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "./global-modal.css";
import { useIntl } from "react-intl";
import { isNil } from "lodash";
import Button from "component-v2/buttons";
import { Loading } from "@haravan/react-components";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { useHotkeysContext } from "react-hotkeys-hook";

const MODAL_COVER_ID = "root-modal-cover";
export default memo(function GlobalModal({
  id, // unique id
  children,
  size, // 'sm', 'md', 'lg', 'xl', xxl, 3xl (full-size)
  fixHeight = false,
  fullScreen = false,
  headerTitle,
  headerClose = true, // show button close modal
  headerBack = false,
  onClickBack,
  body,
  footer,
  onCancel, // function on click cancel, button show when this is set and there is no footer props
  onConfirm, // function on click confirm, button show when this is set and there is no footer props
  show = false,
  setShow,
  loading,
  loadingOverlay,
  className,
  useEscapeKey = true,
  isPaddingBody = true,
  isPriority = false,
  confirmTitle = "",
  cancelTitle = "",
  confirmButtonClassName = "",
}) {
  const intl = useIntl();
  const { enableScope, disableScope } = useHotkeysContext();
  let modalCover = document.getElementById(MODAL_COVER_ID);
  if (!modalCover) {
    modalCover = document.createElement("div");
    modalCover.setAttribute("id", MODAL_COVER_ID);
    document.getElementById("root").appendChild(modalCover);
  }
  const thisKey = useRef();
  const modalEl = useRef();
  const modalRef = null;

  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC && onCancel) {
        onCancel();
      }
      if (e.key === HOTKEY_EVENTS.F8 && onConfirm) {
        onConfirm();
      }
    },
    enabled: !!show && useEscapeKey && !loading && !loadingOverlay,
    scopes: HOTKEY_SCOPES.MODAL,
  });

  useEffect(() => {
    let key = id;
    if (!key) {
      key = v5();
    }
    thisKey.current = key;
    return () => {
      if (thisKey.current) {
        const ex = document.getElementById(thisKey.current);
        if (ex) ex.outerHTML = "";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (show) {
      enableScope(HOTKEY_SCOPES.MODAL);
      disableScope(HOTKEY_SCOPES.CART);
      modalEl.current = document.createElement("div");
      modalEl.current.setAttribute("id", thisKey.current);
      modalCover.appendChild(modalEl.current);
    } else {
      const ex = document.getElementById(thisKey.current);
      if (ex) ex.outerHTML = "";
      enableScope(HOTKEY_SCOPES.CART);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const closeModal = () => {
    enableScope(HOTKEY_SCOPES.CART);
    setShow(false);
  };
  return (
    <Fragment>
      {children}
      {modalEl.current
        ? createPortal(
            <Fragment>
              <div
                ref={modalRef}
                tabIndex={-1}
                className={`modal ${isPriority ? "modal-priority" : ""}`}
                style={{ display: show ? "block" : "none" }}
              >
                <div
                  className={`modal-dialog${
                    size ? " modal-" + size : ""
                  } modal-dialog-scrollable modal-dialog-centered ${className}`}
                >
                  <div
                    className="modal-content"
                    style={{
                      height: fullScreen
                        ? "100%"
                        : fixHeight
                          ? "56rem"
                          : "unset",
                    }}
                  >
                    {loadingOverlay ? (
                      <>
                        <Loading className="loading-btn position-absolute" />
                        <div className="overlay-backdrop" />
                      </>
                    ) : (
                      ""
                    )}
                    {!isNil(headerTitle) ? (
                      <div className="modal-header">
                        {headerBack ? (
                          <Button
                            plain
                            className="l-button--back"
                            onClick={() => onClickBack()}
                            icon="arrow_back_ios"
                          />
                        ) : null}
                        <h4 className="modal-title">{headerTitle}</h4>
                        {headerClose ? (
                          <Button
                            plain
                            className="l-button--close"
                            onClick={() =>
                              onCancel ? onCancel() : closeModal(false)
                            }
                            icon="close"
                          />
                        ) : null}
                      </div>
                    ) : null}
                    <div className={`modal-body ${isPaddingBody ? "" : "p-0"}`}>
                      {isNil(headerTitle) && headerClose ? (
                        <Button
                          plain
                          className="l-button--close"
                          onClick={() => closeModal(false)}
                          icon="close"
                        />
                      ) : null}
                      {body}
                    </div>
                    {footer ? (
                      <div className="modal-footer">{footer}</div>
                    ) : onCancel || onConfirm ? (
                      <div className="modal-footer">
                        {onCancel ? (
                          <Button
                            title={
                              cancelTitle
                                ? cancelTitle
                                : intl.formatMessage({
                                    id: "systems.popups.Hủy",
                                    defaultMessage: "Hủy",
                                  })
                            }
                            light
                            size="md"
                            onClick={onCancel}
                            badge={HOTKEYS.ESC}
                          ></Button>
                        ) : null}
                        {onConfirm ? (
                          <Button
                            title={
                              confirmTitle
                                ? confirmTitle
                                : intl.formatMessage({
                                    id: "systems.popups.Xác nhận",
                                    defaultMessage: "Xác nhận",
                                  })
                            }
                            status={
                              confirmButtonClassName
                                ? confirmButtonClassName
                                : "primary"
                            }
                            size="md"
                            onClick={() => !loading && onConfirm?.()}
                            loading={loading}
                            badge={HOTKEYS.F8}
                          ></Button>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
                {show ? <div className="modal-backdrop show" /> : null}
              </div>
            </Fragment>,
            modalEl.current,
          )
        : null}
    </Fragment>
  );
});
