import LayoutMiddle from "screens-v2/layouts/layouts/layout-middle";
import Icon from "./icons/icons";

export default function EmptyState({
  iconName = "update",
  title = "",
  description = "",
  button,
  className,
}) {
  return (
    <LayoutMiddle
      className={`l-empty-state${className ? " " + className : ""}`}
    >
      <div className="l-empty-state--icon">
        <Icon size={4} name={iconName} color="var(--contentTertiary)" />
      </div>
      {title ? <div className="l-empty-state--title">{title}</div> : null}
      {description ? (
        <p className="l-empty-state--desc">{description}</p>
      ) : null}
      {button ? button : null}
    </LayoutMiddle>
  );
}
