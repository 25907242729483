


export class RefundModel {
  key = "";
  title = "";
  customer = null;
  order = null;

  lineItems = null;

  isHasRefundItem = false;
  isSubmitRefundOrderWaiting = false;
  refund_quantity_total = 0;
  lastestAction = 0;
  priceUserRefund = 0;
  priceUserCanRefund = 0;
  refundRedeemAmount = 0;
  refundRedeemPoint = 0;

  constructor() {
    let refund_key = new Date().toISOString();
    this.key = refund_key;
    this.title = "Đổi trả";
    this.customer = null;
    this.order = null;
    this.lineItems = [];
    this.isHasRefundItem = false;
    this.isSubmitRefundOrderWaiting = false;
    this.refund_quantity_total = 0;
    this.lastestAction = 0;
    this.priceUserRefund = 0;
    this.priceUserCanRefund = 0;
    this.refundRedeemAmount = 0;
    this.refundRedeemPoint = 0;
    this.eInvoice = []
  }

}


export class RefundCartModel {
  lineItems = [];
  key = '';
  title = '';
  note = '';
  noteAttributes = null;
  isShipping = false;
  customer = null;
  payment_methods = [];
  pricingApplied = null;
  orderLineErr = null;
  orderDiscountSelected = null;

  cart_quantity_total = 0;

  summaries = {
    subtotal: 0,
    discount: 0,
    discount_code: "",
    shipping_fee: 0,
    total_payment: 0,
    from_customer: 0,
    surplus: 0,
  };

  constructor() {
    let cart_key = new Date().toISOString();
    this.key = cart_key;
    this.title = 'Đơn hàng';
    this.note = '';
    this.noteAttributes = null;
    this.isShipping = false;
    this.customer = null;
    this.lineItems = [];
    this.orderLineErr = null;
    this.payment_methods = [];
    this.pricingApplied = null;
    this.cart_quantity_total = 0;
    this.orderDiscountSelected = null;
    this.summaries = {
      total_price: 0,
      subtotal: 0,
      discount: 0,
      discount_code: "",
      shipping_fee: 0,
      total_payment: 0,
      from_customer: 0,
      surplus: 0,
    };
    this.vatValue = {
      vatSum: 0,
      sumIncludedVat: 0,
      sumNotIncludedVat: 0,
      shippingVat: 0,
    }
  }
}