import { Input, InputCheckbox } from "@haravan/react-components";
import React, { useEffect, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { InputNumber } from "../RightCart/components/InputNumber";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useIntl } from "react-intl";
import { useCartContext } from "..";
import { cloneDeep } from "lodash";
import v5 from "uuid";
import { InputQuantity } from "component-v2/Input";

export const ProductCustomModal = ({ show, setShow }) => {
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [productQuantity, setProductQuantity] = useState(1);
  const [isShipping, setIsShipping] = useState(true);
  const layoutContext = useLayoutContext();
  const intl = useIntl();
  const { current_shopping_cart, updateCurrentSession } = useCartContext();

  useEffect(() => {
    if (!show) {
      setProductName("");
      setProductPrice(0);
      setProductQuantity(1);
      setIsShipping(true);
    }
  }, [show]);

  function onAddTempoProduct(product) {
    const products = cloneDeep(current_shopping_cart?.lineItems) || [];
    if (products && products.length > 0) {
      let v = products.find(
        (v) =>
          !v.variantId &&
          v.productTitle.toLowerCase() === product.name.toLowerCase().trim(),
      );
      if (v) {
        layoutContext.showGlobalToast(
          "default",
          `Sản phẩm ${product.name} đã được tạo`,
        );
        return false;
      }
    }
    var objProduct = {
      price: product.price,
      linePrice: product.price,
      originalPrice: product.price,
      quantity: product.quantity,
      productName: product.name.trim(),
      isShipping: product.isShipping,
      isChargeTaxes: false,
      weight: 0,
      isCalculatePromotion: true,
      notAllowPromotion: false,
    };
    products.push(objProduct);

    updateCurrentSession({ lineItems: products });
    return true;
  }

  function onAddNewProduct() {
    if (!productName.trim()) {
      layoutContext.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "notifications.Chưa điền tên sản phẩm",
          defaultMessage: "Chưa điền tên sản phẩm",
        }),
      );
      return;
    }
    if (productName.trim().length > 255) {
      layoutContext.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "errors.product.name.max_limit_reached__255__",
          defaultMessage: "Tên sản phẩm không được vượt quá 255 ký tự",
        }),
      );
      return;
    }
    if (productPrice == null || Number.isNaN(productPrice)) {
      layoutContext.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "errors.orders.components.Giá sản phẩm không đúng",
          defaultMessage: "Giá sản phẩm không đúng",
        }),
      );
      return;
    }
    if (Number(productPrice) < 0) {
      layoutContext.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "orders.create.Giá sản phẩm phải lớn hơn hoặc bằng 0",
          defaultMessage: "Giá sản phẩm phải lớn hơn hoặc bằng 0",
        }),
      );
      return;
    }
    if (
      !productQuantity ||
      Number.isNaN(productQuantity) ||
      Number(productQuantity) <= 0
    ) {
      layoutContext.showGlobalToast(
        "error",
        intl.formatMessage({
          id: "orders.create.Số lượng sản phẩm không đúng",
          defaultMessage: "Số lượng sản phẩm không đúng",
        }),
      );
      return;
    }
    let result = onAddTempoProduct({
      name: productName,
      price: productPrice,
      quantity: productQuantity,
      isShipping,
    });
    if (result) setShow(false);
  }

  const body = () => {
    return (
      <div>
        <div>
          <label htmlFor="productName">Tên sản phẩm</label>
          <Input
            placeholder="Nhập tên sản phẩm"
            value={productName}
            onChange={(value) => setProductName(value)}
            autoFocus={true}
            onFocus={(_, e) => e.currentTarget.select()}
          />
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div className="w-100">
            <label htmlFor="productPrice">Giá</label>
            <InputNumber
              id="productPrice"
              value={productPrice}
              onChange={(value) => setProductPrice(value)}
            />
          </div>
          <div className="w-100 ml-3">
            <label htmlFor="productQuantity">Số lượng</label>
            <InputQuantity
              id="productQuantity"
              value={productQuantity}
              onChange={(_, value) => setProductQuantity(value)}
              showAction={false}
              size="md"
              className="text-right"
              textRight
            />
          </div>
        </div>
        <InputCheckbox
          className="mt-3"
          checked={isShipping}
          onChange={(check) => {
            setIsShipping(check);
          }}
        >
          <span>Có giao hàng</span>
        </InputCheckbox>
      </div>
    );
  };

  return (
    <GlobalModal
      headerTitle={"Tạo sản phẩm tùy ý"}
      isTitleCenter
      body={body()}
      show={show}
      setShow={setShow}
      onConfirm={() => {
        onAddNewProduct();
      }}
      onCancel={() => {
        setShow(false);
      }}
    />
  );
};
