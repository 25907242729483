import { GetCashFlowById } from "api/payment-voucher-api";
import { Assets } from "assets";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";

const ModalCreateSuccess = ({ data, isCashReceipt }) => {
  const { shop_info: shopInfo } = useApp();
  const intl = useIntl();
  const [detail, setDetail] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const appContext = useApp();
  useEffect(() => {
    if (data) {
      getCashFlowDetail(data);
    }
  }, [data]);

  const getCashFlowDetail = async (data) => {
    const response = await GetCashFlowById(appContext, data);
    if (response?.data) {
      setDetail(response.data);
      setIsOpen(true);
    }
  };

  const body = () => {
    const link = `${shopInfo?.seller_domain}/accounting/transactions/${data}`;
    return (
      <div>
        <div className="d-flex justify-content-end">
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="icon-secondary icon-w-14 ml-auto cursor-pointer"
          >
            {Assets.icons.close}
          </div>
        </div>
        <div className="text-center text-default">
          <Icon
            className={"mb-4"}
            name={"checkbox_circle_line"}
            size="xl"
            color="var(--green600)"
          />
          <div className="mb-4 d-flex flex-column">
            <p className="font-size-24 mb-4 font-weight-medium">
              {intl.formatMessage({
                id: `payment-vouchers.Đã tạo thành công phiếu ${
                  isCashReceipt ? "thu" : "chi"
                }`,
                defaultMessage: `Đã tạo thành công phiếu ${
                  isCashReceipt ? "thu" : "chi"
                }`,
              })}{" "}
            </p>
            <a
              className="font-size-24 font-weight-medium"
              href={link}
              rel="no noreferrer"
              target={"_blank"}
            >
              #{detail?.casNumber}
            </a>
          </div>
          <p className="text-secondary mb-4">
            {intl.formatMessage({
              id: "payment-vouchers.Ngày",
              defaultMessage: "Ngày",
            })}{" "}
            {moment(detail?.tranDate).format("DD/MM/YYYY")}{" "}
            {detail?.createdUserName ? `• ${detail?.createdUserName}` : ""}
          </p>
          <Button
            onClick={() => setIsOpen(false)}
            light
            size="md"
            className={"px-4"}
          >
            Xong
          </Button>
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      headerClose={false}
      body={body()}
      show={isOpen}
      setShow={setIsOpen}
    />
  );
};

export default ModalCreateSuccess;
