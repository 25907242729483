import React, { useEffect, Fragment, useRef, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";
import {debounce} from "lodash";
import {ProductService} from "services";
import {MODE_SEARCH} from "../utils/constant";
import {ProductSearchBox, ProductSearchResult} from "../components";
import {useLayoutContext} from "contexts/Layout/layout-context";
import {useApp} from "contexts/app";
import { useOnClickOutside } from "hooks/useOnClickOutSide";

export const ProductInfo = (props) => {
  const _service = new ProductService();
  const appContext = useApp();
  const history = useHistory();
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const searchProductInfoRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowResult, setIsShowResult] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState(_service.getDefaultProductValue());
  const this_ref = useRef(null)
  const [mouseOverResult, setMouseOverResult] = useState(false);

  useOnClickOutside(this_ref, () => {
    !mouseOverResult && setIsShowResult(false);
  })

  const handleSelectedVariant = async (variantArg) => {
    if (!variantArg) {
      showGlobalToast('default', intl.formatMessage({ id: 'products.searches.Không tìm thấy sản phẩm', defaultMessage: 'Không tìm thấy sản phẩm' }));
      return;
    }
    let productId = variantArg.productId;
    let variantId = variantArg.id;
    history.push(`/products/${productId}/variants/${variantId}`);
    setIsShowResult(false);
    setIsFocused(false);
  };


  //#region seach activity

  const debounceSearch = useCallback(debounce(async (e) => {
    setIsLoading(true);
    // call api search product
    setIsShowResult(true);
    let products = await _service.searchProducts(appContext, MODE_SEARCH, 1, 10, e);
    if (products) setProducts(products);
    setIsLoading(false);
  }, 400), []);

  const onSearchChange = (event) => {
    debounceSearch(event.target.value);
    setSearchText(event.target.value);
  };

  const onSearchFocus = (event) => {
    event.target.select();
    setIsFocused(true);
    if (searchText.length > 0) {
      setIsShowResult(true);
    }
  };

  const onSearchLostFocus = (event) => {
    setIsFocused(false);

  };

  const onNextPage = async (page) => {
    setIsLoading(true);
    let products = await _service.searchProducts(appContext, MODE_SEARCH, page, 10, searchText);
    if (products) setProducts(products);
    setIsLoading(false);
  };

  const handleKeywordClear = () => {
    setSearchText('');
    searchProductInfoRef.current?.focus()
  };

  //#endregion 

  return (
    <Fragment>
      <div ref={node => this_ref.current = node} >
          <ProductSearchBox ref={searchProductInfoRef} disabled={false} isFocused={isFocused} keyword={searchText}
          onChange={onSearchChange}
          onFocus={onSearchFocus} onClickInput={() => { setIsShowResult(true); }}
          onBlur={onSearchLostFocus} onClearSearch={handleKeywordClear} />
        {
          isShowResult && searchText.length >= 2 &&
          <ProductSearchResult products={products}
            isLoading={isLoading}
            searchText={searchText}
            onNextPage={onNextPage}
            onClick={handleSelectedVariant}
            setMouseOverResult={setMouseOverResult}
            {...props} />
        }

      </div>
    </Fragment>
  );
};



ProductInfo.propTypes = {
  isExact: PropTypes.bool
};