import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom";
import App from "App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./contexts/app";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { CookiesProvider } from "react-cookie";
import { SWRConfig } from "swr";
const history = createBrowserHistory();

ReactDOM.render(
  <CookiesProvider>
    <SWRConfig
      value={{
        fetcher: (resource, init) =>
          fetch(resource, init).then((res) => res.json()),
      }}
    >
      <AppProvider>
        <Router history={history}>
          <App />
        </Router>
      </AppProvider>
    </SWRConfig>
  </CookiesProvider>,
  document.getElementById("root"),
);

reportWebVitals();
