import React, { Component } from "react";
import PropTypes from "prop-types";
import { Loading } from "@haravan/react-components";
import { ImageCustom } from "component-v2/ImageCustom";

export default class ImageLazyLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      productModel: {},
    };
  }

  getImageByProductId = async (productId) => {
    try {
      const result = await this.props.appContext.callAPI(
        "get",
        `/call/com_api/products/${productId}/images/feature`,
      );
      return result.data || {};
    } catch (error) {
      return {};
    }
  };

  async componentDidMount() {
    try {
      const { product_id } = this.props;
      const product = await this.getImageByProductId(product_id);
      this.setState({
        isError: false,
        isLoading: false,
        productModel: product,
      });
    } catch (e) {
      this.setState({
        isError: true,
        isLoading: false,
        productModel: {},
      });
    }
  }

  render() {
    const { isLoading, productModel } = this.state;
    const { style } = this.props;
    if (isLoading) {
      return <Loading size="pico" />;
    } else {
      return (
        <ImageCustom
          size="small"
          alt="haravaner"
          src={productModel?.imageUrl}
        />
        // <ImageResize
        //     size='small'
        //     alt='haravaner'
        //     src={productModel.imageUrl}
        //     imageError={Assets.icon.img_product_report}
        //     className={productModel.imageUrl ? style : ""}
        // />
      );
    }
  }
}

ImageLazyLoad.propTypes = {
  product_id: PropTypes.number,
};
