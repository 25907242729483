import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getDecimalValue, isAllowDecimal } from "../../utils/Commons";
import { isNil } from "lodash";
import { CurrencyUtils } from "utils";
import "./index.css";
import { useApp } from "contexts/app";

function InputDecimal({
  id,
  placeholder,
  name,
  className,
  isDisabled,
  isReadOnly,
  value,
  onBlur,
  onChange,
  min,
  max,
  allowNegative,
  onFocus,
  maxWidth,
}) {
  const { shop_info } = useApp();
  const [_value, setValue] = useState(getDecimalValue(shop_info, value));

  useEffect(() => {
    setValue(getDecimalValue(shop_info, value));
  }, [value]);

  useEffect(() => {
    if (_value > max) {
      setValue(max);
    }
  }, [_value, max]);

  const numberReturnFormated = (e) => {
    let value = getDecimalValue(shop_info, e.target.value);
    if (!isNil(min) && value < min) value = min;
    else if (!isNil(max) && value > max) value = max;
    return value;
  };

  const handleOnBlur = (e) => {
    let _value = numberReturnFormated(e);
    if (_value !== value) _value = value;
    let event = { ...e, target: { ...e.target, value: _value } };
    setValue(_value);
    onBlur(event, _value);
  };

  const onKeyPress = (e) => {
    // not allow input decimal in prod
    if (
      !isAllowDecimal(shop_info) &&
      !new RegExp(allowNegative ? /^-?[\d]*$/ : /^[\d]*$/).test(e.key)
    ) {
      e.preventDefault();
    }
    const NUMBER_DOT_COMMA = allowNegative ? /^-?[\d,.]*$/ : /^[\d,.]*$/;
    const fieldValue = e.target.value;
    const fieldHasCommaOrDot =
      fieldValue.includes(".") || fieldValue.includes(",");
    const keyIsCommaOrDot = e.key === "." || e.key === ",";

    if (
      !NUMBER_DOT_COMMA.test(e.key) ||
      (keyIsCommaOrDot && fieldHasCommaOrDot) ||
      (allowNegative && e.key === "-" && e.target.selectionStart !== 0)
    )
      e.preventDefault();

    e.target.value = fieldValue.replace(",", ".");
  };

  const onTextChange = (e) => {
    if (!isAllowDecimal(shop_info)) {
      e.target.value = CurrencyUtils.numberWithCommasActive(e.target.value);
    }
    setValue(e.target.value);
    const value = numberReturnFormated(e);
    let event = { ...e, target: { ...e.target, value: value } };
    onChange(event, value);
  };

  return (
    <input
      type="text"
      id={id}
      style={{ maxWidth: `${maxWidth}px` }}
      className={`input-decimal ${className}`}
      placeholder={placeholder}
      disabled={isDisabled}
      readOnly={isReadOnly}
      name={name}
      value={_value}
      onKeyPress={(e) => onKeyPress(e)}
      onChange={(e) => onTextChange(e)}
      onBlur={(e) => handleOnBlur(e)}
      onFocus={onFocus}
    />
  );
}

InputDecimal.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  allowNegative: false,
  max: Infinity,
};

InputDecimal.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  allowNegative: PropTypes.bool,
};

export default InputDecimal;
