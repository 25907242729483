import { FormattedMessage } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import DateTimeShow from "component-v2/datetime-show";
import { DateTimeShowType } from "infrastructures/format-helpers";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";

export default function RefundOrderModal({
  showRefundModal,
  setShowRefundModal,
  reportOrderRefundTable,
}) {
  const body = () => {
    return (
      <table className="table table-hover mb-0 report-table">
        <thead>
          <tr>
            <th className="px-4 py-3">
              <FormattedMessage
                id="reports.Mã hóa đơn"
                defaultMessage="Mã hóa đơn"
              />
            </th>
            <th className="px-4 py-3 text-right">
              <FormattedMessage
                id="reports.Ngày hoàn trả"
                defaultMessage="Ngày hoàn trả"
              />
            </th>
            <th className="px-4 py-3 text-right">
              <FormattedMessage
                id="reports.Tiền hoàn trả"
                defaultMessage="Tiền hoàn trả"
              />
            </th>
            <th className="px-4 py-3 text-right">
              <FormattedMessage
                id="reports.Kênh bán hàng"
                defaultMessage="Kênh bán hàng"
              />
            </th>
          </tr>
        </thead>
        <tbody className="scrollable-y">
          {reportOrderRefundTable.map(
            (order, index) =>
              order.ReturnsAmount !== 0 && (
                <tr key={index}>
                  <td className="px-4 py-3">{order.OrderNumber}</td>
                  <td className="px-4 py-3 text-right">
                    <DateTimeShow
                      date={order.Hour || order.Day}
                      type={DateTimeShowType.custom}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </td>
                  <td className="px-4 py-3 text-right">
                    <CurrencyShow value={order.ReturnsAmount} />
                  </td>
                  <td className="px-4 py-3 text-right">
                    {order.SourceName || ""}
                  </td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    );
  };
  const footer = () => {
    return (
      <div className="d-flex justify-content-end align-content-center">
        <Button light size="md" onClick={() => setShowRefundModal(false)}>
          <FormattedMessage id="systems.popups.Hủy" defaultMessage="Hủy" />
          <span className="ml-3 keyboard-ESC">ESC</span>
        </Button>
      </div>
    );
  };
  return (
    <GlobalModal
      headerTitle={"Hoàn trả"}
      isTitleCenter
      show={showRefundModal}
      setShow={setShowRefundModal}
      body={body()}
      footer={footer()}
      size="lg"
      className="refund-order-modal"
    />
  );
}
