import React from "react";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import OrderCancelModal from "../components/order-cancel-modal";
import OrderPaymentConfirmModal from "../components/order-payment-confirm-modal";
import OrderShipmentConfirmModal from "../components/order-shipment-confirm-modal";
import PrintButton from "../components/print-button";
import Icon from "component-v2/icons/icons";
import {
  paymentStatusValue,
  shipmentStatusValue,
} from "screens-v2/orders/utils/utils";
import { FormattedMessage } from "react-intl";
import Button from "component-v2/buttons";
import RefundOtherLocationModal from "screens-v2/refunds-v2/components/refund-other-location-modal";
import { useApp } from "contexts/app";
import { ModalQRPayment } from "screens-v2/carts-v2/RightCart/components/modalQRPayment";
import { useLocation } from "react-router-dom";
export default function OrderDetailHeader({ order, mutate, session }) {
  const { current_location, shop_setting } = useApp();
  const intl = useIntl();
  const history = useHistory();
  const [showStatuses, setShowStatuses] = useState({
    acceptCancel: false,
    acceptRefund: false,
    acceptConfirmFulfill: false,
    acceptConfirmPayment: false,
  });
  const [showModalCannotRefund, setShowModalCannotRefund] = useState(false);
  const {
    contentValue: contentValueShipment,
    badgeValue: badgeValueShipment,
    colorValue: colorIconShipment,
  } = React.useMemo(
    () => shipmentStatusValue(order.shipmentStatus, order.fulfilledStatus),
    [order],
  );
  const {
    contentValue: contentValuePayment,
    badgeValue: badgeValuePayment,
    colorValue: colorIconPayment,
  } = React.useMemo(() => paymentStatusValue(order.paymentStatusId), [order]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [qrPaymentDetails, setQrPaymentDetails] = useState(null);
  const [showQRPaymentModal, setShowQRPaymentModal] = useState(false);
  const [hasQRPayment, setHasQRPayment] = useState(false);
  const [showPaymentConfirm, setShowPaymentConfirm] = useState(
    false || !!params?.get("confirmPayment"),
  );
  const [showCancelOrder, setShowCancelOrder] = useState(
    false || !!params?.get("cancelOrder"),
  );
  useEffect(() => {
    let acceptCancel = false;
    let acceptRefund = false;
    let acceptConfirmFulfill = false;
    let acceptConfirmPayment = false;
    if (order) {
      // check accept cancel
      if (
        shop_setting.allowCancelOrder &&
        !order.isCancel &&
        !(
          order.shipmentStatus === 4 &&
          [2, 3, 4, 6, 8].indexOf(order.paymentStatusId) !== -1
        )
      )
        acceptCancel = true;
      // check accept refund
      let existP = order.orderProducts.find((item) => {
        return item.quantity - item.restockQuantity - item.returnedQuantity > 0;
      });
      if (existP) {
        acceptRefund =
          order.isShowRefund &&
          order.shipmentStatus === 4 &&
          (order.paymentStatusId === 2 ||
            (order.paymentStatusId === 3 &&
              !shop_setting.refundOnlyOnceEnable));
      }
      // check accept confirm fulfill
      if (order.omniOrderStatus !== 11 && order.shipmentStatus === 7)
        acceptConfirmFulfill = true;
      if (
        order.omniOrderStatus !== 11 &&
        [4, 5].includes(order.paymentStatusId)
      )
        acceptConfirmPayment = true;
    }
    setShowStatuses({
      acceptCancel,
      acceptRefund,
      acceptConfirmFulfill,
      acceptConfirmPayment,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);
  function checkAcceptRefund(order, history) {
    const currentLocation = current_location;
    if (
      !shop_setting.allowRefundOrderAllLocation &&
      order.locationId !== currentLocation.id
    ) {
      setShowModalCannotRefund(true);
    } else {
      setShowModalCannotRefund(false);
      history.push(
        getConstantLinkDetail(CONSTANT_LINKS.route_refund_order, order.orderId),
      );
    }
  }
  return (
    <div className="d-flex justify-content-between">
      <div>
        <div className="d-flex flex-column">
          <span className="font-weight-semibold">Đơn {order.orderNumber}</span>
          <div className="l-mt-4">
            {order.omniOrderStatus === 11 ? (
              <Badge className="l-mr-4 l-mb-4" light rounded status={"red"}>
                <FormattedMessage
                  id="orders.searches.Đã hủy"
                  defaultMessage="Đã hủy"
                />
              </Badge>
            ) : (
              ""
            )}
            {order.omniOrderStatus !== 11 && order.isArchived ? (
              <Badge className="l-mr-4 l-mb-4" light rounded status={"red"}>
                <FormattedMessage
                  id="orders.searches.Lưu trữ"
                  defaultMessage="Lưu trữ"
                />
              </Badge>
            ) : (
              ""
            )}
            {!order.isArchived ? (
              <>
                {contentValuePayment && (
                  <Badge
                    className="l-mr-4 l-mb-4"
                    light
                    rounded
                    status={badgeValuePayment}
                  >
                    <Icon
                      size="xs"
                      name={"payments"}
                      color={colorIconPayment}
                      className="mr-2"
                    />
                    {contentValuePayment}
                  </Badge>
                )}
                {contentValueShipment ? (
                  <Badge
                    className="l-mr-4 l-mb-4"
                    light
                    rounded
                    status={badgeValueShipment}
                  >
                    <Icon
                      size="xs"
                      name={"local_shipping"}
                      color={colorIconShipment}
                      className="mr-2"
                    />
                    <FormattedMessage
                      id={`carts.orders.shipments.${contentValueShipment}`}
                      defaultMessage={contentValueShipment}
                    />
                  </Badge>
                ) : null}
              </>
            ) : (
              ""
            )}
          </div>
          {order.refOrderNumber && order.refOrderId ? (
            <div>
              <p className="text-default mb-2 mb-lg-0">
                <span className="order-info--header-title font-size-12">
                  {intl.formatMessage({
                    id: "orders.searches.Đơn hàng được đặt lại từ đơn",
                    defaultMessage: "Đơn hàng được đặt lại từ đơn",
                  })}{" "}
                  <a
                    href={`/orders/${order.refOrderId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <b>{order.refOrderNumber}</b>
                  </a>
                </span>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="d-flex flex-row align-self-center">
        <span className="align-self-center l-mr-8">
          <PrintButton
            isDetail={true}
            orderId={null}
            orderData={order}
            sessionData={session?.order}
            isOrderPrintBill={true}
            placementTooltip="left"
          />
        </span>
        {showStatuses.acceptCancel ? (
          <span className="l-mr-8 lign-self-center">
            <OrderCancelModal
              show={showCancelOrder}
              setShow={setShowCancelOrder}
              order={order}
              onSucceeded={mutate}
            />
          </span>
        ) : null}
        {showStatuses.acceptConfirmPayment ? (
          <>
            <ModalQRPayment
              show={showQRPaymentModal}
              paymentDetails={qrPaymentDetails}
              setPaymentDetails={setQrPaymentDetails}
              setShow={(value) => {
                if (!value) {
                  setShowQRPaymentModal(false);
                }
              }}
              changeMethodAction={() => {
                setShowQRPaymentModal(false);
                setShowPaymentConfirm(true);
              }}
              cancelOrderAction={() => {
                setShowQRPaymentModal(false);
                setShowPaymentConfirm(false);
                setShowCancelOrder(true);
                mutate();
              }}
              onTransactionCompleted={(orderData) => {
                setShowQRPaymentModal(false);
                setShowPaymentConfirm(false);
                mutate();
              }}
            />
            <OrderPaymentConfirmModal
              show={showPaymentConfirm}
              setShow={setShowPaymentConfirm}
              order={order}
              onSucceeded={mutate}
              hasQRPayment={hasQRPayment}
              setHasQRPayment={setHasQRPayment}
              showQRPaymentModal={showQRPaymentModal}
              setShowQRPaymentModal={setShowQRPaymentModal}
              setQrPaymentDetails={setQrPaymentDetails}
              session={session}
            />
          </>
        ) : null}
        {showStatuses.acceptRefund &&
        (shop_setting.allowRefundOrderAllLocation ||
          (order?.locationId === current_location.id &&
            order?.sourceName === "pos")) ? (
          <Button
            light
            status="primary"
            onClick={() => checkAcceptRefund(order, history)}
            title={intl.formatMessage({
              id: "orders.searches.Đổi trả",
              defaultMessage: "Đổi trả",
            })}
          />
        ) : null}
        {!showStatuses.acceptRefund && showStatuses.acceptConfirmFulfill ? (
          <OrderShipmentConfirmModal
            orderId={order.orderId}
            onSucceeded={mutate}
            order={order}
          />
        ) : null}
      </div>
      {showModalCannotRefund ? (
        <RefundOtherLocationModal
          orderNumber={order.orderNumber}
          locationName={order.locationName}
        />
      ) : null}
    </div>
  );
}
