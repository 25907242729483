import { cancelOrder } from "api/order-repository";
import { useShift } from "contexts/shift/shift-context";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";
import { Input, InputHasIcon } from "@haravan/react-components";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";

export default function OrderCancelModal({
  order,
  onSucceeded,
  show,
  setShow,
}) {
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  const { callAPI, shop_setting } = useApp();
  const { checkShiftReady, shift } = useShift();
  const [note, setNote] = useState("");
  const [orderNumberCheck, setOrderNumberCheck] = useState("");
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let after = orderNumberCheck?.trim();
    if (order.orderNumber === after) setReady(true);
    else setReady(false);
  }, [order.orderNumber, orderNumberCheck]);
  async function callCancel() {
    setLoading(true);
    let cancelNote = "POS";
    if (note?.trim().length > 0) {
      cancelNote = `${cancelNote} - ${note.trim()}`;
    }
    let result = await cancelOrder(
      shop_setting,
      callAPI,
      order,
      cancelNote,
      shift.current_shift,
    );
    if (result.data) {
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.Hủy đơn hàng thành công!`,
          defaultMessage: "Hủy đơn hàng thành công!",
        }),
      );
      setShow(false);
      onSucceeded?.();
    } else {
      showGlobalToast(
        "error",
        result.error ||
          intl.formatMessage({
            id: `errors.Hủy đơn hàng thất bại!`,
            defaultMessage: result.error,
          }),
      );
      setLoading(false);
    }
  }
  const body = () => {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <label className="margin-0">
              {intl.formatMessage({
                id: "carts.orders.Nhập lại đúng mã đơn hàng",
                defaultMessage: "Nhập lại đúng mã đơn hàng",
              })}
            </label>
            <Input
              type="text"
              className="cart-input text-black pl-3"
              placeholder={intl.formatMessage({
                id: "carts.orders.Nhập mã đơn hàng",
                defaultMessage: "Nhập mã đơn hàng",
              })}
              onChange={(value) => setOrderNumberCheck(value)}
              value={orderNumberCheck}
            />
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12">
            <label className="margin-0">
              {intl.formatMessage({
                id: "carts.orders.Ghi chú hủy",
                defaultMessage: "Ghi chú hủy",
              })}
              <span className="ml-2 text-secondary">{`(Không bắt buộc)`}</span>
            </label>
            <InputHasIcon
              type="text"
              prefix={"POS - "}
              value={note}
              onChange={(value) => setNote(value)}
              className="cart-input text-black pl-3"
            />
          </div>
        </div>
      </div>
    );
  };

  const footer = () => {
    return (
      <>
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <Button
            className="mr-2 light text-uppercase"
            onClick={() => setShow(false)}
            title="Hủy bỏ"
          />
          <Button
            status="danger"
            className="text-uppercase"
            disabled={!ready || loading}
            onClick={callCancel}
            title="Hủy đơn hàng"
          />
        </div>
      </>
    );
  };

  return (
    <GlobalModal
      headerTitle={
        <span>
          {intl.formatMessage({
            id: "carts.orders.Hủy đơn hàng",
            defaultMessage: "Hủy đơn hàng",
          })}{" "}
          {order.orderNumber}
        </span>
      }
      footer={footer()}
      onCancel={() => setShow(false)}
      body={body()}
      show={show}
      setShow={setShow}
    >
      <Button
        status="danger"
        light
        title={"Hủy"}
        onClick={() => checkShiftReady(true, () => setShow(true))}
      />
    </GlobalModal>
  );
}
