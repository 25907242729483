import moment from "moment";
import numeral from "numeral";

export const NumberShowType = {
  // số nguyên
  integer: "int",
  // số có lẻ sau chấm thập phân
  decimal: "dec",
  // tương đối
  relative: "relative",
  // theo custom format
  custom: "custom",
};
export const CurrencyShowType = {
  // hiển thị số
  absolute: "absolute",
  // hiển thị tương đối
  relative: "relative",
  // theo custom format
  custom: "custom",
};
export const PercentShowType = {
  // số nguyên
  integer: "int",
  // có lẻ sau chấm thập phân
  decimal: "dec",
  // theo custom format
  custom: "custom",
};

export function toFormattedNumber(
  value,
  type = NumberShowType.decimal,
  format,
) {
  switch (type) {
    case NumberShowType.relative:
      return numeral(value).format("0,0.[0]a");
    case NumberShowType.integer:
      return numeral(value).format("0,0");
    case NumberShowType.decimal:
      return numeral(value).format("0,0.[000]");
    case NumberShowType.custom:
    default:
      if (!format || !format.trim()) format = "0,0.[000]";
      return numeral(value).format(format);
  }
}
export function toFormattedCurrency(
  value,
  type = CurrencyShowType.absolute,
  format,
) {
  switch (type) {
    case CurrencyShowType.relative:
      let locale = numeral.locale();
      switch (locale) {
        case "en":
          return numeral(value).format("$0,0.[0]a");
        case "vi":
        default:
          return numeral(value).format("0,0a");
      }
    case CurrencyShowType.absolute:
      return numeral(value).format();
    case CurrencyShowType.custom:
    default:
      if (!format || !format.trim()) format = "0,0$";
      return numeral(value).format(format);
  }
}
export function toFormattedPercent(
  value,
  type = PercentShowType.integer,
  format,
) {
  switch (type) {
    case PercentShowType.integer:
      return numeral(value).format("0,0") + "%";
    case PercentShowType.decimal:
      return numeral(value).format("0,0.[0]") + "%";
    case PercentShowType.custom:
    default:
      if (!format || !format.trim()) format = "0,0.[0]";
      return numeral(value).format(format) + "%";
  }
}

export function initNumeral(locale, currency) {
  let abbreviations = null;
  let _currency = { symbol: "₫" };
  switch (currency) {
    case "USD":
      _currency.symbol = "$";
      numeral.defaultFormat("$0,0.[00]");
      numeral.locale("en");
      break;
    case "PHP":
      _currency.symbol = "₱";
      numeral.defaultFormat("$0,0.[00]");
      numeral.locale("en");
      break;
    case "VND":
    default:
      numeral.defaultFormat("0,0");
      numeral.locale("vi");
      break;
  }
  switch (locale) {
    case "en":
      abbreviations = {
        thousand: "K",
        million: "M",
        billion: "B",
        trillion: "T",
      };
      break;
    case "vi":
    default:
      abbreviations = {
        thousand: " nghìn",
        million: " triệu",
        billion: " tỷ",
        trillion: " nghìn tỷ",
      };
      break;
  }
  if (!numeral.locales["en"]) {
    numeral.register("locale", "en", {
      delimiters: { thousands: ",", decimal: "." },
      abbreviations,
      currency: _currency,
    });
  } else {
    numeral.locales["en"] = {
      delimiters: { thousands: ",", decimal: "." },
      abbreviations,
      currency: _currency,
    };
  }
  if (!numeral.locales["vi"]) {
    numeral.register("locale", "vi", {
      delimiters: { thousands: ",", decimal: "." },
      abbreviations,
      ordinal: function () {
        return ".";
      },
      currency: _currency,
    });
  } else {
    numeral.locales["vi"] = {
      delimiters: { thousands: ",", decimal: "." },
      abbreviations,
      ordinal: function () {
        return ".";
      },
      currency: _currency,
    };
  }
}

export const DateTimeShowType = {
  // follow moment's relativeTime
  relative: "relative",
  // Day of Week
  day_of_week: "day_of_week",
  // HH:mm A
  time: "time",
  // HH:mm:ss A
  long_time: "long_time",
  // DD/MM/YYYY
  date: "date",
  // follow moment's calendar
  long_date: "long_date",
  // relative within an hour, above 1 hour: long_date
  mixed_long_date: "mixed_long_date",
  // DD/MM/YYYY HH:mm:ss
  date_time: "date_time",
  // custom
  custom: "custom",
};

var this_timezone = 7;

export const toFormattedDate = (
  date,
  date_show_type = DateTimeShowType.mixed_long_date,
  format = "LLLL",
) => {
  switch (date_show_type) {
    case DateTimeShowType.relative:
      return moment(date).utcOffset(this_timezone).fromNow();
    case DateTimeShowType.day_of_week:
      return moment(date).utcOffset(this_timezone).format("dddd");
    case DateTimeShowType.time:
      return moment(date).utcOffset(this_timezone).format("LT");
    case DateTimeShowType.long_time:
      return moment(date).utcOffset(this_timezone).format("LTS");
    case DateTimeShowType.date:
      return moment(date).utcOffset(this_timezone).format("L");
    case DateTimeShowType.long_date:
      return moment(date).utcOffset(this_timezone).calendar();
    case DateTimeShowType.date_time:
      return moment(date)
        .utcOffset(this_timezone)
        .format("DD/MM/YYYY HH:mm:ss");
    case DateTimeShowType.custom:
      return moment(date).format(format);
    case DateTimeShowType.mixed_long_date:
    default:
      let diffMinutes = moment(new Date()).diff(moment(date), "minutes");
      if (diffMinutes >= 0 && diffMinutes < 60) {
        return moment(date).utcOffset(this_timezone).fromNow();
      }
      return moment(date).utcOffset(this_timezone).calendar();
  }
};

// type: 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years'
export const getDuration = (fromDate, toDate, type = "days") => {
  let end = moment(toDate);
  let start = moment(fromDate);
  let result = end.diff(start, type);
  return result ? Math.abs(result) : 0;
};

export const getFirstDateOfMonth = (date) => {
  let d = new Date(+date);
  d.setDate(1);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const getLastDateOfMonth = (date) => {
  let mm = date.getMonth() + 1;
  let yy = date.getFullYear();
  return new Date(yy, mm, 0, 23, 59, 59, 999);
};
export const getDateWithStartTime = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth();
  let yy = date.getFullYear();
  return new Date(yy, mm, dd, 0, 0, 0, 0);
};
export const getDateWithEndTime = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth();
  let yy = date.getFullYear();
  return new Date(yy, mm, dd, 23, 59, 59, 999);
};

export const initMoment = (locale, timezone = 7) => {
  this_timezone = timezone;
  moment.relativeTimeThreshold("s", 60);
  moment.relativeTimeThreshold("m", 60);
  moment.relativeTimeThreshold("h", 24);
  switch (locale) {
    case "en":
      moment.updateLocale(locale, {
        longDateFormat: {
          LT: "HH:mm",
          LTS: "HH:mm:ss",
          L: "DD/MM/YYYY",
          LL: "Do MMMM YYYY",
          LLL: "Do MMMM YYYY LT",
          LLLL: "dddd, Do MMMM YYYY LT",
        },
        calendar: {
          sameDay: "[Today] LT",
          nextDay: "[Tommorow] LT",
          nextWeek: "L LT",
          lastDay: "[Yesterday] LT",
          lastWeek: `L LT`,
          sameElse: `L LT`,
        },
      });
      break;
    case "vi":
    default:
      moment.updateLocale("vi", {
        months: [
          "Tháng 1",
          "Tháng 2",
          "Tháng 3",
          "Tháng 4",
          "Tháng 5",
          "Tháng 6",
          "Tháng 7",
          "Tháng 8",
          "Tháng 9",
          "Tháng 10",
          "Tháng 11",
          "Tháng 12",
        ],
        weekdays: [
          "Chủ nhật",
          "Thứ hai",
          "Thứ ba",
          "Thứ tư",
          "Thứ năm",
          "Thứ sáu",
          "Thứ bảy",
        ],
        longDateFormat: {
          LT: "HH:mm",
          LTS: "HH:mm:ss",
          L: "DD/MM/YYYY",
          LL: "Do MMMM YYYY",
          LLL: "Do MMMM YYYY LT",
          LLLL: "dddd, Do MMMM YYYY LT",
        },
        calendar: {
          sameDay: "[Hôm nay] LT",
          nextDay: "[Ngày mai] LT",
          nextWeek: "L LT",
          lastDay: "[Hôm qua] LT",
          lastWeek: `L LT`,
          sameElse: `L LT`,
        },
        relativeTime: {
          future: "%s trước",
          past: "%s trước",
          s: "vài giây",
          ss: "%d giây",
          m: "1 phút",
          mm: "%d phút",
          h: "một giờ",
          hh: "%d giờ",
          d: "một ngày",
          dd: "%d ngày",
          M: "một tháng",
          MM: "%d tháng",
          y: "một năm",
          yy: "%d năm",
        },
        // meridiem: function (hour) {
        //     return hour < 12 ? 'SA' : 'CH';
        // },
        invalidDate: "Ngày không hợp lệ",
      });
      break;
  }
};
