import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useIntl } from 'react-intl';
import { cloneDeep, debounce, isNil } from "lodash";
import { useRefundContext } from "../index";

import { RefundCartItem } from "./refund-carts/refund-cart-item";
import { RefundCartEmpty } from "./refund-carts/refund-list-empty";
import "./refund-cart.css";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import { getDecimalValue } from "utils/Commons";
import { productQuantityMax } from "screens-v2/carts-v2/cart-helpers.service";
import { useApp } from "contexts/app";

export const RefundCartList = (props) => {
  const intl = useIntl();
  const { shop_info, shop_setting } = useApp();
  const { current_shopping_cart, current_refund, loadingRefund, updateItemCurrentCart } = useRefundContext();
  const [itemSelected, setItemSelected] = useState(null);
  const [orderLines, setOrderLines] = useState([]);

  useEffect(() => {
    setOrderLines(current_shopping_cart?.lineItems || []);
  }, [current_shopping_cart?.lineItems]);

  useGlobalHotkeys({
    keys: [HOTKEYS.HOME, HOTKEYS.DOWN, HOTKEYS.UP],
    scopes: HOTKEY_SCOPES.CART,
    enabled: !loadingRefund,
    callback: (e) => {
      const inputEl = document.querySelectorAll(".refund-quantity-cart-item");
      const hotkeyAction = e.key;
      if (hotkeyAction === HOTKEY_EVENTS.HOME) {
        if (inputEl?.length) {
          inputEl[0].focus();
          setItemSelected(0);
        }
      }
      if (hotkeyAction === HOTKEY_EVENTS.DOWN && !isNil(itemSelected)) {
        if (itemSelected === inputEl?.length - 1) {
          inputEl[0].focus();
          setItemSelected(0);
        } else {
          inputEl[itemSelected + 1].focus();
          setItemSelected(itemSelected + 1);
        }
      }
      if (hotkeyAction === HOTKEY_EVENTS.UP && !isNil(itemSelected)) {
        if (!itemSelected) {
          inputEl[inputEl?.length - 1].focus();
          setItemSelected(inputEl?.length - 1);
        } else {
          inputEl[itemSelected - 1].focus();
          setItemSelected(itemSelected - 1);
        }
      }
    },
  });

  useGlobalHotkeys({
    keys: [HOTKEYS.RIGHT, HOTKEYS.LEFT],
    scopes: HOTKEY_SCOPES.CART,
    enabled: !isNil(itemSelected),
    callback: (e) => {
      // e.preventDefault();
      const hotkeyAction = e.key;
      const newOrderLines = cloneDeep(orderLines);

      if (hotkeyAction === HOTKEY_EVENTS.RIGHT && !isNil(itemSelected)) {
        if (newOrderLines[itemSelected].quantity <= productQuantityMax) {
          newOrderLines[itemSelected].quantity = getDecimalValue(
            shop_info,
            newOrderLines[itemSelected].quantity + 1
          );
          setOrderLines(newOrderLines);
          debounceOnChange(
            newOrderLines[itemSelected],
            itemSelected,
            newOrderLines[itemSelected].quantity
          );
        }
      }
      if (hotkeyAction === HOTKEY_EVENTS.LEFT && !isNil(itemSelected)) {
        if (newOrderLines[itemSelected].quantity > 1) {
          newOrderLines[itemSelected].quantity = getDecimalValue(
            shop_info,
            newOrderLines[itemSelected].quantity - 1
          );
          setOrderLines(newOrderLines);
          debounceOnChange(
            newOrderLines[itemSelected],
            itemSelected,
            newOrderLines[itemSelected].quantity
          );
        }
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((item, index, value) => {
      updateItemCurrentCart(item, index, value, ["quantity"]);
    }, 500),
    [current_shopping_cart?.lineItems]
  );

  return (
    <Fragment>

      <div className="refund-carts-cart scrollable h-100">
        {orderLines.length === 0 ? <RefundCartEmpty intl={intl} /> : <div className="">
          {
            orderLines.map((item, index) => { return (<RefundCartItem intl={intl} key={index} item={item} index={index} setItemSelected={setItemSelected} />); })
          }
        </div>}
      </div>
    </Fragment>

  );
};

