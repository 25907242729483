import Icon from "component-v2/icons/icons";
import {
  toFormattedCurrency,
  toFormattedNumber,
} from "infrastructures/format-helpers";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import OrderDetailSummaryTransactions from "./order-detail-summary-transactions";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { sumBy } from "lodash";
import Tooltip from "rc-tooltip";
import OrderNoteModal from "../components/order-note-modal";
import { DiscountAllocationType } from "screens-v2/carts-v2/cart-helpers.service";

export default function OrderDetailSummary({ order, mutate, session }) {
  const intl = useIntl();
  const [showMoreVat, setShowMoreVat] = useState(true);
  const [isPayment, setIsPayment] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const showPayment = () => {
    setIsPayment((prev) => !prev);
  };
  const showRefund = () => {
    setIsRefund((prev) => !prev);
  };
  const showMoreDiscount = () => setShowDiscount((prev) => !prev);
  let vatValue = {
    vatSum: order.totalTaxNotIncluded + order.totalTaxIncluded,
    sumIncludedVat: order.totalTaxIncluded,
    sumNotIncludedVat: order.totalTaxNotIncluded,
  };
  const discountAllocations = useMemo(
    () =>
      (!!session?.discountAllocations?.length
        ? session?.discountAllocations?.filter(
            (d) =>
              d.type !== DiscountAllocationType.MEMBERSHIP &&
              d.type !== DiscountAllocationType.PRODUCT &&
              d.type !== DiscountAllocationType.REDEEM_POINT,
          )
        : order.discountAllocations
      ).map((d) => ({
        ...d,
        name: d.name || d.code,
      })),
    [session, order],
  );
  const multiDiscountTotalAmount = useMemo(() => {
    if (!!discountAllocations?.length) {
      return discountAllocations?.reduce((total, curVal) => {
        return total + curVal?.amount;
      }, 0);
    }
  }, [discountAllocations]);
  const renderDiscountAllocations = () => {
    return (
      <div className="d-flex flex-column">
        {discountAllocations?.map((discount) => (
          <div
            className="w-100 pb-2 d-flex justify-content-between"
            key={discount?.discountId}
          >
            <Badge
              className="border-0 font-weight-semibold text-truncate"
              light
              status={"gray"}
            >
              <Icon
                size="1.2"
                name="card_giftcard"
                color="var(--coolgray600)"
              />{" "}
              {`${discount?.name}`}
            </Badge>
            <CurrencyShow
              className={"order-summary-value text-right content-tertiary"}
              value={discount?.amount}
            />
          </div>
        ))}
      </div>
    );
  };
  const renderVatCondition = () => {
    if (vatValue.vatSum === 0) return null;
    let content = null;

    // trường hợp cả phí vận chuyển && phí mặc định đều có
    if (vatValue.sumNotIncludedVat <= 0 && vatValue.sumIncludedVat > 0) {
      content = (
        <span className="font-size-12 content-tertiary">
          (đã bao gồm trong giá)
        </span>
      );
      return content;
    } else if (vatValue.sumNotIncludedVat > 0 && vatValue.sumIncludedVat <= 0) {
      // content = <span className="l-section border-0">(giá chưa bao gồm thuế)</span>;
      return content;
    } else {
      content = (
        <div className="border-0 px-0 pb-0">
          {!!vatValue.sumIncludedVat && vatValue.sumIncludedVat > 0 && (
            <div className="d-flex flex-row justify-content-between my-2 font-size-12 text-secondary ml-4">
              <div>
                <span>Thuế đã bao gồm trong giá</span>
              </div>
              <div>
                {toFormattedCurrency(vatValue.sumIncludedVat)}
                {/* <CurrencyShow value={toFormattedCurrency(vatValue.sumIncludedVat)} /> */}
              </div>
            </div>
          )}
          {!!vatValue.sumNotIncludedVat && vatValue.sumNotIncludedVat > 0 && (
            <div className="d-flex flex-row justify-content-between my-2 font-size-12 text-secondary ml-4">
              <div>
                <span>Thuế phải trả</span>
              </div>
              <div>
                {toFormattedCurrency(vatValue.sumNotIncludedVat)}
                {/* <CurrencyShow value={toFormattedCurrency(vatValue.sumNotIncludedVat)} /> */}
              </div>
            </div>
          )}
        </div>
      );
    }

    return content;
  };
  return (
    <div className="right-main--cover">
      <div className="order-detail-note-wrapper">
        <div className="order-detail-note">
          <Icon name="assignment" color="var(--contentTertiary)" />
          <span className="l-ml-8 text-break">
            {order.orderNotes
              ? order.orderNotes
              : intl.formatMessage({
                  id: "orders.searches.Không có ghi chú cho đơn hàng này",
                  defaultMessage: "Không có ghi chú cho đơn hàng này",
                })}
          </span>
        </div>
        <OrderNoteModal order={order} onSucceed={mutate} />
      </div>
      <div className="order-detail-right-section">
        <div className="l-pb-4 d-flex flex-row">
          <div className="d-flex justify-content-start align-items-center order-summary-label">
            <p className="text-label">
              {intl.formatMessage({
                id: "orders.searches.Tổng tiền hàng",
                defaultMessage: "Tổng tiền hàng",
              })}
            </p>
            <Badge
              className="l-ml-8 border-0 font-weight-semibold"
              light
              status={"gray"}
            >
              {`${toFormattedNumber(
                sumBy(order.orderProducts, (product) => product.quantity) || 0,
              )} sản phẩm`}
            </Badge>
          </div>
          <CurrencyShow
            className={"order-summary-value text-right"}
            value={
              (order.newCheckout && order.subTotalAfterDiscount) ||
              order.subTotal
            }
          />
        </div>
        <div className="l-pt-4 l-pb-4 d-flex flex-row">
          <p className="text-label order-summary-label">
            {intl.formatMessage({
              id: "orders.searches.Phí vận chuyển",
              defaultMessage: "Phí vận chuyển",
            })}
          </p>
          <CurrencyShow
            className={"order-summary-value text-right"}
            value={order.orderShippingFee}
          />
        </div>
        <div className="l-pt-4 l-pb-4 d-flex flex-row">
          <div
            className={`text-label order-summary-label d-flex align-items-center flex-wrap ${
              discountAllocations?.length > 1 ? "cursor-pointer" : ""
            }`}
            onClick={showMoreDiscount}
          >
            {intl.formatMessage({
              id: "orders.searches.Giảm giá đơn hàng",
              defaultMessage: "Giảm giá đơn hàng",
            })}
            {discountAllocations?.length > 1 && (
              <>
                {` (${discountAllocations?.length}) `}
                {showDiscount ? (
                  <Icon name="keyboard_arrow_down" color="var(--coolgray600)" />
                ) : (
                  <Icon
                    className={"rotate--90"}
                    color="var(--coolgray600)"
                    name="keyboard_arrow_down"
                  />
                )}
              </>
            )}
            {discountAllocations?.length === 1 &&
              discountAllocations?.[0]?.type !==
                DiscountAllocationType.MANUAL_ORDER && (
                <Tooltip
                  placement="top"
                  showArrow={false}
                  overlay={<span>{discountAllocations[0]?.name}</span>}
                >
                  <div style={{ height: "2rem" }}>
                    <Badge
                      className="l-ml-8 border-0 font-weight-semibold text-truncate"
                      style={{ maxWidth: "140px" }}
                      light
                      status={"gray"}
                    >
                      <Icon
                        size="1.2"
                        name="card_giftcard"
                        color="var(--coolgray600)"
                      />{" "}
                      {discountAllocations[0]?.name}
                    </Badge>
                  </div>
                </Tooltip>
              )}
          </div>
          <CurrencyShow
            className={"order-summary-value text-right"}
            value={
              order.newCheckout
                ? multiDiscountTotalAmount
                : order.discountAmount
            }
          />
        </div>
        {discountAllocations?.length > 1 && (
          <>{showDiscount && renderDiscountAllocations()}</>
        )}
        {order?.redeemName && (
          <div className="l-pt-4 l-pb-4 d-flex flex-row">
            <p className="text-label order-summary-label">
              {order?.redeemName}
            </p>
            <span className={"order-summary-value text-right"}>
              {toFormattedCurrency(order?.redeemUsedAmount)}
            </span>
          </div>
        )}
        <div className="l-pt-4 l-pb-4 d-flex flex-column">
          <div className="d-flex flex-row">
            <p className={`text-label order-summary-label`}>
              {intl.formatMessage({
                id: "orders.searches.VAT",
                defaultMessage: "VAT",
              })}
              {vatValue.sumIncludedVat > 0 &&
                vatValue.sumNotIncludedVat > 0 && (
                  <span
                    onClick={() => {
                      setShowMoreVat((prev) => !prev);
                    }}
                    className="ml-2 cursor-pointer"
                  >
                    {showMoreVat ? (
                      <Icon
                        name="keyboard_arrow_down"
                        color="var(--coolgray600)"
                      />
                    ) : (
                      <Icon
                        className={"rotate--90"}
                        color="var(--coolgray600)"
                        name="keyboard_arrow_down"
                      />
                    )}
                  </span>
                )}
            </p>
            <CurrencyShow
              className={"order-summary-value text-right"}
              value={vatValue.vatSum}
            />
          </div>
          {showMoreVat ? renderVatCondition() : null}
        </div>
        <div className="l-pt-4 l-pb-4 d-flex flex-row">
          <p className="text-label order-summary-label">
            {intl.formatMessage({
              id: "orders.searches.Tiền khách phải thanh toán",
              defaultMessage: "Tiền khách phải thanh toán",
            })}
          </p>
          <span className={"order-summary-value text-right"}>
            <CurrencyShow
              className={"order-summary-value text-right"}
              value={order.totalPaid}
            />
          </span>
        </div>
        <div className="l-pt-4 l-pb-4 d-flex flex-column">
          <div className="d-flex flex-row">
            <p
              className={`${
                order.amountPaid !== 0 ? "cursor-pointer" : ""
              } text-label  order-summary-label`}
              onClick={showPayment}
            >
              {intl.formatMessage({
                id: "orders.searches.Tiền khách đã thanh toán",
                defaultMessage: "Tiền khách đã thanh toán",
              })}
              {order.amountPaid !== 0 ? (
                <span className="text-primary pointer ml-2 font-weight-normal cursor-pointer">
                  {isPayment ? (
                    <Icon
                      name="keyboard_arrow_down"
                      color="var(--coolgray600)"
                    />
                  ) : (
                    <Icon
                      className={"rotate--90"}
                      color="var(--coolgray600)"
                      name="keyboard_arrow_down"
                    />
                  )}
                </span>
              ) : null}
            </p>
            <CurrencyShow
              className={"order-summary-value text-right"}
              value={order.amountPaid}
            />
          </div>
          {isPayment && !!order?.transactions?.length
            ? order.transactions.map((transaction, index) => {
                return (
                  <OrderDetailSummaryTransactions
                    key={index}
                    transaction={transaction}
                    type="payment"
                  />
                );
              })
            : null}
        </div>

        {order.amountRefuned > 0 && (
          <div className="l-pt-4 l-pb-4 d-flex flex-column">
            <div className="d-flex flex-row">
              <p
                className={`${
                  order.amountRefuned !== 0 ? "cursor-pointer" : ""
                } text-label  order-summary-label`}
                onClick={showRefund}
              >
                {intl.formatMessage({
                  id: "orders.searches.Tiền đã hoàn trả khách",
                  defaultMessage: "Tiền đã hoàn trả khách",
                })}
                {order.amountRefuned !== 0 ? (
                  <span className="text-primary pointer ml-2 font-weight-normal cursor-pointer">
                    {isRefund ? (
                      <Icon
                        name="keyboard_arrow_down"
                        color="var(--coolgray600)"
                      />
                    ) : (
                      <Icon
                        className={"rotate--90"}
                        color="var(--coolgray600)"
                        name="keyboard_arrow_down"
                      />
                    )}
                  </span>
                ) : null}
              </p>
              <CurrencyShow
                className={"order-summary-value text-right"}
                value={-order.amountRefuned}
              />
            </div>

            {isRefund && order?.transactions?.length
              ? order.transactions.map((transaction, index) => {
                  return (
                    <OrderDetailSummaryTransactions
                      key={index}
                      transaction={transaction}
                      type="refund"
                    />
                  );
                })
              : null}
          </div>
        )}
        {order.marketPlaceExtraInfoModel &&
        order.marketPlaceExtraInfoModel.sellerIncome >= 0 ? (
          <>
            <div className="l-pt-4 l-pb-4 d-flex flex-row">
              <p className="text-label order-summary-label">
                {intl.formatMessage({
                  id: "orders.searches.Phí trả sàn TMĐT dự kiến",
                  defaultMessage: "Phí trả sàn TMĐT dự kiến",
                })}
              </p>
              <CurrencyShow
                className={"order-summary-value text-right"}
                value={Math.abs(
                  order.totalPaid -
                    order.marketPlaceExtraInfoModel.sellerIncome,
                )}
              />
            </div>
            <div className="l-pt-4 l-pb-4 d-flex flex-row font-weight-semibold">
              <p className="text-label  order-summary-label">
                {intl.formatMessage({
                  id: "orders.searches.Thực nhận",
                  defaultMessage: "Thực nhận",
                })}
              </p>
              <CurrencyShow
                className={"order-summary-value text-right"}
                value={Math.abs(
                  (order.amountPaid || 0) -
                    (order.amountRefuned || 0) -
                    (order.totalPaid -
                      order.marketPlaceExtraInfoModel.sellerIncome),
                )}
              />
            </div>
          </>
        ) : (
          <div className="l-pt-4 l-pb-4 d-flex flex-row font-weight-semibold">
            <p className="text-label  order-summary-label">
              {intl.formatMessage({
                id: "orders.searches.Thực nhận",
                defaultMessage: "Thực nhận",
              })}
            </p>
            <CurrencyShow
              className={"order-summary-value text-right"}
              value={Math.abs(order.totalRefunded)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
