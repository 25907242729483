import Button from "component-v2/buttons";
import { useShift } from "contexts/shift/shift-context";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import { forwardRef, useEffect, useState } from "react";
import { HOTKEYS, HOTKEY_SCOPES } from "models/constants";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import "../styles.css";
import { Loading } from "@haravan/react-components";
import { useRefundContext } from "screens-v2/refunds-v2";
import { Notes } from "../notes";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useIntl } from "react-intl";
import { useApp } from "contexts/app";

export const RefundPaymentBottomRight = forwardRef(({ refundOrder }, ref) => {
  const intl = useIntl();
  const {
    current_shopping_cart,
    current_refund,
    finalPrice,
    isSubmitRefundOrderWaiting,
    loadingRefund,
  } = useRefundContext();
  const { showGlobalToast } = useLayoutContext();
  const { checkShiftReady } = useShift();
  const { shop_setting } = useApp();

  const [hasModalOpen, setHasModalOpen] = useState(false);

  useEffect(() => {
    const modalRoot = document.querySelector("#root-modal-cover");
    if (modalRoot?.children?.length && !hasModalOpen) {
      setHasModalOpen(true);
    } else if (!modalRoot?.children?.length && hasModalOpen) {
      setHasModalOpen(false);
    }
  });

  useGlobalHotkeys({
    keys: [HOTKEYS.F9],
    callback: (e) => {
      checkShiftReady(true, () => {
        refundOrder();
      });
    },
    enabled:
      current_refund?.refund_quantity_total > 0 &&
      !(
        shop_setting?.onlyAllowExchange &&
        !current_shopping_cart?.lineItems?.length
      ),
    disabled: loadingRefund,
    scopes: HOTKEY_SCOPES.CART,
  });

  if (!current_shopping_cart?.summaries) {
    return null;
  }

  return (
    <div className="" ref={ref}>
      <Notes />
      <Button
        size="lg"
        className={"w-100 l-mt-8"}
        status={
          finalPrice() > 0 ||
          (finalPrice() === 0 && !current_shopping_cart?.lineItems?.length)
            ? "warning"
            : "primary"
        }
        onClick={() => {
          if (loadingRefund) {
            showGlobalToast(
              "default",
              intl.formatMessage({
                id: "notifications.Đang cập nhật giỏ hàng",
                defaultMessage: "Đang cập nhật giỏ hàng",
              }),
            );
            return;
          }
          checkShiftReady(true, () => {
            refundOrder();
          });
        }}
        disabled={
          current_refund?.refund_quantity_total <= 0 ||
          hasModalOpen ||
          (shop_setting?.onlyAllowExchange &&
            !current_shopping_cart?.lineItems?.length)
        }
        badge={HOTKEYS.F9}
      >
        {isSubmitRefundOrderWaiting ? (
          <Loading className="print-button--loading-btn m-auto" size="pico" />
        ) : (
          <span>
            <span>
              {finalPrice() > 0 ||
              (finalPrice() === 0 && !current_shopping_cart?.lineItems?.length)
                ? "Hoàn trả"
                : "Thanh toán"}
              {Math.abs(finalPrice()) !== 0 ? (
                <span className="ml-2">
                  {toFormattedCurrency(Math.abs(finalPrice()))}
                </span>
              ) : null}
            </span>
            <span className="l-button-badge ml-3">{HOTKEYS.F9}</span>
          </span>
        )}
      </Button>
    </div>
  );
});
