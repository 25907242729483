import React, { Fragment, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { debounce } from "lodash";
import { InputQuantity } from "component-v2/Input";
import CurrencyShow from "component-v2/currency-show";
import NumberShow from "component-v2/number-show";
// import {LotDateList} from "components";
import { getDecimalValue } from "utils/Commons";
import { useRefundContext } from "../index";
import LotDateList from "component-v2/LotDate/index";
import SelectLotDateModal from "component-v2/LotDate/SelectLotDateModal";
import { useApp } from "contexts/app";
import { ImageCustom } from "component-v2/ImageCustom";
import { DiscountAllocationType } from "screens-v2/carts-v2/cart-helpers.service";

export const RefundItem = ({ index, product, sessionLine }) => {
  const { shop_info } = useApp();
  const intl = useIntl();
  const refundConext = useRefundContext();
  const [isShowLoDateModal, setIsShowLoDateModal] = useState(false);
  const lineItemDiscount = sessionLine?.discountAllocations?.map((d) =>
    d.type === DiscountAllocationType.LINE_DISCOUNT ?
      {
        ...d,
        name: d.name || "Giảm giá tùy chỉnh",
      } : d) || [];
  const totalQtyCanReturn =
    product.quantity - product.restockQuantity - product.returnedQuantity;

  let promotionName = "";
  if (lineItemDiscount) {
    promotionName = lineItemDiscount
      .map((element) => {
        if (element.name?.toLowerCase().startsWith('x-haravan-')) return null;
        return getName(element);
      })
      .join(", ");
  }
  function getName(promotion){
    let promotionName = promotion.name;
    if (promotion.value && promotion.value.length > 0) {
      let arrs = promotion.value.split(' - ');
      if(arrs.length > 1) {
        promotionName = arrs.slice(1).join(' ');
      }
    }
    return promotionName;
  }

  const productNameRender = (productName) => {
    // if (productName.length > 24) {
    //   productName = productName.substring(0, 24) + "...";
    // }
    return productName;
  };
  const unit = product.unit ? `(${product.unit})` : "";
  const allowPlusAction = product.refundNumber < totalQtyCanReturn;
  const allowRemoveAction = product.refundNumber > 0;
  const refundedTotal = product.isTrackingInventory
    ? product.returnedQuantity
    : product.restockQuantity;

  const updateOrderLine = debounce((event) => {
    const totalQtyCanReturn =
      product.quantity - product.restockQuantity - product.returnedQuantity;

    let restockableQuantity = 0;
    if (event && event.target && event.target.value) {
      restockableQuantity = event.target.value || 0;
    }

    if (restockableQuantity > totalQtyCanReturn) {
      restockableQuantity = totalQtyCanReturn;
    }
    refundConext.updateItemCurrentRefund(product, index, restockableQuantity);
  }, 300);

  // lodate handle
  const onUpdateLotDate = (lotSelected) => {
    let indexChange = product.lots.findIndex(
      (lot) => lot.id === lotSelected.id
    );
    let lots = [...product.lots];
    lots.splice(indexChange, 1, lotSelected);
    product.lots = lots;
    let totalQty = lots.reduce((total, lot) => total + lot.quantity, 0);
    refundConext.updateItemCurrentRefund(product, index, totalQty, false);
  };

  const onDeleteLotDate = (lotSelected) => {
    let lots = product.lots.filter((lot) => lot.id !== lotSelected.id);
    if (lots) {
      let totalQty = lots.reduce((total, lot) => total + lot.quantity, 0);
      product.lots = lots;
      refundConext.updateItemCurrentRefund(product, index, totalQty, false);
    }
  };

  const onSaveLotDateModal = (selectedLots) => {
    let totalQty = selectedLots.reduce((total, lot) => total + lot.quantity, 0);
    if (totalQty > totalQtyCanReturn) {
      totalQty = totalQtyCanReturn;
    }
    product.lots = selectedLots;

    refundConext.updateItemCurrentRefund(product, index, totalQty, false);
    setIsShowLoDateModal(false);
  };

  const onCloseLotDateModal = () => {
    setIsShowLoDateModal(false);
  };

  const handleLoDateModal = () => {
    setIsShowLoDateModal(!isShowLoDateModal);
  };

  return (
    <Fragment>
      <tr>
        <td className="pl-0" colSpan={2}>
          <div className="d-flex">
            <div className="refund-items-img flex-shrink-0">
              <ImageCustom
                src={product?.imageUrl}
                alt="haravaner"
                size="thumb"
              />
            </div>
            <div className="ml-2">
              <p className="content-primary" title={product.productName}>
                {" "}
                {`${productNameRender(product.productName)} ${unit}`}
              </p>
              <p
                className="content-secondary"
                title={
                  product.variantValue ? product.variantValue : "Default Title"
                }
              >
                {product.variantValue ? product.variantValue : "Default Title"}
              </p>
              <p className="content-secondary">
                {product.barcode ? product.barcode : ""}
              </p>
              <p className="content-primary">
                {sessionLine?.price > 0 &&
                  sessionLine?.price > sessionLine?.linePrice && (
                    <strike className="content-primary mr-2">
                      <CurrencyShow value={sessionLine?.price || product.originalPrice} />
                    </strike>
                  )}
                <CurrencyShow value={sessionLine?.linePrice || product.productPrice} />
              </p>
              <p className="text-promotion">
                {promotionName
                  ? intl.formatMessage({
                      id: `settings.print-templates.${promotionName}`,
                      defaultMessage: promotionName,
                    })
                  : null}
              </p>
            </div>
          </div>
        </td>
        <td className="text-center content-primary align-top" width="30%">
          <NumberShow value={refundedTotal} />/
          <NumberShow value={product.quantity} />
        </td>
        <td className="text-center align-top pr-0" width="30%">
          <InputQuantity
            wrapperClassName="pr-2"
            enableSubtract={allowRemoveAction}
            enableAdd={allowPlusAction}
            disabled={false}
            value={product.refundNumber}
            onChange={(event) => updateOrderLine(event)}
            onSubtract={() =>
              allowRemoveAction &&
              refundConext.updateItemCurrentRefund(
                product,
                index,
                getDecimalValue(
                  shop_info,
                  product.refundNumber - 1 < 0 ? 0 : product.refundNumber - 1
                )
              )
            }
            onAdd={() =>
              allowPlusAction &&
              refundConext.updateItemCurrentRefund(
                product,
                index,
                getDecimalValue(
                  shop_info,
                  product.refundNumber + 1 > totalQtyCanReturn
                    ? totalQtyCanReturn
                    : product.refundNumber + 1
                )
              )
            }
            max={totalQtyCanReturn}
          />
        </td>
        {/* <td>{product.lotsData.length}</td> */}
        {/* <td>{allowRemoveAction.toString()}</td> */}
      </tr>
      {product.pricingListId && (
        <tr>
          <td colSpan={6} style={{ paddingLeft: "6rem", marginTop:"-0.1rem" }} className="border-0 mt-0 pt-0">
            <div className="pricing-badge" title={product.pricingListName}>
              {product.pricingListName}
            </div>
          </td>
        </tr>
      )}
      {product.lotsData && product.lotsData.length > 0 ? (
        <tr className="lotdate">
          {/* <td width='52'>
            </td> */}
          <td colSpan={6}>
            <div>
              <LotDateList
                orderLine={product}
                isRefund={true}
                handleOpenModalLotDate={handleLoDateModal}
                onUpdateLotDate={onUpdateLotDate}
                onDeleteLotDate={onDeleteLotDate}
              />
            </div>
          </td>
        </tr>
      ) : null}
      <SelectLotDateModal
        isRefundMode
        lotsSelected={product.lots}
        lotsData={product.lotsData}
        handleOnSave={onSaveLotDateModal}
        onCloseModal={onCloseLotDateModal}
        isShowModal={isShowLoDateModal}
        handleModalAction={handleLoDateModal}
      />
    </Fragment>
  );
};
