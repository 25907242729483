import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { HOTKEYS } from "models/constants";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PrintButton from "screens-v2/orders/components/print-button";
import { useRefundContext } from "screens-v2/refunds-v2";
import GlobalSimpleModal from "screens-v2/layouts/layouts/global-simple-modal";
export const ModalRefundOrderSuccess = ({
  show,
  setShow,
  data,
  setBlockingPage,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { current_refund } = useRefundContext();
  const body = () => (
    <div className="p-4">
      <iframe
        id="print-order"
        style={{ display: "none" }}
        title="print preview"
      />
      <div className="text-center font-weight-semibold font-size-24">
        <Icon name="checkbox_circle_line" size="xl" color="var(--green600)" />
        <p className="mt-4">Đổi trả thành công</p>
        <p>
          đơn hàng <span className="content-accent">{data?.orderNumber}</span>
        </p>
      </div>
      <div className="w-100 d-flex justify-content-center mt-5">
        <Button
          light
          size="lg"
          id="order.create.success_popup.new_button"
          onClick={() => {
            setShow(false);
            history.push("/orders");
            setBlockingPage(false);
          }}
          badge={HOTKEYS.ESC}
          className="l-mr-16"
          title={intl.formatMessage({
            id: "carts.orders.Đóng",
            defaultMessage: "Đóng",
          })}
        />
        <PrintButton
          intl={intl}
          orderId={data?.orderId}
          isNewOrder={data?.isNewOrder}
          refundOrderModel={data?.refundOrderModel}
          refundSessionData={data?.current_refund?.session?.order}
          currentRefund={data?.current_refund}
          sessionData={data?.session?.order}
          orderData={null}
          isBtnLoading={true}
          isAutoPrint={show}
          totalRefund={data?.totalRefund}
          isShowTitleButton
          hasTitle
          size="lg"
          taxData={data?.isNewOrder ? current_refund : null}
        ></PrintButton>
      </div>
    </div>
  );

  return (
    <GlobalSimpleModal
      body={body()}
      show={show}
      setShow={(value) => {
        setShow(value);
        if (!value) history.push("/orders");
      }}
      closeCallback={() => {
        history.push("/orders");
      }}
    />
  );
};
