import { callSearchProducts } from "api/product-repository";
import { find, forEach, trim, uniqueId } from "lodash";
import { defaultTaxData } from "./RightCart/vat";

export const MAX_TAB_COUNT = 7;
export class ShoppingCartModel {
  key = '';
  title = '';
  noteAttributes = null;
  isShipping = false;
  customer = null;
  orderLineErr = null;
  lineItems = [];
  payment_methods = [];
  pricingApplied = null;
  orderDiscountSelected = null;
  loyalty = {
    profile: null,
    redeemConfig: null,
    redeemUsedAmount: 0,
    rewardRedeemAmount: 0,
    discountCouponLoyalty: ""
  }

  searchText = "";

  summaries = {
    subtotal: 0,
    discount: 0,
    discount_code: "",
    shipping_fee: 0,
    total_payment: 0,
  };

  constructor() {
    let cart_key = uniqueId('cart-');
    this.key = cart_key;
    this.title = 'Đơn';
    this.id = "";
    this.phone = null;
    this.shippingAddress = null;
    this.fullName = null;
    this.shippingCountryId = null;
    this.shippingProvinceId = null;
    this.shippingDistrictId = null;
    this.shippingWardId = null;
    this.shippingMethodId = null;
    this.shippingMethodName = null;
    this.paymentMethodId = null;
    this.payDepositOnly = false;
    this.depositRequired = false;
    this.eInvoiceRequest = false;
    this.eInvoiceInfo = null;
    this.utmParameters = null;
    this.depositAmount = 0;
    this.depositCODAmount = 0;
    this.note = "";
    this.orderNote = "";
    this.completedAt = null;
    this.errors = [];
    this.userErrors = [];
    this.webUrl = "";
    this.isReady = false;
    this.lineItems = null;
    this.shippingMethods = [];
    this.paymentMethods = [];
    this.discountAllocations = [];
    this.noteAttributes = null;
    this.discount = 0;
    this.locationId = null;
    this.pricingListId = null;
    this.constraint = null;
    this.subTotalBeforeTax = 0;
    this.subTotalTax = 0;
    this.subTotal = 0;
    this.shippingBeforeTax = 0;
    this.shippingTax = 0;
    this.shipping = 0;
    this.totalTaxIncluded = 0;
    this.totalTaxNotIncluded = 0;
    this.total = 0;
    this.order = null;
    this.channel = "pos";
    this.nextActionData = null;
    this.nextAction = null;
    this.hasError = false;
    this.isShipping = false;
    this.customer = null;
    this.lineItems = [];
    this.orderLineErr = null;
    this.payment_methods = [];
    this.pricingApplied = null;
    this.orderDiscountSelected = null;
    this.searchText = "";
    this.summaries = {
      subtotal: 0,
      discount: 0,
      discount_code: "",
      shipping_fee: 0,
      total_payment: 0,
    };
    this.loyalty = {
      profile: null,
      redeemConfig: null,
      redeemUsedAmount: 0,
      rewardRedeemAmount: 0,
      discountCouponLoyalty: ""
    };
    this.shippingValue = {
      shipping_price: 0,
      shipping_price_after_tax: 0,
      shipping_price_before_tax: 0,
      shipping_tax: 0,
      shipping_tax_included: false,
      shipping_tax_rate: 0,
    };
    this.vatValue = {
      vatSum: 0,
      sumIncludedVat: 0,
      sumNotIncludedVat: 0,
      shippingVat: 0,
    }
    this.loyalty_program = null
    this.taxData = defaultTaxData
    this.ignoreLoyalty = false
  }
}

export async function callSearchVariants(text_search, mode = null, page = 1, limit = 20, appContext) {
  try {
    const location = appContext.current_location;

    const result = await callSearchProducts(page, limit, text_search, location.id);

    const products = result.data.data || [];
    let records = [];
    let variantList = [];
    let variantExact = [];
    let variantResult = [];

    const searchOnResult = (str) => {
      if (str) {
        return str.toLowerCase().includes(text_search.toLowerCase());
      } else {
        return false;
      }
    };

    const filterProductByName = (listProducts, keyword) => {
      if (keyword) {
        const product = find(listProducts, (p) => {
          return trim(p.productName.toLowerCase()) === trim(keyword.toLowerCase());
        });
        return product;
      } else {
        return false;
      }
    };
    if (appContext.shop_setting.hideProductOutOfStock && !appContext.shop_setting.allowOrderProductOutOfStock) {
      const productByName = filterProductByName(products, text_search);
      if (!productByName) {
        forEach(products, (product) => {
          let variants = product.variants || [];
          if (searchOnResult(product.productName)) {
            forEach(variants, (variant) => {
              if (!variant.isTrackingInventory || variant.quantity > 0 || mode === 'search') {
                variant.notAllowPromotion = product.notAllowPromotion;
                variant.variantId = variant.id;
                variant.productId = product.id;
                variant.productTitle = product.productName;
                variant.imageUrl = variant.imageUrl || product.url;
                variant.units = variant.units || [];
                variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
                variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
                variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
                variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
                variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
                variantResult.push(variant);
              }
            });
          } else {
            forEach(variants, (variant) => {
              if (!variant.isTrackingInventory || variant.quantity > 0 || mode === 'search') {
                variant.notAllowPromotion = product.notAllowPromotion;
                variant.variantId = variant.id;
                variant.productId = product.id;
                variant.productTitle = product.productName;
                variant.imageUrl = variant.imageUrl || product.url;
                variant.units = variant.units || [];
                variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
                variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
                variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
                variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
                variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
                if ((variant.sku && variant.sku === text_search) || (variant.barcode && variant.barcode === text_search)) {
                  variantExact.push(variant);
                } else {
                  variantList.push(variant);
                }
              }
            });
          }
        });
      } else {
        let variants = productByName.variants || [];
        forEach(variants, (variant) => {
          if (!variant.isTrackingInventory || variant.quantity > 0 || mode === 'search') {
            variant.notAllowPromotion = productByName.notAllowPromotion;
            variant.variantId = variant.id;
            variant.productId = productByName.id;
            variant.productTitle = productByName.productName;
            variant.imageUrl = variant.imageUrl || productByName.url;
            variant.units = variant.units || [];
            variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
            variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
            variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
            variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
            variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
            variantResult.push(variant);
          }
        });
        if (products && products.length > 1) {
          forEach(products, (product) => {
            let variantsProduct = product.variants || [];
            if (searchOnResult(product.productName) && (productByName.id !== product.id)) {
              forEach(variantsProduct, (variant) => {
                if (!variant.isTrackingInventory || variant.quantity > 0 || mode === 'search') {
                  variant.notAllowPromotion = product.notAllowPromotion;
                  variant.variantId = variant.id;
                  variant.productId = product.id;
                  variant.productTitle = product.productName;
                  variant.imageUrl = variant.imageUrl || product.url;
                  variant.units = variant.units || [];
                  variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
                  variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
                  variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
                  variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
                  variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
                  variantResult.push(variant);
                }
              });
            }
          });
        }
      }
    } else {
      const productByName = filterProductByName(products, text_search);
      if (!productByName) {
        forEach(products, (product) => {
          let variants = product.variants || [];
          if (searchOnResult(product.productName)) {
            // eslint-disable-next-line array-callback-return
            variants.map((variant) => {
              variant.variantId = variant.id;
              variant.productId = product.id;
              variant.productTitle = product.productName;
              variant.imageUrl = variant.imageUrl || product.url;
              variant.units = variant.units || [];
              variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
              variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
              variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
              variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
              variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
              variantResult.push(variant);
            });
          } else {
            // eslint-disable-next-line array-callback-return
            variants.map((variant) => {
              variant.notAllowPromotion = product.notAllowPromotion;
              variant.variantId = variant.id;
              variant.productId = product.id;
              variant.productTitle = product.productName;
              variant.imageUrl = variant.imageUrl || product.url;
              variant.units = variant.units || [];
              variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
              variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
              variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
              variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
              variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
              if (searchOnResult(variant.barcode) || searchOnResult(variant.sku)) {
                variantExact.push(variant);
              } else {
                variantList.push(variant);
              }
            });
          }
        });
      } else {
        let variants = productByName.variants || [];
        forEach(variants, (variant) => {
          variant.notAllowPromotion = productByName.notAllowPromotion;
          variant.variantId = variant.id;
          variant.productId = productByName.id;
          variant.productTitle = productByName.productName;
          variant.imageUrl = variant.imageUrl || productByName.url;
          variant.units = variant.units || [];
          variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
          variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
          variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
          variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
          variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
          variantResult.push(variant);
        });
        if (products && products.length > 1) {
          forEach(products, (product) => {
            let variantsProduct = product.variants || [];
            if (searchOnResult(product.productName) && (productByName.id !== product.id)) {
              // eslint-disable-next-line array-callback-return
              variantsProduct.map((variant) => {
                variant.notAllowPromotion = product.notAllowPromotion;
                variant.variantId = variant.id;
                variant.productId = product.id;
                variant.name = product.productName;
                variant.imageUrl = variant.imageUrl || product.url;
                variant.units = variant.units || [];
                variant.selectedUnit = variant.units.find(unit => variant.variantUnitId ? unit.id === variant.variantUnitId : unit.base);
                variant.variantUnitId = variant.selectedUnit ? variant.selectedUnit.id : null;
                variant.price = variant.selectedUnit ? variant.selectedUnit.price : variant.price;
                variant.sku = variant.selectedUnit ? variant.selectedUnit.sku : variant.sku;
                variant.barcode = variant.selectedUnit ? variant.selectedUnit.barcode : variant.barcode;
                variantResult.push(variant);
              });
            }
          });
        }
      }
    }


    if (variantExact.length > 0) {
      records = [...variantExact, ...variantResult];
    } else {
      records = [...variantList, ...variantResult];
    }

    const total = result.data.totalRecord || 0;
    const hasNextPage = total > (page * limit);
    const currentPage = page;

    return { records, total, hasNextPage, currentPage };
  } catch (error) {
    return null;
  }
}

export async function callSearchSuggestedProducts(product_group = null, product_type = null, product_vendor = null, product_tag = null, page = 1, limit = 10, appContext) {
  const location = appContext.current_location;
  if (!location) {
    return;
  }

  let response = await callSearchProducts(page, limit, '', location.id, product_group, product_type, product_vendor, product_tag);
  return response.data ? response.data : null;
}

export const concatFullName = (firstName, lastName) => {
  let fullName = firstName ? firstName.trim() : '';
  lastName = lastName ? lastName.trim() : '';
  fullName += ' ' + lastName;
  fullName = fullName.trim();
  return fullName;
};

export const concatString = ({ value, separator = " " }) => {
  return [...value].filter(Boolean).join(separator);
};