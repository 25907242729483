import { Assets } from "assets";
import {
  toFormattedCurrency,
  toFormattedNumber,
} from "infrastructures/format-helpers";
import "moment-timezone";
import eng from "../../../infrastructures/locales/en-messages.json";

export function getPrintTemplateQRCode({
  printSettings,
  order,
  locale,
  shopLogoUrl,
  qrcodeSvg,
}) {
  const renderStyle = `
   <style>
      @page {
      margin-top: 4mm;
      margin-right: 7mm;
      margin-left: 7mm;
      margin-bottom: 4mm;
      }
      .qrcode svg
      {
         
      }
      html,
      body {
      width: 100%;
      }
      hr {
      border-top: 1px dashed black;
      }
      body {
      font-family: sans-serif;
      display: block;
      font-size: 13px;
      color: #000;
      margin: 0 auto;
      line-height: 16px;
      }
      body {
      position: relative;
      width: 100%;
      -webkit-touch-callout: none;
      -webkit-text-size-adjust: none;
      -webkit-user-select: none;
      -webkit-highlight: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      .text-align-center {
         text-align: center;
      }
      .printorder {
      margin: 0 auto;
      font-size: inherit;
      font-family: inherit;
      }
      .printorder .printtable {
      font-size: inherit;
      font-family: inherit;
      width: 100%;
      }
      .text-right {
      text-align: right;
      }
      .text-center {
      text-align: center;
      }
      .p-r-20 {
      padding-right: 20px;
      }
      .printorder .printrow {
      clear: both;
      width: 100%;
      display: table;
      }
      .printorder .printrow.last {
      border-bottom: 1px solid #B7B7B7;
      }
      .printorder .printrow.noborder .printcol {
      border: none;
      }
      .printorder .printcol {
      display: table-cell;
      border-left: 1px solid #B7B7B7;
      border-top: 1px solid #B7B7B7;
      vertical-align: middle;
      }
      p{
      margin: 0 !important;
      }
      .text-align-center {
      text-align: center;
      }
      .m-b-10 {
      margin-bottom: 10px;
      }
      .m-b-8 {
      margin-bottom: 8px;
      }
      .m-t-8 {
      margin-top: 8px;
      }
      .m-t-5 {
      margin-top: 5px;
      }
      .barcode-wrapper {
      width: 100%;
      overflow: hidden;
      text-align: center;
      }
   </style>
   `;

  const renderSummary = `
      ${
        printSettings.totalPaid
          ? `<tr>
         <td class="text-right p-r-20">${(locale === "en" && eng["settings.print-templates.Tổng tiền"]) || "Tổng tiền"}:</td>
         <td class="text-right" style='white-space: nowrap' style="width:100px">${toFormattedCurrency(order.amountPaid)}</td>
      </tr>`
          : ``
      }
   `;

  let template = `
<div id="printOrder" class="printorder">
   ${renderStyle}
   <div class="printorder">
      ${
        printSettings.showLogo
          ? `
      <div class="printtable">
         <div class="printrow noborder">
            <div class="printcol" style="width: 100%; text-align: center;">
               <img style="width: 220px; display: inline-block;" src=${shopLogoUrl || Assets.icon.hrv_logo_print}>
            </div>
         </div>
      </div>
      `
          : ``
      }
      ${
        printSettings.showLogo
          ? `
      <div style="margin-top: 16px;margin-bottom: 10px;">
         <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
      </div>
      `
          : ``
      }
      <div class="printtable m-t-5"
         style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
         <span style="text-align: center;font-weight: 600;text-transform: uppercase;font-size: 16px;">&nbsp;${(locale === "en" && eng["settings.print-templates.Mã QR thanh toán"]) || "Mã QR thanh toán"}&nbsp;</span>
      </div>
      ${
        qrcodeSvg
          ? `
		  <div class="printtable m-t-8">
			 <div class="printrow noborder">
				<div class="printcol barcode-wrapper qrcode" style="width: 100%;">    
				   ${qrcodeSvg.outerHTML}
				</div>
			 </div>
		  </div>
		  `
          : ``
      }
      <div class="printtable m-t-5"
         style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
         ${
           order?.transferAccount
             ? `<span style="text-align: center;font-weight: 600;text-transform: uppercase;font-size: 14px;">${order?.transferAccount?.bankName}&nbsp;</span>
               ${
                 order?.transferAccount?.autoConfirmPayment
                   ? ``
                   : `
                     <span class="m-t-5" style="text-align: center;font-weight: 600;text-transform: uppercase;font-size: 14px;">${order?.transferAccount?.bankAccount}&nbsp;</span>
                     <span class="m-t-5" style="text-align: center;font-weight: 600;text-transform: uppercase;font-size: 14px;">${order?.transferAccount?.bankAccountName}&nbsp;</span>
                  `
               }
            `
             : ``
         }
         <span class="m-t-8" style="text-align: center;">&nbsp;${(locale === "en" && eng["settings.print-templates.Đơn hàng"]) || "Đơn hàng"} ${order?.orderNumber}&nbsp;</span>
      </div>
      <div style="margin-top: 16px;margin-bottom: 8px;">
        <p style="border-bottom:1px dashed #000000;height: 1px;"></p>
      </div>
      <div class="text-align-center m-t-8" style="width: 100%;font-size: 14px;color: black;line-height: 10px;font-weight: 600">
         ${renderSummary}
      </div>
   </div>
</div>
`;
  return template;
}
