import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { Tooltip } from "@haravan/react-components";
import { useRefundContext } from ".";
import { concatString } from "screens-v2/carts-v2/utils";
import { PopupVatInfo } from "component-v2/VAT/PopupVatInfo";

export const defaultTaxData = {
  billingCompany: "",
  billingTaxCode: "",
  billingEmail: "",
  billingAddress: "",
  address: null,
  eInvoiceSetting: null,
  eInvoice: null,
  eInvoiceInfo: {
    address: "",
    company: false,
    name: "",
    taxCode: "",
    email: "",
    save: false,
    submit: false,
  },
};

export const VAT = ({ isVATInfo }) => {
  const { openPopupVat, setOpenPopupVat, openRemoveVat, setOpenRemoveVat } =
    useVATContext();

  const { shop_info: shopInfo } = useApp();
  const {
    current_refund,
    current_shopping_cart,
    updateCurrentRefund,
    updateCurrentSession,
  } = useRefundContext();
  const [taxData, setTaxData] = useState({ ...defaultTaxData });

  const handleSavePopupVat = (data) => {
    updateCurrentRefund({ taxData: data });
    updateCurrentSession({
      taxData: {
        ...data,
        eInvoiceInfo: {
          ...data?.eInvoiceInfo,
          save: data?.eInvoiceInfo?.save || false,
          submit: true,
        },
        isEdited: true,
      },
      eInvoiceInfo: {
        ...data?.eInvoiceInfo,
        save: data?.eInvoiceInfo?.save || false,
        submit: true,
      },
    });
    setOpenPopupVat(false);
  };

  useEffect(() => {
    if (current_refund?.taxData) {
      setTaxData({ ...current_refund?.taxData });
    }
  }, [current_refund?.taxData]);

  const removeVat = () => {
    updateCurrentRefund({
      taxData: {
        ...defaultTaxData,
        oldEInvoiceInfo: current_refund?.taxData?.oldEInvoiceInfo,
      },
    });
    setOpenRemoveVat(false);
    updateCurrentSession({
      taxData: {
        ...defaultTaxData,
        eInvoiceInfo: {
          ...current_shopping_cart?.taxData?.eInvoiceInfo,
          submit: false,
        },
      },
    });
  };

  const popupRemoveVat = () => {
    return (
      <GlobalModal
        size={"md"}
        headerTitle={"Hủy yêu cầu xuất hóa đơn VAT"}
        isTitleCenter
        body={<span>Thông tin hóa đơn đã nhập sẽ không được lưu.</span>}
        confirmButtonClassName={"l-button l-button-danger l-button-md"}
        onCancel={() => setOpenRemoveVat(false)}
        onConfirm={removeVat}
        confirmTitle={"Gỡ bỏ"}
        show={openRemoveVat}
        setShow={(value) => setOpenRemoveVat(value)}
      />
    );
  };

  const renderPopupVAT = () => {
    return (
      <PopupVatInfo
        autoFillEmail={{
          title: "Sử dụng email của khách",
          value: current_shopping_cart?.customer?.email || "",
        }}
        autoFillCompanyAddress={{
          title: "Sử dụng địa chỉ giao hàng",
          value: {
            billingCountry:
              current_shopping_cart?.customer?.customerAddress?.[0]?.countryId,
            billingCountryName:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.countryName,
            billingProvince:
              current_shopping_cart?.customer?.customerAddress?.[0]?.provinceId,
            billingProvinceName:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.provinceName,
            billingDistrictName:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.districtName,
            billingDistrict:
              current_shopping_cart?.customer?.customerAddress?.[0]?.districtId,
            billingWard:
              current_shopping_cart?.customer?.customerAddress?.[0]?.wardId,
            billingWardName:
              current_shopping_cart?.customer?.customerAddress?.[0]?.wardName,
            city: current_shopping_cart?.customer?.customerAddress?.[0]?.city,
            zipCode:
              current_shopping_cart?.customer?.customerAddress?.[0]?.zipCode,
            countryCode:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.countryCode,
            provinceCode:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.provinceCode,
            districtCode:
              current_shopping_cart?.customer?.customerAddress?.[0]
                ?.districtCode,
            wardCode:
              current_shopping_cart?.customer?.customerAddress?.[0]?.wardCode,
            billingAddress:
              current_shopping_cart?.customer?.customerAddress?.[0]?.address1,
            address:
              current_shopping_cart?.customer?.customerAddress?.[0]?.address1,
          },
        }}
        // context={this.context}
        formData={taxData}
        onSave={(data) => handleSavePopupVat(data)}
        setOpen={(value) => setOpenPopupVat(value)}
        isOpen={openPopupVat}
        onCancel={() => setOpenPopupVat(false)}
      />
    );
  };
  if (!shopInfo.taxable) return null;

  if (
    !(
      current_refund?.taxData?.eInvoiceInfo?.name ||
      current_refund?.taxData?.billingCompany
    )
  ) {
    return (
      <Fragment>
        <span
          className="cursor-pointer"
          onClick={() => {
            setOpenPopupVat((prev) => !prev);
            if (current_refund?.taxData?.oldEInvoiceInfo) {
              setTaxData({
                ...current_refund?.taxData,
                eInvoiceInfo: { ...current_refund?.taxData?.oldEInvoiceInfo },
              });
            }
          }}
        >
          <Badge className="border-0" light status={"info"} size="L" rounded>
            <span className="d-inline-flex align-items-center">
              <Icon
                name="add"
                color="var(--contentAccent)"
                className="l-mr-4"
              />
              <span>Hóa đơn VAT</span>
            </span>
          </Badge>
        </span>
        {renderPopupVAT()}
      </Fragment>
    );
  } else if (current_shopping_cart?.taxData?.eInvoiceInfo?.submit) {
    return (
      <div className="flex-auto">
        <div className="d-flex align-items-center l-pt-8">
          <p
            className="flex-auto content-accent cursor-pointer l-mr-16"
            onClick={() => setOpenPopupVat((prev) => !prev.openPopupVat)}
          >
            Hóa đơn VAT
          </p>
          <div className="flex-stretch l-mr-4 min-w-0">
            {current_refund.taxData.eInvoiceInfo?.name ||
            current_refund.taxData.billingCompany ? (
              <Tooltip
                placement="bottomRight"
                trigger="hover"
                overlay={
                  <div>
                    <p className="content-on-color-primary font-size-12 lh-18 l-mb-4">
                      {current_refund.taxData.eInvoiceInfo?.name ||
                        current_refund.taxData.billingCompany}
                    </p>
                    <ul
                      className="font-size-12 lh-18 content-tertiary m-0"
                      style={{ paddingInlineStart: "3rem" }}
                    >
                      {(current_refund.taxData.eInvoiceInfo?.taxCode ||
                        current_refund.taxData.billingTaxCode) && (
                        <li>
                          {current_refund.taxData.eInvoiceInfo?.taxCode ||
                            current_refund.taxData.billingTaxCode}
                        </li>
                      )}
                      {(current_refund.taxData.eInvoiceInfo?.email ||
                        current_refund.taxData.billingEmail) && (
                        <li>
                          {current_refund.taxData.eInvoiceInfo?.email ||
                            current_refund.taxData.billingEmail}
                        </li>
                      )}
                      {(current_refund.taxData.eInvoiceInfo?.address ||
                        current_refund.taxData.address) && (
                        <li>
                          {current_refund.taxData.eInvoiceInfo?.address ||
                            concatString({
                              value: [
                                current_refund.taxData.address.billingAddress,
                                current_refund.taxData.address.billingWardName,
                                current_refund.taxData.address
                                  .billingDistrictName,
                                current_refund.taxData.address
                                  .billingProvinceName,
                                current_refund.taxData.address
                                  .billingCountryName,
                              ],
                              separator: ", ",
                            })}
                        </li>
                      )}
                    </ul>
                    <p className="content-primary font-size-12 mb-0 lh-18"></p>
                  </div>
                }
              >
                <p className="text-truncate content-secondary text-right">
                  {current_refund.taxData.eInvoiceInfo?.name ||
                    current_refund.taxData.billingCompany}
                </p>
              </Tooltip>
            ) : null}
          </div>
          <div
            className="flex-auto cursor-pointer btn-close l-ml-8"
            onClick={() => setOpenRemoveVat(true)}
            style={{ height: "max-content", padding: "0.2rem" }}
          >
            <Icon name="close" />
          </div>
        </div>
        {renderPopupVAT()}
        {popupRemoveVat()}
      </div>
    );
  }
  return null;
};

export const VATContext = createContext({
  openPopupVat: false,
  setOpenPopupVat: () => {},
  openRemoveVat: false,
  setOpenRemoveVat: () => {},
});

export const useVATContext = () => {
  const context = useContext(VATContext);
  return context;
};
