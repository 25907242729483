import React, {Fragment, useEffect} from "react";
import {useState} from "react";
import {useIntl} from 'react-intl';
import {useRefundContext} from "../index";
import {toFormattedNumber} from "infrastructures/format-helpers";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import {RefundItem} from "./refund-item";
import {RefundItemsSummary} from "./refund-items-summary";
import "./index.css";
import { useApp } from "contexts/app";

export const RefundListItem = (props) => {
  const {shop_loyalty} = useApp()
  const refundContext = useRefundContext();
  const intl = useIntl();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (refundContext.current_order) {
      setOrder(refundContext.current_refund?.order);
    }
    setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundContext.current_order]);

  return (
    <Fragment>
      <div className="refund-items-root ">
        {isLoading && <LoadingCircle overlay className="h-100" />}
        {!isLoading && order &&
          <>
            <div className="l-text-larger"> {intl.formatMessage({id: 'carts.refunds.Sản phẩm đổi trả', defaultMessage: 'Sản phẩm đổi trả'})}</div>
            <table className="table table-left l-mt-16">
              <thead>
                <tr>
                  <th scope="col" colSpan='2' width="40%" className="p-0">
                    {intl.formatMessage({id: 'carts.refunds.Sản phẩm', defaultMessage: 'Sản phẩm'})}
                    <span className="badge badge-quantity ml-2">
                      {toFormattedNumber(refundContext.current_refund.refund_quantity_total || 0)}
                    </span>
                  </th>
                  <th scope="col" className='text-center p-0' width="30%" >{intl.formatMessage({id: 'carts.refunds.Đã trả', defaultMessage: 'Đã trả'})}</th>
                  <th scope="col" className='text-center p-0' width="30%">{intl.formatMessage({id: 'carts.refunds.Nhập trả', defaultMessage: 'Nhập trả'})}</th>
                </tr>
              </thead>
            </table>
            <div className="order-refund scrollable-y ">
              <table className="table table-left ">
                <tbody >
                  {refundContext.current_refund?.lineItems?.map((item, index) => {
                    const sessionLine = refundContext.current_refund?.session?.order?.lineItems?.find(line => line.id === item.id);
                    return (<RefundItem key={item.id} index={index} product={item} sessionLine={sessionLine}/>);
                  })}
                  <tr className="border" />
                </tbody>
              </table>
            </div>
          </>
        }
        {!isLoading && order &&
          <div className=' refund-items-money border-top l-section-group '>
            <RefundItemsSummary order={order} loyaltyEnabled={shop_loyalty?.enabled} />
          </div>
        }
      </div>
    </Fragment >
  );
};
