import { ImageCustom } from "component-v2/ImageCustom";
import Button from "component-v2/buttons";
import CurrencyShow from "component-v2/currency-show";
import Icon from "component-v2/icons/icons";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import React, { useEffect, useMemo, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import "./style.css";
import { useIntl } from "react-intl";
import { memoizedGetProductDetail, useCartContext } from "screens-v2/carts-v2";
import { convertViToEn, getDecimalValue } from "utils/Commons";
import { InputQuantity } from "component-v2/Input";
import { productQuantityMax } from "screens-v2/carts-v2/cart-helpers.service";
import { useApp } from "contexts/app";
import EmptyState from "component-v2/empty-state";

const AddProductButton = ({ onClick, loading }) => {
  return (
    <Button
      title="Chọn"
      status="default"
      size="sm"
      light
      className="add-btn"
      onClick={() => onClick()}
      loading={loading}
      disabled={loading}
    />
  );
};
const CustomVariantItem = ({
  item,
  inputQuantity = false,
  handleOnChange,
  handleDecrease,
  handleIncrease,
}) => {
  const { shop_info } = useApp();
  const [quantity, setQuantity] = useState(item?.quantity || 0);
  const handleDecreaseQuantity = () => {
    let value = getDecimalValue(shop_info, quantity - 1);
    if (value >= 0) {
      setQuantity(value);
      handleDecrease(value);
    }
  };
  const handleInCreaseQuantity = () => {
    let value = getDecimalValue(shop_info, quantity + 1);
    if (value <= productQuantityMax) {
      setQuantity(value);
      handleIncrease(value);
    }
  };
  const enableSubtract = quantity > 0;
  return (
    <div
      key={item?.id}
      className={`custom-variant-item ${
        !inputQuantity && item.isSelected ? "selected" : ""
      } d-flex align-items-center l-p-8 l-p-8`}
    >
      <div className="cart-product-item--image">
        <ImageCustom src={item?.image} alt="product image" />
      </div>
      <div className="d-flex flex-column l-ml-8 w-100">
        <div className="tbl-list-item">
          <div className="flex-stretch flex-column">
            <p className="font-weight-medium content-primary">
              {item?.productName}
            </p>
            {item?.value1 && (
              <p className="content-secondary l-mt-4">
                {item?.value1}
                {item?.value2 && `/ ${item?.value2}`}
              </p>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="tbl-list-item position-relative">
            <p className="m-0 l-pr-16">
              <CurrencyShow value={item?.price} />
            </p>
          </div>
          <div>
            {inputQuantity && (
              <InputQuantity
                className={"hrv-input-sm"}
                enableSubtract={enableSubtract}
                enableAdd={true}
                disabled={false}
                value={quantity}
                onChange={(e) => {
                  let value = getDecimalValue(shop_info, e.target.value);
                  setQuantity(value);
                  handleOnChange(value);
                }}
                max={productQuantityMax}
                onSubtract={() => enableSubtract && handleDecreaseQuantity()}
                onAdd={() => handleInCreaseQuantity()}
                wrapperClassName="hrv-input-sm-custom"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const DiscountRecommendModal = ({
  show,
  setShow,
  item,
  promotion,
  onClickBack,
}) => {
  const intl = useIntl();
  const appContext = useApp();
  const { addBulkItemPromotions, current_shopping_cart } = useCartContext();
  const [showSelection, setShowSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [conditionVariants, setConditionVariants] = useState([]);
  const [recommendVariants, setRecommendVariants] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [showRecommendGroup, setShowRecommendGroup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selectedQuantity = useMemo(
    () =>
      variantList?.reduce((sum, v) => (v.isSelected ? (sum += 1) : sum), 0) ||
      0,
    [variantList],
  );
  const totalRecommend = useMemo(() => {
    return (
      recommendVariants?.reduce(
        (sum, v) => (v.quantity > 0 ? (sum += v.quantity) : sum),
        0,
      ) || 0
    );
  }, [recommendVariants]);
  const totalCondition = useMemo(() => {
    return (
      conditionVariants?.reduce(
        (sum, v) => (v.quantity > 0 ? (sum += v.quantity) : sum),
        0,
      ) || 0
    );
  }, [conditionVariants]);
  const conditionVariantsSelected = useMemo(
    () => conditionVariants?.filter((v) => v.quantity > 0),
    [conditionVariants],
  );
  const recommendVariantsSelected = useMemo(
    () => recommendVariants?.filter((v) => v.quantity > 0),
    [recommendVariants],
  );
  const handleCancel = () => {
    showSelection ? setShowSelection(false) : setShow(false);
  };
  const handleSavePromotions = () => {
    const variants = variantList?.map((v) => ({
      ...v,
      quantity: v.isSelected ? v.quantity || 1 : 0,
    }));
    showRecommendGroup
      ? setRecommendVariants(variants)
      : setConditionVariants(variants);
    setShowSelection(false);
  };
  const handleApplyPromotion = () => {
    const res = [...conditionVariants, ...recommendVariants].reduce(
      (accumulator, cur) => {
        const found = accumulator.find((v) => {
          return v.id === cur.id;
        });
        if (found) found.quantity += cur.quantity;
        else accumulator.push(cur);
        return accumulator;
      },
      [],
    );
    addBulkItemPromotions(res);
    setShow(false);
  };
  const handleShowConditionProduct = () => {
    setVariantList([...conditionVariants] || []);
    setShowSelection(true);
    setShowRecommendGroup(false);
  };
  const handleShowRecommendProduct = () => {
    setVariantList([...recommendVariants] || []);
    setShowSelection(true);
    setShowRecommendGroup(true);
  };
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        handleCancel();
      }
      if (e.key === HOTKEY_EVENTS.F8) {
        !showSelection ? handleApplyPromotion() : setShowSelection(false);
      }
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  const handleOnchangeQuantity = (
    variants = [],
    value,
    item,
    isCondition = false,
  ) => {
    let itemIndex = variants?.findIndex((v) => v.id === item.id);
    variants[itemIndex] = {
      ...item,
      quantity: value,
      quantityImport: value,
    };
    isCondition
      ? setConditionVariants([...variants])
      : setRecommendVariants([...variants]);
  };
  const handleSelectVariant = (variantList, item) => {
    let variants = [...variantList];
    let itemIndex = variants?.findIndex((v) => v.id === item.id);
    variants[itemIndex] = {
      ...item,
      isSelected: !item.isSelected,
    };
    setVariantList([...variants]);
  };
  const onSearchVariants = (value) => {
    const _searchText = value;
    setSearchText(value);
    if (_searchText !== "") {
      const res = variantList?.map((v) => {
        return {
          ...v,
          isSearched: convertViToEn(
            [
              v.id?.toString(),
              v.productName,
              v.value1,
              v.value2,
              v.value3,
            ]?.join(" "),
          )?.includes(convertViToEn(_searchText)),
        };
      });
      setVariantList(res);
    } else {
      setVariantList([
        ...variantList?.map((v) => ({ ...v, isSearched: true })),
      ]);
    }
  };
  useEffect(() => {
    if (!show) {
      setSearchText("");
      setShowSelection(false);
      setRecommendVariants([]);
      setConditionVariants([]);
    } else {
      if (promotion) {
        setIsLoading(true);
        const mapProductDetails = async (promotion) => {
          let _recommendVariants = [];
          let _conditionVariants = [];
          for (let i = 0; i < promotion?.data?.length; i++) {
            const product = promotion?.data[i];
            const productDetail = (
              await memoizedGetProductDetail(appContext, product.productId)
            )?.data;
            _recommendVariants = [
              ..._recommendVariants,
              ...productDetail?.productVariants?.map((v) => {
                const _qty =
                  current_shopping_cart?.lineItems?.reduce((sum, cur) => {
                    if (v.id === cur.variantId) {
                      return (sum += cur.quantity);
                    }
                    return sum;
                  }, 0) || 0;
                return {
                  ...v,
                  productId: productDetail.id,
                  productName: productDetail.productName,
                  image:
                    v.imageUrl ||
                    productDetail?.images?.find((image) => image.isFeatured)
                      ?.url,
                  price: v.price || 0,
                  quantity: _qty,
                  isSelected: _qty > 0,
                  isSearched: true,
                };
              }),
            ];
          }
          for (let i = 0; i < promotion?.conditionData?.length; i++) {
            const product = promotion?.conditionData[i];
            const productDetail = (
              await memoizedGetProductDetail(appContext, product.productId)
            )?.data;
            _conditionVariants = [
              ..._conditionVariants,
              ...productDetail?.productVariants?.map((v) => {
                const _qty =
                  current_shopping_cart?.lineItems?.reduce((sum, cur) => {
                    if (v.id === cur.variantId) {
                      return (sum += cur.quantity);
                    }
                    return sum;
                  }, 0) || 0;
                return {
                  ...v,
                  productId: productDetail.id,
                  productName: productDetail.productName,
                  image:
                    v.imageUrl ||
                    productDetail?.images?.find((image) => image.isFeatured)
                      ?.url,
                  price: v.price || 0,
                  quantity: _qty,
                  isSelected: _qty > 0,
                  isSearched: true,
                };
              }),
            ];
          }
          setRecommendVariants([..._recommendVariants]);
          setConditionVariants([..._conditionVariants]);
        };
        mapProductDetails(promotion).finally(() => setIsLoading(false));
      }
    }
  }, [show, promotion]);

  const body = () => {
    return (
      <div className="recommend-content container d-flex flex-column">
        <div className="row">
          <span className="recommend-header w-100 d-flex justify-content-between">
            <span className="">
              {`${promotion.label_1} ${promotion.label_2}`}
            </span>
          </span>
        </div>
        <div className="row flex-1 h-100">
          <div className="col d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center col-header">
              <span className="font-weight-bold">
                Sản phẩm điều kiện có trong giỏ
                <span
                  className={`ml-2 count-badge ${
                    totalCondition >= promotion.minOrderAmount
                      ? "activated"
                      : ""
                  }`}
                >{`${totalCondition}/${promotion.minOrderAmount}`}</span>
              </span>
              <AddProductButton
                onClick={() => handleShowConditionProduct()}
                loading={isLoading}
              />
            </div>
            <div className="selection-container flex-1">
              {conditionVariantsSelected?.map((variant) => {
                return (
                  <div className="l-mb-8" key={variant?.id}>
                    <CustomVariantItem
                      item={variant}
                      inputQuantity={true}
                      handleOnChange={(value) =>
                        handleOnchangeQuantity(
                          conditionVariants,
                          value,
                          variant,
                          true,
                        )
                      }
                      handleDecrease={(value) =>
                        handleOnchangeQuantity(
                          conditionVariants,
                          value,
                          variant,
                          true,
                        )
                      }
                      handleIncrease={(value) =>
                        handleOnchangeQuantity(
                          conditionVariants,
                          value,
                          variant,
                          true,
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center col-header">
              <span className="font-weight-bold">
                Sản phẩm khuyến mãi có trong giỏ
                <span
                  className={`ml-2 count-badge ${
                    totalRecommend >= promotion.maxDiscountAmount
                      ? "activated"
                      : ""
                  }`}
                >{`${totalRecommend}/${promotion.maxDiscountAmount}`}</span>
              </span>
              {totalCondition >= promotion.minOrderAmount && (
                <AddProductButton
                  onClick={() => handleShowRecommendProduct()}
                  loading={isLoading}
                />
              )}
            </div>
            <div className="selection-container flex-1">
              {totalCondition >= promotion.minOrderAmount ? (
                <>
                  {recommendVariantsSelected?.map((variant) => {
                    return (
                      <div className="l-mb-8" key={variant?.id}>
                        <CustomVariantItem
                          item={variant}
                          inputQuantity={true}
                          handleOnChange={(value) =>
                            handleOnchangeQuantity(
                              recommendVariants,
                              value,
                              variant,
                            )
                          }
                          handleDecrease={(value) =>
                            handleOnchangeQuantity(
                              recommendVariants,
                              value,
                              variant,
                            )
                          }
                          handleIncrease={(value) =>
                            handleOnchangeQuantity(
                              recommendVariants,
                              value,
                              variant,
                            )
                          }
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="d-flex flex-column">
                  <EmptyState
                    iconName="local_offer"
                    description={intl.formatMessage({
                      id: "carts.orders.Chọn đủ số lượng sản phẩm điều kiện để có thể tiếp tục chọn sản phẩm khuyến mãi",
                      defaultMessage:
                        "Chọn đủ số lượng sản phẩm điều kiện để có thể tiếp tục chọn sản phẩm khuyến mãi",
                    })}
                  />
                  <Button
                    title="Xem sản phẩm khuyến mãi"
                    status="default"
                    size="sm"
                    light
                    className={"align-self-center"}
                    onClick={() => handleShowRecommendProduct()}
                    loading={isLoading}
                    disabled={isLoading}
                  />
                  <></>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const productSelectBody = () => {
    return (
      <div className="recommend-content w-100 d-flex flex-column">
        <div className="recommend-search-container">
          <span className={`cart-search-icon`}>
            <Icon name={"search"} color="var(--contentSecondary)" />
          </span>
          <input
            id="search_cart_product"
            type="text"
            autoComplete="off"
            className=" cart-search-box h-100"
            placeholder={intl.formatMessage({
              id: "carts.orders.products.searches.Tìm kiếm",
              defaultMessage: "Tìm kiếm",
            })}
            value={searchText}
            onChange={(e) => onSearchVariants(e.target.value)}
          />
          {searchText.length > 0 && (
            <Button
              size="sm"
              plain
              icon="close"
              title=""
              className={`l-ml-8 cart-search-icon cart-btn-remove l-mr-16`}
              onClick={() => onSearchVariants("")}
            />
          )}
        </div>
        <div className="overflow-auto l-p-16">
          {variantList
            ?.filter((v) => v.isSearched)
            ?.map((variant) => {
              return (
                <div
                  className="l-mb-8 search-item cursor-pointer"
                  key={variant?.id}
                  onClick={() => handleSelectVariant(variantList, variant)}
                >
                  <CustomVariantItem item={variant} />
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  const footer = () => {
    return (
      <>
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <Button
            className="l-mr-8 light"
            onClick={handleCancel}
            title="Hủy"
            badge={HOTKEYS.ESC}
          />
          {showSelection ? (
            <Button
              status="primary"
              onClick={() => handleSavePromotions()}
              title={`Chọn${
                selectedQuantity ? ` ${selectedQuantity}` : ""
              } sản phẩm`}
              badge={HOTKEYS.F8}
            />
          ) : (
            <Button
              status="primary"
              onClick={() => handleApplyPromotion()}
              title={"Áp dụng"}
              badge={HOTKEYS.F8}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <GlobalModal
      size={"lg"}
      className={"discount-recommend-modal"}
      fixHeight
      isTitleCenter
      show={show}
      setShow={setShow}
      body={!showSelection ? body() : productSelectBody()}
      headerTitle={
        !showSelection ? (
          <span className="truncate-30ch">{promotion.code || "Chọn quà"}</span>
        ) : showRecommendGroup ? (
          promotion.recommendGroupName || "Chọn sản phẩm khuyến mãi"
        ) : (
          promotion.conditionGroupName || "Chọn sản phẩm mua"
        )
      }
      headerBack={true}
      onClickBack={() => {
        if (showSelection) {
          setShowSelection(false);
        } else {
          onClickBack?.();
        }
      }}
      footer={footer()}
    ></GlobalModal>
  );
};

export default DiscountRecommendModal;
