import React, {Fragment} from "react";
import PropTypes from 'prop-types';

export const VariantSearchBox = (props) => {
  const {icon, placeholder, onChange} = props;
  return (
    <Fragment>
      <div className="cart-search-container ">
        <span className={`cart-search-icon`}>
          {icon}
        </span>
        <input type="text" className=" h-100 cart-search-box" placeholder={placeholder} onChange={onChange} />
      </div>
    </Fragment>
  );
};

VariantSearchBox.propTypes = {
  icon: PropTypes.element,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};