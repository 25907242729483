export function toPaymentDisplayName(payment_name) {
  if (!payment_name) return "";
  let display_name = payment_name === "--" ? "Khác" : payment_name;
  return display_name;
}

export const SYSTEM_GUEST_EMAIL = "guest@haravan.com";
export const initCustomerInfo = (name, email, phone) => {
  email = email ? email.trim() : "";
  name = name ? name.trim() : "";
  phone = phone ? phone.trim() : "";
  if (email === SYSTEM_GUEST_EMAIL)
    return { name: "Khách lẻ", email: "", phone: "" };
  if (!name && email) {
    name = email;
    email = "";
  }
  if (!name && phone) {
    name = phone;
    phone = "";
  }
  if (!name) name = "---";
  return { name, email, phone };
};
export const initCustomerInfoDetail = (name, email, phone) => {
  email = email ? email.trim() : "";
  name = name?.trim() ? name.trim() : "--";
  phone = phone ? phone.trim() : "";
  if (email === SYSTEM_GUEST_EMAIL)
    return { name: "Khách lẻ", email: "", phone: "" };
  return { name, email, phone };
};

export const initCustomerInfoList = (name, email, phone) => {
  email = email ? email.trim() : "";
  name = name ? name.trim() : "";
  phone = phone ? phone.trim() : "";
  if (email === SYSTEM_GUEST_EMAIL) return { name: "", email: "", phone: "" };
  return { name, email, phone };
};

export const urlHasParams = (url) => {
  let RegEx_URL_WITH_PARAMS_PATTERN = new RegExp(/\?.+/);
  return RegEx_URL_WITH_PARAMS_PATTERN.test(url);
};

export const getAppUrl = (appUrl, shopInfo) => {
  let sellerDomain = shopInfo.seller_domain;
  let orgId = shopInfo.org_id;
  var shop = sellerDomain
    .replace("http://", "")
    .replace("https://", "")
    .replace("/admin", "")
    .replace("/", "");

  return urlHasParams(appUrl)
    ? `${appUrl}&orgid=${orgId}&shop=${shop}&locale=vi`
    : `${appUrl}?orgid=${orgId}&shop=${shop}&locale=vi}`;
};

export const getChromeVersion = () => {
  const chromeVersion = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  if (chromeVersion) {
    return parseInt(chromeVersion[2], 10);
  }
  return null;
};
