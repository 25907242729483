import React, { Fragment, memo, useState } from "react";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import Button from "component-v2/buttons";
import { TemplateType, printOrder } from "./print-template";
import "./index.css";
import { useApp } from "contexts/app";

function PrintButtonQRCode({
  orderData,
  qrcodeSVG,
  isOrderPrintBill,
  isShowTitleButton = false,
  size = "lg",
  hasTitle = false,
  placementTooltip,
  disabled = false,
}) {
  const appContext = useApp();
  const [isLoading, setLoading] = useState(false);

  const callPrintOrder = async (order, qrcodeSVG) => {
    let template = await printOrder(
      appContext,
      order,
      null, // barcodeImg?.current,
      null,
      null,
      null,
      null,
      null,
      qrcodeSVG.current,
      isOrderPrintBill,
      null,
      TemplateType.QR_CODE,
    );
    var contentWindow = document.getElementById("print-qrcode");
    let contentPopup;
    if (contentWindow) {
      contentPopup = contentWindow.contentWindow.document;
      contentPopup.open();
      contentPopup.write(template);
      setTimeout(function () {
        contentPopup.close();
      }, 200);
    }
    setLoading(false);
  };

  const clickPrintOrder = async () => {
    setLoading(true);
    try {
      await callPrintOrder(orderData, qrcodeSVG);
    } catch (error) {
      console.log("print error", error.message);
    }
  };

  return (
    <Fragment>
      <Button
        light
        size={size}
        icon={"qrCode"}
        title={hasTitle ? "In mã QR" : ""}
        tooltipTitle={hasTitle ? "" : "In mã QR"}
        placementTooltip={placementTooltip}
        status={isShowTitleButton ? "primary" : "default"}
        plain={!isShowTitleButton}
        onClick={() => clickPrintOrder()}
        isBtnLoading={isLoading}
        disabled={disabled}
        className="w-100 font-weight-semibold font-size-16"
      />
      <iframe title="print" id="print-qrcode" style={{ display: "none" }} />
    </Fragment>
  );
}

export default memo(PrintButtonQRCode);

PrintButtonQRCode.propTypes = {
  qrcodeSVG: PropTypes.any,
  orderId: PropTypes.any,
  orderData: PropTypes.any,
  isBtnLoading: PropTypes.any,
  isAutoPrint: PropTypes.any,
  disabled: PropTypes.any,
};
