import React, { useState, Fragment } from 'react'
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { useIntl } from "react-intl";
import { useLayoutContext } from "contexts/Layout/layout-context";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { taxStatusValue } from "screens-v2/orders/utils/utils";
import DateTimeShow from 'component-v2/datetime-show';
import { concatString } from 'screens-v2/carts-v2/utils';
import { DateTimeShowType } from 'infrastructures/format-helpers';


const PopupVatDetail = (props) => {
  useLayoutContext();
  const intl = useIntl();
  const {
    onCancel = () => { },
    modalTitle = 'Thông tin chi tiết hóa đơn',
    isOpen,
    vatDataDetail,
    billingData
  } = props;

  const [expandList, setExpandList] = useState(0)

  const body = () => {
    const renderData = () => {
      if (!vatDataDetail) return null
      return [...vatDataDetail].map((item, index) => {
        const isExpand = index === expandList;
        const statusValue = taxStatusValue(item?.status);
        return (
          <Fragment key={index}>
            <tr >
              <td className="ml-4">
                {isExpand ?
                  <Button
                    size='xs'
                    light
                    className='cursor-pointer'
                    onClick={() => setExpandList(null)}
                  >
                    <Icon onConfirm Icon name='keyboard_arrow_down'></Icon>
                  </Button>
                  :
                  <Button
                    size='xs'
                    light
                    className='cursor-pointer'
                    onClick={() => setExpandList(index)}
                  >
                    <Icon className={'rotate--90'} color="var(--coolgray600)" name="keyboard_arrow_down" />
                  </Button>
                }
              </td>
              <td className="ml-4">
                <Badge className='l-mb-4 mw-100' light status={statusValue.badgeValue}>
                  {intl.formatMessage({
                    id: statusValue.title,
                    defaultMessage: statusValue.title,
                  })}
                </Badge>
              </td>
              <td className="ml-4 content-accent">
                <a target='_blank' href={item?.eInvoiceTrackingUrl} className="link" rel="noreferrer">{item?.haravanExternalCode ?? "---"}</a>
              </td>
              <td className="ml-4">{item?.eInvoiceIssuerName}</td>
            </tr>
            {/* expand data */}
            {isExpand ?
              <>
                <tr className='bg-light'>
                  <td></td>
                  <td className="ml-4">
                    <div className="d-flex flex-column">
                      <span className='content-secondary'>Ngày ký</span>
                      {item?.publishDate ? <DateTimeShow date={item?.publishDate} format='DD/MM/YYYY' type={DateTimeShowType.date} /> :
                        <span>--</span>}
                    </div>
                  </td>
                  <td className="ml-4">
                    <div className="d-flex flex-column">
                      <span className='content-secondary'>Số hóa đơn</span>
                      <span>{item?.invoiceNumber || '--'}</span>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr className='bg-light'>
                  <td className='border-0'></td>
                  <td className="ml-4 border-0">
                    <div className="d-flex flex-column">
                      <span className='content-secondary'>Ký hiệu</span>
                      <span>{(item?.invoicePattern || '').charAt(0) + (item?.invoiceSerialNumber || '') || '---'}</span>
                    </div>
                  </td>
                  <td className="ml-4 border-0">
                    <div className="d-flex flex-column">
                      <span className='content-secondary'>Mã cơ quan thuế</span>
                      <span>{item?.invoiceTaxCode || '--'}</span>
                    </div>
                  </td>
                  <td className='border-0'></td>
                </tr>
              </> : null}
          </Fragment>
        )
      })
    }

    const renderInfoVat = () => {
      const taxNameDefault = 'Người mua không lấy hóa đơn'
      return <>
        <p className='font-weight-semibold mb-3'>Thông tin xuất hóa đơn</p>
        {!(billingData?.eInvoiceInfo?.name || billingData?.billingCompany) && vatDataDetail?.length > 0 ?
          <div className="l-section--with-title mb-3">
            <label className='content-secondary mr-2'>Tên người mua hàng:</label>
            <div className="l-section--body">
              <div className="flex-row w-100">
                <div
                  className="flex-stretch overflow-auto"
                >
                  <span>{taxNameDefault}</span>
                </div>
              </div>
            </div>
          </div> :
          <>
            <div className="l-section--with-title mb-3">
              <label className='content-secondary mr-2'>{billingData?.eInvoiceInfo?.company ? 'Tên công ty:' : 'Tên:'}</label>
              <div className="l-section--body">
                <div className="flex-row w-100">
                  <div
                    className="flex-stretch overflow-autor"
                  >
                    <span>{billingData?.eInvoiceInfo?.name || billingData?.billingCompany || '--'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="l-section--with-title mb-3">
              <label className='content-secondary mr-2'>Mã số thuế:</label>
              <div className="l-section--body">
                <div className="flex-row w-100">
                  <div
                    className="flex-stretch overflow-auto"
                  >
                    <span>{billingData?.eInvoiceInfo?.taxCode || billingData?.billingTaxCode || '--'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="l-section--with-title mb-3">
              <label className='content-secondary mr-2'>{`Địa chỉ${billingData?.eInvoiceInfo?.company ? ' công ty' : ''}`}:</label>
              <div className="l-section--body">
                <div className="flex-row w-100">
                  <div
                    className="flex-stretch overflow-auto cursor-pointer"
                  >
                    <span>{billingData?.eInvoiceInfo?.address ||
                      concatString({
                        value: [
                          billingData?.address?.billingAddress,
                          billingData?.address?.billingWardName,
                          billingData?.address?.billingDistrictName,
                          billingData?.address?.billingCity,
                          billingData?.address?.billingProvinceName,
                        ],
                        separator: ", ",
                      })}</span>
                  </div>
                </div>
              </div>
            </div>
          </>}
      </>
    }

    return (
      <div className=''>
        {/* Thông tin xuất hóa đơn */}
        {renderInfoVat()}

        {/* // List hóa đơn */}
        <div className="product-detail-table-list">
          <table className="table">
            <thead>
              <tr>
                <th className="ml-4"></th>
                <th className="ml-4">Trạng thái</th>
                <th className="ml-4">Mã tra cứu</th>
                <th className="ml-4">Đơn vị phát hành</th>
              </tr>
            </thead>
            <tbody>
              {renderData()}
            </tbody>
          </table>
        </div>
      </div >
    );
  }

  return (
    <GlobalModal
      size={'lg'}
      headerTitle={modalTitle}
      isTitleCenter
      onConfirm={false}
      body={body()}
      onCancel={() => {
        onCancel()
      }}
      cancelTitle="Đóng"
      show={isOpen}
      setShow={(value) => props.setOpen(value ? value : null)}
    />
  );
}

export { PopupVatDetail }