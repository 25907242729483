import {
  getCheckoutSession,
  getDiscountRecommendation,
} from "api/checkout-repository";
import { getCustomerDetails } from "api/customer-repository";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import { cloneDeep, isNumber } from "lodash";
import { PaymentQrMethods } from "models/payment-methods-model";
import { FormattedMessage } from "react-intl";
import { getDecimalValue } from "utils/Commons";
import { memoizedGetProductDetail } from ".";
import { Fea_RecommendPromotions } from "configs";
const mappingHasAuthorizationCode = (method) => {
  switch (method) {
    case "visa":
      return true;
    case "transfer":
      return true;
    case "cod":
      return false;
    case "momo":
      return true;
    case "moca":
      return true;
    case "vnpay":
      return true;
    case "zalopay":
      return true;
    default:
      break;
  }
};
export const SysAttributeKeys = {
  LEGACY_LOY_TRAN_ID: "sys.legacy.loy.tran.id",
  LEGACY_LOY_DISCOUNT: "sys.legacy.loy.discount",
  SYS_REF_ORDER: "sys.pos.ref_order_id",
  SYS_LINE_LOTS: "sys.pos.lots",
  SYS_LINE_ADVISER: "x-haravan-pos-adviser",
};
const mappingHasOnly = (method) => {
  switch (method) {
    case "visa":
      return false;
    case "transfer":
      return false;
    case "cod":
      return false;
    case "momo":
      return true;
    case "moca":
      return true;
    case "vnpay":
      return true;
    case "zalopay":
      return true;
    default:
      break;
  }
};
export const isQRMethod = (name) => {
  return !!PaymentQrMethods[name?.toLowerCase()];
};
export const paymentMethodsDefault = (shop_setting) => {
  let defaultMethod = shop_setting.paymentMethods
    .filter((item) => item.isCustom === false)
    .reduce(
      (listMethod, method) => {
        let currentMethod = {
          type: method.code,
          name: method.name,
          amount: 0,
          enable: false,
          d_name: method.name,
          hasAuthorizationCode: mappingHasAuthorizationCode(method.code), //mã chuẩn chi
          hasOnly: mappingHasOnly(method.code),
          isHidden:
            !method.isEnable || (method.isQr && !method.paymentInstructionId),
          tooltip: `bằng ${method.name}`,
          isCustom: method.isCustom,
          index: listMethod.length,
          isQR: method.isQr && !method.isCustom,
          paymentInstructionId: method.paymentInstructionId,
          paymentInstructionTypeId: method.paymentInstructionType,
        };
        return listMethod.concat(currentMethod);
      },
      [
        {
          type: "cash",
          name: "Tiền mặt",
          amount: 0,
          enable: true,
          d_name: "Tiền mặt",
          hasAuthorizationCode: false, //mã chuẩn chi
          hasOnly: false,
          isHidden: false,
          tooltip: "bằng Tiền mặt",
          isCustom: false,
          index: 0,
        },
      ],
    );
  let customMethod = shop_setting.paymentMethods
    .filter((item) => item.isCustom === true)
    .reduce((listMethod, method) => {
      let currentMethod = {
        type: method.code,
        name: method.name,
        amount: 0,
        enable: false,
        d_name: method.name,
        // hasAuthorizationCode: mappingHasAuthorizationCode(method.code), //mã chuẩn chi
        hasAuthorizationCode: true, //mã chuẩn chi
        hasOnly: mappingHasOnly(method.code),
        isHidden: !method.isEnable,
        tooltip: `bằng ${method.name}`,
        isCustom: method.isCustom,
      };
      return listMethod.concat(currentMethod);
    }, []);
  return defaultMethod.concat(customMethod);
};

export const OrderSourceEnums = [
  { id: 1, name: "web", display: "Web" },
  { id: 2, name: "pos", display: "POS" },
  { id: 3, name: "fb", display: "Facebook" },
  { id: 4, name: "haravan_draft_order", display: "Draft", is_default: true },
  { id: 5, name: "zalo", display: "Zalo" },
  { id: 6, name: "phone", display: "Phone" },
  { id: 7, name: "staff", display: "Staff" },
  { id: 8, name: "lazada", display: "Lazada" },
  { id: 9, name: "tiki", display: "Tiki" },
  { id: 10, name: "fbshop", display: "Facebook Shop" },
  { id: 11, name: "harafunnel", display: "Harafunnel" },
  { id: 12, name: "app_android", display: "App Android" },
  { id: 13, name: "app_ios", display: "App IOS" },
  { id: 14, name: "harapos", display: "HaraPOS" },
  { id: 15, name: "shopee", display: "Shopee" },
  { id: 16, name: "googleshop", display: "Google Shop" },
  { id: 17, name: "harasocial", display: "Harasocial" },
  { id: 18, name: "sendo", display: "Sendo" },
  { id: 20, name: "tiktokshop", display: "Tiktok Shop" },
  { id: 21, name: "instagram", display: "Instagram" },
  { id: 22, name: "livestream", display: "Livestream" },
];

export const DiscountAllocationType = {
  LINE_ITEM: "LINEITEM",
  LINE_DISCOUNT: "LINEDISCOUNT",
  MANUAL_ORDER: "MANUALORDER",
  REDEEM_POINT: "REDEEMPOINT",
  ORDER: "ORDER",
  MEMBERSHIP: "MEMBERSHIP",
  PRODUCT: "PRODUCT",
  SAME_PRICE: "SAMEPRICE",
  SHIPPING: "SHIPPING",
};
export const PromotionType = {
  SAME_PRICE: "SamePrice",
  PERCENTAGE: "Percentage",
  AMOUNT: "Amount",
};
export const ManualDiscountType = {
  Amount: 0,
  Percent: 1,
};

export const pricingDefault = {
  id: null,
  name: "Bảng giá mặc định",
};
export const productQuantityMax = 99999;

export const getLotsData = async (callAPI, orderLine, appContext) => {
  try {
    let url = `/call/com_api/locations/${appContext.current_location.id}/products/${orderLine.productId}/variants/${orderLine.variantId}/lots?active=true&status=in_stock`;
    const response = await callAPI("get", url);
    if (response) {
      if (!response.errors || !response.errors.length) {
        const ratio = orderLine.selectedUnit ? orderLine.selectedUnit.ratio : 1;
        const data = (response.data || []).map((item) => ({
          ...item,
          quantity: getDecimalValue(appContext.shop_info, item.qty / ratio),
        }));
        return data;
      }
    }
    return [];
  } catch (err) {}
  return [];
};

export function fillLots(lotsData = [], quantity, shop_info, lotNo = null) {
  let lots = lotsData;
  if (lotNo) {
    lots = lotsData?.filter((x) => x.lotNo === lotNo);
  }
  let mappedData = lots?.reduce(
    (obj, lot, index) => {
      if (obj.quantityCanAdd === 0) return obj;
      let _lot = { ...lot };
      if (
        obj.quantityCanAdd > 0 &&
        lot.quantity > 0 &&
        index + 1 !== lots?.length
      ) {
        if (lot.quantity >= obj.quantityCanAdd) {
          _lot.quantity = obj.quantityCanAdd;
          obj.quantityCanAdd = 0;
        } else {
          _lot.quantity = lot.quantity;
          obj.quantityCanAdd = getDecimalValue(
            shop_info,
            obj.quantityCanAdd - lot.quantity,
          );
        }
        obj.lots.push(_lot);
      } else if (obj.quantityCanAdd > 0 && index + 1 === lots?.length) {
        _lot.quantity = obj.quantityCanAdd;
        obj.lots.push(_lot);
      }
      return obj;
    },
    { lots: [], quantityCanAdd: quantity },
  );
  return mappedData.lots;
}
export function updateLots(
  lineLots = [],
  lotsData = [],
  quantity,
  shop_info,
  lotNo = null,
) {
  let remainQuantity =
    quantity - lineLots?.reduce((sum, cur) => (sum += cur.quantity), 0);
  let mappedData = lineLots;
  if (remainQuantity < 0) {
    for (let i = mappedData?.length - 1; i >= 0; i--) {
      if (remainQuantity === 0) break;
      const lineLot = mappedData[i];
      if (lineLot.quantity + remainQuantity <= 0) {
        remainQuantity = remainQuantity + lineLot.quantity;
        lineLot.quantity = 0;
      } else {
        lineLot.quantity += remainQuantity;
        remainQuantity = 0;
      }
      mappedData[i] = { ...lineLot };
    }
  } else {
    if (!lotNo?.length) {
      mappedData = lineLots?.map((lineLot, index) => {
        if (remainQuantity === 0) return lineLot;
        const data = lotsData?.find((l) => l.id === lineLot.id);
        const lotQuantity = lineLot.quantity;
        if (lineLot.quantity >= data.qty) {
          if (index + 1 === lineLots?.length) {
            lineLot.quantity += remainQuantity;
            remainQuantity = 0;
          }
          return { ...lineLot };
        } else {
          if (lineLot.quantity + remainQuantity >= data.qty) {
            lineLot.quantity = data.qty;
            remainQuantity = getDecimalValue(
              shop_info,
              remainQuantity - (data.qty - lotQuantity),
            );
          } else {
            lineLot.quantity += remainQuantity;
            remainQuantity = 0;
          }
        }
        return { ...lineLot };
      });
    } else {
      let existItem = lineLots?.find((x) => x.lotNo === lotNo);
      if (!existItem) {
        let newLot = lotsData?.find((x) => x.lotNo === lotNo);
        if (newLot) {
          newLot.quantity = remainQuantity;
          const losts = [...lineLots, newLot];
          return losts;
        }
      } else {
        mappedData = lineLots?.map((lineLot, index) => {
          if (remainQuantity === 0 || lineLot.id !== existItem.id)
            return lineLot;
          const data = lotsData?.find((l) => l.id === lineLot.id);
          const lotQuantity = lineLot.quantity;
          if (lineLot.quantity >= data.qty) {
            if (index + 1 === lineLots?.length) {
              lineLot.quantity += remainQuantity;
              remainQuantity = 0;
            }
            return { ...lineLot };
          } else {
            if (lineLot.quantity + remainQuantity >= data.qty) {
              lineLot.quantity = data.qty;
              remainQuantity = getDecimalValue(
                shop_info,
                remainQuantity - (data.qty - lotQuantity),
              );
            } else {
              lineLot.quantity += remainQuantity;
              remainQuantity = 0;
            }
          }
          return { ...lineLot };
        });
      }
    }
  }
  return mappedData?.filter((lot) => lot.quantity > 0);
}
export const fulfillmentAsyncOrder = async (
  order,
  isShipping,
  appContext,
  showGlobalToast,
) => {
  const sumTotalQtyLot = (lots) =>
    +(lots || [])
      .map((i) => i?.quantity ?? 0)
      .reduce((prev, curr) => prev + curr, 0);
  const currentLocation = appContext.current_location;

  let orderProducts = cloneDeep(order.orderProducts);

  const isInValidQuantity = (orderProducts ?? []).some((product) => {
    return product?.lotSupport
      ? sumTotalQtyLot(product.lots || []) !== product.quantity
      : false;
  });

  if (isInValidQuantity && !isShipping) {
    showGlobalToast(
      "error",
      "Số lượng của lô phải bằng số lượng cần giao hàng",
    );
    return;
  }

  orderProducts = orderProducts?.filter(
    (p) => p.quantityNotFulfilled - p.restockQuantity > 0,
  );
  orderProducts = Array.isArray(orderProducts)
    ? orderProducts.map((product) => {
        let clone = { ...product };
        if (clone?.lotSupport)
          clone.lots = (product.lots || [])
            .filter((lot) => lot.quantity !== 0)
            .map((l) => ({
              lotExpireDate: l.lotExpireDate,
              lotNo: l.lotNo,
              quantity: l.quantity,
            }));
        return clone;
      })
    : orderProducts;
  const carrierModel = {
    id: 0,
    name: "",
    packageId: 1390170,
    packageName: "Khác",
    isActived: true,
    isShowPackageDimension: true,
    isShowPaymentMethod: false,
    isShowPaidBy: false,
    packageHeight: 0,
    packageLength: 0,
    packageWidth: 0,
    isShowCouponCode: false,
    carrierDiscountCode: "",
    isViewBefore: false,
    isShowViewBefore: true,
    isShownAllowTest: true,
    allowTest: false,
    isViewSenderInfo: false,
    transportType: 0,
    hasInsurance: false,
    paidByReceiver: false,
    isPickupTime: false,
    isPickupDateTime: false,
    codFee: 0,
    fee: 0,
    isInsurance: false,
    insurancePrice: 0,
  };
  let request = {
    carrierShippingService: carrierModel,
    order: {
      ...order,
      orderProducts: null,
      gHNPaymentType: 1,
      gHN2018PaymentType: 1,
      viettelPaymentType: 1,
      shipchungPaymentType: 1,
      proShipPaymentType: 1,
      vNPostPaymentType: 1,
      vNPostPlusPaymentType: 1,
      flexShipPaymentType: 1,
      gHTKPaymentType: 1,
      ninjavanPaymentType: 1,
      locationId: currentLocation.id,
      assignedLocationId: currentLocation.id,
      isCallNewCreateShipping: true,
      totalWeight: Number(order.totalWeight),
    },
    isSentEmail: true,
    listDetail: orderProducts,
  };

  return await appContext.callAPI(
    "post",
    `/call/com_api/orders/${order.orderId}/fulfillment_async`,
    request,
  );
};
const getPromotionLabel = (promotion) => {
  let label = "";
  switch (promotion.discountAmountType) {
    case PromotionType.AMOUNT:
      label = `Giảm ${toFormattedCurrency(
        promotion.discountAmount,
      )} mỗi sản phẩm`;
      break;
    case PromotionType.PERCENTAGE:
      label = `Giảm ${promotion.discountAmount}% ${promotion.maxDiscountAmount} sản phẩm`;
      break;
    case PromotionType.SAME_PRICE:
      label = `Đồng giá ${toFormattedCurrency(promotion.discountAmount)}: ${
        promotion.maxDiscountAmount
      } sản phẩm`;
      break;
    default:
      label = `Giảm giá ${promotion.maxDiscountAmount} sản phẩm`;
      break;
  }
  return label;
};
export const processingDiscover = async ({
  currentCart,
  newCart,
  showGlobalToast,
  appContext,
  methodDefaults,
  intl,
  isRefund = false,
}) => {
  const orderErrors = [
    ...newCart.errors?.filter(
      (err) => err?.field !== "Lines" && err?.field !== "Inventory",
    ),
    ...newCart.userErrors,
  ];
  if (!!orderErrors?.length && !!showGlobalToast) {
    if (newCart.id) {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: `notifications.${orderErrors[0]?.message}`,
          defaultMessage: orderErrors[0]?.message,
        }),
      );
      newCart = await getCheckoutSession(appContext.callAPI, newCart.id);
    }
  }
  const shippingValue = {
    shipping_price: newCart.shipping,
    shipping_price_after_tax: newCart.shipping_price_after_tax || 0,
    shipping_price_before_tax: newCart.shippingBeforeTax,
    shipping_tax: newCart.shippingTax,
    shipping_tax_included: newCart.shipping_tax_included,
    shipping_tax_rate: newCart.shipping_tax_rate || 0,
  };
  const vatValue = {
    vatSum: newCart.totalTaxIncluded + newCart.totalTaxNotIncluded,
    sumIncludedVat: newCart.totalTaxIncluded,
    sumNotIncludedVat: newCart.totalTaxNotIncluded,
  };
  const recommendPromotions =
    !isRefund &&
    Fea_RecommendPromotions(appContext?.auth?.user?.orgid) &&
    newCart?.id
      ? await getDiscountRecommendation(appContext.callAPI, newCart?.id).catch(
          (e) => new Error(e),
        )
      : null;
  const productsMap = new Map();
  let cartItems = await Promise.all(newCart.lineItems || []);
  let newLineItems = [];
  for (let i = 0; i < cartItems.length; i++) {
    const line = cartItems[i];
    let lineDetail = null;
    let linePromotions = null;
    if (!!recommendPromotions?.length) {
      linePromotions = recommendPromotions?.filter((r) => r.lineId === line.id);
    }
    let productDetail = null; // custom product is null
    if (line.productId && line.variantId) {
      if (productsMap.has(line.productId)) {
        productDetail = productsMap.get(line.productId);
      } else {
        productDetail = (
          await memoizedGetProductDetail(appContext, line.productId)
        )?.data;
        productsMap.set(line.productId, productDetail);
      }
      lineDetail = productDetail?.productVariants?.find(
        (variant) => variant.id === line.variantId,
      );
    }
    const currentLine = currentCart.lineItems?.find(
      (l) => l.variantId === line.variantId,
    );
    let lineLotsData = currentLine?.lotsData;
    let lineLots = JSON.parse(
      !!line?.attributes?.length
        ? line?.attributes?.find(
            (attr) => attr.key === SysAttributeKeys.SYS_LINE_LOTS,
          )?.value || `[]`
        : `[]`,
    );
    if (line.lotSupport) {
      if (!lineLotsData?.length && !!lineLots?.length) {
        lineLotsData = await getLotsData(appContext.callAPI, line, appContext);
      }
      if (typeof lineLots === "object" && !!lineLots?.length) {
        lineLots = lineLots?.map((lot) => ({
          ...lineLotsData?.find((d) => d.id === lot.id),
          ...lot,
        }));
      }
      line.lotsData = lineLotsData || [];
      line.lots =
        typeof lineLots === "object" && !!lineLots?.length ? lineLots : [];
    }
    let adviser = JSON.parse(
      line?.attributes?.find(
        (attr) => attr.key === SysAttributeKeys.SYS_LINE_ADVISER,
      )?.value || null,
    );
    const lineCouponsDiscountAmount = line.discountAllocations?.reduce(
      (sum, cur) => {
        if (
          cur.type === DiscountAllocationType.PRODUCT ||
          cur.type === DiscountAllocationType.SAME_PRICE
        ) {
          sum += cur.amount;
        }
        return sum;
      },
      0,
    );
    const lineDiscount = line.discountAllocations?.find(
      (d) => d.type === DiscountAllocationType.LINE_DISCOUNT,
    );
    const lineItemDiscount = line.discountAllocations?.filter(
      (d) => d.type !== DiscountAllocationType.LINE_DISCOUNT,
    );
    const lineItemDiscountAmount = lineItemDiscount?.reduce(
      (sum, cur) => (sum += cur.amount),
      0,
    );
    const defaultSelectedUnit = lineDetail?.variantUnits?.find((unit) =>
      line.variantUnitId
        ? unit.id === line.variantUnitId && unit.sellable
        : unit.base,
    );
    if (defaultSelectedUnit && defaultSelectedUnit.base)
      defaultSelectedUnit.price = lineDetail.price;
    linePromotions = linePromotions?.map((promo) => {
      return {
        ...promo,
        label_1: `Mua ${promo.minOrderAmount} sản phẩm`,
        label_2: `${getPromotionLabel(promo)} khuyến mãi`,
        getQuantity:
          newCart?.lineItems?.reduce((sum, line) => {
            if (promo?.data?.find((v) => v.productId === line.productId)) {
              return (sum += line.quantity);
            }
            return sum;
          }, 0) || 0,
        buyQuantity:
          newCart?.lineItems?.reduce((sum, line) => {
            if (
              promo?.conditionData?.find((v) => v.productId === line.productId)
            ) {
              return (sum += line.quantity);
            }
            return sum;
          }, 0) || 0,
      };
    });
    newLineItems.push({
      ...line,
      pricingInValid: !!(newCart.pricingListId && !line.pricingListId),
      lineErrors: newCart.errors?.filter(
        (err) => err?.refData === line?.variantId || err?.refData === line.id,
      ),
      originalPrice:
        (!lineItemDiscount?.length && line.pricingListId) || !line.variantId
          ? line.price
          : (line.selectedUnit || defaultSelectedUnit)?.price ||
            line.priceOriginal,
      discountPromotion: lineItemDiscount?.length
        ? lineItemDiscountAmount / line.quantity
        : 0,
      couponsDiscountAmount: lineCouponsDiscountAmount,
      price: line.linePrice,
      productPrice: line.linePrice,
      productName: line.productTitle,
      properties: lineItemDiscount || [],
      notAllowPromotion: line?.notAllowPromotion,
      ...(lineDiscount && {
        discountDescription: lineDiscount?.name,
        discountAmountPerLine: Number(
          (lineDiscount?.amount / line.quantity).toFixed(4),
        ),
      }),
      discountAmount: lineDiscount?.amount || 0,
      ...(lineDetail && {
        units: lineDetail.variantUnits?.filter((unit) => unit.sellable) || [],
        selectedUnit: line.selectedUnit || defaultSelectedUnit,
        variantUnitId: line.variantUnitId
          ? line.variantUnitId
          : line.selectedUnit?.id || defaultSelectedUnit?.id || null,
      }),
      autoFillLot: false,
      isShipping: line.requiresShipping,
      discountType:
        lineDiscount?.valueType === ManualDiscountType.Percent
          ? "percent"
          : lineDiscount?.valueType === ManualDiscountType.Amount
            ? "amount"
            : "new_price",
      discountValue:
        lineDiscount?.value ||
        (lineDiscount?.valueType === ManualDiscountType.Amount
          ? Number((lineDiscount?.amount / line.quantity).toFixed(4))
          : 0),
      ...(!!linePromotions?.length && {
        linePromotions,
        canApplyPromotions: linePromotions?.filter(
          (r) => r.isCanApply === true,
        ),
      }),
      adviser: adviser,
      isShowAdviser: !!adviser || currentLine?.isShowAdviser,
    });
  }
  let conditionVariants = recommendPromotions?.reduce((acc, item) => {
    const recommend = newLineItems?.find(
      (l) =>
        l.id === item.lineId &&
        !item.isCanApply &&
        !recommendPromotions?.find((r) =>
          l.discountAllocations.find((d) => d.discountId === r.id),
        ),
    );
    const linePromotion = recommend?.linePromotions?.find(
      (p) => p.id === item.id,
    );
    if (recommend) {
      acc.push({
        ...recommend,
        discountId: item.id,
        isCanApply: item.isCanApply,
        code: item.code,
        linePromotion,
      });
    }
    return acc;
  }, []);
  if (!!conditionVariants?.length) {
    const promotionMap = new Set([]);
    for (let i = 0; i < newLineItems.length; i++) {
      const item = newLineItems[i];
      const conditionProduct = conditionVariants.find(
        (p) =>
          p.productId === item.productId && p.id === item.id && !p.isCanApply,
      );
      const promoGroup = conditionVariants?.find((p) =>
        item?.discountAllocations.find((d) => p.discountId === d.discountId),
      );
      if (!!conditionProduct && !promoGroup) {
        item.promotionGroup = conditionProduct.discountId;
        item.promotionId = conditionProduct.discountId;
        item.promotionGroupCode = conditionProduct.code;
        item.promotionHeaderId = item.id;
        item.isPromotionGroupHeader = true;
        item.promotionGroupName = conditionProduct.code;
        item.isPromotionApplied = !conditionProduct.isCanApply;
        continue;
      }
      if (!!promoGroup) {
        if (promotionMap?.has(promoGroup.discountId)) {
          item.isPromotionGroupSibling = true;
        } else {
          promotionMap.add(promoGroup.discountId);
        }
        item.parentPromotion = promoGroup?.linePromotion;
        item.promotionGroup = promoGroup.discountId;
        item.promotionId = promoGroup.discountId;
        item.promotionGroupCode = promoGroup.code;
        item.promotionHeaderId = promoGroup.id;
        item.isPromotionGroupHeader = false;
        item.isPromotionGroupChild = true;
        item.isPromotionApplied = !promoGroup.isCanApply;
        item.linePromotions = [];
        item.canApplyPromotions = [];
      }
    }
    const groupByConditionHeader = {};
    for (var i = 0, len = newLineItems.length, l; i < len; i++) {
      l = newLineItems[i];

      if (groupByConditionHeader[l.promotionHeaderId] === undefined)
        groupByConditionHeader[l.promotionHeaderId] = {};

      if (
        groupByConditionHeader[l.promotionHeaderId][l.promotionGroup] ===
        undefined
      )
        groupByConditionHeader[l.promotionHeaderId][l.promotionGroup] = [];

      groupByConditionHeader[l.promotionHeaderId][l.promotionGroup].push(l);
    }
    for (let prop in groupByConditionHeader) {
      groupByConditionHeader[prop] = Object.values(
        groupByConditionHeader[prop],
      )?.reduce((res, cur) => [...res, ...cur], []);
      if (groupByConditionHeader[prop] !== undefined) {
        groupByConditionHeader[prop] = groupByConditionHeader[prop]?.sort(
          (a, b) => b.isPromotionGroupHeader - a.isPromotionGroupHeader,
        );
      }
    }
    newLineItems = Object.values(groupByConditionHeader)?.reduce(
      (res, cur) => [...res, ...cur],
      [],
    );
  }
  let newPaymentMethods = cloneDeep(currentCart.payment_methods);
  if (
    !!newPaymentMethods?.length &&
    !currentCart.payment_methods.find(
      (method) => method.type === "cod" && method.enable,
    )
  ) {
    const paymentMethodEnabled = currentCart?.payment_methods.filter(
      (method) => method.enable,
    );
    if (
      paymentMethodEnabled?.length &&
      paymentMethodEnabled?.length <= 1 &&
      paymentMethodEnabled[0].type === "cash"
    ) {
      newPaymentMethods[0].amount = newCart.total;
    }
  } else {
    newPaymentMethods = !!newPaymentMethods?.length
      ? currentCart.payment_methods
      : cloneDeep(methodDefaults);
    newPaymentMethods[0].amount = newCart.total;
  }
  const loyaltyDiscount = newCart.discountAllocations?.find(
    (d) =>
      d.type === DiscountAllocationType.MEMBERSHIP ||
      d.type === DiscountAllocationType.REDEEM_POINT,
  );
  const orderDiscount = newCart.discountAllocations?.find(
    (d) => d.type !== DiscountAllocationType.MEMBERSHIP,
  );
  const newOrderDiscountSelected = reCalcOrderDiscount(
    {
      ...currentCart.orderDiscountSelected,
      ...(orderDiscount?.type === DiscountAllocationType.ORDER ||
      orderDiscount?.type === DiscountAllocationType.SHIPPING ||
      orderDiscount?.type === DiscountAllocationType.SAME_PRICE ||
      orderDiscount?.type === DiscountAllocationType.PRODUCT ||
      orderDiscount?.type === DiscountAllocationType?.REDEEM_POINT
        ? { discountTypeName: "coupon" }
        : orderDiscount?.type === DiscountAllocationType.MANUAL_ORDER
          ? {
              discountTypeName:
                orderDiscount?.valueType === ManualDiscountType.Amount
                  ? "amount"
                  : "percent",
              couponCode: null,
              discountAmount: orderDiscount.amount,
              discountValue: orderDiscount.value,
            }
          : null),
    },
    newCart.discountAllocations || [],
    newLineItems,
    newLineItems?.reduce((total, lineItem) => {
      if (!lineItem.notAllowPromotion)
        return total + lineItem.price * lineItem.quantity;
      return total;
    }, 0),
  );
  let customerRes;
  let newCustomer = newCart?.customerId
    ? currentCart?.customer || {
        id: newCart?.customerId,
        key: newCart?.customerId,
      }
    : null;
  if (newCart.customerId) {
    customerRes = await getCustomerDetails(
      appContext.callAPI,
      newCart?.customerId,
    );
    if (customerRes?.data && !customerRes?.errors?.length) {
      newCustomer = customerRes?.data;
    }
  }
  const cart = {
    ...currentCart,
    ...newCart,
    shippingValue: {
      ...currentCart.shippingValue,
      ...shippingValue,
    },
    vatValue: {
      ...currentCart.vatValue,
      ...vatValue,
    },
    eInvoiceInfo: newCart.eInvoiceInfo,
    taxData: {
      ...currentCart?.taxData,
      eInvoiceInfo: newCart.eInvoiceInfo,
    },
    summaries: {
      ...currentCart.summaries,
      discount: orderDiscount?.amount || 0,
      discount_code: orderDiscount?.name || "",
      subtotal: newCart.subTotal,
      total_payment: newCart.total,
      subtotal_price_after_promotion: newCart.subTotal,
      shipping_fee: newCart.shipping,
    },
    isShipping:
      currentCart.isShipping || newCart.shippingMethodName === "Giao hàng sau",
    lineItems: newLineItems,
    items: newLineItems,
    orderProducts: newLineItems,
    ...(newCart.customerId
      ? {
          customer: {
            ...currentCart.customer,
            ...newCustomer,
          },
        }
      : { customer: null }),
    ...(loyaltyDiscount
      ? {
          loyalty_program: {
            ...currentCart.loyalty_program,
            program_name: loyaltyDiscount.name,
            type:
              loyaltyDiscount.type === DiscountAllocationType.MEMBERSHIP
                ? "membership"
                : "rewards",
            used_amount: loyaltyDiscount.amount,
            discount:
              loyaltyDiscount.type === DiscountAllocationType.MEMBERSHIP
                ? newCart?.attributes?.find(
                    (att) => att.key === SysAttributeKeys.LEGACY_LOY_DISCOUNT,
                  )?.value
                : "",
          },
          loyalty: {
            ...currentCart.loyalty,
            redeemUsedAmount:
              loyaltyDiscount.type === DiscountAllocationType.REDEEM_POINT
                ? loyaltyDiscount.amount
                : 0,
            rewardRedeemAmount:
              loyaltyDiscount.type === DiscountAllocationType.REDEEM_POINT
                ? loyaltyDiscount.amount /
                  (appContext?.shop_loyalty?.config?.exchange_money || 1)
                : 0,
            redeemConfig: {
              ...currentCart.loyalty?.redeemConfig,
              name: loyaltyDiscount.name,
              type:
                loyaltyDiscount.type === DiscountAllocationType.MEMBERSHIP
                  ? "membership"
                  : "rewards",
              id:
                newCart?.attributes?.find(
                  (att) => att.key === SysAttributeKeys.LEGACY_LOY_TRAN_ID,
                )?.value || "",
            },
          },
        }
      : {
          loyalty: {
            ...currentCart.loyalty,
            redeemUsedAmount: 0,
            rewardRedeemAmount: 0,
          },
          loyalty_program: {
            ...currentCart.loyalty_program,
            used_amount: 0,
          },
        }),
    noteAttributes: !!newCart?.attributes?.length
      ? newCart?.attributes?.filter(
          (attr) =>
            attr.key !== SysAttributeKeys.LEGACY_LOY_TRAN_ID &&
            attr.key !== SysAttributeKeys.LEGACY_LOY_DISCOUNT &&
            attr.key !== SysAttributeKeys.SYS_REF_ORDER,
        )
      : null,
    payment_methods: isRefund
      ? currentCart?.payment_methods
      : newPaymentMethods,
    pricingApplied: newCart.pricingListId
      ? { id: newCart.pricingListId }
      : pricingDefault,
    orderDiscountSelected: newOrderDiscountSelected,
    ...(newCart.saleUserId && {
      salesMan: appContext.staff_users?.find(
        (user) => user.id === newCart.saleUserId,
      ),
    }),
    reOrderLineItems: !!conditionVariants?.length,
  };
  return {
    ...cart,
  };
};
export const handlePrintData = ({
  data,
  model,
  dataMember,
  currentCart,
  salesMan,
  appContext,
  currentSession,
}) => {
  const location = appContext.current_location;
  const { discountTypeName, discountPercent } =
    currentCart.orderDiscountSelected;
  const customerData = currentCart.customer || {};
  let customerAddress =
    customerData && customerData.customerAddress
      ? customerData.customerAddress[0]
      : customerData;
  let totalAmoutProduct = 0;
  const orderModel = model;
  orderModel.orderProducts.map((product, index) => {
    product.variantValue = product.variantTitle;
    const lineItem =
      !!currentSession?.lineItems?.length && currentSession?.lineItems[index];
    if (
      lineItem &&
      lineItem.productId === product.productId &&
      lineItem.variantId === product.productVariantsId &&
      product.quantity === lineItem.quantity
    ) {
      product.discountAllocations = lineItem.discountAllocations;
      product.productPrice = lineItem.linePrice;
      product.totalPrice = lineItem.linePrice * lineItem.quantity;
    } else {
      product.productPrice = product.price;
      product.totalPrice = product.price * product.quantity;
    }
    totalAmoutProduct += product.price * product.quantity;
  });
  const amountPaid = model.orderTransactions.reduce((total, trans) => {
    return trans && trans.transactionAmount > 0
      ? total + trans.transactionAmount
      : total;
  }, 0);
  let shipFullName = "";
  const { firstName, lastName, email, phone } = customerData;
  if (
    customerData &&
    (firstName || lastName || email || phone) &&
    email !== "guest@haravan.com"
  ) {
    shipFullName = `${lastName ? lastName : ""}${
      firstName ? ` ${firstName}` : ""
    }`;
  }

  let totalDiscount = 0;
  if (
    !!currentSession?.discountAllocations &&
    !!currentSession?.discountAllocations.length
  ) {
    const orderDiscounts =
      currentSession.discountAllocations.filter(
        (d) =>
          d.type === DiscountAllocationType.MANUAL_ORDER ||
          d.type === DiscountAllocationType.ORDER ||
          d.type === DiscountAllocationType.SHIPPING,
      ) || [];
    for (let i = 0; i < orderDiscounts.length; i++) {
      totalDiscount += orderDiscounts[i].amount;
    }
  }

  let orderPrintData = {
    orderProducts: model.orderProducts,
    orderDate: new Date()?.toISOString(),
    orderId: data.orderId,
    userName: salesMan.name,
    orderNumber: data.orderNumber,
    shipFullName: shipFullName,
    shippingAddress: model.shippingAddress || "",
    wardName: customerAddress ? customerAddress.wardName : "",
    districtName: customerAddress ? customerAddress.districtName : "",
    shippingProvinceName: customerAddress ? customerAddress.provinceName : "",
    shippingCountryName: customerAddress ? customerAddress.countryName : "",
    customerEmail: customerData ? email : "",
    shippingPhone: customerData
      ? phone
        ? phone
        : customerData.customerAddressPhone
      : "",
    subTotal: totalAmoutProduct,
    orderShippingFee: currentCart.summaries.shipping_fee,
    discountAmount: totalDiscount,
    coupons: model.coupons || "",
    discountAllocations: currentSession?.discountAllocations || [],
    discountType: discountTypeName,
    discountPercent,
    totalPaid: currentCart.summaries.total_payment,
    amountPaid,
    amountRefuned: 0,
    totalRefunded: 0,
    orderNotes: model.orderNotes,
    noteAttributes: model.noteAttributes,
    totalMoneyPaidByCustomer: model.totalMoneyPaidByCustomer,
    transactions: model.orderTransactions,
    //loyalty
    redeemName: model.redeemServiceName,
    redeemUsedAmount: model.redeemUsedAmount,
    redeemDiscount: orderModel.redeemDiscount,
    dataMember: dataMember ? dataMember : null,
    //endloyalty
    locationId: location.id,
    locationName: location.locationName,
    customerId: orderModel.customerId,
    rewardRedeemAmount: currentCart?.loyalty?.rewardRedeemAmount,
    session: currentSession?.order,
  };
  return orderPrintData;
};

export const reCalcOrderDiscount = (
  discount,
  discountAllocations,
  newOrderLines,
  orderPriceTotal,
) => {
  let orderLines = newOrderLines || [];
  let orderDiscount = { ...discount };
  if (!orderDiscount) return;
  switch (orderDiscount?.discountTypeName) {
    case "coupon":
      const couponDiscounts = discountAllocations?.filter(
        (d) =>
          d.type !== DiscountAllocationType.MANUAL_ORDER &&
          d.type !== DiscountAllocationType.MEMBERSHIP &&
          d.type !== DiscountAllocationType.REDEEM_POINT,
      );
      const hasShippingCode = !!discountAllocations?.find(
        (d) => d.type === DiscountAllocationType.SHIPPING,
      );
      orderDiscount = {
        discountTypeId: hasShippingCode ? 3 : !!couponDiscounts?.length ? 5 : 0,
        discountTypeName: "coupon",
        discountAmount:
          couponDiscounts?.reduce((sum, cur) => (sum += cur.amount), 0) ?? 0,
        discountName: "Mã khuyến mãi",
        discountPercent: 0,
        coupons: couponDiscounts?.map((c) => c.name) || [],
        hasPromotion: false,
        isUseCouponCode: !!couponDiscounts?.length,
        isCouponCode: !!couponDiscounts?.length,
      };
      break;
    case "amount":
      let amount = orderDiscount?.discountAmount ?? 0;
      orderDiscount.discountAmount =
        amount < orderPriceTotal ? amount : orderPriceTotal;
      break;
    case "percent":
      orderDiscount.discountAmount =
        (orderDiscount.discountPercent * orderPriceTotal) / 100;
      orderDiscount.discountPercent = orderDiscount.discountValue;
      break;
    default:
      break;
  }
  orderDiscount.hasPromotion =
    !!orderLines?.length &&
    orderLines.some((orderLine) => orderLine?.hasPromotion);
  return orderDiscount;
};

export async function checkValidRedeem(
  appContext,
  { productHasPromotion = false, currentCart, showGlobalToast = () => {} },
) {
  let options = {};
  try {
    let amount = 0;
    let notAllowUseWithOtherDiscount = false;
    const priceTotal = currentCart?.summaries?.subtotal || 0;
    if (
      appContext.shop_loyalty?.enabled &&
      currentCart?.loyalty?.redeemConfig
    ) {
      if (currentCart?.loyalty?.redeemConfig.type === "rewards") {
        amount = currentCart?.loyalty?.rewardRedeemAmount;
      } else if (
        currentCart?.loyalty?.redeemConfig.type === "membership" &&
        appContext.checkNotAllowUseCouponCodeWithLoyaltyDiscount()
      ) {
        let priceSubTotal = priceTotal;

        //discountTypeId ===3 is coupon freeship
        if (currentCart?.orderDiscountSelected?.discountTypeId !== 3) {
          priceSubTotal =
            priceTotal -
            (currentCart?.orderDiscountSelected?.discountAmount || 0);
        }
        amount =
          (priceSubTotal * currentCart?.loyalty?.redeemConfig.discount) / 100;
        if (
          currentCart?.loyalty?.redeemConfig.max_per_order > 0 &&
          amount > currentCart?.loyalty?.redeemConfig.max_per_order
        ) {
          amount = currentCart?.loyalty?.redeemConfig.max_per_order;
        }
        if (currentCart?.loyalty?.rewardRedeemAmount === 1) {
          amount = 0;
        }
      } else {
        amount = 0;
      }
    }

    const orderHasPromotion =
      currentCart?.orderDiscountSelected?.discountAmount;
    if (
      (orderHasPromotion || productHasPromotion) &&
      currentCart?.lineItems?.length > 0 &&
      appContext.shop_loyalty?.is_allow
    ) {
      options = {
        customer_profile_id: currentCart?.loyalty?.profile.customer_profile_id,
        has_promotion: 1,
        amount_redeem: amount,
      };
      const result = await appContext.callAPI(
        "post",
        `/call/loy_api/v1/redeems/checkvalid`,
        options,
      );

      if (result?.data?.status === 0) {
        const { data } = result;
        let text_errors = "";
        if (
          data.message_text ===
          "error_loyalty_program_is_not_allowed_using_with_others"
        ) {
          text_errors =
            "Lưu ý: Chương trình khách hàng thân thiết không cho phép dùng chung với chương trình khuyến mãi khác!";
          notAllowUseWithOtherDiscount = true;
          amount = 0;
        }
        showGlobalToast(
          "error",
          text_errors ? (
            <FormattedMessage
              id={`notifications.${text_errors}`}
              defaultMessage={text_errors}
            />
          ) : (
            ""
          ),
        );
      } else {
        notAllowUseWithOtherDiscount = false;
      }
    }
    if (orderHasPromotion && notAllowUseWithOtherDiscount) {
      amount = 0;
    }

    return isNumber(amount) ? amount : 0;
  } catch (error) {
    return 0;
  }
}
