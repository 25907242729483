import React from "react";
import { FormattedMessage } from "react-intl";
import LotDateBadge from "./LotDateBadge";

export default function LotDateList({
  allowEdit = true,
  orderLine = {},
  isRefund = false,
  showReturnQty = false,
  onUpdateLotDate,
  onDeleteLotDate,
  handleOpenModalLotDate,
}) {
  return (
    <div className="d-flex align-items-center lotdate-badge-wrapper l-mt-4">
      {allowEdit && (
        <span
          className="cursor-pointer content-accent font-weight-semibold mr-3 no-wrap font-height-20 font-size-14"
          onClick={() => handleOpenModalLotDate()}
        >
          <FormattedMessage
            id="carts.orders.Chọn lô"
            defaultMessage="Chọn lô"
          />
        </span>
      )}
      {orderLine && orderLine.lots && orderLine.lots.length ? (
        <div className="d-flex flex-wrap" style={{ gap: "0.5rem 0rem" }}>
          {orderLine.lots.map((lot) => {
            return (
              <LotDateBadge
                key={lot.id || lot.lotId}
                data={lot}
                allowDelete={allowEdit}
                onUpdate={(item) => onUpdateLotDate(item)}
                onDelete={(item) => onDeleteLotDate(item)}
                showReturnQty={showReturnQty}
                isRefund={isRefund}
                ratio={orderLine?.ratio ?? 1}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
