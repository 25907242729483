import { useHotkeys } from "react-hotkeys-hook";

export default function useGlobalHotkeys({
  keys,
  callback,
  scopes,
  enabled = true,
  enableOnFormTags = true,
  preventDefault = true,
}) {
  if (!keys) throw new Error("Cần truyền keys để sử dụng hotkeys hook");
  if (!scopes) throw new Error("Cần truyền scope để sử dụng hotkeys hook");
  let _keys = keys;
  if (Array.isArray(keys)) {
    _keys = keys.join(", ");
  }
  const _options = {
    enabled,
    enableOnFormTags,
    preventDefault,
    scopes: scopes,
  };

  return useHotkeys(_keys, callback, _options);
}
