import { accountTypes } from "models/payment-voucher-models";

export const formDataPaymentDefault = {
  createdLoc: null,
  accountId: 0, // Tài khoản chi
  accountTypeId: accountTypes[0].id, // Chi vào quỹ
  objectId: null, // Chi Nhánh tạo phiếu
  objectTypeId: null, // Đối tượng nhận
  notes: '', // Ghi chú
  debt: false, // Ghi nhận vào công nợ
  pnL: false, // Ghi nhận vào báo cáo kết quả kinh doanh
  paymentMethod: 1, // Phương thức thanh toán
  typeId: 2, // Phiếu chi
  subTypeId: null, // Lý do chi
  refNumber: "", // Mã tham chiếu
  amount: 0, // Số tiền chi
  images: [],
};

export const formDataCashReceiptDefault = {
  createdLoc: null,
  accountId: 0, // Tài khoản chi
  accountTypeId: accountTypes[0].id, // Chi vào quỹ
  objectId: null, // Chi Nhánh tạo phiếu
  objectTypeId: null, // Đối tượng nhận
  notes: '', // Ghi chú
  debt: false, // Ghi nhận vào công nợ
  pnL: false, // Ghi nhận vào báo cáo kết quả kinh doanh
  paymentMethod: 1, // Phương thức thanh toán
  typeId: 1, // Phiếu thu
  subTypeId: null, // Lý do chi
  refNumber: "", // Mã tham chiếu
  amount: 0, // Số tiền chi
  images: [],
};

export const paymentVoucherCreateModal = {
  ACCOUNT: "account",
  REASON: "reason",
  OTHER: "other",
}

export const MAX_AMOUNT = "9999999999999"