import { Assets } from "assets";
import React, { useState } from "react";
import { CartPageKeyboardGuides } from "./models";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "component-v2/buttons";
import "./styles.css";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { HOTKEYS, HOTKEY_SCOPES } from "models/constants";
import { HotkeysProvider } from "react-hotkeys-hook";
import { useHotkeysContext } from "react-hotkeys-hook";
import { useApp } from "contexts/app";
import Icon from "component-v2/icons/icons";
import Tooltip from "rc-tooltip";
import { createPortal } from "react-dom";

const KeyboardShortcutGuide = ({ handleOnClick }) => {
  const { shop_info } = useApp();
  const intl = useIntl();
  const [showKeyboardGuide, setShowKeyboardGuide] = useState(false);
  const { enableScope, disableScope } = useHotkeysContext();
  useGlobalHotkeys({
    keys: HOTKEYS.ESC,
    callback: () => {
      enableScope(HOTKEY_SCOPES.CART);
      setShowKeyboardGuide(false);
    },
    scopes: HOTKEY_SCOPES.MODAL,
    enabled: showKeyboardGuide,
  });
  return (
    <React.Fragment>
      <div
        className="l-header--user-item action border-0"
        onClick={() => {
          disableScope(HOTKEY_SCOPES.CART);
          enableScope(HOTKEY_SCOPES.MODAL);
          setShowKeyboardGuide(true);
          handleOnClick?.();
        }}
      >
        <Icon name="keyboard" color="var(--contentSecondary)" />
        <span className="l-pl-8">Phím tắt</span>
      </div>
      {createPortal(
        <HotkeysProvider initialScopes={[HOTKEY_SCOPES.MODAL]}>
          <div
            className={`keyboard-shortcut-guide-wrapper${showKeyboardGuide ? " show" : ""}`}
          >
            <div
              className={`keyboard-shortcut-guide-overlay`}
              onClick={() => setShowKeyboardGuide(false)}
            />
            <div className={`keyboard-shortcut-guide-container`}>
              <div className="header">
                <p>Phím tắt bán hàng</p>
                <div
                  className="close-btn cursor-pointer"
                  onClick={() => setShowKeyboardGuide(false)}
                >
                  {Assets.icons_svg.close}
                </div>
              </div>
              <div className="content text-default pb-4 scrollable-y">
                {Object.values(
                  CartPageKeyboardGuides(shop_info.scale_support),
                ).map((guide) => {
                  if (guide.isHidden) return null;
                  return (
                    <div
                      key={guide.key}
                      className="content-item d-flex align-items-center justify-content-between"
                    >
                      <span className="content-item-name font-size-16">
                        {intl.formatMessage({
                          id: `carts.orders.${guide.name}`,
                          defaultMessage: guide.name,
                        })}
                      </span>
                      <span className="content-item-symbol font-size-16 text-capitalize">
                        {guide.icon || guide.key}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="footer">
                <Button
                  light
                  size="md"
                  onClick={() => setShowKeyboardGuide(false)}
                >
                  <FormattedMessage
                    id="systems.popups.Hủy"
                    defaultMessage="Hủy"
                  />
                  <span className="ml-3 keyboard-ESC">ESC</span>
                </Button>
              </div>
            </div>
          </div>
        </HotkeysProvider>,
        document.body,
      )}
    </React.Fragment>
  );
};
export default KeyboardShortcutGuide;
