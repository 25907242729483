import { Input, Loading } from "@haravan/react-components";
import { GetSuppliers } from "api/payment-voucher-api";
import { Assets } from "assets";
import { OutsideTag } from "components";
import { useGetDataAndLoadMore } from "hooks/useGetDataAndLoadMore";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import "./selections.css";

const SelectionSuppliers = ({ disabled, placeholder, handleOnChange }) => {
  const limit = 10;
  const _resultRef = useRef(null);
  const refInput = useRef(null);
  const [openDropdown, setOpenDropDown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isCallApi, setIsCallApi] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const this_ref = useRef(null);

  const { data, isLoading, hasLoadMore, clearData } = useGetDataAndLoadMore({
    searchText,
    limit,
    isCallApi,
    isLoadingMore,
    getDataApi: GetSuppliers,
  });

  useEffect(() => {
    setIsLoadingMore(false);
  }, [data]);

  useEffect(() => {
    if (selectedSupplier) {
      setSearchText(
        selectedSupplier?.supplierName || selectedSupplier?.value || "--",
      );
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown]);

  function handleOutsideClick(e) {
    if (!this_ref.current.contains(e.target)) {
      setOpenDropDown(false);
      setIsCallApi(false);
      clearData();
      document.removeEventListener("click", handleOutsideClick, false);
    }
  }

  const handleSelectedSupplier = (supplier) => {
    setSelectedSupplier(supplier);
    setIsCallApi(false);
    setOpenDropDown(false);
    clearData();
    handleOnChange(supplier.key);
  };

  const handleScroll = (e) => {
    if (_resultRef?.current) {
      const isScrollEnd =
        _resultRef?.current.clientHeight + _resultRef?.current.scrollTop + 50 >=
        _resultRef?.current.scrollHeight;
      if (isScrollEnd && hasLoadMore && !isLoading) {
        setIsLoadingMore(true);
      }
    }
  };

  const renderPopupContent = () => {
    return (
      <div
        id="selection-wrapper-result"
        className="selection-wrapper scrollable-y w-100 mt-3 position-absolute"
        style={{ maxHeight: "345px" }}
        onScroll={() => handleScroll()}
        ref={_resultRef}
      >
        {isLoading ? (
          <li className="list-group-item" style={{ height: "69px" }}>
            <Loading size="pico" />
          </li>
        ) : (
          <ul className="list-group">
            {data.map((supplier) => {
              return (
                <li
                  key={supplier.key}
                  onClick={() => handleSelectedSupplier(supplier)}
                  className={`list-group-item pointer selection-item ${supplier.key === selectedSupplier?.key ? "selection-item-active" : ""}`}
                >
                  <div className="d-flex flex-column">
                    <p
                      className="text-default font-size-14 font-weight-500 text-line-1 text-truncate no-wordwrap"
                      title={supplier.supplierName}
                    >
                      {supplier?.supplierName || "--"}{" "}
                    </p>
                    <p
                      className="text-primary font-size-14 text-line-1 text-break text-truncate no-wordwrap"
                      title={supplier.value}
                    >
                      {supplier?.value || "--"}
                    </p>
                  </div>
                </li>
              );
            })}
            {isLoadingMore && (
              <li className="list-group-item padding-8">
                <Loading size="pico" />
              </li>
            )}
            {!data.length && !isLoading && (
              <>
                <li className="list-group-item padding-16 text-center text-black">
                  {searchText
                    ? "Không tìm thấy nhà cung cấp."
                    : "Không có dữ liệu"}
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div ref={(node) => (this_ref.current = node)}>
      <div className={`input-group cart-input new-input-focused`}>
        <div
          onClick={() => {
            refInput.current.onSetFocus();
          }}
          id="selection-wrapper-input"
          className="hrv-next-input text-right position-relative cursor-pointer hrv-select-selection"
          style={{ height: "max-content" }}
        >
          <Input
            id="search_suppliers"
            type="text"
            className="text-black py-0 bg-white mr-4 selection-input"
            placeholder={placeholder}
            value={searchText}
            onChange={(value) => {
              setSearchText(value);
            }}
            onFocus={() => {
              setOpenDropDown(true);
              setIsCallApi(true);
            }}
            style={{ borderBottom: "none" }}
            disabled={disabled}
            autoComplete="off"
            ref={refInput}
          />
          <span
            onClick={() => {
              setOpenDropDown((prev) => {
                if (!prev) {
                  // refInput?.current?.focus();
                  setIsCallApi(true);
                }
                return !prev;
              });
            }}
            className="hrv-select-arrow cursor-pointer"
            unselectable="on"
            style={{ userSelect: "none" }}
          >
            <svg
              className="hrv-svg-inline-block hrv-svg-next-icon-rotate-90 hrv-select-arrow-icon"
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
            >
              <path d="M6.47 4L5.53 4.94L8.58333 8L5.53 11.06L6.47 12L10.47 8L6.47 4Z"></path>
            </svg>
          </span>
        </div>
        {openDropdown && renderPopupContent()}
      </div>
    </div>
  );
};

SelectionSuppliers.propTypes = {
  handleOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
};

export default SelectionSuppliers;
