import { useMemo, useState, useCallback } from "react";
import { Switch, InputRadio, Input } from "@haravan/react-components";
import { useIntl } from "react-intl";
import { updateSettingGeneral } from "api/settings-repository";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  LayoutCard,
  LayoutCardHeader,
  LayoutCardSection,
} from "screens-v2/layouts/layouts/omni-layout-card";
import Button from "component-v2/buttons";
import EditGatewayModal from "../EditGatewayModal";
import { useApp } from "contexts/app";
import { debounce } from "lodash";
import { Fea_BarcodePattern } from "configs";

function GeneralSetting() {
  const { shop_setting, refreshShopSetting, current_location } = useApp();
  const intl = useIntl();
  const layout = useLayoutContext();
  const [showGateway, setShowGateway] = useState(false);
  const appContext = useApp();
  const [isBarcodePatternCustom, setIsBarcodePatternCustom] = useState(
    shop_setting.isBarcodePatternCustom,
  );
  const [barcodePattern, setBarcodePattern] = useState(
    shop_setting.barcodePattern,
  );
  const [barcodePatternText, setBarcodePatternText] = useState("");
  const [patternGroups, setPatternGroup] = useState(null);
  const [error, setError] = useState(null);
  const generalValues = useMemo(() => {
    return {
      autoPrint: shop_setting.printSettings?.autoPrint,
      allowChangeSeller: shop_setting.allowChangeSeller,
      shippingPriceEnable: shop_setting.shippingPriceEnable,
      promotionEnable: shop_setting.promotionEnable,
      allowPrintTempBill: shop_setting.printSettings?.allowPrintTempBill,
      isNewPos: shop_setting.isNewPos,
      barcodePattern: shop_setting.barcodePattern,
      isBarcodePatternCustom: shop_setting.isBarcodePatternCustom,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop_setting]);

  const debounceOnChange = useCallback(
    debounce((value) => {
      setBarcodePatternText(value);
    }, 500),
    [],
  );

  const onCheckPattern = () => {
    if (
      !isBarcodePatternCustom ||
      !barcodePattern ||
      !barcodePattern.length ||
      !barcodePatternText ||
      !barcodePatternText.length
    ) {
      setPatternGroup(null);
      setError(null);
      return;
    }
    try {
      const pattern = `(${barcodePattern})`;
      const regex = new RegExp(pattern);
      const match = regex.exec(barcodePatternText);
      setPatternGroup(match?.groups);
      if (match === null) {
        setError("Pattern không hợp lệ");
      } else {
        setError(null);
      }
    } catch (error) {
      setPatternGroup(null);
      setError("Pattern không hợp lệ");
    }
  };

  const renderGroups = () => {
    if (!patternGroups) {
      return (
        <ul className="mt-4">
          <div>
            Output: <span className="content-danger">{error}</span>
          </div>
        </ul>
      );
    }
    const list = [];
    for (let property in patternGroups) {
      list.push({ id: property, value: patternGroups[property] });
    }
    return (
      <ul className="mt-4">
        <span>Output: </span>
        {list.map((x) => {
          return (
            <li key={x.id} className="mt-4">
              <span>{x.id}</span>:{" "}
              <span>
                <strong className="">{x.value}</strong>
              </span>
            </li>
          );
        })}
      </ul>
    );
  };

  const onUpdateSetting = async (settingKey, isPrintSettings) => {
    let model = null;
    if (settingKey === "isBarcodePatternCustom") {
      model = {
        ...generalValues,
        isBarcodePatternCustom: isBarcodePatternCustom,
        barcodePattern: barcodePattern,
      };
    } else {
      const value = isPrintSettings
        ? !shop_setting.printSettings[settingKey]
        : !shop_setting[settingKey];
      model = {
        ...generalValues,
        [settingKey]: value,
      };
    }
    const response = await updateSettingGeneral(
      appContext,
      model,
      current_location.id,
    );
    if (response?.data) {
      await refreshShopSetting();
      layout.showGlobalToast(
        "default",
        "Cấu hình đã được cập nhật thành công!",
      );
    } else {
      layout.showGlobalToast(
        "error",
        "Cập nhật cấu hình không thành công. Vui lòng thử lại!",
      );
    }
  };

  return (
    <LayoutCard className="bg-transparent">
      <LayoutCardSection className="pt-0">
        <LayoutCardHeader title="Cấu hình chung" />
        <p className="text-secondary mt-2">Cấu hình chung cho cửa hàng</p>

        <div className="border-top mt-4">
          <p className="mt-4 text-secondary font-weight-medium">
            {intl.formatMessage({
              id: "settings.shifts.Cấu hình chung",
              defaultMessage: "Cấu hình chung",
            })}
          </p>
          <LayoutCard className="l-mt-8">
            <LayoutCardSection className="d-flex">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  {intl.formatMessage({
                    id: "settings.generals.Tự động in hóa đơn",
                    defaultMessage: "Tự động in hóa đơn",
                  })}
                </p>
                <p className="text-secondary font-size-12">
                  {intl.formatMessage({
                    id: "settings.generals.Hóa đơn tự động in ngay sau khi tạo thành công đơn hàng",
                    defaultMessage:
                      "Hóa đơn tự động in ngay sau khi tạo thành công đơn hàng",
                  })}
                </p>
              </div>
              <div className="text-right">
                <Switch
                  onChange={() => onUpdateSetting("autoPrint", true)}
                  checked={shop_setting.printSettings.autoPrint}
                />
              </div>
            </LayoutCardSection>
            <LayoutCardSection className="d-flex">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  {intl.formatMessage({
                    id: "settings.generals.Cho phép in hóa đơn tạm tính",
                    defaultMessage: "Cho phép in hóa đơn tạm tính",
                  })}
                </p>
                <p className="text-secondary font-size-12">
                  {intl.formatMessage({
                    id: "settings.generals.Bổ sung nút in hóa đơn trước khi bấm Thanh toán",
                    defaultMessage:
                      "Bổ sung nút in hóa đơn trước khi bấm Thanh toán",
                  })}
                </p>
              </div>
              <div className="text-right">
                <Switch
                  onChange={() => onUpdateSetting("allowPrintTempBill", true)}
                  checked={shop_setting.printSettings?.allowPrintTempBill}
                />
              </div>
            </LayoutCardSection>
            <LayoutCardSection className="d-flex">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  {intl.formatMessage({
                    id: "settings.generals.Thay đổi nhân viên bán hàng",
                    defaultMessage: "Thay đổi nhân viên bán hàng",
                  })}
                </p>
                <p className="text-secondary font-size-12">
                  {intl.formatMessage({
                    id: "settings.generals.Cho phép thay đổi tên nhân viên bán hàng",
                    defaultMessage: "Cho phép thay đổi tên nhân viên bán hàng",
                  })}
                </p>
              </div>
              <Switch
                onChange={() => onUpdateSetting("allowChangeSeller")}
                checked={shop_setting.allowChangeSeller}
              />
            </LayoutCardSection>
            <LayoutCardSection className="d-flex">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  {intl.formatMessage({
                    id: "settings.generals.Hiển thị phí vận chuyển khi tạo đơn hàng",
                    defaultMessage: "Hiển thị phí vận chuyển khi tạo đơn hàng",
                  })}
                </p>
                <p className="text-secondary font-size-12">
                  {intl.formatMessage({
                    id: "settings.generals.Cho phép hiển thị và nhập phí vận chuyển khi tạo đơn, áp dụng cho những đơn hàng giao hàng sau",
                    defaultMessage:
                      "Cho phép hiển thị và nhập phí vận chuyển khi tạo đơn, áp dụng cho những đơn hàng giao hàng sau",
                  })}
                </p>
              </div>
              <Switch
                onChange={() => onUpdateSetting("shippingPriceEnable")}
                checked={shop_setting.shippingPriceEnable}
              />
            </LayoutCardSection>
          </LayoutCard>
        </div>

        <div className="mt-4">
          <p className="mt-4 text-secondary font-weight-medium">
            {intl.formatMessage({
              id: "settings.shifts.Cấu hình phương thức thanh toán",
              defaultMessage: "Cấu hình phương thức thanh toán",
            })}
          </p>
          <LayoutCard className="l-mt-8">
            <LayoutCardSection className="d-flex">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  {intl.formatMessage({
                    id: "settings.generals.Cho phép thêm các phương thức thanh toán tại cửa hàng",
                    defaultMessage:
                      "Cho phép thêm các phương thức thanh toán tại cửa hàng",
                  })}
                </p>
                <p className="text-secondary font-size-12">
                  {intl.formatMessage({
                    id: "settings.generals.Cho phép khách hàng thanh toán ở cửa hàng theo nhiều hình thức",
                    defaultMessage:
                      "Cho phép khách hàng thanh toán ở cửa hàng theo nhiều hình thức",
                  })}
                </p>
              </div>
              <Button
                className={"h-100 ml-3"}
                status="primary"
                onClick={() => {
                  setShowGateway(true);
                }}
              >
                + <span>Thêm phương thức thanh toán</span>
              </Button>
            </LayoutCardSection>
          </LayoutCard>
        </div>

        <div className="mt-4">
          <p className="mt-4 text-secondary font-weight-medium">
            {intl.formatMessage({
              id: "settings.shifts.Cấu hình khuyến mãi",
              defaultMessage: "Cấu hình khuyến mãi",
            })}
          </p>
          <LayoutCard className="l-mt-8">
            <LayoutCardSection className="d-flex">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  {intl.formatMessage({
                    id: "settings.generals.Cho phép điều chỉnh giá sản phẩm",
                    defaultMessage: "Cho phép điều chỉnh giá sản phẩm",
                  })}
                </p>
                <p className="text-secondary font-size-12">
                  {intl.formatMessage({
                    id: "settings.generals.Cho phép nhân viên bán hàng có thể giảm giá sản phẩm, đơn hàng khi bán hàng",
                    defaultMessage:
                      "Cho phép nhân viên bán hàng có thể giảm giá sản phẩm, đơn hàng khi bán hàng",
                  })}{" "}
                  <br />-{" "}
                  {intl.formatMessage({
                    id: "settings.generals.Thêm khuyến mãi theo sản phẩm",
                    defaultMessage: "Thêm khuyến mãi theo sản phẩm",
                  })}{" "}
                  <br />-{" "}
                  {intl.formatMessage({
                    id: "settings.generals.Khuyến mãi giảm giá theo Tiền mặt (đ) hoặc Phần trăm (%) trên tổng hóa đơn khi tạo đơn hàng",
                    defaultMessage:
                      "Khuyến mãi giảm giá theo Tiền mặt (đ) hoặc Phần trăm (%) trên tổng hóa đơn khi tạo đơn hàng",
                  })}{" "}
                  <br />
                </p>
              </div>
              <Switch
                onChange={() => onUpdateSetting("promotionEnable")}
                checked={shop_setting.promotionEnable}
              />
            </LayoutCardSection>
          </LayoutCard>
        </div>
        {Fea_BarcodePattern(appContext.auth?.user?.orgid) && (
          <div className="mt-4">
            <p className="mt-4 text-secondary font-weight-medium">
              {intl.formatMessage({
                id: "settings.shifts.Cấu hình Barcode",
                defaultMessage: "Cấu hình Barcode",
              })}
            </p>
            <LayoutCard className="l-mt-8">
              <LayoutCardSection className="d-flex">
                <div className="flex-stretch">
                  <p className="text-default font-weight-medium">
                    {intl.formatMessage({
                      id: "settings.generals.Cấu hình pattern barcode khi quét mã bằng máy",
                      defaultMessage:
                        "Cấu hình pattern barcode khi quét mã bằng máy",
                    })}
                  </p>
                  <div className="mt-4">
                    <InputRadio
                      id="barcode-pattern-default"
                      value={false}
                      checked={!isBarcodePatternCustom}
                      onChange={() => {
                        setIsBarcodePatternCustom(false);
                      }}
                    >
                      {intl.formatMessage({
                        id: "settings.shifts.Mặc định",
                        defaultMessage: "Mặc định",
                      })}
                    </InputRadio>
                  </div>
                  <div className="mt-4">
                    <InputRadio
                      id="barcode-pattern-custom"
                      value={true}
                      checked={isBarcodePatternCustom}
                      onChange={() => {
                        setIsBarcodePatternCustom(true);
                      }}
                    >
                      {intl.formatMessage({
                        id: "settings.shifts.Tùy chỉnh",
                        defaultMessage: "Tùy chỉnh",
                      })}
                    </InputRadio>
                    {isBarcodePatternCustom ? (
                      <div className="barcode-pattern-config">
                        <div className="mt-2 barcode-pattern-config--item">
                          <span>Pattern</span>
                          <Input
                            placeholder={`(?<barcode>.+)\.(?<lotNo>.+)`}
                            id={"barcode-pattern"}
                            value={barcodePattern}
                            onChange={(value) => {
                              setBarcodePattern(value);
                            }}
                          />
                        </div>
                        <div className="mt-2 barcode-pattern-config--item">
                          <span>Input</span>
                          <Input
                            id={"barcode-pattern-test"}
                            value={barcodePatternText}
                            onChange={(value) => {
                              debounceOnChange(value);
                            }}
                          />
                          <Button
                            size="md"
                            light
                            className="cursor-pointer"
                            disabled={
                              !barcodePattern?.length ||
                              !barcodePatternText?.length
                            }
                            onClick={() => onCheckPattern()}
                          >
                            Kiểm tra
                          </Button>
                        </div>
                        {renderGroups()}
                      </div>
                    ) : null}
                  </div>
                </div>
                <Button
                  size="md"
                  status="primary"
                  className="cursor-pointer"
                  onClick={() => onUpdateSetting("isBarcodePatternCustom")}
                >
                  Lưu
                </Button>
              </LayoutCardSection>
            </LayoutCard>
          </div>
        )}
        <EditGatewayModal show={showGateway} setShow={setShowGateway} />
      </LayoutCardSection>
    </LayoutCard>
  );
}

export default GeneralSetting;
