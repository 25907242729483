import { LayoutCardItem } from "screens-v2/layouts/layouts/omni-layout-card";
import OrderDetailProductItem from "./order-detail-product-item";
import { memo } from "react";

export default memo(function OrderDetailProducts({ order, session }) {
  return (
    <LayoutCardItem className="order-detail--products">
      <div className="l-table">
        {order.orderProducts.length > 0 &&
          order.orderProducts.map((product) => {
            const sessionLine = session?.order?.lineItems?.find(
              (line) => line.id === product.id,
            );
            return (
              <OrderDetailProductItem
                key={product.id}
                product={product}
                sessionLine={sessionLine}
                newCheckout={order.newCheckout}
              />
            );
          })}
      </div>
    </LayoutCardItem>
  );
});
