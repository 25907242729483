import {forEach, filter, slice} from 'lodash';
import {ProductUtils} from 'utils';
import {getVariantInventory} from "api/inventory-repository";


export class ProductHelperService {

  filterInventory(_filter, listInventory, page = 1, limitProduct = 10) {
    const {provinceId, districtId, keySearch} = _filter;
    let result = {
      listShopAvailable: [],
      totalRecord: 0
    };
    let listShop = null;
    if (provinceId || districtId) {
      listShop = filter(listInventory, (item) => {
        return districtId ? item.locDistrictId === districtId : item.locProvinceId === provinceId;
      });
      if (keySearch) {
        result.listShopAvailable = this.getInventory(this.filterLikeInventoryByText(listShop, keySearch), page, limitProduct);
        result.totalRecord = this.filterLikeInventoryByText(listShop, keySearch).length;
      } else {
        result.listShopAvailable = this.getInventory(listShop, page, limitProduct);
        result.totalRecord = listShop.length;
      }
    } else {
      if (keySearch) {
        result.listShopAvailable = this.getInventory(this.filterLikeInventoryByText(listInventory, keySearch), page, limitProduct);
        result.totalRecord = this.filterLikeInventoryByText(listInventory, keySearch).length;
      } else {
        result.listShopAvailable = this.getInventory(listInventory, page, limitProduct);
        result.totalRecord = listInventory && listInventory.length;
      }
    }

    return result;
  };

  filterLikeInventoryByText(listShop, keySearch) {
    let re = new RegExp(ProductUtils.formatText(keySearch), 'i');
    let listAvailable = filter(listShop, item => {
      return ProductUtils.formatText(item.locationName).search(re) !== -1;
    });
    return listAvailable;
  };

  async getDetailProductInventory(appContext, variantId, locationId) {
    try {

      const inventoryLocations = await appContext.shop_locations;
      let result = await getVariantInventory(appContext, variantId, locationId);
      let inventoryListTmp = result.data.data;
      let inventoryList = [];
      forEach(inventoryListTmp, (item, index) => {
        if ((item.qty_OnHand - item.qty_Commited) > 0) {
          let _location = inventoryLocations.find((location) => {
            return location.id === item.locId;
          });
          if (_location) {
            item.locProvinceId = _location.provinceId;
            item.locDistrictId = _location.districtId;
            item.locProvinceName = _location.provinceName;
            item.locWardName = _location.wardName;
            item.holdStock = _location.holdStock;
            item.locAddress =
              (_location.street ? _location.street + ' - ' : '') +
              (_location.wardName ? _location.wardName + ' - ' : '') +
              (_location.districtName ? _location.districtName + ' - ' : '') +
              (_location.provinceName ? _location.provinceName : '');
          }
          if (!item.isUnavailableQty) {
            inventoryList.push(item);
          }
        }
      });
      return {
        totalRecord: result.data.totalRecord,
        listInventory: inventoryList,
        isAvailable: inventoryList.length > 0 ? true : false
      };
    } catch (error) {
      return {
        totalRecord: 0,
        listInventory: [],
        isAvailable: false
      };
    }
  }

  getInventory = (list, page = 1, limit = 10) => {
    let listInventory = [];
    if (list) {
      listInventory = [...list];
    }
    return slice(listInventory, ((page * limit) - limit), (limit * page));
  };

}