import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { Input, Tooltip } from "@haravan/react-components";
import { useIntl } from "react-intl";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { ProductCustomModal } from "screens-v2/refunds-v2/components/ProductCustomModal";
import { useApp } from "contexts/app";
export const RefundCartSearchInput = forwardRef((props, ref) => {
  const { shop_setting } = useApp();
  const { disabled, keyword, onChange, onFocus, onBlur, isFocused } = props;
  const [isCreateProductCustom, setIsCreateProductCustom] = useState(false)
  const handleHotKeyClick = () => {
    let dom = document.getElementById("refund_cart_search_product");
    if (dom) dom.focus();
  };

  return (
    <div className="w-100">
      {!props.isFocused && keyword.length === 0 ? (
        <span className="refund-cart-search-hotkey" onClick={handleHotKeyClick}>
          F2
        </span>
      ) : null}

      <div className="refund-cart-search-container p-2">
        <span className={`refund-cart-search-icon `}>
          <Icon name={"search"} color="var(--contentSecondary)" />
        </span>
        <input
          id="refund_cart_search_product"
          type="text"
          autoComplete="off"
          ref={ref}
          disabled={disabled}
          className="form-control refund-cart-search-box h-100"
          placeholder="Tìm sản phẩm hoặc nhập barcode"
          value={keyword}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {keyword.length > 0 && (
          <Button
            size="sm"
            plain
            icon="close"
            title=""
            className="l-ml-8 refund-cart-search-icon "
            onClick={props.onClearSearch}
          />
        )}
        {
          shop_setting?.allowCreateCustomProduct ? <>
            <span className={`cart-search-icon-blur ml-3`}>
              <Button
                tooltipTitle="Tạo sản phẩm tùy ý"
                className={"flex-shrink-0"}
                light
                size="sm"
                plain
                onClick={() => {
                  setIsCreateProductCustom(true);
                }}
                icon={"add"}
                status="primary"
                iconColor={"var(--contentAccent)"}
              />
            </span>
            <ProductCustomModal
              show={isCreateProductCustom}
              setShow={setIsCreateProductCustom}
            />
          </> : null
        }
      </div>
    </div>
  );
});

RefundCartSearchInput.propTypes = {
  isFocused: PropTypes.bool,
  isElectricScale: PropTypes.bool,
  enableElectricScale: PropTypes.bool,
  keyword: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onESClick: PropTypes.func,
  onClearSearch: PropTypes.func,
};
