import { ENDPOINT_GATEWAY, useGetDataSWR } from "api/api-connect-base";

export const useDailyReportSummaryData = (from_date, to_date, location_ids, created_user_ids) => {
  let endpoint = '/pos/cash_flows/Summary'
  if(!from_date) endpoint = null
  if(!to_date) endpoint = null
  if(!location_ids) endpoint = null
  let params = {source: 'hararetail', from_date, to_date, location_ids}
  if(created_user_ids) params.created_user_ids = created_user_ids
  const a = useGetDataSWR(ENDPOINT_GATEWAY.commerce, endpoint,params)
  const data = a?.data;
  return { data, isValidating: a.isValidating }
}
export const useDailyReportResultData = (from_date, to_date, location_ids, created_user_ids, account_ids, type, limit = 20, after, before) => {
  let endpoint = '/cash_flows/report'
  if(!from_date) endpoint = null
  if(!to_date) endpoint = null
  if(!location_ids) endpoint = null
  let params = {source: 'hararetail', from_date, to_date, location_ids, limit}
  if(created_user_ids) params.created_user_ids = created_user_ids
  if(account_ids) params.account_ids = account_ids
  if(type) params.type = type
  if(after) params.after = after
  if(before) params.before = before
  
  const a = useGetDataSWR(ENDPOINT_GATEWAY.commerce, endpoint,params)
  const data = a?.data;
  return { data, isValidating: a.isValidating }
}
