import { isNil } from "lodash";
import numeral from "numeral";
import moment from "moment";

export const generatePaymentUrl = (shop_info, checkoutToken) => {
  let link = null;
  if (checkoutToken && shop_info && shop_info.buyer_domain) {
    link = `${shop_info.buyer_domain}checkouts/${checkoutToken}/payment`;
  }
  return link;
};

export const showOnlinePayment = (shop_info) => {
  const { online_payment_support } = shop_info;
  return online_payment_support;
};

export const getDecimalValue = (shop_info, input, format = null) => {
  if (!format) format = isAllowDecimal(shop_info) ? "0,0.[000]" : "0,0";
  return numeral(numeral(input).format(format)).value();
};

export const removeDiacritics = (str, toUpperCase = false) => {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
  str = str.replace(/\u02C6|\u0306|\u031B/g, "");
  return toUpperCase ? str.toUpperCase() : str;
};

export const isAllowDecimal = (shop_info) => {
  return shop_info.numberic_mode === 2;
};

export const showKeyboardShortcut = (location) => {
  return location.pathname === "/" || location.pathname === "/cart";
};

//validate Email
export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  if (!email) return true;
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

//Validate Phone
export function validatePhone(phoneNo) {
  var phoneno = /^\d{11}$/;
  if (phoneNo.value.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}

export const isValidPhoneNumber = (phone) => {
  if (isNil(phone)) return false;
  let patternPhone = /^\d{8,15}$/;
  if (patternPhone.test(phone)) return true;
  return false;
};

export const isValidTaxCode = (taxCode) => {
  const regex = /^[\d-]{10,50}$/;
  // `Vui lòng nhập chuỗi có độ dài từ 10 đến 50 ký tự chỉ bao gồm chữ số và dấu '-'`,
  return regex.test(taxCode);
};

export function checkImageResize(file) {
  if (
    file.type &&
    (file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg")
  )
    return true;
  return false;
}

export function resizeImage(file, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;
      maxWidth = maxWidth || 2048;
      maxHeight = maxHeight || 2048;
      if (width <= maxWidth && height <= maxHeight) {
        resolve(file);
      }

      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      let canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      let context = canvas.getContext("2d");

      context.drawImage(image, 0, 0, newWidth, newHeight);

      canvas.toBlob(resolve, file.type);
    };
    image.onerror = reject;
  });
}

export function blobToFile(theBlob, fileName) {
  return new File([theBlob], fileName, { lastModified: Date.now() });
}

export async function getFile(files, maxWidth, maxHeight) {
  var promises = files.map(async (image, index) => {
    return new Promise((resolve, reject) => {
      if (checkImageResize(image)) {
        resizeImage(image, maxWidth, maxHeight).then((blob) => {
          resolve(blobToFile(blob, image.name));
        });
      } else {
        resolve(image);
      }
    });
  });
  return Promise.all(promises).then((result) => {
    return result;
  });
}

export function convertViToEn(str, toUpperCase = false) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
  str = str.replace(/\u02C6|\u0306|\u031B/g, "");

  return toUpperCase ? str.toUpperCase() : str;
}
export function getCustomerName(firstName, lastName, phone, email) {
  let first_name = firstName ? firstName.trim() : "";
  let last_name = lastName ? lastName.trim() : "";
  let full_name = (first_name + " " + last_name).trim();
  if (full_name) return full_name;
  if (phone && phone.trim()) return phone.trim();
  if (email) return email.trim();
}

export function isHTMLString(str) {
  const htmlRegex = /<[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
}

export const removeHTMLTags = (str) => {
  return str.replace(/<[^>]+>/g, "");
};
export const getBrowserInfo = () => {
  const keys = [
    "appCodeName",
    "appName",
    "appVersion",
    "cookieEnabled",
    "onLine",
    "platform",
    "userAgent",
    "plugins",
  ];
  const info = {};
  for (let prop in navigator) {
    if (keys.includes(prop)) {
      info[prop] = navigator[prop];
    }
  }
  return info;
};

export const diffFrom = (dateTo, dateFrom = new Date()) => {
  const today = dateFrom ? new Date(dateFrom).getTime() : new Date().getTime();
  let lastDay = new Date(dateTo);
  lastDay.setHours(23, 59, 59, 999);
  const last_day = lastDay.getTime();
  return Math.floor((last_day - today) / (1000 * 3600 * 24));
};

export const getZoomTarget = (windowWidth) => {
  if (windowWidth < 1024) {
    return 0.75;
  } else if (windowWidth < 1140) {
    return 0.8;
  } else if (windowWidth < 1280) {
    return 0.85;
  } else if (windowWidth < 1360) {
    return 0.95;
  } else if (windowWidth < 1600) {
    return 1;
  } else if (windowWidth < 1900) {
    return 1.1;
  } else {
    return 1.2;
  }
};

export const formatDate = (date, format = "DD/MM/YYYY") =>
  moment(date).format(format);
