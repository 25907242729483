import { Tooltip } from "@haravan/react-components";
import { Assets } from "assets";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { useDailyReportResultData } from "hooks/useReportData";
import { getZTime } from "infrastructures/datetime-helpers";
import { DateTimeShowType } from "infrastructures/format-helpers";
import { cashFlowObjectType } from "infrastructures/models";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CurrencyShow from "component-v2/currency-show";
import {
  LayoutCard,
  LayoutCardItem,
  LayoutCardItemStack,
  LayoutCardItemStackWrapper,
} from "screens-v2/layouts/layouts/omni-layout-card";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";
import DateTimeShow from "component-v2/datetime-show";
import { useApp } from "contexts/app";

const TABS = [
  { code: "all", type: null, title: "Tất cả" },
  { code: "receipt", type: "cash_receipt", title: "Thu" },
  { code: "payment", type: "cash_payment", title: "Chi" },
];
const PAGING_LIMIT = 20;
export default function DailyReportResult({
  dateRange,
  user,
  location,
  summary,
}) {
  const { shop_info } = useApp();
  const intl = useIntl();
  // eslint-disable-next-line no-unused-vars
  const [tab, setTab] = useState(TABS[0]);
  const [paging, setPaging] = useState({ after: null, before: null });
  const [results, setResults] = useState([]);
  const [hasData, setHasData] = useState(false);
  const [pager, setPager] = useState({ hasNext: false, hasPrev: false });
  const [request, setRequest] = useState(null);

  const { data, isValidating: loading } = useDailyReportResultData(
    request?.startDate,
    request?.endDate,
    request?.locationId,
    request?.userId,
    request?.accountId,
    request?.tabType,
    PAGING_LIMIT,
    paging?.after,
    paging?.before,
  );

  useEffect(() => {
    let request = {
      startDate: getZTime(dateRange?.startDate),
      endDate: getZTime(dateRange?.endDate),
      locationId: location?.id,
      userId: user?.id,
      accountId: summary?.accountId,
      tabType: tab.type,
    };
    setRequest(request);
    setPaging({ after: null, before: null });
  }, [dateRange, user, location, summary, tab]);

  useEffect(() => {
    let hasNext = false;
    let hasPrev = false;
    let lastId = 0;
    let firstId = 0;
    let results = [];
    if (data?.data?.data?.length) {
      results = data.data.data;
      hasNext = data.data.hasAfter;
      hasPrev = data.data.hasBefore;
      firstId = results[0].id;
      lastId = results[results.length - 1].id;
    }
    setPager({ hasNext, hasPrev, firstId, lastId });
    setResults(results);
    setHasData(results && results.length);
  }, [data]);

  return (
    <LayoutCard className="daily-report-results">
      <LayoutCardItem>
        <LayoutCardItemStackWrapper>
          <LayoutCardItemStack fill className="flex-column align-items-start">
            <p className="font-size-12" style={{ color: "#4B5563" }}>
              {intl.formatMessage({
                id: `reports.daily-report-table.Phương thức thanh toán`,
                defaultMessage: "Phương thức thanh toán",
              })}
            </p>
            <span className="m-0 font-size-16 font-weight-semibold text-break">
              {summary?.accountName}
            </span>
          </LayoutCardItemStack>
          <LayoutCardItemStack className="text-right">
            <div className="sale-mode large">
              {TABS.map((_tab, i) => {
                let title = intl.formatMessage({
                  id: `reports.daily-report.${_tab.title}`,
                  defaultMessage: _tab.title,
                });
                return (
                  <React.Fragment key={"rprts-rslts-tb-" + _tab.code}>
                    <div
                      key={"rprts-rslts-tb-" + _tab.code}
                      className={`${
                        _tab.type === tab.type ? "sale-mode-active" : ""
                      }`}
                      onClick={() => setTab(_tab)}
                    >
                      {title}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </LayoutCardItemStack>
        </LayoutCardItemStackWrapper>
      </LayoutCardItem>
      <LayoutCardItem>
        {loading ? <LoadingCircle overlay /> : null}
        <table className="daily-report-results-table">
          <thead>
            <tr>
              <th>
                {intl.formatMessage({
                  id: "reports.daily-report.Giao dịch",
                  defaultMessage: "Giao dịch",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "reports.daily-report.Thời gian",
                  defaultMessage: "Thời gian",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "reports.daily-report.Phương thức",
                  defaultMessage: "Phương thức",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "reports.daily-report.Lý do",
                  defaultMessage: "Lý do",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "reports.daily-report.Đối tượng",
                  defaultMessage: "Đối tượng",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "reports.daily-report.Chứng từ",
                  defaultMessage: "Chứng từ",
                })}
              </th>
              <th className="text-right">
                {intl.formatMessage({
                  id: "reports.daily-report.Thu / Chi",
                  defaultMessage: "Thu / Chi",
                })}
              </th>
            </tr>
          </thead>
          <tbody className="position-relative">
            {results.map((result) => {
              return (
                <tr key={"rprts-rslts-" + result.id}>
                  <td className="text-nowrap">
                    <a
                      className="text-decoration-none"
                      href={`${shop_info.seller_domain}/accounting/transactions/${result.id}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {result.casNumber}
                    </a>
                    {result.refNumber ? (
                      <Tooltip
                        className="ml-2"
                        overlayStyle={{ zIndex: "10002" }}
                        placement="right"
                        title={"#" + result.refNumber}
                      >
                        <p className="icon-secondary d-inline">
                          {Assets.icons_svg.search_product_icon}
                        </p>
                      </Tooltip>
                    ) : null}
                  </td>
                  <td>
                    <DateTimeShow
                      date={result.tranDate}
                      type={DateTimeShowType.custom}
                      format="HH:mm"
                    />
                  </td>
                  <td>
                    <p className="text-break">
                      {result.accountTypeId === 1
                        ? intl.formatMessage({
                            id: `payment-vouchers.${result.accountName}`,
                            defaultMessage: result.accountName,
                          })
                        : result.accountName}
                    </p>
                    {result.bankCode ? (
                      <p className="content-tertiary">
                        {result.bankCode} - {result.bankAccNumber}
                      </p>
                    ) : null}
                  </td>
                  <td>
                    {intl.formatMessage({
                      id: `payment-vouchers.${result.reasonName}`,
                      defaultMessage: result.reasonName,
                    })}
                  </td>
                  <td>
                    <p>{result.objectName}</p>
                    <p className="content-secondary">
                      {intl.formatMessage({
                        id: `payment-vouchers.${
                          cashFlowObjectType[result.objectTypeId]?.title
                        }`,
                        defaultMessage:
                          cashFlowObjectType[result.objectTypeId]?.title,
                      })}
                    </p>
                  </td>
                  <td className="text-nowrap">
                    {result.toRefId ? (
                      <a
                        className="text-decoration-none"
                        href={`/orders/${result.toRefId}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {result.toRefNumber || "-"}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right">
                    <CurrencyShow value={result.amount} colored />
                  </td>
                </tr>
              );
            })}
            {hasData || loading ? null : (
              <tr>
                <td
                  colSpan={6}
                  className="text-center content-secondary no-data-col"
                >
                  {intl.formatMessage({
                    id: "reports.daily-report.Không có dữ liệu",
                    defaultMessage: "Không có dữ liệu",
                  })}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </LayoutCardItem>
      {hasData ? (
        <div className="text-right mb-4 mr-4">
          <Button
            light
            size="md"
            disabled={!pager.hasPrev}
            className="ml-2"
            onClick={() => {
              setPaging({ before: pager.firstId, after: null });
            }}
          >
            <Icon name="keyboard_arrow_up" className="rotate--90" />
          </Button>
          <Button
            light
            size="md"
            disabled={!pager.hasNext}
            className="ml-3"
            onClick={() => {
              setPaging({ before: null, after: pager.lastId });
            }}
          >
            <Icon name="keyboard_arrow_up" className="rotate-90" />
          </Button>
        </div>
      ) : null}
    </LayoutCard>
  );
}
