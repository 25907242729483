import React, { Fragment, memo, useState } from "react";
import PropTypes from "prop-types";
import { toPaymentDisplayName } from "infrastructures/utils";
import Button from "component-v2/buttons";
import {
  TemplateType,
  printOrder,
} from "screens-v2/orders/components/print-template";
import { Loading } from "@haravan/react-components";
import "../../../orders/components/index.css";
import { useApp } from "contexts/app";
import { useCartContext } from "../../index";
import { handlePrintData } from "screens-v2/carts-v2/cart-helpers.service";
import { postCustomerLoyaltyPoint } from "api/customer-repository";
import { concatString } from "screens-v2/carts-v2/utils";
import { SYSTEM_GUEST_EMAIL } from "infrastructures/utils";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useIntl } from "react-intl";
function PrintButtonTempBill({
  isCod,
  isShowTitleButton = false,
  size = "md",
  hasTitle = false,
  placementTooltip,
  taxData,
  disabled,
}) {
  const appContext = useApp();
  const [isLoading, setLoading] = useState(false);
  const { current_shopping_cart, salesMan } = useCartContext();
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  async function postPoint(loyaltyId, totalMoney) {
    if (loyaltyId) {
      const result = await postCustomerLoyaltyPoint(
        appContext.callAPI,
        loyaltyId,
        totalMoney,
      );
      return result;
    }
    return null;
  }
  const createOrderData = async () => {
    const location = appContext.current_location;
    const orderLines = current_shopping_cart?.items;
    const summaries = current_shopping_cart?.summaries;
    if (orderLines?.length > 200) {
      showGlobalToast(
        "error",
        intl.formatMessage(
          {
            id: `notifications.Số lượng sản phẩm quá lớn: {a}/200 sản phẩm`,
            defaultMessage: "Số lượng sản phẩm quá lớn: {a}/200 sản phẩm",
          },
          { a: orderLines?.length },
        ),
      );
      return;
    }
    const currentOrderLines = orderLines.map((order) => {
      return {
        ...order,
        discountAmount: +Number(
          (order.originalPrice - order.price) * order.quantity -
            order.discountPromotion * order.quantity,
        ).toFixed(4),
        lots: !isCod && !current_shopping_cart?.isShipping ? order.lots : null,
      };
    });

    //Redeem model
    let redeemModel = {};
    if (
      appContext.shop_loyalty?.enabled &&
      current_shopping_cart?.loyalty?.redeemConfig &&
      current_shopping_cart?.loyalty_program
    ) {
      const { redeemConfig } = current_shopping_cart?.loyalty;
      const loyalty_program = current_shopping_cart.loyalty_program;
      redeemModel = {
        redeemServiceName: redeemConfig.name,
        redeemPayloadId: redeemConfig.id,
        redeemDiscountType: loyalty_program.discount_type || 0,
        redeemDiscount: loyalty_program.discount || 0,
        redeemMaxPerOrder: loyalty_program.max_per_order || 0,
        redeemUsedAmount: loyalty_program.used_amount,
        redeemAmount: loyalty_program.amount,
      };
    }

    const totalDiscount = current_shopping_cart?.discount
      ? current_shopping_cart?.discount
      : 0;

    const billingModel = {
      billingCompany:
        current_shopping_cart.taxData?.billingCompany ||
        concatString({
          value: [
            current_shopping_cart?.customer?.firstName,
            current_shopping_cart?.customer?.lastName,
          ],
        }),
      billingTaxCode: current_shopping_cart.taxData?.billingTaxCode || "",
      billingEmail: current_shopping_cart.taxData?.billingEmail || "",
      billingAddress: current_shopping_cart.taxData?.billingAddress || "",
      billingCountry:
        current_shopping_cart.taxData?.address?.billingCountry || "",
      billingCountryName:
        current_shopping_cart.taxData?.address?.billingCountryName || "",
      billingProvince:
        current_shopping_cart.taxData?.address?.billingProvince || "",
      billingProvinceName:
        current_shopping_cart.taxData?.address?.billingProvinceName || "",
      billingDistrict:
        current_shopping_cart.taxData?.address?.billingDistrict || "",
      billingDistrictName:
        current_shopping_cart.taxData?.address?.billingDistrictName || "",
      billingWard: current_shopping_cart.taxData?.address?.billingWard || "",
      billingWardName:
        current_shopping_cart.taxData?.address?.billingWardName || "",
      billingCity: current_shopping_cart.taxData?.address?.city || "",
      billingZipCode: current_shopping_cart.taxData?.address?.zipCode || "",
    };

    let orderModel = {
      ...redeemModel,
      ...billingModel,
      userId: current_shopping_cart?.salesMan?.id,
      locationId: location.id,
      locationName: location.name,
      assignedLocationId: location.id,
      assignedLocationName: location.name,
      orderShippingFee: summaries.shipping_fee,
      orderNotes: current_shopping_cart.orderNote,
      note_Attributes: current_shopping_cart.noteAttributes,
      orderProducts: currentOrderLines,
      ...current_shopping_cart?.orderDiscountSelected,
      discountAmount: totalDiscount,
      isCodGateway: isCod,
      totalDiscounts: totalDiscount,
      sourceName: "pos",
      source: "pos",
      send_webhooks: true,
      fulFillMentStatusId: !isCod && !current_shopping_cart?.isShipping ? 1 : 0,
      fulfilledDate: null,
    };

    const orderCustomer = { ...current_shopping_cart?.customer };
    let customerAddress = null;
    if (orderCustomer) {
      if (orderCustomer?.customerAddress?.length) {
        customerAddress = orderCustomer.customerAddress.find(
          (address) => address.isDefault,
        );
      }
      orderModel.customerId = orderCustomer.id || orderCustomer.key;
      orderModel.customerName = `${concatString({
        value: [orderCustomer.firstName, orderCustomer.lastName],
      })}`;
      orderModel.customerEmail = orderCustomer.email || "";
      orderModel.shippingFirstName = orderCustomer.firstName || "";
      orderModel.shippingLastName = orderCustomer.lastName || "";
      orderModel.shippingPhone = orderCustomer.phone || "";
      if (
        !orderModel.shippingFirstName &&
        !orderModel.shippingLastName &&
        orderModel.customerId &&
        orderModel.customerEmail !== SYSTEM_GUEST_EMAIL
      ) {
        orderModel.shippingFirstName = "--";
      }

      if (customerAddress) {
        orderModel.shippingAddress =
          customerAddress.address1 || customerAddress.address2 || "";
        orderModel.shippingProvinceCode = customerAddress.provinceCode || "";
        orderModel.shippingDistrictCode = customerAddress.districtCode || "";
        orderModel.shippingWardCode = customerAddress.wardCode || "";
        orderModel.shippingCountryCode = customerAddress.countryCode || "";
        orderModel.shippingDistrict = customerAddress.districtId || "";
        orderModel.shippingProvince = customerAddress.provinceId || "";
        orderModel.shippingCountry = customerAddress.countryId || "";
        orderModel.shippingWard = customerAddress.wardId || "";
      }
    }
    if (!orderModel.gateway) {
      orderModel.gateway = "Tiền mặt";
      orderModel.orderTransactions = [];
    }
    let orderPrintData = null;
    let dataMember = null;
    if (
      appContext.shop_loyalty?.enabled &&
      current_shopping_cart?.loyalty?.profile?.current_membership_level !==
        "Chưa là thành viên"
    ) {
      dataMember = await postPoint(
        current_shopping_cart?.loyalty?.profile?.customer_profile_id,
        current_shopping_cart?.summaries?.total_payment,
      );
    }
    orderPrintData = await handlePrintData({
      dataMember,
      data: { orderId: "", orderNumber: "" },
      model: orderModel,
      salesMan: current_shopping_cart?.salesMan,
      currentCart: current_shopping_cart,
      appContext,
      currentSession: current_shopping_cart,
    });
    return orderPrintData;
  };

  const callPrintOrder = async (
    dataMember,
    order,
    isOrderPrintBill,
    refundOrderModel,
    isNewOrder,
    totalRefund,
    locationRefund,
  ) => {
    const templateType =
      appContext?.shop_setting?.printSettings?.printType === "html"
        ? TemplateType.TEMP_BILL_HTML
        : TemplateType.TEMP_BILL;
    order &&
      order.transactions.forEach((trans) => {
        trans.gateway = toPaymentDisplayName(trans.gateway);
        trans.paymentMethodName = toPaymentDisplayName(trans.paymentMethodName);
      });
    refundOrderModel &&
      refundOrderModel.orderModel.transactions.forEach((trans) => {
        trans.gateway = toPaymentDisplayName(trans.gateway);
        trans.paymentMethodName = toPaymentDisplayName(trans.paymentMethodName);
      });
    let template = await printOrder(
      appContext,
      order,
      null,
      refundOrderModel,
      isNewOrder,
      totalRefund,
      locationRefund,
      dataMember,
      null,
      isOrderPrintBill,
      null,
      templateType,
    );
    var contentWindow = document.getElementById("print-order");
    let contentPopup;
    if (contentWindow) {
      contentPopup = contentWindow.contentWindow.document;
      contentPopup.open();
      contentPopup.write(template);
      setTimeout(function () {
        contentPopup.close();
      }, 200);
    }
    setLoading(false);
  };

  const clickPrintOrder = async () => {
    setLoading(true);
    let orderData = await createOrderData();
    try {
      if (orderData) {
        let orderPrint = {
          ...orderData,
          orderEInvoice: null,
          isVatFail: taxData?.eInvoice && appContext.shop_info.taxable,
          noteAttributes: orderData?.noteAttributes || [],
          transactions: orderData?.transactions || [],
          totalTaxIncluded: current_shopping_cart?.vatValue?.sumIncludedVat,
          totalTaxNotIncluded:
            current_shopping_cart?.vatValue?.sumNotIncludedVat,
        };
        await callPrintOrder(null, orderPrint, null);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Button size={size} status="default" light className={"w-100"}>
          <Loading className="print-button--loading-btn" size="pico" />
        </Button>
      ) : (
        <Button
          light
          size={size}
          icon={"print"}
          title={hasTitle ? "In tạm tính" : ""}
          tooltipTitle={hasTitle ? "" : "In tạm tính"}
          placementTooltip={placementTooltip}
          status={isShowTitleButton ? "primary" : "default"}
          plain={!isShowTitleButton}
          onClick={() => clickPrintOrder()}
          isBtnLoading={true}
          disabled={disabled}
          className={"w-100"}
        />
      )}
      <iframe title="print" id="print-order" style={{ display: "none" }} />
    </Fragment>
  );
}

export default memo(PrintButtonTempBill);

PrintButtonTempBill.propTypes = {
  orderId: PropTypes.any,
  orderData: PropTypes.any,
  isBtnLoading: PropTypes.any,
  isAutoPrint: PropTypes.any,
};
