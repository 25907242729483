import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import { forwardRef, useMemo, useState } from "react";
import { DiscountAllocationType, useRefundContext } from "..";
import { ModalDiscountOrder } from "./components/modalDiscountOrder";
import { ModalEditPriceRefund } from "./components/modalEditPriceRefund";
import { ModalEditShippingFee } from "./components/modalEditShippingFee";
import "./styles.css";
import { PaymentMethods } from "./Payments/paymentMethods";
import CouponBadge from "screens-v2/carts-v2/RightCart/components/couponBadge";

export const PaymentSummary = forwardRef(
  (
    { setShowPayment, resetCart, refundOrder, totalMoneyPaidByCustomer },
    ref,
  ) => {
    const { shop_setting, shop_info } = useApp();
    const [editShippingFee, setEditShippingFee] = useState(false);
    const [isDiscountOrder, setIsDiscountOrder] = useState(false);
    const [isTotalPriceRefund, setIsTotalPriceRefund] = useState(false);
    const [showMoreVat, setShowMoreVat] = useState(true);
    const { current_shopping_cart, current_refund, finalPrice } =
      useRefundContext();

    const orderDiscounts = current_shopping_cart?.discountAllocations?.filter(
      (d) =>
        d.type === DiscountAllocationType.MANUAL_ORDER ||
        d.type === DiscountAllocationType.ORDER ||
        d.type === DiscountAllocationType.SHIPPING,
    );
    const orderDiscountAmount = useMemo(() => {
      if (orderDiscounts?.length) {
        return orderDiscounts?.reduce((sum, cur) => (sum += cur.amount), 0);
      }
      return 0;
    }, [orderDiscounts]);
    if (!current_shopping_cart?.summaries) {
      return null;
    }
    const { summaries } = current_shopping_cart;

    let discountValue = "";
    let newDiscountCode = "";
    let isUseCouponCode = "";
    if (current_shopping_cart?.orderDiscountSelected) {
      const { discountTypeName, discountPercent, discountCode } =
        current_shopping_cart?.orderDiscountSelected;
      if (current_shopping_cart?.loyalty?.discountCouponLoyalty?.length) {
        const { discountCouponLoyalty } = current_shopping_cart?.loyalty;
        const codeDiscount4 = discountCouponLoyalty.slice(
          -3,
          discountCouponLoyalty.length,
        );
        newDiscountCode = "********" + codeDiscount4;
        discountValue =
          discountCouponLoyalty === "" ? null : `LOY${newDiscountCode}`;
      } else {
        isUseCouponCode =
          current_shopping_cart?.orderDiscountSelected?.isUseCouponCode;
        discountValue =
          discountTypeName === "percent"
            ? `${discountPercent}%`
            : discountTypeName === "coupon"
              ? discountCode
              : "";
      }
    }
    //Tổng tiền tạm tính
    function priceTempOrderRefund() {
      if (current_shopping_cart?.lineItems?.length) {
        let amount =
          current_shopping_cart?.summaries?.subtotal -
          (current_refund.priceUserRefund + current_refund.refundRedeemAmount);
        if (amount < 0 && shop_setting.notAllowRefundIfLowerPrice) {
          amount = 0;
        }
        return amount;
      } else {
        return -current_refund?.priceUserRefund;
      }
    }
    let priceTempOrderRefundLabel = "";
    if (priceTempOrderRefund() > 0) {
      priceTempOrderRefundLabel = "Tạm tính khách trả";
    } else if (priceTempOrderRefund() <= 0) {
      priceTempOrderRefundLabel = "Tạm tính trả khách";
    }

    const renderVatCondition = (vatValue) => {
      if (vatValue.vatSum === 0) return null;
      let content = null;

      // trường hợp cả phí vận chuyển && phí mặc định đều có
      if (vatValue.sumNotIncludedVat <= 0 && vatValue.sumIncludedVat > 0) {
        content = <span className="pl-2">(đã bao gồm trong giá)</span>;
        return content;
      } else if (
        vatValue.sumNotIncludedVat > 0 &&
        vatValue.sumIncludedVat <= 0
      ) {
        // content = <span className="l-section border-0">(giá chưa bao gồm thuế)</span>;
        return content;
      } else {
        content = (
          <div className="border-0 px-0 pb-0">
            {!!vatValue.sumIncludedVat && vatValue.sumIncludedVat > 0 && (
              <div className="d-flex flex-row justify-content-between my-2 font-size-12 text-secondary ml-4">
                <div>
                  <span>Thuế đã bao gồm trong giá</span>
                </div>
                <div>
                  {toFormattedCurrency(vatValue.sumIncludedVat)}
                  {/* <CurrencyShow value={toFormattedCurrency(vatValue.sumIncludedVat)} /> */}
                </div>
              </div>
            )}
            {!!vatValue.sumNotIncludedVat && vatValue.sumNotIncludedVat > 0 && (
              <div className="d-flex flex-row justify-content-between my-2 font-size-12 text-secondary ml-4">
                <div>
                  <span>Thuế phải trả</span>
                </div>
                <div>
                  {toFormattedCurrency(vatValue.sumNotIncludedVat)}
                  {/* <CurrencyShow value={toFormattedCurrency(vatValue.sumNotIncludedVat)} /> */}
                </div>
              </div>
            )}
          </div>
        );
      }

      return content;
    };

    const vatRender = () => {
      // if (!shop_info.taxable || !current_refund?.eInvoice || current_refund?.eInvoice?.length <= 0) return null;
      if (!shop_info.taxable) return null;

      let vatValue = current_shopping_cart.vatValue;

      const isVatSameCondition =
        vatValue.sumIncludedVat > 0 && vatValue.sumNotIncludedVat > 0;
      return (
        <>
          <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
            <div className="d-flex flex-row">
              <div className="d-flex flex-row">
                <p className="">VAT</p>
                {isVatSameCondition && (
                  <span
                    onClick={() => {
                      setShowMoreVat((prev) => !prev);
                    }}
                    className="ml-3 cursor-pointer"
                  >
                    {showMoreVat ? (
                      <Icon Icon name="keyboard_arrow_up"></Icon>
                    ) : (
                      <Icon name="keyboard_arrow_down"></Icon>
                    )}
                  </span>
                )}
              </div>
              {/* <p>{toFormattedCurrency(summaries.subtotal)}</p> */}
            </div>
            <span className="refund-items-summary-value">
              {current_shopping_cart?.lineItems?.length > 0
                ? toFormattedCurrency(vatValue.vatSum)
                : 0}
            </span>
          </div>
          {current_shopping_cart?.lineItems?.length > 0 &&
          showMoreVat &&
          (isVatSameCondition || vatValue.vatSum > 0)
            ? renderVatCondition(vatValue)
            : null}
        </>
      );
    };

    return (
      <div className="" ref={ref}>
        <div className="pr-2">
          {current_shopping_cart?.lineItems?.length ? (
            <>
              <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
                <p
                  className={`${
                    current_shopping_cart?.lineItems?.length
                      ? "content-accent "
                      : "content-secondary"
                  } cursor-pointer`}
                  onClick={() => {
                    current_shopping_cart?.lineItems?.length &&
                      setIsTotalPriceRefund(true);
                  }}
                >
                  Tổng tiền hoàn trả
                </p>
                <p>
                  {toFormattedCurrency(
                    Math.abs(current_refund?.priceUserRefund),
                  )}
                </p>
              </div>
              <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
                <p>Tiền hàng mua mới</p>
                <p>
                  {toFormattedCurrency(
                    current_shopping_cart?.summaries?.subtotal,
                  )}
                </p>
              </div>
            </>
          ) : null}
          <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
            <p>{priceTempOrderRefundLabel}</p>
            <p>{toFormattedCurrency(Math.abs(priceTempOrderRefund()))}</p>
          </div>

          {shop_setting.shippingPriceEnable && (
            <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
              <p
                className={`${
                  current_shopping_cart?.lineItems?.length
                    ? "content-accent "
                    : "content-secondary"
                }  cursor-pointer`}
                onClick={() => {
                  current_shopping_cart?.lineItems?.length &&
                    setEditShippingFee(true);
                }}
              >
                Phí vận chuyển
              </p>
              <p>{toFormattedCurrency(summaries.shipping_fee)}</p>
            </div>
          )}

          <div className="l-pt-4 l-pb-4 d-flex flex-column justify-content-between">
            <p
              className={`d-flex ${
                current_shopping_cart?.lineItems?.length
                  ? "content-accent"
                  : "content-secondary"
              } cursor-pointer`}
              onClick={() => {
                current_shopping_cart?.lineItems?.length &&
                  setIsDiscountOrder(true);
              }}
            >
              Khuyến mãi{" "}
            </p>
            <div className="d-inline-flex flex-wrap">
              {!!current_shopping_cart?.discountAllocations?.length
                ? current_shopping_cart?.discountAllocations
                    ?.filter(
                      (d) =>
                        d.type !== DiscountAllocationType.MANUAL_ORDER &&
                        d.type !== DiscountAllocationType.MEMBERSHIP &&
                        d.type !== DiscountAllocationType.REDEEM_POINT,
                    )
                    ?.map((coupon) => {
                      return (
                        <CouponBadge
                          key={coupon.name}
                          className={"l-pr-8 l-mr-8 mt-2"}
                          couponLabel={coupon.name}
                          iconBackground={
                            coupon.type === DiscountAllocationType.SHIPPING
                              ? "var(--teal400)"
                              : "var(--orange300)"
                          }
                          badgeIcon={
                            coupon.type === DiscountAllocationType.SHIPPING
                              ? "local_shipping"
                              : "card_giftcard"
                          }
                        />
                      );
                    })
                : ""}
            </div>
          </div>
          {orderDiscountAmount !== 0 && (
            <div className="l-pt-4 l-pb-4 d-flex justify-content-between">
              <p className={"cursor-pointer d-flex"}>Giảm giá đơn hàng</p>
              <p>{toFormattedCurrency(orderDiscountAmount || 0)}</p>
            </div>
          )}
          {/* Loyalty */}
          {current_refund?.refundRedeemAmount ? (
            <div className="l-pt-8 l-pb-4 d-flex justify-content-between">
              <p>Tiền khuyến mãi KHTT</p>
              <p>
                {toFormattedCurrency(current_refund?.refundRedeemAmount || 0)}
              </p>
            </div>
          ) : null}
          {vatRender()}
          {current_refund?.refund_quantity_total > 0 ? (
            <div className="l-pt-8 l-pb-8 d-flex justify-content-between">
              <p className={`font-weight-semibold`}>
                {finalPrice() > 0 ||
                (finalPrice() === 0 &&
                  !current_shopping_cart?.lineItems?.length)
                  ? "Cần hoàn trả khách"
                  : "Khách cần trả"}
              </p>
              <p className={`font-weight-semibold`}>
                {toFormattedCurrency(Math.abs(finalPrice()))}
              </p>
            </div>
          ) : null}
          <hr className="my-0 border-dotted" />
          <PaymentMethods totalMoneyPaidByCustomer={totalMoneyPaidByCustomer} />
        </div>
        <ModalEditPriceRefund
          show={isTotalPriceRefund}
          setShow={setIsTotalPriceRefund}
        />
        <ModalEditShippingFee
          show={editShippingFee}
          setShow={setEditShippingFee}
        />
        <ModalDiscountOrder
          show={isDiscountOrder}
          setShow={setIsDiscountOrder}
          resetCart={resetCart}
        />
      </div>
    );
  },
);
