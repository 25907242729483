import React from "react";
import "./style.css";
import { InputRadio } from "@haravan/react-components";
import { useApp } from "contexts/app";

const LocationItem = ({ location, showGlobalToast }) => {
  const { setCurrentLocation, current_location } = useApp();

  const addressLocation = React.useMemo(() => {
    return (
      (location.street ? location.street + " - " : "") +
      (location.wardName ? location.wardName + " - " : "") +
      (location.districtName ? location.districtName + " - " : "") +
      (location.provinceName ? location.provinceName : "")
    );
  }, [location]);

  const handleUpdateLocation = () => {
    setCurrentLocation(location.id);
    showGlobalToast(
      "default",
      "Địa chỉ đã cập nhật thành công sang " + location.name,
    );
  };

  if (location.isUnavailableQty || !location.holdStock) {
    return null;
  }

  return (
    <li
      onClick={() => {
        handleUpdateLocation();
      }}
      className="location--item cursor-pointer"
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <div className="font-weight-medium">
            {location.name || location.location_name}
          </div>
          <div className="text-secondary l-mt-4">{addressLocation}</div>
        </div>
        <span className={`align-self-center`}>
          <InputRadio
            className={`${
              current_location?.id === location.id
                ? "border-0 location-item--radio-button-checked"
                : "location-item--radio-button-uncheck"
            }`}
            checked={current_location?.id === location.id}
            id="checkbox"
          ></InputRadio>
        </span>
      </div>
    </li>
  );
};

export { LocationItem };
