import React from "react";
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Icon from "component-v2/icons/icons";
import CurrencyShow from "component-v2/currency-show";
import NumberShow from "component-v2/number-show";
import { Assets } from 'assets';
import { Tooltip, ImageResize } from '@haravan/react-components';
import Button from "component-v2/buttons";
import { ImageCustom } from "component-v2/ImageCustom";
import { UnitSelection2 } from "component-v2/UnitSelection/index_backup";

export const ProductSearchItem = (props) => {
  const intl = useIntl();
  const { isVinamilk, product, index, isShowUnit } = props;
  const id = `search-product--item-${index}`;
  const logoChange = isVinamilk ? Assets.icons_svg.search_product_icon_vinamilk : Assets.icons_svg.search_product_icon;
  const ratio = product.selectedUnit ? (product.selectedUnit.ratio || 1) : 1;

  const handleClickProduct = () => {
    props.onClick(product);
  };


  const handleChangeVariantUnit = (unit) => {
    let _variant = { ...product };
    _variant.selectedUnit = unit;
    _variant.variantUnitId = unit.id;
    _variant.price = unit.price;
    _variant.sku = unit.sku;
    _variant.barcode = unit.barcode;
    props.onClick(_variant);
  };

  return (
    // <Button light id={id} onClick={handleClickProduct} className="pointer product-info--search-item list-group-item ">
    <Button light id={id} onClick={handleClickProduct} noWrap={false} className="pointer product-info--search-item list-group-item ">
      <div className="row col-12">
        <div className='col-5 pr-0 pl-2'>

          <div className="prod-flex-2-cols  prod-p-08 "  >
            <div className="prod-img-variant" >
              <ImageCustom src={product?.productImgUrl} alt="image3" size='medium'/>
            </div>
            <div className="col prod-flex-2-cols cart-product-item--info">
              <p className='product-name font-weight-medium prod-sub-title-item'>{product.productName}</p>
              <p className='text-secondary font-size-12 prod-sub-title-item'>{product.variantTitle}</p>
              <p className='product-code font-size-12 text-secondary prod-sub-title-item'>{product.barcode}</p>
            </div>
          </div>
        </div>
        {
          isShowUnit && (
            <div className="col-2 p-0 d-flex align-items-center" >
              <div className='text-left' onClick={(e) => e.stopPropagation()}>
                <UnitSelection2 setMouseOverResult={props.setMouseOverResult} options={product.units} selectedOption={product.selectedUnit} onChange={(unit) => handleChangeVariantUnit(unit)} />
              </div>
            </div>
          )
        }
        <div className={`p-0 d-flex align-items-center justify-content-center ${isShowUnit ? 'col-2' : 'col-3'}`}>
          <div className='text-right'>
            <span className='ml-2'><CurrencyShow value={product.price} /></span>
          </div>
        </div>
        <div className={`p-0 d-flex align-items-center justify-content-end  ${isShowUnit ? 'col-2' : 'col-3'}`}>
          <div className="d-flex align-items-center justify-content-end">
            {!product.isTrackingInventory
              ? <span className='prod-tag pr-2 d-flex-center' ><Icon name="infinity" size="md" /></span>
              : <p className={`${product.quantity < 0 ? 'text-danger' : ''} `}><NumberShow value={product.quantity / ratio} /></p>
            }
            <p className="ml-2">{intl.formatMessage({ id: 'products.details.Khả dụng', defaultMessage: 'Khả dụng' })} </p>
          </div>
        </div>
        <div className="col-1 p-0 d-flex align-items-center justify-content-center">
          {!product.isTrackingInventory
            ? <span className='icon-w-14 icon-secondary pr-2 d-flex-center' >{Assets.icons_svg.search_product_icon_disabled}</span>
            : (
              <Tooltip placement="topRight" title={intl.formatMessage({ id: 'products.details.Xem chi tiết sản phẩm', defaultMessage: 'Xem chi tiết sản phẩm' })} overlayClassName="tooltip-custom">
                <Link to={`/products/${product.productId}/variants/${product.id}`} target="_blank" className='icon-w-14 pr-2 d-flex-center product-detail-icon icon-primary'>{logoChange}</Link>
              </Tooltip>
            )
          }
        </div>
      </div>
    </Button>
  );
};





ProductSearchItem.propTypes = {
  isFocused: PropTypes.bool,
  keyword: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

