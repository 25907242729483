import { useMemo, useState } from "react";
import { Switch } from "@haravan/react-components";
import { useIntl } from "react-intl";
import { updateSettingShift } from "api/settings-repository";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  LayoutCard,
  LayoutCardSection,
  LayoutCardHeader,
} from "screens-v2/layouts/layouts/omni-layout-card";
import { useApp } from "contexts/app";
import ShiftDisableSettingAlert from "./shiftSettingAlert";
import { Fea_ShiftManager } from "configs";

function ShiftSetting() {
  const intl = useIntl();
  const { shop_setting, refreshShopSetting, current_location } = useApp();
  const layout = useLayoutContext();
  const [showAlert, setShowAlert] = useState(false);
  const shiftValues = useMemo(() => {
    return {
      shiftEnable: shop_setting.shiftEnable,
      shiftCashTracking: shop_setting.shiftCashTracking,
      allowAssignShiftManager: shop_setting.allowAssignShiftManager,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop_setting]);
  const appContext = useApp();

  const onUpdateSetting = async (settingKey) => {
    const value = !shop_setting[settingKey];
    let model = {
      ...shiftValues,
      [settingKey]: value,
    };
    if (settingKey === "shiftEnable" && !value) {
      model = {
        ...model,
        shiftCashTracking: false,
        allowAssignShiftManager: false,
      };
    }
    const response = await updateSettingShift(
      appContext,
      model,
      current_location?.id,
    );
    if (response?.data) {
      await refreshShopSetting();
      layout.showGlobalToast(
        "default",
        "Cấu hình đã được cập nhật thành công!",
      );
    } else if (response.errors?.length) {
      if (
        response.errors?.[0] === "Không thể cập nhật cấu hình do có ca đang mở"
      ) {
        setShowAlert(true);
      } else {
        layout.showGlobalToast("error", response.errors?.[0]);
      }
    } else {
      layout.showGlobalToast(
        "error",
        "Cấu hình cập nhật không thành công. Vui lòng thử lại!",
      );
    }
  };

  return (
    <>
      <LayoutCard className="bg-transparent">
        <LayoutCardSection className="pt-0">
          <LayoutCardHeader title="Quản lý ca" />
          <p className="text-secondary mt-2">
            Cấu hình quản lý ca cho thiết bị
          </p>

          <div className="border-top mt-4">
            <p className="mt-4 text-secondary font-weight-medium">
              {intl.formatMessage({
                id: "settings.shifts.Quản lý ca",
                defaultMessage: "Quản lý ca",
              })}
            </p>
            <LayoutCard className="l-mt-8">
              <LayoutCardSection className="flex-row">
                <div className="flex-stretch">
                  <p className="text-default font-weight-medium">
                    {intl.formatMessage({
                      id: "settings.shifts.Mở chức năng quản lý ca",
                      defaultMessage: "Mở chức năng quản lý ca",
                    })}
                  </p>
                  <p className="text-secondary font-size-12">
                    {intl.formatMessage({
                      id: "settings.shifts.Theo dõi doanh thu cho mỗi ca",
                      defaultMessage: "Theo dõi doanh thu cho mỗi ca",
                    })}
                  </p>
                </div>
                <div className="col-auto text-right">
                  <Switch
                    onChange={() => onUpdateSetting("shiftEnable")}
                    checked={shiftValues.shiftEnable}
                  />
                </div>
              </LayoutCardSection>

              {Fea_ShiftManager(appContext.auth?.user?.orgid) &&
              shiftValues.shiftEnable ? (
                <LayoutCardSection className="flex-row">
                  <div className="flex-stretch">
                    <p className="text-default font-weight-medium">
                      {intl.formatMessage({
                        id: "settings.shifts.Chỉ định nhân viên quản lý ca",
                        defaultMessage: "Chỉ định nhân viên quản lý ca",
                      })}
                    </p>
                    <p className="text-secondary font-size-12">
                      {intl.formatMessage({
                        id: "settings.shifts.Cho phép chỉ định thêm một nhân viên giữ vai trò Quản lý của ca bán hàng",
                        defaultMessage:
                          "Cho phép chỉ định thêm một nhân viên giữ vai trò Quản lý của ca bán hàng",
                      })}
                    </p>
                  </div>
                  <div className="col-auto text-right">
                    <Switch
                      onChange={() =>
                        onUpdateSetting("allowAssignShiftManager")
                      }
                      checked={shiftValues.allowAssignShiftManager}
                    />
                  </div>
                </LayoutCardSection>
              ) : null}

              {shiftValues.shiftEnable ? (
                <LayoutCardSection className="flex-row">
                  <div className="flex-stretch">
                    <p className="text-default font-weight-medium">
                      {intl.formatMessage({
                        id: "settings.shifts.Theo dõi tiền mặt",
                        defaultMessage: "Theo dõi tiền mặt",
                      })}
                    </p>
                    <p className="text-secondary font-size-12">
                      {intl.formatMessage({
                        id: "settings.shifts.Ghi lại lượng tiền mặt trong mỗi ca làm việc",
                        defaultMessage:
                          "Ghi lại lượng tiền mặt trong mỗi ca làm việc",
                      })}
                    </p>
                  </div>
                  <div className="col-auto text-right">
                    <Switch
                      disabled={!shiftValues.shiftEnable ? true : false}
                      onChange={() => onUpdateSetting("shiftCashTracking")}
                      checked={shiftValues.shiftCashTracking}
                    />
                  </div>
                </LayoutCardSection>
              ) : null}
            </LayoutCard>
          </div>
        </LayoutCardSection>
        <ShiftDisableSettingAlert show={showAlert} setShow={setShowAlert} />
      </LayoutCard>
    </>
  );
}

export default ShiftSetting;
