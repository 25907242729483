export const CacheStorage = {
  Get(cacheTitle, store = "session") {
    if (typeof Storage !== undefined) {
      let result = null;
      if (store === "local") {
        result = localStorage.getItem(cacheTitle);
      }
      if (store === "session") {
        result = sessionStorage.getItem(cacheTitle);
      }
      if (result) {
        let data = JSON.parse(result);
        if (store === "local") {
          if (data.value == null || data.expire_in == null) {
            localStorage.removeItem(cacheTitle);
          } else {
            let now = new Date();
            if (now > data.expire_in) {
              localStorage.removeItem(cacheTitle);
            } else {
              return data.value;
            }
          }
        } else {
          return JSON.parse(result);
        }
      }
    }
    return null;
  },
  Set: (cacheTitle, object, store) => {
    if (typeof Storage !== undefined) {
      if (!object) return false;
      if (store === "local") {
        let now = new Date();
        let nextMonth = now.setMonth(now.getMonth() + 1);
        let objCache = { value: object, expire_in: nextMonth };
        localStorage.setItem(cacheTitle, JSON.stringify(objCache));
      }
      if (store === "session") {
        sessionStorage.setItem(cacheTitle, JSON.stringify(object));
      }

      return true;
    }
    return false;
  },
  Clear: (cacheTitle, store) => {
    if (typeof Storage !== undefined) {
      if (store === "local") {
        localStorage.removeItem(cacheTitle);
      }
      if (store === "session") {
        sessionStorage.removeItem(cacheTitle);
      }
      return true;
    }
    return false;
  },
  ClearAll: (store) => {
    if (typeof Storage !== undefined) {
      if (store === "local") {
        localStorage.clear();
      }
      if (store === "session") {
        sessionStorage.clear();
      }
      return true;
    }
    return false;
  },
};
