import React, { useEffect, useState } from "react";
import PaymentMethodReportChart from "./payment-method-report-chart";
import { toNumber } from "lodash";
import { getReportPaymentMethodDetail } from "../utils/utils";
import { useApp } from "contexts/app";

export default function PaymentMethodTab({
  globalLoading,
  dateRange,
  channel,
  staff,
  summaryPaymentRefund,
}) {
  const appContext = useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [table, setTable] = useState([]);

  useEffect(() => {
    if (globalLoading) setIsLoading(true);
    else
      onRefreshDataReport(
        dateRange.startDate,
        dateRange.endDate,
        channel,
        staff,
        summaryPaymentRefund,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLoading]);

  const onRefreshDataReport = async (
    startDate,
    endDate,
    channel,
    staff,
    summaryPaymentRefund,
  ) => {
    try {
      setIsLoading(true);
      const reportResult = await getReportPaymentMethodDetail(
        appContext,
        startDate,
        endDate,
        "",
        channel,
        staff,
        summaryPaymentRefund,
      );
      let series = [];
      reportResult.highcharts.series.forEach((data) => {
        let obj = {
          name: data.name,
          y: data.data.reduce((arr1, arr2) => {
            return toNumber(arr1) + toNumber(arr2);
          }),
          events: {
            legendItemClick: () => {
              let dataClick = false;
              for (let i = 0; i < data.data.length; i++) {
                if (data.data[i] !== 0) {
                  dataClick = true;
                  break;
                }
              }
              return dataClick;
            },
          },
        };
        if (obj.y <= 0) {
          obj.visible = false;
        }
        if (data.color) {
          obj.color = data.color;
        }
        series.push(obj);
      });
      setIsLoading(false);
      setSeries(series);
      setTable(reportResult.table);
    } catch (e) {
      setIsLoading(false);
    }
  };
  return (
    <div className="row report-payment-method-inner">
      <PaymentMethodReportChart
        isLoading={isLoading}
        series={series}
        table={table}
      />
    </div>
  );
}
