import Icon from "component-v2/icons/icons";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import Tooltip from "rc-tooltip";
import { useApp } from "contexts/app";
import { ImageCustom } from "component-v2/ImageCustom";
import KeyboardShortcutGuide from "screens-v2/carts-v2/components/keyboard-shortcut-guide";
import { useHistory } from "react-router-dom";
import { Feedback } from "./feedback";
import Button from "component-v2/buttons";

export default function GlobalUserInfo() {
  const { auth, current_location } = useApp();
  const [show, setShow] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const [showFeedback, setShowFeeback] = useState(false);
  const history = useHistory();

  const this_ref = useRef(null);
  useEffect(() => {
    if (show) {
      document.addEventListener("click", handleOutsideClick, false);
      setIsOpenTooltip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  function handleOutsideClick(e) {
    if (!this_ref.current.contains(e.target)) {
      setShow(false);
      document.removeEventListener("click", handleOutsideClick, false);
    }
  }

  return (
    <>
      <Tooltip
        showArrow={false}
        placement="bottomRight"
        trigger={["hover"]}
        overlay={
          <div className="font-weight-normal">
            <p className="mb-1">{current_location?.locationName}</p>
            <p className="mb-0" style={{ color: "rgba(255, 255, 255, 0.70)" }}>
              {" "}
              {[
                current_location?.address,
                current_location?.wardName,
                current_location?.districtName,
                current_location?.provinceName,
              ]
                .filter(Boolean)
                .join(", ")}
            </p>
          </div>
        }
        mouseEnterDelay={0.2}
      >
        <div>
          <Button
            title={current_location?.locationName}
            size="sm"
            light
            rounded
            icon="storefront"
            className="mr-3 max-width-180 text-truncate"
            onClick={() => history.push("/locations")}
          />
        </div>
      </Tooltip>
      <div ref={(node) => (this_ref.current = node)} className="l-header--user">
        <Tooltip
          visible={isOpenTooltip}
          onVisibleChange={(visible) => {
            if (!show) setIsOpenTooltip(visible);
          }}
          showArrow={false}
          placement="bottomRight"
          trigger={["hover"]}
          overlay={
            <div>
              <p className="m-0">{auth.user.name}</p>
              <p className="content-secondary m-0">{auth.user.email}</p>
            </div>
          }
          mouseEnterDelay={0.2}
        >
          {!auth.user?.picture && auth.user.name ? (
            <div
              className="avatar-empty"
              onClick={() => setShow((prev) => !prev)}
            >
              {auth.user.name
                .split(" ")
                .map((word) => word.charAt(0))
                .join("")}
            </div>
          ) : (
            <div>
              <ImageCustom
                src={auth.user?.picture ? String(auth.user.picture) : null}
                size="small"
                alt="haravaner"
                onClick={() => setShow((prev) => !prev)}
              />
            </div>
          )}
        </Tooltip>
        <div className={`l-header--user-info${show ? " show" : ""} l-mt-8`}>
          <div className="l-header--user-item text-nowrap">
            <p className="text-nowrap m-0">{auth.user.orgName}</p>
            <p className="content-secondary m-0 l-mt-4">{auth.user.name}</p>
          </div>
          <div className="border-top">
            <KeyboardShortcutGuide
              handleOnClick={() => {
                setShow(false);
              }}
            />
            <div
              className="l-header--user-item action border-0"
              onClick={() => {
                setShow(false);
                window.open("https://support.haravan.com", "_blank");
              }}
            >
              <Icon name="help" color="var(--contentSecondary)" />
              <span className="l-pl-8">Hướng dẫn sử dụng</span>
            </div>
            <div
              className="l-header--user-item action border-0"
              onClick={() => {
                setShowFeeback(true);
                setShow(false);
              }}
            >
              <Icon name="feedback" color="var(--contentSecondary)" />
              <span className="l-pl-8">Góp ý</span>
            </div>
          </div>
          <div
            className="l-header--user-item action border-top"
            onClick={() => {
              setShow(false);
              window.location.replace("/auth/logout");
            }}
          >
            <Icon name="output" />
            <span className="l-pl-8">Đăng xuất</span>
          </div>
        </div>
        <Feedback show={showFeedback} setShow={setShowFeeback} />
      </div>
    </>
  );
}
