import { concat, findIndex, forEach } from "lodash";
import { FormattedMessage } from "react-intl";
import HighchartUtils from "./highchart-utils";

export async function getReportDetail(
  appContext,
  startDate,
  endDate,
  keySearch,
  channel,
  staff,
  orderRefund,
  queryRefund,
) {
  try {
    let extend = {
      order_by: {
        dimension: "Day",
        direction: "desc",
      },
      groupby: ["day"],
      limit: 100,
    };
    if (keySearch) {
      extend.groupby = ["hour"];
    }

    extend.filters = [
      {
        conjunction: "and",
        dimension: "OrderStatusId",
        query: [
          {
            conjunction: "and",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái đơn hàng không là Hủy"
                defaultMessage="Trạng thái đơn hàng không là Hủy"
              />
            ),
            query: "Hủy",
            symbol: "isnot",
            value: "3",
          },
        ],
      },
    ];

    let objReport = onUpdateFilterObj(
      appContext,
      new Date(startDate),
      new Date(endDate),
      extend,
      "",
      channel,
      staff,
    );
    let objReportOrderDate = onUpdateFilterObj(
      appContext,
      new Date(startDate),
      new Date(endDate),
      extend,
      "",
      channel,
      staff,
    );
    let objReportRefund = onUpdateFilterObj(
      appContext,
      new Date(startDate),
      new Date(endDate),
      extend,
      true,
      channel,
      staff,
    );
    const result = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100736/query",
      objReport,
    );
    const resultReportRefund = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100736/query",
      objReportRefund,
    );
    const resultOrderDate = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100753/query",
      objReportOrderDate,
    );
    let highchartResult = {};
    if (
      result &&
      result.data &&
      result.data.data &&
      result.data.data.length &&
      resultOrderDate &&
      resultOrderDate.data &&
      resultOrderDate.data.data &&
      resultOrderDate.data.data.length
    ) {
      if (keySearch) {
        highchartResult = HighchartUtils.convertFormOmniReport(
          result.data.headerreport,
          result.data.data,
          resultOrderDate.data.data,
          resultReportRefund.data.data,
          "hour",
          channel,
          resultReportRefund.data.headerreport,
          orderRefund,
          queryRefund,
        );
      } else {
        highchartResult = HighchartUtils.convertFormOmniReport(
          result.data.headerreport,
          result.data.data,
          resultOrderDate.data.data,
          resultReportRefund.data.data,
          null,
          channel,
          resultReportRefund.data.headerreport,
          orderRefund,
          queryRefund,
        );
      }
    } else {
      highchartResult = HighchartUtils.convertFormOmniNoDataReport();
      if (resultReportRefund?.data?.data?.length) {
        let reportRefundTable = HighchartUtils.convertReportRefundTable(
          resultReportRefund.data.data,
          resultReportRefund.data.headerreport,
          channel,
          orderRefund,
        );
        highchartResult.reportRefundTable = reportRefundTable;
      }
    }
    return highchartResult;
  } catch (error) {}
}

export async function getDataRefund(appContext, startDate, endDate) {
  let summaryRefund = null;
  let queryRefund = null;
  let summaryPaymentRefund = null;
  let orderRefund = null;

  try {
    const location = appContext.current_location;

    let refundSummary = {
      LocationId: location.id,
      OrderSource: "online",
      TransactionType: 2,
      StartDate: new Date(startDate),
      EndDate: new Date(endDate),
    };
    const resultSummary = await appContext.callAPI(
      "post",
      "/call/retail_api/transactions/refund/summary",
      refundSummary,
    );
    let resultQuery = await appContext.callAPI(
      "post",
      "/call/retail_api/transactions/refund/query",
      refundSummary,
    );
    let resultSummaryPaymentRefund = await appContext.callAPI(
      "post",
      "/call/retail_api/transactions/payments/summary",
      refundSummary,
    );
    let resultOrderRefund = await appContext.callAPI(
      "post",
      "/call/retail_api/transactions/searchs",
      refundSummary,
    );
    if (!resultSummary.error) {
      summaryRefund = resultSummary.data || [];
    }
    if (!resultQuery.error) {
      queryRefund = resultQuery.data || [];
    }
    if (!resultSummaryPaymentRefund.error) {
      summaryPaymentRefund = resultSummaryPaymentRefund.data || [];
    }
    if (!resultOrderRefund.error) {
      orderRefund = resultOrderRefund.data;
    }
  } catch (error) {}
  return { summaryRefund, queryRefund, summaryPaymentRefund, orderRefund };
}

export async function getTittleReport(
  appContext,
  startDate,
  endDate,
  channel = null,
  staff,
) {
  let extend = {
    order_by: {
      dimension: "Day",
      direction: "desc",
    },
    groupby: ["day", "gateway"],
    limit: 100,
  };
  extend.filters = [
    {
      conjunction: "and",
      dimension: "OrderStatusId",
      query: [
        {
          symbol: "isnot",
          query: "Hủy",
          value: "3",
          conjunction: "and",
          displayText: (
            <FormattedMessage
              id="reports.Trạng thái đơn hàng không là Hủy"
              defaultMessage="Trạng thái đơn hàng không là Hủy"
            />
          ),
        },
      ],
    },
  ];
  let _startDate = new Date(startDate);
  let _endDate = new Date(endDate);
  let objReport = onUpdateFilterObj(
    appContext,
    _startDate,
    _endDate,
    extend,
    "",
    channel,
    staff,
  );
  let objReportRefund = onUpdateFilterObj(
    appContext,
    _startDate,
    _endDate,
    extend,
    true,
    channel,
    staff,
  );
  try {
    const result = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100736/summary",
      objReport,
    );
    const resultRefund = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100736/summary",
      objReportRefund,
    );
    let summaryReport = null;
    let summaryReportRefund = null;
    if (result && result.data && resultRefund && resultRefund.data)
      summaryReport = result.data;
    if (result && result.data && resultRefund && resultRefund.data)
      summaryReportRefund = resultRefund.data;
    return { summaryReport, summaryReportRefund };
  } catch (error) {
    return { summaryReport: null, summaryReportRefund: null };
  }
}

export async function getTittleReportFollowOrderDateDeal(
  appContext,
  startDate,
  endDate,
  cancel = false,
  channel = null,
  staff,
  isRefund = false,
) {
  let extend = {
    order_by: {
      dimension: "Day",
      direction: "desc",
    },
    groupby: ["day"],
    limit: 100,
  };
  if (cancel) {
    extend.filters = [
      {
        conjunction: "and",
        dimension: "OrderStatusId",
        query: [
          {
            symbol: "is",
            query: "Hủy",
            value: "3",
            conjunction: "and",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái đơn hàng là Hủy"
                defaultMessage="Trạng thái đơn hàng là Hủy"
              />
            ),
          },
        ],
      },
    ];
  } else {
    extend.filters = [
      {
        conjunction: "and",
        dimension: "OrderStatusId",
        query: [
          {
            symbol: "isnot",
            query: "Hủy",
            value: "3",
            conjunction: "and",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái đơn hàng không là Hủy"
                defaultMessage="Trạng thái đơn hàng không là Hủy"
              />
            ),
          },
        ],
      },
    ];
  }
  if (isRefund) {
    let filters = [
      {
        conjunction: "or",
        dimension: "PaymentStatusId",
        query: [
          {
            symbol: "is",
            query: "Nhập trả một phần",
            value: "3",
            conjunction: "or",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái thanh toán là Nhập trả một phần"
                defaultMessage="Trạng thái thanh toán là Nhập trả một phần"
              />
            ),
          },
          {
            symbol: "is",
            query: "Đã nhập trả",
            value: "6",
            conjunction: "or",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái thanh toán là Đã nhập trả"
                defaultMessage="Trạng thái thanh toán là Đã nhập trả"
              />
            ),
          },
        ],
      },
    ];
    extend.filters = concat(extend.filters, filters);
  }
  let _startDate = new Date(startDate);
  let _endDate = new Date(endDate);
  let objReport = onUpdateFilterObj(
    appContext,
    _startDate,
    _endDate,
    extend,
    "",
    channel,
    staff,
  );
  try {
    const result = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100736/summary",
      objReport,
    );
    return result.data ? result.data : null;
  } catch (error) {
    return null;
  }
}

export async function getTittleReportFollowOrderDate(
  appContext,
  startDate,
  endDate,
  cancel = false,
  channel = null,
  staff,
  isRefund = false,
  isPending = false,
) {
  let extend = {
    order_by: {
      dimension: "Day",
      direction: "desc",
    },
    groupby: ["day"],
    limit: 100,
  };
  if (cancel) {
    extend.filters = [
      {
        conjunction: "and",
        dimension: "OrderStatusId",
        query: [
          {
            symbol: "is",
            query: "Hủy",
            value: "3",
            conjunction: "and",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái đơn hàng là Hủy"
                defaultMessage="Trạng thái đơn hàng là Hủy"
              />
            ),
          },
        ],
      },
    ];
  } else {
    extend.filters = [
      {
        conjunction: "and",
        dimension: "OrderStatusId",
        query: [
          {
            symbol: "isnot",
            query: "Hủy",
            value: "3",
            conjunction: "and",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái đơn hàng không là Hủy"
                defaultMessage="Trạng thái đơn hàng không là Hủy"
              />
            ),
          },
        ],
      },
    ];
  }
  if (isRefund) {
    let filters = [
      {
        conjunction: "or",
        dimension: "PaymentStatusId",
        query: [
          {
            symbol: "is",
            query: "Nhập trả một phần",
            value: "3",
            conjunction: "or",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái thanh toán là Nhập trả một phần"
                defaultMessage="Trạng thái thanh toán là Nhập trả một phần"
              />
            ),
          },
          {
            symbol: "is",
            query: "Đã nhập trả",
            value: "6",
            conjunction: "or",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái thanh toán là Đã nhập trả"
                defaultMessage="Trạng thái thanh toán là Đã nhập trả"
              />
            ),
          },
        ],
      },
    ];
    extend.filters = concat(extend.filters, filters);
  }
  let _startDate = new Date(startDate);
  let _endDate = new Date(endDate);
  let objReport = onUpdateFilterObj(
    appContext,
    _startDate,
    _endDate,
    extend,
    "",
    channel,
    staff,
    isPending,
  );
  try {
    const result = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100753/summary",
      objReport,
      true,
    );
    return result.data ? result.data : null;
  } catch (error) {
    return null;
  }
}

export async function getTopProduct(
  appContext,
  startDate,
  endDate,
  channel = null,
  staff,
) {
  try {
    let extend = {};

    extend.filters = [
      {
        conjunction: "and",
        dimension: "OrderStatusId",
        query: [
          {
            symbol: "isnot",
            query: "Hủy",
            value: "3",
            conjunction: "and",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái đơn hàng không là Hủy"
                defaultMessage="Trạng thái đơn hàng không là Hủy"
              />
            ),
          },
        ],
      },
    ];
    extend.order_by = {
      dimension: "Quantity",
      direction: "desc",
    };
    extend.metrics = [
      {
        measureField: "TotalSaleAmount",
        measureName: "TotalSaleAmount",
      },
      {
        measureField: "Quantity",
        measureName: "Quantity",
      },
    ];
    extend.groupby = ["productid"];
    let objReport = onUpdateFilterObj(
      appContext,
      new Date(startDate),
      new Date(endDate),
      extend,
      "",
      channel,
      staff,
    );
    const result = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100738/query",
      objReport,
    );

    if (result && result.data && result.data.data) {
      const topProducts = HighchartUtils.convertTopProducts(
        result.data.data,
        result.data.datalink,
      );

      return topProducts;
    }
  } catch (e) {
    return [];
  }
}

export async function getReportPaymentMethodDetail(
  appContext,
  startDate,
  endDate,
  keySearch,
  channel,
  staff,
  summaryPaymentRefund,
) {
  try {
    let extend = {
      order_by: {
        dimension: "Day",
        direction: "desc",
      },
      groupby: ["gateway"],
      limit: 100,
    };
    extend.filters = {
      conjunction: "or",
      dimension: "OrderStatusId",
      query: [
        {
          symbol: "is",
          value: "2",
          conjunction: "or",
        },
      ],
    };

    let objReport = onUpdateFilterObj(
      appContext,
      new Date(startDate),
      new Date(endDate),
      extend,
      "",
      channel,
      staff,
    );
    let objReportRefund = onUpdateFilterObj(
      appContext,
      new Date(startDate),
      new Date(endDate),
      extend,
      true,
      channel,
      staff,
    );
    const result = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100736/query",
      objReport,
    );
    const resultRefund = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100736/query",
      objReportRefund,
    );
    let highchartResult = {};
    let headerReport =
      result.data.headerreport.length > 0
        ? result.data.headerreport
        : resultRefund.data.headerreport.length > 0
          ? resultRefund.data.headerreport
          : [];
    if (
      (result &&
        result.data &&
        result.data.data &&
        result.data.data.length > 0) ||
      (resultRefund &&
        resultRefund.data &&
        resultRefund.data.data &&
        resultRefund.data.data.length > 0)
    ) {
      if (keySearch) {
        highchartResult = HighchartUtils.convertPaymentMethodOmniReport(
          headerReport,
          result.data.data,
          summaryPaymentRefund,
          "hour",
          channel,
        );
      } else {
        highchartResult = HighchartUtils.convertPaymentMethodOmniReport(
          headerReport,
          result.data.data,
          summaryPaymentRefund,
          null,
          channel,
        );
      }
    } else {
      highchartResult = HighchartUtils.convertFormOmniNoDataReport();
    }
    return highchartResult;
  } catch (error) {}
}

export async function getReportProductDetail(
  appContext,
  startDate,
  endDate,
  channel,
  staff,
) {
  try {
    let extend = {
      order_by: {
        dimension: "Quantity",
        direction: "desc",
      },
      metrics: [
        {
          measureField: "OrderCountSpecial",
          measureName: "OrderCountSpecial",
        },
        {
          measureField: "GrossSaleAmount",
          measureName: "GrossSaleAmount",
        },
        {
          measureField: "DiscountAmount",
          measureName: "DiscountAmount",
        },
        {
          measureField: "NetSaleAmount",
          measureName: "NetSaleAmount",
        },
        {
          measureField: "TotalSaleAmount",
          measureName: "TotalSaleAmount",
        },
        {
          measureField: "PaidAmount",
          measureName: "PaidAmount",
        },
        {
          measureField: "ReturnsAmount",
          measureName: "ReturnsAmount",
        },
        {
          measureField: "Quantity",
          measureName: "Quantity",
        },
        {
          measureField: "ReturnQuantity",
          measureName: "ReturnQuantity",
        },
      ],
      filters: {
        conjunction: "and",
        dimension: "OrderStatusId",
        query: [
          {
            symbol: "isnot",
            query: "Hủy",
            value: "3",
            conjunction: "and",
            displayText: (
              <FormattedMessage
                id="reports.Trạng thái đơn hàng không là Hủy"
                defaultMessage="Trạng thái đơn hàng không là Hủy"
              />
            ),
          },
        ],
      },
      groupby: ["productid", "variantid"],
      limit: 10,
    };
    let objReport = onUpdateFilterObj(
      appContext,
      new Date(startDate),
      new Date(endDate),
      extend,
      null,
      channel,
      staff,
    );
    const result = await appContext.callAPI(
      "post",
      "/call/report_api/reports/100735/query",
      objReport,
    );
    if (result && result.data && result.data.data) {
      let { data, headerreport, datalink } = result.data;
      forEach(datalink, (variantLink, index) => {
        if (variantLink[0] && variantLink[1]) {
          const link = variantLink[1].split("/");
          data[index].push(link[2]);
        }
      });

      headerreport.push({
        name: "VariantId2",
        type: "GroupProperty",
      });

      const highchartResult = HighchartUtils.convertFormOmniReportProduct(
        headerreport,
        data,
      );
      const inventorys = await getVariantsBalance(
        appContext,
        result.data.datalink,
      );
      if (inventorys) {
        forEach(highchartResult.table, (product) => {
          const result = findIndex(inventorys[0].balances, function (o) {
            return o.variantId.toString() === product.VariantId2;
          });
          if (result !== -1) {
            const { qty_Commited, qty_OnHand } = inventorys[0].balances[result];
            product.Inventory = qty_OnHand - qty_Commited;
          } else {
            product.Inventory = "∞";
          }
        });
      }

      return highchartResult;
    }
  } catch (e) {
    this.dataSelling = [];
  }
}

// PRIVATE
const onUpdateFilterObj = (
  appContext,
  start,
  end,
  extend,
  justRefund = false,
  channel = null,
  staff,
  isPending = false,
) => {
  const { id, locationName } = appContext.current_location;
  let objReport = {
    pagination: {
      offset: 0,
      limit: 100,
    },
    daterange: {
      startdate: start,
      enddate: end,
    },
    order_by: {
      dimension: "Day",
      direction: "desc",
    },
    filters: [
      {
        conjunction: "or",
        dimension: "LocationId",
        query: [
          {
            symbol: "is",
            value: id,
            conjunction: "or",
            displayText: (
              <FormattedMessage
                id="reports.Chi nhánh là {a}"
                defaultMessage="Chi nhánh là {a}"
                values={{
                  a: <span>{locationName}</span>,
                }}
              />
            ),
            query: locationName,
          },
        ],
      },
    ],
    metrics: [
      {
        measureField: "OrderCountSpecial",
        measureName: "OrderCountSpecial",
      },
      {
        measureField: "GrossSaleAmount",
        measureName: "GrossSaleAmount",
      },
      {
        measureField: "DiscountAmount",
        measureName: "DiscountAmount",
      },
      {
        measureField: "NetSaleAmount",
        measureName: "NetSaleAmount",
      },
      {
        measureField: "ShippingAmount",
        measureName: "ShippingAmount",
      },
      {
        measureField: "TotalSaleAmount",
        measureName: "TotalSaleAmount",
      },
      {
        measureField: "PaidAmount",
        measureName: "PaidAmount",
      },
      {
        measureField: "ReturnsAmount",
        measureName: "ReturnsAmount",
      },
      {
        measureField: "Quantity",
        measureName: "Quantity",
      },
      {
        measureField: "RedeemValue",
        measureName: "RedeemValue",
      },
    ],
    groupby: [],
  };
  if (justRefund) {
    objReport.filters = concat(objReport.filters, {
      conjunction: "or",
      dimension: "PaymentStatusId",
      query: [
        {
          symbol: "is",
          query: "Nhập trả một phần",
          value: "3",
          conjunction: "or",
          displayText: (
            <FormattedMessage
              id="reports.Trạng thái thanh toán là Nhập trả một phần"
              defaultMessage="Trạng thái thanh toán là Nhập trả một phần"
            />
          ),
        },
        {
          symbol: "is",
          query: "Đã nhập trả",
          value: "6",
          conjunction: "or",
          displayText: (
            <FormattedMessage
              id="reports.Trạng thái thanh toán là Đã nhập trả"
              defaultMessage="Trạng thái thanh toán là Đã nhập trả"
            />
          ),
        },
      ],
    });
    objReport.groupby = ["hour", "sourcename", "ordernumber"];
    objReport.metrics = [
      {
        measureField: "ReturnsAmount",
        measureName: "ReturnsAmount",
      },
    ];
  }
  if (extend) {
    objReport.groupby = objReport.groupby.concat(extend.groupby);
    objReport.order_by = extend.order_by;
    objReport.pagination.limit = extend.limit;
    if (extend.metrics) {
      objReport.metrics = extend.metrics;
    }
    if (extend.filters) {
      objReport.filters = concat(objReport.filters, extend.filters);
    }
  }
  if (channel === "channelPOS") {
    let filters = {
      conjunction: "or",
      dimension: "SourceName",
      query: [
        {
          symbol: "is",
          value: "pos",
          query: "pos",
          conjunction: "or",
        },
      ],
    };
    objReport.filters.push(filters);
  } else if (channel === "channelOnline") {
    let filters = {
      conjunction: "and",
      dimension: "SourceName",
      query: [
        {
          symbol: "isnot",
          value: "pos",
          query: "pos",
          conjunction: "and",
        },
      ],
    };
    objReport.filters.push(filters);
  }
  if (staff && staff.id !== null) {
    let filters = {
      conjunction: "and",
      dimension: "UserId",
      query: [
        {
          symbol: "is",
          query: staff.name,
          value: staff.id,
          conjunction: "or",
          displayText: `Nhân viên thanh toán là ${staff.name}`,
        },
      ],
    };
    objReport.filters.push(filters);
    if (isPending) {
      let filters = {
        conjunction: "and",
        dimension: "OrderCreatedUser",
        query: [
          {
            symbol: "is",
            query: staff.name,
            value: staff.id,
            conjunction: "or",
            displayText: `Nhân viên tạo là ${staff.name}`,
          },
        ],
      };
      objReport.filters.push(filters);
    }
  }
  return objReport;
};

const getVariantsBalance = async (appContext, datalink) => {
  const currentLocation = appContext.current_location;
  let variantIds = [];
  forEach(datalink, (variantLink) => {
    if (variantLink[0] && variantLink[1]) {
      const link = variantLink[1].split("/");
      if (link[0] !== "shipping" && link[0] !== "--") {
        variantIds.push(link[2]);
      }
    }
  });

  let obj = {
    countryId: currentLocation.countryId,
    provinceId: currentLocation.provinceId,
    variantIds: variantIds,
  };
  const result = await appContext.callAPI(
    "post",
    "/call/com_api/orders/locations/variants/balance",
    obj,
  );
  if (!result.error) {
    return result.data;
  } else {
    return null;
  }
};
