class StorageService {
  constructor() {
    this.storage = window.localStorage;
    this.location_key = "@haravan-pos:location";
  }

  get(key) {
    return JSON.parse(this.storage.getItem(key)) || false;
  }

  set(key, value) {
    if (key) {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  remove(key) {
    this.storage.removeItem(key);
  }
}

const storageService = new StorageService();
export default storageService;
