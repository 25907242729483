import { useLayoutContext } from "contexts/Layout/layout-context";
import { toFormattedNumber } from "infrastructures/format-helpers";
import { SYSTEM_GUEST_EMAIL } from "infrastructures/utils";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { concatString } from "screens-v2/carts-v2/utils";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import { ModalCustomerInfo } from "../components/modalCustomerInfo";
import Icon from "component-v2/icons/icons";

export const CustomerInfo = ({
  customer,
  showEditAddress,
  setShowEditAddress,
  reloadCustomer,
}) => {
  useLayoutContext();
  const [editCustomerInfo, setEditCustomerInfo] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(customer);

  useEffect(() => {
    setCustomerInfo(customer);
  }, [customer]);

  useEffect(() => {
    if (showEditAddress) {
      setEditCustomerInfo(true);
    }
  }, [showEditAddress]);

  const renderCustomerDebt = () => {
    if (customerInfo.email === SYSTEM_GUEST_EMAIL) return null;

    if (customer && customer?.debt === 0) {
      return (
        <Badge status="default" light className="mr-3 border-0">
          <FormattedMessage id="Không có nợ" defaultMessage="Không có nợ" />
        </Badge>
      );
    }

    return (
      <Badge
        status={customer?.debt < 0 ? "red" : "green"}
        light
        className="mr-3 border-0"
      >
        <FormattedMessage
          id={"Khách nợ: {x}"}
          defaultMessage={"Khách nợ: {x}"}
          values={{
            x: (
              <span
                className={customer?.debt < 0 ? "text-danger" : "text-success"}
              >
                {toFormattedNumber(-customer?.debt)}
              </span>
            ),
          }}
        />
      </Badge>
    );
  };

  return (
    <div>
      <div className="l-section--with-title">
        <div className="l-img-placeholder l-mr-8">
          <Icon name="account_circle" size={2.4} />
        </div>
        <div className="l-section--body">
          <div className="flex-row w-100 h-100">
            <div
              className="flex-stretch overflow-auto"
              // onClick={() => setEditCustomerInfo(true)}
            >
              {!customer || customer?.email === SYSTEM_GUEST_EMAIL ? (
                <div className="content-primary d-flex text-truncate align-items-center h-100">
                  Khách lẻ
                </div>
              ) : (
                <>
                  <div
                    className="content-primary text-truncate"
                    title={
                      concatString({
                        value: [customer?.lastName, customer?.firstName],
                      }) || "--"
                    }
                  >
                    {concatString({
                      value: [customer?.lastName, customer?.firstName],
                    }) || "--"}
                  </div>
                  <div className="content-secondary font-size-12 d-flex flex-wrap">
                    {concatString({
                      value: [customer?.phone, customer?.email],
                      separator: " • ",
                    }) || "--"}
                  </div>
                  {renderCustomerDebt()}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalCustomerInfo
        customerInfo={customerInfo}
        show={editCustomerInfo}
        setShow={(value) => {
          setEditCustomerInfo(value);
          if (!value) setShowEditAddress(value);
        }}
        showEditAddress={showEditAddress}
        reloadCustomer={reloadCustomer}
      />
    </div>
  );
};
