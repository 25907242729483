import React, { useEffect, useState } from "react";
import "./style.css";
import * as HrvComponents from "@haravan/react-components";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { useIntl } from "react-intl";
import { InputNumber } from "screens-v2/carts-v2/RightCart/components/InputNumber";
import { useCartContext } from "screens-v2/carts-v2";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import Button from "component-v2/buttons";
import { HOTKEYS, HOTKEY_EVENTS, HOTKEY_SCOPES } from "models/constants";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { ImageCustom } from "component-v2/ImageCustom";

const discountType = {
  new_price: { id: 1, name: "new price" },
  discount_price: { id: 2, name: "discount price" },
};

const discountMode = {
  currency: "currency",
  percentage: "percentage",
};

const OrderLineDiscountForm = (props) => {
  const { current_shopping_cart, updateCurrentSession, updateItemCurrentCart } =
    useCartContext();
  const {
    originalPrice,
    discountPromotion,
    linePrice,
    couponsDiscountAmount,
    discountAmountPerLine,
    quantity,
  } = props.currentLineData;
  const [localState, setLocalState] = useState({
    currentLineUpdateData: props.currentLineData,
    discountType: "new_price",
    discountAmount: 0,
    discountDescription: "",
    discountValue: 0,
    percent: 0,
    newPrice: props.currentLineData.linePrice,
    amount: 0,
    selectedDiscountType: discountType.new_price,
    sltDiscountMode: discountMode.currency,
  });
  const intl = useIntl();

  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        resetDataCurrentLine();
        props.setShowModal(false);
      }
      if (e.key === HOTKEY_EVENTS.F8) {
        handleConfirm();
      }
    },
    enabled: props.showModal,
    scopes: HOTKEY_SCOPES.MODAL,
  });

  useEffect(() => {
    setLocalState((prev) => ({
      ...prev,
      newPrice: props.currentLineData.linePrice,
      currentLineUpdateData: props.currentLineData,
      discountValue: props.currentLineData.discountValue,
      discountAmount: props.currentLineData.discountAmount,
      discountDescription: props.currentLineData.discountDescription,
      discountType: props.currentLineData.discountType,
      amount:
        !props.currentLineData.discountType ||
        props.currentLineData.discountType === "amount" ||
        props.currentLineData.discountType === "new_price"
          ? props.currentLineData.discountValue
          : 0,
      percent:
        props.currentLineData.discountType === "percent"
          ? props.currentLineData.discountValue
          : 0,
      selectedDiscountType:
        !props.currentLineData.discountType ||
        props.currentLineData.discountType === "new_price"
          ? discountType.new_price
          : discountType.discount_price,
      sltDiscountMode:
        !props.currentLineData.discountType ||
        props.currentLineData.discountType === "amount" ||
        props.currentLineData.discountType === "new_price"
          ? discountMode.currency
          : discountMode.percentage,
    }));
  }, [props.currentLineData]);

  const handleInputChanged = (type, value) => {
    let res = null;
    if (type === "new_price") {
      if (
        value >
        originalPrice - (discountPromotion - couponsDiscountAmount / quantity)
      ) {
        value =
          originalPrice -
          (discountPromotion - couponsDiscountAmount / quantity);
      }
      res = {
        discountType: type,
        discountAmount: Number(
          (
            linePrice +
            (discountAmountPerLine || 0) +
            couponsDiscountAmount / quantity -
            value
          ).toFixed(4),
        ),
        newPrice: value,
        discountValue: Number(
          (
            linePrice +
            (discountAmountPerLine || 0) +
            couponsDiscountAmount / quantity -
            value
          ).toFixed(4),
        ),
        percent: 0,
      };
    } else if (type === "amount") {
      if (value > originalPrice - discountPromotion) {
        value = originalPrice - discountPromotion;
      }
      res = {
        discountType: type,
        discountAmount: value,
        amount: value,
        discountValue: value || 0,
        newPrice: originalPrice - discountPromotion - value,
      };
    } else if (type === "percent") {
      const discountAmount =
        ((originalPrice - discountPromotion) * value) / 100;
      res = {
        discountType: type,
        percent: value,
        discountAmount: discountAmount,
        discountValue: value || 0,
        newPrice: Number(
          (originalPrice - discountPromotion - discountAmount).toFixed(4),
        ),
      };
    }

    setLocalState((prev) => ({
      ...prev,
      ...res,
    }));
  };

  const handleConfirm = () => {
    const {
      discountType,
      discountAmount,
      discountDescription,
      discountValue,
      currentLineData,
    } = localState;

    const discountTypeStore = currentLineData?.discountType;
    const discountValueStore = currentLineData?.discountValue;
    const discountDescriptionStore = currentLineData?.discountDescription;
    if (
      discountType !== discountTypeStore ||
      discountValueStore !== discountValue ||
      discountDescription !== discountDescriptionStore
    ) {
      setOrderLineDiscountAmount(
        discountType,
        discountAmount,
        discountDescription,
        discountValue,
      );
    } else {
    }
    props.setShowModal(false);
  };

  const setOrderLineDiscountAmount = async (
    discountType,
    discountAmountPerLine,
    discountDescription,
    discountValue,
  ) => {
    const { currentLineUpdateData } = localState;
    const { originalPrice, discountPromotion, quantity } =
      currentLineUpdateData;
    let discountTypeId = null;
    if (discountType === "amount" || discountType === "new_price") {
      discountTypeId = 1;
    } else if (discountType === "percent") {
      discountTypeId = 2;
    }
    const orderLineDiscountUpdate = {
      ...currentLineUpdateData,
      discountAmountPerLine: discountAmountPerLine,
      discountAmount: discountAmountPerLine * quantity,
      discountDescription: discountDescription,
      discountTypeId: discountTypeId,
      discountType: discountType,
      discountValue: discountValue,
      price: Number(
        (originalPrice - discountPromotion - discountAmountPerLine).toFixed(4),
      ),
      isCalculatePromotion: true,
      fields: ["price"],
    };
    const currentOrderLines = [...current_shopping_cart?.lineItems];

    const orderLineIndex = currentOrderLines.findIndex((orderLine) => {
      return (
        orderLine.id === orderLineDiscountUpdate.id &&
        orderLine.productId === orderLineDiscountUpdate.productId
      );
    });

    currentOrderLines.splice(orderLineIndex, 1, orderLineDiscountUpdate);
    updateCurrentSession({
      lineItems: currentOrderLines,
      loyalty: {
        ...current_shopping_cart.loyalty,
        redeemUsedAmount: 0,
        rewardRedeemAmount: 0,
        discountCouponLoyalty: "",
      },
    });
  };

  const resetDataCurrentLine = (data = {}) => {
    setLocalState((prev) => {
      return {
        ...prev,
        ...data,
        discountAmount: data?.discountAmount || 0,
        discountValue: data?.discountValue | 0,
        percent: data?.percent | 0,
        newPrice: props.currentLineData.linePrice,
        amount: data?.amount | 0,
      };
    });
  };

  const removeDiscountOrderLine = () => {
    const newItem = {
      ...props.currentLineData,
      price: props.currentLineData.originalPrice,
      isCalculatePromotion: true,
      discountAmount: 0,
      discountPromotion: 0,
      discountAmountPerLine: 0,
      discountValue: 0,
      discountType: null,
    };

    updateItemCurrentCart(newItem, props.index, newItem.quantity, ["price"]);
    props.setShowModal(false);
  };

  const body = () => {
    return (
      <>
        <div className="d-flex pb-4 mb-4 border-bottom justify-content-between">
          <div className="d-flex">
            <div className="cart-product-item--image mr-3">
              <ImageCustom
                src={localState?.currentLineUpdateData?.imageUrl}
                alt="haravaner"
              />
            </div>
            <div className="cart-product-item--info">
              <p className="product-name font-weight-medium mb-0">
                {localState.currentLineUpdateData.productTitle}
              </p>
              <p className="product-type  content-secondary mb-0">
                {localState.currentLineUpdateData.variantTitle}
              </p>
              <p className="product-code  content-secondary mb-0">
                {localState.currentLineUpdateData.barcode}
              </p>
            </div>
          </div>
          {localState?.currentLineUpdateData?.selectedUnit ? (
            <Badge status={"gray"} light>
              {localState?.currentLineUpdateData?.selectedUnit?.unit}
            </Badge>
          ) : null}
        </div>
        <div className="detail-boby">
          <div className="w-100 margin-bottom-16 ">
            <div className="mb-3">
              <HrvComponents.InputRadio
                onChange={() =>
                  resetDataCurrentLine({
                    selectedDiscountType: discountType.new_price,
                    sltDiscountMode: discountMode.currency,
                    discountType: "new_price",
                    discountAmount: Number(
                      (
                        linePrice +
                        (discountAmountPerLine || 0) +
                        couponsDiscountAmount / quantity -
                        localState.newPrice
                      ).toFixed(4),
                    ),
                    discountValue: Number(
                      (
                        linePrice +
                        (discountAmountPerLine || 0) +
                        couponsDiscountAmount / quantity -
                        localState.newPrice
                      ).toFixed(4),
                    ),
                    percent: 0,
                  })
                }
                checked={
                  localState.selectedDiscountType.id ===
                  discountType.new_price.id
                }
                className={`mb-3 ${
                  localState.selectedDiscountType.id ===
                  discountType.new_price.id
                    ? ""
                    : "discount-radio-btn"
                }`}
              >
                Đặt giá bán mới cho sản phẩm
              </HrvComponents.InputRadio>
              <div className="discount-section">
                <InputNumber
                  currency
                  value={localState.newPrice}
                  onChange={(value) => {
                    handleInputChanged("new_price", value);
                  }}
                  disabled={
                    localState.selectedDiscountType.id !==
                    discountType.new_price.id
                  }
                  className="w-100 my-3 text-right  "
                />
              </div>
            </div>
            <div className="mb-3">
              <HrvComponents.InputRadio
                onChange={() =>
                  resetDataCurrentLine({
                    selectedDiscountType: discountType.discount_price,
                    discountType: "amount",
                  })
                }
                checked={
                  localState.selectedDiscountType.id ===
                  discountType.discount_price.id
                }
                className={`mb-3 ${
                  localState.selectedDiscountType.id ===
                  discountType.discount_price.id
                    ? ""
                    : "discount-radio-btn"
                }`}
              >
                Giảm giá cho sản phẩm
              </HrvComponents.InputRadio>
              <div className="d-flex discount-section ">
                <div className="sale-mode flex-basis-50 mr-3 discount-mode-root ">
                  <div
                    className={`${
                      localState.sltDiscountMode === discountMode.currency
                        ? "sale-mode-active"
                        : ""
                    }
                ${
                  localState.selectedDiscountType.id !==
                  discountType.discount_price.id
                    ? "disabled"
                    : ""
                }`}
                    onClick={() => {
                      resetDataCurrentLine({
                        sltDiscountMode: discountMode.currency,
                        selectedDiscountType: discountType.discount_price,
                        discountType: "amount",
                      });
                    }}
                  >
                    Giảm tiền
                  </div>
                  <div
                    className={`${
                      localState.sltDiscountMode === discountMode.percentage
                        ? "sale-mode-active"
                        : ""
                    } 
                ${
                  localState.selectedDiscountType.id !==
                  discountType.discount_price.id
                    ? "disabled"
                    : ""
                }`}
                    onClick={() => {
                      resetDataCurrentLine({
                        sltDiscountMode: discountMode.percentage,
                        selectedDiscountType: discountType.discount_price,
                        discountType: "percent",
                      });
                    }}
                  >
                    Giảm %
                  </div>
                </div>
                {localState.sltDiscountMode === discountMode.percentage ? (
                  <InputNumber
                    min={0}
                    max={100}
                    value={localState.percent}
                    disabled={
                      localState.selectedDiscountType.id !==
                      discountType.discount_price.id
                    }
                    onChange={(value) => {
                      handleInputChanged("percent", value);
                    }}
                    className="flex-basis-50 text-right h-100"
                  />
                ) : (
                  <InputNumber
                    currency
                    value={localState.amount}
                    disabled={
                      localState.selectedDiscountType.id !==
                      discountType.discount_price.id
                    }
                    onChange={(value) => {
                      handleInputChanged("amount", value);
                    }}
                    className="flex-basis-50 text-right h-100"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-100">
            <label className="hrv-next-label--switch">
              {intl.formatMessage({
                id: "carts.orders.Lý do nhập giá mới",
                defaultMessage: "Lý do nhập giá mới",
              })}
            </label>
            <HrvComponents.Textarea
              value={localState.discountDescription}
              rows="3"
              className="w-100"
              placeholder={intl.formatMessage({
                id: "carts.orders.Giảm giá cho sản phẩm, khách hàng thân thiết",
                defaultMessage: "Giảm giá cho sản phẩm, khách hàng thân thiết",
              })}
              onChange={(value) => {
                setLocalState((prev) => ({
                  ...prev,
                  discountDescription: value,
                }));
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const footer = () => (
    <div className={`d-flex justify-content-between align-items-center w-100`}>
      <Button
        title={intl.formatMessage({
          id: "carts.orders.Xóa thay đổi giá",
          defaultMessage: "XÓA THAY ĐỔI GIÁ",
        })}
        onClick={() => {
          removeDiscountOrderLine();
        }}
        light={true}
      />
      <div>
        <Button
          title="Hủy"
          onClick={() => {
            resetDataCurrentLine();
            props.setShowModal(false);
          }}
          light={true}
          className="l-mr-8"
          badge={HOTKEYS.ESC}
        />
        <Button
          title={"Xong"}
          onClick={() => {
            handleConfirm();
          }}
          status="primary"
          badge={HOTKEYS.F8}
        />
      </div>
    </div>
  );

  return (
    <GlobalModal
      headerTitle={"Sửa giá"}
      body={body()}
      footer={footer()}
      show={props.showModal}
      setShow={(value) => {
        // if (!value) resetDataCurrentLine();
        props.setShowModal(value);
      }}
    />
  );
};

export { OrderLineDiscountForm };
