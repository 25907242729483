import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "component-v2/icons/icons";


import CurrencyShow from "component-v2/currency-show";
import NumberShow from "component-v2/number-show";
import { Assets } from "assets";
import { Tooltip, ImageResize } from "@haravan/react-components";
import Button from "component-v2/buttons";
import { ImageCustom } from "component-v2/ImageCustom";
import { UnitSelection2 } from "component-v2/UnitSelection/index_backup";

export const RefundCartSearchItem = (props) => {
  const intl = useIntl();
  const { isVinamilk, product, index, isShowUnit } = props;
  const id = `search-product--item-${index}`;
  let logoChange = isVinamilk
    ? Assets.icons_svg.search_product_icon_vinamilk
    : Assets.icons_svg.search_product_icon;
  let ratio = product.selectedUnit ? product.selectedUnit.ratio || 1 : 1;

  const handleClickProduct = () => {
    props.onClick(product);
  };

  const handleChangeVariantUnit = (unit) => {
    let _variant = { ...product };
    _variant.selectedUnit = unit;
    _variant.variantUnitId = unit.id;
    _variant.price = unit.price;
    _variant.sku = unit.sku;
    _variant.barcode = unit.barcode;
    props.onClick(_variant);
  };

  return (
    <Button
      id={id}
      light
      onClick={handleClickProduct}
      noWrap={false}
      className="list-group-item cart-product-search-item"
    >
      <div className="d-flex">
        <div className="prod-img-variant flex-shrink-0">
          <ImageCustom src={product?.productImgUrl} alt="image3" size="thumb" />
        </div>
        <div className="pr-0 pl-2 w-100">
          <div className="d-flex justify-content-between">
            <div className="cart-product-item--info ml-2">
              <p className="product-name font-weight-medium prod-sub-title-item mb-2">
                {product.productName}
              </p>
              <p className="text-secondary font-size-12 prod-sub-title-item mb-2">
                {product.variantTitle}
              </p>
              <p className="product-code font-size-12 text-secondary prod-sub-title-item mb-2">
                {product.barcode}
              </p>
            </div>
            {isShowUnit && (
              <div className="p-0 d-flex">
                <div className="text-left" onClick={(e) => e.stopPropagation()}>
                  <UnitSelection2
                    setMouseOverResult={props.setMouseOverResult}
                    options={product.units}
                    selectedOption={product.selectedUnit}
                    onChange={(unit) => handleChangeVariantUnit(unit)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className={`p-0`}>
              <div className="text-right">
                <span className="ml-2">
                  <CurrencyShow value={product.price} />
                </span>
              </div>
            </div>
            <div className={`p-0 d-flex align-items-center`}>
              <div className="d-flex align-items-center">
                {!product.isTrackingInventory ? (
                  <span className="prod-tag pr-2 d-flex-center">
                    <Icon name="infinity" size="md" />
                  </span>
                ) : (
                  <p
                    className={`${product.quantity < 0 ? "text-danger" : ""} `}
                  >
                    <NumberShow value={product.quantity / ratio} />
                  </p>
                )}
                <p className="ml-2">
                  {intl.formatMessage({
                    id: "products.details.Khả dụng",
                    defaultMessage: "Khả dụng",
                  })}{" "}
                </p>
              </div>
              <div className="ml-2">
                {!product.isTrackingInventory ? (
                  <span className="icon-w-14 icon-secondary pr-2 d-flex-center">
                    {Assets.icons_svg.search_product_icon_disabled}
                  </span>
                ) : (
                  <Tooltip
                    placement="topRight"
                    title={intl.formatMessage({
                      id: "products.details.Xem chi tiết sản phẩm",
                      defaultMessage: "Xem chi tiết sản phẩm",
                    })}
                    overlayClassName="tooltip-custom"
                  >
                    <Link
                      to={`/products/${product.productId}/variants/${product.id}`}
                      target="_blank"
                      className="icon-w-14 pr-2 d-flex-center product-detail-icon icon-primary"
                    >
                      {logoChange}
                    </Link>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Button>
  );
};

RefundCartSearchItem.propTypes = {
  onClick: PropTypes.func,
  onOpenDetail: PropTypes.func,
  searchText: PropTypes.string,
  products: PropTypes.object,
  index: PropTypes.any,
  isShowUnit: PropTypes.bool,
};
