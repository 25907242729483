import React from "react";
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { ArrowPagination } from 'components';
import { ProductSearchItem } from "./ProductSearchItem";
import EmptyState from "component-v2/empty-state";
import LoadingCircle from "screens-v2/layouts/layouts/omni-loading-circle";

export const ProductSearchResult = (props) => {
  const intl = useIntl();
  const { isLoading, searchText, products } = props;
  const isShowUnit = products?.records?.some(product => !!product.variantUnitId);

  return (
    <>
      {
        isLoading ?
          <div className='cart-search-result-modal'>
            < LoadingCircle />
          </div >
          :
          <div className='cart-search-result-modal'>
            <div className="list-group scrollable-y">
              {products?.records?.length ?
              <div className="l-table align-top table-cart-list">
                {products.records.map((product, index) => {
                  return (
                    <ProductSearchItem key={product.id} product={product} index={index} isShowUnit={isShowUnit} {...props} onClick={props.onClick} />
                  );
                })}
              </div> : null
              }
              {products?.total >= 20 && (
                <ArrowPagination
                total={products.total}
                currentPage={products.currentPage}
                pageSize={20}
                onChange={props.onNextPage}
              />
              )}
            </div>

          </div>
      }
      {
        searchText.length > 0 && (!products || products.records?.length === 0) && !isLoading && <div className="cart-search-result-modal scrollable-y" style={{ height: "25.6rem" }}><EmptyState iconName="shopping_cart" description={intl.formatMessage({ id: 'carts.orders.Không tìm thấy sản phẩm nào', defaultMessage: 'Không tìm thấy sản phẩm nào' })} /></div>
      }
    </>
  );
};


ProductSearchResult.propTypes = {
  isLoading: PropTypes.bool,
  searchText: PropTypes.string,
  products: PropTypes.object,
  onClick: PropTypes.func
};