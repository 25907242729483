import { useApp } from "contexts/app";
import { dataLayerPush } from "utils";
import React, { useContext, useEffect, useState } from "react";

const context = React.createContext({
  breadcrumb: null,
  setBreadcrumb: (_breadcrumb) => {},
  setBackLink: (_back_link) => {},
  toast: null,
  showGlobalToast: (_type, _msg, _delay = TOAST_DELAY_DEFAULT) => {},
  menu_size: "sm",
  enlargeMenu: () => {},
  shrinkMenu: () => {},
});
// global toast types: 'default' | 'success' | 'info' | 'warning' | 'error'
// breadcrumb: example of order detail page: [{title: 'Order list', link: '/orders'}, {title: '#100234', link: null}]
function LayoutProvider({ children }) {
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [back_link, setBackLink] = useState(null);
  const [toast, setToast] = useState({
    show: false,
    type: "info",
    message: "",
  });
  const [menu_size, setMenuSize] = useState("sm");
  const { apiError, setApiError, auth } = useApp();

  useEffect(() => {
    if (apiError) {
      showGlobalToast("error", apiError);
      setApiError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  const setPageTitle = (title) => {
    const currentTitle = document.title;

    let pageTitle = "Haravan POS";
    if (auth.user) {
      pageTitle += ` - ${auth.user.orgName}`;
    }

    if (title) {
      pageTitle += ` - ${title}`;
    }

    document.title = pageTitle;

    if (currentTitle !== document.title) {
      dataLayerPush({
        event: "page_view",
      });
    }
  };

  const showGlobalToast = (type, msg, delay = TOAST_DELAY_DEFAULT) => {
    if (!type) type = "default";
    let _toast = { type: type, message: msg, show: true };
    setToast(_toast);
    const timer = setTimeout(() => {
      setToast({ ..._toast, show: false });
      clearTimeout(timer);
    }, delay);
    const _timer = setTimeout(() => {
      setToast({ message: "", show: false });
      clearTimeout(_timer);
    }, delay + 200);
  };
  function enlargeMenu() {
    setMenuSize("lg");
  }
  function shrinkMenu() {
    setMenuSize("sm");
  }

  return (
    <context.Provider
      value={{
        breadcrumb,
        setBreadcrumb,
        back_link,
        setBackLink,
        toast,
        showGlobalToast,
        menu_size,
        enlargeMenu,
        shrinkMenu,
        setPageTitle,
      }}
    >
      {children}
    </context.Provider>
  );
}
const useLayoutContext = () => {
  const _context = useContext(context);
  return _context;
};

export { useLayoutContext };
export default LayoutProvider;

export const TOAST_DELAY_DEFAULT = 3000;
