export const ShopPlanFunctionEnum = {
  // đơn hàng cơ bản
  order_standard: "order-standard",
  // đơn hàng chưa hoàn tất
  order_standard_abandoned: "order-abandoned",
  // đơn hàng nâng cao
  order_advance: "order-advance",
  // giao hàng
  fulfillment: "fulfillment",
  // tồn kho cơ bản
  inventory_standard: "inventory_standard",
  // tồn kho nâng cao
  inventory_advance: "inventory_advance",
  // báo cáo
  report_default: "report_default",
  report_default_revenue: "report_revenue",
  report_default_inventory: "report_inventory",
  report_default_website: "report_website",
  report_default_customer: "report_customer",
  report_default_custom: "report_custom",
  // apps
  app_common: "common_app",
  sales_page: "sales_page",
  // kênh loyalty
  loyalty: "haraloyalty",
  // google ads
  marketing_google: "google_smart_shopping",
  // app HaraAds
  marketing_app_ads: "app_haraads",
  // kênh website (haraweb)
  channel_website: "website",
  channel_website_domain: "website_domain",
  // kênh social
  channel_social: "social",
  // app messenger
  channel_app_messenger: "app_messenger",
  // các channel: Lazada, Tiki, Zalo, Shopee
  channel_common: "channel_common",
  // HaraRetail (POS)
  channel_retail_mobile: "retail_mobile",
  channel_retail_web: "retail_web",
  // HaraFunnel
  channel_funnel_pro: "funnel_pro",
  channel_funnel_unbrand: "funnel_unbrand",
  // Cấu hình chung
  setting_general: "setting_general",
  // Cấu hình Tài khoản
  setting_account: "setting_account",
  // Cấu hình Nhóm tài khoản
  setting_permissiongroup: "setting_permissiongroup",
  // Cấu hình Nhóm phân công
  setting_accountgroup: "setting_accountgroup",
  // Cấu hình Địa chỉ
  setting_location: "setting_location",
  // Cấu hình Vận chuyển
  setting_shipping: "setting_shipping",
  // Cấu hình Phương thức thanh toán
  setting_paymentmethod: "setting_paymentmethod",
  // Cấu hình Thanh toán
  setting_checkout: "setting_checkout",
  // Cấu hình Kênh bán hàng
  setting_saleschannels: "setting_saleschannels",
  // Cấu hình Files
  setting_file: "setting_file",
  // Cấu hình Thông báo
  setting_notificated: "setting_notificated",
  // Cấu hình Gói kinh doanh (Billing)
  setting_billing: "setting_billing",
};

export const BusinessProductCodeEnum = {
  // HaraRetail (expired / cancelled)
  haraRetail: "pcode_omni_retail",
  // HaraFunnel
  haraFunnel: "pcode_omni_funnel",
  // Standard Social Commerce (tên cũ: Conversation Commerce)
  conversationCommerce: "pcode_omni_conversation_commerce",
  // Advanced Web (tên cũ: Shopping Web)
  advancedWeb: "pcode_omni_web_shopping",
  // Social Commerce (stopped: ngừng kinh doanh)
  socialCommerce: "pcode_omni_social_commerce",
  // Business Web
  businessWeb: "pcode_omni_business_web",
  // Omnichannel (gói Trial)
  omniChannel: "pcode_omni_channel",
  // Advanced Omnichannel (Fulfillment Network)
  omniChannelAdvanced: "pcode_omni_channel_plus",
  // Affiliate
  affiliate: "pcode_omni_affiliate",
  // Combo Advanced Web & Social Commerce
  comboWebSocial: "pcode_omni_combo_web_social",
  // Social Commerce 2020, Social Commerce Advance
  socialCommerceAdvanced: "pcode_omni_social_commerce2020",
  // CÁC GÓI MỚI TỪ 04-2020
  // START
  START: "pcode_omni_start_042020",
  // PRO
  PRO: "pcode_omni_pro_042020",
  // GROW
  GROW: "pcode_omni_grow_042020",
  // SCALE
  SCALE: "pcode_omni_scale_042020",
  // BUSINESS
  BUSINESS: "pcode_omni_business_web_062021",
  // CÁC GÓI MỚI TỪ 09-2021
  // START
  STANDARD_092021: "pcode_omni_standard_092021",
  // PRO
  PRO_092021: "pcode_omni_pro_092021",
  // GROW
  GROW_092021: "pcode_omni_grow_092021",
  // SCALE
  SCALE_092021: "pcode_omni_scale_092021",
  // CÁC GÓI MỚI TỪ 05-2022
  // START
  STANDARD_PROVINCE_2022: "pcode_omni_standard_province_052022",
  // CÁC GÓI MỚI TỪ 02-2024
  // START
  STANDARD_022024: "pcode_omni_standard_022024",
  // PRO
  PRO_022024: "pcode_omni_pro_022024",
  // ADVANCED
  ADVANCED_022024: "pcode_omni_advanced_022024",
  // GROW
  GROW_022024: "pcode_omni_grow_022024",
  //SCALE
  SCALE_022024: "pcode_omni_scale_022024",
  //SCALE
  SCALE_022024_ENTERPRISE: "pcode_omni_scale_022024_enterprise",
  // Expired Shop Plan (chỉ đặt cho client)
  __EXPIRED: "__EXPIRED",
};

export const ReferrenceBusinessProductCodes = [
  { code: BusinessProductCodeEnum.haraRetail, refCodes: "pcode_omni_retail" },
  {
    code: BusinessProductCodeEnum.conversationCommerce,
    refCodes: "pcode_omni_conversation_commerce,pcode_engage_harasocialpro2019",
  },
  {
    code: BusinessProductCodeEnum.advancedWeb,
    refCodes: "pcode_omni_web_shopping",
  },
  {
    code: BusinessProductCodeEnum.socialCommerce,
    refCodes: "pcode_omni_social_commerce",
  },
  {
    code: BusinessProductCodeEnum.businessWeb,
    refCodes:
      "pcode_omni_business_web,pcode_omni_web_biz,pcode_omni_businessprofessional,pcode_omni_businesswebsitestandard,pcode_omni_biz_yearly",
  },
  {
    code: BusinessProductCodeEnum.omniChannel,
    refCodes:
      "pcode_omni_channel,pcode_omni_web_pro,pcode_web_pro,pcode_omni_web_adv,pcode_omni_common,pcode_omni_unlimited,pcode_omni_oldprofessional,pcode_omni_fbcommon,pcode_omni_fbprofessional,pcode_omni_fbunlimited,pcode_omni_combo,pcode_omni_fbcombo,pcode_omni_businessbasic,pcode_omni_saleonwebsitebasic,pcode_omni_saleonwebsiteprofessional,pcode_omni_fbpersonal,pcode_omni_smallshop,pcode_omni_bigshop,pcode_omni_fbcombowebsiteharapage2016,pcode_omni_fbcomboretail,pcode_omni_comboretail2016,pcode_omni_fbbrandplus23,pcode_omni_businesswebsiteadvance,pcode_omni_ecommercestandard,pcode_omni_ecommerceprofessional,pcode_omni_pro_yearly,plan_omni_free_yearly_disable,pcode_omni_free",
  },
  {
    code: BusinessProductCodeEnum.omniChannelAdvanced,
    refCodes:
      "pcode_omni_channel_plus,pcode_omni_preminum,pcode_omni_comboomnichannel1store,pcode_omni_comboomnichannel2store,pcode_omni_comboomnichannel5store,pcode_omni_ecommerceadvance,pcode_omni_omnichannelstandard,pcode_omni_adv_yearly,pcode_omni_omnichanneladvance2019,pcode_omni_web_preminum,pcode_omni_comboomnichannel10store,pcode_omni_omnichannelprofessional",
  },
  { code: BusinessProductCodeEnum.affiliate, refCodes: "pcode_omni_affiliate" },
  {
    code: BusinessProductCodeEnum.comboWebSocial,
    refCodes: "pcode_omni_combo_web_social",
  },
  {
    code: BusinessProductCodeEnum.socialCommerceAdvanced,
    refCodes: "pcode_omni_social_commerce2020",
  },
  { code: BusinessProductCodeEnum.START, refCodes: "pcode_omni_start_042020" },
  { code: BusinessProductCodeEnum.PRO, refCodes: "pcode_omni_pro_042020" },
  { code: BusinessProductCodeEnum.GROW, refCodes: "pcode_omni_grow_042020" },
  { code: BusinessProductCodeEnum.SCALE, refCodes: "pcode_omni_scale_042020" },
  {
    code: BusinessProductCodeEnum.BUSINESS,
    refCodes: "pcode_omni_business_web_062021",
  },
  {
    code: BusinessProductCodeEnum.STANDARD_092021,
    refCodes: BusinessProductCodeEnum.STANDARD_092021,
  },
  {
    code: BusinessProductCodeEnum.PRO_092021,
    refCodes: BusinessProductCodeEnum.PRO_092021,
  },
  {
    code: BusinessProductCodeEnum.GROW_092021,
    refCodes: BusinessProductCodeEnum.GROW_092021,
  },
  {
    code: BusinessProductCodeEnum.SCALE_092021,
    refCodes: BusinessProductCodeEnum.SCALE_092021,
  },
  {
    code: BusinessProductCodeEnum.STANDARD_PROVINCE_2022,
    refCodes: BusinessProductCodeEnum.STANDARD_PROVINCE_2022,
  },
  {
    code: BusinessProductCodeEnum.STANDARD_022024,
    refCodes: BusinessProductCodeEnum.STANDARD_022024,
  },
  {
    code: BusinessProductCodeEnum.SCALE_022024,
    refCodes: BusinessProductCodeEnum.SCALE_022024,
  },
  {
    code: BusinessProductCodeEnum.GROW_022024,
    refCodes: BusinessProductCodeEnum.GROW_022024,
  },
  {
    code: BusinessProductCodeEnum.ADVANCED_022024,
    refCodes: BusinessProductCodeEnum.ADVANCED_022024,
  },
  {
    code: BusinessProductCodeEnum.PRO_022024,
    refCodes: BusinessProductCodeEnum.PRO_022024,
  },
  {
    code: BusinessProductCodeEnum.SCALE_022024_ENTERPRISE,
    refCodes: BusinessProductCodeEnum.SCALE_022024_ENTERPRISE,
  },
];

export const ShopPlanSupportedFunctions = [
  // order
  {
    function: ShopPlanFunctionEnum.order_standard,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.order_standard_abandoned,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.order_advance,
    supported_shopplans:
      "pcode_omni_scale_092021,pcode_omni_scale_042020,pcode_omni_channel_plus",
  },
  // fulfillment
  {
    function: ShopPlanFunctionEnum.fulfillment,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  // inventory
  {
    function: ShopPlanFunctionEnum.inventory_standard,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.inventory_advance,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  // report
  {
    function: ShopPlanFunctionEnum.report_default,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.report_default_revenue,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.report_default_inventory,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.report_default_website,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  {
    function: ShopPlanFunctionEnum.report_default_customer,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  {
    function: ShopPlanFunctionEnum.report_default_custom,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  // apps
  {
    function: ShopPlanFunctionEnum.app_common,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  // sales page
  {
    function: ShopPlanFunctionEnum.sales_page,
    supported_shopplans:
      "pcode_omni_standard_province_052022,pcode_omni_standard_092021,__EXPIRED",
  },
  // loyalty
  {
    function: ShopPlanFunctionEnum.loyalty,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  // marketing
  {
    function: ShopPlanFunctionEnum.marketing_google,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  {
    function: ShopPlanFunctionEnum.marketing_app_ads,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  // website
  {
    function: ShopPlanFunctionEnum.channel_website,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.channel_website_domain,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_combo_web_social,__EXPIRED",
  },
  // social
  {
    function: ShopPlanFunctionEnum.channel_social,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_conversation_commerce,pcode_omni_social_commerce,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  //messenger
  {
    function: ShopPlanFunctionEnum.channel_app_messenger,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  // sales channels
  {
    function: ShopPlanFunctionEnum.channel_common,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_channel,pcode_omni_channel_plus,__EXPIRED",
  },
  // retail
  {
    function: ShopPlanFunctionEnum.channel_retail_mobile,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  {
    function: ShopPlanFunctionEnum.channel_retail_web,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  // funnel
  {
    function: ShopPlanFunctionEnum.channel_funnel_pro,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  {
    function: ShopPlanFunctionEnum.channel_funnel_unbrand,
    supported_shopplans:
      "pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  // setting
  {
    function: ShopPlanFunctionEnum.setting_general,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_account,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_permissiongroup,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_accountgroup,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_location,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_shipping,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_paymentmethod,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  {
    function: ShopPlanFunctionEnum.setting_checkout,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020",
  },
  {
    function: ShopPlanFunctionEnum.setting_saleschannels,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_file,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_notificated,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
  {
    function: ShopPlanFunctionEnum.setting_billing,
    supported_shopplans:
      "pcode_omni_pro_022024,pcode_omni_advanced_022024,pcode_omni_grow_022024,pcode_omni_scale_022024,pcode_omni_standard_province_052022,pcode_omni_standard_092021,pcode_omni_pro_092021,pcode_omni_grow_092021,pcode_omni_scale_092021,pcode_omni_start_042020,pcode_omni_pro_042020,pcode_omni_grow_042020,pcode_omni_scale_042020,pcode_omni_business_web_062021,pcode_omni_retail,pcode_omni_conversation_commerce,pcode_omni_web_shopping,pcode_omni_social_commerce,pcode_omni_business_web,pcode_omni_channel,pcode_omni_channel_plus,pcode_omni_affiliate,pcode_omni_combo_web_social,pcode_omni_social_commerce2020,__EXPIRED",
  },
];
