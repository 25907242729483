import "moment-timezone";
import { forEach } from "lodash";
import { ROLES } from "../../../models/constants";
import {
  DateTimeShowType,
  toFormattedDate,
} from "infrastructures/format-helpers";
import { getPrintTemplateOrderNew } from "./print-tempate-order-new";
import { getPrintTemplateRefund } from "./print-template-refund";
import { getPrintingTemplateTempBill } from "./print-tempate-temp-bill";
import { getPrintTemplateQRCode } from "./print-tempate-qr-code";
import { getPrintTemplateCustomHTML } from "./print-template-html";

export const TemplateType = {
  TEMP_BILL: "TEMP_BILL",
  QR_CODE: "QR_CODE",
  CUSTOM_HTML: 1,
  CUSTOM_HTML_ORDER_DETAIL: 4,
  CUSTOM_HTML_REFUND: 3,
  TEMP_BILL_HTML: 2,
};
export const printSettingsDefault = {
  printType: "default",
  showLocation: true,
  showLogo: true,
  showImage: false,
  acknowledgements: "Thank You!",
  barcode: true,
  billDay: true,
  shopLogoUrl: null,
  shopImageUrl: null,
  cashPayCustomer: true,
  customerAdress: true,
  customerEmail: true,
  customerName: true,
  customerPhone: true,
  customerPayingAmt: true,
  totalToPay: false,
  totalPaid: true,
  discount: true,
  nameSalesMan: true,
  note: true,
  orderNumber: true,
  price: true,
  productName: true,
  productType: true,
  quantity: true,
  shipPrice: true,
  isVariant: true,
  shopAddress: true,
  shopEmail: true,
  shopName: true,
  shopPhone: true,
  shopWebsite: true,
  websiteUrl: "--",
  skuCode: true,
  totalPrice: true,
  totalQuantity: true,
  unitPrice: true,
  barcodeVariant: false,
  paymentGateway: false,
  showAcknowledgements: true,
  showLevelNameLoyalty: false,
  showAmountPaymentLoyalty: false,
  showPointMember: false,
  showCustomerInfo: true,
};
/**
 * IN HÓA ĐƠN: In nhiệt
 */
export async function getPrintingTemplate(
  appContext,
  order,
  barcode,
  refundOrderModel,
  isNewOrder,
  totalRefund,
  locationRoles,
  locationRefund,
  dataMember,
  qrcodeSvg,
  isOrderPrintBill,
  qrcodeVAT,
  templateType,
) {
  const locale = JSON.parse(localStorage.getItem("rtl-locale-lang"))?.value;
  let printSettingsAPI =
    appContext.shop_setting && appContext.shop_setting.printSettings
      ? appContext.shop_setting.printSettings
      : printSettingsDefault;
  let shopLogoUrl =
    appContext.shop_setting && appContext.shop_setting.shopLogoUrl
      ? appContext.shop_setting.shopLogoUrl
      : "";
  let shopImageUrl =
    appContext.shop_setting && appContext.shop_setting.shopImageUrl
      ? appContext.shop_setting.shopImageUrl
      : "";
  let taxable = appContext.shop_info && appContext.shop_info.taxable;
  let printSettings = null;
  let renderAmountTransaction = isOrderPrintBill ? false : true;
  if (printSettingsAPI.printType === "default") {
    const settingsDefault = {
      ...printSettingsDefault,
      printType: "default",
      showLogo: printSettingsAPI.showLogo,
      showImage: false,
      acknowledgements: printSettingsAPI.acknowledgements,
      shopLogoUrl: shopLogoUrl || null,
      shopImageUrl: shopImageUrl || null,
      shopWebsite: printSettingsAPI.shopWebsite,
      websiteUrl: printSettingsAPI.websiteUrl,
      showAcknowledgements: printSettingsAPI.showAcknowledgements,
    };
    printSettings = settingsDefault;
  } else {
    printSettings = printSettingsAPI;
  }
  let location = {};
  const lstLocations = appContext.shop_locations;
  if (locationRefund) {
    location = locationRefund;
  } else if (lstLocations && lstLocations.length) {
    forEach(lstLocations, async (item) => {
      if (locationRoles) {
        location = locationRoles;
      } else if (item.id === order.locationId) {
        location = item;
      }
    });
  } else {
    const currentLocation = appContext.current_location;
    if (order.locationId === currentLocation.id) {
      location = currentLocation;
    } else {
      location = {
        locationName: order.locationName,
      };
    }
  }
  let totalQtt = 0;
  order.orderProducts?.map((product) => {
    totalQtt += product.quantity;
  });
  let orderDate = toFormattedDate(order.orderDate, DateTimeShowType.date_time);
  if (barcode && barcode.outerHTML) {
    var barcodeRender = barcode.outerHTML;
  }

  const shopAddress = [
    location.street,
    location.wardName,
    location.districtName,
    location.provinceName,
  ]
    .filter(Boolean)
    .join(", ");

  let template = "";
  switch (templateType) {
    case TemplateType.TEMP_BILL:
      template = getPrintingTemplateTempBill({
        printSettings,
        barcodeRender,
        location,
        order,
        locale,
        shopLogoUrl,
        shopImageUrl,
        shopAddress,
        orderDate,
        totalQtt,
        taxable,
        appContext,
        dataMember,
        renderAmountTransaction,
        qrcodeVAT,
        qrcodeSvg,
      });
      break;
    case TemplateType.QR_CODE:
      template = getPrintTemplateQRCode({
        printSettings,
        order,
        locale,
        shopLogoUrl,
        qrcodeSvg,
      });
      break;
    case TemplateType.TEMP_BILL_HTML:
    case TemplateType.CUSTOM_HTML:
    case TemplateType.CUSTOM_HTML_ORDER_DETAIL:
    case TemplateType.CUSTOM_HTML_REFUND:
      template = await getPrintTemplateCustomHTML({
        printSettings,
        appContext,
        location,
        order,
        isNewOrder,
        refundOrderModel,
        totalRefund,
        shopLogoUrl,
        shopImageUrl,
        shopAddress,
        totalQtt,
        taxable,
        typeId: templateType,
      });
      break;
    default:
      template = refundOrderModel
        ? getPrintTemplateRefund({
            printSettings,
            barcodeRender,
            location,
            order,
            locale,
            shopLogoUrl,
            shopImageUrl,
            shopAddress,
            orderDate,
            totalQtt,
            taxable,
            appContext,
            dataMember,
            renderAmountTransaction,
            qrcodeVAT,
            qrcodeSvg,
            isNewOrder,
            refundOrderModel,
            totalRefund,
          })
        : getPrintTemplateOrderNew({
            printSettings,
            barcodeRender,
            location,
            order,
            locale,
            shopLogoUrl,
            shopImageUrl,
            shopAddress,
            orderDate,
            totalQtt,
            taxable,
            appContext,
            dataMember,
            renderAmountTransaction,
            qrcodeVAT,
            qrcodeSvg,
          });
      break;
  }

  return template;
}
export const printOrder = async (
  appContext,
  order,
  barcode,
  refundOrderModel,
  isNewOrder,
  totalRefund,
  locationRefund,
  dataMember,
  qrcodeSvg,
  isOrderPrintBill,
  qrcodeVAT,
  templateType,
) => {
  let printLocationRoles = null;
  if (
    appContext.checkRoleAllow(ROLES.API_LOCATION_READ) ||
    appContext.checkRoleAllow(ROLES.API_LOCATION_WRITE)
  ) {
    if (!(printLocationRoles && printLocationRoles.id === order.locationId)) {
      try {
        let result = await appContext.callAPI(
          "get",
          `/call/com_api/locations/${order.locationId}`,
        );
        if (result && !result.errors) {
          printLocationRoles = result.data;
        }
      } catch (error) {}
    }
  }
  let print_template = await getPrintingTemplate(
    appContext,
    order,
    barcode,
    refundOrderModel,
    isNewOrder,
    totalRefund,
    printLocationRoles,
    locationRefund,
    dataMember,
    qrcodeSvg,
    isOrderPrintBill,
    qrcodeVAT,
    templateType,
  );
  var html = `<!DOCTYPE html>
         <html lang="en">
             <head>
                 <meta charset="utf-8" />
                 <title>Haravan</title>
                 <meta name="viewport" content="width=device-width, initial-scale=1.0" />
             </head>
             <body onload="window.print();window.close()">${print_template}</body>
         </html>`;
  return html;
};
