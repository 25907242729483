import { Input, Popover, Tooltip } from "@haravan/react-components";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { useEffect, useRef, useState } from "react";

export const PaymentAuthCode = ({
  code,
  customCss = null,
  onUpdate = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(code);
  const refInput = useRef(null);

  useEffect(() => {
    if (isOpen) {
      refInput.current?.onSetFocus?.();
      setValue(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOk = () => {
    onUpdate(value?.trim());
    setIsOpen(false);
  };

  const content = (
    <div className="p-4">
      <label className="font-weight-medium l-mb-8">Mã chuẩn chi</label>
      <div className="d-flex justify-content-between align-items-center">
        <Input
          autoFocus
          ref={refInput}
          value={value}
          placeholder="Nhập mã chuẩn chi"
          className="l-mr-8"
          onChange={(value) => setValue(value)}
          onFocus={(_, e) => e?.target?.select?.()}
        />
        <div className="flex-auto">
          <Button
            status="primary"
            onClick={handleOk}
            className="min-w-0"
            title="Lưu"
          />
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      onVisibleChange={(visible) => setIsOpen(visible)}
      placement="bottom"
      visible={isOpen}
      content={content}
    >
      <Tooltip
        placement="right"
        trigger="hover"
        overlay={
          <div>
            <p className="content-on-color-primary font-size-12 lh-18 mb-0">
              Mã chuẩn chi
            </p>
            {code && (
              <p className="content-tertiary font-size-12 lh-18 mb-0 l-mt-4">
                {code}
              </p>
            )}
          </div>
        }
      >
        <div
          className={
            `${customCss ? customCss : "l-ml-8 auth-code-btn"}` +
            `${isOpen ? "control--focused" : ""}`
          }
        >
          <Icon name="edit" size="md" className="icon-size--24" />
          {code?.length > 0 && (
            <span className="icon-check-mark">
              <Icon name="check_mark" size="xs" />
            </span>
          )}
        </div>
      </Tooltip>
    </Popover>
  );
};
