export async function postFeedback(appContext, feedback) {
  const response = await appContext.callAPI(
    "post",
    `/call/com_api/feedback`,
    feedback
  );
  return response;
}

export async function checkRefundPointSuccess(appContext, orderId, refundId) {
  const result = await appContext.callAPI(
    "get",
    `/call/loy_api/v1/orders/${orderId}/point_histories?refund_id=${refundId}`
  );
  if (result?.data) {
    return result.data.point_refunded;
  }

  return 0;
}

export const refundPointManually = async (appContext, pointModel) => {
  const result = await appContext.callAPI(
    "post",
    `/call/loy_api/v1/redeems/update_refund_point_usage`,
    pointModel
  );
  if (result?.status === 1) {
    return result.status;
  } else {
    return result.message || result.message_text;
  }
};

export const signalRConnection = async (appContext, locationId) => {
  return await appContext.callAPI(
    "post",
    `/call/retail_api/${locationId}/notify`
  );
};

export const fetchSessionReturn = async (callAPI) => {
  return await callAPI("post", `/call/retail_api/fetch_session`);
};
