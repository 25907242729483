import { toFormattedCurrency } from "infrastructures/format-helpers";
import { useEffect, useState } from "react";
const NEGATIVE_CLASS = " neg-number";
const POSITIVE_CLASS = " pos-number";
export default function CurrencyShow({ value, colored = false, className }) {
  const [formatted, setFormatted] = useState(value);
  const [colored_class, setCorloredClass] = useState("");
  useEffect(() => {
    if (isNaN(value)) {
      setFormatted("");
      setCorloredClass("");
    } else {
      setFormatted(toFormattedCurrency(value));
      if (colored) {
        if (value < 0) setCorloredClass(NEGATIVE_CLASS);
        if (value > 0) setCorloredClass(POSITIVE_CLASS);
        if (value === 0) setCorloredClass("");
      }
    }
  }, [value, colored]);
  return (
    <span
      className={`currency-show${colored_class}${className ? " " + className : ""}`}
    >
      {formatted}
    </span>
  );
}
