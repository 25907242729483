export const OrderFilterStatuses = [
  { id: 0, name: "Tất cả đơn hàng" },
  { id: 1, name: "Chờ thanh toán", financialStatus: 'unpaid' },
  { id: 2, name: "Thanh toán 1 phần", financialStatus: 'partially_paid' },
  { id: 3, name: "Chờ xử lý", financialStatus: 'pending' },
  { id: 4, name: "Chờ lấy hàng", shipmentStatus: 'ready_to_pick' },
  { id: 5, name: "Đã giao hàng", shipmentStatus: 'delivered' },
  { id: 6, name: "Đã hoàn trả", financialStatus: 'refunded' },
  { id: 7, name: "Hủy", orderStatus: 'cancelled' },
  { id: 8, name: "Lỗi giao hàng", shipmentStatus: 'failed' },
  { id: 9, name: "Lấy hàng thất bại", shipmentStatus: 'pickup_fail' }
]

const paymentStatusModel = [
  {
    id: 1,
    contentValue: 'Đã xác thực',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 2,
    contentValue: 'Đã thanh toán',
    badgeValue: 'green',
    colorValue: 'green'
  },
  {
    id: 3,
    contentValue: 'Đã nhập trả một phần',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 4,
    contentValue: 'Thanh toán một phần',
    badgeValue: 'blue',
    colorValue: 'blue'
  },
  {
    id: 5,
    contentValue: 'Chờ xử lý',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 6,
    contentValue: 'Đã nhập trả',
    badgeValue: 'gray',
    colorValue: 'gray'
  },
  {
    id: 7,
    contentValue: 'Chờ thanh toán',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 8,
    contentValue: 'Đã hủy',
    badgeValue: 'red',
    colorValue: 'red'
  },
]

const shipmentStatusModel = [
  {
    id: 1,
    contentValue: 'Chờ lấy hàng',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 2,
    contentValue: 'Đang đi lấy',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 3,
    contentValue: 'Đang giao hàng',
    badgeValue: 'blue',
    colorValue: 'blue'
  },
  {
    id: 4,
    contentValue: 'Đã giao hàng',
    badgeValue: 'green',
    colorValue: 'green'
  },
  {
    id: 5,
    contentValue: 'Hủy giao hàng',
    badgeValue: 'red',
    colorValue: 'red'
  },
  {
    id: 6,
    contentValue: 'Đã chuyển hoàn',
    badgeValue: 'gray',
    colorValue: 'gray'
  },
  {
    id: 7,
    contentValue: 'Chờ xử lý',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 8,
    contentValue: 'Không gặp khách',
    badgeValue: 'red',
    colorValue: 'red'
  },
  {
    id: 9,
    contentValue: 'Chờ chuyển hoàn',
    badgeValue: 'warning',
    colorValue: 'orange'
  },
  {
    id: 12,
    contentValue: 'Lấy hàng thất bại',
    badgeValue: 'red',
    colorValue: 'red'
  },
]

const fulfilledStatusModel = [
  {
    id: 1,
    contentValue: 'Đang xử lý',
    badgeValue: 'blue',
    colorValue: 'blue'
  },
  {
    id: 2,
    contentValue: 'Thành công',
    badgeValue: 'green',
    colorValue: 'green'
  },
  {
    id: 3,
    contentValue: 'Hủy',
    badgeValue: 'red',
    colorValue: 'red'
  },
  {
    id: 4,
    contentValue: 'Lỗi giao hàng',
    badgeValue: 'red',
    colorValue: 'red'
  },
  {
    id: 5,
    contentValue: 'Thất bại',
    badgeValue: 'red',
    colorValue: 'red'
  },
]

const fulfilledStatusEnum = {
  pending: 1,
  success: 2,
  cancelled: 3,
  error: 4,
  failure: 5
}

export const paymentStatusValue = (paymentId) => {
  const matchPaymentValue = paymentStatusModel.find(item => item.id === paymentId);
  return { ...matchPaymentValue }
}

export const shipmentStatusValue = (shipmentId, fulfilledStatus) => {
  let matchShipmentValue = null;
  if (fulfilledStatus && fulfilledStatus === fulfilledStatusEnum.error) {
    matchShipmentValue = fulfilledStatusModel.find(x => x.id === fulfilledStatus);
    if (matchShipmentValue) {
      return {...matchShipmentValue}
    }
  }
  matchShipmentValue = shipmentStatusModel.find(item => item.id === shipmentId);
  return { ...matchShipmentValue }
}

export const taxStatusValue = (statusId) => {
  const matchTaxValue = TaxCreateStatus.find(item => item.id === statusId);
  return { ...matchTaxValue }
}

export const actionVAT = {
  draft: {
    id: 1,
    title: "Xem bản nháp"
  },
  detailMultiVAT: {
    id: 2,
    title: "Thông tin chi tiết hóa đơn"
  },
  detailVAT: {
    id: 3,
    title: "Xem hóa đơn"
  }
}

export const TaxCreateStatus = [
  {
    id: 0,
    code: "vat_notyet",
    name: "Chưa tạo hóa đơn",
    title: "Chưa tạo hóa đơn",
    badgeValue: 'warning',
    colorValue: 'orange',
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 1,
    code: "vat_create_pending",
    name: "Đang xử lý",
    title: "Đang xử lý",
    badgeValue: 'blue',
    colorValue: 'blue',
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 2,
    code: "vat_create_error",
    name: "Tạo hóa đơn lỗi",
    title: "Tạo hóa đơn lỗi",
    badgeValue: "red",
    colorValue: "red",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 3,
    code: "vat_wait_release",
    name: "Chờ phát hành",
    title: "Chờ phát hành",
    badgeValue: "blue",
    colorValue: "blue",
    action: [
      {
        id: actionVAT.draft.id,
        title: actionVAT.draft.title
      },
    ]
  },
  {
    id: 4,
    code: "vat_publishing",
    name: "Đang phát hành",
    title: "Đang phát hành",
    badgeValue: "blue",
    colorValue: "blue",
    action: [
      {
        id: actionVAT.draft.id,
        title: actionVAT.draft.title
      },
    ]
  },
  {
    id: 5,
    code: "vat_publish_error",
    name: "Phát hành lỗi",
    title: "Phát hành lỗi",
    badgeValue: "red",
    colorValue: "red",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 6, code: "vat_published",
    name: "Đã phát hành",
    title: "Đã phát hành",
    badgeValue: "green",
    colorValue: "green",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 7,
    code: "vat_publish_cancel",
    name: "Hủy",
    title: "Hủy",
    badgeValue: "red",
    colorValue: "red",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 8,
    code: 'vat_create_pending',
    name: 'Đang tạo hóa đơn',
    title: 'Đang tạo hóa đơn',
    badgeValue: 'warning',
    colorValue: 'orange',
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 9,
    code: 'vat_published',
    name: 'Đã phát hành',
    title: 'Đã phát hành',
    badgeValue: "blue",
    colorValue: "blue",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 10,
    code: 'vat_waiting_approve',
    name: 'Chờ cơ quan thuế chấp nhận',
    title: 'Chờ cơ quan thuế chấp nhận',
    badgeValue: "blue",
    colorValue: "blue",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 11,
    code: 'vat_approved',
    name: 'Cơ quan thuế duyệt',
    title: 'Cơ quan thuế duyệt',
    badgeValue: "blue",
    colorValue: "blue",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
  {
    id: 12,
    code: 'vat_denined',
    name: 'Cơ quan thuế từ chối',
    title: 'Cơ quan thuế từ chối',
    badgeValue: "blue",
    colorValue: "blue",
    action: [
      {
        id: actionVAT.detailVAT.id,
        title: actionVAT.detailVAT.title
      },
      {
        id: actionVAT.detailMultiVAT.id,
        title: actionVAT.detailMultiVAT.title
      }
    ]
  },
];


export const autoGenerateVAT = true;



/* 
phát hành tự động:
+ check yêu cầu xuất hđ => tự động phát hành và có hóa đơn với thông tin nhập vô
+ không check => lấy thông tin customer mặc định làm thông tin hóa đơn và phát hành

không phát hành tự động: 
+ check yêu cầu xuất hđ: lấy thông tin nhập lúc yêu cầu đưa vào thông tin billing của ĐƠN HÀNG, không phát hành hoá đơn (cho sửa ở detail)
+ không check: lấy thông tin ‰

*/