import { useEffect, useState } from "react";
import { Assets } from "assets";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { LayoutCardHeader } from "screens-v2/layouts/layouts/omni-layout-card";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";
import { InputDatetimePicker } from "@haravan/react-components";

export default function ReportHeader({
  globalLoading,
  label,
  dateRange,
  setDateRange,
  channel,
  setChannel,
  setStaff,
}) {
  const intl = useIntl();
  const appContext = useApp();
  const { formatMessage } = intl;
  const [reinit, setReinit] = useState(false);
  const { staff_users } = useApp();
  const [selectedStaff, setSelectedStaff] = useState({
    id: null,
    name: "Tất cả nhân viên",
  });
  const [initStaff, setInitStaff] = useState([
    {
      email: null,
      id: null,
      name: "Tất cả nhân viên",
      phone: null,
    },
  ]);
  const [initChannel] = useState([
    {
      id: null,
      name: formatMessage({
        id: "reports.Tất cả các kênh",
        defaultMessage: "Tất cả các kênh",
      }),
    },
    {
      id: "channelPOS",
      name: formatMessage({
        id: "reports.Kênh POS",
        defaultMessage: "Kênh POS",
      }),
    },
    {
      id: "channelOnline",
      name: formatMessage({
        id: "reports.Kênh Online",
        defaultMessage: "Kênh Online",
      }),
    },
  ]);

  useEffect(() => {
    if (!!staff_users?.length) {
      setInitStaff((prev) => [...prev, ...staff_users]);
    }
  }, [staff_users]);

  const getListDate = {
    //Hôm nay
    startToDay: moment().startOf("days"),
    endToDay: moment().endOf("days"),

    // Hôm qua
    startYesterDay: moment().subtract(1, "days").startOf("days"),
    endYesterDay: moment().subtract(1, "days").endOf("days"),

    // Tuần này
    startWeekDay: moment().startOf("week"),
    endWeekDay: moment().endOf("days"),

    // 7 ngày trước
    start7Day: moment().subtract(6, "days").startOf("days"),
    end7Day: moment().endOf("days"),

    // 30 ngày trước
    start30Day: moment().subtract(29, "days").startOf("days"),
    end30Day: moment().endOf("days"),

    // Tháng này
    startThisMonth: moment().startOf("month"),
    endThisMonth: moment().endOf("days"),

    // Tháng trước
    startLastMonth: moment().subtract(1, "month").startOf("month"),
    endLastMonth: moment().subtract(1, "month").endOf("month"),
  };
  const DATE_RANGES_DEFAULT = {
    [formatMessage({
      id: "reports.Hôm nay",
      defaultMessage: "Hôm nay",
    })]: [getListDate.startToDay, getListDate.endToDay],
    [formatMessage({
      id: "reports.Hôm qua",
      defaultMessage: "Hôm qua",
    })]: [getListDate.startYesterDay, getListDate.endYesterDay],
    [formatMessage({
      id: "reports.7 ngày trước",
      defaultMessage: "7 ngày trước",
    })]: [getListDate.start7Day, getListDate.end7Day],
    [formatMessage({
      id: "reports.30 ngày trước",
      defaultMessage: "30 ngày trước",
    })]: [getListDate.start30Day, getListDate.end30Day],
    [formatMessage({
      id: "reports.Tháng này",
      defaultMessage: "Tháng này",
    })]: [getListDate.startThisMonth, getListDate.endThisMonth],
    [formatMessage({
      id: "reports.Tháng trước",
      defaultMessage: "Tháng trước",
    })]: [getListDate.startLastMonth, getListDate.endLastMonth],
  };
  const currentLocation = appContext.current_location || {};

  useEffect(() => {
    setReinit(true);
    setDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
    setTimeout(() => {
      setReinit(false);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.locale]);

  return (
    <div className="report__header">
      <div
        className="flex-auto"
        style={{ display: "flex", alignItems: "center" }}
      >
        <LayoutCardHeader
          className="p-0"
          title={intl.formatMessage({
            id: "reports.Báo cáo bán hàng",
            defaultMessage: "Báo cáo bán hàng",
          })}
        />
      </div>
      <div className="flex-stretch l-ml-16">
        <div
          className="d-flex align-items-center location-info"
          style={{
            width: "fit-content",
            maxWidth: "45.5rem",
            marginLeft: "auto",
          }}
        >
          <Icon size="2" name="storefront" />
          <span className="ml-2 text-ellipsis">
            {currentLocation?.locationName}
          </span>
        </div>
      </div>
      <div className="flex-auto l-ml-16" style={{ minWidth: "25rem" }}>
        <SelectionCustom
          options={initStaff}
          idField="id"
          valueField="name"
          disabled={globalLoading}
          value={selectedStaff?.id}
          style={{ background: "#FFF" }}
          onChange={(value) => {
            const exist = initStaff.find((item) => item.id === value);
            setSelectedStaff(exist);
            setStaff(exist);
          }}
        >
          {initStaff?.map((u) => {
            return (
              <SelectionCustom.Option key={u.id} id={u.id} value={u.id}>
                {u.name}
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
      </div>
      <div className="flex-auto l-ml-16" style={{ minWidth: "25rem" }}>
        <SelectionCustom
          options={initChannel}
          idField={"id"}
          valueField={"name"}
          disabled={globalLoading}
          value={channel}
          style={{ background: "#FFF" }}
          onChange={(value) => setChannel(value)}
        >
          <SelectionCustom.Option value={null}>
            <FormattedMessage
              id="reports.Tất cả các kênh"
              defaultMessage="Tất cả các kênh"
            />
          </SelectionCustom.Option>
          <SelectionCustom.Option value={"channelPOS"}>
            <FormattedMessage id="reports.Kênh POS" defaultMessage="Kênh POS" />
          </SelectionCustom.Option>
          <SelectionCustom.Option value={"channelOnline"}>
            <FormattedMessage
              id="reports.Kênh Online"
              defaultMessage="Kênh Online"
            />
          </SelectionCustom.Option>
        </SelectionCustom>
      </div>
      <div className="flex-auto l-ml-16" style={{ minWidth: "25rem" }}>
        <div style={{ width: "100%", marginLeft: "auto" }}>
          {reinit ? null : (
            <InputDatetimePicker
              ranges={DATE_RANGES_DEFAULT}
              locale={{
                format: "DD/MM/YYYY",
                separator: " - ",
                applyLabel: formatMessage({
                  id: "carts.orders.Xác nhận",
                  defaultMessage: "Xác nhận",
                }),
                cancelLabel: formatMessage({
                  id: "orders.searches.Hủy",
                  defaultMessage: "Hủy",
                }),
                fromLabel: formatMessage({
                  id: "reports.Từ",
                  defaultMessage: "Từ",
                }),
                toLabel: formatMessage({
                  id: "reports.đến",
                  defaultMessage: "đến",
                }),
                customRangeLabel: formatMessage({
                  id: "reports.Tùy chọn",
                  defaultMessage: "Tùy chọn",
                }),
                daysOfWeek: [
                  formatMessage({
                    id: "reports.CN",
                    defaultMessage: "Sun",
                  }),
                  formatMessage({
                    id: "reports.T2",
                    defaultMessage: "Mon",
                  }),
                  formatMessage({
                    id: "reports.T3",
                    defaultMessage: "Tue",
                  }),
                  formatMessage({
                    id: "reports.T4",
                    defaultMessage: "Wed",
                  }),
                  formatMessage({
                    id: "reports.T5",
                    defaultMessage: "Thu",
                  }),
                  formatMessage({
                    id: "reports.T6",
                    defaultMessage: "Fri",
                  }),
                  formatMessage({
                    id: "reports.T7",
                    defaultMessage: "Sar",
                  }),
                ],
                monthNames: [
                  formatMessage({
                    id: "reports.Tháng 1",
                    defaultMessage: "Tháng 1",
                  }),
                  formatMessage({
                    id: "reports.Tháng 2",
                    defaultMessage: "Tháng 2",
                  }),
                  formatMessage({
                    id: "reports.Tháng 3",
                    defaultMessage: "Tháng 3",
                  }),
                  formatMessage({
                    id: "reports.Tháng 4",
                    defaultMessage: "Tháng 4",
                  }),
                  formatMessage({
                    id: "reports.Tháng 5",
                    defaultMessage: "Tháng 5",
                  }),
                  formatMessage({
                    id: "reports.Tháng 6",
                    defaultMessage: "Tháng 6",
                  }),
                  formatMessage({
                    id: "reports.Tháng 7",
                    defaultMessage: "Tháng 7",
                  }),
                  formatMessage({
                    id: "reports.Tháng 8",
                    defaultMessage: "Tháng 8",
                  }),
                  formatMessage({
                    id: "reports.Tháng 9",
                    defaultMessage: "Tháng 9",
                  }),
                  formatMessage({
                    id: "reports.Tháng 10",
                    defaultMessage: "Tháng 10",
                  }),
                  formatMessage({
                    id: "reports.Tháng 11",
                    defaultMessage: "Tháng 11",
                  }),
                  formatMessage({
                    id: "reports.Tháng 12",
                    defaultMessage: "Tháng 12",
                  }),
                ],
                firstDay: 0,
              }}
              disabled={globalLoading}
              placement="left"
              showRanges={true}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              alwaysShowCalendars={true}
              singleDatePicker={false}
              timePicker={false}
              showIconClose={false}
              minDate="01-01-1950"
              maxDate={new Date()}
              onChange={(startDate, endDate) => {
                setDateRange({
                  startDate: startDate.format(),
                  endDate: endDate.format(),
                });
              }}
            >
              <div className="report-daterange-picker">
                <div className="ui-select-control">
                  <div className="ui-select-cell d-flex justify-content-between">
                    <div className="ui-select-value">{label}</div>
                    <div className="ui-select-arrow">
                      <span className="icon-w-16 icon-primary">
                        {Assets.icons.date_range}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </InputDatetimePicker>
          )}
        </div>
      </div>
    </div>
  );
}
