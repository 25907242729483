import { Assets } from "assets";
import Button from "component-v2/buttons";
import Icon from "component-v2/icons/icons";
import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import Tooltip from "rc-tooltip";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function MainMenu() {
  const { auth, shop_info, checkRoleAllow, shop_setting, shop_loyalty } =
    useApp();
  const layout = useLayoutContext();
  let enlarge = layout.menu_size === "lg";

  const menus = getMenuList(
    shop_setting,
    shop_info,
    shop_loyalty,
    auth.user,
    checkRoleAllow,
  );
  const [selected, setSelected] = useState("/");
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const location = useLocation();

  const menu_ref = useRef(null);
  useEffect(() => {
    if (layout.menu_size === "lg") {
      document.addEventListener("click", handleOutsideClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.menu_size]);
  function handleOutsideClick(e) {
    if (!menu_ref.current.contains(e.target)) {
      onShrinkMenu();
    }
  }
  function onShrinkMenu() {
    layout.shrinkMenu();
    document.removeEventListener("click", handleOutsideClick, false);
  }
  useEffect(() => {
    window.addEventListener("message", handleBlockEvent, false);
    return () => {
      window.removeEventListener("message", handleBlockEvent);
    };
  }, []);
  const handleBlockEvent = (event) => {
    if (
      event &&
      event.data &&
      event.data.type === "dispatch" &&
      event.data.payload
    ) {
      setShouldBlockNavigation(event.data.payload?.blockNavigation);
    }
  };

  useEffect(() => {
    let selected_path = null;
    menus.forEach((group) => {
      group.forEach((menu) => {
        if (selected_path) return;
        if (
          menu.path === location.pathname ||
          (menu.path !== "/" && location.pathname.startsWith(menu.path))
        ) {
          selected_path = menu.path;
        }
      });
    });
    setSelected(selected_path);
  }, [location, menus]);

  return (
    <div className={`l-menu${enlarge ? " enlarge" : ""}`}>
      <div ref={(node) => (menu_ref.current = node)} className="l-menu--float">
        <div className="l-menu--header">
          <div className="l-menu--header-retail">
            <img src={Assets.logos.logo_hrv} alt="Haravn POS" />
          </div>
          <Button
            plain
            size="sm"
            icon={enlarge ? "keyboard_double_arrow_left" : "menu"}
            onClick={() => (enlarge ? onShrinkMenu() : layout.enlargeMenu())}
          ></Button>
        </div>
        <div className="l-menu--items">
          {menus.map((group, idx) => {
            let _items = group.filter((i) => i.isShow);
            if (!_items || !_items.length) return null;
            return (
              <div key={"mn-grp-" + idx} className="l-menu--item-group">
                {_items.map((menu) => {
                  let is_active = menu?.path === selected;
                  return (
                    <Tooltip
                      key={"mnu-" + menu.title}
                      placement="right"
                      trigger={enlarge ? ["none"] : ["hover"]}
                      overlay={<span>{menu.title}</span>}
                      mouseEnterDelay={0.2}
                    >
                      {menu.outsite ? (
                        <a
                          id={menu.id}
                          onClick={onShrinkMenu}
                          className={`l-menu--item${
                            is_active ? " selected" : ""
                          }`}
                          href={menu?.path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon
                            name={is_active ? menu.iconActive : menu.icon}
                            color={
                              is_active
                                ? "var(--contentAccent)"
                                : "var(--contentSecondary)"
                            }
                          />
                          <span className="l-menu--item-title">
                            {menu.title}
                          </span>
                        </a>
                      ) : menu?.path ? (
                        <NavLink
                          onClick={onShrinkMenu}
                          className={`l-menu--item${
                            is_active ? " selected" : ""
                          }`}
                          to={menu?.path}
                          {...(shouldBlockNavigation &&
                            !is_active && {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            })}
                        >
                          <Icon
                            name={is_active ? menu.iconActive : menu.icon}
                            color={
                              is_active
                                ? "var(--contentAccent)"
                                : "var(--contentSecondary)"
                            }
                          />
                          <span className="l-menu--item-title">
                            {menu.title}
                          </span>
                        </NavLink>
                      ) : (
                        <span
                          className={`l-menu--item${
                            is_active ? " selected" : ""
                          }`}
                        >
                          <Icon
                            name={is_active ? menu.iconActive : menu.icon}
                            color={
                              is_active
                                ? "var(--contentAccent)"
                                : "var(--contentSecondary)"
                            }
                          />
                          <span className="l-menu--item-title">
                            {menu.title}
                          </span>
                        </span>
                      )}
                    </Tooltip>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
function getMenuList(
  shop_setting,
  shop_info,
  shop_loyalty,
  user,
  checkRoleAllow,
) {
  const hasNoRoles = !user?.roles || user?.roles.length === 0;
  if (hasNoRoles) return [];
  
  const isOwner =
    user.roles?.includes("admin") || user.roles?.includes("com_api.admin");

  let menuGroups = [];
  let menuGroup1 = [
    {
      title: "Bán hàng",
      path: "/",
      icon: "shopping_cart",
      id: "shopping_cart",
      iconActive: "shopping_cart_filled",
      isShow: true,
    },
    {
      title: "Tra cứu đơn hàng",
      path: "/orders",
      icon: "receipt",
      id: "insert_drive_file",
      iconActive: "receipt_filled",
      isShow: true,
    },
    {
      title: "Tra cứu sản phẩm",
      path: "/products",
      icon: "local_offer",
      id: "local_offer",
      iconActive: "local_offer_filled",
      isShow: true,
    },
    {
      title: "Tạo phiếu thu",
      path: "/cash_receipts/new",
      icon: "call_received",
      id: "call_received",
      iconActive: "south_west_filled",
      isShow:
        checkRoleAllow("com_api.cashflow.read") ||
        checkRoleAllow("com_api.cashflow.write"),
    },
    {
      title: "Tạo phiếu chi",
      path: "/payment_vouchers/new",
      icon: "north_east",
      id: "north_east",
      iconActive: "north_east_filled",
      isShow:
        checkRoleAllow("com_api.cashflow.read") ||
        checkRoleAllow("com_api.cashflow.write"),
    },
    {
      title: "Quản lý ca",
      path: "/shifts",
      icon: "access_time",
      id: "access_time",
      iconActive: "access_time_filled",
      isShow: shop_setting?.shiftEnable,
    },
    {
      title: "Báo cáo cuối ngày",
      path: "/reports/daily",
      icon: "leaderboard",
      id: "leaderboard",
      iconActive: "leaderboard_filled",
      isShow:
        checkRoleAllow("com_api.order.read") ||
        checkRoleAllow("com_api.order.write"),
    },
    {
      title: "Báo cáo bán hàng",
      path: "/reports/sales",
      icon: "insert_chart",
      id: "insert_chart",
      iconActive: "insert_chart_filled",
      isShow:
        checkRoleAllow("com_api.order.read") ||
        checkRoleAllow("com_api.order.write"),
    },
    {
      title: "Cấu hình",
      path: "/settings",
      icon: "settings",
      id: "settings",
      iconActive: "settings_filled",
      isShow: isOwner,
    },
    {
      title: "Quay về giao diện cũ",
      path: "backVersion", // chỉ để hiển thị nút Phím tắt, không phải link menu
      isShow: false,
      icon: "restart_alt",
      id: "restart_alt",
    },
  ];
  menuGroups.push(menuGroup1);
  return menuGroups;
}
