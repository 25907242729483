import { updateOrderNotes } from "api/order-repository";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";
import { Textarea } from "@haravan/react-components";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useApp } from "contexts/app";
import Icon from "component-v2/icons/icons";

export default function OrderNoteModal({ order, onSucceed, children }) {
  const intl = useIntl();
  const { showGlobalToast } = useLayoutContext();
  const { callAPI } = useApp();
  const [show, setShow] = useState(false);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNote(order?.orderNotes);
  }, [order]);
  const handleCancel = () => {
    setShow(false);
    setNote(order?.orderNotes);
  };
  async function handleSaveOrderNote() {
    setLoading(true);
    updateOrderNotes(callAPI, order?.orderId, {
      listAttributes: order?.noteAttributes,
      note,
    })
      .then((res) => {
        if (res && (!res?.errors || !res?.errorCodes)) {
          showGlobalToast("default", "Đã cập nhật ghi chú");
        } else {
          showGlobalToast(
            "error",
            res?.errors || "Đã có lỗi xảy ra, xin vui lòng thử lại.",
          );
        }
        setShow(false);
        onSucceed?.();
      })
      .catch(() => {
        showGlobalToast("error", "Đã có lỗi xảy ra, xin vui lòng thử lại.");
        setNote(order?.orderNotes);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const body = () => {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <label className="margin-0">
              {intl.formatMessage({
                id: "carts.orders.Ghi chú",
                defaultMessage: "Ghi chú",
              })}
            </label>
            <Textarea
              className="l-section w-100 max-3-rows pl-3 order-detail-note-textarea"
              rows={1}
              autoResize={true}
              placeholder={
                note
                  ? note
                  : intl.formatMessage({
                      id: "carts.orders.Nhập ghi chú",
                      defaultMessage: "Nhập ghi chú",
                    })
              }
              onChange={(value) => setNote(value)}
              value={note}
            />
          </div>
        </div>
      </div>
    );
  };

  const footer = () => {
    return (
      <>
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <Button
            className="mr-2 light text-uppercase"
            onClick={handleCancel}
            title="Hủy bỏ"
          />
          <Button
            status="primary"
            className="text-uppercase"
            disabled={loading}
            onClick={handleSaveOrderNote}
            title="Lưu"
          />
        </div>
      </>
    );
  };

  return (
    <GlobalModal
      headerTitle={
        <span>
          {intl.formatMessage({
            id: "carts.orders.Ghi chú đơn hàng",
            defaultMessage: "Ghi chú đơn hàng",
          })}{" "}
          {order.orderNumber}
        </span>
      }
      footer={footer()}
      onCancel={handleCancel}
      body={body()}
      show={show}
      setShow={setShow}
    >
      {children ? (
        children
      ) : (
        <span
          className="cursor-pointer order-note-trigger"
          onClick={() => setShow(true)}
        >
          <Icon name="edit_pencil" color="#2463EB" />
        </span>
      )}
    </GlobalModal>
  );
}
