import React, { useEffect, useState } from "react";
import "./style.css";
import { forEach, find, concat } from "lodash";
import ImportOrderForm from "./import-order-form";
import ProcessOrderForm from "./process-order-form";
import RetryErrorOrderForm from "./error-retry-order-form";
import ErrorOrderForm from "./error-order-form";
import { useCartContext } from "screens-v2/carts-v2";
import { useApp } from "contexts/app";
import { ProductCartHelperService } from "screens-v2/carts-v2/Products/product-cart-helper.service";
import { useLayoutContext } from "contexts/Layout/layout-context";

export default function ImportOrderAlert({ intl, show, setShow }) {
  const appContext = useApp();
  const [dataSheet, setDataSheet] = useState([]);
  const [dataSuccess, setDataSuccess] = useState([]);
  const [dataError, setDataError] = useState([]);
  const [currentForm, setCurrentForm] = useState(1);
  const [currentProcess, setCurrentProcess] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const cartContext = useCartContext();
  const layoutContext = useLayoutContext();
  const productCartHelper = new ProductCartHelperService(
    appContext.shop_setting,
  );
  useEffect(() => {
    if (show) setCurrentForm(1);
  }, [show]);

  if (!show) return null;

  const validationRow = (row) => {
    if (!row || !row.code || !row.quantity) return false;
    if (isNaN(parseInt(row.quantity))) return false;
    return true;
  };

  const searchProductsImport = async (row, page = 1, limit = 10) => {
    const currentRow = { ...row, code: `${row.code}` };
    try {
      const location = appContext.current_location;
      if (!location) {
        return;
      }
      const result = await appContext.callAPI(
        "get",
        `/call/com_api/products/?channel=hararetail&page=${page}&limit=${limit}&location_id=${location.id}&query=${currentRow.code}`,
      );
      const products = result.data.data || [];
      let records = [];

      forEach(products, (product) => {
        let variants = product.variants || [];
        // eslint-disable-next-line array-callback-return
        variants.map((variant) => {
          variant.notAllowPromotion = product.notAllowPromotion;
          variant.variantId = variant.id;
          variant.productId = product.id;
          variant.productTitle = product.productName;
          variant.imageUrl = variant.imageUrl || product.url;
          variant.quantityImport = row.quantity || 1;
        });
        const listFound = find(
          variants,
          (variant) =>
            variant.barcode === currentRow.code ||
            variant.sku === currentRow.code,
        );
        if (listFound) records = concat(records, listFound);
      });
      return records;
    } catch (error) {
      return [];
    }
  };

  const checkProductInWorkSheet = async (listProductSheet = []) => {
    const processPercent = 100 / listProductSheet.length;
    let process = 0;
    let productListSuccess = [];
    let productListError = [];
    for (let index = 0; index < listProductSheet.length; index += 1) {
      const row = listProductSheet[index];
      const valid = validationRow(row);
      if (valid) {
        const record = await searchProductsImport(row);
        if (record && record.length) {
          if (record.length === 1)
            productListSuccess = concat(productListSuccess, record);
          else {
            listProductSheet[index].messageError = intl.formatMessage({
              id: "carts.orders.Sản phẩm có trùng Barcode/SKU",
              defaultMessage: "Sản phẩm có trùng Barcode/SKU",
            });
            listProductSheet[index].error = true;
            productListError = concat(
              productListError,
              listProductSheet[index],
            );
          }
        } else {
          listProductSheet[index].messageError = intl.formatMessage({
            id: "carts.orders.Sản phẩm không tồn tại",
            defaultMessage: "Sản phẩm không tồn tại",
          });
          listProductSheet[index].error = true;
          productListError = concat(productListError, listProductSheet[index]);
        }
        process += processPercent;
      } else {
        process += processPercent;
        listProductSheet[index].messageError = intl.formatMessage({
          id: "payment-vouchers.Dữ liệu không hợp lệ",
          defaultMessage: "Dữ liệu không hợp lệ",
        });
        listProductSheet[index].quantity = 1;
        listProductSheet[index].error = true;
        productListError = concat(productListError, listProductSheet[index]);
      }
      setCurrentProcess(process);
    }
    return { productListSuccess, productListError };
  };

  const addOrderLine = async (orderLines = []) => {
    setShow(false);
    cartContext.addBulkItemCurrentCart(orderLines);
  };
  switch (currentForm) {
    case 1:
      return (
        <ImportOrderForm
          intl={intl}
          show={show}
          setShow={setShow}
          setErrorMessage={setErrorMessage}
          setCurrentForm={setCurrentForm}
          setDataSheet={setDataSheet}
          dataSuccess={dataSuccess}
          setDataSuccess={setDataSuccess}
          setDataError={setDataError}
          checkProductInWorkSheet={checkProductInWorkSheet}
          addOrderLine={addOrderLine}
        />
      );
    case 2:
      return <ProcessOrderForm intl={intl} currentProcess={currentProcess} />;
    case 3:
      return (
        <RetryErrorOrderForm
          intl={intl}
          show={show}
          setShow={setShow}
          dataSuccess={dataSuccess}
          setDataSuccess={setDataSuccess}
          dataSheet={dataSheet}
          setDataSheet={setDataSheet}
          dataError={dataError}
          setDataError={setDataError}
          setCurrentForm={setCurrentForm}
          checkProductInWorkSheet={checkProductInWorkSheet}
          addOrderLine={addOrderLine}
        />
      );
    case 4:
      return (
        <ErrorOrderForm
          intl={intl}
          show={show}
          setShow={setShow}
          errorMessage={errorMessage}
        />
      );
    default:
      return null;
  }
}
