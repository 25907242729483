import { Input, Loading, Textarea } from "@haravan/react-components";
import {
  CreateCashFlowObject,
  GetCashFlowObjectById,
  UpdateCashFlowObject,
} from "api/payment-voucher-api";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Addresses from "./addresses";
import "../index.css";
import { useApp } from "contexts/app";
const default_data = {
  name: "",
  phone: "",
  address: "",
  notes: "",
  city: null,
  countryId: null,
  countryName: "",
  districtId: null,
  districtName: "",
  provinceId: null,
  provinceName: "",
  wardId: "",
  wardName: "",
  zipCode: null,
};

const ModalAddNewObjectOther = ({
  isOpen,
  closeModal,
  handleCallApiSuccess,
  isEditModal,
  editData,
}) => {
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [formData, setFormData] = useState(default_data);
  const appContext = useApp();
  useEffect(() => {
    if (isEditModal) {
      if (editData?.id) {
        getCashFlowObjectDetail(editData.id);
      }
    }
  }, [isEditModal, editData]);

  const getCashFlowObjectDetail = async (id) => {
    setIsLoadingData(true);
    const response = await GetCashFlowObjectById(appContext, id);
    setIsLoadingData(false);
    if (response?.data) {
      setFormData({ ...default_data, ...response.data });
    }
  };

  const handleOnChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearForm = () => {
    setFormData(default_data);
  };

  const handleCancel = () => {
    closeModal();
    clearForm();
  };

  const handleConfirm = async () => {
    const message = isEditModal
      ? "Cập nhật người nhận thành công"
      : "Tạo người nhận thành công";
    setIsLoading(true);
    const res = isEditModal
      ? await UpdateCashFlowObject(appContext, formData, formData.id)
      : await CreateCashFlowObject(appContext, formData);
    setIsLoading(false);
    if (res?.data) {
      showGlobalToast(
        "default",
        intl.formatMessage({
          id: `notifications.${message}`,
          defaultMessage: message,
        }),
      );
      handleCallApiSuccess(formData.id);
      setTimeout(() => {
        handleCancel();
        clearForm();
      }, 400);
    } else if (res?.errors?.length) {
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: `notifications.${res?.errors[0]}`,
          defaultMessage: res?.errors[0],
        }),
      );
    }
  };

  const title = isEditModal ? "Cập nhật người nhận" : "Tạo người nhận";

  const body = () => (
    <div className="row">
      <div className="col-6 mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "carts.orders.Tên",
            defaultMessage: "Tên",
          })}
        </label>
        <Input
          value={formData?.name || ""}
          onChange={(value) => handleOnChange("name", value)}
          type="text"
          className="text-black"
          placeholder={intl.formatMessage({
            id: "carts.orders.Nhập tên",
            defaultMessage: "Nhập tên",
          })}
          autoFocus={true}
        />
      </div>
      <div className="col-6 mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "carts.orders.Số điện thoại",
            defaultMessage: "Số điện thoại",
          })}
        </label>
        <Input
          value={formData?.phone || ""}
          onChange={(value) => handleOnChange("phone", value)}
          type="number"
          className="text-black"
          placeholder={intl.formatMessage({
            id: "carts.orders.Nhập số điện thoại",
            defaultMessage: "Nhập số điện thoại",
          })}
          autoFocus={true}
        />
      </div>
      <div className="col-12 mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "settings.print-templates.Địa chỉ",
            defaultMessage: "Địa chỉ",
          })}
        </label>
        <Input
          value={formData?.address || ""}
          onChange={(value) => handleOnChange("address", value)}
          type="text"
          className="text-black"
          placeholder={intl.formatMessage({
            id: "payment-vouchers.Nhập địa chỉ",
            defaultMessage: "Nhập địa chỉ",
          })}
          autoFocus={true}
        />
      </div>
      <Addresses
        countryId={formData?.countryId || 241}
        provinceId={formData?.provinceId}
        districtId={formData?.districtId}
        wardId={formData?.wardId}
        handleOnChange={(data) => {
          setFormData((prev) => ({
            ...prev,
            ...data,
          }));
        }}
      />
      <div className="col-12 mb-3">
        <label className="pb-2">
          {intl.formatMessage({
            id: "carts.orders.Ghi chú",
            defaultMessage: "Ghi chú",
          })}
        </label>
        <Textarea
          rows={3}
          value={formData?.notes || ""}
          onChange={(value) => handleOnChange("notes", value)}
          className="text-black text-area-none-resize"
          placeholder={intl.formatMessage({
            id: "carts.orders.Nhập ghi chú",
            defaultMessage: "Nhập ghi chú",
          })}
        />
      </div>
      {isLoadingData && (
        <>
          <div className="loading-bg-blur" />
          <div className="modal-loading--icon position-absolute loading-content-modal">
            <Loading />
          </div>
        </>
      )}
    </div>
  );

  return (
    <GlobalModal
      headerTitle={intl.formatMessage({
        id: `payment-vouchers.${title}`,
        defaultMessage: title,
      })}
      body={body()}
      show={isOpen}
      setShow={(value) => !value && closeModal()}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      loading={isLoading}
    />
  );
};

export default ModalAddNewObjectOther;
