import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useAddressData from "hooks/useAddressData";
import { useIntl } from "react-intl";
import { SelectionCustom } from "./SelectionCustom";

const vietnamId = 241;

const address_default = {
  countryId: vietnamId,
  countryName: "Vietnam",
  provinceId: null,
  provinceName: "",
  districtId: null,
  districtName: "",
  wardId: null,
  wardName: "",
};

const AddressesGlobal = ({
  handleOnChange,
  onlyVN = false,
  countryId,
  provinceId,
  districtId,
  wardId,
  isRequired = false,
  isDisable = false,
}) => {
  const intl = useIntl();

  const [addresses, setAddresses] = useState(address_default);

  const { countries, provinces, districts, wards } = useAddressData({
    countryId: addresses.countryId,
    provinceId: addresses.provinceId,
    districtId: addresses.districtId,
  });

  const handleOnChangeAddress = (value) => {
    const newAddresses = { ...addresses, ...value };
    setAddresses(newAddresses);
    handleOnChange(newAddresses);
  };

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      countryId,
    }));
  }, [countryId]);

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      provinceId,
    }));
  }, [provinceId]);

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      districtId,
    }));
  }, [districtId]);

  useEffect(() => {
    setAddresses((prev) => ({
      ...prev,
      wardId,
    }));
  }, [wardId]);

  const notFoundContent = intl.formatMessage({
    id: "carts.orders.Không tìm thấy",
    defaultMessage: "Không tìm thấy",
  });
  return (
    <>
      {/* {!onlyVN && */}
      <div className="col-12 col-md-6 mt-3">
        <label>
          {intl.formatMessage({
            id: "payment-vouchers.Quốc gia",
            defaultMessage: "Quốc gia",
          })}
        </label>
        {isRequired && (
          <span className="ml-1" style={{ color: "red" }}>
            *
          </span>
        )}
        <SelectionCustom
          options={countries}
          idField="id"
          valueField="name"
          disabled={isDisable}
          showSearch={true}
          placeholder={intl.formatMessage({
            id: "payment-vouchers.Quốc gia",
            defaultMessage: "Quốc gia",
          })}
          value={onlyVN ? vietnamId : addresses.countryId}
          notFoundContent={notFoundContent}
          onChange={(value) => {
            const countrySelected = countries.find((item) => item.id === value);
            handleOnChangeAddress({
              countryId: value,
              countryName: countrySelected?.name,
              provinceId: null,
              provinceName: "",
              districtId: null,
              districtName: "",
              wardId: null,
              wardName: "",
            });
          }}
          className={`cart-input`}
        >
          {countries?.map((option) => {
            return (
              <SelectionCustom.Option
                disabled={option.id !== vietnamId}
                value={option.id}
                key={option.id}
              >
                {option.name}
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
      </div>
      {/* } */}
      <div className="col-12 col-md-6 mt-3">
        <label>
          {intl.formatMessage({
            id: "carts.orders.Tỉnh/Thành phố",
            defaultMessage: "Tỉnh/Thành phố",
          })}
        </label>
        {isRequired && (
          <span className="ml-1" style={{ color: "red" }}>
            *
          </span>
        )}
        <SelectionCustom
          idField="id"
          valueField="name"
          options={provinces}
          disabled={isDisable}
          showSearch={true}
          placeholder={`${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Tỉnh/Thành phố", defaultMessage: "Tỉnh/Thành phố" })}`}
          value={
            addresses.provinceId ||
            `${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Tỉnh/Thành phố", defaultMessage: "Tỉnh/Thành phố" })}`
          }
          notFoundContent={notFoundContent}
          onChange={(value) => {
            const provinceSelected = provinces.find(
              (item) => item.id === value,
            );
            handleOnChangeAddress({
              provinceId: value,
              provinceName: provinceSelected?.name,
              districtId: null,
              districtName: "",
              wardId: null,
              wardName: "",
            });
          }}
          className={`cart-input`}
        >
          {provinces?.map((option) => {
            return (
              <SelectionCustom.Option value={option.id} key={option.id}>
                {option.name}
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
      </div>
      <div className="col-12 col-md-6 mt-3">
        <label>
          {intl.formatMessage({
            id: "carts.orders.Quận/Huyện",
            defaultMessage: "Quận/Huyện",
          })}
        </label>
        {isRequired && (
          <span className="ml-1" style={{ color: "red" }}>
            *
          </span>
        )}
        <SelectionCustom
          options={districts}
          idField="id"
          valueField="name"
          disabled={isDisable}
          showSearch={true}
          placeholder={`${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Quận/Huyện", defaultMessage: "Quận/Huyện" })}`}
          value={
            addresses.districtId ||
            `${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Quận/Huyện", defaultMessage: "Quận/Huyện" })}`
          }
          notFoundContent={notFoundContent}
          onChange={(value) => {
            const districtSelected = districts.find(
              (item) => item.id === value,
            );
            handleOnChangeAddress({
              districtId: value,
              districtName: districtSelected?.name,
              wardId: null,
              wardName: "",
            });
          }}
          className={`cart-input`}
        >
          {districts?.map((option) => {
            return (
              <SelectionCustom.Option value={option.id} key={option.id}>
                {option.name}
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
      </div>
      <div className="col-12 col-md-6 mt-3">
        <label>
          {intl.formatMessage({
            id: "carts.orders.Phường/Xã",
            defaultMessage: "Phường/Xã",
          })}
        </label>
        {isRequired && (
          <span className="ml-1" style={{ color: "red" }}>
            *
          </span>
        )}
        <SelectionCustom
          options={wards}
          idField="id"
          valueField="name"
          disabled={isDisable}
          showSearch={true}
          placeholder={`${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Phường/Xã", defaultMessage: "Phường/Xã" })}`}
          value={
            addresses.wardId ||
            `${intl.formatMessage({ id: "carts.orders.Chọn", defaultMessage: "Chọn" })} ${intl.formatMessage({ id: "carts.orders.Phường/Xã", defaultMessage: "Phường/Xã" })}`
          }
          notFoundContent={notFoundContent}
          onChange={(value) => {
            const wardSelected = wards.find((item) => item.id === value);
            handleOnChangeAddress({
              wardId: value,
              wardName: wardSelected?.name,
            });
          }}
          className={`cart-input`}
        >
          {wards?.map((option) => {
            return (
              <SelectionCustom.Option value={option.id} key={option.id}>
                {option.name}
              </SelectionCustom.Option>
            );
          })}
        </SelectionCustom>
      </div>
    </>
  );
};

AddressesGlobal.propTypes = {
  handleOnChange: PropTypes.func,
  onlyVN: PropTypes.bool,
};

export default AddressesGlobal;
