import Button from "component-v2/buttons";
import { HOTKEYS } from "models/constants";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
function ShiftDisableSettingAlert({ show, setShow }) {
  const intl = useIntl();

  const body = () => {
    return (
      <p className="margin-top-10 text-custom-color">
        {intl.formatMessage({
          id: "carts.orders.Phiên làm việc có ca đang mở, Vui lòng đóng ca tất cả chi nhánh trước khi tắt cấu hình này!",
          defaultMessage:
            "Phiên làm việc có ca đang mở, Vui lòng đóng ca tất cả chi nhánh trước khi tắt cấu hình này!",
        })}
      </p>
    );
  };

  const footer = () => {
    return (
      <Button
        light
        id="order.create.success_popup.new_button"
        onClick={() => {
          setShow(false);
        }}
        className="l-mr-16"
        title={intl.formatMessage({
          id: "carts.orders.Đóng",
          defaultMessage: "Đóng",
        })}
        badge={HOTKEYS.ESC}
      />
    );
  };

  return (
    <GlobalModal
      headerTitle={intl.formatMessage({
        id: "carts.orders.Không thể tắt chức năng quản lý ca",
        defaultMessage: "Không thể tắt chức năng quản lý ca",
      })}
      body={body()}
      show={show}
      setShow={setShow}
      onCancel={() => setShow(false)}
      footer={footer()}
    />
  );
}

export default ShiftDisableSettingAlert;
