export async function updateSettingSelling(appContext, model, locationId) {
  return await appContext.callAPI(
    "put",
    `/call/retail_api/setting/cart?locationId=${locationId}`,
    model
  );
}

export async function updateSettingShift(appContext, model, locationId) {
  return await appContext.callAPI(
    "put",
    `/call/retail_api/setting/shift?locationId=${locationId}`,
    model
  );
}

export async function enablePaymentMethod(appContext, model) {
  return await appContext.callAPI(
    "put",
    `/call/retail_api/settings/payment/enable?code=${model.code}&locationId=${model.locationId}`
  );
}

export async function createCustomPaymentMethod(appContext, model) {
  const response = await appContext.callAPI(
    "post",
    `/call/retail_api/settings/payment/custom?&name=${model.name}&locationId=${model.locationId}`
  );
  return response;
}

export async function updateCustomPaymentMethod(appContext, model) {
  const response = await appContext.callAPI(
    "put",
    `/call/retail_api/settings/payment/custom?code=${model.code}&name=${model.name}&locationId=${model.locationId}`
  );
  return response;
}

export async function disablePaymentMethod(appContext, model) {
  return await appContext.callAPI(
    "put",
    `/call/retail_api/settings/payment/disable?code=${model.code}&locationId=${model.locationId}`
  );
}

export async function deletePaymentMethod(appContext, model) {
  return await appContext.callAPI(
    "delete",
    `/call/retail_api/settings/payment/custom?code=${model.code}&locationId=${model.locationId}`
  );
}

export async function updateSettingGeneral(appContext, model, locationId) {
  return await appContext.callAPI(
    "put",
    `/call/retail_api/setting/general?locationId=${locationId}`,
    model
  );
}

export async function updateSettingPrint(appContext, model) {
  return await appContext.callAPI(
    "put",
    "/call/retail_api/setting/print",
    model
  );
}
export async function testHTMLPrintTemplate(callAPI, model) {
  return await callAPI(
    "post",
    "/call/retail_api/settings/print-template/test",
    model
  );
}
export async function updateHTMLPrintTemplate(callAPI, typeId, model) {
  return await callAPI(
    "put",
    `/call/retail_api/settings/print-template/${typeId}`,
    model
  );
}
export async function getHTMLPrintTemplate(callAPI, typeId) {
  return await callAPI(
    "get",
    `/call/retail_api/settings/print-template/${typeId}`
  );
}
export async function getDefaultHTMLPrintTemplate(callAPI, typeId) {
  return await callAPI(
    "get",
    `/call/retail_api/settings/print-template/${typeId}/default`
  );
}
export async function getHTMLTemplateTypes(callAPI) {
  return await callAPI("get", "/call/retail_api/settings/print-template/types");
}
export async function uploadLogo(appContext, shopDataUrl, typeUpload) {
  return new Promise((resolve, reject) => {
    let fileData = new FormData();
    const canvas = document.createElement("canvas");
    const img = document.createElement("img");
    const ctx = canvas.getContext("2d");
    const MAX_WIDTH = 220;
    img.src = shopDataUrl;
    img.onload = () => {
      const { width, height } = img;
      const ratio = width / height;
      canvas.width = width <= MAX_WIDTH ? width : MAX_WIDTH;
      canvas.height = width <= MAX_WIDTH ? height : canvas.width / ratio;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(async (blob) => {
        const uploadFile = new File(
          [blob],
          typeUpload === "logo" ? "uploadLogo.jpeg" : "uploadShopImage.jpeg"
        );
        fileData.append("file", uploadFile);
        try {
          const result = await appContext.callAPI(
            "upload_file",
            "/call/retail_api/file/upload",
            fileData
          );
          resolve(result);
        } catch (error) {
          reject();
        }
      }, "img/jpeg");
    };
  });
}

export async function getStaffUsers(callAPI, locationId) {
  return await callAPI(
    "get",
    `/call/com_api/locations/${locationId}/users`,
    null,
    null,
    false
  );
}
